import React, { useEffect } from 'react';
import {
    Box,
} from '@mui/material';
import useStyles from './Chart.styles';

import { Bar } from 'react-chartjs-2';
import { useGQLQuery } from '../../hooks/useGQLQuery';
import chartQuery from '../../queries/chart';
import { URL_CONF } from '../../helper/constants';
import Loader from '../../components/loader';
import toast from 'react-hot-toast';
import { BarElement, CategoryScale, Legend, LinearScale, Title, Tooltip } from 'chart.js';

function Chart() {
    const classes = useStyles();

    const {
        data, isFetching, refetch, error,
    } = useGQLQuery(
        'chart',
        chartQuery.CHART_DATA(),
        {},
        {},
        URL_CONF.DEFAULT,
    );

    useEffect(() => {
        if (error !== null) {
            const message = error && error.response && error.response.errors
                && error.response.errors[0] ? error.response.errors[0].message : 'API failed';
            if (message) {
                toast.error(message);
            }
        }
    }, [error]);

    const chartData = data?.chart;

    const labels = chartData && chartData.map((item) => {return item.month});

    const barGrapHData = {
        labels,
        datasets: [
            {
                label: 'Sales',
                data: chartData && chartData.map((item) => {return item.sales}),
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
            {
                label: 'Tax',
                data: chartData && chartData.map((item) => {return item.tax}),
                backgroundColor: 'rgba(22, 109, 70, 20%)',
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: 'Chart.js Bar Chart',
            },
        },
    };

    // Chart.register(
    //     CategoryScale,
    //     LinearScale,
    //     BarElement,
    //     Title,
    //     Tooltip,
    //     Legend,
    // );

    return (
        <Box>
            <Loader loading={isFetching} />
            <h2>Charts</h2>
            <Box className={classes.chartSec}>
                {/* <Bar options={options} data={barGrapHData} /> */}
            </Box>
        </Box>
    );
}

export default Chart;
