import { ThemeProvider, createTheme } from '@mui/material/styles';
import { hover } from '@testing-library/user-event/dist/hover';
import { green } from '@mui/material/colors';
import { Padding } from '@mui/icons-material';

const SocialInfoPopupTheme = createTheme({
    components: {
        MuiSelect: {
            styleOverrides: {
                root: {
                    minHeight: '28px',
                    padding: '0',
                    // border: '1px solid #707070',
                    borderRadius: '0px',
                    width: '11rem',
                },
            },
        },

    },
    typography: {
        fontFamily: 'Montserrat, sans-serif',
        h2: {
            fontSize: 32,
            fontWeight: 'bold',
        },
        h3: {
            fontSize: 20,
            fontWeight: 'bold',
        },
        body1: {
            fontFamily: 'Roboto, sans-serif',
            fontSize: 14,
        },
    },
});

export default SocialInfoPopupTheme;
