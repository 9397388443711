import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => {return {
    chartSec: {
        width: "-webkit-fill-available",
        height: "60vh",
    },
    BoxItem: {
        padding: "5px",
        fontSize: "12px",
        color: "#707070",
        display: "grid",
        gridTemplateColumns: "1fr auto auto",
        columnGap: "1rem",
        "&:nth-of-type(odd)": {
            backgroundColor: "#F3F3F3",
        },
        "&:nth-of-type(even)": {
            backgroundColor: "#E6E6E6",
        },
    },

    ProcessTopWrapper: {
        display: "grid",
        gridTemplateColumns: "1fr auto",
        alignItems: "start",
        justifyItems: "center",
    },

    SelectPopupParent: {
        width: "-webkit-fill-available",
        height: "-webkit-fill-available",
        display: 'flex',
        justifyContent: 'center',
        position: 'fixed',
        top: 0,
        left: 0,
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.54)',
        zIndex: 1300,
    },

    SelectPopupContent: {
        width: "-webkit-fill-available",
        height: "-webkit-fill-available",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        justifyContent: 'center',
        alignItems: 'center',
        transition: '0.3ms',
    },

    PaperWrapper: {
        display: "flex",
        flexDirection: "column",
        gap: "1vw",
        padding: '5rem',
        justifyContent: 'center',
        alignItems: 'center',
    },

    InnerContent: {
        margin: '1vw',
        display: 'flex',
        flexDirection: 'column',
        gap: '1vw',
        alignItems: 'center',
    },

    SelectBorderRemove: {
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
        },
    },

    BackgroundBorderRemove:{
        border: 0,
        background: 0,
    },

    PopupMessage: {
        fontSize:'1.4vw',
        fontWeight: 'bold',
        fontFamily: 'Roboto, sans-serif',
        lineHeight: '1.2',
        marginTop: '1rem',
    },

    PopupButtonText: {
        fontSize:'0.8vw !important',
        fontWeight: 'bold',
        fontFamily: 'Montserrat, sans-serif',
        color: 'white !important',
        width: '5vw !important',
        height: 'auto !important',
        '@media (max-width: 1300px)': {
            height: 'auto',
        },
    },

    ButtonWrap: {
        display: 'flex',
        flexDirection: 'row',
        gap: '1vw',

    },

    SubmitEditWrapper: {
        display: "flex",
        flexDirection: "column",
    },
    NameRenamer: {
        display: "flex",
        gap: "1vw",
    },
    KeepOriginalCheck: {
        display: "flex",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        gap: "1vw",
    },

    MissingInputsMessage: {
        textDecoration: "underline",
        color: "#d32f2f",
    },
    MissingInputsBox: {
        display: "flex", 
        flexDirection: "column", 
        textAlign: "left",
        gap: "0.5vw",
    },
    MissingInputsList: {
        display: "flex", 
        flexDirection: "column", 
        fontSize: "0.7vw",
    },
    SubmittingLoadingBox: {
        width: '-webkit-fill-available',
        height: 'fit-content',
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        position: "relative",
        top: 0,
        left: 0,
        justifyContent: "center",
        zIndex: 2000,
        transition: '0.3ms',
        // backgroundColor: "rgba(0, 0, 0, 0.54)",
    },
    SubmittingLoadingPaper: {
        display: "flex", 
        flexDirection: "row", 
        gap: "0.5vw",
        alignItems: "center",
        height: "auto",
        padding: '1vw !important',
    },
    SubmittingLoadingMessage: {
        fontSize: '1vw !important',

    },
    CheckIconSuccess:{
        color: '#004021',
        fontSize: '3vw !important',
    },
    CheckCalcWrapper:{
        display: "flex", 
        flexDirection: "column", 
        gap: "0.5vw",
        alignItems: 'center',
    },

    '@global': {
        '@keyframes deleteSpinner': {
            '0%': { transform: 'rotate(0deg)' },
            '100%': { transform: 'rotate(360deg)' },
        },
    },
    deleteLoadingSpinner: {
        animation: 'deleteSpinner 2s linear infinite',
        fontSize: '1.4vw !important',
    },

}});

export default useStyles;
