import { useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';
import { ThemeProvider } from '@mui/material/styles';
import useStyles from './C1SDGTableChart';
// import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { EnvironmentalResult, Product } from '../../../../../interface/Product';
import GraphDownload from '../../../options/components/ChartDownload';
import SDGLinkage from '../../../options/components/SDGLinkage';
import ProductComparison from '../../../options/components/Comparison';

import { initialOptions } from '../../../options/InitialOptionValues';
import deepClone from '../../../../../helper/deepClone';
import {
    ChartJSData,
    ChartJSOptions,
} from '../../../../../interface/ChartData';
import { CreateChartTS } from '../../../util/CreateChartTS';
import EnvChartNavigation from '../../../../dropdowns/BaseOptions/EnvChartNavigation';
import ResultsTheme from '../../../style/ResultsTheme';
import SDGTable from '../../../sdgtable';
import GraphType from '../../../options/components/GraphTypeOptions';
import SDGChecklist from '../../../components/SDGChecklist';

interface ChartData {
    product: Product;
}

interface SDGCategory {
    name: string;
    val: number;
    unit: string;
    correlation: 'Direct' | 'Indirect' | 'None';
}

interface SDGResult {
    name: string;
    categories: SDGCategory[];
}

function C1SDGLinkChart(props: ChartData) {
    const classes = useStyles();
    const { product } = props;
    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
    const [chartJSData, setChartJSData] = useState<ChartJSData>({
        datasets: [],
        labels: [],
    });
    const [chartOptions, setChartOptions] = useState<ChartJSOptions>();
    const [selectedValueType, setSelectedValueType] = useState<string>('');
    const [results, setResults] = useState<EnvironmentalResult[]>([]);
    const [selectedSDGs, setSelectedSDGs] = useState<string[]>([]);


    useMemo(() => {
        const calc = product.calculations?.find(
            (item) => item.calculationType === 'ENVIRONMENTAL'
        );
        const calcResults = calc?.calculationResults as EnvironmentalResult[];
        if (calcResults) {
            setResults(calcResults);
        }
    }, [product]);

    useEffect(() => {
        const chartData: ChartJSData = { datasets: [], labels: [] };
        const options: ChartJSOptions = deepClone<ChartJSOptions>(initialOptions);
        chartData.datasets = [
            {
                label: product.productName,
                data: selectedCategories.map(
                    (category) =>
                        results.reduce(
                            (productTotal, lcs) =>
                                productTotal +
                                lcs.subStages.reduce((total, lcss) => {
                                    const val = lcss.normalizedResults.find(
                                        (result) => result.impactCategory.name === category
                                    );
                                    if (val) {
                                        total += val.value;
                                    }
                                    return total;
                                }, 0),
                            0
                        ),
                    0
                ),
            },
        ];

        chartData.labels = selectedCategories;
        setChartJSData(chartData);
        setChartOptions(options);
    }, [selectedValueType, selectedCategories, results]);

    useEffect(() => {
        if (chartJSData && chartOptions) {
            const options = deepClone(chartOptions);
            const chartData = deepClone(chartJSData);
            console.log(chartData);
            CreateChartTS(chartData, options);
        }
    }, [chartJSData, chartOptions]);

    // Transform product data into SDGResults format
    const currentSDGs: SDGResult[] = results.map(result => ({
        name: result.name,
        categories: result.subStages.flatMap(subStage => subStage.normalizedResults.map(normResult => ({
            name: normResult.impactCategory.name,
            val: normResult.value,
            unit: normResult.unit,
            correlation: 'Direct', // Assume 'Direct' for this example; adjust based on actual data structure
        }))),
    }));

    return (
        <ThemeProvider theme={ResultsTheme}>
            <Box
                sx={{
                    margin: '0',
                    maxWidth: '100%',
                    maxHeight: '-webkit-fill-available',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1vw',
                }}
            >
                <Box component='div' className='results-wrapper'>
                    <Box component='div' className='results-options'>
                        <Paper square>
                            <Box component='div' className='results-options-top'>
                                <FormControl
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: '3rem',
                                        alignItems: 'stretch',
                                    }}
                                >
                                    <EnvChartNavigation
                                        product={product}
                                        defaultBaseIndex={2}
                                        defaultResultIndex={0}
                                        sdgactive={true}
                                    />
                                    <SDGChecklist
                                        setting="C1"
                                        dimension="Environmental"
                                        setSelectedSDGs={setSelectedSDGs}

                                    />
                                    <ProductComparison />
                                </FormControl>
                            </Box>
                        </Paper>
                        <Divider />
                        <Paper square>
                            <Box component='div' className='results-options-bottom'>
                                <Box>
                                    <FormControl
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}
                                        className='bottom-options side-left'
                                    >
                                        <GraphType />
                                    </FormControl>
                                </Box>
                                <Box>
                                    <FormControl
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}
                                        className='bottom-options side-right'
                                    >
                                        <GraphDownload />
                                    </FormControl>
                                </Box>
                            </Box>
                        </Paper>
                    </Box>
                </Box>
                <Box className={classes.chartSec}>
                    <Paper
                        square
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: '1vw',
                            padding: '2rem',
                        }}
                    >
                        <SDGTable currentSDGs={currentSDGs} />
                    </Paper>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default C1SDGLinkChart;
