import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => {return {
    chartSec: {
        width: "-webkit-fill-available",
        height: "60vh",
    },
    BoxListItem: (props) => ({
        padding: '5px',
        fontSize: '12px',
        color: '#707070',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr auto',
        columnGap: '1vw',
        '&:nth-of-type(odd)': {
            backgroundColor: '#F3F3F3',
        },
        '&:nth-of-type(even)': {
            backgroundColor: '#E6E6E6',
        },
    }),

    BoxListItemNoFlow: {
        padding: '5px',
        fontSize: '12px',
        color: '#707070',
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        columnGap: '1vw',
        '&:nth-of-type(odd)': {
            backgroundColor: '#F3F3F3',
        },
        '&:nth-of-type(even)': {
            backgroundColor: '#E6E6E6',
        },
    },

    FlowTopWrapper: {
        display: "grid",
        gridTemplateColumns: "1fr auto",
        alignItems: "start",
        justifyItems: "center",
    },

    SelectProcessPopupParent: {
        width: "-webkit-fill-available",
        height: "-webkit-fill-available",
        margin: "5rem",
        display: 'flex',
        justifyContent: 'center',
    },

    SelectProcessPopupContent: {
        width: "-webkit-fill-available",
        height: "-webkit-fill-available",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
    },

    FlowMiddleWrapper: {
        display: "flex",
        alignItems: "center",
        gap: "2rem",
        padding: "1rem",
        justifyContent: 'center',
    },

    FlowBottomWrapper: {
        display: 'flex',
        columnGap: '1vw',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        maxHeight: '50vh',
        overflowY: 'auto',
    },

    ButtonEditFlow: {
        width: "5rem",
        height: "fit-content",
        fontSize: "14px",
        padding: "2px",
        boxShadow: "0 0 0",
    },

    DropdownWrapperProcess: {
        display: "grid",
        alignItems: "center",
        gap: "1rem",
        gridTemplateColumns: '2fr 1fr 1fr 1fr auto',
        width: '-webkit-fill-available',
    },

    CategoryDropdown: {
        width: '12vw',
    },

    PopupPageNumbers: {
        flexWrap: 'nowrap',
    },

    CategoryBox: {
        border: '1px solid #c4c4c4',
        // padding: '5px',
        backgroundColor: '#D9EFDF',
        marginLeft: '0 !important',
        marginRight: '0 !important',
    },

    ProcessSearchBar: {
        border: '1px solid #c4c4c4',
    },

    SelectBorderRemove: {
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
        },
    },

    BackgroundBorderRemove:{
        border: 0,
        background: 0,
    },

    currentNumPage: {
        backgroundColor: '#a0c6b4',
    },

}});

export default useStyles;
