import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => {return {
    chartSec: {
        width: "-webkit-fill-available",
        height: "60vh",
    },
    BoxListItem: {
        padding: "5px",
        fontSize: "12px",
        color: "#707070",
        display: "grid",
        gridTemplateColumns: "1fr auto auto",
        columnGap: "1rem",
        "&:nth-of-type(odd)": {
            backgroundColor: "#F3F3F3",
        },
        "&:nth-of-type(even)": {
            backgroundColor: "#E6E6E6",
        },
    },

    ProcessTopWrapper: {
        display: "grid",
        gridTemplateColumns: "1fr auto",
        alignItems: "start",
        justifyItems: "center",
    },

    SelectProcessPopupParent: {
        width: "-webkit-fill-available",
        height: "-webkit-fill-available",
        margin: "5rem",
        display: 'flex',
        justifyContent: 'center',
    },

    SelectProcessPopupContent: {
        width: "-webkit-fill-available",
        height: "-webkit-fill-available",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
    },

    ProcessMiddleWrapper: {
        display: "flex",
        alignItems: "center",
        gap: "2rem",
        padding: "1rem",
        justifyContent: 'center',
    },

    ProcessBottomWrapper: {
        display: 'flex',
        columnGap: '1vw',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        maxHeight: '50vh',
        overflowY: 'auto',
    },

    ButtonEditProcess: {
        width: "5vw !important",
        height: "fit-content !important",
        fontSize: "0.7vw !important",
        padding: "2px !important",
        boxShadow: "0 0 0 !important",
        display: 'flex !important',
        alignItems: 'center !important',
        justifyContent: 'center !important',
        lineHeight: '1vw !important',
    },

    DropdownWrapperProcess: {
        display: "grid",
        alignItems: "center",
        gap: "1rem",
        gridTemplateColumns: '2fr 1fr 1fr 1fr auto',
        width: '-webkit-fill-available',
    },

    CategoryDropdown: {
        width: '12vw',
    },

    PopupPageNumbers: {
        flexWrap: 'nowrap',
    },

    CategoryBox: {
        border: '1px solid #c4c4c4',
        // padding: '5px',
        backgroundColor: '#D9EFDF',
        marginLeft: '0 !important',
        marginRight: '0 !important',
    },

    ProcessSearchBar: {
        border: '1px solid #c4c4c4',
    },

    SelectBorderRemove: {
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
        },
    },

    BackgroundBorderRemove:{
        border: 0,
        background: 0,
    },

    currentNumPage: {
        backgroundColor: '#a0c6b4',
    },

}});

export default useStyles;
