import { createTheme, ThemeProvider } from '@mui/material';
import { hover } from '@testing-library/user-event/dist/hover';
import { styled } from '@mui/material/styles';
import { green } from '@mui/material/colors';
import { Padding } from '@mui/icons-material';

const MiniButtonSelectsTheme = createTheme({
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    // minHeight: '32px',
                    // minWidth: '32px',
                    // maxWidth: '32px',
                    // maxHeight: '32px',
                    minWidth: '2vw !important',
                    minHeight: '2vw !important',
                    width: '100%',
                    backgroundColor: 'white',
                    border: '1px solid #056839',
                    borderRadius: '0',
                    // padding: '0.5w',
                    padding: '0.2vh 0.2vw !important',
                    "&.Mui-disabled": {
                        background: "#89b8a2",
                        borderColor: "#056839",
                    },
                },
            },
        },
    },

});

export default MiniButtonSelectsTheme;
