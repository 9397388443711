/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable react/function-component-definition */
import React from 'react';
import {
    Container,
    Paper,
    FormControl,
    Box,
    Button,
    Stack,
    ThemeProvider,
} from '@mui/material';
import { Outlet, useNavigate } from '@tanstack/react-router';
import GeneralTheme from '../../components/style/GeneralTheme';
import ProjectDetailsInputs from '../../components/createproduct/ProductDetails';
import EnvironmentalInputs from '../../components/createproduct/EnvironmentalInputs';
import { FormProvider } from '../../components/forms/FormContext';
import CreateProductHeader from '../../components/results/components/createproductheader';

const CreateProduct = () => {
    const navigate = useNavigate();

    return (
        <Box>
            <FormProvider>
                <Outlet />
            </FormProvider>
        </Box>
    );
};

export default CreateProduct;
