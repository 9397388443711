import { createTheme } from '@mui/material/styles';

const ExitButton = createTheme({
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                    minWidth: '2vw',
                    minHeight: '2vw',
                    fontSize: '43px',
                    backgroundColor: '#B85959',
                    borderRadius: 0,
                    padding: 0,
                    marginTop: '-1rem',
                    marginRight: '-1rem',
                    color: 'white',
                    '&:hover':{
                        backgroundColor: '#EFC4C4',
                        boxShadow: 'none',
                    },
                },
            },
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    fontSize: '1vw',
                },
            },
        },
    },
    typography: {
        fontFamily: 'Montserrat, sans-serif',
        h2: {
            fontSize: 22,
            fontWeight: 'bold',
        },
        h3: {
            fontSize: 22,
            fontWeight: 'normal',
        },
        h4: {
            fontSize: 18,
            fontWeight: 'normal',
        },
        body1: {
            fontFamily: 'Roboto, sans-serif',
            fontSize: 14,
        },
    },
});

export default ExitButton;
