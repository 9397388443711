/* eslint-disable react/function-component-definition */
/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { useEffect, useState } from 'react';
import {
    Autocomplete,
    Checkbox,
    ClickAwayListener,
    Container,
    InputLabel,
    TextField,
    ThemeProvider,
} from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled, alpha } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import MenuItem from '@mui/material/MenuItem';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import ProcessPopupTheme from '../../../../../style/components/ProcessPopupTheme';
import ExitButton from '../../../../../style/components/ExitButton';

interface SelectSPASocialProcessPopupProps {
  handleProcessPopupClick: any;
}

function SelectSPASocialProcessPopup(props: SelectSPASocialProcessPopupProps) {
    const [openSocialProcessFlow, setOpenSocialProcessFlow] = useState(false);

    const handleSocialProcessFlowClick = () => {
        setOpenSocialProcessFlow((prev) => {return !prev});
    };

    const handleSocialProcessFlowClickOff = () => {
        setOpenSocialProcessFlow(false);
    };

    /* This will be moved to the Theme page soon */

    const BoxListItem = styled(Box)(({ theme }) => {return {
        padding: '5px',
        fontSize: '12px',
        color: '#707070',
        display: 'grid',
        gridTemplateColumns: '1fr auto auto',
        columnGap: '1vw',
        '&:nth-of-type(odd)': {
            backgroundColor: '#F3F3F3',
        },
        '&:nth-of-type(even)': {
            backgroundColor: '#E6E6E6',
        },
    }});

    const ProcessTopWrapper = styled(Box)(({ theme }) => {return {
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        alignItems: 'start',
        justifyItems: 'center',
    }});

    const SelectProcessPopupParent = styled(Box)(({ theme }) => {return {
        width: '-webkit-fill-available',
        height: '-webkit-fill-available',
        margin: '5rem',
    }});

    const SelectProcessPopupContent = styled(Box)(({ theme }) => {return {
        width: '-webkit-fill-available',
        height: '-webkit-fill-available',
        display: 'flex',
        flexDirection: 'column',
        gap: '1vw',
    }});

    const ProcessMiddleWrapper = styled(Box)(({ theme }) => {return {
        display: 'flex',
        alignItems: 'flex-end',
        gap: '2rem',
        padding: '1rem',
    }});

    const ProcessBottomWrapper = styled(Box)(({ theme }) => {return {
        display: 'flex',
        columnGap: '1vw',
        flexDirection: 'column',
        justifyContent: 'center',
    }});

    const ButtonEditProcess = styled(Button)(({ theme }) => {return {
        width: "5vw !important",
        height: "fit-content !important",
        fontSize: "0.7vw !important",
        padding: "2px !important",
        boxShadow: "0 0 0 !important",
        display: 'flex !important',
        alignItems: 'center !important',
        justifyContent: 'center !important',
        lineHeight: '1vw !important',
    }});

    const DropdownWrapperProcess = styled(Box)(({ theme }) => {return {
        display: 'flex',
        alignItems: 'center',
        gap: '1vw',
    }});

    return (
        <ThemeProvider theme={ProcessPopupTheme}>
            <ClickAwayListener onClickAway={handleSocialProcessFlowClickOff}>
                <FormGroup>
                    <SelectProcessPopupParent className='select-process-popup__parent'>
                        <Paper square>
                            <SelectProcessPopupContent className='select-process-popup__content'>
                                <ProcessTopWrapper>
                                    <Typography
                                        className='select-process-popup__title'
                                        component='h2'
                                        variant='h2'
                                    >
                                        Select Process
                                    </Typography>
                                    <ThemeProvider theme={ExitButton}>
                                        <Button
                                            className='social-process-exit-button'
                                            onClick={props.handleProcessPopupClick}
                                            sx={{ marginRight: '0rem', marginTop: '0rem' }}
                                        >
                                            <ClearIcon />
                                        </Button>
                                    </ThemeProvider>
                                </ProcessTopWrapper>
                                <ProcessMiddleWrapper className='dropdowns'>
                                    <DropdownWrapperProcess>
                                        <InputLabel>Country</InputLabel>
                                        <Select
                                            id='category1'
                                            size='small'
                                            label='Age'
                                            sx={{
                                                minWidth: '24rem',
                                            }}
                                        >
                                            <MenuItem value=''>Country Name</MenuItem>
                                            <MenuItem value=''>Country Name</MenuItem>
                                            <MenuItem value=''>Country Name</MenuItem>
                                        </Select>
                                    </DropdownWrapperProcess>
                                    <DropdownWrapperProcess>
                                        <InputLabel>00:Category Name</InputLabel>
                                        <Select
                                            id='category2'
                                            size='small'
                                            label='Age'
                                            sx={{
                                                minWidth: '12rem',
                                            }}
                                        >
                                            <MenuItem value=''>00:Category Name</MenuItem>
                                            <MenuItem value=''>01:Category Name</MenuItem>
                                            <MenuItem value=''>02:Category Name</MenuItem>
                                        </Select>
                                    </DropdownWrapperProcess>
                                    <DropdownWrapperProcess className='product-search-process'>
                                        <Box
                                            className='category-search'
                                            sx={{
                                                width: '13rem',
                                                padding: '2px',
                                                paddingLeft: '4px',
                                                paddingRight: '4px',
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                backgroundColor: '#D9EFDF',
                                                border: '1px solid #707070',
                                                gap: '5px',
                                            }}
                                        >
                                            <Checkbox
                                                sx={{
                                                    margin: '-0.5rem',
                                                }}
                                            />
                                            <Typography>Search by Category</Typography>
                                        </Box>
                                        <Autocomplete
                                            freeSolo
                                            id='processName'
                                            className='process-name__search'
                                            size='small'
                                            sx={{
                                                minWidth: '30rem',
                                                width: '-webkit-fill-available',
                                            }}
                                            options={[
                                                'Process 1',
                                                'Process 2',
                                                'Process 3',
                                                'Process 4',
                                            ]}
                                            renderInput={(params) => {return (
                                                <TextField
                                                    {...params}
                                                    label='Search input'
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        type: 'search',
                                                    }}
                                                />
                                            )}}
                                            aria-placeholder='Search'
                                        />
                                    </DropdownWrapperProcess>
                                </ProcessMiddleWrapper>
                                <ProcessBottomWrapper className='processes'>
                                    <BoxListItem>
                                        <Typography className='select-process' variant='body1'>
                                            Process Name
                                        </Typography>
                                        <ButtonEditProcess variant='contained'>
                                            Select
                                        </ButtonEditProcess>
                                    </BoxListItem>
                                    <BoxListItem>
                                        <Typography className='select-process' variant='body1'>
                                            Process Name
                                        </Typography>
                                        <ButtonEditProcess variant='contained'>
                                            Select
                                        </ButtonEditProcess>
                                    </BoxListItem>
                                </ProcessBottomWrapper>
                            </SelectProcessPopupContent>
                        </Paper>
                    </SelectProcessPopupParent>
                </FormGroup>
            </ClickAwayListener>
        </ThemeProvider>
    );
}
export default SelectSPASocialProcessPopup;
