export const weighingMethods : WeighingMethod[] = [
    {
        name: "Distance-to-target + Risk-based",
        sets: [
            {
                name: "Weighing set A",
                values: [
                    { level: "High", text: "3/6", float: 3.0 / 6.0 },
                    { level: "Some", text: "2/6", float: 2.0 / 6.0 },
                    { level: "Low", text: "1/6", float: 1.0 / 6.0 },
                ],
            },
            {
                name: "Weighing set B",
                values: [
                    { level: "High", text: "2/3", float: 2.0 / 3.0 },
                    { level: "Some", text: "1/3", float: 1.0 / 3.0 },
                    { level: "Low", text: "0", float: 0 },
                ],
            },
        ],
    },
    { name: "Equal Weighing", sets: [] },
    { name: "Based on norms", sets: [] },
    { name: "Stakeholders' judgement", sets: [] },
];


export interface WeighingMethod{
    name:string,
    sets:WeighingSet[],
  }

export interface WeighingSet{
    name:string,
    values:{level:string,text:string, float:number}[]
  }