import { createTheme, ThemeProvider } from '@mui/material';
import { hover } from '@testing-library/user-event/dist/hover';
import { styled } from '@mui/material/styles';
import { green } from '@mui/material/colors';
import { Padding } from '@mui/icons-material';

const ResultsTheme = createTheme({
    components: {
        MuiPaper: {
            defaultProps: {
                elevation: 0,
            },
            styleOverrides: {
                root: {
                    elevation: 0,
                    padding: '10px',
                    boxShadow: '0 3px 6px #89B8A2',
                },
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    body1: {
                        fontSize: 14,
                        fontWeight: 'bold',
                        fontFamily: 'Roboto, sans-serif',
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    width: '158px',
                    height: 'auto',
                    borderRadius: 0,
                    fontWeight: 'bold',
                    fontFamily: '"Montserrat", sans-serif',
                    fontSize: '24px',
                    textTransform: 'capitalize',
                    backgroundColor: '#166d46',
                    '&:hover': {
                        backgroundColor: '#D9EFDF',
                        color: '#004021',
                    },
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: green[800],
                    '&.Mui-checked': {
                        color: green[800],
                    },
                },
            },
        },
        MuiContainer: {
            styleOverrides: {
                root: {
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    gap: '1vw',
                },
            },
        },
    },
    typography: {
        fontFamily: 'Montserrat, sans-serif',
        h2: {
            fontSize: 32,
            fontWeight: 'bold',
        },
        h3: {
            fontSize: 20,
            fontWeight: 'bold',
        },
        body1: {
            fontFamily: 'Roboto, sans-serif',
            fontSize: 14,
        },
    },

});

export default ResultsTheme;
