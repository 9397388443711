import React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { ThemeProvider } from "@mui/material/styles";
import GraphLink from "../../../../style/components/GraphLink";
import GraphSettingsOptions, { ChartColorIdentifiers } from "../GraphSettingsOptions";
import {
    ChartJSData,
    ChartJSOptions,
} from "../../../../../interface/ChartData";


interface GraphSettingProps {
    chartOptions: ChartJSOptions | undefined;
    setApplyGraphSettings: React.Dispatch<React.SetStateAction<((options: ChartJSOptions, 
        chartData: ChartJSData, colorIdentifiers?: ChartColorIdentifiers[]) => {
        options: ChartJSOptions;
        chartData: ChartJSData;
    }) | undefined>>
    triggerChartUpdate: () => void;
    chartData: ChartJSData;
    chartColorType?: string;
    noColors?: boolean;
    graphType?: string;
    labelSettings?: unknown;
    setLabelSettings?: unknown;
    lifecycleStagesNo?: number;
    chartLabels?: number;
    colorIdentifiers?: ChartColorIdentifiers[],
    sdgOn?: boolean;
    productSysOnly?: boolean;
}

function GraphSetting({
    chartOptions,
    setApplyGraphSettings,
    triggerChartUpdate,
    chartData,
    chartColorType,
    graphType,
    labelSettings,
    setLabelSettings,
    chartLabels,
    lifecycleStagesNo,
    colorIdentifiers,
    noColors,
    sdgOn,
    productSysOnly,
}: GraphSettingProps) {
    const [isSettingsVisible, setIsSettingsVisible] = React.useState(false);

    const toggleSettingsVisibility = () => {
        setIsSettingsVisible(!isSettingsVisible);
    };

    // console.log(lifecycleStagesNo);
    // console.log(chartLabels);
    return (
        <ThemeProvider theme={GraphLink}>
            <Box className="graphsettings-button" sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                <Button variant="text" type="button" onClick={toggleSettingsVisibility}>
                    Graph Settings
                </Button>

                <Box
                    sx={{
                        display: isSettingsVisible ? "block" : "none",
                        position: "absolute",
                        zIndex: 100,
                        right: "10px",
                    }}
                >
                    <GraphSettingsOptions
                        chartOptions={chartOptions}
                        graphSettingHandleClick={toggleSettingsVisibility}
                        setApplyGraphSettings={setApplyGraphSettings}
                        triggerChartUpdate={triggerChartUpdate}
                        chartData={chartData}
                        chartColorType={chartColorType}
                        isSettingsVisible={isSettingsVisible}
                        setIsSettingsVisible={setIsSettingsVisible}
                        noColors= {noColors}
                        graphType={graphType}
                        lifecycleStagesNo={lifecycleStagesNo}
                        chartLabels={chartLabels}
                        sdgOn={sdgOn}
                        productSysOnly={productSysOnly}
                    />
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default GraphSetting;
