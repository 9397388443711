/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable global-require */
import React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { ThemeProvider } from "@mui/material/styles";
import MiniButtonSelectsTheme from "../../../../style/components/MiniButtonSelects";
import "../../../../style/components/MiniButtonSelects.css";

function GraphDownload() {
    const DownloadImage = require("../../../../../uploads/images/general-icons/Download-Image.png");
    const DownloadExcel = require("../../../../../uploads/images/general-icons/Download-Excel.png");

    const chartDownloader = () => {
        const chartLink = document.createElement("a");
        chartLink.download = "test.txt";
        chartLink.href = "./test.txt";
        chartLink.click();
    };

    return (
        <ThemeProvider theme={MiniButtonSelectsTheme}>
            <Box className="mini-button-wrapper">
                <Button onClick={chartDownloader} variant="outlined" color="primary">
                    <img
                        src={DownloadImage}
                        alt="Icon of graph download"
                        style={{ 
                            width: '1.2vw',
                            /* height: 100%; */
                            objectFit: 'contain',
                        }}
                    />
                </Button>
                <Button onClick={chartDownloader} variant="outlined" color="primary">
                    <img src={DownloadExcel} alt="Icon of graph download"
                        style={{ 
                            width: '1vw',
                            /* height: 100%; */
                            objectFit: 'contain',
                        }}
                    />
                </Button>
            </Box>
        </ThemeProvider>
    );
}

export default GraphDownload;
