import { makeStyles } from "@mui/styles";
import tableCellClasses from '@mui/material/TableCell/tableCellClasses';

const useStyles = makeStyles(() => {return {

    InputTableCell: {
        width: '-webkit-fill-available',
        fontSize: '0.7vw !important',
        lineHeight: '1.1vw !important',
        padding: '5px !important',
        border: 0,
        borderBottom: '0 !important',
        display: 'flex !important',
        justifyContent: 'center',
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#004021',
            color: 'white',
        },
        [`&.${tableCellClasses.body}`]: {
            // fontSize: 14,
        },
        '@media (max-width: 1680px)': {
            padding: '5px',
            // fontSize: 10,
            // lineHeight: '15px',
        },
    },

}});

export default useStyles;
