import { createTheme, ThemeProvider } from '@mui/material';

const menutheme = createTheme({
    typography: {
        fontFamily: 'Montserrat, sans-serif',
        h2: {
            fontSize: '1vw',
            fontWeight: 'normal',
            textDecoration: 'none',
            // '@media (max-width: 1680px)': {
            //     fontSize: 16,
            // },
        },
        h3: {
            fontSize: '0.95vw',
            fontWeight: 'normal',
            // '@media (max-width: 1680px)': {
            //     fontSize: 14,
            // },
        },
        body1: {
            fontSize: '0.7vw',
            color: '#707070',
            // '@media (max-width: 1680px)': {
            //     fontSize: '1.05vw',
            // },
            '@media (max-width: 1300px)': {
                fontSize: '0.8vw',
            },
        },
    },
    components: {
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: 'black',
                    '@media (max-width: 1680px)': {
                        minWidth: 'auto',
                    },
                },
            },
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    padding: '4px',
                    '@media (max-width: 1680px)': {
                        // display: 'grid',
                        // gridTemplateColumns: 'auto 1fr auto'
                        // gap: '5px',
                    },
                },
            },
        },
        MuiCollapse: {
            styleOverrides: {
                root: {
                    padding: '0',
                },
            },
        },
        MuiListItem: {
            styleOverrides: {
                root: {
                    paddingTop: '0.8vh',
                    paddingBottom: '0.8vh',
                    paddingLeft: '1vw',
                    paddingRight: '1vw',
                },
            },
        },
    },
});

export default menutheme;
