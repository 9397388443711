/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import "./VideoDemonstration.css";

interface VideoDemonstrationProps {
  showVideoDemo: boolean;
  setShowVideoDemo: (show: boolean) => void;
}

const VideoDemonstration: React.FC<VideoDemonstrationProps> = (props) => {
    const [play, setPlay] = useState(false);

    function close_video() {
        setPlay(false);
    }

    return (
        <div className="website-only">
            <div className="demo-video-wrap">
                <React.StrictMode>
                    <div
                        className="video-demo-exit"
                        onClick={() => {
                            props.setShowVideoDemo(!props.showVideoDemo);
                            close_video();
                        }}
                    >
            &times;
                    </div>
                    <iframe
                        width="750"
                        height="500"
                        src="https://www.youtube.com/embed/r0JcShk69X8?si=jxmG7gbYdqnr0dAO"
                        title="YouTube video player"
                        style={{ border: 0 }} // Use style for border instead of deprecated frameborder
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                    ></iframe>
                </React.StrictMode>
            </div>
        </div>
    );
};

export default VideoDemonstration;
