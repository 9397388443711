import React from "react";
import "./PastWebinarVideos.css";

const PastWebinarVideos: React.FC = () => {
    return (
        <div className="website-only">
            <div className="webinar-videos">
                <div className="webinar-video">
                    <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/VRQzHszFfqw"
                        title="YouTube video player"
                        style={{ border: 0 }}  // Inline style to remove the border
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                    <p>
                        Beyond environmental assessment: Life cycle thinking for
                        decision-makers
                    </p>
                </div>
                <div className="webinar-video">
                    <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/Wh1dN0rELso"
                        title="YouTube video player"
                        style={{ border: 0 }}  // Inline style to remove the border
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                    ></iframe>
                    <p>
                        How to measure your decisions’ contribution to the SDGs using LCSA?
                    </p>
                </div>
                <div className="webinar-video">
                    <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/MkRQsqP8mr0"
                        title="YouTube video player"
                        style={{ border: 0 }}  // Inline style to remove the border
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                    ></iframe>
                    <p>Social aspects in decision-making and sustainability</p>
                </div>
            </div>
        </div>
    );
};

export default PastWebinarVideos;
