import { createTheme } from '@mui/material';
import { green } from '@mui/material/colors';

const SDGLinkageTheme = createTheme({
    components: {
        MuiPaper: {
            defaultProps: {
                elevation: 0,
            },
            styleOverrides: {
                root: {
                    elevation: 0,
                    padding: '10px',
                    boxShadow: '0 3px 6px #89B8A2',
                },
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    body1: {
                        fontSize: 14,
                        fontWeight: 'bold',
                        fontFamily: 'Roboto, sans-serif',
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    width: '158px',
                    height: '66px',
                    borderRadius: 0,
                    fontWeight: 'bold',
                    fontFamily: '"Montserrat", sans-serif',
                    fontSize: '24px',
                    textTransform: 'capitalize',
                    backgroundColor: '#166d46',
                    '&:hover': {
                        backgroundColor: '#D9EFDF',
                        color: '#004021',
                    },
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: green[800],
                    '&.Mui-checked': {
                        color: green[800],
                    },
                },
            },
        },
        MuiContainer: {
            styleOverrides: {
                root: {
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    gap: '1vw',
                },
            },
        },
        MuiTable: {
            styleOverrides: {
                root: {
                    width: '100%',
                    minWidth: '50%',
                    overflowY: 'scroll',
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    padding: '2px',
                    minWidth: '100px',
                    textAlign: 'center',
                    border: '2px solid white',
                },
            },
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    minHeight: '100px',
                },
            },
        },
    },
    typography: {
        fontFamily: 'Montserrat, sans-serif',
        h2: {
            fontSize: '1vw',
            fontWeight: 'normal',
            textDecoration: 'none',
            fontFamily: 'Montserrat, sans-serif',
        },
        h3: {
            fontSize: '0.95vw',
            fontWeight: 'normal',
            fontFamily: 'Montserrat, sans-serif',

        },
        h4: {
            fontSize: '0.9vw',
            fontWeight: 'normal',
            fontFamily: 'Montserrat, sans-serif',
        },
        body1: {
            fontSize: '0.7vw',
            color: '#707070',
            display: 'flex',
            alignItems: 'center',
        },
    },

});

export default SDGLinkageTheme;
