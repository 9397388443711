export interface Unit {
  name: string;
  id: string;
}

export interface UnitGroup {
  name: string;
  id: string;
  units: Unit[];
}

export const findUnitUnitGroup = (
    unitId: string,
    unitGroups: UnitGroup[]
): UnitGroup | undefined => {

    const UGToReturn = unitGroups.find((unitGroup) => {
        return unitGroup.units.find((unit) => {
            return unit.id === unitId;
        });
    });
    console.log(UGToReturn);
    return UGToReturn;
};
