import { UnitGroup } from "../interface/Unit";

export function arraysHaveSameValues<T>(arr1: T[], arr2: T[]): boolean {
    if (arr1.length !== arr2.length) {
        return false;
    }

    const sortedArr1 = [...arr1].sort();
    const sortedArr2 = [...arr2].sort();

    for (let i = 0; i < sortedArr1.length; i++) {
        if (sortedArr1[i] !== sortedArr2[i]) {
            return false;
        }
    }

    return true;
}
export const noCaseCompare = (string1 : string, string2 : string) : boolean => {
    return string1.toLowerCase() === string2.toLowerCase();
};

export const getUnitNameFromRefID = (loadedUnitGroups : UnitGroup[] ,refId: string): string => {

    let unitName = '';
    if (loadedUnitGroups.length > 0) {
        loadedUnitGroups.forEach((unitGroup) => {
            unitGroup.units.forEach((unit) => {
                if (refId.includes(unit.id)) {
                    unitName = unit.name;
                    return;

                }
            })
            if (unitName) {
                return;
            }
        })

    }

    return unitName;
}