import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    Box, Divider, FormControl, Paper, ThemeProvider,
} from '@mui/material';
import useStyles from './D1BarChart.styles';
import { Bar } from 'react-chartjs-2';
import { EnvironmentalResult, Product } from '../../../../../interface/Product';
// import Loader from '../../components/loader';
import toast from 'react-hot-toast';
import { Accumulator } from '../../../../../interface/Accumulator';
import ImpactCategoryDropdown from '../../../../dropdowns/ImpactCategoryDropdown';
import ChartOptions from '../../../options/ChartOptions';
import { Link, useParams } from '@tanstack/react-router';
import { initialOptions } from '../../../options/InitialOptionValues';
import deepClone from '../../../../../helper/deepClone';
import { ChartJSData, ChartJSOptions } from '../../../../../interface/ChartData';
import { applyValueType } from '../../../options/util/applyOptions';
import { CreateChartTS } from '../../../util/CreateChartTS';
import EnvChartNavigation from '../../../../dropdowns/BaseOptions/EnvChartNavigation';
import Dropdown from '../../../../dropdowns/Dropdown';
import ChartDownload from '../../../options/components/ChartDownload';
import ProductComparison from '../../../options/components/Comparison';
import GraphSetting from '../../../options/components/GraphSettings';
import SDGLinkage from '../../../options/components/SDGLinkage';
import ResultsTheme from '../../../style/ResultsTheme';
import { QueryKey, useQuery } from '@tanstack/react-query';
import { fetchProduct, fetchUnitGroups } from '../../../../../helper/fetchUtil';
import { palette1, palette2, palette3, palette4, processColors } from "../../../../../helper/colors";
import { useFormContext } from '../../../../forms/FormContext';
import { getUnitNameFromRefID } from '../../../../../helper/utils';
import { ChartColorIdentifiers } from '../../../options/components/GraphSettingsOptions';
import { UnitGroup } from '../../../../../interface/Unit';



interface ChartData {
    productId?: string,
    product: Product,
}
function D1BarChart(props: ChartData) {
    const { productId, product } = props;
    // const product = useQuery<Product>(
    //     {
    //         queryKey:['product', productId],
    //         queryFn:() =>fetchProduct(productId),
    //     },
    // ).data as Product;
    const { formState, setFormState } = useFormContext();
    const classes = useStyles();
    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
    const [chartJSData, setChartJSData] = useState<ChartJSData>({ datasets: [], labels: [] });
    const [chartOptions, setChartOptions] = useState<ChartJSOptions>(initialOptions);
    const [colorIdentifiers, setColorIdentifiers] = useState<ChartColorIdentifiers[]>([]);

    const [selectedValueType, setSelectedValueType] = useState<string>('');
    const [results, setResults] = useState<EnvironmentalResult[]>([]);
    const { data: loadedUnitGroups = [] } = useQuery({
        queryKey: ['unitGroups'],
        queryFn: (() => {

            return fetchUnitGroups();
        }),
        staleTime: Infinity,


    })
    useMemo(() => {
        const calc = product.calculations.find(
            (item) => { return item.calculationType === 'ENVIRONMENTAL' },
        );
        const calcResults = calc?.calculationResults as EnvironmentalResult[];
        setResults((calcResults));
    }, [product]);







    console.log(chartOptions.scales?.x?.title?.display);


    useEffect(() => {

        if (loadedUnitGroups.length === 0)
            return;

        const chartData: ChartJSData = { datasets: [], labels: [] };
        const options: ChartJSOptions = deepClone<ChartJSOptions>(initialOptions);


        setColorIdentifiers([{ label: product.productName }])
        let unit = '';
        chartData.datasets = [
            {
                label: product.productName,
                data: [
                    selectedCategories.reduce((productTotal: Accumulator, category: string) => {
                        if (results) {
                            const productValue: Accumulator = results.reduce((lcsTotal: Accumulator, lcs) => {
                                const lcsValue: Accumulator = lcs.subStages.reduce(
                                    (subStageTotal: Accumulator, subStage) => {
                                        const matchingCategory = subStage.results.find(
                                            (result) => { return result.impactCategory.name === category },
                                        );


                                        if (matchingCategory) {
                                            subStageTotal.total += matchingCategory.values.reduce((total, process) => total += process.value, 0);;
                                            unit = matchingCategory.impactCategory.refUnit
                                        }
                                        return subStageTotal;
                                    },
                                    { total: 0 },
                                );
                                lcsTotal.total += lcsValue.total;
                                return lcsTotal;
                            }, { total: 0 });
                            productTotal.total += productValue.total;
                        }

                        return productTotal;
                    }, { total: 0 }).total,
                ],

                // backgroundColor: lifeCycleColors[index], //colors[index],
                // backgroundColor: currentPalette[index], //colors[index],
                // //backgroundColor: extendedPalette[colorIndex],
                // borderColor: '000000',
                // borderWidth: 0,
            }];
        chartData.labels = [product.productName];
        if (options.scales?.y?.title) {
            options.scales.y.title.text = unit;

        }
        setChartJSData(chartData);
        setChartOptions(options);
    }, [selectedValueType, selectedCategories, loadedUnitGroups]);
    /* COPY lines 98 and 103 to other charts */
    const [applyGraphSettings, setApplyGraphSettings] =
        useState<(options: ChartJSOptions, chartData: ChartJSData, colorIdentifiers?: ChartColorIdentifiers[]) =>
            { options: ChartJSOptions; chartData: ChartJSData; }>();

    const triggerChartUpdate = () => {
        let options = deepClone(chartOptions) || {};
        let chartData = deepClone(chartJSData);
        if (applyGraphSettings) {
            ({ options, chartData } = applyGraphSettings(options, chartData, colorIdentifiers));
        } CreateChartTS(chartData, options);
    };
    useEffect(() => {
        if (chartJSData && chartOptions) {
            triggerChartUpdate();
        }
    }, [chartJSData, chartOptions]);




    return (
        <ThemeProvider theme={ResultsTheme}>
            <Box
                sx={{
                    margin: '0',
                    maxWidth: '100%',
                    maxHeight: '-webkit-fill-available',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1vw',
                }}
            >

                <Box component='div' className='results-wrapper'>
                    <Box component='div' className='results-options'>
                        <Paper square>
                            <Box component='div' className='results-options-top'>
                                <FormControl
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: '3rem',
                                        alignItems: 'stretch',

                                    }}
                                >
                                    <EnvChartNavigation
                                        product={product}
                                        defaultBaseIndex={3}
                                        defaultResultIndex={0}
                                    />
                                    <SDGLinkage
                                        dimension="Environmental"
                                        charttype='Table'
                                        setting="D1"
                                    />
                                    <ProductComparison />
                                </FormControl>
                            </Box>
                        </Paper>
                        <Divider />
                        <Paper square>
                            <Box component='div' className='results-options-bottom'>
                                <Box>
                                    <FormControl
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}
                                        className='bottom-options side-left'
                                    >
                                        <ImpactCategoryDropdown
                                            product={product}
                                            selectedCategories={selectedCategories}
                                            setSelectedCategories={setSelectedCategories}
                                            defaultBaseIndex={3}
                                            defaultResultIndex={0}
                                        />
                                    </FormControl>
                                </Box>
                                <Box>
                                    <FormControl
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            '@media (max-width: 1680px)': {
                                                gap: '1vw',
                                            },
                                        }}
                                        className='bottom-options side-right'
                                    >
                                        <ChartDownload chartData={chartJSData}

                                            chartName={'Midpoint result: Impact category level - Product System'}
                                            chartType='Bar Graph' />
                                        <GraphSetting
                                            chartOptions={chartOptions}
                                            setApplyGraphSettings={setApplyGraphSettings}
                                            triggerChartUpdate={triggerChartUpdate}
                                            colorIdentifiers={colorIdentifiers}
                                            graphType='barindicator'
                                            chartData={chartJSData}
                                            chartColorType='processA1'
                                            productSysOnly
                                        />
                                    </FormControl>
                                </Box>
                            </Box>
                        </Paper>
                    </Box>
                </Box>
                <Box className={classes.chartSec}>
                    <Paper
                        square
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            gap: '1vw',
                        }}
                    >
                        <Box className={classes.chartSec}>
                            <canvas id='graph1Canvas' className='graph-canvas' />
                        </Box>
                    </Paper>
                </Box>
            </Box>
        </ThemeProvider>
    );
}
export default D1BarChart;
