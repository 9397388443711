import React, { FC } from 'react';
import { createRoute } from '@tanstack/react-router';
import { rootRoute } from './RootRoute';

export const generateRoute = (path: string, Component:FC) => {return (
    createRoute({
        getParentRoute: () => {return rootRoute},
        path,
        component: () => {return <Component />},
    })
)};

export const generateIndexRoute = (path: string) => {return (
    createRoute({
        getParentRoute: () => {return rootRoute},
        path,
    })
)};
