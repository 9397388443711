/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable react/function-component-definition */
import React, { useEffect, useState } from "react";
import { Container } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { styled, alpha } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

import GeneralTheme from "../../style/GeneralTheme";
import "./EnvironmentalInputs.css";
import "../../style/components/TableTheme.css";
import { ThemeProvider } from "@emotion/react";
import FormControl from "@mui/material/FormControl";
import EnvInputProcessList from "./components/EnvInputProcessList";
import Dropdown from "../../dropdowns/Dropdown";
import { useNavigate } from "@tanstack/react-router";
import { useFormContext } from "../../forms/FormContext";
import { useForm } from "@tanstack/react-form";
import { EnvironmentalInputsForm } from "../../../interface/Form";
import EnvInputEmissionsList from "./components/EnvInputEmissionsList";
import EnvOutputEmissionsList from "./components/EnvOutputEmissionsList";
import EnvOutputWasteList from "./components/EnvOutputWasteList";
import { LifeCycleStage } from "../../../helper/lifeCycleStage";
import EnvImpactTable from "./components/EnvImpactTable";
import { fetchEnvMethods } from "../../../helper/fetchUtil";
import { SelectMethod } from "../../../interface/ImpactMethod";
import CreateProductHeader from "../../results/components/createproductheader";
import { CreateProductNavButtons } from "../components/CreateProductNavButtons";

interface EnvironmentalModalProps { }

export const EnvironmentalInputs = (props: EnvironmentalModalProps) => {
    const [selectableLCS, setSelectableLCS] = useState<string[]>([]);
    const { formState, setFormState } = useFormContext();


    const [selectableLCSS, setSelectableLCSS] = useState<string[]>([]);

    const [selectableMethodOptions, setSelectableMethodOptions] = useState<
        string[]
    >([]);
    const [selectableMethodValues, setSelectableMethodValues] = useState<
        string[]
    >([]);

    const [selectableNWSetOptions, setSelectableNWSetOptions] = useState<
        string[]
    >([]);
    const [selectableNWSetValues, setSelectableNWSetValues] = useState<string[]>(
        []
    );

    const [selectedLCS, setSelectedLCS] = useState<string>("");
    const [selectedLCSS, setSelectedLCSS] = useState<string>("");
    const [selectedStages, setSelectedStages] = useState<LifeCycleStage[]>([]);

    const [loadedMethods, setLoadedMethods] = useState<SelectMethod[]>([]);
    const [selectedMethodName, setSelectedMethodName] = useState<string>("");

    const form = useForm<EnvironmentalInputsForm>({
        defaultValues: {
            envInputProcesses: formState.envInputProcesses || [],
            envInputEmissions: formState.envInputEmissions || [],
            envOutputEmissions: formState.envOutputEmissions || [],
            envOutputWaste: formState.envOutputWaste || [],
            currentLCS: formState.selectedLCS
                ? (formState.selectedLCS as string[])[0].split(":")[0]
                : "Product Stage",
            currentLCSS: formState.selectedLCS
                ? (formState.selectedLCS as string[])[0].split(":")[1]
                : "Raw Material Supply", // TODO use this way of getting default LCS in social as well
            envAssessmentMethod: (formState.envAssessmentMethod as string) || "None",
            envNWSet: (formState.envNWSet as string) || "",
        },
        onSubmit: ({ value }) => {
            // Do something with form data
            const newFormState = {
                ...formState,
                envInputProcesses: value.envInputProcesses,
                envInputEmissions: value.envInputEmissions,
                envOutputEmissions: value.envOutputEmissions,
                envOutputWaste: value.envOutputWaste,
                envAssessmentMethod: value.envAssessmentMethod,
                envAssessmentMethodName: selectedMethodName,
                envNWSet: value.envNWSet,
            };
            setFormState(newFormState);

        },
    });

    const selectLCSHandler = (lcs: string, _stages?: LifeCycleStage[]) => {
        const stages = _stages || selectedStages;
        const stage = stages.find((_stage) => {
            return _stage.name === lcs;
        });
        if (stage) {
            setSelectableLCSS(
                stage.substages.map((substage) => {
                    return substage.name;
                })
            );
            setSelectedLCSS(stage.substages[0].name);
            form.setFieldValue("currentLCSS", stage.substages[0].name);
        }
    };

    const selectMethodHandler = (method: string, defaultNWSet?: string) => {
        const selectedMethod = loadedMethods.find((_method) => {
            return _method.id === method;
        });
        if (selectedMethod) {
            const nwSet =
                defaultNWSet ||
                (selectedMethod.nwSets.length > 0
                    ? selectedMethod.nwSets[0].id
                    : "noSet");
            setSelectedMethodName(selectedMethod.name);
            if (selectedMethod.nwSets.length > 0) {
                setSelectableNWSetOptions(
                    selectedMethod.nwSets.map((set) => {
                        return set.name;
                    })
                );
                setSelectableNWSetValues(
                    selectedMethod.nwSets.map((set) => {
                        return set.id;
                    })
                );
                form.setFieldValue("envNWSet", nwSet);
            } else {
                setSelectableNWSetOptions(["noSet"]);
                setSelectableNWSetValues(["noSet"]);
                form.setFieldValue("envNWSet", "noSet");
            }
        }
    };

    const navigate = useNavigate();
    const showValues = () => {
        const method: string = form.getFieldValue("envAssessmentMethod");
        const set: string = form.getFieldValue("envNWSet");
    };

    const fetchSelectedLCS = () => {
        if (formState && formState.selectedLCS) {
            const stages: LifeCycleStage[] = [];
            (formState.selectedLCS as string[]).forEach((lcs: string) => {
                const matchingLCS = stages.find((stage) => {
                    return stage.name === lcs.split(":")[0];
                });
                if (matchingLCS) {
                    matchingLCS.substages.push({ name: lcs.split(":")[1] });
                } else {
                    stages.push({
                        name: lcs.split(":")[0],
                        substages: [{ name: lcs.split(":")[1] }],
                    });
                }
            });
            setSelectedStages(stages);
            if (stages[0]) {
                form.setFieldValue("currentLCS", stages[0].name);
                setSelectedLCS(stages[0].name);
                setSelectableLCS(
                    stages.map((stage) => {
                        return stage.name;
                    })
                );
                selectLCSHandler(stages[0].name, stages);
            }
        } else {
            navigate({ to: "../details" })
                .then((val) => { })
                .catch((err) => { });
        }
    };

    useEffect(() => {
        fetchSelectedLCS();
    }, []);

    useEffect(() => {
        fetchEnvMethods()
            .then((methods) => {
                setLoadedMethods(methods);
                setSelectableMethodOptions(
                    methods.map((method) => {
                        return method.name;
                    })
                );
                setSelectableMethodValues(
                    methods.map((method) => {
                        return method.id;
                    })
                );
            })
            .catch(() => { });
    }, []);

    useEffect(() => {
        if (selectableMethodValues.length > 0) {
            const method =
                (formState.envAssessmentMethod as string) || loadedMethods[0].id;
            form.setFieldValue("envAssessmentMethod", method);
            const selectedMethod = loadedMethods.find((item) => {
                return item.id === method;
            });
            selectMethodHandler(method, selectedMethod?.nwSets[0]?.id || "noSet");
        }
    }, [selectableMethodValues]);

    return (
        <Box>
            <form
                // onSubmit={(e) => {
                //     e.preventDefault();
                //     e.stopPropagation();
                //     form
                //         .handleSubmit()
                //         .then(() => {})
                //         .catch((err) => {});
                // }}
                className="inputs-form-env"
            >
                <CreateProductHeader
                    formState={formState}
                    currentPage="environmental"
                />

                <ThemeProvider theme={GeneralTheme}>
                    <FormControl>
                        <Container
                            className="environmental-modal-content"
                            maxWidth={false}
                            sx={{
                                maxWidth: "100%",
                                height: "auto",
                                padding: "0.5vw",
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "1rem",
                                    width: "-webkit-fill-available",
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        gap: "1rem",
                                        width: "-webkit-fill-available",
                                    }}
                                >
                                    <Paper
                                        square
                                        sx={{
                                            display: "grid",
                                            gridTemplateColumns: "auto 1fr",
                                            justifyItems: "center",
                                            width: "-webkit-fill-available",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Typography
                                            component="h2"
                                            variant="h2"
                                            onClick={() => {
                                                console.log(form.state.values);
                                                console.log(formState);
                                            }}
                                        >
                                            Lifecycle Stages
                                        </Typography>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                                gap: "2rem",
                                                width: "-webkit-fill-available",
                                                justifyContent: "center",
                                                marginRight: "12vw",
                                            }}
                                        >
                                            <form.Field
                                                name="currentLCS"
                                                children={(field) => {
                                                    return (
                                                        <Dropdown
                                                            options={selectableLCS}
                                                            onChange={(e) => {
                                                                field.handleChange(e.target.value);
                                                                selectLCSHandler(e.target.value);
                                                                setSelectedLCS(e.target.value);
                                                            }}
                                                            id={field.name}
                                                            className={field.name}
                                                            value={field.state.value}
                                                            onBlur={field.handleBlur}
                                                            label="Lifecycle Stage"
                                                            noDefaults
                                                        />
                                                    );
                                                }}
                                            />
                                            <form.Field
                                                name="currentLCSS"
                                                children={(field) => {
                                                    return (
                                                        <Dropdown
                                                            options={selectableLCSS}
                                                            onChange={(e) => {
                                                                field.handleChange(e.target.value);
                                                                setSelectedLCSS(e.target.value);
                                                            }}
                                                            id={field.name}
                                                            className={field.name}
                                                            value={field.state.value}
                                                            onBlur={field.handleBlur}
                                                            label="Sublifecycle Stage"
                                                            noDefaults
                                                        />
                                                    );
                                                }}
                                            />
                                        </Box>
                                    </Paper>
                                </Box>
                                <Box
                                    sx={{
                                        display: "grid",
                                        gridTemplateColumns: "50% 50%",
                                        gap: "1rem",
                                        justifyContent: "center",
                                        height: "60vh",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            borderRadius: "0",
                                            height: "60vh",
                                            width: "-webkit-fill-available",
                                            padding: "9px",
                                        }}
                                    >
                                        <Paper
                                            square
                                            sx={{
                                                display: "grid",
                                                gridTemplateRows: "auto 1fr 1fr",
                                                gap: "1rem",
                                                width: "-webkit-fill-available",
                                                height: "-webkit-fill-available",
                                                "@media (max-width: 1680px)": {
                                                    // height: '53vh',
                                                },
                                            }}
                                        >
                                            <Typography
                                                component="h2"
                                                variant="h2"
                                                sx={{
                                                    textAlign: "left",
                                                }}
                                            >
                                                Inputs
                                            </Typography>
                                            <EnvInputProcessList
                                                envForm={form}
                                                lifeCycleStage={
                                                    selectedLCS && selectedLCSS
                                                        ? `${selectedLCS}:${selectedLCSS}`
                                                        : ""
                                                }
                                            />
                                            <EnvInputEmissionsList
                                                envForm={form}
                                                lifeCycleStage={
                                                    selectedLCS && selectedLCSS
                                                        ? `${selectedLCS}:${selectedLCSS}`
                                                        : ""
                                                }
                                            />
                                        </Paper>
                                    </Box>
                                    <Box
                                        sx={{
                                            borderRadius: "0",
                                            height: "60vh",
                                            width: "-webkit-fill-available",
                                            padding: "9px",
                                        }}
                                    >
                                        <Paper
                                            square
                                            sx={{
                                                display: "grid",
                                                gridTemplateRows: "auto 1fr 1fr",
                                                gap: "1rem",
                                                width: "-webkit-fill-available",
                                                height: "-webkit-fill-available",
                                                "@media (max-width: 1680px)": {
                                                    // height: '53vh',
                                                },
                                            }}
                                        >
                                            <Typography
                                                component="h2"
                                                variant="h2"
                                                sx={{
                                                    textAlign: "left",
                                                }}
                                            >
                                                Outputs
                                            </Typography>
                                            <EnvOutputEmissionsList
                                                envForm={form}
                                                lifeCycleStage={
                                                    selectedLCS && selectedLCSS
                                                        ? `${selectedLCS}:${selectedLCSS}`
                                                        : ""
                                                }
                                            />
                                            <EnvOutputWasteList
                                                envForm={form}
                                                lifeCycleStage={
                                                    selectedLCS && selectedLCSS
                                                        ? `${selectedLCS}:${selectedLCSS}`
                                                        : ""
                                                }
                                            />
                                        </Paper>
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        gap: "1rem",
                                        width: "-webkit-fill-available",
                                        alignItems: "stretch",
                                    }}
                                >
                                    <Paper
                                        square
                                        sx={{
                                            display: "grid",
                                            gridTemplateColumns: "auto 1fr",
                                            justifyItems: "center",
                                            width: "-webkit-fill-available",
                                            alignItems: "center",
                                            height: "auto",
                                            paddingBottom: "9px",
                                        }}
                                    >
                                        <Typography component="h2" variant="h2">
                                            Impact Assessment Method
                                        </Typography>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifySelf: "center",
                                                alignItems: "stretch",
                                                gap: "2rem",
                                                // '@media (max-width: 1680px)': {
                                                //   gap: '1vw',
                                                // },
                                            }}
                                        >
                                            <form.Field
                                                name="envAssessmentMethod"
                                                children={(field) => {
                                                    return (
                                                        <Dropdown
                                                            options={selectableMethodOptions}
                                                            values={selectableMethodValues}
                                                            onChange={(e) => {
                                                                console.log("ENV METHOD ONCHANGE");
                                                                field.handleChange(e.target.value);
                                                                selectMethodHandler(e.target.value);
                                                            }}
                                                            id={field.name}
                                                            className={field.name}
                                                            value={field.state.value}
                                                            onBlur={field.handleBlur}
                                                            label="Assessment"
                                                        />
                                                    );
                                                }}
                                            />
                                            <form.Field
                                                name="envNWSet"
                                                children={(field) => {
                                                    return (
                                                        <Dropdown
                                                            options={selectableNWSetOptions}
                                                            values={selectableNWSetValues}
                                                            onChange={(e) => {
                                                                field.handleChange(e.target.value);
                                                            }}
                                                            id={field.name}
                                                            className={field.name}
                                                            value={field.state.value}
                                                            onBlur={field.handleBlur}
                                                            label="Normalization"
                                                        />
                                                    );
                                                }}
                                            />
                                            <EnvImpactTable />

                                            {/* <Dropdown
                                                label='Assessment' options={[]} value={undefined} />

                                                    <Dropdown
                                                label='Normalization' options={[]} value={undefined}                                                    /> */}
                                        </Box>
                                    </Paper>
                                    <CreateProductNavButtons
                                        form={form}
                                        formState={formState}
                                        currentPage="environmental"
                                    />
                                </Box>
                            </Box>
                        </Container>
                    </FormControl>
                </ThemeProvider>
            </form>
        </Box>
    );
};

export default EnvironmentalInputs;
