/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Box, ThemeProvider } from "@mui/material";
import { useNavigate, useRouterState } from "@tanstack/react-router";

import HeaderDimensionsTheme from "../../../style/HeaderDimensionsTheme";
import "../../../style/HeaderDimensionsTheme.css";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import { FormState } from "../../../forms/FormContext";

function CreateProductHeader({
    formState,
    currentPage,
}: {
  formState: FormState;
  currentPage: string;
}) {
    const navigate = useNavigate();
    const [value, setValue] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    const router = useRouterState();

    useEffect(() => {
        switch (currentPage) {
        case "details":
            setValue(0);
            break;
        case "environmental":
            setValue(1);
            break;
        case "social":
            setValue(2);
            break;
        case "economic":
            setValue(3);
            break;
        default:
            setValue(0);
        }
    }, [currentPage]);

    return (
        <ThemeProvider theme={HeaderDimensionsTheme}>
            <Box className="header-wrapper">
                <TabContext value={value}>
                    <TabList
                        onChange={handleChange}
                        aria-label=""
                        TabIndicatorProps={{
                            style: { display: "none" },
                        }}
                    >
                        {/* <Tab label='Edit Data' value='edit-data' /> */}
                        <Tab
                            label="Assessment Details"
                            value={0}
                            onClick={() => {
                                navigate({ to: "../details" })
                                    .then((val) => {})
                                    .catch((err) => {});
                            }}
                        />
                        <Tab
                            label="Environmental"
                            value={1}
                            onClick={() => {
                                navigate({ to: "../environmental" })
                                    .then((val) => {})
                                    .catch((err) => {});
                            }}
                            style={
                                currentPage === "details" || !formState.envDim
                                    ? { display: "none" }
                                    : {}
                            }
                            // hidden={}
                        />
                        <Tab
                            label="Social"
                            value={2}
                            onClick={() => {
                                navigate({ to: "../social" })
                                    .then((val) => {})
                                    .catch((err) => {});
                            }}
                            style={
                                currentPage === "details" || !formState.socialDim
                                    ? { display: "none" }
                                    : {}
                            }
                        />
                        <Tab
                            label="Economic"
                            value={3}
                            onClick={() => {
                                navigate({ to: "../economic" })
                                    .then((val) => {})
                                    .catch((err) => {});
                            }}
                            style={
                                currentPage === "details" || !formState.ecoDim
                                    ? { display: "none" }
                                    : {}
                            }
                        />
                        {/* <Tab icon={<BookIcon />} label='Product Report' value='product-report' /> */}
                    </TabList>
                    {/* <TabPanel value='1'>Environmental</TabPanel>
            <TabPanel value='2'>Social</TabPanel>
            <TabPanel value='3'>Economic</TabPanel> */}
                    <Button
                        sx={{
                            backgroundColor: "#056939",
                            color: "white",
                            minWidth: "8vw",
                            display: "flex",
                            gap: "5px",
                            minHeight: '3.5vh',
                            fontSize: '0.9vw',
                            height: 'auto',
                        }}
                    >
                        <SaveIcon
                            sx={{
                                width: "1.1vw",
                                fontSize: '0.9vw',
                            }}
                        />
            Save
                    </Button>
                </TabContext>
            </Box>
        </ThemeProvider>
    );
}

export default CreateProductHeader;
