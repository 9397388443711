import React, { useState } from 'react';
import {
    Box,
} from '@mui/material';

function TestComponent() {
    return (
        <Box>
            <h2>TESTING</h2>
        </Box>
    );
}

export default TestComponent;
