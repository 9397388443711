import React from "react";
import "../Homepage.css";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import PastWebinarVideos from "../../components/PastWebinarVideos";

const ResourcesWebinars: React.FC = () => {
    return (
        <div className="website-only">
            <Header />

            <div className="header-container">
                <div className="header-transparent-layer"> </div>
                <img
                    src={require("../../images/headers/pexels-alesia-kozik-6004828.jpg")}
                    alt="Cloudy and dark waterfall"
                />

                <h1>Resources</h1>
            </div>
            <div className="box-container">
                <h2>Webinars</h2>
                <p className="p-hide">
          TOSSA Sustainability Services is pleased to announce a series of free
          sustainability and life cycle assessment webinars. These webinars
          cover a wide range of topics (i.e. triple-bottom line thinking, life
          cycle assessment, life cycle sustainability assessment, social life
          cycle assessment and ESG) and are delivered by TOSSA team leaders in
          their respective fields. Each webinar is held from 11AM-12 PM EST with
          a presentation followed by a Q&A Session.
                </p>
            </div>
            <div className="box-container">
                <h2>Upcoming Webinar</h2>
                <p className="upcoming-webinar">
                    <b>Webinar #:</b> TBA
                </p>
                <p>
                    <b>When:</b> TBA
                </p>
                {/*<a href="#" className="button-link">Register here!</a>*/}
                <button className="button-link" disabled>
          Registration unavailable
                </button>
                <div className="webinar-wrap">
                    <h3>Next webinar has yet to be announced. Stay tuned!</h3>
                    {/*<h3>About this webinar</h3>
<p>
</p>
<p>
</p>*/}
                </div>
            </div>
            <div className="box-container">
                <h2>Past webinars</h2>
                <PastWebinarVideos />
            </div>

            <Footer />
        </div>
    );
};

export default ResourcesWebinars;
