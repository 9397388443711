import {
    useState, useCallback, useRef, useEffect, useContext,
} from 'react';

interface HttpError {
    message: string,
    code: number
}

interface UseHttpClientReturn {
    isLoading: boolean,
    error: HttpError | undefined | null,
    sendRequest: <T>(url: string, method: string, body: string | undefined, headers: any) => Promise<T>, // function that returns Promise<Response>
    clearError: () => void, // function that returns void
}

export const useHttpClient = (): UseHttpClientReturn => {
    const [error, setError] = useState<HttpError>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const activeHttpRequests = useRef<AbortController[]>([]);
    const sendRequest = useCallback(async (url: string, method = 'GET', body = '', headers = {
    }) => {
        setIsLoading(true);
        const httpAbortCtrl = new AbortController(); // logic run when a request is sent for a component that is no longer on screen
        activeHttpRequests.current.push(httpAbortCtrl);
        try {
            const response = await fetch(url, {
                method,
                body: (method !== 'GET' ? body : null),
                headers,
                signal: httpAbortCtrl.signal,
            });
            const responseData = await response.json();
            if (!response.ok) {
                throw new Error(responseData.message);
            }

            activeHttpRequests.current = activeHttpRequests.current.filter(
                (reqCtrl) => {return reqCtrl !== httpAbortCtrl},
            );

            setIsLoading(false);
            return responseData;
        } catch (err: any) {
            setError(err.message);
            setIsLoading(false);
            throw err;
        }
    }, []);
    const clearError = () => {
        setError(undefined);
    };

    const returnObj: UseHttpClientReturn = {
        isLoading,
        error,
        sendRequest,
        clearError,
    };
    return returnObj;
}