import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import useStyles from './B4BarPSILCA.styles';
import deepClone from '../../../../../../helper/deepClone';
import { ChartJSData, ChartJSOptions } from '../../../../../../interface/ChartData';
import { Product, PSILCAResult } from '../../../../../../interface/Product';
import { initialOptions } from '../../../../options/InitialOptionValues';
import { applyValueType } from '../../../../options/util/applyOptions';
import { CreateChartTS } from '../../../../util/CreateChartTS';
import { ThemeProvider } from '@emotion/react';
import { FormControl } from '@mui/base';
import { Paper, Divider } from '@mui/material';
import ChartDownload from '../../../../options/components/ChartDownload';
import GraphSetting from '../../../../options/components/GraphSettings';
import GraphType from '../../../../options/components/GraphTypeOptions';
import ResultsTheme from '../../../../style/ResultsTheme';
import { palette1, palette2, palette3, palette4, socialProcessColors, treemapRGB } from '../../../../../../helper/colors';
import { useFormContext } from '../../../../../forms/FormContext';
import { ChartColorIdentifiers } from '../../../../options/components/GraphSettingsOptions';



interface ChartData {
    product: Product,
    selectedLCS: string,
    selectedLCSS: string,
    selectedValueType: string,
}

export 

function B4BarPSILCA(props: ChartData) {
    const { formState, setFormState } = useFormContext();
    const classes = useStyles();
    const {
        product, selectedLCS, selectedLCSS, selectedValueType,
    } = props;
    const [chartJSData, setChartJSData] = useState<ChartJSData>({ datasets: [], labels: [] });
    const [chartOptions, setChartOptions] = useState<ChartJSOptions>(initialOptions);
    const [colorIdentifiers, setColorIdentifiers] = useState<ChartColorIdentifiers[]>([]);
    const [results, setResults] = useState<PSILCAResult[]>([]);
    const [graphColors, setGraphColors] = useState<ColorIndexData[]>(formState.processColorIndexes || []);

    useMemo(() => {
        const calc = product.calculations.find(
            (item) => {return item.calculationType === 'SOCIAL-PSILCA'},
        );
        const calcResults = calc?.calculationResults as PSILCAResult[];
        setResults((calcResults));
    }, [product]);








    useEffect(() => {
        const chartData: ChartJSData = { datasets: [], labels: [] };
        const options: ChartJSOptions = deepClone<ChartJSOptions>(initialOptions);
        const chartColorIdentifiers : ChartColorIdentifiers[] = [];

        if (options.scales && options.scales.y && options.scales.y.title) {
            options.scales.y.title.text = "Medium Risk Hours";
        }

        let processes: string[] = [];
        const matchingLCS = results[0].subcategories[0].indicators[0]
            .indicatorResults.find((item) => {return item.lcsName === `${selectedLCS}:${selectedLCSS}`});
        if (matchingLCS) {
            processes = matchingLCS.processResults.processes.map((item) => {return item.process});
        }

        // const totalProcesses = matchingLCS.processResults[0].values.length;

        chartData.datasets = processes.map((process, index) => {
            
      

            chartColorIdentifiers.push({label:process})
                
            return {
                label: process,
                data: results.map((stakeholder) => {return stakeholder.subcategories
                    .map((subcategory) => {return subcategory.indicators
                        .map((indicator) => {return indicator.indicatorResults
                            .find(
                                (lcs) => {return lcs.lcsName === `${selectedLCS}:${selectedLCSS}`},
                            )?.processResults.processes.find(
                                (item) => {return item.process === process},
                            )?.processTotalSum})
                        .reduce((a: number, b) => {return a + (b || 0)}, 0)})
                    .reduce((a, b) => {return a + b}, 0)}),
                // backgroundColor:
                //     currentPalette[
                //     getGraphColorIndex({
                //         label: process,
                //         // identifier: lifeCycle.lcsName,
                //     })
                //     ], //colors[index], //random color from array
                // borderColor: "000000",
                //backgroundColor: extendedPalette[colorIndex],
            }});

        chartData.labels = results.map(
            (stakeholder) => {return stakeholder.stakeholder},
        );
        console.log(chartData);

        setChartJSData(chartData);
        setChartOptions(options);
        setColorIdentifiers(chartColorIdentifiers);    
    }, [selectedValueType, selectedLCS, selectedLCSS,  results]);

    const [applyGraphSettings, setApplyGraphSettings] =
        useState<(options: ChartJSOptions, chartData: ChartJSData, colorIdentifiers?: ChartColorIdentifiers[]) => 
            { options: ChartJSOptions; chartData: ChartJSData; }>();
    const triggerChartUpdate = () => {
        let options = deepClone(chartOptions) || {};
        let chartData = deepClone(chartJSData);
        const applyOptionReturn = applyValueType(selectedValueType, chartData, options, false, 'pie');
        options = applyOptionReturn.options;
        chartData = applyOptionReturn.chartData;
        if(applyGraphSettings){
            ({ options, chartData } = applyGraphSettings(options, chartData, colorIdentifiers));
        }
        CreateChartTS(chartData, options);
    };

    useEffect(() => {
        if (chartJSData && chartOptions) {
            triggerChartUpdate();
        }
    }, [chartJSData, chartOptions]);
    return (
        // <Box className={classes.chartSec}>
        //     <Paper
        //         square
        //         sx={{
        //             display: 'flex',
        //             justifyContent: 'center',
        //         }}
        //     >
        <Box className={classes.chartSec}>
            <div className='settings-two-graph'>
                <ChartDownload chartData = {chartJSData} 
                    chartName={'Stakeholder level result - Process'} chartType='Bar Graph' />
                <GraphSetting
                    chartOptions={chartOptions}
                    setApplyGraphSettings={setApplyGraphSettings}
                    triggerChartUpdate={triggerChartUpdate}
                    colorIdentifiers={colorIdentifiers}
                    chartData = {chartJSData}
                    chartColorType = 'process'
                    graphType='barindicator'  
                />
            </div>
            <canvas id='graph1Canvas' className='graph-canvas' />
        </Box>
        //     </Paper>
        // </Box>

    );
}

export default B4BarPSILCA;
