import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { useState, useMemo, useEffect, useCallback } from 'react';
import deepClone from '../../../../../helper/deepClone';
import {
    ChartJSData,
    ChartJSOptions,
} from '../../../../../interface/ChartData';
import {
    Product,
    EnvironmentalResult,
    EnvResultSubstage,
} from '../../../../../interface/Product';
import Dropdown from '../../../../dropdowns/Dropdown';
import ImpactCategoryDropdown from '../../../../dropdowns/ImpactCategoryDropdown';
import { initialOptions } from '../../../options/InitialOptionValues';
import { applyValueType } from '../../../options/util/applyOptions';
import { CreateChartTS } from '../../../util/CreateChartTS';
import useStyles from './D2BarChart.styles';
import { Link } from '@tanstack/react-router';
import ResultsTheme from '../../../style/ResultsTheme';
import ChartDownload from '../../../options/components/ChartDownload';
import ProductComparison from '../../../options/components/Comparison';
import GraphSetting from '../../../options/components/GraphSettings';
import SDGLinkage from '../../../options/components/SDGLinkage';
import EnvChartNavigation from '../../../../dropdowns/BaseOptions/EnvChartNavigation';
import { lifeCycleColors, palette1, palette2, palette3, palette4 } from '../../../../../helper/colors';
import { useFormContext } from '../../../../forms/FormContext';
import { fetchUnitGroups } from '../../../../../helper/fetchUtil';
import { getUnitNameFromRefID } from '../../../../../helper/utils';
import { UnitGroup } from '../../../../../interface/Unit';
import { ChartColorIdentifiers } from '../../../options/components/GraphSettingsOptions';
import { useQuery } from '@tanstack/react-query';



interface ChartData {
    product: Product;
}

function D2BarChart(props: ChartData) {
    const classes = useStyles();
    const { formState, setFormState } = useFormContext();
    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
    const { product } = props;
    const [chartJSData, setChartJSData] = useState<ChartJSData>({ datasets: [], labels: [] });
    const [chartOptions, setChartOptions] = useState<ChartJSOptions>(initialOptions);
    const [colorIdentifiers, setColorIdentifiers] = useState<ChartColorIdentifiers[]>([]);
    const [selectedValueType, setSelectedValueType] = useState<string>('');
    const [results, setResults] = useState<EnvironmentalResult[]>([]);

    let unit: string; // TEMP
    const { data: loadedUnitGroups = [] } = useQuery({
        queryKey: ['unitGroups'],
        queryFn: (() => {

            return fetchUnitGroups();
        }),
        staleTime: Infinity,


    })

    useMemo(() => {
        const calc = product.calculations.find(
            (item) => { return item.calculationType === 'ENVIRONMENTAL' },
        );
        const calcResults = calc?.calculationResults as EnvironmentalResult[];
        setResults((calcResults));
    
    }, [product]);

    const getData = (lcs: EnvironmentalResult, lcss: EnvResultSubstage, category: string): number => {
        if (results) {
            const matchingStage = results.find((item) => { return item.name === lcs.name }) as EnvironmentalResult;
            if (matchingStage) {
                const matchingSubstage = matchingStage.subStages.find(
                    (item) => { return item.name === lcss.name },
                );

                if (matchingSubstage) {
                    const selectedCategory = selectedCategories[0];
                    let matchingCategory;
                    if (category) {
                        matchingCategory = matchingSubstage.results.find(
                            (item) => { return item.impactCategory.name === category },
                        );
                    } else {
                        matchingCategory = matchingSubstage.results.find(
                            (item) => { return item.impactCategory.name === selectedCategory },
                        );
                    }

                    if (matchingCategory) {
                        if (!unit) {
                            unit = matchingCategory.impactCategory.refUnit
                        }
                        return matchingCategory.values.reduce((total , process) => total += process.value , 0);
                    }
                }
            }
        }
        return 0;
    };



    useEffect(() => {
        if (loadedUnitGroups.length === 0)
            return;


        const chartData: ChartJSData = { datasets: [], labels: [] };
        const options: ChartJSOptions = deepClone<ChartJSOptions>(initialOptions);
        const chartColorIdentifiers : ChartColorIdentifiers[] = [];


        chartData.datasets = results.map((lcs, index) => {
            chartColorIdentifiers.push({label:lcs.name});
            return {
                label: lcs.name,
                data:
                    // categoryDropdownType === 'Radio'
                    //     ? [
                    //         lcs.subStages.reduce((total, lcss) => {
                    //             return total + getData(lcs, lcss);
                    //         }, 0),
                    //     ]
                    //     :
                    selectedCategories.map((category) => {
                        return lcs.subStages.reduce((total, lcss) => {
                            const totalVal: number = total + getData(lcs, lcss, category);

                            return totalVal;
                        }, 0)
                    }),

                // backgroundColor: lifeCycleColors[colorIndex++], //colors[index],
                // backgroundColor: currentPaletteLifeCycle[colorIndex++], //colors[index],
                // borderColor: '000000',
                // //backgroundColor: extendedPalette[index],

            }
        });
        if (options.scales?.y?.title) {
            options.scales.y.title.text = unit;

        }
        chartData.labels = selectedCategories;
        setChartJSData(chartData);
        setChartOptions(options);
        setColorIdentifiers(chartColorIdentifiers);    
    }, [selectedValueType, selectedCategories,loadedUnitGroups]);


    const [applyGraphSettings, setApplyGraphSettings] =
        useState<(options: ChartJSOptions, chartData: ChartJSData, colorIdentifiers?: ChartColorIdentifiers[]) => 
            { options: ChartJSOptions; chartData: ChartJSData; }>();
    const triggerChartUpdate = () => {
        let options = deepClone(chartOptions) || {};
        let chartData = deepClone(chartJSData);
        const applyOptionReturn = applyValueType(selectedValueType, chartData, options);
        options = applyOptionReturn.options;
        chartData = applyOptionReturn.chartData;
        if (applyGraphSettings) {
            ({ options, chartData } = applyGraphSettings(options, chartData, colorIdentifiers));
        } CreateChartTS(chartData, options);
    };

    useEffect(() => {

        if (chartJSData && chartOptions) {
            triggerChartUpdate();
        }
    }, [chartJSData, chartOptions]);

    return (
        <ThemeProvider theme={ResultsTheme}>
            <Box
                sx={{
                    margin: '0',
                    maxWidth: '100%',
                    maxHeight: '-webkit-fill-available',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1vw',
                }}
            >

                <Box component='div' className='results-wrapper'>
                    <Box component='div' className='results-options'>
                        <Paper square>
                            <Box component='div' className='results-options-top'>
                                <FormControl
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: '3rem',
                                        alignItems: 'stretch',

                                    }}
                                >
                                    <EnvChartNavigation
                                        product={product}
                                        defaultBaseIndex={3}
                                        defaultResultIndex={1}
                                    />
                                    <ProductComparison />
                                </FormControl>
                            </Box>
                        </Paper>
                        <Divider />
                        <Paper square>
                            <Box component='div' className='results-options-bottom'>
                                <Box>
                                    <FormControl
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}
                                        className='bottom-options side-left'
                                    >
                                        <Dropdown
                                            options={['Raw Values', '% Per Lifecycle Stage']}
                                            valueSetter={setSelectedValueType}
                                            value={selectedValueType}

                                        />
                                        <ImpactCategoryDropdown
                                            product={product}
                                            selectedCategories={selectedCategories}
                                            setSelectedCategories={setSelectedCategories}
                                            defaultBaseIndex={3}
                                            defaultResultIndex={1}
                                        />
                                    </FormControl>
                                </Box>
                                <Box>
                                    <FormControl
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            '@media (max-width: 1680px)': {
                                                gap: '1vw',
                                            },
                                        }}
                                        className='bottom-options side-right'
                                    >
                                        <ChartDownload chartData={chartJSData} 
                                            chartName={'Midpoint result: Impact category level - Lifecycle'} chartType='Bar Graph' />
                                        <GraphSetting
                                            chartOptions={chartOptions}
                                            setApplyGraphSettings={setApplyGraphSettings}
                                            triggerChartUpdate={triggerChartUpdate}
                                            chartData={chartJSData}
                                            chartColorType='lcs'
                                            graphType='barindicator'
                                        />
                                    </FormControl>
                                </Box>
                            </Box>
                        </Paper>
                    </Box>
                </Box>
                <Box className={classes.chartSec}>
                    <Paper
                        square
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <Box className={classes.chartSec}>
                            <canvas id='graph1Canvas' className='graph-canvas' />
                        </Box>
                    </Paper>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default D2BarChart;
