import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    TableHeaderCellStyled: {
        minWidth: '6vw !important',
        color: 'white !important',
        backgroundColor: '#056939',
        lineHeight: '0.5rem !important',
        fontSize: '0.7vw !important',
        // '@media (max-width: 1680px)': {
        //     fontSize: '12px',
        // },
    },
    
    TableBodyCellStyled: {
        minWidth: '100px',
        padding: '15px',
        backgroundColor: '#D9EFDF',
        fontSize: '0.7vw !important',
        height: '5vw !important',
        // '@media (max-width: 1680px)': {
        //     fontSize: '12px',
        //     padding: '10px',
        // },
    },
    
    SDGLegendWrap: {
        display: 'flex',
        flexDirection: 'row',
        gap: '1vw',
    },
    
    SDGLegendBox: {
        display: 'flex',
        flexDirection: 'row',
        gap: '5px',
    },
    
    TableHeaderCellImg: {
        padding: '1px',
        lineHeight: '0.5rem !important',
        border: 0,
        minWidth: '5vw !important',
    },
}));

export default useStyles;
