/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/function-component-definition */
/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { useEffect, useState } from 'react';
import {
    ClickAwayListener,
    Container,
    InputLabel,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    tableCellClasses,
} from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled, alpha } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import MenuItem from '@mui/material/MenuItem';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { ThemeProvider } from '@emotion/react';
import TableTheme from '../../../../style/components/TableTheme';
import TabContext from '@mui/lab/TabContext';
import ExitButton from '../../../../style/components/ExitButton';
import ClearIcon from '@mui/icons-material/Clear';
import { Process } from '../../../../../interface/Process';
import { fetchProcessFlowDetails } from '../../../../../helper/fetchUtil';

interface EnvironmentalProcessFlowDetailsProps {
    processId: string | undefined
    handleEnvProcessFlowClick: () => void
    handleEnvProcessFlowClickOff: () => void
}

function EnvironmentalProcessFlowDetails(
    { processId, handleEnvProcessFlowClick, handleEnvProcessFlowClickOff }: EnvironmentalProcessFlowDetailsProps,
) {
    const [detailsFlowValue, setDetailsFlowValue] = React.useState('details-process');
    const [loadedProcess, setLoadedProcess] = useState<Process>();
    const handleDetailsTabChange = (
        event: React.SyntheticEvent,
        newFlowValue: string,
    ) => {
        setDetailsFlowValue(newFlowValue);
    };

    function createData(
        name: string,
        description: string,
        costvalue: number,
        category: string,
        location: string,

    ) {
        return {
            name, description, costvalue, category, location,
        };
    }


    useEffect(() => {
        if (processId) {
            fetchProcessFlowDetails(processId).then((val) => setLoadedProcess(val));

        }
    }, [processId])

    function createFlowData(
        flowname: string,
        resultvalue: number,
        categoryflow: string,
        flowtype: string,
        unit: string,

    ) {
        return {
            flowname, resultvalue, categoryflow, flowtype, unit,
        };
    }



    const flowtablerows = [
        createFlowData('Process 1', 0.0, 'Something', 'Something', 'Something'),
        createFlowData('Process 2', 0.0, 'Something', 'Something', 'Something'),
        createFlowData('Process 3', 0.0, 'Something', 'Something', 'Something'),
        createFlowData('Process 4', 0.0, 'Something', 'Something', 'Something'),
        createFlowData('Process 5', 0.0, 'Something', 'Something', 'Something'),
    ];

    const InputTableCell = styled(TableCell)(({ theme }) => {
        return {
            [`&.${tableCellClasses.head}`]: {
                backgroundColor: '#004021',
                color: 'white',
                fontSize: '0.9vw',
                padding: '1vw',
            },
            [`&.${tableCellClasses.body}`]: {
                fontSize: '0.7vw',
                padding: '1vw',
            },
        }
    });

    const InputTableRow = styled(TableRow)(({ theme }) => {
        return {
            padding: '5px',
            fontSize: '0.8vw',
            color: '#707070',
            '&:nth-of-type(odd)': {
                backgroundColor: '#F3F3F3',
            },
            '&:nth-of-type(even)': {
                backgroundColor: '#E6E6E6',
            },
        }
    });

    const ProcessTopWrapper = styled(Box)(({ theme }) => {
        return {
            display: 'flex',
            flexDirection: 'row-reverse',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            height: '5vh',
        }
    });

    const SelectProcessPopupParent = styled(Box)(({ theme }) => {
        return {
            width: '-webkit-fill-available',
            height: '-webkit-fill-available',
            margin: '5rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }
    });

    const SelectProcessPopupContent = styled(Box)(({ theme }) => {
        return {
            width: "-webkit-fill-available",
            height: "-webkit-fill-available",
            display: "flex",
            flexDirection: "column",
            gap: "1vw",
        }
    });

    const TabEditProcess = styled(Tab)(({ theme }) => {
        return {
            width: '5vw',
            height: 'fit-content',
            fontSize: '0.8vw',
            padding: '2px',
            boxShadow: '0 0 0',
            textTransform: 'capitalize',
            fontWeight: 'bold',
            fontFamily: '"Montserrat", sans-serif',
            backgroundColor: '#166d46',
            color: 'white',
            margin: '0.5vw',
            minHeight: '3vh',
            minWidth: '5vw',
            '&:hover': {
                backgroundColor: '#D9EFDF',
                color: '#004021',
                transition: '0.3s',
            },
            '&.MuiTab-root.Mui-selected': {
                backgroundColor: '#D9EFDF',
                color: '#004021',
                transition: '0.3s',
            },
        }
    });

    const TabListStyled = styled(TabList)(({ theme }) => {
        return {
            margin: '1rem',
            marginBottom: '0',
            display: 'flex',
            gap: '1vw',
            '& .MuiTabs-indicator': { display: 'none' },
        }
    });

    return (
        <ThemeProvider theme={TableTheme}>
            {/* <ClickAwayListener onClickAway={handleEnvProcessFlowClickOff}> */}
            {loadedProcess && <FormGroup>
                <SelectProcessPopupParent
                    className='select-process-popup__parent'
                    sx={{
                        position: 'fixed',
                    }}
                >
                    <Paper square>
                        <SelectProcessPopupContent className='select-process-popup__content'>
                            <TabContext value={detailsFlowValue}>
                                <Box>
                                    <ProcessTopWrapper>
                                        <ThemeProvider theme={ExitButton}>
                                            <Button
                                                onClick={handleEnvProcessFlowClick}
                                                sx={{ marginRight: '0rem', marginTop: '0rem' }}
                                            >
                                                <ClearIcon />
                                            </Button>
                                        </ThemeProvider>
                                        <TabListStyled
                                            onChange={handleDetailsTabChange}
                                            aria-label='Process and Flow Tabs'
                                        >
                                            <TabEditProcess label='Process' value='details-process' />
                                            <TabEditProcess label='Flow' value='details-flow' />
                                        </TabListStyled>
                                    </ProcessTopWrapper>
                                </Box>
                                <TabPanel value='details-process' sx={{ paddingTop: 0 }}>
                                    <Typography
                                        className='select-process-popup__title'
                                        component='h2'
                                        variant='h2'
                                        sx={{ marginTop: '1rem', marginBottom: '1rem' }}
                                    >
                                        Process Details
                                    </Typography>
                                    <TableContainer component={Box}>
                                        <Table sx={{ minWidth: '40vw' }} aria-label='simple table'>
                                            <TableHead>
                                                <TableRow>
                                                    <InputTableCell>Process Name</InputTableCell>
                                                    <InputTableCell align='center'>
                                                        Description
                                                    </InputTableCell>
                                                    <InputTableCell align='center'>
                                                        Cost Value
                                                    </InputTableCell>
                                                    <InputTableCell align='center'>
                                                        Category
                                                    </InputTableCell>
                                                    <InputTableCell align='center'>
                                                        Location
                                                    </InputTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <InputTableRow key={loadedProcess.id}>
                                                    <InputTableCell component='th' scope='row'>
                                                        {loadedProcess.name}
                                                    </InputTableCell>
                                                    <InputTableCell align='center'>
                                                        {loadedProcess.description}
                                                    </InputTableCell>
                                                    <InputTableCell align='center'>
                                                        {loadedProcess.costValue}
                                                    </InputTableCell>
                                                    <InputTableCell align='center'>
                                                    </InputTableCell>
                                                    <InputTableCell align='center'>
                                                    </InputTableCell>
                                                </InputTableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </TabPanel>
                                <TabPanel value='details-flow' sx={{ paddingTop: 0 }}>
                                    <Typography
                                        className='select-process-popup__title'
                                        component='h2'
                                        variant='h2'
                                        sx={{ marginTop: '1rem', marginBottom: '1rem' }}
                                    >
                                        Flow Details
                                    </Typography>
                                    <TableContainer component={Box}>
                                        <Table sx={{ minWidth: '40vw' }} aria-label='flow table'>
                                            <TableHead>
                                                <TableRow>
                                                    <InputTableCell>Flow Name</InputTableCell>
                                                    <InputTableCell align='center'>
                                                        Description
                                                    </InputTableCell>
                                                    <InputTableCell align='center'>
                                                        Cost Value
                                                    </InputTableCell>
                                                    <InputTableCell align='center'>
                                                        Category
                                                    </InputTableCell>
                                                    <InputTableCell align='center'>
                                                        Location
                                                    </InputTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {flowtablerows.map((row) => {
                                                    return (
                                                        <InputTableRow key={row.flowname}>
                                                            <InputTableCell component='th' scope='row'>
                                                                {row.flowname}
                                                            </InputTableCell>
                                                            <InputTableCell align='center'>
                                                                {row.categoryflow}
                                                            </InputTableCell>
                                                            <InputTableCell align='center'>
                                                                {row.flowtype}
                                                            </InputTableCell>
                                                            <InputTableCell align='center'>
                                                                {row.resultvalue}
                                                            </InputTableCell>
                                                            <InputTableCell align='center'>
                                                                {row.unit}
                                                            </InputTableCell>
                                                        </InputTableRow>
                                                    )
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </TabPanel>
                            </TabContext>
                        </SelectProcessPopupContent>
                    </Paper>
                </SelectProcessPopupParent>
            </FormGroup>}
            {/* </ClickAwayListener> */}
        </ThemeProvider>
    );
}

export default EnvironmentalProcessFlowDetails;
