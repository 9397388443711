/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable react/function-component-definition */
import React, { useEffect, useState } from 'react';
import { Container } from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled, alpha } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import GeneralTheme from '../../../style/GeneralTheme';
import { ThemeProvider } from '@emotion/react';
import FormControl from '@mui/material/FormControl';
import SelectSocialProcessPopup from '../components/SelectSocialProcessPopup';
import SocialInputProcessList from './SocialInputProcessList';
import Dropdown from '../../../dropdowns/Dropdown';
import { useNavigate, useRouterState } from '@tanstack/react-router';
import { FormApi, useForm } from '@tanstack/react-form';
import { SocialInputsForm } from '../../../../interface/Form';
import { useFormContext } from '../../../forms/FormContext';
import SocialInfoPopup from '../components/SocialInfoPopup';
import useStyles from '../SocialInputs.styles';
import { SocialInputsLCSDropdowns } from '../components/SocialInputsLCSDropdowns';
import { CreateProductNavButtons } from '../../components/CreateProductNavButtons';
import CreateProductHeader from '../../../results/components/createproductheader';

const SocialPSILCAInputs = ({editProductId} : {editProductId?:string}) => {
    const navigate = useNavigate();

    const classes = useStyles();
    const { formState, setFormState } = useFormContext();
    const [selectedLCS, setSelectedLCS] = useState<string>(formState.currentLCS || '');
    const [selectedLCSS, setSelectedLCSS] = useState<string>(formState.currentLCSS || '');
    const route = useRouterState();
    const isEditing = route.location.pathname.includes("edit");
    const [enableInputs, setEnableInputs] = useState<boolean>(isEditing || formState.socialChanged);   
    const [isFirstSelection, setIsFirstSelection] = useState(true);
    
    const form = useForm<SocialInputsForm>({
        defaultValues: {
            socialInputProcesses: formState.socialInputProcesses ||[] ,
            currentLCS: formState.currentLCS ? (formState.currentLCS).split(':')[0] : '',
            currentLCSS: formState.currentLCSS ? (formState.currentLCSS).split(':')[1] : '', // TODO use this way of getting default LCS in social as well
            // currentLCS: formState.currentLCS ? (formState.currentLCS).split(':')[0] : 'Product Stage',
            // currentLCSS: formState.currentLCSS ? (formState.currentLCSS).split(':')[1] : 'Raw Material Supply', // TODO use this way of getting default LCS in social as well
            socialAssessmentMethod: 'PSILCA',
            socialSPAWeighingMethod: 'Distance-to-target + Risk-based',
            socialSPAWeighingSet: '',
            useSPALCS:false,
        },
        onSubmit: ({ value }) => {
            // Do something with form data
            console.log(value);
            setFormState({
                ...formState,
                socialInputProcesses: value.socialInputProcesses,
                socialAssessmentMethod: value.socialAssessmentMethod,
                socialSPAWeighingMethod: '',
                socialSPAWeighingSet: '',
                currentLCS:selectedLCS,
                currentLCSS:selectedLCSS,
                socialChanged:!!selectedLCS && !!selectedLCSS,
            });

        },
    });

    useEffect(() => {
        // setFormState({ ...formState, socialAssessmentMethod: form.state.values.socialAssessmentMethod })
    }, [form.state.values.socialAssessmentMethod])

    // useEffect(() => {
    //     if (selectedLCS && selectedLCSS) {
    //         setEnableInputs(true);
    //     } else {
    //         setEnableInputs(false);
    //     }
    // }, [selectedLCS, selectedLCSS]);

    return (
        <ThemeProvider theme={GeneralTheme}>
            <CreateProductHeader formState={formState} currentPage='social'
                form = {form} />
            <Box
                sx={{
                    display: 'flex',
                    gap: '1vw',
                    width: '-webkit-fill-available',
                }}
                className='psilca-box'
            >
   
                <SocialInputsLCSDropdowns setSelectedLCS={setSelectedLCS} setSelectedLCSS={setSelectedLCSS}
                    enableInputs={enableInputs}
                    setEnableInputs={setEnableInputs}
                    isFirstSelection={isFirstSelection}
                    setIsFirstSelection={setIsFirstSelection}
                    
                />
    
            </Box>
            <Box
                sx={{
                    width: '-webkit-fill-available',
                    height: '56vh',
                    gap: '1vw',
                    opacity: enableInputs ? "1" : "0.5",
                    pointerEvents: enableInputs ? "auto" : "none",
                }}
            >
                <Paper
                    sx={{
                        height: '100%',
                    }}
                >

                    <Typography component="h2" variant="h2" onClick={(() => {
                        console.log(form.state.values);
                    })}>
                    Inputs
                    </Typography>
                    <SocialInputProcessList socialForm={form} lifeCycleStage={`${selectedLCS}:${selectedLCSS}`}
                    />
                </Paper>
            </Box>
            <CreateProductNavButtons 
                form={form}
                formState={formState}
                currentPage='social'
                editProductId = {editProductId}
            />

        </ThemeProvider>
    );
};

export default SocialPSILCAInputs;
