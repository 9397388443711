import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import useStyles from './A4BarSPA.styles';
import deepClone from '../../../../../../helper/deepClone';
import { ChartJSData, ChartJSOptions } from '../../../../../../interface/ChartData';
import { Product, PSILCAResult, SPAResult } from '../../../../../../interface/Product';
import PSILCAChartNavigation from '../../../../../dropdowns/BaseOptions/PSILCAChartNavigation';
import ResultsTheme from '../../../../style/ResultsTheme';
import { initialOptions } from '../../../../options/InitialOptionValues';
import ChartDownload from '../../../../options/components/ChartDownload';
import ProductComparison from '../../../../options/components/Comparison';
import GraphSetting from '../../../../options/components/GraphSettings';
import { CreateChartTS } from '../../../../util/CreateChartTS';
import { useFormContext } from '../../../../../forms/FormContext';
import { WeighingMethod, WeighingSet, applyWeight, getWeighingSetValue, weighingMethods } from '../../../../../../helper/weighingMethod';
import Dropdown from '../../../../../dropdowns/Dropdown';
import SPAChartNavigation from '../../../../../dropdowns/BaseOptions/SPAChartNavigation';
import { applyValueType } from '../../../../options/util/applyOptions';
import { useCallback, useEffect, useMemo, useState } from 'react';
import LCSDropdowns from '../../../../../dropdowns/LCSDropdowns';
import GraphType from '../../../../options/components/GraphTypeOptions';
import { ChartColorIdentifiers } from '../../../../options/components/GraphSettingsOptions';



interface ChartData {
    product: Product;
}

function A4BarSPA(props: ChartData) {
    const { formState, setFormState } = useFormContext();
    const classes = useStyles();
    const { product } = props;
    const [chartJSData, setChartJSData] = useState<ChartJSData>({ datasets: [], labels: [] });
    const [chartOptions, setChartOptions] = useState<ChartJSOptions>(initialOptions);
    const [colorIdentifiers, setColorIdentifiers] = useState<ChartColorIdentifiers[]>([]);
    const [currentWeighingSet, setCurrentWeighingSet] = useState<WeighingSet>();
    const [selectedValueType, setSelectedValueType] = useState('');
    const [selectedLCS, setSelectedLCS] = useState<string>('');
    const [selectedLCSS, setSelectedLCSS] = useState<string>('');
    const [selectedProcess, setSelectedProcess] = useState('');
    const [results, setResults] = useState<SPAResult>();

    useMemo(() => {
        const calc = product.calculations.find(
            (item) => { return item.calculationType === 'SOCIAL-SPA' },
        );
        const calcResults = calc?.calculationResults as unknown as SPAResult;
        console.log(calc);
        const weighingMethod = weighingMethods.find((method) => method.name === product.socialData.weighingMethod.name);
        const weighingSet = weighingMethod?.sets.find((set) => set.name === product.socialData.weighingMethod.set);
        setCurrentWeighingSet(weighingSet);
        setResults((calcResults));
    }, [product]);








    useEffect(() => {
        console.log(results);

        if (!results || !currentWeighingSet)
            return;


        const chartData: ChartJSData = { datasets: [], labels: [] };
        const options: ChartJSOptions = deepClone<ChartJSOptions>(initialOptions);
        const chartColorIdentifiers : ChartColorIdentifiers[] = [];

        if (options.scales && options.scales.y && options.scales.y.title) {
            options.scales.y.title.text = "Weighted Score";
        }
        
        const spaLifeCycle = results.lifeCycleResults.find((lcs) =>lcs.lcsName === `${selectedLCS}:${selectedLCSS}`);

        //Regular bar graph SPA process
        if (!spaLifeCycle) {
            if(!results.SPAProcess)
                return;

            const lcsName = results.SPAProcess.lifeCycleStage;
            chartData.labels = [lcsName];
            chartColorIdentifiers.push({label:results.SPAProcess.name});

            chartData.datasets.push({
                label: results.SPAProcess.name,
                data: [
                    results.stakeholderResults

                        .map((stakeholder) => {
                            const val = stakeholder.SPAPriorities.reduce(
                                (val3, val4) => {
                                    return val3 + applyWeight(val4, "sam", currentWeighingSet);
                                },
                                0
                            );

                            return val;
                        })
                        .reduce((val1, val2) => {
                            return val1 + val2;
                        }, 0),
                ],
                // backgroundColor: selectedPalette[
                //     0
                //     // getGraphColorIndex({
                //     //     label: results.SPAProcess.name,
                //     //     identifier: lifeCycle,
                //     // })
                // ], //"#1B5D82", //currentPaletteLifeCycle,//random color from array
                borderColor: "000000",
            });
        } else {
            if (!(chartData.labels.length > 0)) {
                chartData.labels = [spaLifeCycle.lcsName];
            }

            //TODO Rewrite so that each bar can have diff colors
            chartData.datasets = spaLifeCycle.processResults.map(
                (process,index) => {
                    chartColorIdentifiers.push({label:process.processName});

                    return {
                        label: process.processName,
                        data: [
                            process.PSILCAPriorities.reduce((val1, val2) => {
                                let num = 0;
                                switch (val2.score) {
                                case 4:
                                    num =
                                            val2.score *
                                            getWeighingSetValue(currentWeighingSet, "High");
                                    break;
                                case 3:
                                    num =
                                            val2.score *
                                            getWeighingSetValue(currentWeighingSet, "Some");
                                    break;
                                case 2:
                                    num =
                                            val2.score *
                                            getWeighingSetValue(currentWeighingSet, "Low");
                                    break;
                                case 1:
                                    num =
                                            val2.score *
                                            getWeighingSetValue(currentWeighingSet, "Low");
                                    break;
                                default:
                                }

                                return val1 + num;
                            }, 0),
                        ],

                        // backgroundColor: selectedPalette[
                        //     index
                        // //     getGraphColorIndex({
                        // //     label: process.processName,
                        // //     identifier: lifeCycle.lcsName,
                        // // })
                        // ], //"#1B5D82",//currentPaletteLifeCycle,//colors[Math.floor(Math.random() * 4)], //random color from array
                        borderColor: "000000",
                    };
                }
            );
        }





        setChartJSData(chartData);
        setChartOptions(options);
        setColorIdentifiers(chartColorIdentifiers);

    }, [results, product,  currentWeighingSet, selectedValueType, selectedLCS, selectedLCSS]);
    const [applyGraphSettings, setApplyGraphSettings] =
        useState<(options: ChartJSOptions, chartData: ChartJSData, colorIdentifiers?: ChartColorIdentifiers[]) => 
            { options: ChartJSOptions; chartData: ChartJSData; }>();
    const triggerChartUpdate = () => {
        if (!chartJSData.datasets || !chartOptions) {
            return; // Exit early if datasets or options are not properly initialized
        }

        let options = deepClone(chartOptions);
        let chartData = deepClone(chartJSData);
        const applyOptionReturn = applyValueType(selectedValueType, chartData, options);
        options = applyOptionReturn.options;
        chartData = applyOptionReturn.chartData;
        if (applyGraphSettings) {
            ({ options, chartData } = applyGraphSettings(options, chartData, colorIdentifiers));

        }

        CreateChartTS(chartData, options);
    };
    useEffect(() => {
        if (chartJSData && chartOptions) {
            triggerChartUpdate();
        }
    }, [chartJSData, chartOptions]);

    return (
        <ThemeProvider theme={ResultsTheme}>
            <Box
                sx={{
                    margin: '0',
                    maxWidth: '100%',
                    maxHeight: '-webkit-fill-available',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1vw',
                }}
            >
                <Box className='temp-box' />
                <Box component='div' className='results-wrapper'>
                    <Box component='div' className='results-options'>
                        <Paper square>
                            <Box component='div' className='results-options-top'>
                                <FormControl
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: '3rem',
                                        alignItems: 'stretch',

                                    }}
                                >
                                    <SPAChartNavigation product={product} defaultBaseIndex={0} defaultResultIndex={3} />
                                    <ProductComparison />
                                </FormControl>
                            </Box>
                        </Paper>
                        <Divider />
                        <Paper square>
                            <Box component='div' className='results-options-bottom'>
                                <Box>
                                    <FormControl
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}
                                        className='bottom-options side-left'
                                    >
                                        <GraphType
                                            type='bar'
                                            dimension='SPA'
                                            chart='A4'
                                        />
                                        <LCSDropdowns
                                            product={product}
                                            selectedLCS={selectedLCS}
                                            setSelectedLCS={setSelectedLCS}
                                            selectedLCSS={selectedLCSS}
                                            setSelectedLCSS={setSelectedLCSS}
                                            dimension="Social"
                                        />
                                        <Dropdown
                                            options={['Raw Values', '% Per Process']}
                                            valueSetter={setSelectedValueType}
                                            value={selectedValueType}
                                            label='Value Type'
                                        />

                                    </FormControl>
                                </Box>
                                <Box>
                                    <FormControl
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            '@media (max-width: 1680px)': {
                                                gap: '1vw',
                                            },
                                        }}
                                        className='bottom-options side-right'
                                    >

                                        <ChartDownload chartData={chartJSData}
                                            
                                            chartName={'Total weighted result: all stakeholders - Product System'}
                                            chartType='Bar Graph' />
                                        <GraphSetting
                                            chartOptions={chartOptions}
                                            setApplyGraphSettings={setApplyGraphSettings}
                                            triggerChartUpdate={triggerChartUpdate}
                                            colorIdentifiers={colorIdentifiers}
                                            chartData={chartJSData}
                                            chartColorType='process'
                                            graphType='barindicator'

                                        />

                                    </FormControl>
                                </Box>
                            </Box>
                        </Paper>
                    </Box>
                </Box>
                <Box className={classes.chartSec}>
                    <Paper
                        square
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Box className={classes.chartSec}>
                            <canvas id='graph1Canvas' className='graph-canvas' />
                        </Box>
                    </Paper>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default A4BarSPA;
