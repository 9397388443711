import React, {
    useEffect,
    useState,
    Dispatch,
    SetStateAction,
    SyntheticEvent,
} from 'react';
import { styled } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import NativeSelect from '@mui/material/NativeSelect';
import InputBase from '@mui/material/InputBase';
import { Product, EnvironmentalResult } from '../../../../interface/Product';
import Dropdown from '../../Dropdown';
import ThemeProvider from '@mui/styles/ThemeProvider';
import DropdownTheme from '../../../style/components/DropdownTheme';
import { useLocation } from 'react-router-dom';
import { useNavigate } from '@tanstack/react-router';
import { useProductStore } from '../../../../hooks/useProductStore';
import useStyles from './EnvChartNavigation.styles';

interface Props {
    product: Product;
    defaultBaseIndex?: number,
    defaultResultIndex?: number,
    sdgactive?: boolean,
}
function EnvChartNavigation(props: Props) {
    const { product, defaultBaseIndex, defaultResultIndex, sdgactive } = props;

    const classes = useStyles();
    const navigate = useNavigate();

    const damageTypeMethods = [
        "dfa238d9-380e-4654-bac5-2349940377d7",
        "82867c73-0329-4ad2-ba76-ef1e3e0cb088",
        "e42bd878-10fa-45ad-b6c6-c3f97a680d0c",
        "a5dca51e-157a-462a-ab47-3a0cebef1c64",
        "2a7b9cdf-a6bf-49fe-92cd-f560b7a7f57e",
        // "3b7b6f24-9809-3b46-a0be-2f09892ab9eb",
        //"692b670c-b4e9-3ded-b7b0-a84b00d39bdc",
        //"183b58c6-44f8-353b-9c75-1cc3d8fe0274",
        //3.9 here
        "6c39afae-415d-43bc-916a-ef905168ad07",
        "43863f1a-9db5-4cb7-86dc-4a7597db90b8",
        "20381352-0a3f-4c19-909e-f752b07e1f2c",
        "f3a6e7c1-fc82-4100-80be-95081dc763b2",
        "ab057a4e-a404-41c0-9ecd-94efe1ca163a",
        "c483e790-9ce5-4475-b764-e9907deef612",

        "dfa238d9-380e-4654-bac5-2349940377d7",
        "82867c73-0329-4ad2-ba76-ef1e3e0cb088",
        "e42bd878-10fa-45ad-b6c6-c3f97a680d0c",
        "a5dca51e-157a-462a-ab47-3a0cebef1c64",
        "2a7b9cdf-a6bf-49fe-92cd-f560b7a7f57e",
    ];

    const noSingleIndexMethods = [
        "6c39afae-415d-43bc-916a-ef905168ad07",
        "43863f1a-9db5-4cb7-86dc-4a7597db90b8",
        "20381352-0a3f-4c19-909e-f752b07e1f2c",
        "f3a6e7c1-fc82-4100-80be-95081dc763b2",
        "ab057a4e-a404-41c0-9ecd-94efe1ca163a",
        "c483e790-9ce5-4475-b764-e9907deef612",
    ];

    const [disabledBaseLayers, setDisabledBaseLayers] = useState<string[]>([]);

    const baseOptions = [
        'Total weighted result: Single index',
        'Total weighted result: Damage type level',
        'Normalized results: impact category level',
        'Midpoint result: Impact category level',
        'Inventory result: Elementary flow level',
    ];

    const resultsOptions = [
        'Product System',
        'LifeCycle Stage',
        'LifeCycle Substage',
        'Process',
    ];

    const [selectedBaseOption, setSelectedBaseOption] = useState<string>(baseOptions[defaultBaseIndex]);
    const [selectedResultsLayer, setSelectedResultsLayer] = useState<string>(resultsOptions[defaultResultIndex]);
    const FormWrapper = styled(FormControl)(({ theme }) => {
        return {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            gap: '1vw',
        }
    });



    useEffect(() => {

        if (!product)
            return;

        const envCalcs = product.calculations.find((item) => item.calculationType === "ENVIRONMENTAL");

        const hasDamageType = !!damageTypeMethods.find((item) => item === product.environmentalData?.assessmentMethod?.id);
        const hasSingleIndex = !noSingleIndexMethods.find((item) => item === product.environmentalData?.assessmentMethod?.id);
        const hasNormalization = envCalcs?.assessmentMethod && envCalcs?.nwSet && envCalcs?.nwSet != "noSet";

        console.log(product);
        console.log(damageTypeMethods);
        if (envCalcs) {
            const disabled: string[] = [];
            if (!hasDamageType || !hasSingleIndex) {
                disabled.push('Total weighted result: Single index')
            }
            if (!hasDamageType) {
                disabled.push('Total weighted result: Damage type level');

            }

            if (!hasNormalization) {
                disabled.push('Normalized results: Impact category level');

            }
            setDisabledBaseLayers(disabled);
        }


    }, [product])

    const navigationHandler = async (baseOption: string, resultsLayer: string) => {
        console.log(baseOption + " " + resultsLayer);
        if (baseOption && resultsLayer) {

            if (sdgactive === true) {
                if (
                    baseOption === 'Total weighted result: Single index'
                ) {
                    switch (resultsLayer) {
                    case 'Product System':
                        await navigate({ to: '../../../../Environmental/A1/Bar' });
                        break;
                    case 'LifeCycle Stage':
                        await navigate({ to: '../../../../Environmental/A2/Bar' });
                        break;
                    case 'LifeCycle Substage':
                        await navigate({ to: '../../../../Environmental/A3/Bar' });
                        break;
                    case 'Process':
                        await navigate({ to: '../../../../Environmental/A4/Bar' });
                        break;
                    default:
                        break;
                    }
                }
                else if (
                    baseOption === 'Total weighted result: Damage type level'


                ) {
                    switch (resultsLayer) {
                    case 'Product System':
                        await navigate({ to: '../../../../Environmental/B1/Bar' });
                        break;
                    case 'LifeCycle Stage':
                        await navigate({ to: '../../../../Environmental/B2/Bar' });
                        break;
                    case 'LifeCycle Substage':
                        await navigate({ to: '../../../../Environmental/B3/Bar' });
                        break;
                    case 'Process':
                        await navigate({ to: '../../../../Environmental/B4/Bar' });
                        break;
                    default:
                        break;
                    }
                }
                else if (
                    baseOption === 'Midpoint result: Impact category level'
                ) {
                    switch (resultsLayer) {
                    case 'Product System':
                        await navigate({ to: '../../../../Environmental/D1/Bar' });
                        break;
                    case 'LifeCycle Stage':
                        await navigate({ to: '../../../../Environmental/D2/Bar' });
                        break;
                    case 'LifeCycle Substage':
                        await navigate({ to: '../../../../Environmental/D3/Bar' });
                        break;
                    case 'Process':
                        await navigate({ to: '../../../../Environmental/D4/Bar' });
                        break;
                    default:
                        break;
                    }
                } else if (
                    baseOption === 'Normalized results: impact category level'
                ) {
                    switch (resultsLayer) {
                    case 'Product System':
                        await navigate({ to: '../../../../Environmental/C1/Bar' });
                        break;
                    case 'LifeCycle Stage':
                        await navigate({ to: '../../../../Environmental/C2/Bar' });
                        break;
                    case 'LifeCycle Substage':
                        await navigate({ to: '../../../../Environmental/C3/Bar' });
                        break;
                    case 'Process':
                        await navigate({ to: '../../../../Environmental/C4/Bar' });
                        break;
                    default:
                        break;
                    }
                } else if (
                    baseOption === 'Inventory result: Elementary flow level'
                ) {
                    switch (resultsLayer) {
                    case 'Product System':
                        await navigate({ to: '../../../../Environmental/E1/Bar' });
                        break;
                    case 'LifeCycle Stage':
                        await navigate({ to: '../../../../Environmental/E2/Bar' });
                        break;
                    case 'LifeCycle Substage':
                        await navigate({ to: '../../../../Environmental/E3/Bar' });
                        break;
                    case 'Process':
                        await navigate({ to: '../../../../Environmental/E4/Bar' });
                        break;
                    default:
                        break;
                    }
                }
            } else {
                if (
                    baseOption === 'Total weighted result: Single index'
                ) {
                    switch (resultsLayer) {
                    case 'Product System':
                        await navigate({ to: '../../../Environmental/A1/Bar' });
                        break;
                    case 'LifeCycle Stage':
                        await navigate({ to: '../../../Environmental/A2/Bar' });
                        break;
                    case 'LifeCycle Substage':
                        await navigate({ to: '../../../Environmental/A3/Bar' });
                        break;
                    case 'Process':
                        await navigate({ to: '../../../Environmental/A4/Bar' });
                        break;
                    default:
                        break;
                    }
                }
                else if (
                    baseOption === 'Total weighted result: Damage type level'


                ) {
                    switch (resultsLayer) {
                    case 'Product System':
                        await navigate({ to: '../../../Environmental/B1/Bar' });
                        break;
                    case 'LifeCycle Stage':
                        await navigate({ to: '../../../Environmental/B2/Bar' });
                        break;
                    case 'LifeCycle Substage':
                        await navigate({ to: '../../../Environmental/B3/Bar' });
                        break;
                    case 'Process':
                        await navigate({ to: '../../../Environmental/B4/Bar' });
                        break;
                    default:
                        break;
                    }
                }
                else if (
                    baseOption === 'Midpoint result: Impact category level'
                ) {
                    switch (resultsLayer) {
                    case 'Product System':
                        await navigate({ to: '../../../Environmental/D1/Bar' });
                        break;
                    case 'LifeCycle Stage':
                        await navigate({ to: '../../../Environmental/D2/Bar' });
                        break;
                    case 'LifeCycle Substage':
                        await navigate({ to: '../../../Environmental/D3/Bar' });
                        break;
                    case 'Process':
                        await navigate({ to: '../../../Environmental/D4/Bar' });
                        break;
                    default:
                        break;
                    }
                } else if (
                    baseOption === 'Normalized results: impact category level'
                ) {
                    switch (resultsLayer) {
                    case 'Product System':
                        await navigate({ to: '../../../Environmental/C1/Bar' });
                        break;
                    case 'LifeCycle Stage':
                        await navigate({ to: '../../../Environmental/C2/Bar' });
                        break;
                    case 'LifeCycle Substage':
                        await navigate({ to: '../../../Environmental/C3/Bar' });
                        break;
                    case 'Process':
                        await navigate({ to: '../../../Environmental/C4/Bar' });
                        break;
                    default:
                        break;
                    }
                } else if (
                    baseOption === 'Inventory result: Elementary flow level'
                ) {
                    switch (resultsLayer) {
                    case 'Product System':
                        await navigate({ to: '../../../Environmental/E1/Bar' });
                        break;
                    case 'LifeCycle Stage':
                        await navigate({ to: '../../../Environmental/E2/Bar' });
                        break;
                    case 'LifeCycle Substage':
                        await navigate({ to: '../../../Environmental/E3/Bar' });
                        break;
                    case 'Process':
                        await navigate({ to: '../../../Environmental/E4/Bar' });
                        break;
                    default:
                        break;
                    }
                }
            }
        }
    };

    const baseOptionChangeHandler = (value: string) => {
        setSelectedBaseOption(value);
        navigationHandler(value, selectedResultsLayer);
    };
    const resultsLayerChangeHandler = (value: string) => {
        setSelectedResultsLayer(value);
        navigationHandler(selectedBaseOption, value);
    };


    return (
        <ThemeProvider theme={DropdownTheme}>
            <FormWrapper size='small'>
                <>
                    {baseOptions.length > 0 && (
                        <Dropdown
                            options={baseOptions}
                            valueSetter={baseOptionChangeHandler}
                            value={selectedBaseOption}
                            label='Base Layer'
                            noDefaults
                            disabledValues={disabledBaseLayers}
                        />
                    )}
                    {resultsOptions.length > 0 && (
                        <Dropdown
                            options={resultsOptions}
                            valueSetter={resultsLayerChangeHandler}
                            value={selectedResultsLayer}
                            label='Results Layer'
                            noDefaults

                        />
                    )}
                </>
            </FormWrapper>
        </ThemeProvider>
    );
}

export default EnvChartNavigation;
