import { ThemeProvider, createTheme } from '@mui/material/styles';
import { hover } from '@testing-library/user-event/dist/hover';
import { green } from '@mui/material/colors';
import { Padding } from '@mui/icons-material';

const GraphSettingsTheme = createTheme({
    components: {
        MuiSelect: {
            styleOverrides: {
                root: {
                    minHeight: '28px',
                    padding: '0',
                    borderRadius: '0',
                    minWidth: '200px',
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    minHeight: '28px',
                    padding: '0',
                    borderRadius: '0',
                    width: '200px',
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    minHeight: '28px',
                    padding: '0',
                    borderRadius: '0',
                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    display: 'flex',
                    flexDirection: 'row',
                    minWidth: '200px',
                },
            },
        },
        MuiPopper: {
            styleOverrides: {
                root: {
                    width: '460px',
                    height: '51rem',
                    backgroundColor: 'white',
                    borderLeft: '2px solid #32463E',
                    padding: '1rem',
                    position: 'fixed',
                    right: 0,
                    marginTop: '5rem',
                    zIndex: 100,
                    overflowY: 'scroll',
                },
            },
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    borderStyle: 'solid',
                    borderColor: '#004021',
                    borderBottomWidth: 'medium',
                    width: '50%',
                    marginLeft: '-1rem',
                },
            },
        },
        MuiStack: {
            styleOverrides: {
                root: {
                    margin: '1rem',
                },
            },
        },
    },
    typography: {
        // h2: {
        //     fontSize: '1.4vw',
        //     fontWeight: 'bold',
        // },
        // h3: {
        //     fontSize: '1.2vw',
        //     fontWeight: 'normal',
        // },
        // h4: {
        //     fontSize: '1vw',
        //     fontWeight: 'normal',
        // },
        // body1: {
        //     fontFamily: 'Roboto, sans-serif',
        //     fontSize: '0.7vw',
        // },
        h2: {
            fontFamily: 'Montserrat, sans-serif',
            fontSize: '1vw',
            fontWeight: 'normal',
            textDecoration: 'none',

        },
        h3: {
            fontFamily: 'Montserrat, sans-serif',
            fontSize: '0.95vw',
            fontWeight: 'normal',

        },
        h4: {
            fontFamily: 'Montserrat, sans-serif',
            fontSize: '0.9vw',
            fontWeight: 'normal',
        },
        body1: {
            fontSize: '0.7vw',
            color: '#707070',
            fontFamily: 'Roboto, sans-serif',

        },
    },
});

export default GraphSettingsTheme;
