import React, { useCallback, useEffect, useMemo, useState } from 'react';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';
import useStyles from './C4SDGBarPSILCA.styles';
import { ThemeProvider } from '@emotion/react';
import Dropdown from '../../../../../dropdowns/Dropdown';
import deepClone from '../../../../../../helper/deepClone';
import { ChartJSData, ChartJSOptions } from '../../../../../../interface/ChartData';
import { Product, PSILCAResult } from '../../../../../../interface/Product';
import LCSDropdowns from '../../../../../dropdowns/LCSDropdowns';
import ResultsTheme from '../../../../style/ResultsTheme';
import { initialOptions } from '../../../../options/InitialOptionValues';
import ChartDownload from '../../../../options/components/ChartDownload';
import ProductComparison from '../../../../options/components/Comparison';
import GraphSetting from '../../../../options/components/GraphSettings';
import SDGLinkage from '../../../../options/components/SDGLinkage';
import { ApplyOptionReturn, applyValueType } from '../../../../options/util/applyOptions';
import { CreateChartTS } from '../../../../util/CreateChartTS';
import PSILCAChartNavigation from '../../../../../dropdowns/BaseOptions/PSILCAChartNavigation';
import { palette1, palette2, palette3, palette4, processColors, sdgColor, socialProcessColors } from '../../../../../../helper/colors';
import { useFormContext } from '../../../../../forms/FormContext';
import { fetchSocialSDGs } from '../../../../../../helper/fetchUtil';
import { SDG } from '../../../../../../interface/SDG';
import SDGChecklist from '../../../../components/SDGChecklist';
import Typography from '@mui/material/Typography';
import CircleIcon from '@mui/icons-material/Circle';
import GraphType from '../../../../options/components/GraphTypeOptions';
import { ChartColorIdentifiers } from '../../../../options/components/GraphSettingsOptions';

interface FlowData {
    flow: string;
    data: number[];
}

interface CategoryFlows {
    category: string;
    flows: FlowData[];
}
interface ChartData {
    product: Product;
}

export 

function C4SDGBarPSILCA(props: ChartData) {
    const { formState, setFormState } = useFormContext();
    const classes = useStyles();
    const { product } = props;
    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
    const [chartJSData, setChartJSData] = useState<ChartJSData>({ datasets: [], labels: [] });
    const [chartOptions, setChartOptions] = useState<ChartJSOptions>(initialOptions);
    const [colorIdentifiers, setColorIdentifiers] = useState<ChartColorIdentifiers[]>([]);
    const [selectedValueType, setSelectedValueType] = useState<string>('');
    const [selectedFilterOption, setSelectedFilterOption] = useState<string>('');
    const [results, setResults] = useState<PSILCAResult[]>([]);
    const [selectedLCS, setSelectedLCS] = useState<string>('');
    const [selectedLCSS, setSelectedLCSS] = useState<string>('');
    const [selectedProcess, setSelectedProcess] = useState<string>('');

    const [selectedStakeholder, setSelectedStakeholder] = useState<string>('');
    const [loadedSDGs, setLoadedSDGs] = useState<SDG[]>([]);
    const [selectedSDGs, setSelectedSDGs] = useState<string[]>([]);
    const [currentSDGs, setCurrentSDGs] = useState<SDGResult[]>([]);
    const [selectedSDG, setSelectedSDG] = useState("");
    const [selectableSDGs, setSelectableSDGs] = useState<string[]>([]);

    useMemo(() => {
        const calc = product.calculations.find(
            (item) => { return item.calculationType === 'SOCIAL-PSILCA' },
        );
        const calcResults = calc?.calculationResults as PSILCAResult[];

        setResults((calcResults));
        try {
            fetchSocialSDGs()
                .then((sdgs) => {
                    setSelectableSDGs(
                        sdgs
                            .map((sdg) => sdg.name)
                            .sort((a, b) => parseInt(a.split(":")[0].replace("SDG", ""), 10) - parseInt(b.split(":")[0].replace("SDG", ""), 10))
                    ); setLoadedSDGs(sdgs);
                })
                .catch();
        } catch (err) {
            console.log(err);
        }
    }, [product]);

  


    function getColor(type: string): string {
        if (type === 'Direct') {
            return '#6ADBD7';
        }
        if (type === 'Indirect') {
            return '#a3a3a3';
        }
        return '#e1dedb';
    }

    useEffect(() => {
        const chartData: ChartJSData = { datasets: [], labels: [] };
        const options: ChartJSOptions = deepClone<ChartJSOptions>(initialOptions);
        const chartColorIdentifiers : ChartColorIdentifiers[] = [];

        if (options.scales && options.scales.y && options.scales.y.title) {
            options.scales.y.title.text = "Medium Risk Hours";
        }

        if (options.plugins && options.plugins.legend) {
            options.plugins.legend.display = false;
        }

        const processes: string[] = [];
        const lifeCycle = results[0].subcategories[0].indicators[0]
            .indicatorResults.find((item) => { return item.lcsName === `${selectedLCS}:${selectedLCSS}` });
        if (lifeCycle) {           

            //processes = lifeCycle.processResults.processes.map((item) => { return item.process });
            const process = lifeCycle.processResults.processes.find(item => item.process === selectedProcess.split(" - ")[0])
    
            if(!process)
                return;

            const stakeholders: PSILCAResult[] = results;
            const subcategories = stakeholders.reduce(
                (subcategories: { stakeholder: string, subcategory: string }[], stakeholder) => {
                    return subcategories.concat(
                        stakeholder.subcategories.map((item) => {
                            return {
                                stakeholder: stakeholder.stakeholder,
                                subcategory: item.subcategory,
                            };
                        })
                    );
                },
                []
            );

            chartData.labels = subcategories.map(
                (item) => item.subcategory
            );

            //processes.forEach((process, index) => {
            chartColorIdentifiers.push({label:process.process});

            chartData.datasets.push({
                label: process.process,
                data: stakeholders.reduce((a: number[], b) => {
                    return a.concat(
                        b.subcategories.map((subcategory) => {
                            return subcategory.indicators
                                .map((indicator) => {

                                    const matchingLCS = indicator.indicatorResults.find(
                                        (lcs) => lcs.lcsName === lifeCycle.lcsName
                                    );
                                    const matchingProcess = matchingLCS?.processResults.processes.find(
                                        (item) => item.process === process.process
                                    );
                                    const result = matchingProcess?.flowResults.reduce((a, b) => {
                                        return a + b.sum;
                                    }, 0) || 0;



                                    return result;
                                })
                                .reduce((a, b) => {
                                    if (!b) {
                                        return a
                                    }
                                    return a + b;
                                }, 0);
                        })
                    );
                }, []),
                // backgroundColor:
                //         selectedPalette[
                //         // getGraphColorIndex({
                //         //     label: process,
                //         //     // identifier: lifeCycle.lcsName,
                //         // })
                //             0
                //         ],

                //manyColors[index], //random color from array
                borderColor: "000000",
            });
            //});


            const sdgs = selectedSDGs.map((sdg) => {
                const matchingSDG = loadedSDGs.find(
                    (item) => item.name === sdg
                );

                return {
                    sdg: sdg,
                    categories: subcategories.reduce(
                        (items: { name: string, val: number, correlation: string }[], subcategory, index) => {
                            if (matchingSDG) {
                                const matchingCategory = matchingSDG.categories.find(
                                    (item) =>
                                        item.categoryName.toLowerCase() ===
                                        `${subcategory.stakeholder}:${subcategory.subcategory}`.toLowerCase()
                                );
                                if (matchingCategory) {
                                    items.push({
                                        name: `${subcategory.stakeholder}:${subcategory.subcategory}`,
                                        val: chartData.datasets[0].data[index],
                                        correlation: matchingCategory.correlation.toLocaleString(),
                                    });
                                }
                            }

                            return items;
                        },
                        []
                    ),
                };
            });
            setCurrentSDGs(sdgs);

            const currentSDG = sdgs.find(
                (item) => item.sdg === selectedSDG
            );
            const graphColors = [];
            chartData.labels = [];
            if (currentSDG) {
                const data = currentSDG.categories.reduce(
                    (items: number[], category) => {
                        switch (category.correlation) {
                        case "Direct":
                            graphColors.push("#7edfdb");
                            items.push(category.val);
                            chartData.labels.push(category.name);
                            break;
                        case "Indirect":
                            graphColors.push("#a3a3a3");
                            items.push(category.val);
                            chartData.labels.push(category.name);

                            break;
                        default:
                            break;
                        }

                        return items;
                    },
                    []
                );
                console.log(data);
                chartData.datasets = [
                    {
                        label: "Medium Risk Hours",
                        data: data,
                        borderColor: "000000",
                        backgroundColor: sdgColor, //"#1B5D82",
                    },
                ];

                // if (graph1ValueType === "%perProcess") {
                //     const total = graphData.datasets[0].data.reduce(
                //         (a, b) => a + b,
                //         0
                //     );
                //     graphData.datasets[0].data =
                //         graphData.datasets[0].data.map(
                //             (item) => autoRound(item / total) * 100
                //         );
                // }


            }
            // else {
            //     graphData = sdgs;
            // }
        }



        console.log(chartData);
        setChartJSData(chartData);
        setChartOptions(options);
        setColorIdentifiers(chartColorIdentifiers);    
    }, [selectedValueType, selectedFilterOption, selectedCategories,selectedProcess,
        selectedLCS, selectedLCSS,  results, selectedSDG]);

    const [applyGraphSettings, setApplyGraphSettings] =
        useState<(options: ChartJSOptions, chartData: ChartJSData, colorIdentifiers?: ChartColorIdentifiers[]) => 
            { options: ChartJSOptions; chartData: ChartJSData; }>();
    const triggerChartUpdate = () => {
        let options = deepClone(chartOptions) || {};
        let chartData = deepClone(chartJSData);
        let applyOptionReturn: ApplyOptionReturn;
        if (selectedValueType === "% Per Process") {
            applyOptionReturn = applyValueType(selectedValueType, chartData, options, false, 'pie');
            options = applyOptionReturn.options;
            chartData = applyOptionReturn.chartData;
        }

        if (applyGraphSettings) {
            ({ options, chartData } = applyGraphSettings(options, chartData, colorIdentifiers));
        }
        CreateChartTS(chartData, options);
    };

    useEffect(() => {
        if (chartJSData && chartOptions) {
            triggerChartUpdate();
        }
    }, [chartJSData, chartOptions]);

    return (
        <ThemeProvider theme={ResultsTheme}>
            <Box
                sx={{
                    margin: '0',
                    maxWidth: '100%',
                    maxHeight: '-webkit-fill-available',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1vw',
                }}
            >
                {/* <Box className='temp-box' /> */}
                <Box component='div' className='results-wrapper'>
                    <Box component='div' className='results-options'>
                        <Paper square>
                            <Box component='div' className='results-options-top'>
                                <FormControl
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: '3rem',
                                        alignItems: 'stretch',

                                    }}
                                >
                                    <PSILCAChartNavigation product={product} defaultBaseIndex={2} defaultResultIndex={3} reverse />
                                    <ProductComparison />
                                    <SDGChecklist
                                        setSelectedSDGs={setSelectedSDGs}
                                        disabled={false}
                                        setting="C4"
                                        dimension="PSILCA"
                                    />
                                </FormControl>
                            </Box>
                        </Paper>
                        <Divider />
                        <Paper square>
                            <Box component='div' className='results-options-bottom'>
                                <Box>
                                    <FormControl
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}
                                        className='bottom-options side-left'
                                    >
                                        <GraphType
                                            dimension='PSILCA'
                                            chart='C4'
                                            type='bar'
                                            sdgOn
                                        />
                                        <LCSDropdowns
                                            product={product}
                                            selectedLCS={selectedLCS}
                                            setSelectedLCS={setSelectedLCS}
                                            selectedLCSS={selectedLCSS}
                                            setSelectedLCSS={setSelectedLCSS}
                                            selectedProcess={selectedProcess}
                                            setSelectedProcess={setSelectedProcess}
                                            dimension="Social" />
                                        <Dropdown
                                            options={['Raw Values', '% Per Process']}
                                            value={selectedValueType}
                                            valueSetter={setSelectedValueType}
                                        />

                                        <Dropdown
                                            options={selectableSDGs}
                                            valueSetter={setSelectedSDG}
                                            value={selectedSDG}
                                        />

                                    </FormControl>
                                </Box>
                                <Box>
                                    <FormControl
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            '@media (max-width: 1680px)': {
                                                gap: '1vw',
                                            },
                                        }}
                                        className='bottom-options side-right'
                                    >

                                        <ChartDownload chartData={chartJSData}
                                            
                                            chartName={'Subcategory level result - Process'} chartType='Bar Graph' />
                                        <GraphSetting
                                            chartOptions={chartOptions}
                                            setApplyGraphSettings={setApplyGraphSettings}
                                            triggerChartUpdate={triggerChartUpdate}
                                            colorIdentifiers={colorIdentifiers}
                                            chartData={chartJSData}
                                            chartColorType='sdg'
                                            graphType='barindicator'  
                                            noColors
                                            sdgOn
                                        />

                                    </FormControl>
                                </Box>
                            </Box>
                        </Paper>
                    </Box>
                </Box>
                <Box className={classes.chartSec}>
                    <Paper
                        square
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Box className={classes.SDGLegendWrap}>
                            <Box className={classes.SDGLegendBox}>
                                <CircleIcon sx={{ color: getColor('Direct'), fontSize: '1.2vw' }} />
                                <Typography className={classes.SDGFont}>Direct</Typography>
                            </Box>
                            <Box className={classes.SDGLegendBox}>
                                <CircleIcon sx={{ color: getColor('Indirect'), fontSize: '1.2vw' }} />
                                <Typography className={classes.SDGFont}>Indirect</Typography>
                            </Box>
                            <Box className={classes.SDGLegendBox}>
                                <CircleIcon sx={{ color: getColor('None'), fontSize: '1.2vw' }} />
                                <Typography className={classes.SDGFont}>No Connection</Typography>
                            </Box>
                        </Box>
                        <Box className={classes.chartSec}>
                            <canvas id='graph1Canvas' className='graph-canvas' />
                        </Box>
                    </Paper>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default C4SDGBarPSILCA;
