/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { createTheme, ThemeProvider } from '@mui/material';
import TableRow from '@mui/material/TableRow';
import { green } from '@mui/material/colors';

const ProcessPopupTheme = createTheme({
    components: {
        MuiPaper: {
            styleOverrides: {
                root: {
                    minHeight: '75vh',
                    minWidth: '70vw',
                    width: '-webkit-fill-available',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    width: "5vw",
                    height: "fit-content",
                    fontSize: "0.7vw !important",
                    padding: "2px",
                    boxShadow: "0 0 0",
                    borderRadius: 0,
                    fontWeight: 'bold',
                    fontFamily: '"Montserrat", sans-serif',
                    textTransform: 'capitalize',
                    backgroundColor: '#166d46',
                    '&:hover': {
                        backgroundColor: '#D9EFDF',
                        color: '#004021',
                    },
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: green[800],
                    '&.Mui-checked': {
                        color: green[800],
                    },
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    position: 'absolute',
                    transform: 'translate(14px, 9px) scale(1)',
                    left: 'auto',
                    top: 'auto',
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    borderRadius: 0,
                },
            },
        },
    },
    typography: {
        fontFamily: 'Roboto, sans-serif',
        h2: {
            fontSize: '1.4vw',
            fontWeight: 'bold',
        },
        h3: {
            fontSize: '1.2vw',
            fontWeight: 'bold',
        },
        body1: {
            fontFamily: 'Roboto, sans-serif',
            fontSize: '0.7vw',
        },
    },
});

export default ProcessPopupTheme;
