import { create } from 'zustand';

interface productStoreData {
    selectedCategories: string[],
    updateSelectedCategories: (newCategories: string[]) => void;
    selectedBaseOption: string,
    selectedResultsLayer: string,
    updateSelectedBaseOption: (newBaseOption: string) => void;
    updateSelectedResultsLayer: (newResultsLayer: string) => void;
}

export const useProductStore = create<productStoreData>((set) => {return {
    selectedCategories: [],
    updateSelectedCategories: (newCategories: string[]) => {return set({ selectedCategories: newCategories })},
    selectedBaseOption: '',
    selectedResultsLayer: '',
    updateSelectedBaseOption: (newBaseOption: string) => {return set({ selectedBaseOption: newBaseOption })},
    updateSelectedResultsLayer: (newResultsLayer: string) => {return set({ selectedResultsLayer: newResultsLayer })},
}})