import "./TokenExpireLogin.css";
import React, { useContext, useEffect, useState } from "react";

import { capitalize, emailValidator, passwordValidator } from "../../../helper/validation";
import { getItemFromSessionStorage, setAccessTokenInSessionStorage, setItemToSessionStorage } from "../../../helper/StorageHelper";
import { useNavigate, useRouterState } from "@tanstack/react-router";
import authStyles from '../Auth.style';
import {
    Box, Button, FormControl, FormLabel, IconButton, InputAdornment, Link, Paper, TextField, Typography,
} from '@mui/material';
import logo from '../../../assets/tossa.png';
import bgImg from '../../../assets/img1.jpg';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { ILoginErrorState, ILoginFormState } from '../../../interface/Auth';
import { useGQLQuery } from '../../../hooks/useGQLQuery';
import authQuery from '../../../queries/auth';
import { URL_CONF } from '../../../helper/constants';
import Loader from '../../../components/loader';
import toast from 'react-hot-toast';
import { sendRequest } from '../../../helper/fetchUtil';
import { useHttpClient } from "../../../hooks/http-hook";
import useStyles from "./TokenExpiredLogin.styles";
import ErrorIcon from '@mui/icons-material/Error';


interface LoginResponse {
    token: string,
    role: string,
    name: string,
    expiry: string,
}


const TokenExpireLogin = () => {
    const classes = authStyles();
    const extraClasses = useStyles();

    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [tokenExpired, setTokenExpired] = useState(false);
    const route = useRouterState();

    useEffect(() => {
        const tokenExpiry = getItemFromSessionStorage('tokenExpiry');
        if (tokenExpiry) {
            const expiryDate = new Date(tokenExpiry); // Convert the stored expiry string to a Date object
            const currentDate = new Date(); // Get the current date and time

            if (currentDate > expiryDate) {
                setTokenExpired(true)
                // Handle the expired token (e.g., log out the user, refresh the token, etc.)
            } else {
                setTokenExpired(false);
                // The token is still valid, proceed with the authenticated actions
            }


        } else {
            console.log("No expiry date found in session storage");
            // Handle the case where there's no expiry date stored
        }


    }, [route])




    const { isLoading, error, sendRequest, clearError } = useHttpClient();


    const [state, setState] = useState<ILoginFormState>({
        email: '',
        password: '',
    });
    const [errorState, setErrorState] = useState<ILoginErrorState>({
        email: '',
        password: '',
    });




    const submitLogin = async (): Promise<LoginResponse> => {
        const responseData = await sendRequest<LoginResponse>(
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            `${process.env.REACT_APP_BACKEND_URL}/user/authenticate`,
            'POST',
            JSON.stringify({
                password: state.password,
                username: state.email,
            }),
            { 'Content-Type': 'application/json' },
        );
        return responseData;
    };
    const validateFields = (name: string, value: string) => {
        if (value.trim().length === 0) {
            setErrorState((prevState) => {
                return {
                    ...prevState,
                    [name]: `${capitalize(name)} is required`,
                }
            });
            return;
        }
        switch (name) {
        case 'email':
            // setErrorState((prevState) => ({
            //     ...prevState,
            //     email: !emailValidator(value) ? 'Please enter a valid email address.' : null,
            // }));
            break;
        case 'password':
            // setErrorState((prevState) => ({
            //     ...prevState,
            //     password: !passwordValidator(value) ? `Password must contain 8-20 characters, including at least one uppercase letter,
            // one lowercase letter, one number, and one special character.` : null,
            // }));
            break;
        default:
            break;
        }
    };

    const validateCredentials = () => {
        const { email, password } = state;
        if (emailValidator(email) && passwordValidator(password)) {
            return true;
        }
        validateFields('email', email);
        validateFields('password', password);
        return false;
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        submitLogin().then((response) => {
            setAccessTokenInSessionStorage(response.token as string);
            setTokenExpired(false);
            if (response.expiry) {
                setItemToSessionStorage('tokenExpiry', response.expiry);
            }
            if (response.token) {
                setTokenExpired(false);
            }
        }).catch((err) => { });

        if (validateCredentials()) {
            // refetch();

        }
    };
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setState((prevState) => {
            return {
                ...prevState,
                [name]: value,
            }
        });
    };
    const resetErrorCode = (e: React.FocusEvent<HTMLInputElement>) => {
        const { name } = e.target;
        setErrorState((prevState) => {
            return {
                ...prevState,
                [name]: '',
            }
        });
    };
    return (
        <>
            <button onClick={(() => {
                setTokenExpired(true);
            })}
            style={{
                position: 'absolute',
                top: '3vw',
            }}
            >EXPIRE TOKEN</button>
            {tokenExpired && <div className="login__content_wrap">
                <div className="login__content">
                    <Paper square className={extraClasses.PaperWrapper}>
                        <Box className={extraClasses.ContentBox}>
                            <ErrorIcon className={extraClasses.IconPopup} />
                            <Box className={extraClasses.PopupMessageBanner}>
                                <Typography className={extraClasses.PopupMessage} component='h2' variant='h2'>Session expired. Please sign in again.</Typography>
                            </Box>
                            <form className={classes.form} onSubmit={handleSubmit}>
                                <FormControl className={classes.formControl}>
                                    <FormLabel htmlFor='emailField'>Email Address</FormLabel>
                                    <TextField
                                        type='text'
                                        id='emailField'
                                        name='email'
                                        placeholder='Please Enter Your Email'
                                        value={state.email}
                                        onChange={handleChange}
                                        onFocus={resetErrorCode}
                                        // onBlur={validate}
                                        sx={{
                                            padding: '0.2vw !important',
                                            '& .MuiInputBase-input': {
                                                padding: '0.2vw !important',
                                            },
                                        }}
                                    />
                                    <span className={classes.errorStyle}>{errorState.email}</span>
                                </FormControl>
                                <Box className={classes.FormElementInBox}>
                                    <FormControl className={classes.formControl}>
                                        <FormLabel htmlFor='passwordField'>Password</FormLabel>
                                        <TextField
                                            type={showPassword ? 'text' : 'password'}
                                            id='passwordField'
                                            name='password'
                                            placeholder='Please Enter Your Password'
                                            value={state.password}
                                            onChange={handleChange}
                                            onFocus={resetErrorCode}
                                            // onBlur={validate}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position='end'>
                                                        <IconButton onClick={() => { return setShowPassword(!showPassword) }}>
                                                            {
                                                                showPassword ? (<VisibilityOutlinedIcon sx={{ fontSize: '0.7vw' }} />) : (
                                                                    <VisibilityOffOutlinedIcon sx={{ fontSize: '0.7vw' }} />
                                                                )
                                                            }
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            sx={{
                                                padding: '0.2vw !important',
                                                '& .MuiInputBase-input': {
                                                    padding: '0.2vw !important',
                                                },
                                            }}
                                        />
                                        <span className={classes.errorStyle}>{errorState.password}</span>
                                    </FormControl>
                                    <Link href='/' className={classes.Link}>Forgot Password?</Link>
                                </Box>
                                <Box className={classes.FormElementInBox}>
                                    <Button type='submit' disableRipple className={`session-expired-button ${classes.signInBtn}`}>
                                        Login
                                    </Button>
                                    <Typography sx={{ fontSize: '0.7vw' }}>
                                        Don&apos;t have an account?
                                        {' '}
                                        <Link href='/signup' className={classes.Link}>Register here</Link>
                                    </Typography>
                                </Box>
                            </form>
                        </Box>
                    </Paper>
                </div>
            </div>}
        </>


    );
};

export default TokenExpireLogin;
