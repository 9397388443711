import React, { useEffect, useState } from "react";
import {
    InputLabel,
    Box,
    Typography,
    Button,
    FormGroup,
    MenuItem,
    Select,
    Paper,
    styled,
    Grid,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import { Portal } from "@mui/base/Portal";
import ClearIcon from "@mui/icons-material/Clear";
import { ThemeProvider } from "@emotion/react";
import ProcessPopupTheme from "../../../../style/components/ProcessPopupTheme";
import ExitButton from "../../../../style/components/ExitButton";
import { FormControl } from "@mui/base/FormControl";
import Pagination from "@mui/material/Pagination";
import {
    fetchEnvFlowByPage,
    fetchEnvFlowCategories,
    fetchEnvFlowsByPageAndCategory,
    fetchFlows,
} from "../../../../../helper/fetchUtil";
import { SelectFlow } from "../../../../../interface/Flow";
import { UnitGroup } from "../../../../../interface/Unit";
import useStyles from "./SelectEnvElementaryFlowPopup.styles";
import { useForm } from "@tanstack/react-form";
import Dropdown from "../../../../dropdowns/Dropdown";
import SearchIcon from "@mui/icons-material/Search";

interface SelectProductEnvFlowPopupProps {
  openFlowSelect: (event: React.MouseEvent<HTMLElement>) => void;
  selectHandler: (flow: SelectFlow) => void;
}

interface CategorySelectForm {
  category1: string;
  category2: string;
  category3: string;
  category4: string;
  searchKey: string;
  useCategorySearch: boolean;
}

function SelectEnvFlowPopup(props: SelectProductEnvFlowPopupProps) {
    const { selectHandler, openFlowSelect } = props;
    const [openEnvFlowFlow, setOpenEnvFlowFlow] = useState(false);

    const classes = useStyles();

    const selectFlowHandler = (
        event: React.MouseEvent<HTMLElement>,
        flow: SelectFlow
    ) => {
        openFlowSelect(event);
        selectHandler(flow);
    };

    const handleEnvFlowFlowClick = () => {
        setOpenEnvFlowFlow((prev) => {
            return !prev;
        });
    };

    const handleEnvFlowFlowClickOff = () => {
        setOpenEnvFlowFlow(false);
    };
    const getUniqueCategories = (
        index: number,
        categories: string[]
    ): string[] => {
        const seen: Record<string, boolean> = {};
        return categories
            .map((category) => {
                return category.split("/")[index];
            })
            .filter((category) => {
                if (seen[category]) {
                    return false;
                }
                seen[category] = true;
                return true;
            });
    };
 

    const getSubcategories = (
        parentCategory: string,
        parentIndex: number,
        subCategoryIndex: number,
        categories: string[]
    ): string[] => {
        const listToReturn: string[] = [];
        categories.forEach((category) => {
            const categorySplit = category.split("/");

            if (
                categorySplit[parentIndex] === parentCategory &&
      !listToReturn.find((item) => {
          return item === categorySplit[subCategoryIndex];
      })
            ) {
                listToReturn.push(categorySplit[subCategoryIndex]);
            }
        });
        return listToReturn;
    };
    /* This will be moved to the Theme page soon */

    const BoxListItem = styled(Box)(({ theme }) => {
        return {
            padding: "5px",
            fontSize: "12px",
            color: "#707070",
            display: "grid",
            gridTemplateColumns: "1fr auto auto",
            columnGap: "1rem",
            "&:nth-of-type(odd)": {
                backgroundColor: "#F3F3F3",
            },
            "&:nth-of-type(even)": {
                backgroundColor: "#E6E6E6",
            },
        };
    });

    const ButtonEditFlow = styled(Button)(({ theme }) => {
        return {
            width: "5rem",
            height: "fit-content",
            fontSize: "14px",
            padding: "2px",
            boxShadow: "0 0 0",
        };
    });

    const DropdownWrapperFlow = styled(Box)(({ theme }) => {
        return {
            display: "flex",
            alignItems: "center",
            gap: "1rem",
        };
    });

    const [loadedCategories, setLoadedCategories] = useState<string[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState<number[]>([1]);
    const [loading, setLoading] = useState(true);
    const [selectableCategory1, setSelectableCategory1] = useState<string[]>([]);
    const [selectableCategory2, setSelectableCategory2] = useState<string[]>([]);
    const [selectableCategory3, setSelectableCategory3] = useState<string[]>([]);

    const [loadedFlows, setLoadedFlows] = useState<SelectFlow[]>([]);

    // useEffect(() => {
    //     if (!(loadedFlows.length > 0)) {
    //         fetchFlows().then((val) => {
    //             if (val) {
    //                 setLoadedFlows(val);
    //             }
    //         }).catch((err) => { });
    //     }
    // }, []);

    const form = useForm<CategorySelectForm>({
        defaultValues: {
            category1: "",
            category2: "",
            category3: "",
            category4: "",
            searchKey: "",
            useCategorySearch: true,
        },
        onSubmit: ({ value }) => {
            // Do something with form data
            console.log(value);
        },
    });

    useEffect(() => {
        fetchEnvFlowCategories("elementary")
            .then((val) => {
                setLoadedCategories(val);
                processCategories(val);
                submitFlowSearch(1, val);
            })
            .catch(() => {});
    }, []);

    const processCategories = (categories: string[]) => {
        const c1 = getUniqueCategories(0, categories);
        setSelectableCategory1(c1);
        selectC1Handler(c1[0], categories);
    };

    const selectC2Handler = (c2: string, categories: string[]) => {
        form.setFieldValue("category2", c2);
        const c3 = getSubcategories(c2, 1, 2, categories);
        setSelectableCategory3(c3);
        selectC3Handler(c3[0], categories);
    };
    const selectC1Handler = (c1: string, categories: string[]) => {
        form.getFieldInfo("category1").instance?.handleChange(c1);
        const c2 = getSubcategories(c1, 0, 1, categories);
        setSelectableCategory2(c2);
        selectC2Handler(c2[0], categories);
    };

    // const selectC3Handler = (c3: string, categories: string[]) => {
    //   form.setFieldValue('category3', c3);

    //   const c4 = getSubcategories(c3, 2, 3, categories);
    //   setSelectableCategory4(c4);
    //   selectC4Handler(c4[0], categories);
    // };
    const selectC3Handler = (c3: string, categories: string[]) => {
        form.setFieldValue("category3", c3);
        setCurrentPage(1);
    };

    
    const submitFlowSearch = (page?: number, categories?: string[]) => {
        const _categories = categories || loadedCategories;
        if (form.getFieldValue("useCategorySearch")) {
            const c1 = form.state.values.category1;
            const c2 = form.state.values.category2;
            const c3 = form.state.values.category3;
            const c4 = form.state.values.category4;
            const key = form.state.values.searchKey;
            setLoading(true);
            const matchingCategory = _categories.find((item) => {
                return item.includes(`${c1}/${c2}/${c3}/${c4}`);
            });
            if (matchingCategory) {
                const categoryToSearch =
          matchingCategory.split("/")[matchingCategory.split("/").length - 1];
                fetchEnvFlowsByPageAndCategory(
                    page || currentPage,
                    key,
                    Number(categoryToSearch)
                )
                    .then((data) => {
                        setLoading(false);
                        setLoadedFlows(data.flows);
                        setTotalPages(Array(Math.ceil(data.entries / 100)).fill(0));
                    })
                    .catch(() => {});
            }
        } else {
            const key = form.state.values.searchKey;
            setLoading(true);

            fetchEnvFlowByPage(page || currentPage, key, "elementary")
                .then((data) => {
                    setLoading(false);
                    setLoadedFlows(data.flows);
                    setTotalPages(Array(Math.ceil(data.entries / 100)).fill(0));
                })
                .catch(() => {});
        }
    };
    
    useEffect(() => {
        fetchEnvFlowCategories("elementary")
            .then((val) => {
                setLoadedCategories(val);
                processCategories(val);
                submitFlowSearch(1, val);
            })
            .catch(() => {});
    }, []);

    return (
        <ThemeProvider theme={ProcessPopupTheme}>
            <ClickAwayListener onClickAway={handleEnvFlowFlowClickOff}>
                <FormGroup>
                    <Box
                        className={`select-process-popup__parent ${classes.SelectProcessPopupParent}`}
                    >
                        <Paper square>
                            <Box
                                className={`select-process-popup__content ${classes.SelectProcessPopupContent}`}
                            >
                                <Box className={classes.FlowTopWrapper}>
                                    {" "}
                                    <Typography
                                        className="select-flow-popup__title"
                                        component="h2"
                                        variant="h2"
                                        sx={{ marginTop: "1rem" }}
                                    >
                    Select Flow
                                    </Typography>
                                    <ThemeProvider theme={ExitButton}>
                                        <Button
                                            className="social-flow-exit-button"
                                            onClick={openFlowSelect}
                                            sx={{ marginRight: "0rem", marginTop: "0rem" }}
                                        >
                                            <ClearIcon />
                                        </Button>
                                    </ThemeProvider>
                                </Box>
                                <Box className={classes.FlowMiddleWrapper}>
                                    <Box className={classes.DropdownWrapperProcess}>
                                        <form.Field
                                            name="category1"
                                            children={(field) => {
                                                return (
                                                    <Dropdown
                                                        options={selectableCategory1}
                                                        onChange={(e) => {
                                                            field.handleChange(e.target.value);
                                                            selectC1Handler(e.target.value, loadedCategories);
                                                            submitFlowSearch(1);
                                                        }}
                                                        id={field.name}
                                                        className={field.name}
                                                        value={field.state.value}
                                                        onBlur={field.handleBlur}
                                                        noDefaults
                                                    />
                                                );
                                            }}
                                        />
                                        <form.Field
                                            name="category2"
                                            children={(field) => {
                                                return (
                                                    <Dropdown
                                                        options={selectableCategory2}
                                                        onChange={(e) => {
                                                            field.handleChange(e.target.value);
                                                            selectC2Handler(e.target.value, loadedCategories);
                                                            submitFlowSearch(1);
                                                        }}
                                                        id={field.name}
                                                        className={field.name}
                                                        value={field.state.value}
                                                        onBlur={field.handleBlur}
                                                        noDefaults
                                                    />
                                                );
                                            }}
                                        />
                                        <form.Field
                                            name="category3"
                                            children={(field) => {
                                                return (
                                                    <Dropdown
                                                        options={selectableCategory3}
                                                        onChange={(e) => {
                                                            field.handleChange(e.target.value);
                                                            selectC3Handler(e.target.value, loadedCategories);
                                                            submitFlowSearch(1);
                                                        }}
                                                        id={field.name}
                                                        className={field.name}
                                                        value={field.state.value}
                                                        onBlur={field.handleBlur}
                                                        noDefaults
                                                    />
                                                );
                                            }}
                                        />
                                        <Box sx={{ display: "flex", gap: "5px", height: '-webkit-fill-available' }}>
                                            <form.Field
                                                name="useCategorySearch"
                                                children={(field) => {
                                                    return (
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={field.state.value}
                                                                    onChange={(e) => {
                                                                        field.handleChange(
                                                                            Boolean(e.target.checked)
                                                                        );
                                                                        setCurrentPage(1);
                                                                        submitFlowSearch(1);
                                                                    }}
                                                                    sx={{
                                                                        padding: "2px",
                                                                        marginLeft: 0,
                                                                        marginRight: 0,
                                                                        fontSize: "0.8vw",
                                                                    }}
                                                                />
                                                            }
                                                            label="Use Categories"
                                                            className={classes.CategoryBox}
                                                            sx={{
                                                                padding: "10px",
                                                                paddingTop: 0,
                                                                paddingBottom: 0,
                                                            }}
                                                        />
                                                    );
                                                }}
                                            />
                                            <Box
                                                sx={{ display: "flex" }}
                                                className={classes.ProcessSearchBar}
                                            >
                                                <form.Field
                                                    name="searchKey"
                                                    children={(field) => {
                                                        return (
                                                            <TextField
                                                                id={field.name}
                                                                className={field.name}
                                                                value={field.state.value}
                                                                onBlur={field.handleBlur}
                                                                onChange={(e) => {
                                                                    return field.handleChange(e.target.value);
                                                                }}
                                                                // placeholder='Search for process'

                                                                sx={{
                                                                    width: "11vw",
                                                                    // padding: '5px',
                                                                    "& .MuiInputBase-input": {
                                                                        width: "11vw",
                                                                        // padding: "0.5vw",
                                                                        padding:'0.42vw',
                                                                    },
                                                                    "& .MuiOutlinedInput-notchedOutline": {
                                                                        border: "none",
                                                                    },
                                                                }}
                                                            />
                                                        );
                                                    }}
                                                />
                                                <button
                                                    type="button"
                                                    onClick={() => {
                                                        setCurrentPage(1);
                                                        submitFlowSearch(1);
                                                    }}
                                                    className={classes.BackgroundBorderRemove}
                                                    
                                                >
                                                    <SearchIcon
                                                        sx={{ fontSize: '1.2vw' }}
                                                    />
                                                </button>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>

                                <Box className={`flows ${classes.FlowBottomWrapper}`}>
                                    {/* {loadedFlows
                    .filter((flow, index) => index <= 100)
                    .map((flow, index) => (
                      <BoxListItem key={flow.name + index.toString()}>
                        <Typography className='select-flow' variant='body1'>
                          {form.state.values.useCategorySearch
                            ? flow.name
                            : flow.name +
                              ' - ' +
                              loadedCategories
                                .find((item) =>
                                  item
                                    .split('/')
                                    [item.split('/').length - 1].includes(
                                      flow.category.toString()
                                    )
                                )
                                ?.replace('/' + flow.category.toString(), '')}
                        </Typography>

                        <ButtonEditFlow
                          variant='contained'
                          onClick={handleEnvFlowFlowClick}
                        >
                          Details
                        </ButtonEditFlow>
                        <ButtonEditFlow
                          variant='contained'
                          onClick={(e) => {
                            selectFlowHandler(e, flow);
                          }}
                        >
                          Select
                        </ButtonEditFlow>
                      </BoxListItem>
                    ))} */}
                                    {loadedFlows
                                        .filter((flow, index) => {
                                            return index <= 100;
                                        })
                                        .map((flow, index) => {
                                            const categoryText = !form.state.values.useCategorySearch
                                                ? loadedCategories
                                                    .find((item) => {
                                                        return item
                                                            .split("/")
                                                            [item.split("/").length - 1].includes(
                                                                flow.category.toString()
                                                            );
                                                    })
                                                    ?.replace("/" + flow.category.toString(), "")
                                                : "";

                                            return (
                                                <Box className={`${
                                                    !form.state.values.useCategorySearch ?
                                                        classes.BoxListItem : classes.BoxListItemNoFlow}` } key={flow.name + index.toString()}>

                                                    <Box
                                                        sx={{
                                                            lineHeight: '16px',
                                                            whiteSpace: 'nowrap',
                                                            maxWidth: '-webkit-fill-available',
                                                            overflowX: 'auto',
                                                            width: 'auto',
                                                            '::-webkit-scrollbar': {
                                                                width: '0.2em',
                                                                height: '0.7vh',
                                                            },
                                                            '::-webkit-scrollbar-track': {
                                                                background: '#939598',
                                                                border: 0,
                                                            },
                                                            '::-webkit-scrollbar-thumb': {
                                                                backgroundColor: '#32463e',
                                                                border: 0,
                                                            },
                                                        }}
                                                    >
                                                        <Typography
                                                            className='select-flow'
                                                            variant='body1'
                                                            sx={{
                                                                fontSize: '0.7vw',
                                                                textAlign: 'center',
                                                            }}
                                                        >
                                                            {flow.name}
                                                        </Typography>
                                                    </Box>
                                                    {!form.state.values.useCategorySearch && (
                                                        <Box>
                                                            <Typography className='select-flow' variant='body1'>
                                                                {categoryText}
                                  
                                                            </Typography>
                                                        </Box>
                                                    )}
                                                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '1vw' }}>
                                                        <Box>
                                                            <ButtonEditFlow
                                                                variant="contained"
                                                                onClick={handleEnvFlowFlowClick}
                                                            >
                                Details
                                                            </ButtonEditFlow>
                                                        </Box>
                                                        <Box>
                                                            <ButtonEditFlow
                                                                variant="contained"
                                                                onClick={(e) => {
                                                                    selectFlowHandler(e, flow);
                                                                }}
                                                            >
                                Select
                                                            </ButtonEditFlow>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            );
                                        })}
                                </Box>
                                <Box
                                    className="process-page-nums"
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        gap: "5px",
                                        width: "-webkit-fill-available",
                                        flexErap: "wrap",
                                        padding: "10px",
                                        paddingTop: "2px",
                                    }}
                                >
                                    <Pagination
                                        count={totalPages.length}
                                        page={currentPage}
                                        onChange={(event, page) => {
                                            setCurrentPage(page);
                                            submitFlowSearch(page);
                                        }}
                                        className={classes.PopupPageNumbers}
                                        variant="outlined"
                                        shape="rounded"
                                        size="small"
                                    />
                                </Box>
                            </Box>
                        </Paper>
                    </Box>
                </FormGroup>
            </ClickAwayListener>
        </ThemeProvider>
    );
}

export default SelectEnvFlowPopup;
