import { useCallback, useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';
import { ThemeProvider } from '@mui/material/styles';
import useStyles from './C1BarChart.styles';

import { EnvironmentalResult, Product } from '../../../../../interface/Product';
import ResultsTheme from '../../../style/ResultsTheme';
import ChartDownload from '../../../options/components/ChartDownload';
import GraphSetting from '../../../options/components/GraphSettings';
import SDGLinkage from '../../../options/components/SDGLinkage';
import ProductComparison from '../../../options/components/Comparison';



// import Loader from '../../components/loader';
import ImpactCategoryDropdown from '../../../../dropdowns/ImpactCategoryDropdown';
import { initialOptions } from '../../../options/InitialOptionValues';
import deepClone from '../../../../../helper/deepClone';
import {
    ChartJSData,
    ChartJSOptions,
} from '../../../../../interface/ChartData';
import { CreateChartTS } from '../../../util/CreateChartTS';
import EnvChartNavigation from '../../../../dropdowns/BaseOptions/EnvChartNavigation';
import { palette1, palette2, palette3, palette4, processColors } from '../../../../../helper/colors';
import { useFormContext } from '../../../../forms/FormContext';
import { ChartColorIdentifiers } from '../../../options/components/GraphSettingsOptions';



interface ChartData {
    product: Product;
}

function C1BarChart(props: ChartData) {
    const classes = useStyles();
    const { formState, setFormState } = useFormContext();
    const { product } = props;
    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
    const [chartJSData, setChartJSData] = useState<ChartJSData>({
        datasets: [],
        labels: [],
    });
    const [chartOptions, setChartOptions] = useState<ChartJSOptions>(initialOptions);
    const [colorIdentifiers, setColorIdentifiers] = useState<ChartColorIdentifiers[]>([]);
    const [selectedValueType, setSelectedValueType] = useState<string>('');
    const [results, setResults] = useState<EnvironmentalResult[]>([]);

    useMemo(() => {
        const calc = product.calculations.find(
            (item) => { return item.calculationType === 'ENVIRONMENTAL' },
        );
        const calcResults = calc?.calculationResults as EnvironmentalResult[];
        setResults(calcResults);
    }, [product]);

    // function getGraphColorIndex({ label, identifier }) {
    //     const hash = `${label}:${identifier}`.split('').reduce((hash, char) => {
    //         return char.charCodeAt(0) + ((hash << 5) - hash);
    //     }, 0);
    //     return Math.abs(hash) % processColors.length;
    // }


    const [noColors, setNoColors] = useState<boolean>(false);

    const someCondition = false;


    useEffect(() => {
        if (someCondition) {
            setNoColors(true);
        } else {
            setNoColors(false);
        }
    }, [someCondition]);

    useEffect(() => {
        const chartData: ChartJSData = { datasets: [], labels: [] };
        const options: ChartJSOptions = deepClone<ChartJSOptions>(initialOptions);
        const chartColorIdentifiers : ChartColorIdentifiers[] = [];



      

        chartColorIdentifiers.push({label:product.productName})

        chartData.datasets = [
            {
                label: product.productName,
                // //backgroundColor: extendedPalette[colorIndex],
                data: selectedCategories.map((category) => {
                    return results.reduce(
                        (productTotal, lcs) => productTotal + lcs.subStages.reduce((total, lcss) => {
                            const val = lcss.normalizedResults.find((result) => result.impactCategory.name === category);
                            return val ? total + val.value : total;
                        }, 0),
                        0,
                    );
                }),
            },
        ];

        chartData.labels = selectedCategories;
        setChartJSData(chartData);
        setChartOptions(options);
        setColorIdentifiers(chartColorIdentifiers);  
    }, [selectedValueType, selectedCategories]);

    const [applyGraphSettings, setApplyGraphSettings] =
        useState<(options: ChartJSOptions, chartData: ChartJSData, colorIdentifiers?: ChartColorIdentifiers[]) => 
            { options: ChartJSOptions; chartData: ChartJSData; }>();
    const triggerChartUpdate = () => {
        let options = deepClone(chartOptions) || {};
        let chartData = deepClone(chartJSData);
        if (applyGraphSettings) {
            ({ options, chartData } = applyGraphSettings(options, chartData, colorIdentifiers));
        };
        CreateChartTS(chartData, options);
    };
    useEffect(() => {
        if (chartJSData && chartOptions) {
            triggerChartUpdate();
        }
    }, [chartJSData, chartOptions]);

    return (
        <ThemeProvider theme={ResultsTheme}>
            <Box
                sx={{
                    margin: '0',
                    maxWidth: '100%',
                    maxHeight: '-webkit-fill-available',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1vw',
                }}
            >
                <Box component='div' className='results-wrapper'>
                    <Box component='div' className='results-options'>
                        <Paper square>
                            <Box component='div' className='results-options-top'>
                                <FormControl
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: '3rem',
                                        alignItems: 'stretch',
                                    }}
                                >
                                    <EnvChartNavigation
                                        product={product}
                                        defaultBaseIndex={2}
                                        defaultResultIndex={0}
                                    />
                                    <SDGLinkage
                                        dimension="Environmental"
                                        charttype='Table'
                                        setting="C1"
                                    />
                                    <ProductComparison />
                                </FormControl>
                            </Box>
                        </Paper>
                        <Divider />
                        <Paper square>
                            <Box component='div' className='results-options-bottom'>
                                <Box>
                                    <FormControl
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}
                                        className='bottom-options side-left'
                                    >
                                        <ImpactCategoryDropdown
                                            product={product}
                                            selectedCategories={selectedCategories}
                                            setSelectedCategories={setSelectedCategories}
                                        />
                                    </FormControl>
                                </Box>
                                <Box>
                                    <FormControl
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            '@media (max-width: 1680px)': {
                                                gap: '1vw',
                                            },
                                        }}
                                        className='bottom-options side-right'
                                    >
                                        <ChartDownload
                                            chartData={chartJSData}
                                            
                                            chartName={'Normalized results: impact category level - Product System'}
                                            chartType='Bar Graph' />
                                        <GraphSetting
                                            chartOptions={chartOptions}
                                            setApplyGraphSettings={setApplyGraphSettings}
                                            triggerChartUpdate={triggerChartUpdate}
                                            colorIdentifiers={colorIdentifiers}
                                            chartData={chartJSData}
                                            chartColorType='processA1'
                                            noColors={noColors}
                                            graphType='barindicator'

                                            
                                            // setNoColors={setNoColors}
                                        />

                                    </FormControl>
                                </Box>
                            </Box>
                        </Paper>
                    </Box>
                </Box>
                <Box className={classes.chartSec}>
                    <Paper
                        square
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <Box className={classes.chartSec}>
                            <canvas id='graph1Canvas' className='graph-canvas' />
                        </Box>
                    </Paper>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default C1BarChart;
