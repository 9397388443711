import React, { useEffect, useMemo, useState } from 'react';
import {
    Box,
    Divider,
    FormControl,
    Paper,
    ThemeProvider,
} from '@mui/material';
import useStyles from './B1PieSPAstyles';
import autoRound from '../../../../../../helper/autoRound';
import { treemapRGB, getColorsForStakeholders } from '../../../../../../helper/colors';
import {
    ChartJSData,
    ChartJSOptions,
    TreemapData, TreemapLegend,
} from '../../../../../../interface/ChartData';
import { Product, SPAResult } from '../../../../../../interface/Product';
import SPAChartNavigation from '../../../../../dropdowns/BaseOptions/SPAChartNavigation';
import { RechartTreemap } from '../../../../Treemap';
import ChartDownload from '../../../../options/components/ChartDownload';
import GraphSetting from '../../../../options/components/GraphSettings';
import ResultsTheme from '../../../../style/ResultsTheme';
import GraphType from '../../../../options/components/GraphTypeOptions';
import { WeighingSet, applyWeight, getWeighingSetValue, weighingMethods } from '../../../../../../helper/weighingMethod';
import deepClone from '../../../../../../helper/deepClone';
import { applyValueType } from '../../../../options/util/applyOptions';
import { CreateChartTS } from '../../../../util/CreateChartTS';
import { initialOptions } from '../../../../options/InitialOptionValues';
import Dropdown from '../../../../../dropdowns/Dropdown';
import { ChartColorIdentifiers } from '../../../../options/components/GraphSettingsOptions';

interface ComponentProps {
    product: Product;
}

function B1PieSPA(props: ComponentProps) {
    const classes = useStyles();
    const { product } = props;
    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
    const [treemapData, setTreemapData] = useState<TreemapData[]>([]);
    const [results, setResults] = useState<SPAResult>();
    const [currentWeighingSet, setCurrentWeighingSet] = useState<WeighingSet>();
    const [chartJSData, setChartJSData] = useState<ChartJSData>({ datasets: [], labels: [] });
    const [chartOptions, setChartOptions] = useState<ChartJSOptions>(initialOptions);
    const [colorIdentifiers, setColorIdentifiers] = useState<ChartColorIdentifiers[]>([]);
    const [selectedValueType, setSelectedValueType] = useState<string>('');

    useMemo(() => {
        const calc = product.calculations.find(
            (item) => item.calculationType === 'SOCIAL-SPA',
        );
        const calcResults = calc?.calculationResults as unknown as SPAResult;
        const weighingMethod = weighingMethods.find((method) => method.name === product.socialData.weighingMethod.name);
        const weighingSet = weighingMethod?.sets.find((set) => set.name === product.socialData.weighingMethod.set);
        setCurrentWeighingSet(weighingSet);
        setResults(calcResults);
    }, [product]);

    useEffect(() => {
        if (!results || !currentWeighingSet)
            return;

        const legends: TreemapLegend[] = [];
        const chartData: ChartJSData = { datasets: [], labels: [] };
        const options: ChartJSOptions = deepClone<ChartJSOptions>(initialOptions);
        const chartColorIdentifiers : ChartColorIdentifiers[] = [];

        if (results.SPAProcess) {
            results.stakeholderResults.forEach((stakeholder) => {
                const val =
                    Math.round(
                        stakeholder.SPAPriorities.reduce((val1, val2) => {
                            return val1 + applyWeight(val2, "sam", currentWeighingSet);
                        }, 0) * 100
                    ) / 100;

                legends.push({
                    text: stakeholder.name
                        .split(":")[0]
                        .split(" ")
                        .map((s) => {
                            const s1 = s.split("");
                            s1[0] = s1[0].toUpperCase();
                            return s1.join("");
                        })
                        .join(" "),
                    value: val,
                });
            });
        }



        results.lifeCycleResults.forEach((lifeCycle) => {
            lifeCycle.processResults.forEach((process) => {
                process.PSILCAPriorities
                    .map(priority => priority.category.split(":")[0])
                    .filter((value, index, self) => self.indexOf(value) === index).forEach((stakeholder) => {
                        const val =
                            Math.round(
                                process.PSILCAPriorities.filter(
                                    (priority) =>
                                        priority.category.split(":")[0] === stakeholder
                                ).reduce((val1, val2) => {
                                    let num = 0;

                                    switch (val2.score) {
                                    case 4:
                                        num =
                                                val2.score * getWeighingSetValue(currentWeighingSet, "High")

                                        break;
                                    case 3:
                                        num =
                                                val2.score *
                                                getWeighingSetValue(currentWeighingSet, "Some")
                                        break;
                                    case 2:
                                        num =
                                                val2.score *
                                                getWeighingSetValue(currentWeighingSet, "Low")
                                        break;
                                    case 1:
                                        num =
                                                val2.score *
                                                getWeighingSetValue(currentWeighingSet, "Low")
                                        break;
                                    default:
                                    }

                                    return val1 + num;
                                }, 0) * 100
                            ) / 100;
                        // tree.push({
                        //     stakeholder: stakeholder,
                        //     // lifeCycleStage: lifeCycle.lcsName.split(":")[0],
                        //     value: val,
                        // });
                        legends.push({ text: stakeholder, value: val });
                    });
            });
        });





        const total = legends.reduce((acc, legend) => acc + legend.value, 0);

        const combinedLegends = legends.reduce<TreemapLegend[]>((acc, legend) => {
            const existingLegend = acc.find((item) => item.text === legend.text);
            if (existingLegend) {
                existingLegend.value += legend.value;
            } else {
                acc.push(legend);
            }
            return acc;
        }, []);

        chartData.datasets = [
            {
                label: product.productName,
                data: combinedLegends.map((item) => Math.round(item.value * 100) / 100),
                backgroundColor: treemapRGB,
                borderWidth: 2,
                borderColor: "#FFFFFF",
            },
        ];
        chartData.labels = combinedLegends.map((item) => item.text);

        setChartJSData(chartData);
        setChartOptions(
            options
        );
        
    }, [results, currentWeighingSet,selectedValueType]);

    const [applyGraphSettings, setApplyGraphSettings] =
        useState<(options: ChartJSOptions, chartData: ChartJSData, colorIdentifiers?: ChartColorIdentifiers[]) => 
            { options: ChartJSOptions; chartData: ChartJSData; }>();

    const triggerChartUpdate = () => {
        let options = deepClone(chartOptions) || {};
        let chartData = deepClone(chartJSData);
        const applyOptionReturn = applyValueType(selectedValueType, chartData, options, false, 'pie');
        options = applyOptionReturn.options;
        chartData = applyOptionReturn.chartData;
        if (applyGraphSettings) {
            ({ options, chartData } = applyGraphSettings(options, chartData, colorIdentifiers));
        }
        console.log(options);
        options.plugins = {
            ...options?.plugins,
            datalabels: {
                ...options?.plugins?.datalabels,
                color: 'black',
            },
        };
        CreateChartTS(chartData, options, 'pie');
    };

    useEffect(() => {
        if (chartJSData && chartOptions) {
            triggerChartUpdate();
        }
    }, [chartJSData, chartOptions]);

    return (
        <ThemeProvider theme={ResultsTheme}>
            <Box
                sx={{
                    margin: '0',
                    maxWidth: '100%',
                    maxHeight: '-webkit-fill-available',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1vw',
                }}
            >
                <Box component='div' className='results-wrapper'>
                    <Box component='div' className='results-options'>
                        <Paper square>
                            <Box component='div' className='results-options-top'>
                                <FormControl
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: '3rem',
                                        alignItems: 'stretch',
                                    }}
                                >
                                    <SPAChartNavigation product={product} defaultBaseIndex={1} defaultResultIndex={0} />
                                </FormControl>
                            </Box>
                        </Paper>
                        <Divider />
                        <Paper square>
                            <Box component='div' className='results-options-bottom'>
                                <Box>
                                    <FormControl
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}
                                        className='bottom-options side-left'
                                    >
                                        <GraphType type='pie' dimension='SPA' chart='B1' />
                                        <Dropdown
                                            options={['Raw Values', '% Per Stakeholder']}
                                            valueSetter={setSelectedValueType}
                                            value={selectedValueType}

                                        />
                                    </FormControl>
                                </Box>
                                <Box>
                                    <FormControl
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            '@media (max-width: 1680px)': {
                                                gap: '1vw',
                                            },
                                        }}
                                        className='bottom-options side-right'
                                    >
                                        <ChartDownload chartData={chartJSData}
                                            
                                            chartName={'Stakeholder level result - Product System'} chartType='Pie Chart' />
                                        <GraphSetting
                                            chartOptions={chartOptions}
                                            setApplyGraphSettings={setApplyGraphSettings}
                                            triggerChartUpdate={triggerChartUpdate}
                                            colorIdentifiers={colorIdentifiers}
                                            chartData={chartJSData}
                                            chartColorType='treemap'
                                            graphType='pie'
                                            noColors
                                        />
                                    </FormControl>
                                </Box>
                            </Box>
                        </Paper>
                    </Box>
                </Box>
                <Box className={classes.chartSec}>
                    <Paper
                        square
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <Box className={classes.chartSec} id="PieChart">
                            <canvas id='graph1Canvas' className='graph-canvas' />
                        </Box>
                    </Paper>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default B1PieSPA;
