/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { useEffect, useState } from 'react';
import {
    InputLabel,
    Box,
    Typography,
    Button,
    FormGroup,
    MenuItem,
    Select,
    Paper,
    styled,
} from '@mui/material';
import { ThemeProvider } from '@emotion/react';
// import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Popper from '@mui/material/Popper';
import SocialInfoPopupTheme from './SocialInfoPopupTheme';
import IconButton from '@mui/material/IconButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import './SocialInfoPopupTheme.css';

interface SocialInfoPopupProps {
    selectedImpactMethod: string,
}

function SocialInfoPopup(
    props: SocialInfoPopupProps,
) {
    const [anchorPopup, setAnchorPopup] = React.useState<null | HTMLElement>(null);

    const { selectedImpactMethod } = props;

    const handlePopupClicker = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorPopup(anchorPopup ? null : event.currentTarget);
    };

    const open = Boolean(anchorPopup);
    const id = open ? 'info-popper' : undefined;

    /* This will be moved to the Theme page soon */

    const ButtonWrapper = styled(IconButton)(({ theme }) => {return {
        width: 'auto',
        color: '#004021',
        '&:hover': {
            color: '#89b8a2',
        },
    }});

    return (
        <ThemeProvider theme={SocialInfoPopupTheme}>
            <IconButton
                aria-describedby={id}
                type="button"
                onClick={handlePopupClicker}
                sx={{
                    width: 'auto',
                    color: '#004021',
                    '&:hover': {
                        color: '#89b8a2',
                    },
                }}
            >
                <InfoOutlinedIcon width={50} sx={{ fontSize: '2vw' }} />
            </IconButton>
            <Popper
                id={id}
                open={open}
                anchorEl={anchorPopup}
                placement='right'
                sx={{
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    alignItems: 'center',
                }}
            >
                <Box
                    sx={{
                        padding: '0.2rem',
                        border: '1px solid',
                        borderColor: '#707070',
                        margin: '0.5rem',
                        marginLeft: 0,
                        boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.1)',
                        backgroundColor: 'white',
                        fontSize: '0.7vw',
                    }}
                >
                    Info for
                    {' '}
                    {selectedImpactMethod}
                    .
                </Box>
                <div id="tail1"></div>
                {/* <div id="tail2"></div> */}
            </Popper>
        </ThemeProvider>
    );
}

export default SocialInfoPopup;
