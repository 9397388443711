import React, { useEffect, useMemo, useState } from 'react';
import {
    Box,
    Divider,
    FormControl,
    Paper,
    ThemeProvider,
} from '@mui/material';
import useStyles from './B1TreeSPAstyles';
import autoRound from '../../../../../../helper/autoRound';
import { treemapRGB, getColorsForStakeholders } from '../../../../../../helper/colors';
import {
    ChartJSData,
    ChartJSOptions,
    TreemapData, TreemapLegend,
} from '../../../../../../interface/ChartData';
import { Product, SPAResult } from '../../../../../../interface/Product';
import SPAChartNavigation from '../../../../../dropdowns/BaseOptions/SPAChartNavigation';
import { RechartTreemap } from '../../../../Treemap';
import ChartDownload from '../../../../options/components/ChartDownload';
import GraphSetting from '../../../../options/components/GraphSettings';
import ResultsTheme from '../../../../style/ResultsTheme';
import GraphType from '../../../../options/components/GraphTypeOptions';
import { WeighingSet, applyWeight, getWeighingSetValue, weighingMethods } from '../../../../../../helper/weighingMethod';
import Dropdown from '../../../../../dropdowns/Dropdown';
import CircleIcon from '@mui/icons-material/Circle';
import { initialOptions } from '../../../../options/InitialOptionValues';
import { ChartColorIdentifiers } from '../../../../options/components/GraphSettingsOptions';

interface ComponentProps {
    product: Product,
}

function B1TreeSPA(props: ComponentProps) {
    const classes = useStyles();
    const { product } = props;
    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
    const [treemapData, setTreemapData] = useState<TreemapData[]>([]);
    const [results, setResults] = useState<SPAResult>();
    const [currentWeighingSet, setCurrentWeighingSet] = useState<WeighingSet>();
    const [chartJSData, setChartJSData] = useState<ChartJSData>({ datasets: [], labels: [] });
    const [chartOptions, setChartOptions] = useState<ChartJSOptions>(initialOptions);
    const [colorIdentifiers, setColorIdentifiers] = useState<ChartColorIdentifiers[]>([]);
    const [selectedValueType, setSelectedValueType] = useState<string>('');
    const [treemapLegends, setTreemapLegends] = useState<TreemapLegend[]>([]);

    useMemo(() => {
        const calc = product.calculations.find(
            (item) => item.calculationType === 'SOCIAL-SPA',
        );
        const calcResults = calc?.calculationResults as unknown as SPAResult;
        const weighingMethod = weighingMethods.find((method) => method.name === product.socialData.weighingMethod.name);
        const weighingSet = weighingMethod?.sets.find((set) => set.name === product.socialData.weighingMethod.set);
        setCurrentWeighingSet(weighingSet);
        setResults(calcResults);
    }, [product]);

    useEffect(() => {
        if (!results || !currentWeighingSet) return;

        // Prepare legends and treemap data
        let legends: TreemapLegend[] = [];
        const chartData: TreemapData[] = [{ name: product.productName, children: [] }];
        let tMapLegends: TreemapLegend[] = [];

        console.log(results);
        results.stakeholderResults.forEach((stakeholder) => {
            const val = Math.round(
                stakeholder.SPAPriorities.reduce((val1, val2) => {
                    return val1 + applyWeight(val2, "sam", currentWeighingSet);
                }, 0) * 100
            ) / 100;

            legends.push({ text: stakeholder.name, value: val });
            chartData[0].children.push({ name: stakeholder.name, size: val });
        });
        results.lifeCycleResults.forEach((lifeCycle) => {
            lifeCycle.processResults.forEach((process) => {
                process.PSILCAPriorities
                    .map(priority => priority.category.split(":")[0])
                    .filter((value, index, self) => self.indexOf(value) === index).forEach((stakeholder) => {
                        const val =
                            Math.round(
                                process.PSILCAPriorities.filter(
                                    (priority) =>
                                        priority.category.split(":")[0] === stakeholder
                                ).reduce((val1, val2) => {
                                    let num = 0;

                                    switch (val2.score) {
                                    case 4:
                                        num =
                                                val2.score * getWeighingSetValue(currentWeighingSet, "High")

                                        break;
                                    case 3:
                                        num =
                                                val2.score *
                                                getWeighingSetValue(currentWeighingSet, "Some")
                                        break;
                                    case 2:
                                        num =
                                                val2.score *
                                                getWeighingSetValue(currentWeighingSet, "Low")
                                        break;
                                    case 1:
                                        num =
                                                val2.score *
                                                getWeighingSetValue(currentWeighingSet, "Low")
                                        break;
                                    default:
                                    }

                                    return val1 + num;
                                }, 0) * 100
                            ) / 100;

                        const data = chartData[0].children.find((item) => item.name === stakeholder);
                        if (data) {
                            data.size += val;
                        } else {
                            chartData[0].children.push({ name: stakeholder, size: val });

                        }

                        // tree.push({
                        //     stakeholder: stakeholder,
                        //     // lifeCycleStage: lifeCycle.lcsName.split(":")[0],
                        //     value: val,
                        // });
                        legends.push({ text: stakeholder, value: val });
                    });
            });
        });



        chartData[0].children.sort((a, b) => b.size - a.size);

        const treemapColors = [treemapRGB[0]].concat(
            getColorsForStakeholders(chartData[0].children.map((item) => item.name))
        );

        const total = legends.reduce((a, b) => a + b.value, 0);
        legends = legends.map((legend) => ({
            ...legend,
            percent: Math.round((legend.value / total) * 100 * 100) / 100,
        }))
            .sort((a, b) => {return b.value - a.value})

            .map((legend, index) => {return { ...legend, color: treemapColors[index + 1] }})
        ;

        console.log(chartData);

        

        setTreemapData(chartData);
        tMapLegends = legends;
        setTreemapLegends(legends);
        console.log(legends);

    }, [results, currentWeighingSet]);


    return (
        <ThemeProvider theme={ResultsTheme}>
            <Box
                sx={{
                    margin: '0',
                    maxWidth: '100%',
                    maxHeight: '-webkit-fill-available',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1vw',
                }}
            >
                <Box component='div' className='results-wrapper'>
                    <Box component='div' className='results-options'>
                        <Paper square>
                            <Box component='div' className='results-options-top'>
                                <FormControl
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: '3rem',
                                        alignItems: 'stretch',
                                    }}
                                >
                                    <SPAChartNavigation product={product} defaultBaseIndex={1} defaultResultIndex={0} />
                                </FormControl>
                            </Box>
                        </Paper>
                        <Divider />
                        <Paper square>
                            <Box component='div' className='results-options-bottom'>
                                <Box>
                                    <FormControl
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}
                                        className='bottom-options side-left'
                                    >
                                        <GraphType type='tree' dimension='SPA' chart='B1' />
                                        <Dropdown
                                            options={['Raw Values', '% Per Process']}
                                            valueSetter={setSelectedValueType}
                                            value={selectedValueType}
                                        />
                                    </FormControl>
                                </Box>
                                <Box>
                                    <FormControl
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            '@media (max-width: 1680px)': {
                                                gap: '1vw',
                                            },
                                        }}
                                        className='bottom-options side-right'
                                    >
                                        <ChartDownload chartData={chartJSData}
                                            
                                            chartName={'Stakeholder level result - Product System'} chartType='Treemap Chart' />
                                        <GraphSetting
                                            chartOptions={chartOptions}
                                            // setApplyGraphSettings={setApplyGraphSettings}
                                            // triggerChartUpdate={triggerChartUpdate}
                                            colorIdentifiers={colorIdentifiers}
                                            chartData={chartJSData}
                                            chartColorType='process'
                                            noColors
                                            graphType='treemap'
                                        />
                                    </FormControl>
                                </Box>
                            </Box>
                        </Paper>
                    </Box>
                </Box>
                <Box className={classes.chartSec}>
                    <Paper
                        square
                        // sx={{
                        //     display: 'flex',
                        //     justifyContent: 'center',
                        // }}
                        className={classes.chartWrapper}
                    >
                        <Box>
                            <div className={classes.treemapLegendWrap}>
                                {treemapLegends.map((legend, index) => (
                                    <div key={index} className={classes.treemapLegendRow}>
                                        <div
                                            className={classes.treemapLegendFont}
                                        >
                                            <CircleIcon sx={{ color: legend.color, fontSize: '1.2vw', verticalAlign: 'middle' }} />
                                        </div>
                                        <div
                                            className={classes.treemapLegendFont}
                                        >
                                            {legend.text}
                                        </div>
                                        <Divider orientation="vertical" variant="middle" flexItem />
                                        <div
                                            className={classes.treemapLegendFont}
                                        >
                                            {legend.value.toLocaleString(navigator.language, {
                                                minimumFractionDigits: 0,
                                            })}
                                        </div>
                                        <Divider orientation="vertical" variant="middle" flexItem />
                                        <div
                                            className={classes.treemapLegendFont}
                                        >
                                            {legend.percent + "%"}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </Box>
                        <Box className={classes.chartSec} id = "TreemapChart">
                            
                            <RechartTreemap data={treemapData} />
                            {/* <canvas id='graph1Canvas' className='graph-canvas' /> */}
                        </Box>

                    </Paper>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default B1TreeSPA;
