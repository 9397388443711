import { useState, useMemo, useEffect } from 'react';
import useStyles from './B4SPACharts.styles';
import { ThemeProvider } from '@emotion/react';
import FormControl from '@mui/material/FormControl';
import Dropdown from '../../../../../dropdowns/Dropdown';
import { Box, Paper, Divider } from '@mui/material';
import B4PieSPA from '../B4PieSPA';
import B4BarSPA from '../B4BarSPA';
import deepClone from '../../../../../../helper/deepClone';
import { ChartJSData, ChartJSOptions } from '../../../../../../interface/ChartData';
import { Product, SPAResult } from '../../../../../../interface/Product';
import SPAChartNavigation from '../../../../../dropdowns/BaseOptions/SPAChartNavigation';
import LCSDropdowns from '../../../../../dropdowns/LCSDropdowns';
import ResultsTheme from '../../../../style/ResultsTheme';
import '../../../../style/ResultsTheme.css';
import { initialOptions } from '../../../../options/InitialOptionValues';
import ChartDownload from '../../../../options/components/ChartDownload';
import ProductComparison from '../../../../options/components/Comparison';
import GraphSetting from '../../../../options/components/GraphSettings';
import { applyValueType } from '../../../../options/util/applyOptions';
import { CreateChartTS } from '../../../../util/CreateChartTS';
import { treemapRGB } from '../../../../../../helper/colors';

interface ChartData {
    product: Product;
}
function B4SPACharts(props: ChartData) {
    const classes = useStyles();
    const { product } = props;
    const [chartJSData, setChartJSData] = useState<ChartJSData>({ datasets: [], labels: [] });
    const [chartOptions, setChartOptions] = useState<ChartJSOptions>(initialOptions);
    const [colorIdentifiers, setColorIdentifiers] = useState<ChartColorIdentifiers[]>([]);
    const [selectedValueType, setSelectedValueType] = useState<string>('');
    const [results, setResults] = useState<SPAResult[]>([]);
    const [selectedLCS, setSelectedLCS] = useState<string>('');
    const [selectedLCSS, setSelectedLCSS] = useState<string>('');
    const [selectedProcess, setSelectedProcess] = useState<string>('');

    useMemo(() => {
        const calc = product.calculations.find(
            (item) => { return item.calculationType === 'SOCIAL-SPA' },
        );
        const calcResults = calc?.calculationResults as SPAResult[];
        setResults((calcResults));
    }, [product]);






    useEffect(() => {
        if (chartJSData && chartOptions) {
            const applyOptionReturn = applyValueType(selectedValueType, chartJSData, chartOptions);
            const updatedOptions = applyOptionReturn.options;
            const updatedChartData = applyOptionReturn.chartData;
            CreateChartTS(updatedChartData, updatedOptions, 'pie', 'graph2Canvas');
        }
    }, [chartJSData, chartOptions]);

    return (
        <ThemeProvider theme={ResultsTheme}>
            <Box
                sx={{
                    margin: '0',
                    maxWidth: '100%',
                    maxHeight: '-webkit-fill-available',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1vw',
                }}
            >
                <Box className='temp-box' />
                <Box component='div' className='results-wrapper'>
                    <Box component='div' className='results-options'>
                        <Paper square>
                            <Box component='div' className='results-options-top'>
                                <FormControl
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: '3rem',
                                        alignItems: 'stretch',

                                    }}
                                >
                                    <SPAChartNavigation product={product} defaultBaseIndex={1} defaultResultIndex={3} />
                                    <ProductComparison />
                                </FormControl>
                            </Box>
                        </Paper>
                        <Divider />
                        <Paper square>
                            <Box component='div' className='results-options-bottom'>
                                <Box>
                                    <FormControl
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}
                                        className='bottom-options side-left'
                                    >
                                        <LCSDropdowns
                                            product={product}
                                            selectedLCS={selectedLCS}
                                            setSelectedLCS={setSelectedLCS}
                                            selectedLCSS={selectedLCSS}
                                            setSelectedLCSS={setSelectedLCSS}
                                            selectedProcess={selectedProcess}
                                            setSelectedProcess={setSelectedProcess}
                                            dimension="Social"
                                        />
                                        <Dropdown
                                            options={['Raw Values', '% Per Process']}
                                            valueSetter={setSelectedValueType}
                                            value={selectedValueType}

                                        />

                                    </FormControl>
                                </Box>
                                <Box>
                                    <FormControl
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            '@media (max-width: 1680px)': {
                                                gap: '1vw',
                                            },
                                        }}
                                        className='bottom-options side-right'
                                    >
                                        {/* <ChartDownload chartData = {chartJSData}  /> */}
                                        {/* <GraphSetting
                                            chartOptions={chartOptions}
                                            setApplyGraphSettings={setApplyGraphSettings}
                                            triggerChartUpdate={triggerChartUpdate}
                                            colorIdentifiers={colorIdentifiers}
                                            chartData = {chartJSData}
                                            chartColorType = 'treemap'
                                        /> */}

                                    </FormControl>
                                </Box>
                            </Box>
                        </Paper>
                    </Box>
                </Box>
                <Box className={classes.chartSec}>
                    <Paper
                        square
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '-webkit-fill-available',
                            height: '-webkit-fill-available',
                        }}
                    >
                        <B4BarSPA
                            product={product}
                            selectedLCS={selectedLCS}
                            selectedLCSS={selectedLCSS}
                            selectedValueType={selectedValueType}
             

                        />

                    </Paper>
                    <Paper
                        square
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '-webkit-fill-available',
                            height: '-webkit-fill-available',
                        }}
                    >
                        <B4PieSPA
                            product={product}
                            selectedLCS={selectedLCS}
                            selectedLCSS={selectedLCSS}
                            selectedProcess={selectedProcess}
                            selectedValueType={selectedValueType}
             
                        />
                    </Paper>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default B4SPACharts;
