import React, { useEffect, useMemo, useState } from 'react';
import {
    Box,
    Divider,
    FormControl,
    Paper,
    ThemeProvider,
} from '@mui/material';
import useStyles from './B1TreePSILCAstyles';
import autoRound from '../../../../../../helper/autoRound';
import { treemapRGB, getColorsForStakeholders } from '../../../../../../helper/colors';
import {
    ChartJSData, ChartJSOptions, TreemapData, TreemapLegend,
} from '../../../../../../interface/ChartData';
import { Product, PSILCAResult } from '../../../../../../interface/Product';
import PSILCAChartNavigation from '../../../../../dropdowns/BaseOptions/PSILCAChartNavigation';
import { RechartTreemap } from '../../../../Treemap';
import ImpactCategoryDropdown from '../../../../../dropdowns/ImpactCategoryDropdown';
import ChartDownload from '../../../../options/components/ChartDownload';
import GraphSetting from '../../../../options/components/GraphSettings';
import ResultsTheme from '../../../../style/ResultsTheme';
import GraphType from '../../../../options/components/GraphTypeOptions';
import Dropdown from '../../../../../dropdowns/Dropdown';
import CircleIcon from '@mui/icons-material/Circle';
import { initialOptions } from '../../../../options/InitialOptionValues';

interface ComponentProps {
    product: Product,
}

function B1TreePSILCA(props: ComponentProps) {
    const classes = useStyles();
    const { product, datalabelFontFamily } = props;
    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
    const [chartJSData, setChartJSData] = useState<ChartJSData>({ datasets: [], labels: [] });
    const [chartOptions, setChartOptions] = useState<ChartJSOptions>(initialOptions);
    const [colorIdentifiers, setColorIdentifiers] = useState<ChartColorIdentifiers[]>([]);
    const [selectedValueType, setSelectedValueType] = useState<string>('');
    const [results, setResults] = useState<PSILCAResult[]>([]);
    const [treemapData, setTreemapData] = useState<TreemapData[]>([]);
    const [treemapLegends, setTreemapLegends] = useState<TreemapLegend[]>([]);

    useMemo(() => {
        const calc = product.calculations.find(
            (item) => {return item.calculationType === 'SOCIAL-PSILCA'},
        );
        const calcResults = calc?.calculationResults as PSILCAResult[];
        setResults((calcResults));
    }, [product]);

    useEffect(() => {
        // Treemap chart
        let legends: TreemapLegend[] = [];
        const tMapLegends: TreemapLegend[] = [];

        const chartData: TreemapData[] = [{ name: product.productName, children: [] }];

        results.forEach((stakeholder) => {
            const val = stakeholder.subcategories
                .map((subcategory) => {return subcategory.indicators
                    .map((indicator) => {return indicator.indicatorResults
                        .map((lcs) => {return lcs.processResults.lcsTotalSum})
                        .reduce((a: number, b: number) => {return a + b}, 0)})
                    .reduce((a: number, b: number) => {return a + b}, 0)})
                .reduce((a: number, b: number) => {return a + b}, 0);
            legends.push({ text: stakeholder.stakeholder, value: val, percent:0, color:'' });
            // tree.push({
            //     name: stakeholder.stakeholder,
            //     value: val,
            // });
            chartData[0].children.push({ name: stakeholder.stakeholder, size: val });
        });
        chartData[0].children.sort((a, b) => {return b.size - a.size});

        const total = legends.reduce((a, b) => {return a + b.value}, 0);

        legends = legends.map((legend) => {return {
            ...legend,
            percent:
                Math.round((legend.value / total) * 100 * 100) / 100,
        }});

        const treemapColors: string[] = [treemapRGB[0]].concat(
            getColorsForStakeholders(chartData[0].children.map((item) => {return item.name})),
        );

        // chartData.datasets = [
        //     {
        //         label: product.productName,
        //         tree,
        //         key: 'value',
        //         groups: ['lifeCycleStage', 'stakeholder'],

        //         borderColor: 'white',
        //         borderWidth: 0,
        //         spacing: 4,
        //         // backgroundColor: function (value : any) {
        //         //     return treemapColors[value.index]
        //         // },
        //     },
        // ];

        legends = legends
            .sort((a, b) => {return b.value - a.value})
            .map((legend, index) => {return { ...legend, color: treemapColors[index + 1] }})
            .map((legend) => {
                // if (graph1Notation === 'Exponential') {
                //     legend.value = autoRound(legend.value, 3).toExponential();
                // } else {
                //     legend.value = autoRound(legend.value, 3);
                // }
                legend.value = autoRound(legend.value, 3);

                return legend;
            });
        setTreemapData(chartData);
        setTreemapLegends(legends);
    }, [selectedValueType, selectedCategories]);

    const triggerChartUpdate = () => {

    };

    return (
        <ThemeProvider theme={ResultsTheme}>
            <Box
                sx={{
                    margin: '0',
                    maxWidth: '100%',
                    maxHeight: '-webkit-fill-available',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1vw',
                }}
            >
                <Box component='div' className='results-wrapper'>
                    <Box component='div' className='results-options'>
                        <Paper square>
                            <Box component='div' className='results-options-top'>
                                <FormControl
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: '3rem',
                                        alignItems: 'stretch',
                                    }}
                                >
                                    <PSILCAChartNavigation product={product} defaultBaseIndex={1} defaultResultIndex={0} />
                                </FormControl>
                            </Box>
                        </Paper>
                        <Divider />
                        <Paper square>
                            <Box component='div' className='results-options-bottom'>
                                <Box>
                                    <FormControl
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}
                                        className='bottom-options side-left'
                                    >
                                        <GraphType
                                            type='tree'
                                            dimension='PSILCA'
                                            chart='B1'
                                        />
                                        <Dropdown
                                            options={['Raw Values', '% Per Process']}
                                            valueSetter={setSelectedValueType}
                                            value={selectedValueType}
                                        />
                                    </FormControl>
                                </Box>
                                <Box>
                                    <FormControl
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            '@media (max-width: 1680px)': {
                                                gap: '1vw',
                                            },
                                        }}
                                        className='bottom-options side-right'
                                    >
                                        <ChartDownload chartData 
                                            = {chartJSData} 
                                        chartName={'Stakeholder level result - Product System'} chartType='Treemap Chart' />
                                        <GraphSetting
                                            chartOptions={chartOptions}
                                            // setApplyGraphSettings={setApplyGraphSettings}
                                            // triggerChartUpdate={triggerChartUpdate}
                                            colorIdentifiers={colorIdentifiers}
                                            chartData = {chartJSData}
                                            chartColorType = 'process'
                                            noColors
                                            graphType='treemap'
                                        />

                                    </FormControl>
                                </Box>
                            </Box>
                        </Paper>
                    </Box>
                </Box>
                <Box className={classes.chartSec}>
                    <Paper
                        square
                        // sx={{
                        //     display: 'flex',
                        //     justifyContent: 'center',
                        // }}
                        className={classes.chartWrapper}
                    >
                        <Box>
                            <div className={classes.treemapLegendWrap}>
                                {treemapLegends.map((legend, index) => (
                                    <div key={index} className={classes.treemapLegendRow}>
                                        <div
                                            className={classes.treemapLegendFont}
                                        >
                                            <CircleIcon sx={{ color: legend.color, fontSize: '1.2vw', verticalAlign: 'middle' }} />
                                        </div>
                                        <div
                                            className={classes.treemapLegendFont}
                                        >
                                            {legend.text}
                                        </div>
                                        <Divider orientation="vertical" variant="middle" flexItem />
                                        <div
                                            className={classes.treemapLegendFont}
                                        >
                                            {legend.value.toLocaleString(navigator.language, {
                                                minimumFractionDigits: 0,
                                            })}
                                        </div>
                                        <Divider orientation="vertical" variant="middle" flexItem />
                                        <div
                                            className={classes.treemapLegendFont}
                                        >
                                            {legend.percent + "%"}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </Box>
                        <Box className={classes.chartSec} id = "TreemapChart">
                            
                            <RechartTreemap data={treemapData}
                                fontFamily={datalabelFontFamily}
                                triggerChartUpdate={triggerChartUpdate}
                                colorIdentifiers={colorIdentifiers}


                            />
                            {/* <canvas id='graph1Canvas' className='graph-canvas' /> */}
                        </Box>

                    </Paper>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default B1TreePSILCA;
