import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => {return {
    chartSec: {
        display: 'grid',
        gap: '1vw',
        width: '100%',
        height: '400px',
        gridTemplateColumns: '1fr 1fr',
    },
}});

export default useStyles;
