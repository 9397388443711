import { gql } from 'graphql-request';

const CHART_DATA = () => {return gql`
query{
    chart {
      sales
      tax
      month
    }
  }
`};

export default {
    CHART_DATA,
};
