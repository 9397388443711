/**
 * Function to encode values before storing in local storage
 */
export function encodeToken(token: string) {
    const uint8Array = new TextEncoder().encode(token);
    const encodedString = Array.from(uint8Array)
        .map(byte => String.fromCharCode(byte))
        .join('');
    return btoa(encodedString);
}


/**
 * Function to decode values taken from local storage
 */
export function decodeToken(token: string) {
    const decoded = atob(token);
    const uint8Array = new Uint8Array(Array.from(decoded).map(char => char.charCodeAt(0)));
    return new TextDecoder().decode(uint8Array);
}

/**
 * Function to fetch and decode items from local storage
 */
export function getItemFromLocalStorage(item: string) {
    const value = localStorage.getItem(item);
    if (value) {
        const decodedValue = decodeToken(value);
        return decodedValue;
    }
    return value;
}

/**
 * Function to fetch and decode items from local storage
 */
export function getItemFromSessionStorage(item: string) {
    const value = sessionStorage.getItem(item);
    // if (value) {
    //     const decodedValue = decodeToken(value);
    //     return decodedValue;
    // }
    return value;
}

/**
 * Function to encode items and set in session storage
 */
export function setItemToSessionStorage(item: string, value: string) {
    // const encodedValue = encodeToken(value); // TODO REIMPLEMENT THIS
    sessionStorage.setItem(item, value);
}

/**
 * Function to set access token in session storage
 */
export function setAccessTokenInSessionStorage(token: string) {
    return setItemToSessionStorage('access_token', token);
}

/**
 * Function to encode items and set in local storage
 */
export function setItemToLocalStorage(item: string, value: string) {
    const encodedValue = encodeToken(value);
    localStorage.setItem(item, encodedValue);
}

/**
 * Function to set access token in local storage
 */
export function setAccessTokenInLocalStorage(token: string) {
    return setItemToLocalStorage('access_token', token);
}

/**
 * Function to fetch access token from local storage
 */
export function getAccessTokenFromLocalStorage() {
    return getItemFromLocalStorage('access_token');
}

/**
 * Function to fetch access token from session storage
 */
export function getAccessTokenFromSessionStorage() {
    return getItemFromSessionStorage('access_token');
}

export function getCookie(cname: string): string {
    const name = cname + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
}

/**
* Function to fetch access token from cookies
*/
export function getAccessTokenFromCookies() {
    return getCookie('access_token');
}
