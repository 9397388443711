import React, { useContext, useEffect, useMemo, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import TableTheme from '../../../../style/components/TableTheme';
import {
    createTheme,
    styled,
    ThemeProvider,
    useMediaQuery,
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ExitButton from '../../../../style/components/ExitButton';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import { Unstable_Popup as Popup } from '@mui/base/Unstable_Popup';
import SelectSocialProcessPopup from '../../components/SelectSocialProcessPopup';
import AddButton from '../../../../style/components/AddButton';
import Popper, { PopperProps } from '@mui/material/Popper';
import { FormApi, useForm } from '@tanstack/react-form';
import {
    EnvInputProcess,
    SocialInputProcess,
    SocialInputsForm,
    SocialInputsLCS,
} from '../../../../../interface/Form';
import { SocialInputProcessListItem } from '../SocialInputProcessListItem';
import { SelectProcess } from '../../../../../interface/Process';
import { fetchUnitGroups } from '../../../../../helper/fetchUtil';
import { UnitGroup, findUnitUnitGroup } from '../../../../../interface/Unit';
import { useFormContext } from '../../../../forms/FormContext';
import useTheme from '@mui/styles/useTheme';
import { SocialInputProcessListItemTemp } from '../SocialInputProcessListItemTemp';
import { Height } from '@mui/icons-material';

function SocialInputProcessList({
    socialForm,
    lifeCycleStage,
}: {
  socialForm: FormApi<SocialInputsForm>;
  lifeCycleStage: string;
}) {
    const [processPopupEnv, setProcessPopupEnv] = useState<null | HTMLElement>(
        null
    );

    const { formState, setFormState } = useFormContext();

    const [currentProcesses, setCurrentProcesses] = useState<
    SocialInputProcess[]
  >([]);

    const addNewProcess = (process: SelectProcess) => {
        if (process) {
            const newIndex = currentProcesses.length;
            const newProcess = {
                index: newIndex,
                endProductName: '',
                processId: process.refId || process.id,
                processName: process.name + ' - ' + process.location,
                processQuantity: 1,
                processComment: '',
                category:process.category,

            };
            setCurrentProcesses(currentProcesses.concat(newProcess));
        }
    };

    //fetch stages from formstate
    const loadInputs = () => {
        let stages: SocialInputsLCS[] = [];
        if (formState && formState.socialInputProcesses) {
            stages = formState.socialInputProcesses as SocialInputsLCS[];
        }
        return stages;
    };

    //load processes from matching lcs from tanstack form
    const loadProcesses = () => {
        let stages: SocialInputsLCS[] = [];
        if (lifeCycleStage) {
            stages = socialForm.getFieldValue('socialInputProcesses');
            if (stages && stages.length > 0) {
                const matchingLCS = stages.find((stage) => { return stage.lcs === lifeCycleStage });

                setCurrentProcesses(matchingLCS?.values || []);
            }

        }
        return stages;
    };

    useEffect(() => {
        if (lifeCycleStage) {
            const listStages = loadProcesses();

            //if process list from form is empty, try to load it from formState
            if (listStages.length === 0) {
                const stages: SocialInputsLCS[] = loadInputs();
                const matchingLCS = stages.find(
                    (stage) => { return stage.lcs === lifeCycleStage }
                );
                if (matchingLCS) {
                    setCurrentProcesses(matchingLCS.values);
                } else {
                    setCurrentProcesses([]);
                }
            }
        }
    }, [lifeCycleStage]);
    ;

    const [selectHandler, setSelectHandler] = useState<
    (process: SelectProcess) => void
        >(() => {return addNewProcess});

    const showValues = () => {
        const processes = socialForm.getFieldValue('socialInputProcesses');
        console.log(processes);
    };

    useEffect(() => {
        if (currentProcesses.length > 0) {
            const stages = socialForm.getFieldValue('socialInputProcesses');
            if (stages) {
                const matchingStage = stages.find(
                    (stage) => {return stage.lcs === lifeCycleStage}
                );
                if (matchingStage) {
                    matchingStage.values = currentProcesses;
                } else {
                    stages.push({ lcs: lifeCycleStage, values: currentProcesses });
                }
            }
            socialForm.setFieldValue('socialInputProcesses', stages);
        }
    }, [currentProcesses]);

    const open = Boolean(processPopupEnv);
    const processPopper = open ? 'process-popper' : undefined;

    const openProcessSelect = (event: React.MouseEvent<HTMLElement>) => {
        setProcessPopupEnv(processPopupEnv ? null : event.currentTarget);
    };
    const selectAddedProcessHandler = (event: React.MouseEvent<HTMLElement>) => {
        setSelectHandler(() => {return addNewProcess});
        openProcessSelect(event);
    };

    const InputTableCell = styled(TableCell)(({ theme }) => {return {
        width: '-webkit-fill-available',
        fontSize: '0.7vw',
        lineHeight: '1.1vw',
        padding: '5px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#004021',
            color: 'white',
            height: '3vw',
        },
        [`&.${tableCellClasses.body}`]: {
            // fontSize: 14,
        },
        '@media (max-width: 1680px)': {
            padding: '5px',
            // fontSize: 10,
            // lineHeight: '15px',
        },
        '&:last-of-type': {
            width: '-webkit-fill-available',
            padding: '0',
            display: 'flex',
            justifyContent: 'center',
            color: '#004021',
            // height: '5.5rem',
            '@media (max-width: 1680px)': {
                // width: '7rem',
            },
        },
    }});

    const InputTableRow = styled(TableRow)(({ theme }) => {return {
        padding: '5px',
        fontSize: '12px',
        color: '#707070',
        display: 'grid',
        gridTemplateColumns: '1fr',
        '&:nth-of-type(odd)': {
            backgroundColor: '#F3F3F3',
        },
        '&:nth-of-type(even)': {
            backgroundColor: '#E6E6E6',
        },
        '@media (max-width: 1680px)': {
            padding: '5px',
            fontSize: 10,
            lineHeight: '15px',
        },
    }});

    const deleteHandler = (processIndex: number) => {
        setCurrentProcesses(currentProcesses.filter((item, index) => {return index != processIndex}));

    }

    return (
        <ThemeProvider theme={TableTheme}>
            <Box>
                <button type='button' onClick={showValues}
                    style={{ position: 'absolute' }}

                >
          TEST
                </button>

                <TableContainer
                    sx={{
                        width: '-webkit-fill-available',
                    }}
                >
                    <Table>
                        <TableHead>
                            <TableRow
                                sx={{
                                    display: 'grid',
                                    gridTemplateColumns: '1fr 2fr 1fr 1fr 5rem',
                                }}
                            >
                                <InputTableCell align='center'>End Product</InputTableCell>
                                <InputTableCell align='center'>Process</InputTableCell>
                                <InputTableCell align='center'>
                  Amount (USD value) per one unit of analysis
                                </InputTableCell>
                                <InputTableCell align='center'>Comment</InputTableCell>
                                <InputTableCell align='center'> </InputTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody
                            sx={{
                                display: 'flex',
                                overflowY: 'auto',
                                maxHeight: '32vh',
                                flexDirection: 'column',
                                '::-webkit-scrollbar': {
                                    width: '0.2em',
                                },
                                '::-webkit-scrollbar-track': {
                                    background: '#939598',
                                    border: 0,
                                },
                                '::-webkit-scrollbar-thumb': {
                                    backgroundColor: '#32463e',
                                    border: 0,
                                },
                            }}
                        >
                            {currentProcesses.map((process, index) => {return (
                                <SocialInputProcessListItem
                                    key={process.processName + index.toString()}
                                    socialForm={socialForm}
                                    index={index}
                                    openProcessSelect={openProcessSelect}
                                    setSelectHandler={setSelectHandler}
                                    process={process}
                                    lifeCycleStage={lifeCycleStage}
                                    deleteHandler={deleteHandler}

                                />
                            )})}
                            {/* <SocialInputProcessListItemTemp /> */}
                            <Popper
                                id={processPopper}
                                open={open}
                                anchorEl={processPopupEnv}
                                disablePortal
                                style={{
                                    position: 'fixed',
                                    transform: 'none !important',
                                }}
                            >
                                <Box
                                    sx={{
                                        position: 'fixed !important',
                                        transform: 'none !important',
                                        top: '0 !important',
                                        left: '0 !important',
                                        zIndex: 1300,
                                        width: '100%',
                                        height: '-webkit-fill-available',
                                        backgroundColor: 'rgba(0, 0, 0, 0.54)',
                                    }}
                                >
                                    <SelectSocialProcessPopup
                                        selectHandler={selectHandler}
                                        openProcessSelect={openProcessSelect}
                                    />
                                </Box>
                            </Popper>
                        </TableBody>
                    </Table>
                </TableContainer>
                <ThemeProvider theme={AddButton}>
                    <Button
                        size='small'
                        id='process-popup'
                        onClick={(e) => {
                            selectAddedProcessHandler(e);
                        }}
                    >
            Add
                        <AddCircleIcon />
                    </Button>
                </ThemeProvider>
            </Box>
        </ThemeProvider>
    );
}

export default SocialInputProcessList;
