import React, { useState, useEffect } from 'react';
import {
    Box,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import useStyles from './ProductTitle.styles';

function ProductTitle() {
    const classes = useStyles();

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'stretch',
            }}
        >
            {/* <Typography component='h2' sx={{ textAlign: 'center' }} className={classes.Title}>Tossa Product</Typography> */}
            {/* {loadedProduct && <D1BarChart product={loadedProduct} />} */}
        </Box>
    );
}
export default ProductTitle;
