import React, { useState, useEffect } from 'react';
import {
    Box, Button, FormControl, FormLabel, IconButton, InputAdornment, Link, TextField, Typography,
} from '@mui/material';
import authStyles from '../Auth.style';
import logo from '../../../assets/tossa.png';
import bgImg from '../../../assets/img1.jpg';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { ILoginErrorState, ILoginFormState } from '../../../interface/Auth';
import { emailValidator, passwordValidator, capitalize } from '../../../helper/validation';
import { useGQLQuery } from '../../../hooks/useGQLQuery';
import authQuery from '../../../queries/auth';
import { URL_CONF } from '../../../helper/constants';
import { redirect, useNavigate } from '@tanstack/react-router';
import Loader from '../../../components/loader';
import toast from 'react-hot-toast';
import { setAccessTokenInLocalStorage, setAccessTokenInSessionStorage } from '../../../helper/StorageHelper';
import { sendRequest } from '../../../helper/fetchUtil';

function Login() {
    const classes = authStyles();
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const navigate = useNavigate();
    const [state, setState] = useState<ILoginFormState>({
        email: '',
        password: '',
    });
    const [errorState, setErrorState] = useState<ILoginErrorState>({
        email: '',
        password: '',
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setState((prevState) => {return {
            ...prevState,
            [name]: value,
        }});
    };

    interface LoginResponse {
        token: any,
        role: string,
        name: string,
    }

    const submitLogin = async (): Promise<LoginResponse> => {
        const responseData = await sendRequest<LoginResponse>(
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            `${process.env.REACT_APP_BACKEND_URL}/user/authenticate`,
            'POST',
            JSON.stringify({
                password: state.password,
                username: state.email,
            }),
            { 'Content-Type': 'application/json' },
        );
        return responseData;
    };

    // const responseData = await sendRequest(
    //     "POST",
    //     `${process.env.REACT_APP_BACKEND_URL}/api/user/authenticate`,
    //     JSON.stringify({
    //         password: formState.inputs.loginPassword.value,
    //         username: formState.inputs.loginUsername.value,
    //     }),
    //     { "Content-Type": "application/json" }
    // );

    const {
        data, isFetching, refetch, error,
    } = useGQLQuery(
        'login',
        authQuery.LOGIN(
            state.email.toLowerCase(),
            state.password,
        ),
        {},
        {
            enabled: false,
        },
        URL_CONF.AUTHENTICATION,
    );

    const validateFields = (name: string, value: string) => {
        if (value.trim().length === 0) {
            setErrorState((prevState) => {return {
                ...prevState,
                [name]: `${capitalize(name)} is required`,
            }});
            return;
        }
        switch (name) {
        case 'email':
            // setErrorState((prevState) => ({
            //     ...prevState,
            //     email: !emailValidator(value) ? 'Please enter a valid email address.' : null,
            // }));
            break;
        case 'password':
            // setErrorState((prevState) => ({
            //     ...prevState,
            //     password: !passwordValidator(value) ? `Password must contain 8-20 characters, including at least one uppercase letter,
            // one lowercase letter, one number, and one special character.` : null,
            // }));
            break;
        default:
            break;
        }
    };

    // Function to validate data
    const validateCredentials = () => {
        const { email, password } = state;
        if (emailValidator(email) && passwordValidator(password)) {
            return true;
        }
        validateFields('email', email);
        validateFields('password', password);
        return false;
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        submitLogin().then((response) => {
            setAccessTokenInSessionStorage(response.token as string);
            if (response.token) {
                navigate({ to: '/app' })
                    .then((val) => { })
                    .catch((err) => { });
            }
        }).catch((err) => { });

        if (validateCredentials()) {
            // refetch();

        }
    };

    // Reset error on Focus
    const resetErrorCode = (e: React.FocusEvent<HTMLInputElement>) => {
        const { name } = e.target;
        setErrorState((prevState) => {return {
            ...prevState,
            [name]: '',
        }});
    };

    const validate = (e: React.FocusEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        validateFields(name, value);
    };

    useEffect(() => {
        if (data && data.login) {
            setAccessTokenInLocalStorage('12345');
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            navigate({
                from: '/login',
                to: '/',
            });
        }
    }, [data]);

    useEffect(() => {
        if (error !== null) {
            const message = error && error.response && error.response.errors
                && error.response.errors[0] ? error.response.errors[0].message : 'API failed';
            if (message) {
                toast.error(message);
            }
        }
    }, [error]);

    return (
        <Box className={classes.root}>
            <Box className={`${classes.leftBox} rootBox`}>
                <img src={bgImg} alt='mountain' />
                <Box className={classes.overlay}>
                    <Typography variant='h1'>
                        Empowering for the best sustainable decisions
                        <br/>
                        <Typography component='span'>
                            “Power and Simplicity”
                        </Typography>
                    </Typography>
                </Box>
            </Box>
            <Box className={`${classes.rightBox} rootBox`}>
                <Loader isFromAGGrid loading={isFetching} />
                <Box className={classes.mainWrapper}>
                    <img src={logo} alt='Tossa' className={classes.logo} />
                    <Typography className={classes.header}>Login</Typography>
                    <form className={classes.form} onSubmit={handleSubmit}>
                        <FormControl className={classes.formControl}>
                            <FormLabel htmlFor='emailField'>Email Address</FormLabel>
                            <TextField
                                type='text'
                                id='emailField'
                                name='email'
                                placeholder='Please Enter Your Email'
                                value={state.email}
                                onChange={handleChange}
                                onFocus={resetErrorCode}
                            // onBlur={validate}
                            />
                            <span className={classes.errorStyle}>{errorState.email}</span>
                        </FormControl>
                        <Box className={classes.FormElementInBox}>
                            <FormControl className={classes.formControl}>
                                <FormLabel htmlFor='passwordField'>Password</FormLabel>
                                <TextField
                                    type={showPassword ? 'text' : 'password'}
                                    id='passwordField'
                                    name='password'
                                    placeholder='Please Enter Your Password'
                                    value={state.password}
                                    onChange={handleChange}
                                    onFocus={resetErrorCode}
                                    // onBlur={validate}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                <IconButton onClick={() => {return setShowPassword(!showPassword)}}>
                                                    {
                                                        showPassword ? (<VisibilityOutlinedIcon sx={{ fontSize: '0.7vw' }} />) : (
                                                            <VisibilityOffOutlinedIcon sx={{ fontSize: '0.7vw' }} />
                                                        )
                                                    }
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <span className={classes.errorStyle}>{errorState.password}</span>
                            </FormControl>
                            <Link href='/' className={classes.Link}>Forgot Password?</Link>
                        </Box>
                        <Box className={classes.FormElementInBox}>
                            <Button type='submit' disableRipple className={classes.signInBtn}>
                                Login
                            </Button>
                            <Typography sx={{ fontSize:'0.7vw' }}>
                                Don&apos;t have an account?
                                {' '}
                                <Link href='/signup' className={classes.Link}>Register here</Link>
                            </Typography>
                        </Box>
                    </form>
                </Box>
            </Box>
        </Box>
    );
}

export default Login;
