import React, { useState } from 'react';
import {
    MenuItem,
    Select,
    Checkbox,
    ListItemText,
    FormControl,
    InputLabel,
    OutlinedInput,
    SelectChangeEvent,
    FormControlLabel,
    Box,
    CheckboxProps,
} from "@mui/material";
import { ThemeProvider, styled } from "@mui/styles";
import "../../../../dropdowns/DropdownTheme.css";
import DropdownTheme from "../../../../style/components/DropdownTheme";
import useStyles from "./styles/SDGLinkageTheme.styles";
import dropdownWrapperStyle from "./styles/DropdownWrapper.styles";
import selectBorderRemove from "./styles/SelectBorderRemove.styles";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { useRouter } from '@tanstack/react-router';

const sdggoals = [
    "SDG Goal #1",
    "SDG Goal #2",
    "SDG Goal #3",
    "SDG Goal #4",
    "SDG Goal #5",
];

function SDGLinkage({ setting, dimension }) {

    const classes = useStyles();
    const dropdownWrapperclasses = dropdownWrapperStyle();
    const borderRemoveClasses = selectBorderRemove();
    const [sdgGoal, setSDGGoal] = useState<string[]>([]);
    const [activateSDG, setActivateSDG] = useState<boolean>(false);

    // Initialize router using useRouter hook
    const router = useRouter();

    const handleChangeSDGLinkage = (event: SelectChangeEvent<typeof sdgGoal>) => {
        const {
            target: { value },
        } = event;
        setSDGGoal(typeof value === "string" ? value.split(",") : value);
    };

    const handleSDGLinkageSwitch = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setActivateSDG(event.target.checked);
    };

    const CheckboxPadding = styled(CheckBoxOutlineBlankIcon)<CheckboxProps>(({ theme }) => {
        return {
            marginLeft: 0,
            marginRight: 0,
            "& .MuiSvgIcon-root": {
                width: "1em",
                height: "1em",
                fontSize: "1.2vw",
            },
        };
    });

    const navigateToSDG = () => {
        const path = `../../../${dimension}/${setting}/SDG/Table`;
        router.navigate({
            to: path,
        }).then((val) => {}).catch((err) => {});
    };

    return (
        <ThemeProvider theme={DropdownTheme}>
            <Box className={dropdownWrapperclasses.DropdownSwitchWrapper}>
                <FormControlLabel
                    control={
                        <CheckboxPadding
                            onClick={navigateToSDG}
                            sx={{
                                padding: 0,
                                marginLeft: 0,
                                marginRight: 0,
                                "& .MuiSvgIcon-root": {
                                    width: "1em",
                                    height: "1em",
                                    fontSize: "1.2vw",
                                    color: 'green',
                                },
                            }}
                        />
                    }
                    sx={{
                        padding: 0,
                        marginLeft: 0,
                        marginRight: 0,
                    }}
                    label=""
                />
                <FormControl
                    sx={{
                        width: 265,
                        borderRadius: 0,
                        height: "auto",
                        "@media (max-width: 1680px)": {
                            width: 220,
                        },
                    }}
                    size="small"
                >
                    <InputLabel
                        id="sdg-linkage-checkbox-label"
                        shrink={false}
                        sx={{
                            color: activateSDG ? "text.primary" : "text.disabled",
                        }}
                    >
                        SDG Linkage
                    </InputLabel>
                    <Select
                        labelId="sdg-linkage-checkbox-label"
                        id="sdg-linkage-checkbox"
                        multiple
                        value={sdgGoal}
                        onChange={handleChangeSDGLinkage}
                        input={<OutlinedInput label="Tag" />}
                        renderValue={(selected) => {
                            return selected.join(", ");
                        }}
                        disabled={!activateSDG}
                        sx={{
                            "&:disabled": {
                                opacity: 0.6,
                            },
                        }}
                        // disableUnderline
                        variant="standard"
                        classes={{
                            root: borderRemoveClasses.select,
                        }}
                    >
                        {sdggoals.map((goal) => {
                            return (
                                <MenuItem key={goal} value={goal}>
                                    <ListItemText
                                        primary={goal}
                                        sx={{ fontSize: "0.7vw", textAlign: "left" }}
                                    />
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </Box>
        </ThemeProvider>
    );
}

export default SDGLinkage;
