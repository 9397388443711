import { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Dropdown from '../../../dropdowns/Dropdown';
import { fetchUnitGroups } from '../../../../helper/fetchUtil';
import { UnitGroup } from '../../../../interface/Unit';
import { FormApi } from '@tanstack/react-form';
import { ProductDetailsForm, loadValuesFromFormContext } from '../../../../interface/Form';
import { FormState, useFormContext } from '../../../forms/FormContext';
import { Product } from '../../../../interface/Product';
import './ProductNameDescForm.css';

function ProductNameDescForm(props: {
    form: FormApi<ProductDetailsForm>, productToEdit: Product | undefined;
}) {
    const { form, productToEdit } = props;
    const [loadedUnitGroups, setLoadedUnitGroups] = useState<UnitGroup[]>([]);
    const [selectableUnits, setSelectableUnits] = useState<string[]>([]);
    const [selectableUnitGroups, setSelectableUnitGroups] = useState<string[]>([]);

    const { formState, setFormState } = useFormContext();

    useEffect(() => {
        fetchUnitGroups()
            .then((val) => {
                setLoadedUnitGroups(val);
            })
            .catch((err) => {
                // Handle the error if needed
            });

    }, []);

    const selectUnitGroupHandler = (selectedUG: string, defaultUnit?: string,): string[] => {
        const unitGroup = loadedUnitGroups.find((item) => { return item.name === selectedUG });
        let units: string[] = [];
        if (unitGroup) {
            units = unitGroup.units.map((item) => { return item.name });
            setSelectableUnits(units);
            const unit = defaultUnit || units[0];
            form.setFieldValue('analysisUnit', unit);

        }
        return units;
    };


    useEffect(() => {
        if (loadedUnitGroups.length > 0) {

       
            const unitGroups = loadedUnitGroups.map((item) => { return item.name });
            setSelectableUnitGroups(unitGroups);
            const unitGroup = formState.analysisUnitGroup || unitGroups[0];
            form.setFieldValue('analysisUnitGroup', unitGroup);
            console.log(formState);
            loadValuesFromFormContext(form, formState, ['productName', 'productDesc', 'analysisUnitQTY', 'analysisUnitGroup', 'analysisUnit']);
            const selectedUnitGroup = loadedUnitGroups.find((ug) => { return ug.name === unitGroup });
      
            selectUnitGroupHandler(unitGroup, selectedUnitGroup?.units[0].name);
        }

    }, [loadedUnitGroups]);

 

    return (
        <Box
            className='product-name-desc-form__content'
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '1vw',
            }}
        >
            <Typography component="h2" variant='h2'>
                Product Details
            </Typography>
            <Box
                className='product-name-desc-section'
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '0.5rem',
                }}
            >
                <Typography component="h3" variant='h3'>
                    Product Name
                </Typography>
                <form.Field
                    name="productName"
                    children={((field) => {
                        return (
                            <TextField
                                id={field.name}
                                className={`${field.name} product-name`}
                                value={field.state.value}
                                onBlur={field.handleBlur}
                                onChange={(e) => { return field.handleChange(e.target.value) }}
                                required
                                label='Required'
                                placeholder='Product Name'
                                size='small'
                                sx={{
                                    width: '100%',
                                }}
                            />
                        )
                    })}
                />

            </Box>
            <Box className='product-name-desc-section'>
                <Typography component="h3" variant='h3'>
                    Product Description
                </Typography>
                <form.Field
                    name="productDesc"
                    children={((field) => {
                        return (
                            <TextField
                                id={field.name}
                                className={field.name}
                                value={field.state.value}
                                onBlur={field.handleBlur}
                                onChange={(e) => { return field.handleChange(e.target.value) }}
                                placeholder='Description'
                                rows={8}
                                multiline
                                size='small'
                                sx={{
                                    width: '100%',
                                }}
                            />
                        )
                    })}
                />

            </Box>
            <Box
                className='product-name-desc-section'
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '0.5rem',
                }}
            >
                <Typography component="h3" variant='h3'>
                    Unit of Analysis
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '0.5rem',
                        // '@media (max-width: 1680px)': {
                        //     flexDirection: 'column',
                        // },
                    }}
                >
                    <form.Field
                        name="analysisUnitQTY"
                        children={(field) => {
                            return (
                                <TextField
                                    id={field.name}
                                    className={field.name}
                                    value={field.state.value}
                                    onBlur={field.handleBlur}
                                    onChange={(e) => { return field.handleChange(Number(e.target.value)) }}
                                    required
                                    label='Required'
                                    type='number'
                                    defaultValue={1}
                                    size='small'
                                    sx={{
                                        width: 'fit-conten',
                                        '& .MuiInputBase-input': {
                                            width: '5vw',
                                            padding: '0.5vw',
                                        },
                                    }}
                                />
                            )
                        }}
                    />
                    <form.Field
                        name="analysisUnitGroup"
                        children={(field) => {
                            return (
                                <Dropdown
                                    options={selectableUnitGroups}
                                    onChange={(e) => {
                                        field.handleChange(e.target.value);
                                        selectUnitGroupHandler(e.target.value)
                                    }}
                                    id={field.name}
                                    className={field.name}
                                    value={field.state.value}
                                    onBlur={field.handleBlur}
                                    noDefaults

                                />
                            )
                        }}
                    />

                    <form.Field
                        name="analysisUnit"
                        children={(field) => {
                            return (
                                <Dropdown
                                    options={selectableUnits}
                                    onChange={(e) => { return field.handleChange(e.target.value) }}
                                    id={field.name}
                                    className={field.name}
                                    value={field.state.value}
                                    onBlur={field.handleBlur}
                                    noDefaults
                                />
                            )
                        }}
                    />

                </Box>
            </Box>
        </Box>
    );
}
export default ProductNameDescForm;
