import React from "react";
import "../Homepage.css";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { Link } from "@tanstack/react-router";

const BioProfileGetachew: React.FC = () => {
    return (
        <div className="website-only">
            <Header />

            <div className="header-container">
                <div className="header-transparent-layer"> </div>
                <img
                    src={require("../../images/headers/pexels-pixabay-461956.jpg")}
                    alt="Mountains under the blue sky"
                />
                <h1>Our Team</h1>
            </div>
            <Link to="/ourteam" className="back">
        &lt; Back to the team
            </Link>
            <div className="box-container bio">
                <div className="bio-profile-detailed">
                    <div className="bio-side-one">
                        <img
                            src={require("../../images/staff-photos/_MG_2420_Final.jpeg")}
                            alt="Getachew"
                        />
                        <h3>Getachew Assefa</h3>
                        <p>Founder, Director and Advisor</p>
                    </div>
                    <div className="bio-side-two">
                        <h3>His bio</h3>
                        <p>
              Professor of Sustainable Design at the University of Calgary. With
              over 25 years of teaching and researching life cycle assessment
              and over the past 10 years life cycle sustainability assessment.
              He has actively participated in and led the development of
              software applications such as EcoEffect, EcoRunner and ORWARE. He
              does consulting in the sustainability positioning of products and
              services.{" "}
                        </p>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
};

export default BioProfileGetachew;
