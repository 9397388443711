/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Box, ThemeProvider } from '@mui/material';

import HeaderDimensionsTheme from '../../../style/HeaderDimensionsTheme';
import '../../../style/HeaderDimensionsTheme.css';
import Button from '@mui/material/Button';
import BookIcon from '@mui/icons-material/Book';
import { useRouterState } from '@tanstack/react-router';

interface ResultsProductHeaderProps {
  env: boolean;
  psilca: boolean;
  spa: boolean;
  eco: boolean;
  navHandler: (dimension: string) => void;
}

function ResultsProductHeader({
    env,
    psilca,
    spa,
    eco,
    navHandler,
}: ResultsProductHeaderProps) {
    const [value, setValue] = React.useState('1');

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const router = useRouterState();

    useEffect(() => {
        if (router.location.pathname.includes('Environmental')) {
            setValue('1');
        }
        else if (router.location.pathname.includes('Social')) {
            setValue('2');
        } else if (router.location.pathname.includes('Economic')) {
            setValue('3');
        }
    }, [router]);

    return (
        <ThemeProvider theme={HeaderDimensionsTheme}>
            <Box
                className='header-wrapper'
                sx={{
                    paddingLeft: '0',
                    paddingRight: '0',
                }}
            >
                <TabContext value={value}>
                    <Button
                        className='edit-data-button'
                        sx={{ 
                            backgroundColor: '#056939', 
                            color: 'white',
                            // minWidth: "8vw",
                            display: "flex",
                            gap: "5px",
                            minHeight: '3.5vh',
                            fontSize: '0.9vw',
                            height: 'auto', 
                        }}
                    >
            Edit Data
                    </Button>
                    <TabList
                        onChange={handleChange}
                        aria-label=''
                        TabIndicatorProps={{
                            style: { display: 'none' },
                        }}
                    >
                        {/* <Tab label='Edit Data' value='edit-data' /> */}
                        {env && (
                            <Tab
                                label='Environmental'
                                value='1'
                                onClick={() => {
                                    navHandler('environmental');
                                    setValue('1');
                                }}
                            />
                        )}
                        {(psilca || spa) && (
                            <Tab
                                label='Social'
                                value='2'
                                onClick={() => {
                                    if (psilca) {
                                        setValue('2');

                                        navHandler('social-psilca');
                                    } else if (spa) {
                                        setValue('2');

                                        navHandler('social-spa');
                                    }
                                }}
                            />
                        )}
                        {eco && (
                            <Tab
                                label='Economic'
                                value='3'
                                onClick={() => {
                                    setValue('3');
                                    navHandler('economic');
                                }}
                            />
                        )}
                        {/* <Tab icon={<BookIcon />} label='Product Report' value='product-report' /> */}
                    </TabList>
                    {/* <TabPanel value='1'>Environmental</TabPanel>
            <TabPanel value='2'>Social</TabPanel>
            <TabPanel value='3'>Economic</TabPanel> */}
                    <Button
                        sx={{ 
                            // minWidth: "8vw",
                            display: "flex",
                            gap: "5px",
                            minHeight: '3.5vh',
                            fontSize: '0.9vw',
                            height: 'auto', 
                        }}
                    >
                        <BookIcon
                            sx={{
                                width: "1.1vw",
                            }}
                        />
            Product Report
                    </Button>
                </TabContext>
            </Box>
        </ThemeProvider>
    );
}

export default ResultsProductHeader;
