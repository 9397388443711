import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => {return {
    product: {
        width: '100%',
        height: '400px',
    },
}});

export default useStyles;
