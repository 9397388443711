import { Box, Paper, Typography } from "@mui/material";
import { FormApi, useForm } from "@tanstack/react-form";
import React, { useEffect, useState } from "react";
import { useFormContext } from "../../../../forms/FormContext";
import SocialInfoPopup from "../SocialInfoPopup";
import Dropdown from "../../../../dropdowns/Dropdown";
import useStyles from "../../SocialInputs.styles";
import { LifeCycleStage } from "../../../../../helper/lifeCycleStage";
import { useNavigate, useRouterState } from "@tanstack/react-router";
import { SocialInputsForm } from "../../../../../interface/Form";
interface SocialLCSDropdowns {
  currentLCS: string;
  currentLCSS: string;
  socialAssessmentMethod: string;
}
interface SocialLCSDropdownsProps {
  setSelectedLCS: React.Dispatch<React.SetStateAction<string>>;
  setSelectedLCSS: React.Dispatch<React.SetStateAction<string>>;
  assessmentMethod?: string;
  isFirstSelection: boolean;
  setIsFirstSelection: React.Dispatch<React.SetStateAction<boolean>>;
  enableInputs: boolean;
  setEnableInputs: React.Dispatch<React.SetStateAction<boolean>>;
}
export const SocialInputsLCSDropdowns = ({
    setSelectedLCS,
    setSelectedLCSS,
    assessmentMethod,
    isFirstSelection,
    setIsFirstSelection,
    enableInputs,
    setEnableInputs,
}: SocialLCSDropdownsProps) => {
    const route = useRouterState();
    const navigate = useNavigate();

    const isEditing = route.location.pathname.includes("edit");

    const classes = useStyles();
    const { formState, setFormState } = useFormContext();
    const [selectableLCS, setSelectableLCS] = useState<string[]>([]);
    const [selectableLCSS, setSelectableLCSS] = useState<string[]>([]);
    const [selectedStages, setSelectedStages] = useState<LifeCycleStage[]>([]);
    const [selectedImpactMethod, setSelectedImpactMethod] = useState<string>(
        assessmentMethod || "PSILCA"
    );
    const [selectableMethods, setSelectableMethods] = useState<string[]>([
        "PSILCA",
        "SPA",
    ]);

    const [socialChanged, setSocialChanged] = useState(false);

    const form = useForm<SocialLCSDropdowns>({
        defaultValues: {
            currentLCS: formState.currentLCS ? formState.currentLCS : "",
            currentLCSS: formState.currentLCSS ? formState.currentLCSS : "",
            // TODO use this way of getting default LCS in social as well
            socialAssessmentMethod: assessmentMethod || "PSILCA",
        },
        onSubmit: ({ value }) => {
            // Do something with form data
            setFormState({
                ...formState,
                currentLCS: value.currentLCS,
                currentLCSS: value.currentLCSS,
            });
        },
    });

    // const selectLCSHandler = (lcs: string, _stages?: LifeCycleStage[]) => {
    //     const stages = _stages || selectedStages;
    //     const stage = stages.find((_stage) => {
    //         return _stage.name === lcs;
    //     });
    //     if (stage) {
    //         setSelectableLCSS(
    //             stage.substages.map((substage) => {
    //                 return substage.name;
    //             })
    //         );
    //         setSelectedLCSS(stage.substages[0].name);
    //         form.setFieldValue("currentLCSS", stage.substages[0].name);
    //     }
    // };

    // const selectLCSHandler = (lcs: string, _stages?: LifeCycleStage[]) => {
    //     const stages = _stages || selectedStages;
    //     const stage = stages.find((_stage) => _stage.name === lcs);

    //     if (stage) {
    //         const substageNames = stage.substages.map((substage) => substage.name);
    //         setSelectableLCSS(substageNames);

    //         // if (isFirstSelection) {
    //         //     setSelectedLCSS('');
    //         //     form.setFieldValue('currentLCSS', '');
    //         //     setEnableInputs(false);
    //         //     setIsFirstSelection(false);
    //         // } else {
    //         const firstSubstage = substageNames[0] || '';
    //         if(formState.productToEdit){
    //             setSelectedLCSS(firstSubstage);

    //         }
    //         form.setFieldValue('currentLCSS', firstSubstage);
    //         setEnableInputs(true);
    //         // }
    //     } else {
    //         // setSelectableLCSS([]);
    //         // setSelectedLCSS('');
    //         // form.setFieldValue('currentLCSS', '');
    //         // setEnableInputs(false);
    //     }
    // };

    const selectLCSHandler = (lcs: string) => {
        setSelectedLCS(lcs);
        let stages = selectedStages;
        console.log(stages);
        if (stages.length === 0) {
            //if selectedStages is empty, try to get selectedStages from formState
            stages =
        formState.selectedLCS?.reduce((list: LifeCycleStage[], lcs: string) => {
            const lcsName = lcs.split(":");
            const stage = list.find((item) => item.name === lcsName[0]);

            if (stage) {
                stage.substages.push({ name: lcsName[1] });
            } else {
                list.push({ name: lcsName[0], substages: [{ name: lcsName[1] }] });
            }
            return list;
        }, []) || [];
        }
        console.log(stages);
        const selectedStage = stages.find((stage) => stage.name === lcs);

        if (selectedStage) {
            const substages = selectedStage.substages.map(
                (substage) => substage.name
            );
            setSelectableLCSS(substages);
            console.log(substages);

            if (substages.length > 0) {
                const firstSubstage = substages[0];
                if (isEditing || formState.socialChanged || socialChanged) {
                    setSelectedLCSS(firstSubstage);
                    form.setFieldValue("currentLCSS",firstSubstage );
                }
                // if (isFirstSelection) {
                //     setSelectedLCSS('');
                //     form.setFieldValue('currentLCSS', '');
                //     setEnableInputs(false);
                //     setIsFirstSelection(false);
                // } else {
                //     setSelectedLCSS(firstSubstage);
                //     form.setFieldValue('currentLCSS', firstSubstage);
                //     setEnableInputs(true);
                // }
                // setSelectedLCSS(firstSubstage || '');
                // setSelectedLCSS('');
                // form.setFieldValue("currentLCSS", firstSubstage || "");

                // setEnableInputs(true);
            }
        } else {
            // setEnableInputs(false);
            // setSelectedLCSS("");
            // form.setFieldValue("currentLCSS", "");
            setSelectableLCSS([]);
        }
    };
    const fetchSelectedLCS = () => {
        if (formState && formState.selectedLCS) {
            const stages: LifeCycleStage[] = [];

            (formState.selectedLCS as string[]).forEach((lcs: string) => {
                // if (lcs === `${selectedPSILCALCS}:${selectedPSILCALCSS}`) {
                //     return;
                // }
                const matchingLCS = stages.find((stage) => {
                    return stage.name === lcs.split(":")[0];
                });

                if (matchingLCS) {
                    matchingLCS.substages.push({ name: lcs.split(":")[1] });
                } else {
                    stages.push({
                        name: lcs.split(":")[0],
                        substages: [{ name: lcs.split(":")[1] }],
                    });
                }
            });
            setSelectedStages(stages);
            let stage;
            // if (form.state.values.currentLCS === selectedPSILCALCS && form.state.values.currentLCSS === selectedPSILCALCSS) {
            //     stage = stages.find((item) => item.name === selectedPSILCALCS);

            // }
            if (!stage) {
                stage = stages[0];
            }
            if (stage) {
                form.setFieldValue("currentLCS", stage.name);
                setSelectedLCS(stage.name);
                setSelectableLCS(
                    stages.map((stage) => {
                        return stage.name;
                    })
                );
                selectLCSHandler(stage.name, stages);
            }
        } else {
            navigate({ to: "../../details" })
                .then((val) => {})
                .catch((err) => {});
        }
    };

    const selectImpactMethodHandler = (method: string) => {
        if (method === "SPA") {
            navigate({ to: "../spa" })
                .then((val) => {})
                .catch((err) => {});
        } else {
            navigate({ to: "../psilca" })
                .then((val) => {})
                .catch((err) => {});
        }
    };

    useEffect(() => {
        fetchSelectedLCS();
    }, []);

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    gap: "1vw",
                    width: "-webkit-fill-available",
                }}
            >
                <Paper
                    square
                    sx={{
                        display: "grid",
                        gridTemplateColumns: "auto 1fr",
                        justifyItems: "center",
                        alignItems: "center",
                        width: "-webkit-fill-available",
                        "@media (max-width: 1680px)": {
                            gridTemplateColumns: "auto auto",
                            gap: "1vw",
                            alignItems: "center",
                        },
                    }}
                >
                    <Typography component="h2" variant="h2">
            Impact Assessment Method
                    </Typography>
                    <Box className={classes.IAMWrapper}>
                        <form.Field
                            name="socialAssessmentMethod"
                            children={(field) => {
                                return (
                                    <Dropdown
                                        options={selectableMethods}
                                        onChange={(e) => {
                                            field.handleChange(e.target.value);
                                            setSelectedImpactMethod(e.target.value);
                                            selectImpactMethodHandler(e.target.value);
                                        }}
                                        id={field.name}
                                        className={`${field.name} iam`}
                                        value={field.state.value}
                                        onBlur={field.handleBlur}
                                        label="Method Stage"
                                        noDefaults
                                    />
                                );
                            }}
                        />

                        <SocialInfoPopup selectedImpactMethod={selectedImpactMethod} />
                    </Box>
                </Paper>
                <Paper
                    square
                    sx={{
                        display: "grid",
                        gridTemplateColumns: "auto 1fr",
                        justifyItems: "center",
                        alignItems: "center",
                        width: "-webkit-fill-available",
                    }}
                >
                    <Typography
                        component="h2"
                        variant="h2"
                        onClick={() => {
                            console.log(form.state.values);
                            console.log(formState);
                        }}
                    >
            Lifecycle Stages
                    </Typography>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "2vw",
                            // '@media (max-width: 1680px)': {
                            //     flexDirection: 'column',
                            //     gap: '5px',
                            // },
                        }}
                    >
                        <form.Field
                            name="currentLCS"
                            children={(field) => {
                                return (
                                    <Dropdown
                                        options={selectableLCS}
                                        onChange={(e) => {
                                            field.handleChange(e.target.value);
                                            selectLCSHandler(e.target.value);
                                            setSelectedLCS(e.target.value);
                                            setSocialChanged(true);

                                        }}
                                        id={field.name}
                                        className={field.name}
                                        value={field.state.value || ""}
                                        onBlur={field.handleBlur}
                                        label="Lifecycle Stage"
                                        // noDefaults
                                        inputOnly
                                        defaultValue=""
                                    />
                                );
                            }}
                        />
                        <form.Field
                            name="currentLCSS"
                            children={(field) => {
                                return (
                                    <Dropdown
                                        options={selectableLCSS}
                                        onChange={(e) => {
                                            field.handleChange(e.target.value);
                                            setSelectedLCSS(e.target.value);
                                            setEnableInputs(true);
                                            setSocialChanged(true);
                                        }}
                                        id={field.name}
                                        className={field.name}
                                        value={field.state.value || ""}
                                        onBlur={field.handleBlur}
                                        label="Sublifecycle Stage"
                                        // noDefaults
                                        inputOnly={true}
                                        disableTemp={selectableLCSS.length === 0}
                                        defaultValue=""
                                    />
                                );
                            }}
                        />
                    </Box>
                </Paper>
            </Box>
        </>
    );
};
