import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { useState, useMemo, useEffect, useCallback } from 'react';
import deepClone from '../../../../../../helper/deepClone';
import { ChartJSData, ChartJSOptions, ChartDataSet } from '../../../../../../interface/ChartData';
import { Product, EnvironmentalResult, EnvResultSubstage } from '../../../../../../interface/Product';
import Dropdown from '../../../../../dropdowns/Dropdown';
import LCSDropdowns from '../../../../../dropdowns/LCSDropdowns';
import { initialOptions } from '../../../../options/InitialOptionValues';
import { applyValueType } from '../../../../options/util/applyOptions';
import { CreateChartTS } from '../../../../util/CreateChartTS';
import useStyles from './B4BarChart.styles';
import GraphDownload from '../../../../options/components/ChartDownload';
import GraphSetting from '../../../../options/components/GraphSettings';
import ResultsTheme from '../../../../style/ResultsTheme';
import EnvChartNavigation from '../../../../../dropdowns/BaseOptions/EnvChartNavigation';
import { palette1, palette2, palette3, palette4, processColors } from '../../../../../../helper/colors';
import { useFormContext } from '../../../../../forms/FormContext';

interface ColorIndexData {
    label: string;
    identifier?: string;
}

interface ChartData {
    product: Product
}

export interface ColorIndexData {
    label: string;
    identifier?: string;
}

function B4BarChart(props: ChartData) {
    const { formState, setFormState } = useFormContext();
    const classes = useStyles();
    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
    const { product } = props;
    const [chartJSData, setChartJSData] = useState<ChartJSData>({ datasets: [], labels: [] });
    const [chartOptions, setChartOptions] = useState<ChartJSOptions>();
    const [selectedValueType, setSelectedValueType] = useState<string>('');
    const [selectedLCS, setSelectedLCS] = useState<string>('');
    const [selectedLCSS, setSelectedLCSS] = useState<string>('');
    const [results, setResults] = useState<EnvironmentalResult[]>([]);

    useMemo(() => {
        const calc = product.calculations.find(
            (item) => item.calculationType === 'ENVIRONMENTAL'
        );
        const calcResults = calc?.calculationResults as EnvironmentalResult[];
        setResults(calcResults);
    }, [product]);

    const findColorPaletteByName = (name: string): string[] => {
        const palettes = {
            "Process Colors": processColors,
            "Sunset": palette1,
            "Multicolored": palette2,
            "Warm": palette3,
            "Cold": palette4,
        } as Record<string, string[]>;
        return palettes[name] || palettes["Process Colors"];
    };

    const generateExtendedPalette = (baseColors: string[], totalColors: number): string[] => {
        const extendedPalette = [...baseColors];
        while (extendedPalette.length < totalColors) {
            baseColors.forEach((color) => {
                // Adjust brightness or saturation to generate new colors
                const newColor = adjustColor(color, extendedPalette.length / totalColors);
                extendedPalette.push(newColor);
                if (extendedPalette.length >= totalColors) return;
            });
        }
        return extendedPalette.slice(0, totalColors);
    };

    const adjustColor = (color: string, factor: number): string => {
     
        const f = parseInt(color.slice(1), 16),
            t = factor < 0 ? 0 : 255,
            p = factor < 0 ? factor * -1 : factor,
            R = f >> 16,
            G = f >> 8 & 0x00FF,
            B = f & 0x0000FF;
        return "#" + (0x1000000 + (Math.round((t - R) * p) + R) * 0x10000 + (Math.round((t - G) * p) + G) * 0x100 + (Math.round((t - B) * p) + B)).toString(16).slice(1);
    };

    const getGraphColorIndex = useCallback(({ label, identifier }: ColorIndexData, palette: string[]) => {
        const hash = `${label}:${identifier}`.split('').reduce((hash, char) => {
            return char.charCodeAt(0) + ((hash << 5) - hash);
        }, 0);
        return Math.abs(hash) % palette.length;
    }, []);

    useMemo(() => {
        let unit: string;
        const chartData: ChartJSData = { datasets: [], labels: [] };
        const options: ChartJSOptions = deepClone<ChartJSOptions>(initialOptions);

        if (results.length > 0 && selectedLCS && selectedLCSS) {
            const lifeCycleStage = results.find(
                (item) => {return item.name === selectedLCS},
            );
            let lifeCycleSubstage: EnvResultSubstage | undefined;

            if (lifeCycleStage) {
                lifeCycleSubstage = lifeCycleStage.subStages.find(
                    (lcss) => {return lcss.name === selectedLCSS},
                );
            }
            
            if (lifeCycleSubstage && lifeCycleSubstage.results) {
                const selectedPalette = findColorPaletteByName(formState.colorPalette);
                //                const totalProcesses = lifeCycleSubstage.results[0].values.length;
                const extendedPalette = generateExtendedPalette(selectedPalette, results.length);

                const datasets = lifeCycleSubstage.results[0].values
                    .map((process, index) => {
                        const colorIndex = getGraphColorIndex({
                            label: process.endProductName,
                        }, extendedPalette);
                
                        return {
                            label: process.endProductName,
                            backgroundColor: extendedPalette[colorIndex],
                            data: selectedCategories.map((category) => {
                                const matchingCategory = lifeCycleSubstage?.results.find(
                                    (item) => {return item.impactCategory.name === category},
                                );
                                if (matchingCategory) {
                                    unit = matchingCategory.impactCategory.refUnit;
                                    const matchingProcess = matchingCategory.values.find(
                                        (item) => {return item.processName === process.processName},
                                    );
                                    if (matchingProcess) {
                                        if (selectedValueType === 'Relative Contribution') {
                                            const processes = matchingCategory.values;
                                            const maxValue = processes.reduce((max, item) => {
                                                if (item.value > max) {
                                                    max = item.value;
                                                }
                                                return max;
                                            }, 0);
                                            return (matchingProcess.value / maxValue) * 100;
                                        }
                                        return matchingProcess.value;
                                    }
                                }
                                return 0;
                            }),
                        };
                    });
                chartData.datasets = datasets;
            }
        }
        chartData.labels = selectedCategories;

        setChartJSData(chartData);
        setChartOptions(options);
    }, [selectedCategories, selectedLCS, selectedLCSS, selectedValueType, getGraphColorIndex, results, formState.colorPalette]);

    const [applyGraphSettings, setApplyGraphSettings] =
    useState<(options: ChartJSOptions, chartData: ChartJSData) => { options: ChartJSOptions; chartData: ChartJSData; }>();

    const triggerChartUpdate = () => {
        let options = deepClone(chartOptions) || {};
        let chartData = deepClone(chartJSData);
        const applyOptionReturn = applyValueType(selectedValueType, chartData, options);
        options = applyOptionReturn.options;
        chartData = applyOptionReturn.chartData;
        if (applyGraphSettings) {
            ({ options, chartData } = applyGraphSettings(options, chartData));
        }
        CreateChartTS(chartData, options);
    };

    useEffect(() => {
        if (chartJSData && chartOptions) {
            triggerChartUpdate();
        }
    }, [
        chartJSData,
        chartOptions,
        selectedValueType,
        // graphColors,
        selectedCategories,
        selectedLCS,
        selectedLCSS,
        getGraphColorIndex,
        results,
        formState.colorPalette,
    ]);

    return (
        <ThemeProvider theme={ResultsTheme}>
            <Box
                sx={{
                    margin: '0',
                    maxWidth: '100%',
                    maxHeight: '-webkit-fill-available',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1vw',
                }}
            >
                <Box component='div' className='results-wrapper'>
                    <Box component='div' className='results-options'>
                        <Paper square>
                            <Box component='div' className='results-options-top'>
                                <FormControl
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                    }}
                                />
                                <EnvChartNavigation
                                    product={product}
                                    defaultBaseIndex={1}
                                    defaultResultIndex={3}
                                />
                            </Box>
                        </Paper>
                        <Divider />
                        <Paper square>
                            <Box component='div' className='results-options-bottom'>
                                <Box>
                                    <FormControl
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}
                                        className='bottom-options side-left'
                                    />
                                </Box>
                                <Box>
                                    <FormControl
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            '@media (max-width: 1680px)': {
                                                gap: '1vw',
                                            },
                                        }}
                                        className='bottom-options side-right'
                                    >
                                        <GraphDownload />
                                        <GraphSetting
                                            chartOptions={chartOptions}
                                            setApplyGraphSettings={setApplyGraphSettings}
                                            triggerChartUpdate={triggerChartUpdate}
                                            chartData={chartJSData}
                                            chartColorType='process'
                                            noColors
                                        />
                                    </FormControl>
                                </Box>
                            </Box>
                        </Paper>
                    </Box>
                </Box>
                <Box className={classes.chartSec}>
                    <Paper
                        square
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <Box className={classes.chartSec}>
                            <canvas id='graph1Canvas' className='graph-canvas' />
                        </Box>
                    </Paper>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default B4BarChart;
