import { Stack, Button } from "@mui/material";
import { FormApi } from "@tanstack/react-form";
import React, { useState, useEffect } from "react";
import { FormState } from "../../../forms/FormContext";
import { useNavigate } from "@tanstack/react-router";
import { SubmitProductPopup } from "../SubmitProductPopup";
import {
    EnvFlowInputLCS,
    EnvProcessInputLCS,
    ProductDetailsForm,
    SocialInputsLCS,
} from "../../../../interface/Form";
import { useQuery } from "@tanstack/react-query";
import { fetchEnvMethods } from "../../../../helper/fetchUtil";
import { SelectMethod } from "../../../../interface/ImpactMethod";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function CreateProductNavButtons({
    form,
    formState,
    currentPage,
    editProductId,
    formNotValid,
}: {
  form: FormApi<any>;
  formState: FormState;
  currentPage: string;
  editProductId?: string;
  formNotValid?: boolean;
}) {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [prevNav, setPrevNav] = useState<string>();
    const [nextNav, setNextNav] = useState<string>();
    const navigate = useNavigate();
    const [envIsLoading, setEnvLoading] = useState<boolean | undefined>(undefined);
    const [socialIsLoading, setSocialLoading] = useState<boolean | undefined>(undefined);
    const [ecoIsLoading, setEcoLoading] = useState<boolean | undefined>(undefined);

    const [showSubmitProductPopup, setShowSubmitProductPopup] = useState(false);
    // const { isPending, error, data } = useQuery({
    //     queryKey: ['envMethods'],
    //     queryFn: fetchEnvMethods,
    // })

    const { data: loadedMethods = [] } = useQuery({
        queryKey: ["envMethods"],
        queryFn: () => {
            return fetchEnvMethods();
        },
        staleTime: Infinity,
    });

    const getNextNav = (): string => {
        let envDim = false;
        let ecoDim = false;
        let socialDim = false;
        let next = "";
        if (Object.keys(formState).length > 0) {
            ({ envDim, ecoDim, socialDim } = formState);
        } else if (currentPage === "details") {
            const detailsForm = (form as FormApi<ProductDetailsForm>).state.values;
            envDim = detailsForm.envDim;
            envDim = detailsForm.envDim;

            envDim = detailsForm.envDim;
            console.log(detailsForm);
        }
        setIsSubmitting(false);
        if (currentPage === "details") {
            if (envDim) {
                next = "../environmental";
            } else if (socialDim) {
                next = "../social/psilca";
            } else if (ecoDim) {
                next = "../economic";
            }
        } else if (currentPage === "environmental") {
            if (socialDim) {
                next = "../social/psilca";
            } else if (ecoDim) {
                next = "../economic";
            } else {
                setIsSubmitting(true);
            }
        } else if (currentPage === "social") {
            if (envDim) {
            } else {
            }
            if (ecoDim) {
                next = "../../economic";
            } else {
                setIsSubmitting(true);
            }
        } else if (currentPage === "economic") {
            if (socialDim) {
            } else if (envDim) {
            } else {
            }
            setIsSubmitting(true);
        }

        return next;
    };
    const getPrevNav = (): string => {
        let envDim = false;
        let ecoDim = false;
        let socialDim = false;
        let prev = "";
        if (Object.keys(formState).length > 0) {
            ({ envDim, ecoDim, socialDim } = formState);
        } else if (currentPage === "details") {
            const detailsForm = (form as FormApi<ProductDetailsForm>).state.values;
            envDim = detailsForm.envDim;
            envDim = detailsForm.envDim;

            envDim = detailsForm.envDim;
        }
        setIsSubmitting(false);
        if (currentPage === "environmental") {
            prev = "../details";
            if (socialDim) {
            } else if (ecoDim) {
            } else {
                setIsSubmitting(true);
            }
        } else if (currentPage === "social") {
            

            if (envDim) {
                prev = "../../environmental";
            } else {
                prev = "../../details";
            }
            if (ecoDim) {
            } else {
                setIsSubmitting(true);
            }
        } else if (currentPage === "economic") {
            if (socialDim) {
                prev = "../social/psilca";
            } else if (envDim) {
                prev = "../environmental";
            } else {
                prev = "../details";
            }
            setIsSubmitting(true);
        }

        return prev;
    };

    const setNav = () => {
        setNextNav(getNextNav());
        setPrevNav(getPrevNav());
    };
    useEffect(() => {
        setNav();
    }, [currentPage, formState.envDim, formState.socialDim, formState.ecoDim]);


    const [missingInputs, setMissingInputs] = useState<string[]>([]);
    const [invalidInputs, setInvalidInputs] = useState<string[]>([]);
    const validateSubmit = () => {
        const missingFormInputs: string[] = [];
        const invalidFormInputs: string[] = [];
        const checkEnv = () => {
            let inputsFound = 0;

            if (formState.envInputProcesses) {
                formState.envInputProcesses.forEach((lcs: EnvProcessInputLCS) => {
                    lcs.values.forEach(() => {
                        inputsFound++;
                    });
                });
            }
            if (formState.envInputEmissions) {
                formState.envInputEmissions.forEach((lcs: EnvFlowInputLCS) => {
                    lcs.values.forEach(() => {
                        inputsFound++;
                    });
                });
            }
            if (formState.envOutputEmissions) {
                formState.envOutputEmissions.forEach((lcs: EnvFlowInputLCS) => {
                    lcs.values.forEach(() => {
                        inputsFound++;
                    });
                });
            }
            if (formState.envOutputWaste) {
                formState.envOutputWaste.forEach((lcs: EnvFlowInputLCS) => {
                    lcs.values.forEach(() => {
                        inputsFound++;
                    });
                });
            }

            if (inputsFound === 0) {
                missingFormInputs.push("Environmental : Process inputs");
            }

            if (
                formState.envAssessmentMethod === undefined ||
        formState.envAssessmentMethod === "" ||
        !loadedMethods.find(
            (method) => method.id === formState.envAssessmentMethod
        )
            ) {
                invalidFormInputs.push("Environmental : Assessment Method");
            }
        };

        const checkSocial = () => {
            const method = formState.socialAssessmentMethod;
            let inputsFound = 0;
            if (method) {
                if (formState.socialInputProcesses) {
                    formState.socialInputProcesses.forEach((lcs: SocialInputsLCS) => {
                        console.log(lcs);
                        lcs.values.forEach(() => {
                            inputsFound++;
                        });
                    });
                }
                if (method === "SPA") {
                    if (formState.useSPALCS) {
                        if (!formState.selectedSPAProcess) {
                            missingFormInputs.push("Social : SPA Process");
                        }

                        if (
                            !formState.selectedStakeholders ||
              formState.selectedStakeholders.length === 0
                        ) {
                            missingFormInputs.push("Social : SPA Stakeholder Inputs");
                        }
                    }
                }

                if (inputsFound === 0) {
                    missingFormInputs.push("Social : Process inputs");
                }
                console.log(formState.socialInputProcesses);
            }
        };

        const checkEco = () => {
            let inputsFound = 0;

            if (formState.envInputProcesses) {
                formState.envInputProcesses.forEach((lcs: EnvProcessInputLCS) => {
                    lcs.values.forEach(() => {
                        inputsFound++;
                    });
                });
            }
            if (inputsFound === 0) {
                missingFormInputs.push("Economic : Process inputs");
            }
        };

        if (formState.envDim) {
            checkEnv();
        }
        if (formState.socialDim) {
            checkSocial();
        }
        if (formState.ecoDim) {
            checkEco();
        }
        setInvalidInputs(invalidFormInputs);
        setMissingInputs(missingFormInputs);
    };

    return (
        <Stack
            direction="row"
            spacing={2}
            sx={{ justifyContent: "flex-end", width: "auto" }}
        >
            {showSubmitProductPopup && (
                <SubmitProductPopup
                    setEnvLoading = {setEnvLoading}
                    setEcoLoading = {setEcoLoading}
                    setSocialLoading = {setSocialLoading}
                    envIsLoading= {envIsLoading}
                    socialIsLoading = {socialIsLoading}
                    ecoIsLoading = {ecoIsLoading}
                    setShowSubmitProductPopup={setShowSubmitProductPopup}
                    editProductId={editProductId}
                    missingInputs={missingInputs}
                />
            )}

            {prevNav && (
                <form.Subscribe
                    selector={(state) => {
                        return [state.canSubmit, state.isSubmitting];
                    }}
                    children={([canSubmit, _isSubmitting]) => {
                        return (
                        // <button type='submit' disabled={!canSubmit}>
                        //     {isSubmitting ? '...' : 'Submit'}
                        // </button>
                            <Button
                                variant="contained"
                                type="submit"
                                disabled={!canSubmit}
                                onClick={() => {
                                    form
                                        .handleSubmit()
                                        .then(() => {
                                            navigate({ to: prevNav || getPrevNav() })
                                                .then((val) => {})
                                                .catch((err) => {});
                                        })
                                        .catch(() => {});
                                }}
                                sx={{
                                    height: "auto",
                                    fontSize: "0.8vw",
                                    width: "5vw",
                                    "@media (max-width: 1300px)": {
                                        height: "auto",
                                    },
                                }}
                            >
                Previous
                            </Button>
                        );
                    }}
                />
            )}
            {isSubmitting ? (
                <Button
                    variant="contained"
                    type="button"
                    disabled={envIsLoading || socialIsLoading || ecoIsLoading || formNotValid}
                    onClick={() => {
                        form
                            .handleSubmit()
                            .then((val) => {
                                setShowSubmitProductPopup(true);
                                validateSubmit();
                                // submitCreateProductHandler(formState, setIsLoading);
                            })
                            .catch(() => {});
                        // navigate({ to: '../social' })
                        //     .then((val) => { })
                        //     .catch((err) => { });
                    }}
                    sx={{
                        height: "auto",
                        fontSize: "0.8vw",
                        width: "5vw",
                        "@media (max-width: 1300px)": {
                            height: "auto",
                        },
                    }}
                >
          Submit
                </Button>
            ) : (
                <form.Subscribe
                    selector={(state) => {
                        return [state.canSubmit, state.isSubmitting];
                    }}
                    children={([canSubmit, _isSubmitting]) => {
                        return (
                            <Button
                                variant="contained"
                                type="submit"
                                disabled={(!canSubmit && !nextNav) || formNotValid}
                                onClick={() => {
                                    form
                                        .handleSubmit()
                                        .then(() => {

                                            navigate({ to: nextNav || getNextNav() })
                                                .then((val) => {})
                                                .catch((err) => {});
                                        })
                                        .catch(() => {});
                                }}
                                sx={{
                                    height: "auto",
                                    fontSize: "0.8vw",
                                    width: "5vw",
                                    "@media (max-width: 1300px)": {
                                        height: "auto",
                                    },
                                }}
                            >
                Next
                            </Button>
                        );
                    }}
                />
            )}
        </Stack>
    );
}
