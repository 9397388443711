import React from "react";
import "./Homepage.css";
import "./OurTeam.css";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Link } from "@tanstack/react-router";
import { Box } from "@mui/material";

const OurTeam: React.FC = () => {
    return (
        <div className="website-only">
            <Header />

            <div className="header-container bio-page">
                <div className="header-transparent-layer"> </div>
                <img
                    src={require("../images/headers/pexels-pixabay-461956.jpg")}
                    alt="Mountains under the blue sky"
                />
                <Box>
                    <h1>Our Team</h1>
                </Box>
            </div>
            <div className="box-container">
                <div className="bio-container">
                    <div className="bio-profile">
                        <img
                            src={require("../images/staff-photos/Marwa_Hannouf_Pic.jpg")}
                            alt="arwa"
                        />
                        <div className="bio-info-wrap">
                            <h3>Marwa Hannouf</h3>
                            <p>Founder, Director and CEO</p>
                            <Link to="/bioprofile/marwa" className="button-link">
                Learn more about Marwa
                            </Link>
                        </div>
                    </div>
                    {/* <div className='bio-profile'>
            <img src={require('../images/staff-photos/IMG_4337.jpg')} alt='Alejandro'/>
            
            <div className='bio-info-wrap'>
            <h3>Alejandro Padilla Rivera</h3>
            <p>Founder, Director and CTO</p>
            <Link to='/bioprofile/alejandro' className='button-link'>Learn more about Alejandro</Link>
            </div>
            </div> */}
                    <div className="bio-profile">
                        <img
                            src={require("../images/staff-photos/CPE_Ian-Gates2.jpeg")}
                            alt="Ian"
                        />
                        <div className="bio-info-wrap">
                            <h3>Ian Gates</h3>
                            <p>Founder, Director and Advisor</p>
                            <Link to="/bioprofile/ian" className="button-link">
                Learn more about Ian
                            </Link>
                        </div>
                    </div>
                    <div className="bio-profile">
                        <img
                            src={require("../images/staff-photos/_MG_2420_Final.jpeg")}
                            alt="Getachew"
                        />
                        <div className="bio-info-wrap">
                            <h3>Getachew Assefa</h3>
                            <p>Founder, Director and Advisor </p>
                            <Link to="/bioprofile/getachew" className="button-link">
                Learn more about Getachew
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
};

export default OurTeam;
