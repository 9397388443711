/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Box, createTheme, ThemeProvider } from '@mui/material';
import { hover } from '@testing-library/user-event/dist/hover';
import { styled } from '@mui/material/styles';
import { green } from '@mui/material/colors';
import TableRow from '@mui/material/TableRow';
import { makeStyles } from '@mui/styles';

const BoxWrapper = styled(Box)(({ theme }) => {return {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '-1rem',
}});

const useStyles = makeStyles((theme) => {return {
    components: {
        MuiListItem: {
            styleOverrides: {
                root: {
                    display: 'grid',
                    gridTemplateRows: '3rem 1rem auto',
                    width: 'auto',
                    alignItems: 'start',
                },
            },
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    alignSelf: 'flex-start',
                    height: 'auto',
                    gap: '1vw',
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                root: {
                    fontFamily: 'Roboto, sans-serif',
                    alignSelf: 'flex-start',
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    alignSelf: 'flex-start',
                },
            },
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    width: ' -webkit-fill-available',
                },
            },
        },
        MuiCollapse: {
            styleOverrides: {
                '& .MuiCollapse-wrapperInner': {
                    overflowY: 'hidden',
                // Add more styles as needed
                },
            },
        },        
        BoxWrapper: {
            display: 'flex',
            flexDirection: 'column',
            marginTop: '-1rem',
        },
    
    },
    typography: {
        fontFamily: 'Roboto, sans-serif',
        h2: {
            fontSize: 32,
            fontWeight: 'bold',
        },
        h3: {
            fontSize: 20,
            fontWeight: 'bold',
            fontFamily: 'Montserrat, sans-serif',
        },
        body1: {
            fontFamily: 'Roboto, sans-serif',
            fontSize: 14,
        },
    },
}});

export default useStyles;
