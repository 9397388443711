/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { createTheme, ThemeProvider } from '@mui/material';
import { hover } from '@testing-library/user-event/dist/hover';
import { styled } from '@mui/material/styles';
import { green } from '@mui/material/colors';
import TableRow from '@mui/material/TableRow';

const TableTheme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 767,
            md: 1300,
            lg: 1450,
            xl: 1600,
        },
    },
    components: {
        MuiPopper:{
            styleOverrides: {
                root: {
                    position: 'fixed',
                    transform: 'none !important',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    width: '158px',
                    height: '66px',
                    borderRadius: 0,
                    fontWeight: 'bold',
                    fontFamily: '"Montserrat", sans-serif',
                    fontSize: '24px',
                    textTransform: 'capitalize',
                    backgroundColor: '#166d46',
                    '&:hover': {
                        backgroundColor: '#D9EFDF',
                        color: '#004021',
                    },
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    width: '158px',
                    height: '66px',
                    borderRadius: 0,
                    fontWeight: 'bold',
                    fontFamily: '"Montserrat", sans-serif',
                    fontSize: '24px',
                    textTransform: 'capitalize',
                    backgroundColor: '#166d46',
                    '&:hover': {
                        backgroundColor: '#D9EFDF',
                        color: '#004021',
                    },
                    '& .MuiTabs-indicator': {
                        display: 'none',
                    },
                },
            },
        },
    },
    typography: {
        fontFamily: 'Roboto, sans-serif',
        h2: {
            fontSize: '1.4vw',
            fontWeight: 'bold',
        },
        h3: {
            fontSize: '1.2vw',
            fontWeight: 'bold',
            fontFamily: 'Montserrat, sans-serif',
        },
        body1: {
            fontFamily: 'Roboto, sans-serif',
            fontSize: '0.7vw',
        },
    },
});

export default TableTheme;
