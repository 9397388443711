import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => {return {
    header: {
        fontSize: '0.7vw',
    },
    miniButtons: {
        boxShadow: '0 3px 6px #89B8A2',
        padding: 0,
        height: '3vh',
        textTransform: 'capitalize',
        fontSize: '0.7vw',
        border: 0,
        borderRadius: 0,
        paddingLeft: 4,
        paddingRight: 4,
        backgroundColor: '#056939',
        color: 'white',
        width: '4vw',
        '&:hover': {
            backgroundColor: '#89B8A2',
        },
    },
    buttonSet: {
        display: 'flex',
        flexDirection: 'row',
        gap: '1vw',
    },

    deleteProductPopup: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: 'white',
        padding: '1vw',
        boxShadow: '0 3px 6px #89B8A2',
        zIndex: 2,
        gap: '1vw',
        position: 'absolute',
        marginLeft: '7vw',
    },

}});

export default useStyles;
