import {
    createRouter,
} from '@tanstack/react-router';
import { rootRoute } from './RootRoute';
import protectedRoutes from './ProtectedRoutes';
import publicRoutes from './PublicRoutes';

const loginRoutes = publicRoutes();
const protectedRoute = protectedRoutes();

const routeTree = rootRoute.addChildren([
    ...loginRoutes,
    ...protectedRoute,
]);

const router = createRouter({ routeTree });

export default router;
