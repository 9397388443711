/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable react/function-component-definition */
import React, { useEffect, useState } from 'react';
import {
    Checkbox, Container, FormControlLabel, TextField,
} from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled, alpha } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import GeneralTheme from '../../../style/GeneralTheme';
import { ThemeProvider } from '@emotion/react';
import FormControl from '@mui/material/FormControl';
import SelectSPASocialProcessPopup from './components/SelectSPASocialProcessPopup';
import Dropdown from '../../../dropdowns/Dropdown';
import { useNavigate } from '@tanstack/react-router';
import SPAStakeholders from './components/SPAStakeholders';
import { CheckBox } from '@mui/icons-material';
import { Popup } from '@mui/base/Unstable_Popup/Popup';
import AddCircleIcon from '@mui/icons-material/AddCircle';

const SocialSPAInputs = () => {
    const navigate = useNavigate();

    const [processPopupSPA, setProcessPopupSPA] = React.useState<null | HTMLElement>(null);

    const [selectedLifecycleStage, setSelectedLifecycleStage] = useState<string>('');
    const [selectedLifecycleSubStage, setSelectedLifecycleSubStage] = useState<string>('');
    const [selectedWeighingMethod, setSelectedWeighingMethod] = useState<string>('');
    const [selectedWeightingSet, setSelectedWeightingSet] = useState<string>('');
    const [selectedImpactMethod, setSelectedImpactMethod] = useState<string>('');
    const [selectedStakeholderSPA, setSelectedStakeholderSPA] = useState<string>('');

    switch (selectedStakeholderSPA) {
    case 'Consumers':
        break;
    case 'Workers':
        break;
    case 'Local Community':
        break;
    case 'Value Chain Actors':
        break;
    case 'Society':
        break;
    case 'Children':
        break;
    default:
        break;
    }

    switch (selectedLifecycleStage) {
    case 'Product Stage':
        break;
    case 'Construction Stage':
        break;
    case 'Use Stage':
        break;
    case 'End of Life Stage':
        break;
    case 'Benefits and Loads Beyond the Life Cycle':
        break;
    default:
        break;
    }

    switch (selectedLifecycleSubStage) {
    case 'Sub Stage 1':
        break;
    case 'Sub Stage 2':
        break;
    case 'Sub Stage 3':
        break;
    case 'Sub Stage 4':
        break;
    case 'Sub Stage 5':
        break;
    default:
        break;
    }

    switch (selectedWeighingMethod) {
    case 'Method 1':
        break;
    case 'Method 2':
        break;
    case 'Method 3':
        break;
    case 'Method 4':
        break;
    default:
        break;
    }

    switch (selectedWeightingSet) {
    case 'Set 1':
        break;
    case 'Set 2':
        break;
    case 'Set 3':
        break;
    case 'Set 4':
        break;
    default:
        break;
    }

    switch (selectedImpactMethod) {
    case 'PSILCA':
        break;
    case 'SPA':
        break;
    default:
        break;
    }

    const BoxWrapper = styled(Box)(({ theme }) => {return {
        display: 'flex',
        flexDirection: 'column',
        gap: '1vw',
        width: '-webkit-fill-available',
    }});

    const BoxInputs = styled(Box)(({ theme }) => {return {
        display: 'flex',
        gap: '1vw',
        width: '-webkit-fill-available',
    }});

    const PaperSpacing = styled(Paper)(({ theme }) => {return {
        display: 'flex',
        gap: '1vw',
        flexDirection: 'column',
        width: '-webkit-fill-available',
        justifyContent: 'space-between',
    }});

    const handleProcessPopupClick = (event: React.MouseEvent<HTMLElement>) => {
        setProcessPopupSPA(processPopupSPA ? null : event.currentTarget);
    };

    const open = Boolean(processPopupSPA);
    const processPopper = open ? 'simple-popper' : undefined;

    return (
        <ThemeProvider theme={GeneralTheme}>
            <Box
                sx={{
                    width: '-webkit-fill-available',
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '1vw',
                }}
            >
                <PaperSpacing
                    square
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '-webkit-fill-available',
                    }}
                >
                    <FormControlLabel control={<Checkbox />} label="Life Cycle Stage w/ primary social data to input" />
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            
                        }}
                        className='spa-lifecycle-stages'
                    >
                        <Typography component='h3' variant='h3'>
                            Lifecycle Stages
                        </Typography>
                        <Dropdown
                            options={[
                                'Product Stage',
                                'Construction Stage',
                                'Use Stage',
                                'End of Life Stage',
                                'Benefits and Loads Beyond the Life Cycle',
                            ]}
                            value={selectedLifecycleStage}
                            valueSetter={setSelectedLifecycleStage}
                        />
                        <Dropdown
                            options={[
                                'Sub Stage 1',
                                'Sub Stage 2',
                                'Sub Stage 3',
                                'Sub Stage 4',
                                'Sub Stage 5',
                            ]}
                            value={selectedLifecycleSubStage}
                            valueSetter={setSelectedLifecycleSubStage}
                        />
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '1vw',
                        }}
                    >
                        <Typography component='h3' variant='h3'>
                            Input Data
                        </Typography>
                        <Dropdown
                            options={[
                                'Consumers',
                                'Workers',
                                'Local Community',
                                'Value Chain Actors',
                                'Society',
                                'Children',
                            ]}
                            value={selectedStakeholderSPA}
                            valueSetter={setSelectedStakeholderSPA}
                        />
                    </Box>
                    <SPAStakeholders />
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '1vw',
                        }}
                    >
                        <Typography component='h3' variant='h3'>Select corresponding process for Comparison</Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '1vw',
                            }}
                        >
                            <TextField size='small' />
                            <Button
                                size='small'
                                id='process-popup'
                                onClick={handleProcessPopupClick}
                                sx={{
                                    color: 'white',
                                    padding: '6px',
                                    fontSize: '14px',
                                    height: 'fit-content',
                                }}
                            >
                                Select Process
                            </Button>
                        </Box>
                    </Box>
                    <Popup id={processPopper} open={open} anchor={processPopupSPA}>
                        <Box>
                            <SelectSPASocialProcessPopup
                                handleProcessPopupClick={handleProcessPopupClick}
                            />
                        </Box>
                    </Popup>
                </PaperSpacing>
                <PaperSpacing
                    square
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '-webkit-fill-available',
                        justifyContent: 'flex-start',
                    }}
                >
                    <Typography component="h2" variant="h2">
                        Inputs
                    </Typography>
                    {/* <Box sx={{ marginTop: '-5rem', }}> */}
                    {/* <SocialInputProcessList /> */}
                    {/* </Box> */}
                </PaperSpacing>
            </Box>
        </ThemeProvider>
    );
};

export default SocialSPAInputs;
