import { ThemeProvider } from '@emotion/react';
import { Button } from '@mui/base';
import { TextField, TableCell, TableRow, styled, Box } from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import { FormApi, useForm } from '@tanstack/react-form';
import {
    EnvFlow,
    EnvFlowInputLCS,
    EnvironmentalInputsForm,
} from '../../../../../interface/Form';
import AddButton from '../../../../style/components/AddButton';
import ExitButton from '../../../../style/components/ExitButton';
import { useEffect, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import { SelectProcess } from '../../../../../interface/Process';
import { UnitGroup, findUnitUnitGroup } from '../../../../../interface/Unit';
import Dropdown from '../../../../dropdowns/Dropdown';
import { SelectFlow } from '../../../../../interface/Flow';

interface EnvFlowListItemProps {
  openFlowSelect: (event: React.MouseEvent<HTMLElement>) => void;
  index: number;
  envForm: FormApi<EnvironmentalInputsForm>;
  unitGroups: UnitGroup[];
  flow: EnvFlow;
  setSelectHandler: React.Dispatch<
    React.SetStateAction<(process: SelectFlow) => void>
  >;
  lifeCycleStage: string;
  stages: EnvFlowInputLCS[];
  deleteHandler: (processIndex: number) => void;
}

export function EnvFlowListItem({
    unitGroups,
    openFlowSelect: openProcessSelect,
    index,
    envForm,
    flow,
    setSelectHandler,
    lifeCycleStage,
    stages,
    deleteHandler,
}: EnvFlowListItemProps) {
    const form = useForm({
        defaultValues: {
            index,
            endProductName: flow.endProductName,
            id: flow.flowId,
            name: flow.flowName,
            quantity: flow.flowQuantity,
            unit: flow.flowUnit,
            unitGroup: flow.flowUnitGroup,
            comment: flow.flowComment,
            category: flow.category,
        },
        onSubmit: ({ value }) => {
            // Do something with form data
            console.log(value);
        },
    });



    const SmallerDeleteButton = styled(Button)(({ theme }) => {return {
        // minHeight: '16px',
        // minWidth: '16px',
        marginTop: '0',
        marginRight: '0',
        padding: '5px',
        border: '0',
        borderRadius: '50px',
        boxShadow: 'none',
        fontSize: '12px',
        width: 'auto',
        backgroundColor: '#B85959',
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover': {
            backgroundColor: '#EFC4C4',
            boxShadow: 'none',
        },
        '@media (max-width: 1680px)': {
            padding: '2px',
        },
    }});

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const [selectedFlow, setSelectedFlow] = useState<SelectFlow>({
        name: flow.flowName,
        id: flow.flowId,
        category: flow.category,
        unitGroup: flow.flowUnitGroup,
    });
    const [selectableUnits, setSelectableUnits] = useState<string[]>([]);
    const [selectableUnitValues, setSelectableUnitValues] = useState<string[]>(
        []
    );

    const selectEditFlowHandler = (_flow: SelectFlow) => {
        const unitGroup = unitGroups.find(
            (_unitGroup) => {return _unitGroup.id === _flow.unitGroup}
        );
        if (unitGroup) {
            const unit = unitGroup.units[0];
            setSelectedFlow(_flow);
            form.setFieldValue('id', _flow.id);
            form.setFieldValue('name', _flow.name);
            form.setFieldValue('unit', unit.id);
            form.setFieldValue('unitGroup', _flow.unitGroup);
        }
    };
    useEffect(() => {
        if (flow.flowUnitGroup) {
            const unitGroup = unitGroups.find(
                (_unitGroup) => _unitGroup.id === flow.flowUnitGroup
            );
            if (unitGroup) {
                setSelectableUnits(unitGroup.units.map((unit) => unit.name));
                setSelectableUnitValues(unitGroup.units.map((unit) => unit.id));
                // Set the existing value for flowUnit if already selected
                form.setFieldValue('unit', flow.flowUnit);
            }
        }
    }, [flow.flowUnitGroup, unitGroups, flow.flowUnit]);
    
    // Trigger update when switching dimensions
    // useEffect(() => {
    //     triggerUpdate();
    // }, [lifeCycleStage, flow.flowUnitGroup]);
    

    const triggerUpdate = () => {
        if (lifeCycleStage) {
            const matchingStage = stages.find(
                (stage) => {return stage.lcs === lifeCycleStage}
            );
            if (matchingStage) {
                const currentFlows = matchingStage.values;
                const matchingFlow = currentFlows.find((item,itemIndex) => {return itemIndex === index});
                const endProductName = form.getFieldValue('endProductName');
                const id = form.getFieldValue('id');
                const name = form.getFieldValue('name');
                const quantity = form.getFieldValue('quantity');
                const unit = form.getFieldValue('unit');
                const unitGroup = form.getFieldValue('unitGroup');
                const comment = form.getFieldValue('comment');
                const category = form.getFieldValue('category');

                const updatedFlow: EnvFlow = {
                    index,
                    endProductName,
                    flowId: id,
                    flowName: name,
                    flowQuantity: quantity,
                    flowUnit: unit,
                    flowUnitGroup: unitGroup,
                    flowComment: comment,
                    category: category,
                };
                if (matchingFlow) {
                    currentFlows.splice(index, 1, updatedFlow);
                } else {
                    currentFlows.push(updatedFlow);
                }
            }

            envForm.setFieldValue('envInputEmissions', stages);
        }
    };



    return (
        <>
            <TableRow
                sx={{
                    // display: 'flex',
                    // flexDirection: 'row',
                    display: 'grid',
                    gridTemplateColumns: '1fr 2fr 1fr 1fr 1fr auto',
                    fontSize: '12px',
                    alignItems: 'center',
                    color: '#707070',
                    '&:nth-of-type(odd)': {
                        backgroundColor: '#F3F3F3',
                    },
                    '&:nth-of-type(even)': {
                        backgroundColor: '#E6E6E6',
                    },
                }}
            >

                <TableCell
                    sx={{
                        width: '-webkit-fill-available',
                        fontSize: '0.7vw',
                        lineHeight: '1.1vw',
                        padding: '5px',
                        border: 0,
                        [`&.${tableCellClasses.head}`]: {
                            backgroundColor: '#004021',
                            color: 'white',
                        },
                        [`&.${tableCellClasses.body}`]: {
                            // fontSize: 14,
                        },
                        '@media (max-width: 1680px)': {
                            padding: '5px',
                            // fontSize: 10,
                            // lineHeight: '15px',
                        },
                    }}
                >
                    <form.Field
                        name='endProductName'
                        children={(field) => {return (
                            <TextField
                                id={field.name}
                                className={field.name}
                                value={field.state.value}
                                onBlur={field.handleBlur}
                                onChange={(e) => {
                                    field.handleChange(e.target.value);
                                    triggerUpdate();
                                }}
                                size='small'
                                sx={{
                                    padding: '2px',
                                    '&.MuiInputBase-input': {
                                        padding: '5.5px 12px',
                                    },
                                }}
                            />
                        )}}
                    />
                </TableCell>

                <TableCell
                    sx={{
                        width: '-webkit-fill-available',
                        fontSize: '0.7vw',
                        padding: '5px',
                        border: 0,
                        [`&.${tableCellClasses.head}`]: {
                            backgroundColor: '#004021',
                            color: 'white',
                        },
                        [`&.${tableCellClasses.body}`]: {
                            // fontSize: 14,
                        },
                        '@media (max-width: 1680px)': {
                            padding: '5px',
                            // fontSize: 10,
                            // lineHeight: '15px',
                        },
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <Box
                            sx={{
                                lineHeight: '16px',
                                whiteSpace: 'nowrap',
                                maxWidth: '10vw',
                                overflowX: 'auto',
                                '::-webkit-scrollbar': {
                                    width: '0.2em',
                                },
                                '::-webkit-scrollbar-track': {
                                    background: '#939598',
                                    border: 0,
                                },
                                '::-webkit-scrollbar-thumb': {
                                    backgroundColor: '#32463e',
                                    border: 0,
                                },
                            }}
                        >
                            {selectedFlow && selectedFlow.name}
                        </Box>
                        <ThemeProvider theme={AddButton}>
                            <Button
                                onClick={(e) => {
                                    setSelectHandler(() => {return selectEditFlowHandler});
                                    openProcessSelect(e);
                                }}
                                style={{
                                    border: 0,
                                    background: 0,
                                }}
                            >
                                <EditIcon sx={{ color: '#707070' }} />
                            </Button>
                        </ThemeProvider>
                    </div>
                </TableCell>
                <TableCell
                    sx={{
                        width: '-webkit-fill-available',
                        fontSize: '0.7vw',
                        lineHeight: '1.1vw',
                        padding: '5px',
                        border: 0,
                        [`&.${tableCellClasses.head}`]: {
                            backgroundColor: '#004021',
                            color: 'white',
                        },
                        [`&.${tableCellClasses.body}`]: {
                            // fontSize: 14,
                        },
                        '@media (max-width: 1680px)': {
                            padding: '5px',
                            // fontSize: 10,
                            // lineHeight: '15px',
                        },
                    }}
                >
                    <form.Field
                        name='quantity'
                        children={(field) => {return (
                            <TextField
                                id={field.name}
                                className={field.name}
                                value={field.state.value}
                                onBlur={field.handleBlur}
                                onChange={(e) => {
                                    field.handleChange(Number(e.target.value));
                                    triggerUpdate();
                                }}
                                required
                                type='number'
                                defaultValue={0}
                                size='small'
                                sx={{
                                    padding: '5px',
                                }}
                            />
                        )}}
                    />
                </TableCell>
                <TableCell
                    sx={{
                        width: '-webkit-fill-available',
                        fontSize: '0.7vw',
                        lineHeight: '1.1vw',
                        padding: '5px',
                        border: 0,
                        [`&.${tableCellClasses.head}`]: {
                            backgroundColor: '#004021',
                            color: 'white',
                        },
                        [`&.${tableCellClasses.body}`]: {
                            // fontSize: 14,
                        },
                        '@media (max-width: 1680px)': {
                            padding: '5px',
                            // fontSize: 10,
                            // lineHeight: '15px',
                        },
                    }}
                >
                    <div className='process-unit'>
                        {/* Unit */}
                        <form.Field
                            name='unit'
                            children={(field) => {return (
                                <Dropdown
                                    options={selectableUnits}
                                    values={selectableUnitValues}
                                    onChange={(e) => {
                                        field.handleChange(e.target.value);
                                    }}
                                    id={field.name}
                                    className={field.name}
                                    value={field.state.value}
                                    onBlur={field.handleBlur}
                                />
                            )}}
                        />
                    </div>
                </TableCell>
                <TableCell
                    sx={{
                        width: '-webkit-fill-available',
                        fontSize: '0.7vw',
                        lineHeight: '1.1vw',
                        padding: '5px',
                        border: 0,
                        [`&.${tableCellClasses.head}`]: {
                            backgroundColor: '#004021',
                            color: 'white',
                        },
                        [`&.${tableCellClasses.body}`]: {
                            // fontSize: 14,
                        },
                        '@media (max-width: 1680px)': {
                            padding: '5px',
                            // fontSize: 10,
                            // lineHeight: '15px',
                        },
                    }}
                >
                    <form.Field
                        name='comment'
                        children={(field) => {return (
                            <TextField
                                id={field.name}
                                className={field.name}
                                value={field.state.value}
                                onBlur={field.handleBlur}
                                onChange={(e) => {
                                    field.handleChange(e.target.value);
                                    triggerUpdate();
                                }}
                                size='small'
                                sx={{
                                    padding: '2px',
                                    '&.MuiInputBase-input': {
                                        padding: '5.5px 12px',
                                    },
                                }}
                            />
                        )}}
                    />
                </TableCell>
                <TableCell
                    sx={{
                        width: '2rem',
                        fontSize: '0.7vw',
                        lineHeight: '1.1vw',
                        padding: '0',
                        display: 'flex',
                        border: 0,
                        justifyContent: 'center',
                        [`&.${tableCellClasses.head}`]: {
                            backgroundColor: '#004021',
                            color: 'white',
                        },
                        [`&.${tableCellClasses.body}`]: {
                            // fontSize: 14,
                        },
                        '@media (max-width: 1680px)': {
                            // padding: '5px',
                            // fontSize: 10,
                            // lineHeight: '15px',
                        },
                    }}
                >
                    <ThemeProvider theme={ExitButton}>
                        <SmallerDeleteButton onClick={() =>{deleteHandler(index)}}>
                            <ClearIcon sx={{ fontSize: '0.7vw' }} />
                        </SmallerDeleteButton>
                    </ThemeProvider>
                </TableCell>
            </TableRow>

            {/* <InputTableRow>

                <TableCell align='center' />

                <TableCell align='center'>
                    Test
                    <ThemeProvider theme={AddButton}>
                        <Button onClick={openProcessSelect}>
                            <EditIcon sx={{ color: '#707070' }} />
                        </Button>
                    </ThemeProvider>
                </TableCell>
                <TableCell align='center'>Test</TableCell>
                <TableCell align='center'>Test</TableCell>
                <TableCell align='center'>Test</TableCell>
                <TableCell align='center'>
                    <ThemeProvider theme={ExitButton}>
                        <SmallerDeleteButton>
                            <ClearIcon sx={{ fontSize: '16px' }} />
                        </SmallerDeleteButton>
                    </ThemeProvider>
                </TableCell>

            </InputTableRow> */}
        </>
    );
}
