import React, { useRef } from "react";
import "./Contact.css";
import Header from "../components/Header";
import Footer from "../components/Footer";
import emailjs from "emailjs-com";

export const ContactUs: React.FC = () => {
    const form = useRef<HTMLFormElement>(null);

    const sendEmail = (e: React.FormEvent) => {
        e.preventDefault();
    
        emailjs
            .sendForm(
                "service_p30w9xv",
                "template_s20t0tj",
                form.current as HTMLFormElement,  // Type assertion here
                "0KCWlWqLsxoZip40R"
            )
            .then(
                (result) => {
                    console.log(result.text);
                },
                (error) => {
                    console.log(error.text);
                }
            );
    };
    
    return (
        <div className="website-only">
            <Header />

            <div className="header-container">
                <div className="header-transparent-layer"></div>
                <img
                    src={require("../images/headers/pexels-felix-mittermeier-2832048.jpg")}
                    alt="Purple flowers in a field"
                />
                <h1>Contact</h1>
            </div>

            <div className="contact-form">
                <div className="box-container">
                    <p>
            Interested in knowing more about TOSSA or getting involved in the
            testing phase? If so, please contact us through the form to your
            right.
                    </p>
                    <h2>Contact Info</h2>
                    <p>1100-225 6 Ave SW Brookfield Place, Calgary, AB, Canada T2P 1N2</p>
                    <p>admin@tossa-sustainability.com</p>
                </div>
                <div className="box-container">
                    <form ref={form} onSubmit={sendEmail}>
                        <label htmlFor="name">Name</label>
                        <input type="text" id="name" name="name" />

                        <label htmlFor="email">Email</label>
                        <input type="text" id="email" name="email" />

                        <label htmlFor="message">Message</label>
                        <textarea id="message" name="message"></textarea>

                        <input type="submit" value="Contact Us" className="button-link" />
                    </form>
                </div>
            </div>

            <Footer />
        </div>
    );
};

export default ContactUs;
