import React, { useState } from 'react';
import {
    Box, Button, FormControl, FormLabel, IconButton, InputAdornment, Link, TextField, Typography,
} from '@mui/material';
import authStyles from '../Auth.style';
import logo from '../../../assets/tossa.png';
import bgImg from '../../../assets/img1.jpg';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

function Signup() {
    const classes = authStyles();
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
    return (
        <Box className={classes.root}>
            <Box className={`${classes.leftBox} rootBox`}>
                <img src={bgImg} alt="mountain" />
                <Box className={classes.overlay}>
                    <Typography variant="h1">
                        Empowering for the best sustainable decisions
                        <Typography component="span">
                            “Power and Simplicity”
                        </Typography>
                    </Typography>
                </Box>
            </Box>
            <Box className={`${classes.rightBox} rootBox`}>
                <Box className={classes.mainWrapper}>
                    <img src={logo} alt="Tossa" className={classes.logo} />
                    <Typography variant="h1" className={classes.header}>Signup</Typography>
                    <form className={classes.form}>
                        <FormControl className={classes.formControl}>
                            <FormLabel htmlFor="fnameField">First Name</FormLabel>
                            <TextField id="fnameField" name="fname" placeholder="Enter Your First Name" />
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <FormLabel htmlFor="lnameField">Last Name</FormLabel>
                            <TextField id="lnameField" name="lname" placeholder="Enter Your Last Name" />
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <FormLabel htmlFor="emailField">Email Address</FormLabel>
                            <TextField type="email" id="emailField" name="email" placeholder="Enter Your Email Address" />
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <FormLabel htmlFor="passwordField">Password</FormLabel>
                            <TextField
                                type={showPassword ? 'text' : 'password'}
                                id="passwordField"
                                name="password"
                                placeholder="Enter Your Password"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => {return setShowPassword(!showPassword)}}>
                                                {
                                                    showPassword ? (<VisibilityOutlinedIcon />) : (
                                                        <VisibilityOffOutlinedIcon />
                                                    )
                                                }
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <FormLabel htmlFor="confirmPasswordField">Confirm Password</FormLabel>
                            <TextField
                                type={showConfirmPassword ? 'text' : 'password'}
                                id="confirmPasswordField"
                                name="confirmPassword"
                                placeholder="Enter Your Confirm Password"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => {return setShowConfirmPassword(!showConfirmPassword)}}>
                                                {
                                                    showConfirmPassword ? (<VisibilityOutlinedIcon />) : (
                                                        <VisibilityOffOutlinedIcon />
                                                    )
                                                }
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </FormControl>
                        <Box className={classes.FormElementInBox}>
                            <Button type="submit" disableRipple className={classes.signInBtn}>
                                Signup
                            </Button>
                            <Typography>
                                Already have an account?
                                {' '}
                                <Link href="/login" className={classes.Link}>Login</Link>
                            </Typography>
                        </Box>
                    </form>
                </Box>
            </Box>
        </Box>
    );
}

export default Signup;
