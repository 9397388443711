// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mini-button-wrapper{
    display: flex;
    gap: 2px;

}


@media (max-width: 1680px) {

button.MuiButtonBase-root.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.MuiButton-colorPrimary.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.MuiButton-colorPrimary.css-pnpb5q-MuiButtonBase-root-MuiButton-root img {
    width: 20px;
}

}

@media (max-width: 1300px) {

    button.MuiButtonBase-root.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.MuiButton-colorPrimary.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.MuiButton-colorPrimary.css-pnpb5q-MuiButtonBase-root-MuiButton-root img {
        width: 15px;
    }
    
    }`, "",{"version":3,"sources":["webpack://./src/components/style/components/MiniButtonSelects.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,QAAQ;;AAEZ;;;AAGA;;AAEA;IACI,WAAW;AACf;;AAEA;;AAEA;;IAEI;QACI,WAAW;IACf;;IAEA","sourcesContent":[".mini-button-wrapper{\r\n    display: flex;\r\n    gap: 2px;\r\n\r\n}\r\n\r\n\r\n@media (max-width: 1680px) {\r\n\r\nbutton.MuiButtonBase-root.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.MuiButton-colorPrimary.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.MuiButton-colorPrimary.css-pnpb5q-MuiButtonBase-root-MuiButton-root img {\r\n    width: 20px;\r\n}\r\n\r\n}\r\n\r\n@media (max-width: 1300px) {\r\n\r\n    button.MuiButtonBase-root.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.MuiButton-colorPrimary.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.MuiButton-colorPrimary.css-pnpb5q-MuiButtonBase-root-MuiButton-root img {\r\n        width: 15px;\r\n    }\r\n    \r\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
