import { ThemeProvider } from '@emotion/react';
import { Button } from '@mui/base';
import { TextField, TableCell, TableRow, styled, Box } from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import { FormApi, useForm } from '@tanstack/react-form';
import {
    EnvironmentalInputsForm,
    EnvInputProcess,
    EnvProcessInputLCS,
} from '../../../../../interface/Form';
import AddButton from '../../../../style/components/AddButton';
import ExitButton from '../../../../style/components/ExitButton';
import { useEffect, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import { SelectProcess } from '../../../../../interface/Process';
import { UnitGroup, findUnitUnitGroup } from '../../../../../interface/Unit';
import Dropdown from '../../../../dropdowns/Dropdown';

interface ProcessListItemProps {
    openProcessSelect: (event: React.MouseEvent<HTMLElement>) => void;
    index: number;
    envForm: FormApi<EnvironmentalInputsForm>;
    unitGroups: UnitGroup[];
    process: EnvInputProcess;
    setSelectHandler: React.Dispatch<
        React.SetStateAction<(process: SelectProcess) => void>
    >;
    lifeCycleStage: string;
    deleteHandler: (processIndex: number) => void;

}

export function EnvInputProcessListItem({
    unitGroups,
    openProcessSelect,
    index,
    envForm,
    process,
    setSelectHandler,
    lifeCycleStage,
    deleteHandler,
}: ProcessListItemProps) {
    const form = useForm({
        defaultValues: {
            index,
            endProductName: process.endProductName,
            processId: process.processId,
            processName: process.processName,
            processQuantity: process.processQuantity || 1,
            processUnit: process.processUnit,
            processUnitGroup: process.processUnitGroup,
            processComment: process.processComment,
            category: process.category,
        },
        onSubmit: ({ value }) => {
            // Do something with form data
            console.log(value);
        },
    });

    const SmallerDeleteButton = styled(Button)(({ theme }) => {
        return {
            // minHeight: '16px',
            // minWidth: '16px',
            marginTop: '0',
            marginRight: '0',
            padding: '5px',
            border: '0',
            borderRadius: '50px',
            boxShadow: 'none',
            fontSize: '12px',
            width: 'auto',
            backgroundColor: '#B85959',
            color: 'white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '&:hover': {
                backgroundColor: '#EFC4C4',
                boxShadow: 'none',
            },
            '@media (max-width: 1680px)': {
                padding: '2px',
            },
        }
    });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const [selectedProcess, setSelectedProcess] = useState<SelectProcess>({
        name: process.processName.split(' - ')[0],
        id: process.processId,
        location: process.processName.split(' - ')[1],
        category: process.category,
        unit: process.processUnit,
    });
    const [selectableUnits, setSelectableUnits] = useState<string[]>([]);
    const [selectableUnitValues, setSelectableUnitValues] = useState<string[]>(
        []
    );

    const selectEditProcessHandler = (_process: SelectProcess) => {
        
        const unitGroup = findUnitUnitGroup(_process.unit || '', unitGroups);
        setSelectedProcess(_process);
        form.setFieldValue('processId', _process.id);
        form.setFieldValue('processName', _process.name);
        if (_process.unit) {
            form.setFieldValue('processUnit', _process.unit);
        }
        form.setFieldValue('processUnitGroup', unitGroup?.name);



    };


    useEffect(() => {
        if (process.processUnitGroup) {
            const unitGroup = unitGroups.find(
                (_unitGroup) => _unitGroup.id === process.processUnitGroup
            );
            if (unitGroup) {
                setSelectableUnits(unitGroup.units.map((unit) => unit.name));
                setSelectableUnitValues(unitGroup.units.map((unit) => unit.id));
                // Set the existing value for processUnit if already selected
                form.setFieldValue('processUnit', process.processUnit);
            }
        }
    }, [process.processUnitGroup, unitGroups, process.processUnit]);




    const triggerUpdate = () => {
        if (lifeCycleStage) {
            const stages: EnvProcessInputLCS[] =
                envForm.getFieldValue('envInputProcesses');
            const matchingStage = stages.find(
                (stage) => { return stage.lcs === lifeCycleStage }
            );
            if (matchingStage) {
                const currentProcesses = matchingStage.values;
                const matchingProcess = currentProcesses.find((item, itemIndex) => { return itemIndex === index });

                const endProductName = form.getFieldValue('endProductName');
                const processId = form.getFieldValue('processId');
                const processName = form.getFieldValue('processName');
                const processQuantity = form.getFieldValue('processQuantity');
                const processUnit = form.getFieldValue('processUnit');
                const processUnitGroup = form.getFieldValue('processUnitGroup');
                const processComment = form.getFieldValue('processComment');
                const category = form.getFieldValue('category');

                const updatedProcess: EnvInputProcess = {
                    index,
                    endProductName,
                    processId,
                    processName,
                    processQuantity,
                    processUnit,
                    processUnitGroup,
                    processComment,
                    category,
                };
                if (matchingProcess) {
                    currentProcesses.splice(index, 1, updatedProcess);
                } else {
                    currentProcesses.push(updatedProcess);
                }
            }
            envForm.setFieldValue('envInputProcesses', stages);
        }
    };
    // Trigger update when switching dimensions 
    // useEffect(() => {
    //     triggerUpdate();
    // }, [ process.processUnitGroup]);

    return (
        <>
            <TableRow
                sx={{
                    // display: 'flex',
                    // flexDirection: 'row',
                    display: 'grid',
                    gridTemplateColumns: '1fr 2fr 1fr 1fr 1fr auto',
                    fontSize: '12px',
                    alignItems: 'center',
                    color: '#707070',
                    '&:nth-of-type(odd)': {
                        backgroundColor: '#F3F3F3',
                    },
                    '&:nth-of-type(even)': {
                        backgroundColor: '#E6E6E6',
                    },
                }}
            >
                <TableCell
                    sx={{
                        width: '-webkit-fill-available',
                        fontSize: '0.7vw',
                        lineHeight: '1.1vw',
                        padding: '5px',
                        border: 0,
                        [`&.${tableCellClasses.head}`]: {
                            backgroundColor: '#004021',
                            color: 'white',
                        },
                        [`&.${tableCellClasses.body}`]: {
                            // fontSize: 14,
                        },
                        '@media (max-width: 1680px)': {
                            padding: '5px',
                            // fontSize: 10,
                            // lineHeight: '15px',
                        },
                    }}
                >
                    <form.Field
                        name='endProductName'
                        children={(field) => {
                            return (
                                <TextField
                                    id={field.name}
                                    className={field.name}
                                    value={field.state.value}
                                    onBlur={field.handleBlur}
                                    onChange={(e) => {
                                        field.handleChange(e.target.value);
                                        triggerUpdate();
                                    }}
                                    size='small'
                                    sx={{
                                        padding: '2px',
                                        '&.MuiInputBase-input': {
                                            padding: '5.5px 12px',
                                        },
                                    }}
                                />
                            )
                        }}
                    />
                </TableCell>
                <TableCell
                    sx={{
                        width: '-webkit-fill-available',
                        fontSize: '0.7vw',
                        padding: '5px',
                        border: 0,
                        [`&.${tableCellClasses.head}`]: {
                            backgroundColor: '#004021',
                            color: 'white',
                        },
                        [`&.${tableCellClasses.body}`]: {
                            // fontSize: 14,
                        },
                        '@media (max-width: 1680px)': {
                            padding: '5px',
                            // fontSize: 10,
                            // lineHeight: '15px',
                        },
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <Box
                            sx={{
                                lineHeight: '16px',
                                whiteSpace: 'nowrap',
                                maxWidth: '10vw',
                                overflowX: 'auto',
                                '::-webkit-scrollbar': {
                                    width: '0.2em',
                                    height: '0.7vh',
                                },
                                '::-webkit-scrollbar-track': {
                                    background: '#939598',
                                    border: 0,
                                },
                                '::-webkit-scrollbar-thumb': {
                                    backgroundColor: '#32463e',
                                    border: 0,
                                },
                            }}
                        >
                            {selectedProcess && selectedProcess.name + selectedProcess.location}
                        </Box>
                        <ThemeProvider theme={AddButton}>
                            <Button
                                onClick={(e) => {
                                    setSelectHandler(() => { return selectEditProcessHandler });
                                    openProcessSelect(e);
                                }}
                                style={{
                                    border: 0,
                                    background: 0,
                                }}
                            >
                                <EditIcon
                                    style={{
                                        color: '#707070',
                                    }}
                                />
                            </Button>
                        </ThemeProvider>
                    </div>
                </TableCell>
                <TableCell
                    sx={{
                        width: '-webkit-fill-available',
                        fontSize: '0.7vw',
                        lineHeight: '1.1vw',
                        padding: '5px',
                        border: 0,
                        [`&.${tableCellClasses.head}`]: {
                            backgroundColor: '#004021',
                            color: 'white',
                        },
                        [`&.${tableCellClasses.body}`]: {
                            // fontSize: 14,
                        },
                        '@media (max-width: 1680px)': {
                            padding: '5px',
                            // fontSize: 10,
                            // lineHeight: '15px',
                        },
                    }}
                >
                    <form.Field
                        name='processQuantity'
                        children={(field) => {
                            return (
                                <TextField
                                    id={field.name}
                                    className={field.name}
                                    value={field.state.value}
                                    onBlur={field.handleBlur}
                                    onChange={(e) => {
                                        field.handleChange(Number(e.target.value));
                                        triggerUpdate();
                                    }}
                                    required
                                    type='number'
                                    defaultValue={0}
                                    size='small'
                                    sx={{
                                        padding: '5px',
                                    }}
                                />
                            )
                        }}
                    />
                </TableCell>
                <TableCell
                    sx={{
                        width: '-webkit-fill-available',
                        fontSize: '0.7vw',
                        lineHeight: '1.1vw',
                        padding: '5px',
                        border: 0,
                        [`&.${tableCellClasses.head}`]: {
                            backgroundColor: '#004021',
                            color: 'white',
                        },
                        [`&.${tableCellClasses.body}`]: {
                            // fontSize: 14,
                        },
                        '@media (max-width: 1680px)': {
                            padding: '5px',
                            // fontSize: 10,
                            // lineHeight: '15px',
                        },
                    }}
                >
                    <div className='process-unit'>
                        {/* Unit */}
                        <form.Field
                            name='processUnit'
                            children={(field) => {
                                return (
                                    <Dropdown
                                        options={selectableUnits}
                                        values={selectableUnitValues}
                                        onChange={(e) => {
                                            field.handleChange(e.target.value);
                                            triggerUpdate();
                                        }}
                                        id={field.name}
                                        className={field.name}
                                        value={field.state.value}
                                        onBlur={field.handleBlur}
                                    />
                                )
                            }}
                        />
                    </div>
                </TableCell>
                <TableCell
                    sx={{
                        width: '-webkit-fill-available',
                        fontSize: '0.7vw',
                        lineHeight: '1.1vw',
                        padding: '5px',
                        border: 0,
                        [`&.${tableCellClasses.head}`]: {
                            backgroundColor: '#004021',
                            color: 'white',
                        },
                        [`&.${tableCellClasses.body}`]: {
                            // fontSize: 14,
                        },
                        '@media (max-width: 1680px)': {
                            padding: '5px',
                            // fontSize: 10,
                            // lineHeight: '15px',
                        },
                    }}
                >
                    {/* <form.Field
            name='processComment'
            children={(field) => {
              <TextField
              id={field.name}
              className={field.name}
              value={field.state.value}
              onBlur={field.handleBlur}
              onChange={(e) => {
                field.handleChange(e.target.value);
                triggerUpdate();
              }}
              size='small'
              sx={{
                padding: '2px',
                '&.MuiInputBase-input': {
                  padding: '5.5px 12px',
                },
              }}
              />;
            }}
          /> */}
                    <form.Field
                        name='processComment'
                        children={(field) => {
                            return (
                                <TextField
                                    id={field.name}
                                    className={field.name}
                                    value={field.state.value}
                                    onBlur={field.handleBlur}
                                    onChange={(e) => {
                                        field.handleChange(e.target.value);
                                        triggerUpdate();
                                    }}
                                    size='small'
                                    sx={{
                                        padding: '2px',
                                        '&.MuiInputBase-input': {
                                            padding: '5.5px 12px',
                                        },
                                    }}
                                />
                            )
                        }}
                    />
                </TableCell>
                <TableCell
                    sx={{
                        width: '2rem',
                        fontSize: '0.7vw',
                        lineHeight: '1.1vw',
                        padding: '0',
                        display: 'flex',
                        border: 0,
                        justifyContent: 'center',
                        [`&.${tableCellClasses.head}`]: {
                            backgroundColor: '#004021',
                            color: 'white',
                        },
                        [`&.${tableCellClasses.body}`]: {
                            // fontSize: 14,
                        },
                        '@media (max-width: 1680px)': {
                            // padding: '5px',
                            // fontSize: 10,
                            // lineHeight: '15px',
                        },
                    }}
                >
                    <ThemeProvider theme={ExitButton}>
                        <SmallerDeleteButton onClick={() => { deleteHandler(index) }}>
                            <ClearIcon sx={{ fontSize: '0.7vw' }} />
                        </SmallerDeleteButton>
                    </ThemeProvider>
                </TableCell>
            </TableRow >

            {/* <InputTableRow>

                <TableCell align='center' />

                <TableCell align='center'>
                    Test
                    <ThemeProvider theme={AddButton}>
                        <Button onClick={openProcessSelect}>
                            <EditIcon sx={{ color: '#707070' }} />
                        </Button>
                    </ThemeProvider>
                </TableCell>
                <TableCell align='center'>Test</TableCell>
                <TableCell align='center'>Test</TableCell>
                <TableCell align='center'>Test</TableCell>
                <TableCell align='center'>
                    <ThemeProvider theme={ExitButton}>
                        <SmallerDeleteButton>
                            <ClearIcon sx={{ fontSize: '16px' }} />
                        </SmallerDeleteButton>
                    </ThemeProvider>
                </TableCell>

            </InputTableRow> */}
        </>
    );
}
