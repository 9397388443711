// FormContext.tsx
import React, {
    ReactNode, createContext, useContext, useState,
} from 'react';

export type FormState = Record<string, any>;

interface FormContextProps {
        formState: FormState;
        setFormState: (_formState: FormState) => void;
}

const FormContext = createContext<FormContextProps | undefined>(undefined);

interface FormProviderProps {
        children: ReactNode;
}

export const FormProvider: React.FC<FormProviderProps> = ({ children }) => {
    const [formState, _setFormState] = useState<FormState>({});
    const setFormState = (_formState : FormState) => {
        console.log("SETTING FORM STATE");
        console.log(_formState);
        _setFormState(_formState);
    };
    return (
        <FormContext.Provider value={{ formState, setFormState }}>
            {children}
        </FormContext.Provider>
    );
};
export const useFormContext = () => {
    const context = useContext(FormContext);
    if (!context) {
        throw new Error('useFormContext must be used within a FormProvider');
    }
    return context;
};
