import React from "react";
import "../pages/Homepage.css";
import Header from "../components/Header";
import Footer from "../components/Footer";
// import PopUpWebinar from "../components/PopUpWebinar"; //Please keep this in case an upcoming webinar is here
import { Link } from "@tanstack/react-router";
import { Box } from "@mui/material";

const Homepage: React.FC = () => {
    return (
        <div className="website-only">
            <Header />

            <div className="header-container homepage-header">
                <div className="header-transparent-layer"> </div>
                <img
                    src={require("../images/headers/pexels-rien-ramerman-3634632.jpg")}
                    alt="Snowy mountains behind a lake and trees"
                />
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "4rem",
                    }}
                >
                    <h1>TOSSA</h1>
                    <Box
                        sx={{
                            zIndex: 1,
                        }}
                    >
                        <p>Empowering for the best sustainable decisions</p>
                        <p>
                            <i>&quot;Power and Simplicity&quot;</i>
                        </p>
                    </Box>
                </Box>
            </div>
            <div className="box-container">
                <h2>About Tossa</h2>
                {/*<<<<<<< Updated upstream
    <p className='p-hide'>Three in ONE Automated Software Solution that Provides Insights / Decision Support for Companies to</p>
=======
    <p>Three in ONE Automated Software Solution that Provides Insights / Decision Support for Companies to</p>
>>>>>>> Stashed changes*/}
                <div className="about-tossa-wrap">
                    <div className="image-container-two about-tossa">
                        <div className="highlight-figure">
                            <img
                                src={require("../images/figures/Automated-Software 01.png")}
                                alt="Lightbulb flashing"
                            />
                            <h3>Increase revenue</h3>
                        </div>
                        <div className="highlight-figure">
                            <img
                                src={require("../images/figures/Automated-Software 02.png")}
                                alt="Shiny trophy"
                            />
                            <h3>Save the environment</h3>
                        </div>
                        <div className="highlight-figure">
                            <img
                                src={require("../images/figures/Automated-Software 03.png")}
                                alt="Computer showcasing forms and papers"
                            />
                            <h3>Create social value</h3>
                        </div>
                    </div>
                    <Link to="/about/whoarewe" className="button-link">
            Learn more about TOSSA
                    </Link>
                </div>
            </div>
            <div className="box-container">
                <h2>Why Us?</h2>
                <p>
          TOSSA is the only organized and scientifically sound intuitive
          computer based ESG+ tool to evaluate whole sustainability performance
          of products/services/corporate operations based on standardized life
          cycle assessment methodology.
                </p>
            </div>
            <div className="box-container">
                <h2>Our Solutions</h2>
                <div className="image-container">
                    <div className="image-box">
                        <img
                            src={require("../images/box-images/pexels-expect-best-744338.jpg")}
                            alt="Windmill in front of grey clouds"
                        />
                        <Link to="/services/tossasoftware" className="button-link">
              LCSA Software
                        </Link>
                    </div>
                    <div className="image-box">
                        <img
                            src={require("../images/box-images/pexels-cottonbro-4101143.jpg")}
                            alt="Person counseling another person"
                        />
                        <Link to="/services/consultingservices" className="button-link">
              Consulting Services
                        </Link>
                    </div>
                    <div className="image-box">
                        <img
                            src={require("../images/box-images/pexels-genine-alyssa-pedrenoandrada-2403209.jpg")}
                            alt="Train in a forest"
                        />
                        <Link to="/services/training" className="button-link">
              Training
                        </Link>
                    </div>
                </div>
            </div>

            {/*<PopUpWebinar />*/}
            <Footer />
        </div>
    );
};

export default Homepage;
