// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.product-name-desc-section div#unitGroup{

}
.product-name .MuiInputLabel-root {
    font-size: 0.8vw !important; 
}

.product-name .MuiInputLabel-root {
    font-size: 0.7vw !important;
}`, "",{"version":3,"sources":["webpack://./src/components/createproduct/ProductDetails/components/ProductNameDescForm.css"],"names":[],"mappings":"AAAA;;AAEA;AACA;IACI,2BAA2B;AAC/B;;AAEA;IACI,2BAA2B;AAC/B","sourcesContent":[".product-name-desc-section div#unitGroup{\r\n\r\n}\r\n.product-name .MuiInputLabel-root {\r\n    font-size: 0.8vw !important; \r\n}\r\n\r\n.product-name .MuiInputLabel-root {\r\n    font-size: 0.7vw !important;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
