import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => {return {
    root: {
        minHeight: '100vh',
        display: 'flex',
        '& .rootBox': {
            width: '50%',
        },
    },
    leftBox: {
        overflow: 'hidden',
        position: 'relative',
        '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
        },
    },
    rightBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
    },
    overlay: {
        background: 'rgba(22,109,70,0.7 )',
        position: 'absolute',
        top: '0',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& h1': {
            fontSize: '1.5vw',
            textAlign: 'center',
            lineHeight: '5vh',
            color: '#fff',
            '& span': {
                fontStyle: 'italic',
                fontSize: '1.4vw',
                fontWeight: 500,
            },
        },
    },
    mainWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '20px',
        width: '85%',
        padding: '20px 0px',
    },
    logo: {
        // width: '142px',
        // height: '66px',
        width: '10vw',
        objectFit: 'contain',
    },
    header: {
        '&.MuiTypography-root': {
            fontSize: '1.4vw',
            fontWeight: 700,
            color: '#166D46',
        },

    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '20px',
        width: '100%',
    },
    formControl: {
        width: '100%',
        display: 'flex',
        gap: '8px',
        '& label': {
            fontFamily: 'Inter-Medium',
            fontWeight: 500,
            fontSize: '0.7vw',
            color: '#2F2F2F',
        },
        '& .MuiInputBase-formControl': {
            border: '1px solid #E2E2E2',
            borderRadius: '5px',
            // height: '40px',
            height: 'auto !important',
            paddingRight: '10px',
            backgroundColor: '#ffffff',
            '& input': {
                fontSize: '0.7vw',
                padding: '1vw 1.5vw',
                '&:-webkit-autofill': {
                    '-webkit-box-shadow': '0 0 0 100px white inset',
                },
                fontFamily: 'Inter',
                fontWeight: 400,
            },
            '& fieldset': {
                display: 'none',
            },

            '& .MuiInputAdornment-root button': {
                padding: '0px',
                '& svg': {
                    width: '1.2vw',
                    height: '1.2vw',
                    color: '#2F2F2F',
                },
                '& span': {
                    display: 'none',

                },
                '&:hover': {
                    background: 'none',
                },
            },
        },
    },
    FormElementInBox: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: '10px',
        '& p': {
            fontSize: '0.7vw',
            fontWeight: 500,
        },

    },
    Link: {
        '&.MuiLink-root': {
            textDecoration: 'none',
            fontSize: '0.7vw',
            color: '#166D46',
            fontFamily: 'Inter-Medium',
            fontWeight: 500,
            cursor: 'pointer',

        },

    },
    signInBtn: {
        '&.MuiButton-root': {
            height: '4vh',
            width: '100%',
            borderRadius: '5px',
            textTransform: 'capitalize',
            backgroundColor: '#166D46 !important',
            color: '#fff',
            marginTop: '10px',
            fontSize: '1vw',
        },
    },
    errorStyle: {
        color: 'red',
        fontSize: '12px',
    },
}});
export default useStyles;
