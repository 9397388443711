/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Box,
    Button,
    styled,
    ThemeProvider,
    tableCellClasses,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import { Unstable_Popup as Popup } from '@mui/base/Unstable_Popup';
import ExitButton from '../../../../../style/components/ExitButton';
import AddButton from '../../../../../style/components/AddButton';
import TableTheme from '../../../../../style/components/TableTheme';
import Popper, { PopperProps } from '@mui/material/Popper';
import SPAIndicatorsTable from '../SPAIndicatorsTable';

const InputTableCell = styled(TableCell)(({ theme }) => {return {
    width: '20rem',
    '&:last-of-type': {
        width: '5rem',
    },
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#004021',
        color: 'white',
    },
    [`&.${tableCellClasses.body}`]: {
        // fontSize: 14,
    },
    '@media (max-width: 1680px)': {
        padding: '5px',
        fontSize: 10,
        lineHeight: '15px',
    },
}});

const InputTableRow = styled(TableRow)(({ theme }) => {return {
    padding: '5px',
    fontSize: '12px',
    color: '#707070',
    '&:nth-of-type(odd)': {
        backgroundColor: '#F3F3F3',
    },
    '&:nth-of-type(even)': {
        backgroundColor: '#E6E6E6',
    },
    '@media (max-width: 1680px)': {
        padding: '5px',
        fontSize: 10,
        lineHeight: '15px',
    },
}});

const SmallerDeleteButton = styled(Button)(({ theme }) => {return {
    minHeight: '16px',
    minWidth: '16px',
    marginTop: '0',
    marginRight: '0',
    padding: '5px',
    border: '0',
    borderRadius: '50px',
}});

function SPAStakeholders() {
    const [processPopupSPA, setProcessPopupEnv] = useState<null | HTMLElement>(
        null,
    );

    const handleIndicatorPopupClick = (event: React.MouseEvent<HTMLElement>) => {
        setProcessPopupEnv(processPopupSPA ? null : event.currentTarget);
    };

    const open = Boolean(processPopupSPA);
    const processPopperSPA = open ? 'process-popper' : undefined;

    return (
        <ThemeProvider theme={TableTheme}>
            <Box>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <InputTableRow>
                                <InputTableCell align='center'>
                                    Stakeholder Name
                                </InputTableCell>
                                <InputTableCell align='center'>Indicators</InputTableCell>
                                <InputTableCell align='center'> </InputTableCell>
                            </InputTableRow>
                        </TableHead>
                        <TableBody>
                            <InputTableRow>
                                <InputTableCell align='center'>Test</InputTableCell>
                                <InputTableCell align='center'>
                                    Set Indicators
                                    <ThemeProvider theme={AddButton}>
                                        <Button onClick={handleIndicatorPopupClick}>
                                            <EditIcon sx={{ color: '#707070' }} />
                                        </Button>
                                    </ThemeProvider>
                                </InputTableCell>
                                <InputTableCell align='center'>
                                    <ThemeProvider theme={ExitButton}>
                                        <SmallerDeleteButton>
                                            <ClearIcon sx={{ fontSize: '16px' }} />
                                        </SmallerDeleteButton>
                                    </ThemeProvider>
                                </InputTableCell>
                            </InputTableRow>
                            <Popper
                                id={processPopperSPA}
                                open={open}
                                anchorEl={processPopupSPA}
                                disablePortal
                                style={{
                                    position: 'fixed',
                                    transform: 'none !important',
                                }}
                            >
                                <Box
                                    sx={{
                                        position: 'fixed !important',
                                        transform: 'none !important',
                                        top: '0 !important',
                                        left: '0 !important',
                                        zIndex: 1300,
                                        width: '100%',
                                        height: '-webkit-fill-available',
                                        backgroundColor: 'rgba(0, 0, 0, 0.54)',
                                    }}
                                >
                                    {/* <SPAIndicatorsTable
                                        handleIndicatorPopupClick={handleIndicatorPopupClick}
                                    /> */}
                                </Box>
                            </Popper>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </ThemeProvider>
    );
}

export default SPAStakeholders;
