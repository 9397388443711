/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { createTheme, ThemeProvider } from '@mui/material';
import { hover } from '@testing-library/user-event/dist/hover';
import { styled } from '@mui/material/styles';
import { green } from '@mui/material/colors';
import TableRow from '@mui/material/TableRow';

const DashboardTheme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 767,
            md: 1024,
            lg: 1450,
            xl: 1600,
        },
    },
    components: {

    },
    typography: {
        fontFamily: 'Roboto, sans-serif',
        h2: {
            fontSize: '1.4vw',
            fontWeight: 'bold',
            fontFamily: 'Montserrat, sans-serif',
            // '@media (max-width: 1680px)': {
            //     fontSize: 24,
            // },
            // '@media (max-width: 1300px)': {
            //     fontSize: 18,
            // },
        },
        h3: {
            fontSize: 20,
            fontWeight: 'bold',
            fontFamily: 'Montserrat, sans-serif',
        },
        body1: {
            fontFamily: 'Roboto, sans-serif',
            fontSize: '0.7vw',
            // '@media (max-width: 1680px)': {
            //     fontSize: 12,
            // },
        },

    },
});

export default DashboardTheme;
