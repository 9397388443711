/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable react/function-component-definition */
import React from 'react';
import {
    Container,
    Paper,
    FormControl,
    Box,
    Button,
    Stack,
    ThemeProvider,
} from '@mui/material';
import { Outlet, useNavigate } from '@tanstack/react-router';
import GeneralTheme from '../../components/style/GeneralTheme';
import ProjectDetailsInputs from '../../components/createproduct/ProductDetails';
import EnvironmentalInputs from '../../components/createproduct/EnvironmentalInputs';
import { FormProvider } from '../../components/forms/FormContext';
import CreateProductHeader from '../../components/results/components/createproductheader';
import TokenExpireLogin from '../auth/TokenExpiredLogin';
import {
    QueryClient,
    QueryClientProvider,
    useQuery,
} from '@tanstack/react-query'
import { fetchEnvMethods, fetchUnitGroups } from '../../helper/fetchUtil';

const queryClient = new QueryClient()

const CreateProduct = () => {

    const { data: loadedMethods = [] } = useQuery({
        queryKey: ['envMethods'],
        queryFn: (() => {
            return fetchEnvMethods()

        }),
        staleTime: Infinity,


    })
    useQuery({
        queryKey: ['unitGroups'],
        queryFn: (() => {

            return fetchUnitGroups()
        }),
        staleTime: Infinity,


    })
    const { isPending, error, data } = useQuery({
        queryKey: ['unitGroups'],
        queryFn: (() => {

            return fetchUnitGroups()
        }),
        staleTime: Infinity,


    })




    return (
        <Box>
          
            <FormProvider>
                <QueryClientProvider client={queryClient}>

                    <TokenExpireLogin />

                    <Outlet />
                </QueryClientProvider>

            </FormProvider>
        </Box>
    );
};

export default CreateProduct;
