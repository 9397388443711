import {
    makeStyles,
    Checkbox,
    Table,
    TableHead,
    TableRow,
    TableBody,
    Box,
} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import React, { useState } from 'react';
import useStyles from './EnvImpactTable.styles';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import { ThemeProvider } from '@mui/styles';
import EnvImpactTableTheme from './EnvImpactTableTheme';

const EnvImpactTable: React.FC = () => {
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const impactTableClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const impactopener = Boolean(anchorEl);
    const impactbutton = impactopener ? 'simple-popper' : undefined;

    return (
        <ThemeProvider theme={EnvImpactTableTheme}>
            <Box className={classes.impactInformationWrap}>
                {/* <Checkbox id='info-impact' className='info-impact-check' /> */}
                <IconButton
                    aria-describedby={impactbutton}
                    type='button'
                    onClick={impactTableClick}
                    className={classes.ButtonWrapper}
                >
                    <InfoOutlinedIcon width={50} sx={{ fontSize: '2vw' }} />
                </IconButton>
                <Popper
                    id={impactbutton}
                    open={impactopener}
                    anchorEl={anchorEl}
                    transition
                    placement='top'
                >
                    {({ TransitionProps }) => {return (
                        <Fade {...TransitionProps} timeout={350}>
                            <Table className={classes.impactMethodTable}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.InputTableCell}>Method</TableCell>
                                        <TableCell className={classes.InputTableCell}>Normalization Sets</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell className={classes.InputTableCell}>CML-IA</TableCell>
                                        <TableCell className={classes.InputTableCell}>
                      World 1990, 1995, 2000;
                                            <br />
                      EU28, 2000, EU25, 2000;
                                            <br />
                      West Europe 1995;
                                            <br />
                      Netherlands, 1997
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className={classes.InputTableCell}>ILCD Midpoint</TableCell>
                                        <TableCell className={classes.InputTableCell}>EU-27, 2010 (available soon)</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className={classes.InputTableCell}>IMPACT 2002+</TableCell>
                                        <TableCell className={classes.InputTableCell}>Europe, 2000</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className={classes.InputTableCell}>ReCiPe</TableCell>
                                        <TableCell className={classes.InputTableCell}>
                      Europe, 2000
                                            <br />
                      World, 2000
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className={classes.InputTableCell}>BEES+</TableCell>
                                        <TableCell className={classes.InputTableCell}>USA, 1997</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className={classes.InputTableCell}>TRACI</TableCell>
                                        <TableCell className={classes.InputTableCell}>
                      US-Canada, 2008;
                                            <br />
                      US, 2008;
                                            <br />
                      Canada, 2008
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className={classes.InputTableCell}>USEtox</TableCell>
                                        <TableCell className={classes.InputTableCell}>
                      Europe, 2004;
                                            <br />
                      North America, 2002/2008
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Fade>
                    )}}
                </Popper>
            </Box>
        </ThemeProvider>
    );
};

export default EnvImpactTable;
