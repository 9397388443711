// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .header-container.bio-page {
    top: 0;
    margin-bottom: 0;
} */
.bio-info-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/website/pages/OurTeam.css"],"names":[],"mappings":"AAAA;;;GAGG;AACH;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;AAC3B","sourcesContent":["/* .header-container.bio-page {\r\n    top: 0;\r\n    margin-bottom: 0;\r\n} */\r\n.bio-info-wrap {\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    justify-content: center;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
