import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';
import useStyles from './B3BarPSILCA.styles';
import { ThemeProvider } from '@emotion/react';
import Dropdown from '../../../../../dropdowns/Dropdown';
import deepClone from '../../../../../../helper/deepClone';
import { ChartJSData, ChartJSOptions } from '../../../../../../interface/ChartData';
import { Product, PSILCAResult } from '../../../../../../interface/Product';
import PSILCAChartNavigation from '../../../../../dropdowns/BaseOptions/PSILCAChartNavigation';
import ResultsTheme from '../../../../style/ResultsTheme';
import { initialOptions } from '../../../../options/InitialOptionValues';
import ChartDownload from '../../../../options/components/ChartDownload';
import ProductComparison from '../../../../options/components/Comparison';
import GraphSetting from '../../../../options/components/GraphSettings';
import { applyValueType } from '../../../../options/util/applyOptions';
import { CreateChartTS } from '../../../../util/CreateChartTS';
import { useFormContext } from '../../../../../forms/FormContext';
import { ChartColorIdentifiers } from '../../../../options/components/GraphSettingsOptions';



interface ChartData {
    product: Product;
}

function B3BarPSILCA(props: ChartData) {
    const { formState, setFormState } = useFormContext();
    const classes = useStyles();
    const { product } = props;
    const [chartJSData, setChartJSData] = useState<ChartJSData>({ datasets: [], labels: [] });
    const [chartOptions, setChartOptions] = useState<ChartJSOptions>(initialOptions);
    const [colorIdentifiers, setColorIdentifiers] = useState<ChartColorIdentifiers[]>([]);
    const [selectedValueType, setSelectedValueType] = useState<string>('');
    const [results, setResults] = useState<PSILCAResult[]>([]);

    useMemo(() => {
        const calc = product.calculations.find(
            (item) => {return item.calculationType === 'SOCIAL-PSILCA'},
        );
        const calcResults = calc?.calculationResults as PSILCAResult[];
        setResults((calcResults));
    }, [product]);








    useEffect(() => {
        const chartData: ChartJSData = { datasets: [], labels: [] };
        const options: ChartJSOptions = deepClone<ChartJSOptions>(initialOptions);
        const chartColorIdentifiers : ChartColorIdentifiers[] = [];

        if (options.scales && options.scales.y && options.scales.y.title) {
            options.scales.y.title.text = "Medium Risk Hours";
        }
        

        const lifeCycleStages = results[0].subcategories[0].indicators[0].indicatorResults.reduce(
            (stages: string[], lcss) => {
                if (!stages.find((item) => {return item === lcss.lcsName})) {
                    stages.push(lcss.lcsName);
                }
                return stages;
            },
            [],
        );
        chartData.datasets = lifeCycleStages.map((lcs, index) => {
            chartColorIdentifiers.push({label:lcs});
            return {
                label: lcs,
                data: results.map((stakeholder) => {return stakeholder.subcategories.reduce(
                    (stakeholderTotal, subcategory) => {return stakeholderTotal
                    + subcategory.indicators.reduce(
                        (subcategoryTotal, indicator) => {
                            const result = indicator.indicatorResults.find(
                                (item) => {return item.lcsName === lcs},
                            );
                            if (result) {
                                subcategoryTotal
                                    += result.processResults.lcsTotalSum;
                            }
                            return subcategoryTotal;
                        },
                        0,
                    )},
                    0,
                )}),

            //backgroundColor: extendedPalette[index],
            // backgroundColor: currentPaletteSubLifeCycle[lcsIndex], // colors[index], //random color from array
            // borderColor: '000000',
            }});

        chartData.labels = results.map(
            (stakeholder) => {return stakeholder.stakeholder},
        );
        setChartJSData(chartData);
        setChartOptions(options);
        setColorIdentifiers(chartColorIdentifiers);    
    }, [selectedValueType]);

    const [applyGraphSettings, setApplyGraphSettings] =
        useState<(options: ChartJSOptions, chartData: ChartJSData, colorIdentifiers?: ChartColorIdentifiers[]) => 
            { options: ChartJSOptions; chartData: ChartJSData; }>();
    const triggerChartUpdate = () => {
        let options = deepClone(chartOptions) || {};
        let chartData = deepClone(chartJSData);
        const applyOptionReturn = applyValueType(selectedValueType, chartData, options,true );
        options = applyOptionReturn.options;
        chartData = applyOptionReturn.chartData;
        console.log(chartData);

        if(applyGraphSettings){
            ({ options, chartData } = applyGraphSettings(options, chartData, colorIdentifiers));
        }
        CreateChartTS(chartData, options);
    };

    useEffect(() => {
        if (chartJSData && chartOptions) {
            triggerChartUpdate();
        }
    }, [chartJSData, chartOptions]);
    return (
        <ThemeProvider theme={ResultsTheme}>
            <Box
                sx={{
                    margin: '0',
                    maxWidth: '100%',
                    maxHeight: '-webkit-fill-available',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1vw',
                }}
            >
                <Box className='temp-box' />
                <Box component='div' className='results-wrapper'>
                    <Box component='div' className='results-options'>
                        <Paper square>
                            <Box component='div' className='results-options-top'>
                                <FormControl
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: '3rem',
                                        alignItems: 'stretch',

                                    }}
                                >
                                    <PSILCAChartNavigation product={product} defaultBaseIndex={1} defaultResultIndex={2} />
                                    <ProductComparison />
                                </FormControl>
                            </Box>
                        </Paper>
                        <Divider />
                        <Paper square>
                            <Box component='div' className='results-options-bottom'>
                                <Box>
                                    <FormControl
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}
                                        className='bottom-options side-left'
                                    >
                                        <Dropdown
                                            options={['Raw Values', '% Per Lifecycle Substage']}
                                            valueSetter={setSelectedValueType}
                                            value={selectedValueType}

                                        />

                                    </FormControl>
                                </Box>
                                <Box>
                                    <FormControl
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            '@media (max-width: 1680px)': {
                                                gap: '1vw',
                                            },
                                        }}
                                        className='bottom-options side-right'
                                    >
                                        <ChartDownload chartData = {chartJSData} 
                                            chartName={'Stakeholder level result - Sublifecycle'} chartType='Bar Graph' />
                                        <GraphSetting 
                                            chartOptions={chartOptions} 
                                            setApplyGraphSettings={setApplyGraphSettings} 
                                            triggerChartUpdate={triggerChartUpdate}
                                            colorIdentifiers={colorIdentifiers}
                                            chartData = {chartJSData}
                                            chartColorType = 'lcss'
                                            graphType='barindicator'
                                        />

                                    </FormControl>
                                </Box>
                            </Box>
                        </Paper>
                    </Box>
                </Box>
                <Box className={classes.chartSec}>
                    <Paper
                        square
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <Box className={classes.chartSec}>
                            <canvas id='graph1Canvas' className='graph-canvas' />
                        </Box>
                    </Paper>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default B3BarPSILCA;
