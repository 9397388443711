// import { Login } from '@mui/icons-material';
import Signup from '../screens/auth/signup';
import { generateRoute } from './CreateRoute';
import Dashboard from '../screens/Dashboard';
import Login from '../screens/auth/login';

import Homepage from '../website/Homepage';
import AboutWhoAreWe from '../website/pages/about/WhoAreWe';
import AboutOurMission from '../website/pages/about/OurMission';
import OurTeam from '../website/pages/OurTeam';
import BioProfileGetachew from '../website/pages/bios/BioProfileGetachew';
import BioProfileIan from '../website/pages/bios/BioProfileIan';
import BioProfileMarwa from '../website/pages/bios/BioProfileMarwa';
import Services from '../website/pages/Services';
import ServicesConsultingServices from '../website/pages/services/ServicesConsultingServices';
import ServicesLCSASoftware from '../website/pages/services/ServicesTOSSASoftware';
import ServicesTraining from '../website/pages/services/ServicesTraining';
import ResourcesWebinars from '../website/pages/resources/ResourcesWebinars';
import ResourcesWhitePapersAndPublications from '../website/pages/resources/ResourcesWhitePapersAndPublications';
import ContactUs from '../website/pages/Contact';
import ResourcesFAQ from '../website/pages/resources/ResourcesFAQ';

function publicRoutes() {
    // const indexRoute = generateRoute('/', Login);
    const indexRoute = generateRoute('/', Homepage);
    const websiteAboutWhoAreWeRoute = generateRoute(
        '/about/whoarewe',
        AboutWhoAreWe
    );
    const websiteAboutOurMissionRoute = generateRoute(
        '/about/ourmission',
        AboutOurMission
    );
    const websiteOurTeamRoute = generateRoute('/ourteam', OurTeam);
    const websiteBioIanRoute = generateRoute('/bioprofile/ian', BioProfileIan);
    const websiteBioMarwaRoute = generateRoute(
        '/bioprofile/marwa',
        BioProfileMarwa
    );
    const websiteBioGetachewRoute = generateRoute(
        '/bioprofile/getachew',
        BioProfileGetachew
    );
    const websiteServicesRoute = generateRoute('/services', Services);
    const websiteServicesConsultRoute = generateRoute(
        '/services/consultingservices',
        ServicesConsultingServices
    );
    const websiteLCSASoftwareRoute = generateRoute(
        '/services/tossasoftware',
        ServicesLCSASoftware
    );
    const websiteServicesTrainingRoute = generateRoute(
        '/services/training',
        ServicesTraining
    );
    const websiteResourcesWebRoute = generateRoute(
        '/resources/resourceswhitepp',
        ResourcesWhitePapersAndPublications
    );
    const websiteWhitePapersRoute = generateRoute(
        '/resources/webinars',
        ResourcesWebinars
    );
    const websiteResourceFAQRoute = generateRoute('/resources/faq', ResourcesFAQ);
    const websiteContactRoute = generateRoute('/contact', ContactUs);

    // const indexRoute = generateRoute('/', Dashboard);
    // const appRoute = generateRoute('/app', Dashboard);

    const loginRoute = generateRoute('login', Login);
    const signUpRoute = generateRoute('signup', Signup);

    const routes = [
        indexRoute,
        // appRoute,
        websiteAboutWhoAreWeRoute,
        websiteAboutOurMissionRoute,
        websiteOurTeamRoute,
        websiteBioIanRoute,
        websiteBioMarwaRoute,
        websiteBioGetachewRoute,
        websiteServicesRoute,
        websiteServicesConsultRoute,
        websiteLCSASoftwareRoute,
        websiteServicesTrainingRoute,
        websiteResourcesWebRoute,
        websiteWhitePapersRoute,
        websiteContactRoute,
        websiteResourceFAQRoute,
        loginRoute,
        signUpRoute,
    ];
    return routes;
}

export default publicRoutes;
