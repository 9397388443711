import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    Box, Divider, FormControl, Paper, ThemeProvider,
} from '@mui/material';
import useStyles from './A3BarChart.styles';
import { Bar } from 'react-chartjs-2';
import { EnvResultSubstage, EnvironmentalResult, Product } from '../../../../../../interface/Product';
// import Loader from '../../components/loader';
import toast from 'react-hot-toast';
import { Accumulator } from '../../../../../../interface/Accumulator';
import ImpactCategoryDropdown from '../../../../../dropdowns/ImpactCategoryDropdown';
import ChartOptions from '../../../../options/ChartOptions';
import { Link } from '@tanstack/react-router';
import { initialOptions } from '../../../../options/InitialOptionValues';
import deepClone from '../../../../../../helper/deepClone';
import { ChartDataSet, ChartJSData, ChartJSOptions } from '../../../../../../interface/ChartData';
import { applyValueType } from '../../../../options/util/applyOptions';
import { CreateChartTS } from '../../../../util/CreateChartTS';
import Dropdown from '../../../../../dropdowns/Dropdown';
import ChartDownload from '../../../../options/components/ChartDownload';
import GraphSetting from '../../../../options/components/GraphSettings';
import ResultsTheme from '../../../../style/ResultsTheme';
import EnvChartNavigation from '../../../../../dropdowns/BaseOptions/EnvChartNavigation';
import { useFormContext } from '../../../../../forms/FormContext';
import { ChartColorIdentifiers } from '../../../../options/components/GraphSettingsOptions';



interface ChartData {
    product: Product
}

function A3BarChart(props: ChartData) {
    const classes = useStyles();
    const { formState, setFormState } = useFormContext();
    const { product } = props;
    const [chartJSData, setChartJSData] = useState<ChartJSData>({ datasets: [], labels: [] });
    const [chartOptions, setChartOptions] = useState<ChartJSOptions>(initialOptions);
    const [colorIdentifiers, setColorIdentifiers] = useState<ChartColorIdentifiers[]>([]);
    const [results, setResults] = useState<EnvironmentalResult[]>([]);
    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
    const [selectedValueType, setSelectedValueType] = useState<string>('');

    useMemo(() => {
        const calc = product.calculations.find(
            (item) => { return item.calculationType === 'ENVIRONMENTAL' },
        );
        const calcResults = calc?.calculationResults as EnvironmentalResult[];
        setResults((calcResults));
    }, [product]);








    useEffect(() => {
        const chartData: ChartJSData = { datasets: [], labels: [] };
        const options: ChartJSOptions = deepClone<ChartJSOptions>(initialOptions);
        const chartColorIdentifiers : ChartColorIdentifiers[] = [];
        let unit: string;
        const colorIndex = 0;

        const getData = (lcs: EnvironmentalResult, lcss: EnvResultSubstage, category: string) => {
            const matchingStage = results.find((item) => { return item.name === lcs.name });
            if (matchingStage) {
                const matchingSubstage = matchingStage.subStages.find(
                    (item) => { return item.name === lcss.name },
                );
                if (matchingSubstage) {
                    const selectedCategory = selectedCategories[0];
                    // categoryDropdownType === "Radio"
                    //     ? formState.inputs.selectedCategory.value
                    //     :
                    //    selectedCategories[0];
                    let matchingCategory;
                    if (category) {
                        matchingCategory = matchingSubstage.results.find(
                            (item) => { return item.impactCategory.name === category },
                        );
                    } else {
                        matchingCategory = matchingSubstage.results.find(
                            (item) => { return item.impactCategory.name === selectedCategory },
                        );
                    }

                    if (matchingCategory) {
                        if (!unit) {
                            unit = matchingCategory.impactCategory.refUnit;
                            if (options.scales?.y?.title) {
                                options.scales.y.title.text = unit;

                            }
                        }
                        return matchingCategory.value;
                    }
                    return 0;
                }
                return 0;
            }
            return null;
        };
        // let colorIndex = 0;
        chartData.datasets = results.map((lcs) => {
            chartColorIdentifiers.push({label:lcs.name});
            return {
                label: lcs.name,
                data:
                    // categoryDropdownType === 'Radio'
                    //     ? [
                    //         lcs.subStages.reduce((total, lcss) => total + getData(lcs, lcss), 0),
                    //     ]
                    //     :
                    selectedCategories.map((category) => {
                        return lcs.subStages.reduce((total, lcss) => {
                            const categoryValue = getData(lcs, lcss, category);
                            if (categoryValue) {
                                total += categoryValue;
                            }
                            return total;
                        }, 0)
                    }),
                //backgroundColor: extendedPalette[colorIndex++],
                // backgroundColor: lifeCycleColors[colorIndex++], //colors[index],
                // backgroundColor: currentPalette[colorIndex++], // colors[index],
                // borderColor: '000000',
            }
        });
        chartData.labels = selectedCategories;
        setChartJSData(chartData);
        setChartOptions(options);
        setColorIdentifiers(chartColorIdentifiers);    
    }, [selectedValueType, selectedCategories]);

    const [applyGraphSettings, setApplyGraphSettings] =
        useState<(options: ChartJSOptions, chartData: ChartJSData, colorIdentifiers?: ChartColorIdentifiers[]) => 
            { options: ChartJSOptions; chartData: ChartJSData; }>();
    const triggerChartUpdate = () => {
        let options = deepClone(chartOptions) || {};
        let chartData = deepClone(chartJSData);
        const applyOptionReturn = applyValueType(selectedValueType, chartData, options);
        options = applyOptionReturn.options;
        chartData = applyOptionReturn.chartData;
        if (applyGraphSettings) {
            ({ options, chartData } = applyGraphSettings(options, chartData, colorIdentifiers));
        }
        CreateChartTS(chartData, options);
    };

    useEffect(() => {
        if (chartJSData && chartOptions) {
            triggerChartUpdate();
        }
    }, [chartJSData, chartOptions]);
    return (
        <ThemeProvider theme={ResultsTheme}>
            <Box
                sx={{
                    margin: '0',
                    maxWidth: '100%',
                    maxHeight: '-webkit-fill-available',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1vw',
                }}
            >

                <Box component='div' className='results-wrapper'>
                    <Box component='div' className='results-options'>
                        <Paper square>
                            <Box component='div' className='results-options-top'>
                                <FormControl
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                    }}
                                >
                                    <EnvChartNavigation
                                        product={product}
                                        defaultBaseIndex={0}
                                        defaultResultIndex={2}
                                    />
                                </FormControl>
                            </Box>
                        </Paper>
                        <Divider />
                        <Paper square>
                            <Box component='div' className='results-options-bottom'>
                                <Box>
                                    <FormControl
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}
                                        className='bottom-options side-left'
                                    >
                                        <Dropdown
                                            options={['Raw Values', '% Per Lifecycle Substage']}
                                            valueSetter={setSelectedValueType}
                                            value={selectedValueType}
                                        />
                                    </FormControl>
                                </Box>
                                <Box>
                                    <FormControl
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            '@media (max-width: 1680px)': {
                                                gap: '1vw',
                                            },
                                        }}
                                        className='bottom-options side-right'
                                    >
                                        <ChartDownload chartData={chartJSData} 
                                            chartName={'Total weighted result: Single index - Sublifecycle'} chartType='Bar Graph' />
                                        <GraphSetting
                                            chartOptions={chartOptions}
                                            setApplyGraphSettings={setApplyGraphSettings}
                                            triggerChartUpdate={triggerChartUpdate}
                                            colorIdentifiers={colorIdentifiers}
                                            chartData={chartJSData}
                                            chartColorType='lcss'
                                            graphType='barindicator'  
                                        />

                                    </FormControl>
                                </Box>
                            </Box>
                        </Paper>
                    </Box>
                </Box>
                <Box className={classes.chartSec}>
                    <Paper
                        square
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <Box className={classes.chartSec}>
                            <canvas id='graph1Canvas' className='graph-canvas' />
                        </Box>
                    </Paper>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default A3BarChart;
