import React from "react";
import "../Homepage.css";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const ResourcesFAQ: React.FC = () => {
    return (
        <div className="website-only">
            <Header />

            <div className="header-container">
                <div className="header-transparent-layer"> </div>
                <img
                    src={require("../../images/headers/pexels-maria-isabella-bernotti-1076130.jpg")}
                    alt="Forest and a lake in front of a white sky"
                />

                <h1>FAQ</h1>
            </div>

            <div className="box-container faq-box">
                <div className="faq-container">
                    <h3>
            How is the TOSSA software tool different from other sustainability
            assessment software tools existing in the market?
                    </h3>
                    <p>
            TOSSA software tool differentiates from other sustainability
            assessment software tools in market by:
                    </p>
                    <ul>
                        <li>
              Being the first-of-its-kind digital tool that integrates automated
              ESG assessment using lifecycle perspective with integrated
              decision-making options.
                        </li>
                        <li>
                            {" "}
              Providing a computer-based tool with ease-of-use to support the
              decision-making process, quantifying and qualifying sustainability
              for all sectors including the energy, building, agriculture,
              manufacturing, transport and associated supply-chain industries.
                        </li>
                        <li>
              Offering a complete solution that provides economic and social
              performance measures in addition to the environmental assessment,
              thus supporting optimal decision-making toward sustainable
              economic, environmental and social prosperity.
                        </li>
                        <li>
              Enabling companies, or other organizations to focus their
              resources on evolving ESG strategies to achieve environmental and
              sustainability benefits: improved productivity, reduced emissions,
              and greater social acceptance.
                        </li>
                    </ul>
                </div>

                <div className="faq-container">
                    <h3>What can TOSSA software tool do for your company?</h3>
                    <ul>
                        <li>
              Assess and report on your ESG performance based on consistent life
              cycle standards
                        </li>
                        <li>
              Connect your sustainability performance to Sustainable Development
              Goals (SDGs)
                        </li>
                        <li>
              Compare your sustainability performance with national - industry
              and science-based thresholds
                        </li>
                        <li>
              Compare different alternatives and/or identify sustainability
              hotspots in your products/technologies/operations
                        </li>
                    </ul>
                </div>

                <div className="faq-container">
                    <h3>Why do you choose TOSSA software tool?</h3>
                    <p>
            With the increased pressure on companies from different stakeholder
            groups including governments and shareholders, assessing, reporting
            and improving ESG and sustainability performance is becoming a
            requirement. TOSSA is the only organized and scientifically sound
            intuitive computer-based sustainability and ESG+ tool to evaluate
            whole sustainability performance of products/services/corporate
            operations based on standardized life cycle assessment methodology.{" "}
                    </p>
                </div>

                <div className="faq-container">
                    <h3>How does the TOSSA software tool work?</h3>
                    <p>
            We designed TOSSA software tool with a simple, easy-to-use and
            powerful graphical user interface where users are guided in the
            process of assessing the sustainability and/or ESG performance of
            their products/technologies/operations step-by-step. The process
            starts with inputting the product details including name,
            description, life cycle stages, sustainability dimensions etc.,
            followed by inputting data for the different dimensions where users
            have the option of selecting data from the generic high-quality
            databases directly connected to TOSSA. Results are then displayed
            automatically in different formats and graph types allowing users to
            zoom in from the most aggregated to the detailed results.
                    </p>
                </div>

                <div className="faq-container">
                    <h3>
            How do you benefit from the TOSSA software tool if you don’t have
            the expertise to use it?
                    </h3>
                    <p>
            You don’t need to own the tool to benefit from the TOSSA software
            tool. TOSSA Sustainability Services offers customized assessment for
            customers through consultancy services using TOSSA software tool.
            TOSSA team provides LCA, ESG and sustainability-consulting. We
            create value through sustainability and life cycle thinking. We
            empower organizations to make them impactful, with scientific-based
            decisions that shift their organizational and sustainability
            performance.{" "}
                    </p>
                </div>

                <div className="faq-container">
                    <h3>What other services does TOSSA sustainability services offer?</h3>
                    <p>
            We offer a variety of services in addition to selling different
            versions of software licenses that fit each user’s needs, including
            consultancy services, training sessions and courses on life cycle
            sustainability assessment, social life cycle assessment and life
            cycle assessment. On demand custom courses and training are also
            available.
                    </p>
                </div>

                <div className="faq-container">
                    <h3>Why TOSSA Sustainability Services?</h3>
                    <p>
            When you work with TOSSA Sustainability Services, you will work with
            a team of experienced scientists and expert leaders in the fields of
            sustainability assessment, ESG, life cycle assessment, social life
            cycle assessment. The team has a proven track record of success with
            scientific achievements and publications and in development of
            innovative methods in the LCA field. This combination of many years
            of industry and academic experience is definitely what makes our
            team and company unique!{" "}
                    </p>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default ResourcesFAQ;
