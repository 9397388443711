// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div#graph-setting-popper {
  position: fixed;
  top: 0px;
  width: auto;
}
.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-sizeSmall.css-1m4yb6h-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  width: 180px;
}`, "",{"version":3,"sources":["webpack://./src/components/charts/options/components/GraphSettings/GraphSettings.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,QAAQ;EACR,WAAW;AACb;AACA;EACE,YAAY;AACd","sourcesContent":["div#graph-setting-popper {\r\n  position: fixed;\r\n  top: 0px;\r\n  width: auto;\r\n}\r\n.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-sizeSmall.css-1m4yb6h-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {\r\n  width: 180px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
