import React, { useEffect, useMemo, useState, useCallback } from 'react';
import {
    Box, Divider, FormControl, Paper, ThemeProvider,
} from '@mui/material';
import useStyles from './A4BarChart.styles';
import { Bar } from 'react-chartjs-2';
import { EnvResultSubstage, EnvironmentalResult, Product } from '../../../../../../interface/Product';
// import Loader from '../../components/loader';
import toast from 'react-hot-toast';
import { Accumulator } from '../../../../../../interface/Accumulator';
import ImpactCategoryDropdown from '../../../../../dropdowns/ImpactCategoryDropdown';
import ChartOptions from '../../../../options/ChartOptions';
import { Link } from '@tanstack/react-router';
import { initialOptions } from '../../../../options/InitialOptionValues';
import deepClone from '../../../../../../helper/deepClone';
import { ChartDataSet, ChartJSData, ChartJSOptions } from '../../../../../../interface/ChartData';
import { applyValueType } from '../../../../options/util/applyOptions';
import { CreateChartTS } from '../../../../util/CreateChartTS';
import Dropdown from '../../../../../dropdowns/Dropdown';
import LCSDropdowns from '../../../../../dropdowns/LCSDropdowns';
import GraphDownload from '../../../../options/components/ChartDownload';
import GraphSetting from '../../../../options/components/GraphSettings';
import ResultsTheme from '../../../../style/ResultsTheme';
import EnvChartNavigation from '../../../../../dropdowns/BaseOptions/EnvChartNavigation';
import { palette1, palette2, palette3, palette4, processColors } from '../../../../../../helper/colors';
import { useFormContext } from '../../../../../forms/FormContext';

interface ColorIndexData {
    label: string;
    identifier?: string;
}

interface ChartData {
    product: Product;
}

export interface ColorIndexData {
    label: string;
    identifier?: string;
}

function A4BarChart(props: ChartData) {
    const { formState, setFormState } = useFormContext();
    const classes = useStyles();
    const { product } = props;
    const [chartJSData, setChartJSData] = useState<ChartJSData>({ datasets: [], labels: [] });
    const [chartOptions, setChartOptions] = useState<ChartJSOptions>();
    const [results, setResults] = useState<EnvironmentalResult[]>([]);
    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
    const [selectedValueType, setSelectedValueType] = useState<string>('');
    const [selectedLCS, setSelectedLCS] = useState<string>('');
    const [selectedLCSS, setSelectedLCSS] = useState<string>('');
    const [colorPalette, setColorPalette] = useState<string[]>(processColors);
    const [colorLocked, setColorLocked] = useState<boolean>(true);

    useMemo(() => {
        const calc = product.calculations.find(
            (item) => {return item.calculationType === 'ENVIRONMENTAL'},
        );
        const calcResults = calc?.calculationResults as EnvironmentalResult[];
        setResults(calcResults);
    }, [product]);

    const findColorPaletteByName = (name: string): string[] => {
        const palettes = {
            "Process Colors": processColors,
            "Sunset": palette1,
            "Multicolored": palette2,
            "Warm": palette3,
            "Cold": palette4,
        } as Record<string, string[]>;
        return palettes[name] || palettes["Process Colors"];
    };

    const generateExtendedPalette = (baseColors: string[], totalColors: number): string[] => {
        const extendedPalette = [...baseColors];
        while (extendedPalette.length < totalColors) {
            baseColors.forEach((color) => {
                // Adjust brightness or saturation to generate new colors
                const newColor = adjustColor(color, extendedPalette.length / totalColors);
                extendedPalette.push(newColor);
                if (extendedPalette.length >= totalColors) return;
            });
        }
        return extendedPalette.slice(0, totalColors);
    };

    const adjustColor = (color: string, factor: number): string => {
     
        const f = parseInt(color.slice(1), 16),
            t = factor < 0 ? 0 : 255,
            p = factor < 0 ? factor * -1 : factor,
            R = f >> 16,
            G = f >> 8 & 0x00FF,
            B = f & 0x0000FF;
        return "#" + (0x1000000 + (Math.round((t - R) * p) + R) * 0x10000 + (Math.round((t - G) * p) + G) * 0x100 + (Math.round((t - B) * p) + B)).toString(16).slice(1);
    };

    const getGraphColorIndex = useCallback(({ label, identifier }: ColorIndexData, palette: string[]) => {
        const hash = `${label}:${identifier}`.split('').reduce((hash, char) => {
            return char.charCodeAt(0) + ((hash << 5) - hash);
        }, 0);
        return Math.abs(hash) % palette.length;
    }, []);

    useEffect(() => {
        const chartData: ChartJSData = { datasets: [], labels: [] };
        const options: ChartJSOptions = deepClone<ChartJSOptions>(initialOptions);
        let unit: string;

        const lifeCycle = results.find(
            (item) => {return item.name === selectedLCS},
        );

        if (lifeCycle) {
            const subStage = lifeCycle.subStages.find(
                (item) => {return item.name === selectedLCSS},
            );

            if (subStage) {
                const selectedPalette = findColorPaletteByName(formState.colorPalette);
                //const totalProcesses = subStage.results[0].values.length;
                const extendedPalette = generateExtendedPalette(selectedPalette, results.length);

                const data = subStage.results[0].values
                    .reduce((values: { name: string, id: string }[], value) => {
                        const matchingValue = values.find(
                            (item) => {return item.name === value.processName},
                        );
                        if (!matchingValue) {
                            values.push({
                                name: value.processName,
                                id: value.processId,
                                endProductName: value.endProductName, // Ensure endProductName is assigned correctly
                            });
                        }
                        return values;
                    }, []);

                chartData.datasets = data.map((process, index) => {
                    const colorIndex = getGraphColorIndex({
                        label: process.endProductName,
                    }, extendedPalette);
            
                    const dataSet: ChartDataSet = {
                        key: `${process.name}_${index}`,
                        label: process.endProductName, // Use endProductName here
                        data: selectedCategories.map((category) => {
                            const matchingCategory = subStage.results.find((item) => item.impactCategory.name === category);
                
                            if (matchingCategory) {
                                const matchingProcess = matchingCategory.values.find((value) => value.processId === process.id);
                
                                if (matchingProcess) {
                                    let valueToDisplay = matchingProcess.value;
                
                                    if (selectedValueType === 'Relative Contribution') {
                                        const maxValue = matchingCategory.values.reduce((max, item) => {
                                            return item.value > max ? item.value : max;
                                        }, 0);
                                        valueToDisplay = (matchingProcess.value / maxValue) * 100;
                                    } else if (selectedValueType === '% Total') {
                                        const total = matchingCategory.values.reduce((acc, item) => acc + item.value, 0);
                                        valueToDisplay = (matchingProcess.value / total) * 100;
                                    }
                
                                    unit = matchingCategory.impactCategory.refUnit;
                                    return valueToDisplay;
                                }
                            }
                            return 0;
                        }),
                        backgroundColor: extendedPalette[colorIndex],
                    };

                    return dataSet;
                });

                console.log(chartData.datasets);
                
            }
        }

        chartData.labels = selectedCategories;
        setChartJSData(chartData);
        setChartOptions(options);
    }, [selectedCategories, selectedLCS, selectedLCSS, selectedValueType, getGraphColorIndex, results, formState.colorPalette]);

    const [applyGraphSettings, setApplyGraphSettings] =
    useState<(options: ChartJSOptions, chartData: ChartJSData) =>
         { options: ChartJSOptions; chartData: ChartJSData; }>();    
    const triggerChartUpdate = () => {
        let options = deepClone(chartOptions) || {};
        let chartData = deepClone(chartJSData);
        const applyOptionReturn = applyValueType(selectedValueType, chartData, options);
        options = applyOptionReturn.options;
        chartData = applyOptionReturn.chartData;
        if (applyGraphSettings) {
            ({ options, chartData } = applyGraphSettings(options, chartData));
        }
        CreateChartTS(chartData, options);
    };

    useEffect(() => {
        if (chartJSData && chartOptions) {
            triggerChartUpdate();
        }
    }, [chartJSData, chartOptions]);

    return (
        <ThemeProvider theme={ResultsTheme}>
            <Box
                sx={{
                    margin: '0',
                    maxWidth: '100%',
                    maxHeight: '-webkit-fill-available',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1vw',
                }}
            >

                <Box component='div' className='results-wrapper'>
                    <Box component='div' className='results-options'>
                        <Paper square>
                            <Box component='div' className='results-options-top'>
                                <FormControl
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: '3rem',
                                        alignItems: 'stretch',
                                    }}
                                >
                                    <EnvChartNavigation
                                        product={product}
                                        defaultBaseIndex={0}
                                        defaultResultIndex={3}
                                    />


                                </FormControl>
                            </Box>
                        </Paper>
                        <Divider />
                        <Paper square>
                            <Box component='div' className='results-options-bottom'>
                                <Box>
                                    <FormControl
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}
                                        className='bottom-options side-left'
                                    >
                                        <Dropdown
                                            options={['Raw Values', 'Relative Contribution', '% Total']}
                                            valueSetter={setSelectedValueType}
                                            value={selectedValueType}
                                        />
                                        <ImpactCategoryDropdown
                                            product={product}
                                            selectedCategories={selectedCategories}
                                            setSelectedCategories={setSelectedCategories}
                                        />
                                        <LCSDropdowns
                                            product={product}
                                            selectedLCS={selectedLCS}
                                            setSelectedLCS={setSelectedLCS}
                                            selectedLCSS={selectedLCSS}
                                            setSelectedLCSS={setSelectedLCSS}
                                        />
                                    </FormControl>
                                </Box>
                                <Box>
                                    <FormControl
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            '@media (max-width: 1680px)': {
                                                gap: '1vw',
                                            },
                                        }}
                                        className='bottom-options side-right'
                                    >
                                        <GraphDownload />
                                        <GraphSetting
                                            chartOptions={chartOptions}
                                            setApplyGraphSettings={setApplyGraphSettings}
                                            triggerChartUpdate={triggerChartUpdate}
                                            chartData = {chartJSData}
                                            chartColorType = 'process'

                                            noColors
                                        />

                                    </FormControl>
                                </Box>
                            </Box>
                        </Paper>
                    </Box>
                </Box>
                <Box className={classes.chartSec}>
                    <Paper
                        square
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <Box className={classes.chartSec}>
                            <canvas id='graph1Canvas' className='graph-canvas' />
                        </Box>
                    </Paper>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default A4BarChart;
