import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import useStyles from './B3BarSPA.styles';
import deepClone from '../../../../../../helper/deepClone';
import { ChartDataSet, ChartJSData, ChartJSOptions } from '../../../../../../interface/ChartData';
import { Product, PSILCAResult, SPAResult } from '../../../../../../interface/Product';
import PSILCAChartNavigation from '../../../../../dropdowns/BaseOptions/PSILCAChartNavigation';
import ResultsTheme from '../../../../style/ResultsTheme';
import { initialOptions } from '../../../../options/InitialOptionValues';
import ChartDownload from '../../../../options/components/ChartDownload';
import ProductComparison from '../../../../options/components/Comparison';
import GraphSetting from '../../../../options/components/GraphSettings';
import { CreateChartTS } from '../../../../util/CreateChartTS';
import { useFormContext } from '../../../../../forms/FormContext';
import { WeighingMethod, WeighingSet, applyWeight, getWeighingSetValue, weighingMethods } from '../../../../../../helper/weighingMethod';
import Dropdown from '../../../../../dropdowns/Dropdown';
import SPAChartNavigation from '../../../../../dropdowns/BaseOptions/SPAChartNavigation';
import { applyValueType } from '../../../../options/util/applyOptions';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ChartColorIdentifiers } from '../../../../options/components/GraphSettingsOptions';



interface ChartData {
    product: Product;
}

function B3BarSPA(props: ChartData) {
    const { formState, setFormState } = useFormContext();
    const classes = useStyles();
    const { product } = props;
    const [chartJSData, setChartJSData] = useState<ChartJSData>({ datasets: [], labels: [] });
    const [chartOptions, setChartOptions] = useState<ChartJSOptions>(initialOptions);
    const [colorIdentifiers, setColorIdentifiers] = useState<ChartColorIdentifiers[]>([]);    
    const [currentWeighingSet, setCurrentWeighingSet] = useState<WeighingSet>();
    const [selectedValueType, setSelectedValueType] = useState('');

    const [results, setResults] = useState<SPAResult>();

    useMemo(() => {
        const calc = product.calculations.find(
            (item) => { return item.calculationType === 'SOCIAL-SPA' },
        );
        const calcResults = calc?.calculationResults as unknown as SPAResult;
        console.log(calc);
        const weighingMethod = weighingMethods.find((method) => method.name === product.socialData.weighingMethod.name);
        const weighingSet = weighingMethod?.sets.find((set) => set.name === product.socialData.weighingMethod.set);
        setCurrentWeighingSet(weighingSet);
        setResults((calcResults));
    }, [product]);








    useEffect(() => {

        if (!results || !currentWeighingSet)
            return;

        const chartData: ChartJSData = { datasets: [], labels: [] };
        const options: ChartJSOptions = deepClone<ChartJSOptions>(initialOptions);
        const chartColorIdentifiers : ChartColorIdentifiers[] = [];
        const spaLifeCycle = results.lifeCycleResults[0];

        if (options.scales && options.scales.y && options.scales.y.title) {
            options.scales.y.title.text = "Weighted Score";
        }
        
        const lifeCycleStages : string[] = [];
        if (results.SPAProcess) {
            lifeCycleStages.push(
                results.SPAProcess.lifeCycleStage.split(":")[0]
            );
            chartData.labels = results.stakeholderResults.map((item) =>
                item.name
                    .split(":")[0]
                    .split(" ")
                    .map((s) => {
                        const s1 = s.split("");
                        s1[0] = s1[0].toUpperCase();
                        return s1.join("");
                    })
                    .join(" ")
            );
        }

        results.lifeCycleResults.forEach((lifeCycle) => {
            const lcs = lifeCycleStages.find(
                (stage) => stage === lifeCycle.lcsName.split(":")[0]
            );
            if (!lcs) {
                lifeCycleStages.push(lifeCycle.lcsName.split(":")[0]);
            }
        });
        chartData.labels = chartData.labels
            .concat(
                spaLifeCycle.processResults[0].PSILCAPriorities.map(
                    (item) => item.category.split(":")[0]
                )
            )
            .filter((value, index, self) => self.indexOf(value) === index);
        
    
        
        //get SPA calculations per stakeholder
        chartData.datasets = results.lifeCycleResults.reduce(
            (sets : ChartDataSet[], lcs, index) => {
                chartColorIdentifiers.push({label: lcs.lcsName});

                sets.push({
                    label: lcs.lcsName,
                    data: lcs
                        ? chartData.labels.map((stakeholder) => {
                            return lcs.processResults
                                .map((process) => {
                                    return process.PSILCAPriorities.filter(
                                        (priority) =>
                                            priority.category.split(":")[0] ===
                          stakeholder
                                    ).reduce((val1, val2) => {
                                        return val1 + applyWeight(val2,'psilca', currentWeighingSet);
                                    }, 0);
                                })
                                .reduce((val1, val2) => {
                                    return val1 + val2;
                                }, 0);
                        })
                        : [],
                    // backgroundColor: selectedPalette[index], //"#1B5D82", //colorsrgb,

                    //backgroundColor: colors[index], //random color from array
                    borderColor: "000000",
                });
                if (results.SPAProcess) {
                    const SPAlcs = results.SPAProcess.lifeCycleStage.split(":")[0];
                    if (lcs.lcsName.split(":")[0] === SPAlcs.split(":")[0]) {
                        const set = chartData.datasets.find(
                            (item) => item.label === SPAlcs
                        );
                        if (!set) {
                            chartColorIdentifiers.push({label: results.SPAProcess.lifeCycleStage});

                            sets.push({
                                label: results.SPAProcess.lifeCycleStage,
                                data: results.stakeholderResults.map((stakeholder) => {
                                    return stakeholder.SPAPriorities.reduce(
                                        (stakeholderTotal, category) => {
                                            return (
                                                stakeholderTotal +
                            applyWeight(category, "sam",currentWeighingSet)
                                            );
                                        },
                                        0
                                    );
                                }),
                                // backgroundColor: selectedPalette[index], //"#1B5D82", //colorsrgb,
                                borderColor: "000000",
                            });
                        }
                    }
                }
                return sets;
            },
            []
        );
        

        console.log(chartData);
        setChartJSData(chartData);
        setChartOptions(options);
        setColorIdentifiers(chartColorIdentifiers);

    }, [results, product,  currentWeighingSet, selectedValueType]);
    const [applyGraphSettings, setApplyGraphSettings] =
        useState<(options: ChartJSOptions, chartData: ChartJSData, colorIdentifiers?: ChartColorIdentifiers[]) => 
            { options: ChartJSOptions; chartData: ChartJSData; }>();
    const triggerChartUpdate = () => {
        if (!chartJSData.datasets || !chartOptions) {
            return; // Exit early if datasets or options are not properly initialized
        }

        let options = deepClone(chartOptions);
        let chartData = deepClone(chartJSData);
        const applyOptionReturn = applyValueType(selectedValueType, chartData, options, true);
        options = applyOptionReturn.options;
        chartData = applyOptionReturn.chartData;
        if (applyGraphSettings) {
            ({ options, chartData } = applyGraphSettings(options, chartData, colorIdentifiers));

        }

        CreateChartTS(chartData, options);
    };
    useEffect(() => {
        if (chartJSData && chartOptions) {
            triggerChartUpdate();
        }
    }, [chartJSData, chartOptions]);

    return (
        <ThemeProvider theme={ResultsTheme}>
            <Box
                sx={{
                    margin: '0',
                    maxWidth: '100%',
                    maxHeight: '-webkit-fill-available',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1vw',
                }}
            >
                <Box className='temp-box' />
                <Box component='div' className='results-wrapper'>
                    <Box component='div' className='results-options'>
                        <Paper square>
                            <Box component='div' className='results-options-top'>
                                <FormControl
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: '3rem',
                                        alignItems: 'stretch',

                                    }}
                                >
                                    <SPAChartNavigation product={product} defaultBaseIndex={1} defaultResultIndex={2} />
                                    <ProductComparison />
                                </FormControl>
                            </Box>
                        </Paper>
                        <Divider />
                        <Paper square>
                            <Box component='div' className='results-options-bottom'>
                                <Box>
                                    <FormControl
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}
                                        className='bottom-options side-left'
                                    >
                                        <Dropdown
                                            options={['Raw Values', '% Per Lifecycle Substage']}
                                            valueSetter={setSelectedValueType}
                                            value={selectedValueType}
                                            label='Value Type'
                                        />
                                    </FormControl>
                                </Box>
                                <Box>
                                    <FormControl
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            '@media (max-width: 1680px)': {
                                                gap: '1vw',
                                            },
                                        }}
                                        className='bottom-options side-right'
                                    >

                                        <ChartDownload chartData={chartJSData}
                                            
                                            chartName={'Total weighted result: all stakeholders - Product System'}
                                            chartType='Bar Graph' />
                                        <GraphSetting
                                            chartOptions={chartOptions}
                                            setApplyGraphSettings={setApplyGraphSettings}
                                            triggerChartUpdate={triggerChartUpdate}
                                            colorIdentifiers={colorIdentifiers}
                                            chartData={chartJSData}
                                            chartColorType='lcss'
                                            graphType='barindicator'

                                        />

                                    </FormControl>
                                </Box>
                            </Box>
                        </Paper>
                    </Box>
                </Box>
                <Box className={classes.chartSec}>
                    <Paper
                        square
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <Box className={classes.chartSec}>
                            <canvas id='graph1Canvas' className='graph-canvas' />
                        </Box>
                    </Paper>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default B3BarSPA;
