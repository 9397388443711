import { makeStyles } from '@mui/styles';

const boxWrapperStyle = makeStyles((theme) => {return {
    BoxWrapper: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '-1rem',
        marginLeft: '1rem',
    },
}});

export default boxWrapperStyle;
