import React, { useEffect, useMemo, useState } from 'react';
import {
    Box, Divider, FormControl, Paper, ThemeProvider,
} from '@mui/material';
import useStyles from './C1EnvSDGTable.styles';
import { EnvironmentalResult, Product } from '../../../../../interface/Product';
// import Loader from '../../components/loader';
import { Accumulator } from '../../../../../interface/Accumulator';
import ImpactCategoryDropdown from '../../../../dropdowns/ImpactCategoryDropdown';

import { initialOptions } from '../../../options/InitialOptionValues';
import deepClone from '../../../../../helper/deepClone';
import { ChartJSData, ChartJSOptions } from '../../../../../interface/ChartData';
import { CreateChartTS } from '../../../util/CreateChartTS';
import EnvChartNavigation from '../../../../dropdowns/BaseOptions/EnvChartNavigation';
import ChartDownload from '../../../options/components/ChartDownload';
import ProductComparison from '../../../options/components/Comparison';
import GraphSetting from '../../../options/components/GraphSettings';
import SDGLinkage from '../../../options/components/SDGLinkage';
import ResultsTheme from '../../../style/ResultsTheme';
import SDGChecklist from '../../../components/SDGChecklist';
import { SDG, SDGCorrelation } from '../../../../../interface/SDG';
import { fetchEnvSDGs, fetchUnitGroups } from '../../../../../helper/fetchUtil';
import LCSDropdowns from '../../../../dropdowns/LCSDropdowns';
import SDGTable from '../../../sdgtable';
import { UnitGroup } from '../../../../../interface/Unit';
import { sdgColor, palette1, palette2, palette3, palette4 } from '../../../../../helper/colors';
import { useFormContext } from '../../../../forms/FormContext';
import { ColorIndexData } from '../C4BarChart';
import { useQuery } from '@tanstack/react-query';
import { ChartColorIdentifiers } from '../../../options/components/GraphSettingsOptions';


interface ChartData {
    productId?: string,
    product: Product,
}

interface SDGCategory {
    name: string,
    val: number,
    unit: string,
    correlation: string,
}


function C1EnvSDGTable(props: ChartData) {
    const { productId, product } = props;
    // const product = useQuery<Product>(
    //     {
    //         queryKey:['product', productId],
    //         queryFn:() =>fetchProduct(productId),
    //     },
    // ).data as Product;
    const classes = useStyles();
    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
    const [chartJSData, setChartJSData] = useState<ChartJSData>({ datasets: [], labels: [] });
    const [chartOptions, setChartOptions] = useState<ChartJSOptions>(initialOptions);
    const [selectedValueType, setSelectedValueType] = useState<string>('');
    const [results, setResults] = useState<EnvironmentalResult[]>([]);
    const [loadedSDGs, setLoadedSDGs] = useState<SDG[]>([]);
    const [selectedProcess, setSelectedProcess] = useState<string>('');
    const [selectedLCS, setSelectedLCS] = useState<string>('');
    const [selectedLCSS, setSelectedLCSS] = useState<string>('');
    const [currentSDGs, setCurrentSDGs] = useState<SDGResult[]>([]);
    const { formState, setFormState } = useFormContext();

    const findColorPaletteByName = (name: string): string[] => {
        const palettes = {
            "SDG Colors": sdgColor,
            "Pastel": palette1,
            "Dark": palette2,
            "Warm": palette3,
            "Cold": palette4,
        } as Record<string, string[]>;
        return palettes[name] || palettes["SDG Colors"];
    };

    const { data: loadedUnitGroups = [] } = useQuery({
        queryKey: ['unitGroups'],
        queryFn: (() => {

            return fetchUnitGroups();
        }),
        staleTime: Infinity,


    })
    const [selectedSDGs, setSelectedSDGs] = useState<string[]>([]);
    useMemo(() => {
        const calc = product.calculations.find(
            (item) => { return item.calculationType === 'ENVIRONMENTAL' },
        );
        const calcResults = calc?.calculationResults as EnvironmentalResult[];
        setResults((calcResults));

        try {
            fetchEnvSDGs().then((sdgs) => setLoadedSDGs(sdgs)).catch(() => { });
        } catch (err) {
            console.log(err);
        }
    }, [product]);

    interface SDGCategory {
        name: string,
        val: number,
        unit: string,
        correlation: SDGCorrelation,

    }
    interface SDGResult {

        name: string;
        categories: SDGCategory[];

    }

    const getUnitNameFromRefID = (refId?: string): string => {

        let unitName = '';

        if (loadedUnitGroups.length > 0 && refId) {
            loadedUnitGroups.forEach((unitGroup) => {
                unitGroup.units.forEach((unit) => {
                    if (refId.includes(unit.id)) {
                        unitName = unit.name;
                        return;
                    }
                })
                if (unitName) {
                    return;
                }
            })

        }
        return unitName;
    }
    useEffect(() => {

        if (loadedUnitGroups.length === 0 || loadedSDGs.length === 0)
            return;

        let sdgs = selectedSDGs.map((item) => {
            return {
                name: item, categories: [] as SDGCategory[],
            };
        });

        

        sdgs = sdgs.map((sdg) => {
            const matchingSDG = loadedSDGs.find(
                (item) => item.name === sdg.name
            );
            if (matchingSDG) {
                sdg.categories = matchingSDG.categories.reduce(
                    (items: SDGCategory[], item) => {



                        let sdgTotal = 0;
                        let unit = '';
                        results.forEach((lcs) => {
                            return lcs.subStages.forEach((subStage) => {
                                const matchingCategory = subStage.normalizedResults.find((result) =>
                                    result.impactCategory.name
                                        .toLowerCase()
                                        .includes(item.categoryName.toLowerCase())
                                );

                                if (matchingCategory) {

                                    matchingCategory.values.forEach((process) => {
                                        sdgTotal += process.value;
                                    })
                                    unit = matchingCategory.impactCategory.refUnit;


                                }
                            });
                        });

                        items.push({
                            name: item.categoryName,
                            val: sdgTotal,
                            unit: getUnitNameFromRefID(unit),
                            correlation: item.correlation,
                        });
                        return items;
                    },
                    []
                );
            }
            return sdg;
        });
        console.log(sdgs);
        setCurrentSDGs(sdgs);
    }, [selectedValueType, selectedCategories, loadedSDGs, selectedSDGs, loadedUnitGroups, selectedLCS, selectedLCSS]);

    const [applyGraphSettings, setApplyGraphSettings] =
        useState<(options: ChartJSOptions, chartData: ChartJSData, colorIdentifiers?: ChartColorIdentifiers[]) => 
            { options: ChartJSOptions; chartData: ChartJSData; }>();
    const triggerChartUpdate = () => {
        let options = deepClone(chartOptions) || {};
        let chartData = deepClone(chartJSData);
        if (applyGraphSettings) {
            ({ options, chartData } = applyGraphSettings(options, chartData));
        } CreateChartTS(chartData, options);
    };

    useEffect(() => {
        if (chartJSData && chartOptions) {
            triggerChartUpdate();
        }
    }, [chartJSData, chartOptions]);




    return (
        <ThemeProvider theme={ResultsTheme}>
            <Box
                sx={{
                    margin: '0',
                    maxWidth: '100%',
                    maxHeight: '-webkit-fill-available',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1vw',
                }}
            >

                <Box component='div' className='results-wrapper'>
                    <Box component='div' className='results-options'>
                        <Paper square>
                            <Box component='div' className='results-options-top'>
                                <FormControl
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: '3rem',
                                        alignItems: 'stretch',

                                    }}
                                >
                                    <EnvChartNavigation
                                        product={product}
                                        defaultBaseIndex={3}
                                        defaultResultIndex={0}
                                        sdgactive
                                    />
                                    {/* <SDGLinkage /> */}
                                    <SDGChecklist
                                        setSelectedSDGs={setSelectedSDGs}
                                        disabled={false}
                                        setting="C1"
                                        dimension="Environmental"
                                    />
                                    {/* <LCSDropdowns
                                        product={product}
                                        selectedProcess={selectedProcess}
                                        setSelectedLCS={setSelectedLCS}
                                        selectedLCS={selectedLCS}
                                        setSelectedLCSS={setSelectedLCSS}
                                        selectedLCSS={selectedLCSS}
                                        setSelectedProcess={setSelectedProcess}
                                    />
                                    <ProductComparison /> */}
                                </FormControl>
                            </Box>
                        </Paper>
                        <Divider />
                        <Paper square>
                            <Box component='div' className='results-options-bottom'>
                                <Box>
                                    <FormControl
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}
                                        className='bottom-options side-left'
                                    >
                                        {/* <ImpactCategoryDropdown
                                            product={product}
                                            selectedCategories={selectedCategories}
                                            setSelectedCategories={setSelectedCategories}
                                        /> */}
                                    </FormControl>
                                </Box>
                                <Box>
                                    <FormControl
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            '@media (max-width: 1680px)': {
                                                gap: '1vw',
                                            },
                                        }}
                                        className='bottom-options side-right'
                                    >
                                        <ChartDownload sdgTableData = {currentSDGs} chartType = "SDG Table" 
                                            chartName="Normalized result: Impact category level - Product System - SDG Table" 
                                        />
                                        <GraphSetting
                                            chartOptions={chartOptions}
                                            setApplyGraphSettings={setApplyGraphSettings}
                                            triggerChartUpdate={triggerChartUpdate}
                                            chartData={chartJSData}
                                            chartColorType='process'

                                            // noColors
                                        />
                                    </FormControl>
                                </Box>
                            </Box>
                        </Paper>
                    </Box>
                </Box>
                <Box className={classes.chartSec}>
                    <Paper
                        square
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: '1vw',
                            padding: '2rem',
                        }}
                    >

                        <SDGTable currentSDGs={currentSDGs} colorPalette={sdgColor} envOnly />
                        {/* <Box className={classes.chartSec}>
                            <canvas id='graph1Canvas' className='graph-canvas' />
                        </Box> */}
                    </Paper>
                </Box>
            </Box>
        </ThemeProvider>
    );
}
export default C1EnvSDGTable;
