/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable react/function-component-definition */
import React, { useEffect, useState } from 'react';
import { Container } from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled, alpha } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import GeneralTheme from '../../style/GeneralTheme';
import { ThemeProvider } from '@emotion/react';
import FormControl from '@mui/material/FormControl';
import Dropdown from '../../dropdowns/Dropdown';
import { Outlet, useNavigate } from '@tanstack/react-router';
import SocialSPAInputs from './SocialSPAInputs';
import SocialPSILCAInputs from './SocialPSILCAInputs';
import { MenuItem } from '@mui/base/MenuItem';
import {
    LifeCycleStage,
    lifeCycleStages,
} from '../../../helper/lifeCycleStage';

import { useFormContext } from '../../forms/FormContext';
import CreateProductHeader from '../../results/components/createproductheader';


import useStyles from './SocialInputs.styles';

const SocialInputs = () => {
    const navigate = useNavigate();
    const classes = useStyles();


    const { formState, setFormState } = useFormContext();

    const [selectedImpactMethod, setSelectedImpactMethod] = useState<string>('PSILCA');

    const handleImpactMethodSwitch = (method: string) => {
        setSelectedImpactMethod(method);
    };

    return (
        <Box>
         

            <ThemeProvider theme={GeneralTheme}>
                <Container
                    className='social-modal-content'
                    maxWidth={false}
                    sx={{
                        maxWidth: '100%',
                        height: 'auto',
                        // padding: '1rem',
                    }}
                >

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '1vw',
                            width: '-webkit-fill-available',
                            '@media (max-width: 1680px)': {
                                gridTemplateColumns: '1fr 1fr',
                                gap: '1vw',
                                // alignItems: 'center',
                            },
                        }}
                    >

                        {/* <Box
                            sx={{
                                width: '-webkit-fill-available',
                                gap: '1vw',
                            }}
                        > */}
                        <Outlet />
                        {/* {selectedImpactMethod === 'SPA' ? (
                                <SocialSPAInputs />
                            ) : (
                                <SocialPSILCAInputs
                                    socialForm={form}
                                    lifeCycleStage={`${selectedLCS}:${selectedLCSS}`}
                                />
                            )}
                        </Box> */}
                         
                        {/* </Box> */}
                    </Box>

                </Container>
            </ThemeProvider>
        </Box>
    );
};

export default SocialInputs;
