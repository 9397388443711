// NOTE: RUNNING A FORMATTER IN HERE WILL CAUSE ESLINT INDENT WARNING
import { Chart } from 'chart.js'; // Ensure you import Chart correctly
import { ChartJSOptions } from '../../../interface/ChartData';

function getDynamicFontSize(vw) {
    // Elements options apply to all of the options unless overridden in a dataset
    // In this case, we are setting the border of each horizontal bar to be 2px wide
    return Math.max(10, Math.round(window.innerWidth * (vw / 100)));
}

export const initialOptions: ChartJSOptions = {
    indexAxis: 'x',
    interaction: {
        mode: 'y',
    },
    elements: {
        bar: {
            borderWidth: 0,
        },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            position: 'bottom',
            display: true,
            labels: {
                font: {
                    size: getDynamicFontSize(0.7),
                    family: 'Open Sans',
                },
            },
        },
        datalabels: {
            display: true,
            anchor: 'end',
            align: 'top',
            font: {
                size: 15,
                family: 'Open Sans',
                weight: 'normal',
            },
            textStrokeColor: 'white',
            textStrokeWidth: 2,
            labels: {
                title: {
                    font: {
                        weight: '700',
                    },
                },
            },
        },
        title: {
            display: true,
            text: 'REPLACETHIS',
            font: {
                size: getDynamicFontSize(1),
                family: 'Open Sans',
            },
        },
        tooltip: {
            enabled: true,
            callbacks: {},
            position: 'nearest',
            padding: 6,
            mode: 'point',
        },
    },
    scales: {
        x: {
            stacked: false,
            ticks: {
                font: {
                    size: getDynamicFontSize(0.7),
                    family: 'Open Sans',
                    weight: 'bold',
                },
            },
            title: {
                font: {
                    size: getDynamicFontSize(1),
                    family: 'Open Sans',
                },
            },
        },
        y: {
            title: {
                display: true,
                text: 'REPLACETHIS',
                font: {
                    size: getDynamicFontSize(1),
                    family: 'Open Sans',
                    weight: 'bold',
                },
            },
            beginAtZero: true,
            stacked: false,
            ticks: {
                maxTicksLimit: 4,
                font: {
                    size: getDynamicFontSize(0.7),
                    family: 'Open Sans',
                },
            },
        },
    },
    layout: {
        padding: {
            top: 30,
            bottom: 5,
            left: 5,
            right: 5,
        },
    },
};

// Function to update the chart with new options
function updateChartOptions(chart) {
    chart.options.plugins.legend.labels.font.size = getDynamicFontSize(1);
    chart.options.plugins.title.font.size = getDynamicFontSize(1);
    chart.options.scales.x.ticks.font.size = getDynamicFontSize(0.7);
    chart.options.scales.x.title.font.size = getDynamicFontSize(1);
    chart.options.scales.y.title.font.size = getDynamicFontSize(1);
    chart.options.scales.y.ticks.font.size = getDynamicFontSize(0.7);
    chart.update();
}

// Add event listener to update chart on window resize
window.addEventListener('resize', () => {
    const chart = Chart.getChart('graph1Canvas'); // Replace with your chart ID or chart instance
    if (chart) {
        updateChartOptions(chart);
    }
});
