import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => {return {
    Title: {
        fontSize: 28,
        fontWeight: 'normal',
        fontFamily: 'Montserrat, sans-serif',
        textAlign: 'center',
    },
}});

export default useStyles;
