import React, { useState, useEffect } from 'react';
import {
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    FormControlLabel,
    Checkbox,
    Radio,
    RadioGroup,
    ListItemText,
    MenuProps as MuiMenuProps,
    Box,
} from '@mui/material';
import { useProductStore } from '../../../hooks/useProductStore';
import { Product } from '../../../interface/Product';
import DropdownTheme from '../../style/components/DropdownTheme';
import { ThemeProvider } from '@mui/styles';
import useStyles from './ImpactCategoryDropdown.styles';

interface ImpactCategoryDropdownData {
  product: Product;
  selectedCategories: string[];
  setSelectedCategories: React.Dispatch<React.SetStateAction<string[]>>;
  defaultBaseIndex?: number;
  defaultResultIndex?: number;
}

interface DropdownOption {
  id: number;
  label: string;
  value: string;
}

function ImpactCategoryDropdown(props: ImpactCategoryDropdownData) {
    const classes = useStyles();
    const { product, selectedCategories, setSelectedCategories, defaultBaseIndex, defaultResultIndex } = props;

    const [options, setOptions] = useState<DropdownOption[]>([]);
    const { updateSelectedCategories } = useProductStore();

    useEffect(() => {
        const calc = product.calculations.find(
            (item) => item.calculationType === 'ENVIRONMENTAL'
        );
        if (calc) {
            const impactCategories = calc.calculationResults?.[0]?.subStages?.[0]?.results?.map(
                (result) => result.impactCategory.name
            ) || [];

            const alphabetiecalCategories = impactCategories.sort((a, b) => a.localeCompare(b));

            const selectedImpactCategory = alphabetiecalCategories.filter((category) =>
                selectedCategories.includes(category)
            );
            const unselectedImpactCategory = alphabetiecalCategories.filter(
                (category) => !selectedCategories.includes(category)
            );
            
            // setOptions(impactCategories.map((category: string, index: number) => ({
            // setOptions(alphabetiecalCategories.map((category: string, index: number) => ({
            //     id: index,
            //     label: category,
            //     value: category,
            // })));
            setOptions([
                ...selectedImpactCategory.map((category, index) => ({
                    id: index,
                    label: category,
                    value: category,
                })),
                ...unselectedImpactCategory.map((category, index) => ({
                    id: selectedImpactCategory.length + index,
                    label: category,
                    value: category,
                })),
            ]);

            if (!selectedCategories.length && impactCategories.length > 0) {
                const initialCategory = [impactCategories[0]];
                setSelectedCategories(initialCategory);
                updateSelectedCategories(initialCategory);
            }
        }
    }, [product, setSelectedCategories, updateSelectedCategories, selectedCategories.length]);

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newCategory = event.target.value;

        const sortedOptions = options.sort((a, b) => {
            if (a.value === newCategory) return -1;
            if (b.value === newCategory) return 1;
            return a.label.localeCompare(b.label);
        });

        setSelectedCategories([newCategory]);
        updateSelectedCategories([newCategory]);
        setOptions(sortedOptions);
    };

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, target: string) => {
        let newCategories: string[] = [...selectedCategories];
        if (event.target.checked) {
            if (!newCategories.includes(target)) {
                newCategories.push(target);
            }
        } else {
            newCategories = newCategories.filter(category => category !== target);
        }

        const sortedOptions = options.sort((a, b) => {
            if (newCategories.includes(a.value)) return -1;  
            if (newCategories.includes(b.value)) return 1;
            return a.label.localeCompare(b.label); 
        });

        setSelectedCategories(newCategories);
        updateSelectedCategories(newCategories);
        setOptions(sortedOptions);

    };

    // Prevent dropdown from closing when clicking a checkbox
    const MenuProps: Partial<MuiMenuProps> = {
        PaperProps: {
            style: {
                maxHeight: 200,
                width: 250,
            },
        },
        disableScrollLock: true,
        onClick: (event: React.MouseEvent) => {
            event.stopPropagation();
        },
    };

    return (
        <ThemeProvider theme={DropdownTheme}>
            <FormControl size="small" className={classes.FormWrapper}>
                <InputLabel id='impactCategoryLabel' htmlFor="impactCategorySelect">Impact Category</InputLabel>
                <Select
                    id="impactCategorySelect"
                    name="impactCategory"
                    sx={{ minWidth: '10vw', borderRadius: '0' }}
                    renderValue={(selected) => (
                        <Box sx={{ display: 'none', flexWrap: 'wrap' }}>
                            {selected.map((value) => (
                                <ListItemText key={value} primary={value} sx={{ whiteSpace: 'nowrap' }} />
                            ))}
                        </Box>
                    )}
                    displayEmpty
                    labelId='impactCategoryLabel'
                    multiple={defaultBaseIndex !== 3 && defaultBaseIndex !== 4}
                    value={selectedCategories}
                    MenuProps={MenuProps}
                >
                    {defaultBaseIndex === 3 || defaultBaseIndex === 4 ? (
                        <RadioGroup
                            aria-labelledby="impactcategories-radio-buttons-label"
                            name="radio-buttons-impactcategories"
                            value={selectedCategories[0] || ''}
                            onChange={handleRadioChange}
                        >
                            {options.map((option) => (
                                <MenuItem key={option.id} value={option.value}>
                                    <FormControlLabel
                                        value={option.value}
                                        control={
                                            <Radio
                                                checked={selectedCategories.includes(option.value)}
                                                value={option.value}
                                                aria-label={option.label}
                                                sx={{
                                                    padding: 0,
                                                    marginLeft: 0,
                                                    marginRight: '0.5vw',
                                                    '& .MuiSvgIcon-root': {
                                                        width: '1em',
                                                        height: '1em',
                                                        fontSize: '1.2vw',
                                                        color: 'green',
                                                    },
                                                }}
                                            />
                                        }
                                        label={option.label}
                                        sx={{ fontSize: '0.7vw', textAlign: 'left' }}
                                    />
                                </MenuItem>
                            ))}
                        </RadioGroup>
                    ) : (
                        options.map((option) => (
                            <MenuItem key={option.id} value={option.value}>
                                <FormControlLabel
                                    value={option.value}
                                    control={
                                        <Checkbox
                                            checked={selectedCategories.includes(option.value)}
                                            onChange={(event) => handleCheckboxChange(event, option.value)}
                                            sx={{
                                                padding: 0,
                                                marginLeft: 0,
                                                marginRight: 0,
                                                '& .MuiSvgIcon-root': {
                                                    width: '1em',
                                                    height: '1em',
                                                    fontSize: '1.2vw',
                                                },
                                            }}
                                        />
                                    }
                                    label={<ListItemText primary={option.label} />}
                                    sx={{ fontSize: '0.7vw', textAlign: 'left' }}
                                />
                            </MenuItem>
                        ))
                    )}
                </Select>
            </FormControl>
        </ThemeProvider>
    );
}

export default ImpactCategoryDropdown;
