import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import useStyles from './C1SDGStackedBarSPA.styles';
import { Bar } from 'react-chartjs-2';
// import Loader from '../../components/loader';
import toast from 'react-hot-toast';
import deepClone from '../../../../../../helper/deepClone';
import { ChartJSData, ChartJSOptions } from '../../../../../../interface/ChartData';
import SPAChartNavigation from '../../../../../dropdowns/BaseOptions/SPAChartNavigation';
import ResultsTheme from '../../../../style/ResultsTheme';
import { initialOptions } from '../../../../options/InitialOptionValues';
import ChartDownload from '../../../../options/components/ChartDownload';
import ProductComparison from '../../../../options/components/Comparison';
import GraphSetting from '../../../../options/components/GraphSettings';
import { applyStacked, applyValueType } from '../../../../options/util/applyOptions';
import { CreateChartTS } from '../../../../util/CreateChartTS';
import { SPAPSILCAPriorityResult, Product, SPALCSProcessResult, SPALifeCycleResult, SPAResult, SPAStakeholderResult } from '../../../../../../interface/Product';
import Dropdown from '../../../../../dropdowns/Dropdown';
import { palette1, palette2, palette3, palette4, processColors, productPerformanceColor, sdg17Colors, socialProcessColors } from '../../../../../../helper/colors';
import { useFormContext } from '../../../../../forms/FormContext';
import { WeighingSet, applyWeight, weighingMethods } from '../../../../../../helper/weighingMethod';
import { fetchEnvSDGs, fetchSocialSDGs } from '../../../../../../helper/fetchUtil';
import { noCaseCompare } from '../../../../../../helper/utils';
import { SDG } from '../../../../../../interface/SDG';
import SDGChecklist from '../../../../components/SDGChecklist';
import GraphType from '../../../../options/components/GraphTypeOptions';
import { ChartColorIdentifiers } from '../../../../options/components/GraphSettingsOptions';



interface ChartData {
    product: Product;
}

function C1SDGStackedBarSPA(props: ChartData) {
    const { formState, setFormState } = useFormContext();
    const classes = useStyles();
    const { product } = props;
    const [chartJSData, setChartJSData] = useState<ChartJSData>({ datasets: [], labels: [] });
    const [chartOptions, setChartOptions] = useState<ChartJSOptions>(initialOptions);
    const [colorIdentifiers, setColorIdentifiers] = useState<ChartColorIdentifiers[]>([]);
    const [selectedValueType, setSelectedValueType] = useState<string>('');
    const [selectedStakeholder, setSelectedStakeholder] = useState<string>('All Stakeholders');
    const [stakeholderOptions, setStakeholderOptions] = useState<string[]>([]);
    const [currentWeighingSet, setCurrentWeighingSet] = useState<WeighingSet>();
    const [loadedSDGs, setLoadedSDGs] = useState<SDG[]>([]);
    const [selectedSDGs, setSelectedSDGs] = useState<string[]>([]);

    const [results, setResults] = useState<SPAResult>();

    useMemo(() => {
        const calc = product.calculations.find(
            (item) => { return item.calculationType === 'SOCIAL-SPA' },
        );
        const calcResults = calc?.calculationResults as unknown as SPAResult;

        setResults((calcResults));
        const weighingMethod = weighingMethods.find((method) => method.name === product.socialData.weighingMethod.name);
        const weighingSet = weighingMethod?.sets.find((set) => set.name === product.socialData.weighingMethod.set);
        setCurrentWeighingSet(weighingSet);
        setStakeholderOptions(['All Stakeholders'].concat(calcResults.stakeholderResults.map((item) => { return item.name })));
        try {
            fetchSocialSDGs().then((sdgs) => setLoadedSDGs(sdgs)).catch();
        } catch (err) {
            console.log(err);
        }
    }, [product]);





    useEffect(() => {

        if(!results)
            return;


        const chartData: ChartJSData = { datasets: [], labels: [] };
        const options: ChartJSOptions = deepClone<ChartJSOptions>(initialOptions);
        const chartColorIdentifiers : ChartColorIdentifiers[] = [];

        if (!currentWeighingSet) {
            return;
        }

        if (options.scales && options.scales.y && options.scales.y.title) {
            options.scales.y.title.text = "Medium Risk Hours";
        }

        const indexes: number[] = [];
        let processes = results.lifeCycleResults.reduce(
            (items: SPALCSProcessResult[], lifeCycle) => {
                return items.concat(lifeCycle.processResults);
            },
            []
        );
        let stakeholders = results.stakeholderResults;
        if (selectedStakeholder !== "All Stakeholders") {
            stakeholders = stakeholders.filter(
                (item) => item.name === selectedStakeholder
            );
            processes = processes.map((item) => {

                return {
                    ...item,
                    PSILCAPriorities: item.PSILCAPriorities.filter(
                        (item) =>
                            item.category.split(":")[0] === selectedStakeholder
                    ),
                    PSILCAResults: item.PSILCAResults.filter(
                        (item) =>
                            item.category.split(":")[0] === selectedStakeholder

                    ),
                };
            });
        }

        const samProcessResults = stakeholders.reduce(
            (scores: { name: string, val: number }[], result) => {
                return scores.concat(
                    result.SPAPriorities.map((item) => {
                        return {
                            name: item.category,
                            val: applyWeight(item, "sam", currentWeighingSet),
                        };
                    }, 0)
                );
            },
            []
        );



        chartData.datasets = processes.map((process, index) => {
            chartColorIdentifiers.push({label: process.processName});

            return {
                label: process.processName,
                data: loadedSDGs
                    .filter((item) => {
                        return selectedSDGs.find(
                            (item2) => item2 === item.name
                        );
                    })
                    .map((sdg) => {
                        return sdg.categories.reduce((total : number | null, category) => {
                            const matchingResult =
                                process.PSILCAPriorities.find((result) =>
                                    noCaseCompare(
                                        result.category,
                                        category.categoryName
                                    )
                                );
                            if (
                                matchingResult &&
                                category.correlation.toLocaleString() !== "No Connection"
                            ) {
                                return (
                                    (total ? total : 0) +
                                    applyWeight(matchingResult, 'psilca', currentWeighingSet)
                                );
                            } else {
                                return total;
                            }
                        }, null);
                    }),
                // backgroundColor: selectedPalette[index], //random color from array
                borderColor: "000000",
                skipNull: true,
            };
        });

        chartData.datasets[0].data.forEach((item, index) => {
            if (
                chartData.datasets.every(
                    (dataset) => dataset.data[index] === null
                )
            ) {
                indexes.push(index);
            }
        });
        chartData.datasets = chartData.datasets.map((dataset) => {
            return {
                ...dataset,
                data: dataset.data.filter(
                    (item, index) =>
                        !indexes.find((item2) => item2 === index)
                ),
            };
        });
        chartData.labels = loadedSDGs.filter((item) => {
            return selectedSDGs.find((item2) => item2 === item.name);
        })
            .map((sdg) => sdg.name)
            .filter(
                (item, index) =>
                    !indexes.find((item2) => item2 === index)
            );
        chartColorIdentifiers.push({label:results.SPAProcess.name});

        chartData.datasets.push({
            label: results.SPAProcess.name,
            data: chartData.labels.map((item) => {
                const sdg = loadedSDGs.find(
                    (item2) => item2.name === item
                );
                return samProcessResults.reduce(
                    (resultsSum, category) => {
                        const matchingCategory = sdg?.categories.find(
                            (item3) =>
                                noCaseCompare(item3.categoryName, category.name)
                        );
                        if (matchingCategory) {
                            if (
                                matchingCategory.correlation.toLocaleString() !== "No Connection"
                            ) {
                                resultsSum += category.val;
                            }
                        }
                        return resultsSum;
                    },
                    0
                );
            }),
            // backgroundColor: selectedPalette[chartData.datasets.length], //random color from array
            borderColor: "000000",
        }); console.log(loadedSDGs);
        
        // chartData.datasets = [
        //     {
        //         label: "Product Performance",
        //         data: loadedSDGs
        //             .map((sdg) => {
        //                 return sdg.categories.reduce((total: number, category) => {
        //                     let processTotal = 0;

        //                     if (category.correlation.toLocaleString() !== "No Connection") {
        //                         processes.forEach((process) => {
        //                             const matchingResult =
        //                                 process.PSILCAPriorities.find((result) => {
        //                                     // console.log(result.category + " vs " +
        //                                     //     category.categoryName);

        //                                     return noCaseCompare(
        //                                         result.category,
        //                                         category.categoryName
        //                                     )
        //                                 }
        //                                 );

        //                             if (matchingResult) {
        //                                 processTotal += applyWeight(matchingResult, 'psilca', currentWeighingSet);
        //                             }
        //                         });
        //                     }

        //                     if (processTotal > 0) {
        //                         return (total ? total : 0) + processTotal;
        //                     } else {
        //                         return total;
        //                     }
        //                 }, 0);
        //             })
        //             .filter((item, index) => {
        //                 if (item === null) {
        //                     indexes.push(index);
        //                     return false;
        //                 } else {
        //                     return true;
        //                 }
        //             }),
        //         backgroundColor: sdg17Colors, //colors[0], //random color from array
        //         borderColor: "000000",
        //         skipNull: true,
        //     },
        // ];
        console.log(chartData);

        chartData.labels = loadedSDGs.map((sdg) => sdg.name);

        chartData.datasets[0].data = chartData.datasets[0].data.map(
            (item, index) => {
                const sdg = loadedSDGs.find(
                    (sdg) => sdg.name === chartData.labels[index]
                );

                return (
                    item +
                    samProcessResults.reduce((resultSum, category) => {
                        const matchingCategory = sdg?.categories.find((item) =>
                            noCaseCompare(item.categoryName, category.name)
                        );
                        if (matchingCategory) {
                            if (
                                matchingCategory.correlation.toLocaleString() !== "No Connection"
                            ) {
                                resultSum += category.val;
                            }
                        }
                        return resultSum;
                    }, 0)
                );
            }
        );

        chartData.labels = loadedSDGs
            .map((sdg) => sdg.name)
            .filter(
                (item, index) =>
                    !indexes.find((item2) => item2 === index)
            );


        setChartJSData(chartData);
        setChartOptions(options);
        setColorIdentifiers(chartColorIdentifiers);    
    }, [selectedValueType, selectedStakeholder,  loadedSDGs]);

    const [applyGraphSettings, setApplyGraphSettings] =
        useState<(options: ChartJSOptions, chartData: ChartJSData, colorIdentifiers?: ChartColorIdentifiers[]) => 
            { options: ChartJSOptions; chartData: ChartJSData; }>();
    const triggerChartUpdate = () => {
        let options = deepClone(chartOptions) || {};
        let chartData = deepClone(chartJSData);
        const applyOptionReturn = applyValueType(selectedValueType, chartData, options);
        options = applyOptionReturn.options;
        chartData = applyOptionReturn.chartData;
        options = applyStacked(options, true);

        if (applyGraphSettings) {
            ({ options, chartData } = applyGraphSettings(options, chartData, colorIdentifiers));
        }
        CreateChartTS(chartData, options);
    };

    useEffect(() => {
        if (chartJSData && chartOptions) {
            triggerChartUpdate();
        }
    }, [chartJSData, chartOptions]);

    return (
        <ThemeProvider theme={ResultsTheme}>
            <Box
                sx={{
                    margin: '0',
                    maxWidth: '100%',
                    maxHeight: '-webkit-fill-available',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1vw',
                }}
            >
                <Box className='temp-box' />
                <Box component='div' className='results-wrapper'>
                    <Box component='div' className='results-options'>
                        <Paper square>
                            <Box component='div' className='results-options-top'>
                                <FormControl
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: '3rem',
                                        alignItems: 'stretch',

                                    }}
                                >
                                    <SPAChartNavigation product={product} defaultBaseIndex={2} defaultResultIndex={0} reverse />
                                    <SDGChecklist
                                        setSelectedSDGs={setSelectedSDGs}
                                        disabled={false}
                                        setting="C1"
                                        dimension="SPA"
                                    />
                                    <ProductComparison />
                                </FormControl>
                            </Box>
                        </Paper>
                        <Divider />
                        <Paper square>
                            <Box component='div' className='results-options-bottom'>
                                <Box>
                                    <FormControl
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}
                                        className='bottom-options side-left'
                                    >
                                        <GraphType
                                            dimension='SPA'
                                            chart='C1'
                                            type='stacked'
                                            sdgOn
                                        />
                                        <Dropdown
                                            options={stakeholderOptions}
                                            valueSetter={setSelectedStakeholder}
                                            value={selectedStakeholder}
                                            noDefaults
                                        />
                                        <Dropdown
                                            options={['Raw Values', '% Per Product System']}
                                            valueSetter={setSelectedValueType}
                                            value={selectedValueType}
                                        />

                                    </FormControl>
                                </Box>
                                <Box>
                                    <FormControl
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            '@media (max-width: 1680px)': {
                                                gap: '1vw',
                                            },
                                        }}
                                        className='bottom-options side-right'
                                    >
                                        <ChartDownload chartData={chartJSData}
                                            
                                            chartName={'Subcategory level result - Product System'}
                                            chartType='Bar Graph' />
                                        <GraphSetting
                                            chartOptions={chartOptions}
                                            setApplyGraphSettings={setApplyGraphSettings}
                                            triggerChartUpdate={triggerChartUpdate}
                                            colorIdentifiers={colorIdentifiers}
                                            chartData={chartJSData}
                                            chartColorType='performance'
                                            graphType='barindicator'
                                            sdgOn
                                        />

                                    </FormControl>
                                </Box>
                            </Box>
                        </Paper>
                    </Box>
                </Box>
                <Box className={classes.chartSec}>
                    <Paper
                        square
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <Box className={classes.chartSec}>
                            <canvas id='graph1Canvas' className='graph-canvas' />
                        </Box>
                    </Paper>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default C1SDGStackedBarSPA;
