function addEscapeCharacters(str: string | null) {
    return str ? `"${str}"` : '';
}

const emailValidator = (email: string) => {
    // eslint-disable-next-line
    const regExp = /(?:[a-z0-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-Z0-9](?:[a-z0-Z0-9-]*[a-z0-Z0-9])?\.)+[a-z0-Z0-9](?:[a-z0-Z0-9-]*[a-z0-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
    return regExp.test(email);
};

const passwordValidator = (password: string) => {
    const regExp = /^(?=^.{8,32}$)((?=.*\d)(?=.*[\W_]+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;
    return regExp.test(password);
};

const capitalize = (word: string) => {
    const data = word.charAt(0).toUpperCase() + word.slice(1);
    return data;
};

export {
    addEscapeCharacters,
    emailValidator,
    passwordValidator,
    capitalize,
};
