import React from "react";
import "../Homepage.css";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const ResourcesWhitePapersAndPublications: React.FC = () => {
    return (
        <div className="website-only">
            <Header />

            <div className="header-container">
                <div className="header-transparent-layer"> </div>
                <img
                    src={require("../../images/headers/pexels-pixabay-315999.jpg")}
                    alt="Green mountyains under a blue sky"
                />

                <h1>Resources</h1>
            </div>
            <div className="box-container">
                <h2>White Papers & Publications</h2>
                <p className="tossa-fancy p-hide">
          Hannouf, M., Assefa G. Subcategory assessment method for social life
          cycle assessment: a case study of high-density polyethylene production
          in Alberta, Canada. International Journal of Life Cycle Assessment,
          23(1):116-132, 2018.{" "}
                    <a href="https://doi.org/10.1007/s11367-017-1303-1">
                        <i>https://doi.org/10.1007/s11367-017-1303-1</i>
                    </a>{" "}
                </p>
                <p className="tossa-fancy">
                    {" "}
          Hannouf, M., Assefa, G., and Gates I.D. Carbon intensity threshold for
          Canadian oil sands industry using planetary boundaries: is a
          sustainable carbon-negative industry possible? Renewable and
          Sustainable Energy Reviews, 151:111529, 2021.{" "}
                    <a href="https://doi.org/10.1016/j.rser.2021.111529">
                        <i>https://doi.org/10.1016/j.rser.2021.111529</i>
                    </a>
                </p>
                <p className="tossa-fancy">
          Hannouf, M., Padilla-Rivera, A., Assefa, G., Gates, I.D.
          Methodological framework to find links between life cycle
          sustainability assessment categories and the UN Sustainable
          Development Goals based on literature. Journal of Industry Ecology,
          1-19, 2022.{" "}
                    <a href="https://doi.org/10.1111/jiec.13283">
                        <i>https://doi.org/10.1111/jiec.13283</i>
                    </a>
                </p>
            </div>

            <Footer />
        </div>
    );
};

export default ResourcesWhitePapersAndPublications;
