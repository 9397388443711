import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => {return {
    Header: {
        position: 'sticky',
        top: 0,
        '& .MuiToolbar-root': {
            height: '3vw !important',
            minHeight: 'auto',
        },
        '&.MuiAppBar-root': {
            background: '#32463e',
            color: '#fff',
            zIndex: '1300',
            boxShadow: '4px 0px 5px 0px rgba(0, 0, 0, 0.25)',
        },
    },
    toolbar: {
        justifyContent: 'space-between',
    },
    headingAndLogo: {
        display: 'flex',
        alignItems: 'center',
        gap: '30px',
        '& img': {
            width: '100px',
            height: '50px',
            objectFit: 'contain',
            objectPosition: 'left',
        },
    },
    menuIconBtn: {
        '&.MuiIconButton-root': {
            '& svg': {
                fontSize: '1.5vw',
                color: '#FFFFFF',
            },
        },
    },
}});

export default useStyles;
