// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../font/Inter-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../font/Inter-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../font/Inter.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: "Inter-Bold";
    src: local("Inter-Bold"), url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "Inter-Medium";
    src: local("Inter-Medium"), url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "Inter";
    src: local("Inter"), url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("truetype");
    font-weight: normal;
}

* {
    font-family: "Inter";
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

html, #root {
    height: 100%;
    background-color: #F2F2F2;
}

.App {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #F2F2F2;
}
`, "",{"version":3,"sources":["webpack://./src/css/style.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,oFAA0E;IAC1E,mBAAmB;AACvB;;AAEA;IACI,2BAA2B;IAC3B,sFAA8E;IAC9E,mBAAmB;AACvB;;AAEA;IACI,oBAAoB;IACpB,+EAAgE;IAChE,mBAAmB;AACvB;;AAEA;IACI,oBAAoB;IACpB,WAAW;IACX,YAAY;IACZ,sBAAsB;AAC1B;;AAEA;IACI,YAAY;IACZ,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,sBAAsB;IACtB,yBAAyB;AAC7B","sourcesContent":["@font-face {\r\n    font-family: \"Inter-Bold\";\r\n    src: local(\"Inter-Bold\"), url(\"../font/Inter-Bold.ttf\") format(\"truetype\");\r\n    font-weight: normal;\r\n}\r\n\r\n@font-face {\r\n    font-family: \"Inter-Medium\";\r\n    src: local(\"Inter-Medium\"), url(\"../font/Inter-Medium.ttf\") format(\"truetype\");\r\n    font-weight: normal;\r\n}\r\n\r\n@font-face {\r\n    font-family: \"Inter\";\r\n    src: local(\"Inter\"), url(\"../font/Inter.ttf\") format(\"truetype\");\r\n    font-weight: normal;\r\n}\r\n\r\n* {\r\n    font-family: \"Inter\";\r\n    margin: 0px;\r\n    padding: 0px;\r\n    box-sizing: border-box;\r\n}\r\n\r\nhtml, #root {\r\n    height: 100%;\r\n    background-color: #F2F2F2;\r\n}\r\n\r\n.App {\r\n    min-height: 100%;\r\n    display: flex;\r\n    flex-direction: column;\r\n    background-color: #F2F2F2;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
