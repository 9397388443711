/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable react/function-component-definition */
import React, { useEffect, useState } from 'react';
import { Container } from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled, alpha } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import GeneralTheme from '../../style/GeneralTheme';
import { ThemeProvider } from '@emotion/react';
import FormControl from '@mui/material/FormControl';
import Dropdown from '../../dropdowns/Dropdown';
import { useNavigate } from '@tanstack/react-router';
import SocialSPAInputs from './spa';
import SocialPSILCAInputs from './psilca';
import { MenuItem } from '@mui/base/MenuItem';
import {
    LifeCycleStage,
    lifeCycleStages,
} from '../../../helper/lifeCycleStage';
import { useForm } from '@tanstack/react-form';
import { SocialInputsForm, loadValuesFromFormContext } from '../../../interface/Form';
import { fetchSocialMethods } from '../../../helper/fetchUtil';
import { weighingMethods } from '../../../helper/weighingMethod';
import { useFormContext } from '../../forms/FormContext';
import SocialInfoPopup from './components/SocialInfoPopup';
import CreateProductHeader from '../../results/components/createproductheader';
import { CreateProductNavButtons } from '../components/CreateProductNavButtons';
import { submitCreateProductHandler } from '../../../helper/productSubmit';

import useStyles from './SocialInputs.styles';

const SocialInputs = () => {
    const navigate = useNavigate();
    const classes = useStyles();

    const [selectableMethods, setSelectableMethods] = useState<string[]>([
        'PSILCA',
        'SPA',
    ]);
    const [selectableLCS, setSelectableLCS] = useState<string[]>([]);
    const [selectableLCSS, setSelectableLCSS] = useState<string[]>([]);
    const [selectedStages, setSelectedStages] = useState<LifeCycleStage[]>([]);

    const [selectedLCS, setSelectedLCS] = useState<string>('');
    const [selectedLCSS, setSelectedLCSS] = useState<string>('');

    const { formState, setFormState } = useFormContext();

    const [selectedImpactMethod, setSelectedImpactMethod] = useState<string>('PSILCA');

    const handleImpactMethodSwitch = (method: string) => {
        setSelectedImpactMethod(method);
    };
    const form = useForm<SocialInputsForm>({
        defaultValues: {
            socialInputProcesses: [],
            currentLCS: formState.selectedLCS ? (formState.selectedLCS as string[])[0].split(':')[0] : 'Product Stage',
            currentLCSS: formState.selectedLCS ? (formState.selectedLCS as string[])[0].split(':')[1] : 'Raw Material Supply', // TODO use this way of getting default LCS in social as well
            socialAssessmentMethod: 'PSILCA',
            socialSPAWeighingMethod: 'Distance-to-target + Risk-based',
            socialSPAWeighingSet: '',
        },
        onSubmit: ({ value }) => {
            // Do something with form data
            setFormState({
                ...formState,
                socialInputProcesses: value.socialInputProcesses,
                socialAssessmentMethod: value.socialAssessmentMethod,
                socialSPAWeighingMethod: value.socialSPAWeighingMethod,
                socialSPAWeighingSet: value.socialSPAWeighingSet,
            });

        },
    });

    const [selectableWeighingMethods, setSelectableWeighingMethods] = useState<
        string[]
    >([
        'Distance-to-target + Risk-based',
        'Equal Weighing',
        'Based on norms',
        "Stakeholders' judgement",
    ]);
    const [selectableWeighingSets, setSelectableWeighingSets] = useState<
        string[]
    >([]);

    const selectWeighingMethodHandler = (val: string) => {
        const selectedMethod = weighingMethods.find(
            (_method) => {return _method.name === val},
        );
        if (selectedMethod) {
            setSelectableWeighingSets(selectedMethod.sets.map((set) => {return set.name}));
        }
    };

    const selectLCSHandler = (lcs: string, _stages?: LifeCycleStage[]) => {
        const stages = _stages || selectedStages;
        const stage = stages.find((_stage) => {return _stage.name === lcs});
        if (stage) {
            setSelectableLCSS(stage.substages.map((substage) => {return substage.name}));
            setSelectedLCSS(stage.substages[0].name);
            form.setFieldValue('currentLCSS', stage.substages[0].name);
        }
    };

    const fetchSelectedLCS = () => {
        if (formState && formState.selectedLCS) {
            const stages: LifeCycleStage[] = [];
            (formState.selectedLCS as string[]).forEach((lcs: string) => {
                const matchingLCS = stages.find(
                    (stage) => {return stage.name === lcs.split(':')[0]},
                );
                if (matchingLCS) {
                    matchingLCS.substages.push({ name: lcs.split(':')[1] });
                } else {
                    stages.push({
                        name: lcs.split(':')[0],
                        substages: [{ name: lcs.split(':')[1] }],
                    });
                }
            });
            setSelectedStages(stages);
            if (stages[0]) {
                form.setFieldValue('currentLCS', stages[0].name);
                setSelectedLCS(stages[0].name);
                setSelectableLCS(stages.map((stage) => {return stage.name}));
                selectLCSHandler(stages[0].name, stages);
            }
        } else {
            navigate({ to: '../details' })
                .then((val) => { })
                .catch((err) => { });
        }
    };

    useEffect(() => {
        selectWeighingMethodHandler(weighingMethods[0].name);
        fetchSelectedLCS();
        loadValuesFromFormContext(form, formState, ['socialAssessmentMethod']);
        const assessmentMethod = formState.socialAssessmentMethod as string;
        if (assessmentMethod) {
            setSelectedImpactMethod(assessmentMethod);
        }
    }, []);

    const showValues = () => {
        const socialInputProcesses = form.getFieldValue('socialInputProcesses');
        console.log(socialInputProcesses);
        console.log(form.getFieldValue('currentLCS'));
        console.log(form.getFieldValue('currentLCSS'));
    };

    return (
        <Box>
            <CreateProductHeader formState={formState} currentPage='social' />

            <ThemeProvider theme={GeneralTheme}>
                <Container
                    className='social-modal-content'
                    maxWidth={false}
                    sx={{
                        maxWidth: '100%',
                        height: '93vh',
                        // padding: '1rem',
                    }}
                >
                    <button
                        type='button'
                        onClick={() => {
                            console.log(formState);
                        }}
                        style={{ position: 'absolute' }}

                    >
                        PRINT CONTEXT
                    </button>
                    <button type='button' onClick={showValues}
                        style={{ position: 'absolute' }}

                    >
                        PRINT FORM
                    </button>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '1vw',
                            width: '-webkit-fill-available',
                            '@media (max-width: 1680px)': {
                                gridTemplateColumns: '1fr 1fr',
                                gap: '1vw',
                                alignItems: 'center',
                            },
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                gap: '1vw',
                                width: '-webkit-fill-available',
                            }}
                        >
                            <Paper
                                square
                                sx={{
                                    display: 'grid',
                                    gridTemplateColumns: 'auto 1fr',
                                    justifyItems: 'center',
                                    alignItems: 'center',
                                    width: '-webkit-fill-available',
                                    '@media (max-width: 1680px)': {
                                        gridTemplateColumns: 'auto auto',
                                        gap: '1vw',
                                        alignItems: 'center',
                                    },
                                }}
                            >
                                <Typography component='h2' variant='h2'>
                                    Impact Assessment Method
                                </Typography>
                                <Box className={classes.IAMWrapper}>
                                    <form.Field
                                        name='socialAssessmentMethod'
                                        children={(field) => {return (
                                            <Dropdown
                                                options={selectableMethods}
                                                onChange={(e) => {
                                                    field.handleChange(e.target.value);
                                                    setSelectedImpactMethod(e.target.value);
                                                }}
                                                id={field.name}
                                                className={field.name}
                                                value={field.state.value}
                                                onBlur={field.handleBlur}
                                                label='Method Stage'
                                                noDefaults

                                            />
                                        )}}
                                    />

                                    <SocialInfoPopup
                                        selectedImpactMethod={selectedImpactMethod}
                                    />
                                </Box>
                            </Paper>
                            <Paper
                                square
                                sx={{
                                    display: 'grid',
                                    gridTemplateColumns: 'auto 1fr',
                                    justifyItems: 'center',
                                    alignItems: 'center',
                                    width: '-webkit-fill-available',
                                }}
                            >
                                <Typography component='h2' variant='h2'>
                                    Lifecycle Stages
                                </Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: '2vw',
                                        // '@media (max-width: 1680px)': {
                                        //     flexDirection: 'column',
                                        //     gap: '5px',
                                        // },
                                    }}
                                >
                                    <form.Field
                                        name='currentLCS'
                                        children={(field) => {return (
                                            <Dropdown
                                                options={selectableLCS}
                                                onChange={(e) => {
                                                    field.handleChange(e.target.value);
                                                    selectLCSHandler(e.target.value);
                                                    setSelectedLCS(e.target.value);
                                                }}
                                                id={field.name}
                                                className={field.name}
                                                value={field.state.value}
                                                onBlur={field.handleBlur}
                                                label='Lifecycle Stage'
                                                noDefaults
                                            />
                                        )}}
                                    />
                                    <form.Field
                                        name='currentLCSS'
                                        children={(field) => {return (
                                            <Dropdown
                                                options={selectableLCSS}
                                                onChange={(e) => {
                                                    field.handleChange(e.target.value);
                                                    setSelectedLCSS(e.target.value);
                                                }}
                                                id={field.name}
                                                className={field.name}
                                                value={field.state.value}
                                                onBlur={field.handleBlur}
                                                label='Sublifecycle Stage'
                                                noDefaults
                                            />
                                        )}}
                                    />
                                </Box>
                            </Paper>
                        </Box>
                        <Box
                            sx={{
                                width: '-webkit-fill-available',
                            }}
                        >
                            {selectedImpactMethod === 'SPA' ? (
                                <SocialSPAInputs />
                            ) : (
                                <SocialPSILCAInputs
                                    socialForm={form}
                                    lifeCycleStage={`${selectedLCS}:${selectedLCSS}`}
                                />
                            )}
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                gap: '1vw',
                                width: '-webkit-fill-available',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <Paper
                                square
                                sx={{
                                    display: selectedImpactMethod === 'SPA' ? 'flex' : 'none',
                                    gap: '1vw',
                                    width: '-webkit-fill-available',
                                    justifyContent: 'center',
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'grid',
                                        gridTemplateColumns: 'auto 1fr',
                                        justifyItems: 'center',
                                        alignItems: 'center',
                                        width: '-webkit-fill-available',
                                    }}
                                >
                                    <Typography component='h2' variant='h2'>
                                        Weighting Method
                                    </Typography>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            width: '-webkit-fill-available',
                                            gap: '3rem',
                                            justifyContent: 'center',
                                            // '@media (max-width: 1680px)': {
                                            //     gap: '1vw',
                                            // },
                                        }}
                                    >
                                        <form.Field
                                            name='socialSPAWeighingMethod'
                                            children={(field) => {return (
                                                <Dropdown
                                                    options={selectableWeighingMethods}
                                                    onChange={(e) => {
                                                        field.handleChange(e.target.value);
                                                        console.log(field.state.value);
                                                        selectWeighingMethodHandler(e.target.value);
                                                    }}
                                                    id={field.name}
                                                    className={field.name}
                                                    value={field.state.value}
                                                    onBlur={field.handleBlur}
                                                    label='Weighing Method'
                                                />
                                            )}}
                                        />
                                        <form.Field
                                            name='socialSPAWeighingSet'
                                            children={(field) => {return (
                                                <Dropdown
                                                    options={selectableWeighingSets}
                                                    onChange={(e) => {
                                                        field.handleChange(e.target.value);
                                                    }}
                                                    id={field.name}
                                                    className={field.name}
                                                    value={field.state.value}
                                                    onBlur={field.handleBlur}
                                                    label='Weighing Set'
                                                />
                                            )}}
                                        />
                                    </Box>
                                </Box>
                            </Paper>
                            <CreateProductNavButtons form={form} formState={formState} currentPage='social' />

                        </Box>
                    </Box>
                </Container>
            </ThemeProvider>
        </Box>
    );
};

export default SocialInputs;
