import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => {return {
    chartSec: {
        width: '-webkit-fill-available',
        height: '63vh',
    },
}});

export default useStyles;
