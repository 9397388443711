import { makeStyles } from '@mui/styles';

const selectBorderRemove = makeStyles((theme) => {return {
    select: {
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
        },
    },
}});

export default selectBorderRemove;
