// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-container-two.highlights p {
    margin-top: 0;
}
.core-value{
    background-color: #fff;
    padding: 20px;
    box-shadow: 0px 3px 6px;
    display: flex;
    margin: 15px;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/website/pages/about/OurMission.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;AACA;IACI,sBAAsB;IACtB,aAAa;IACb,uBAAuB;IACvB,aAAa;IACb,YAAY;IACZ,sBAAsB;IACtB,6BAA6B;IAC7B,mBAAmB;AACvB","sourcesContent":[".image-container-two.highlights p {\r\n    margin-top: 0;\r\n}\r\n.core-value{\r\n    background-color: #fff;\r\n    padding: 20px;\r\n    box-shadow: 0px 3px 6px;\r\n    display: flex;\r\n    margin: 15px;\r\n    flex-direction: column;\r\n    justify-content: space-evenly;\r\n    align-items: center;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
