import { makeStyles } from '@mui/styles';

const dropdownWrapperStyle = makeStyles((theme) => {return {
    DropdownSwitchWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid #bdbdbd',
        height: 'auto',
        width: '13vw',
    },
}});

export default dropdownWrapperStyle;
