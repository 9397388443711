import { useState, useMemo, useEffect } from 'react';
import useStyles from './B4SPACharts.styles';
import { ThemeProvider } from '@emotion/react';
import FormControl from '@mui/material/FormControl';
import Dropdown from '../../../../../dropdowns/Dropdown';
import { Box, Paper, Divider } from '@mui/material';
import deepClone from '../../../../../../helper/deepClone';
import { ChartJSData, ChartJSOptions } from '../../../../../../interface/ChartData';
import { Product, SPAResult } from '../../../../../../interface/Product';
import SPAChartNavigation from '../../../../../dropdowns/BaseOptions/SPAChartNavigation';
import LCSDropdowns from '../../../../../dropdowns/LCSDropdowns';
import ResultsTheme from '../../../../style/ResultsTheme';
import '../../../../style/ResultsTheme.css';
import { initialOptions } from '../../../../options/InitialOptionValues';
import ChartDownload from '../../../../options/components/ChartDownload';
import ProductComparison from '../../../../options/components/Comparison';
import GraphSetting from '../../../../options/components/GraphSettings';
import { applyValueType } from '../../../../options/util/applyOptions';
import { CreateChartTS } from '../../../../util/CreateChartTS';
import { treemapRGB } from '../../../../../../helper/colors';
import C4Bar1SPA from '../C4Bar1SPA';
import C4Bar2SPA from '../C4Bar2SPA';
import C4RadarSPA from '../C4RadarSPA';
import C4SDGSunburstSPA from '../C4SunburstSPA';
import { Outlet } from '@tanstack/react-router';
import SDGLinkage from '../../../../options/components/SDGLinkage';

interface ChartData {
    product: Product;
}
function B4SPACharts(props: ChartData) {
    const classes = useStyles();
    const { product } = props;
    const [chartJSData, setChartJSData] = useState<ChartJSData>({ datasets: [], labels: [] });
    const [chartOptions, setChartOptions] = useState<ChartJSOptions>(initialOptions);
    const [colorIdentifiers, setColorIdentifiers] = useState<ChartColorIdentifiers[]>([]);
    const [selectedValueType, setSelectedValueType] = useState<string>('');
    const [results, setResults] = useState<SPAResult>();
 

    useMemo(() => {
        const calc = product.calculations.find(
            (item) => { return item.calculationType === 'SOCIAL-SPA' },
        );
        const calcResults = calc?.calculationResults as unknown as SPAResult;
        setResults((calcResults));

    }, [product]);


    useEffect(() => {
        if (chartJSData && chartOptions) {
            const applyOptionReturn = applyValueType(selectedValueType, chartJSData, chartOptions);
            const updatedOptions = applyOptionReturn.options;
            const updatedChartData = applyOptionReturn.chartData;
            CreateChartTS(updatedChartData, updatedOptions, 'pie', 'graph2Canvas');
        }
    }, [chartJSData, chartOptions]);

    const doubleGraph = results && results.stakeholderResults.length > 0;

    return (
        <ThemeProvider theme={ResultsTheme}>
            <Box
                sx={{
                    margin: '0',
                    maxWidth: '100%',
                    maxHeight: '-webkit-fill-available',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1vw',
                }}
            >
                <Box className='temp-box' />
                <Box component='div' className='results-wrapper'>
                    <Box component='div' className='results-options'>
                        <Paper square>
                            <Box component='div' className='results-options-top'>
                                <FormControl
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: '3rem',
                                        alignItems: 'stretch',

                                    }}
                                >
                                    <SPAChartNavigation product={product} defaultBaseIndex={2} defaultResultIndex={3} />
                                    <SDGLinkage
                                        dimension='SPA'
                                        setting='C4'
                                        charttype='Table'
                                    />
                                    <ProductComparison />
                                </FormControl>
                            </Box>
                        </Paper>

                    </Box>
                </Box>
                <Box className={classes.chartSec}
                    sx={{
                        gridTemplateColumns: doubleGraph ? '49.5% 49.5%' : '1fr',
                    }}
                >
                    <Paper
                        square
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '-webkit-fill-available',
                            height: '-webkit-fill-available',
                        }}
                    >
                        <Outlet 
                            doubleGraph={doubleGraph}
                        />
                        {/* <C4SunburstSPA
                            product = {product}
                        /> */}

                    </Paper>
                    {/* {results && results.stakeholderResults.length > 0 &&    */}
                    {doubleGraph && 
                    <Paper
                        square
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '-webkit-fill-available',
                            hewight: '-webkit-fill-available',
                        }}
                    >

                        {<C4Bar2SPA product={product} />}
                    </Paper>}
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default B4SPACharts;
