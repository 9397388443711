// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.webinar-videos{
    display: flex;
    margin: 2rem;
    justify-content: center;
    gap: 20px;
}
.webinar-video {
    box-shadow: 0px 3px 6px rgb(0 0 0 / 50%);
    padding: 1.5rem;
    padding-bottom: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/website/components/PastWebinarVideos.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;IACZ,uBAAuB;IACvB,SAAS;AACb;AACA;IACI,wCAAwC;IACxC,eAAe;IACf,oBAAoB;AACxB","sourcesContent":[".webinar-videos{\r\n    display: flex;\r\n    margin: 2rem;\r\n    justify-content: center;\r\n    gap: 20px;\r\n}\r\n.webinar-video {\r\n    box-shadow: 0px 3px 6px rgb(0 0 0 / 50%);\r\n    padding: 1.5rem;\r\n    padding-bottom: 1rem;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
