import React, { useEffect, useMemo, useState } from "react";
import {
    Container,
    Box,
    Paper,
    Typography,
    Stack,
    Button,
    FormControl,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import GeneralTheme from "../../style/GeneralTheme";
import { ThemeProvider } from "@emotion/react";
import ProductNameDescForm from "./components/ProductNameDescForm";
import DimensionCheckboxes from "./components/DimensionCheckboxes";
import LifeCycleStagesDropdowns from "./components/LifeCycleStagesDropdowns";
import { useNavigate, useRouterState } from "@tanstack/react-router";
import { fetchUnitGroups, sendRequest } from "../../../helper/fetchUtil";
import { UnitGroup } from "../../../interface/Unit";
import { useForm, FormApi } from "@tanstack/react-form";
import { useFormContext } from "../../forms/FormContext";
import {
    EnvFlowInputLCS,
    EnvProcessInputLCS,
    LifeCycleStageForm,
    ProductDetailsForm,
    SocialInputsLCS,
    defaultLCSFormValues,
} from "../../../interface/Form";
import Dropdown from "../../dropdowns/Dropdown";
import CreateProductHeader from "../../results/components/createproductheader";
import { CreateProductNavButtons } from "../components/CreateProductNavButtons";
import { Product } from "../../../interface/Product";
import { useLocation } from "react-router-dom";

interface ProjectDetailsProps {
    editProductId?: string;
}
interface ProductResponse {
    product: Product;
}

function ProjectDetailsInputs({ editProductId }: ProjectDetailsProps) {
    const navigate = useNavigate();
    const { formState, setFormState } = useFormContext();

    const getSelectedLCS = (value: ProductDetailsForm): string[] => {
        const lcsList: string[] = [];
        (Object.keys(value) as (keyof ProductDetailsForm)[]).forEach((key) => {
            if (key.includes(":") && value[key] === true) {
                lcsList.push(key as string);
            }
        });
        return lcsList;
    };

    const [productToEdit, setProductToEdit] = useState<Product>();

    const form: FormApi<ProductDetailsForm> = useForm({
        defaultValues: {
            productName: formState.productName || productToEdit?.productName || "",
            productDesc: formState.productDesc || productToEdit?.productDesc || "",
            analysisUnit: formState.analysisUnit || productToEdit?.unitOfAnalysis.unit || "",
            analysisUnitGroup: formState.analysisUnitGroup || productToEdit?.unitOfAnalysis.unitGroup || "",
            analysisUnitQTY: formState.analysisUnitQTY || productToEdit?.unitOfAnalysis.unitQTY || 1,
            envDim: false,
            socialDim: false,
            ecoDim: false,
            ...defaultLCSFormValues,
            currentLCS: "Product Stage",
            currentLCSS: "Raw Material Supply",
        } as ProductDetailsForm,
        onSubmit: ({ value }) => {
            setFormState({
                ...formState,
                selectedLCS: getSelectedLCS(value),
                envDim: value.envDim,
                socialDim: value.socialDim,
                ecoDim: value.ecoDim,
                productName: value.productName,
                productDesc: value.productDesc,
                analysisUnit: value.analysisUnit,
                analysisUnitGroup: value.analysisUnitGroup,
                analysisUnitQTY: value.analysisUnitQTY,
            });
        },
    });

    const fetchProduct = async (productId: string): Promise<ProductResponse> => {
        const response: ProductResponse = await sendRequest<ProductResponse>(
            `${process.env.REACT_APP_BACKEND_URL}/product/${productId}`,
            "GET",
            undefined,
            { "Content-Type": "application/json" }
        );
        return response;
    };

    const populateFieldsWithProductData = (product: Product) => {
        setProductToEdit(product);
        const envInputEmissions: EnvFlowInputLCS[] = [];
        const envInputProcesses: EnvProcessInputLCS[] = [];
        const envOutputEmissions: EnvFlowInputLCS[] = [];
        const envOutputWaste: EnvFlowInputLCS[] = [];
        const socialInputProcesses: SocialInputsLCS[] = [];

        product.lifeCycleStages.forEach((lcs) => {
            if (product.dimensions.environmental) {
                envInputProcesses.push({
                    lcs: lcs.stage,
                    values: lcs.value.environmental.inputs.processes,
                });
                envInputEmissions.push({
                    lcs: lcs.stage,
                    values: lcs.value.environmental.inputs.emissionFlows,
                });
                envOutputEmissions.push({
                    lcs: lcs.stage,
                    values: lcs.value.environmental.outputs.emissionFlows,
                });
                envOutputWaste.push({
                    lcs: lcs.stage,
                    values: lcs.value.environmental.outputs.wasteFlows,
                });
            }
            if (product.dimensions.social) {
                socialInputProcesses.push({
                    lcs: lcs.stage,
                    values: lcs.value.social.inputs,
                });
            }
            // const key = lcs.stage as keyof LifeCycleStageForm;

            // form.state.values[key] = true;
        });
        setFormState({
            analysisUnit: product.unitOfAnalysis.unit,
            analysisUnitGroup: product.unitOfAnalysis.unitGroup,
            analysisUnitQTY: product.unitOfAnalysis.unitQTY,
            envDim: product.dimensions.environmental,
            socialDim: product.dimensions.social,
            ecoDim: product.dimensions.economic,
            envInputEmissions,
            envInputProcesses,
            envOutputEmissions,
            envOutputWaste,
            envAssessmentMethod: product.environmentalData?.assessmentMethod.id,
            envAssessmentMethodName: product.environmentalData?.assessmentMethod.name,
            envNWSet: product.environmentalData?.nwSet,
            productDesc: product.productDesc,
            productName: product.productName,
            selectedLCS: product.lifeCycleStages.map((lcs) => lcs.stage),
            socialAssessmentMethod: product.socialData?.data?.type,
            socialInputProcesses,
            socialSPAWeighingMethod: product.socialData?.data?.weighingMethod,
            socialSPAWeighingSet: product.socialData?.data?.weighingSet,
            productToEdit: product,
        });
        product.lifeCycleStages.forEach((lcs) => {
            const key = lcs.stage as keyof LifeCycleStageForm;
            (form.state.values[key] as boolean) = true;
        });
    };

    //Fill with edit data if: Loading details for the first time and switching products
    useEffect(() => {
        if((editProductId && Object.keys(formState).length === 0) ){
            fetchProduct(editProductId).then((response) => {
                populateFieldsWithProductData(response.product);
                setProductToEdit(response.product);
            });
        }

    }, []);


    // useEffect(() => {
    //     let product: Product;
    //     if (!formState.productToEdit && editProductId) {
    //         fetchProduct(editProductId).then((response) => {
    //             populateFieldsWithProductData(response.product);
    //         });
    //     }else if (formState.productToEdit){
    //         if(editProductId != formState.productToEdit.id){
    //             fetchProduct(editProductId).then((response) => {
    //                 populateFieldsWithProductData(response.product);
    //                 setProductToEdit(formState.productToEdit);

    //             });
    //         }else{

    //         }
    //     }

    //     if ()
    //         if (editProductId != formState.productToEdit?.id) {
    //             if (editProductId && formState.productToEdit) {
    //                 fetchProduct(editProductId).then((response) => {
    //                     populateFieldsWithProductData(response.product);
    //                 });
    //             }
    //         } else {
    //             if (formState.productToEdit) {
    //                 setProductToEdit(formState.productToEdit);
    //             }
    //         }
    // }, [editProductId, formState.productToEdit]);

    return (
        <ThemeProvider theme={GeneralTheme}>
            {/* <button
                type="button"
                onClick={() => {
                    console.log(formState);
                    console.log(form.state.values);
                }}
                style={{
                    position: "absolute",
                    top: "4rem",
                    left: 0,
                }}
            >
                PRINT CONTEXT
            </button> */}

            <CreateProductHeader formState={formState} currentPage="details" />

            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    form
                        .handleSubmit()
                        .then(() => { })
                        .catch(() => { });
                }}
            >
                <Container
                    className="projectDetails-modal-content"
                    maxWidth={false}
                    sx={{
                        maxWidth: "100%",
                        // height: "83vh",
                        padding: "1rem",
                    }}
                >
                    <form.Subscribe
                        selector={(state) => {
                            return [state.canSubmit, state.isSubmitting];
                        }}
                        children={([canSubmit, isSubmitting]) => {
                            return (
                                <button
                                    type="submit"
                                    disabled={!canSubmit}
                                    style={{
                                        position: "absolute",
                                        top: "6rem",
                                        left: 0,
                                    }}
                                >
                                    {isSubmitting ? "..." : "Submit"}
                                </button>
                            );
                        }}
                    />

                    <FormControl
                        sx={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr",
                            gap: "1rem",
                            height: "auto",
                            textAlign: "left",
                            // "@media (max-width: 1680px)": {
                            //     gridTemplateColumns: "1fr",
                            //     gridTemplateRows: "1fr 1fr",
                            // },
                        }}
                    >
                        <Box
                            className="create-inputs-box project-details-box left"
                            sx={{
                                display: "grid",
                                gridTemplateRows: "auto auto",
                                gap: "1rem",
                                height: "-webkit-fill-available",
                                // "@media (max-width: 1680px)": {
                                //     gridTemplateColumns: "1fr 1fr",
                                //     gridTemplateRows: "1fr",
                                // },
                            }}
                        >
                            <Paper square className="project-details-box">
                                <ProductNameDescForm
                                    form={form}
                                    productToEdit={productToEdit}
                                />
                            </Paper>
                            <Paper
                                square className="project-details-box"
                                sx={{
                                    height: "auto",
                                    "@media (max-width: 1680px)": {
                                        height: "-webkit-fill-available",
                                    },
                                }}
                            >
                                <DimensionCheckboxes
                                    form={form}
                                    productToEdit={productToEdit}
                                />
                            </Paper>
                        </Box>
                        <Box
                            className="create-inputs-box project-details-box right"
                            sx={{ height: "-webkit-fill-available", display: "flex" }}
                        >
                            <Paper
                                square className="project-details-box"
                                sx={{
                                    height: "-webkit-fill-available",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                }}
                            >
                                <LifeCycleStagesDropdowns
                                    form={form}
                                    productToEdit={productToEdit}
                                />
                                <CreateProductNavButtons
                                    form={form}
                                    formState={formState}
                                    currentPage="details"
                                />
                            </Paper>
                        </Box>
                    </FormControl>
                </Container>
            </form>
        </ThemeProvider>
    );
}

export default ProjectDetailsInputs;
