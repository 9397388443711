import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => {return {
    chartSec: {
        width: '-webkit-fill-available',
        height: '63vh',
    },
    treemapLegendWrap: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
        width: '-webkit-fill-available',
        gap: '2vw',
    },
    treemapLegendRow: {
        display: 'flex',
        gap: '0.5vw',
        fontSize: '0.7vw',
        alignItems: 'center',
    },
    treemapLegendFont: {
        fontSize: '0.7vw',
    },
    chartWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1vw',
        height: 'auto',
        justifyContent: 'center',
    },
}});

export default useStyles;
