import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';
import useStyles from './D3BarPSILCA.styles';
import { ThemeProvider } from '@emotion/react';
import Dropdown from '../../../../../dropdowns/Dropdown';
import deepClone from '../../../../../../helper/deepClone';
import { ChartJSData, ChartJSOptions } from '../../../../../../interface/ChartData';
import { Product, PSILCAResult } from '../../../../../../interface/Product';
import PSILCAChartNavigation from '../../../../../dropdowns/BaseOptions/PSILCAChartNavigation';
import ResultsTheme from '../../../../style/ResultsTheme';
import { initialOptions } from '../../../../options/InitialOptionValues';
import ChartDownload from '../../../../options/components/ChartDownload';
import ProductComparison from '../../../../options/components/Comparison';
import GraphSetting from '../../../../options/components/GraphSettings';
import { CreateChartTS } from '../../../../util/CreateChartTS';
import { useFormContext } from '../../../../../forms/FormContext';



interface ChartData {
    product: Product;
}

function D3BarPSILCA(props: ChartData) {
    const classes = useStyles();
    const { formState, setFormState } = useFormContext();
    const { product } = props;
    const [chartJSData, setChartJSData] = useState<ChartJSData>({ datasets: [], labels: [] });
    const [chartOptions, setChartOptions] = useState<ChartJSOptions>(initialOptions);
    const [colorIdentifiers, setColorIdentifiers] = useState<ChartColorIdentifiers[]>([]);
    const [selectedStakeholder, setSelectedStakeholder] = useState<string>('');
    const [stakeholderOptions, setStakeholderOptions] = useState<string[]>([]);
    const [selectedFilterOption, setSelectedFilterOption] = useState<string>('');
    const [lifeCycleStagesList, setLifeCycleStagesList] = useState<string[]>([]); 

    const [results, setResults] = useState<PSILCAResult[]>([]);

    useMemo(() => {
        const calc = product.calculations.find(
            (item) => {return item.calculationType === 'SOCIAL-PSILCA'},
        );
        const calcResults = calc?.calculationResults as PSILCAResult[];

        setResults((calcResults));
        setStakeholderOptions(['All Stakeholders'].concat(calcResults.map((item) => {return item.stakeholder})));
    }, [product]);







    useEffect(() => {
        const chartData: ChartJSData = { datasets: [], labels: [] };
        const options: ChartJSOptions = deepClone<ChartJSOptions>(initialOptions);
        const chartColorIdentifiers : ChartColorIdentifiers[] = [];

        if (options.scales && options.scales.y && options.scales.y.title) {
            options.scales.y.title.text = "Medium Risk Hours";
        }
        
        let stakeholders: PSILCAResult[] = [];
        if (selectedStakeholder === 'All Stakeholders') {
            stakeholders = results;
        } else if (selectedStakeholder) {
            const matchingStakeholder = results.find(
                (item) => {return item.stakeholder === selectedStakeholder},
            );
            if (matchingStakeholder) {
                stakeholders = [matchingStakeholder];
            }
        }

        const lifeCycleStages = results[0].subcategories[0].indicators[0].indicatorResults.reduce(
            (stages: string[], lcss) => {
                if (!stages.find((item) => {return item === lcss.lcsName})) {
                    stages.push(lcss.lcsName);
                }
                return stages;
            },
            [],
        );

        chartData.datasets = lifeCycleStages.map((lcs, index) => {
            let data: { label: string, value: number }[] = [];

            stakeholders.forEach((stakeholder) => {
                return stakeholder.subcategories.forEach((subcategory) => {
                    return subcategory.indicators.forEach((indicator) => {
                        const matchingLCS = indicator.indicatorResults.find(
                            (lcs2) => {return lcs2.lcsName === lcs},
                        );
                        if (matchingLCS) {
                            matchingLCS.processResults.processes.forEach(
                                (process) => {
                                    process.flowResults.forEach((item) => {
                                        const flow = data.find(
                                            (item2) => {return item2.label === item.flowName},
                                        );
                                        if (flow) {
                                            flow.value += item.sum;
                                        } else {
                                            const matchingData = data.find(
                                                (item2) => {return item2.label === item.flowName},
                                            );
                                            if (matchingData) {
                                                matchingData.value += item.sum;
                                            } else {
                                                data.push({
                                                    label: item.flowName,
                                                    value: item.sum,
                                                });
                                            }
                                        }
                                    });
                                },
                            );
                        }
                    })})});
            let max = 0;
            data.forEach((item) => {
                if (item.value > max) {
                    max = item.value;
                }
            });
            let percentVal : number;
            switch (selectedFilterOption) {
            case '0.1% of Maximum Value':
                percentVal = 0.1;
                break;
            case '0.2% of Maximum Value':
                percentVal = 0.2;
                break;
            case '1% of Maximum Value':
                percentVal = 1;
        
                break;
            case '3% of Maximum Value':
                percentVal = 3;
        
                break;
            case '5% of Maximum Value':
                percentVal = 5;
        
                break;
        
            default:
                percentVal = 1;
            }
        
            data = data.filter((item) => {return (item.value / max) * 100 > percentVal});

            data.forEach((item) => {
                if (
                    !chartData.labels.find((item2) => {return item2 === item.label})
                ) {
                    chartData.labels.push(item.label);
                }
            });
            chartColorIdentifiers.push({label:lcs});

            return {
                label: lcs,
                data: data.map((item) => {return item.value}),
                //backgroundColor: extendedPalette[index],
                barPercentage: 1,
                categoryPercentage: 0.85,
                // backgroundColor: currentPaletteSubLifeCycle[lcsIndex], //colors[0], //random color from array
                // borderColor: "000000",
            };
        });
        setLifeCycleStagesList(lifeCycleStages);

        setChartJSData(chartData);
        setChartOptions(options);
        setColorIdentifiers(chartColorIdentifiers);    
    }, [selectedFilterOption, selectedStakeholder]);

    const [applyGraphSettings, setApplyGraphSettings] =
        useState<(options: ChartJSOptions, chartData: ChartJSData, colorIdentifiers?: ChartColorIdentifiers[]) => 
            { options: ChartJSOptions; chartData: ChartJSData; }>();
    const triggerChartUpdate = useCallback(() => {
        let options = deepClone(chartOptions) || {};
        let chartData = deepClone(chartJSData);
        if(applyGraphSettings){
            ({ options, chartData } = applyGraphSettings(options, chartData, colorIdentifiers));
        }        
        CreateChartTS(chartData, options);
    }, [chartJSData, chartOptions, applyGraphSettings]);
    // useEffect(() => {
    //     const delayUpdate = setTimeout(() => {
    //         if (chartJSData && chartOptions) {
    //             triggerChartUpdate();
    //         }
    //     }, 300);
    //     return () => clearTimeout(delayUpdate);
    // }, [chartJSData, chartOptions]);
    
    useEffect(() => {
        if (chartJSData && chartOptions) {
            triggerChartUpdate();
        }
    }, [chartJSData, chartOptions, applyGraphSettings, colorIdentifiers]);

    return (
        <ThemeProvider theme={ResultsTheme}>
            <Box
                sx={{
                    margin: '0',
                    maxWidth: '100%',
                    maxHeight: '-webkit-fill-available',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1vw',
                }}
            >
                <Box className='temp-box' />
                <Box component='div' className='results-wrapper'>
                    <Box component='div' className='results-options'>
                        <Paper square>
                            <Box component='div' className='results-options-top'>
                                <FormControl
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: '3rem',
                                        alignItems: 'stretch',

                                    }}
                                >
                                    <PSILCAChartNavigation product={product} defaultBaseIndex={3} defaultResultIndex={2} />
                                    <ProductComparison />
                                </FormControl>
                            </Box>
                        </Paper>
                        <Divider />
                        <Paper square>
                            <Box component='div' className='results-options-bottom'>
                                <Box>
                                    <FormControl
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}
                                        className='bottom-options side-left'
                                    >

                                        <Dropdown
                                            options={stakeholderOptions}
                                            valueSetter={setSelectedStakeholder}
                                            value={selectedStakeholder}

                                        />
                                        <Dropdown
                                            options={['5% of Maximum Value', '3% of Maximum Value', '1% of Maximum Value', 
                                                '0.5% of Maximum Value', '0.1% of Maximum Value']}
                                            value={selectedFilterOption}
                                            valueSetter={setSelectedFilterOption}
                                        />

                                    </FormControl>
                                </Box>
                                <Box>
                                    <FormControl
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            '@media (max-width: 1680px)': {
                                                gap: '1vw',
                                            },
                                        }}
                                        className='bottom-options side-right'
                                    >
                                        <ChartDownload chartData = {chartJSData} 
                                            chartName={'Indicator level result - Sublifecycle'} chartType='Bar Graph' />
                                        <GraphSetting 
                                            chartOptions={chartOptions} 
                                            setApplyGraphSettings={setApplyGraphSettings} 
                                            triggerChartUpdate={triggerChartUpdate}
                                            colorIdentifiers={colorIdentifiers}
                                            chartData = {chartJSData}
                                            chartColorType = 'lcss'
                                            graphType='barlcs'
                                            // chartLabels={chartJSData.labels}
                                            // lifecycleStagesNo={lifeCycleStagesList.length}
                                        />

                                    </FormControl>
                                </Box>
                            </Box>
                        </Paper>
                    </Box>
                </Box>
                <Box className={classes.chartSec}>
                    <Paper
                        square
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <Box className={classes.chartSec}>
                            <canvas id='graph1Canvas' className='graph-canvas' />
                        </Box>
                    </Paper>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default D3BarPSILCA;
