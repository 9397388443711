import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { useState, useMemo, useEffect, useCallback } from 'react';
import deepClone from '../../../../../../helper/deepClone';
import { ChartJSData, ChartJSOptions, ChartDataSet } from '../../../../../../interface/ChartData';
import { Product, EnvironmentalResult, EnvResultSubstage } from '../../../../../../interface/Product';
import Dropdown from '../../../../../dropdowns/Dropdown';
import LCSDropdowns from '../../../../../dropdowns/LCSDropdowns';
import { initialOptions } from '../../../../options/InitialOptionValues';
import { applyValueType } from '../../../../options/util/applyOptions';
import { CreateChartTS } from '../../../../util/CreateChartTS';
import useStyles from './B4BarChart.styles';
import ChartDownload from '../../../../options/components/ChartDownload';
import GraphSetting from '../../../../options/components/GraphSettings';
import ResultsTheme from '../../../../style/ResultsTheme';
import EnvChartNavigation from '../../../../../dropdowns/BaseOptions/EnvChartNavigation';
import { useFormContext } from '../../../../../forms/FormContext';
import { ChartColorIdentifiers } from '../../../../options/components/GraphSettingsOptions';
import ImpactCategoryDropdown from '../../../../../dropdowns/ImpactCategoryDropdown';



interface ChartData {
    product: Product
}

export 

function B4BarChart(props: ChartData) {
    const { formState, setFormState } = useFormContext();
    const classes = useStyles();
    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
    const { product } = props;
    const [chartJSData, setChartJSData] = useState<ChartJSData>({ datasets: [], labels: [] });
    const [chartOptions, setChartOptions] = useState<ChartJSOptions>(initialOptions);
    const [colorIdentifiers, setColorIdentifiers] = useState<ChartColorIdentifiers[]>([]);
    const [selectedValueType, setSelectedValueType] = useState<string>('');
    const [selectedLCS, setSelectedLCS] = useState<string>('');
    const [selectedLCSS, setSelectedLCSS] = useState<string>('');
    const [results, setResults] = useState<EnvironmentalResult[]>([]);

    useMemo(() => {
        const calc = product.calculations.find(
            (item) => item.calculationType === 'ENVIRONMENTAL'
        );
        const calcResults = calc?.calculationResults as EnvironmentalResult[];
        setResults(calcResults);
    }, [product]);








    useMemo(() => {
        let unit: string;
        const chartData: ChartJSData = { datasets: [], labels: [] };
        const options: ChartJSOptions = deepClone<ChartJSOptions>(initialOptions);
        const chartColorIdentifiers : ChartColorIdentifiers[] = [];

        if (results.length > 0 && selectedLCS && selectedLCSS) {
            const lifeCycleStage = results.find(
                (item) => {return item.name === selectedLCS},
            );
            let lifeCycleSubstage: EnvResultSubstage | undefined;

            if (lifeCycleStage) {
                lifeCycleSubstage = lifeCycleStage.subStages.find(
                    (lcss) => {return lcss.name === selectedLCSS},
                );
            }
            
            if (lifeCycleSubstage && lifeCycleSubstage.results) {
                //                const totalProcesses = lifeCycleSubstage.results[0].values.length;

                const datasets = lifeCycleSubstage.results[0].values
                    .sort((a, b) => a.processName.localeCompare(b.processName))
                    .map((process, index) => {
                    
                        chartColorIdentifiers.push({label:process.endProductName});
                
                        return {
                            label: process.endProductName,
                            //backgroundColor: extendedPalette[colorIndex],
                            data: selectedCategories.map((category) => {
                                const matchingCategory = lifeCycleSubstage?.results.find(
                                    (item) => {return item.impactCategory.name === category},
                                );
                                if (matchingCategory) {
                                    unit = matchingCategory.impactCategory.refUnit;
                                    const matchingProcess = matchingCategory.values.find(
                                        (item) => {return item.processName === process.processName},
                                    );
                                    if (matchingProcess) {
                                        if (selectedValueType === 'Relative Contribution') {
                                            if(options?.scales?.y?.title?.text){
                                                options.scales.y.title.text = "%";

                                            }
                                            const processes = matchingCategory.values;
                                            const maxValue = processes.reduce((max, item) => {
                                                if (item.value > max) {
                                                    max = item.value;
                                                }
                                                return max;
                                            }, 0);
                                            return (matchingProcess.value / maxValue) * 100;
                                        }
                                        return matchingProcess.value;
                                    }
                                }
                                return 0;
                            }),
                        };
                    });
                chartData.datasets = datasets;
            }
        }
        chartData.labels = selectedCategories;
        console.log(chartData);
        setChartJSData(chartData);
        setChartOptions(options);
        setColorIdentifiers(chartColorIdentifiers);    
    }, [selectedCategories, selectedLCS, selectedLCSS, selectedValueType,  results]);

    const [applyGraphSettings, setApplyGraphSettings] =
        useState<(options: ChartJSOptions, chartData: ChartJSData, colorIdentifiers?: ChartColorIdentifiers[]) =>
            { options: ChartJSOptions; chartData: ChartJSData; }>(); 
    const triggerChartUpdate = () => {
        let options = deepClone(chartOptions) || {};
        let chartData = deepClone(chartJSData);
        const applyOptionReturn = applyValueType(selectedValueType, chartData, options);
        options = applyOptionReturn.options;
        chartData = applyOptionReturn.chartData;
        if (applyGraphSettings) {
            ({ options, chartData } = applyGraphSettings(options, chartData, colorIdentifiers));
        }
        CreateChartTS(chartData, options);
    };

    useEffect(() => {
        if (chartJSData && chartOptions) {
            triggerChartUpdate();
        }
    }, [
        chartJSData,
        chartOptions,
        selectedValueType,
        // graphColors,
        selectedCategories,
        selectedLCS,
        selectedLCSS,
        
        results,
        
    ]);

    return (
        <ThemeProvider theme={ResultsTheme}>
            <Box
                sx={{
                    margin: '0',
                    maxWidth: '100%',
                    maxHeight: '-webkit-fill-available',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1vw',
                }}
            >
                <Box component='div' className='results-wrapper'>
                    <Box component='div' className='results-options'>
                        <Paper square>
                            <Box component='div' className='results-options-top'>
                                <FormControl
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                    }}
                                />
                                <EnvChartNavigation
                                    product={product}
                                    defaultBaseIndex={1}
                                    defaultResultIndex={3}
                                />
                            </Box>
                        </Paper>
                        <Divider />
                        <Paper square>
                            <Box component='div' className='results-options-bottom'>
                                <Box>
                                    <FormControl
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}
                                        className='bottom-options side-left'
                                    >
                                        <LCSDropdowns
                                            product={product}
                                            selectedLCS={selectedLCS}
                                            setSelectedLCS={setSelectedLCS}
                                            selectedLCSS={selectedLCSS}
                                            setSelectedLCSS={setSelectedLCSS}
                                            dimension="Environmental"
                                        />
                                        <ImpactCategoryDropdown
                                            product={product}
                                            selectedCategories={selectedCategories}
                                            setSelectedCategories={setSelectedCategories}
                                            defaultBaseIndex={1}
                                            defaultResultIndex={3}
                                        />
                                    </FormControl>
                                </Box>
                                <Box>
                                    <FormControl
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            '@media (max-width: 1680px)': {
                                                gap: '1vw',
                                            },
                                        }}
                                        className='bottom-options side-right'
                                    >
                                        <ChartDownload chartData = {chartJSData} 
                                            chartName={'Total weighted result: Damage type level - Process'} chartType='Bar Graph' />
                                        <GraphSetting
                                            chartOptions={chartOptions}
                                            setApplyGraphSettings={setApplyGraphSettings}
                                            triggerChartUpdate={triggerChartUpdate}
                                            colorIdentifiers={colorIdentifiers}
                                            chartData={chartJSData}
                                            chartColorType='process'
                                            // noColors
                                            graphType='barindicator'  
                                        />
                                    </FormControl>
                                </Box>
                            </Box>
                        </Paper>
                    </Box>
                </Box>
                <Box className={classes.chartSec}>
                    <Paper
                        square
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <Box className={classes.chartSec}>
                            <canvas id='graph1Canvas' className='graph-canvas' />
                        </Box>
                    </Paper>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default B4BarChart;
