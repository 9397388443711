/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Box,
    Button,
    styled,
    ThemeProvider,
    tableCellClasses,
    Typography,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import { Unstable_Popup as Popup } from '@mui/base/Unstable_Popup';
import ExitButton from '../../../../../style/components/ExitButton';
import AddButton from '../../../../../style/components/AddButton';
import TableTheme from '../../../../../style/components/TableTheme';
import Popper, { PopperProps } from '@mui/material/Popper';
import SPAIndicatorsTable from '../SPAIndicatorsTable';
import { StakeholderIndicators, StakeholderSubcategory, getStakeholderFormValues } from '../../../../../../interface/Stakeholder';
import { FormApi, useForm } from '@tanstack/react-form';
import Dropdown from '../../../../../dropdowns/Dropdown';
import useStyles from './SPAStakeholders.styles';
import { FormState, useFormContext } from '../../../../../forms/FormContext';




function SPAStakeholders() {
    const classes = useStyles();
    const [selectedStakeholderForm, setSelectedStakeholderForm] = useState<string>('');

    const { formState, setFormState } = useFormContext();
    const [selectedStakeholders, setSelectedStakeholders] = useState<string[]>(formState.selectedStakeholders || []);
    const form = useForm<StakeholderIndicators>({
        defaultValues: getStakeholderFormValues(formState),
        onSubmit: ({ value }) => {
            console.log(formState.SPAIndicators);

            // Handle form submission logic here
            // setFormState({
            //     SPAIndicators: value,
            //     selectedStakeholders: selectedStakeholders,
            // })
        },
    });


    const selectedStakeholderDropdownForm = useForm<{ selectedStakeholder: string }>({
        defaultValues: {
            selectedStakeholder: "Consumers",
        },
        onSubmit: ({ value }) => {

        },
    });
    const [processPopupSPA, setProcessPopupEnv] = useState<null | HTMLElement>(
        null,
    );

    const open = Boolean(processPopupSPA);
    const processPopperSPA = open ? 'process-popper' : undefined;


    const addStakeholderHandler = () => {

        if (selectedStakeholderDropdownForm.state.values.selectedStakeholder)
            setSelectedStakeholders(selectedStakeholders.concat(selectedStakeholderDropdownForm.state.values.selectedStakeholder));
    }

    const deleteStakeholderHandler = ((deleteIndex: number) => {
        setSelectedStakeholders(selectedStakeholders.filter(((item, index) => index != deleteIndex)))
    })
    const handleIndicatorPopupClick = (event: React.MouseEvent<HTMLElement>, stakeholder?: string) => {
        setProcessPopupEnv(processPopupSPA ? null : event.currentTarget);
        if (stakeholder) {
            setSelectedStakeholderForm(stakeholder)

        }
    };


    return (
        <ThemeProvider theme={TableTheme}>
            <button onClick={() => { console.log(formState) }}>STAKEHOLDERS</button>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1vw',
                    alignItems: 'flex-start',
                }}
            >
                <Typography component='h3' variant='h3'>
                    Input Data
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '1vw',
                    }}
                >
                    <selectedStakeholderDropdownForm.Field
                        name="selectedStakeholder"
                        children={(field) => {
                            return (
                                <Dropdown
                                    options={[
                                        'Consumers',
                                        'Workers',
                                        'Local Community',
                                        'Value Chain Actors',
                                        'Society',
                                        'Children',
                                    ]} onChange={(e) => {
                                        field.handleChange(e.target.value);
                                        // form.handleSubmit();
                                    }}
                                    id={field.name}
                                    className={field.name}
                                    value={field.state.value}
                                    onBlur={field.handleBlur}
                                    noDefaults
                                    label=""
                                />
                            );
                        }}
                    />
                    <button onClick={addStakeholderHandler} className='spa-button'>Add Stakeholder</button>
                    <button onClick={(() => {
                        console.log(form.state.values);
                    })} className='spa-button'>TEST</button>
                </Box>
            </Box>
            <Box>
                <TableContainer
                    sx={{
                        height: 'auto',
                    }}
                >
                    <Box
                        sx={{
                            width: 'auto',
                        }}
                    >
                        <Table>
                            <TableHead
                                sx={{
                                    // display: 'flex',
                                    // width: '-webkit-fill-available',
                                    // padding: 0,
                                }}
                            >
                                <TableRow
                                    sx={{

                                        padding: 0,
                                    }}
                                >
                                    <TableCell className={classes.InputTableCell} align='center' sx={{ width: '22vw' }}>
                                        Stakeholder Name
                                    </TableCell>
                                    <TableCell className={classes.InputTableCell} align='center' sx={{ width: '22vw' }}>
                                        Indicators
                                    </TableCell>
                                    <TableCell className={classes.InputTableCell} align='center' sx={{ width: '3vw !important' }}>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                        </Table>
                    </Box>
                    {/* Scrollable TableBody */}
                    <Box
                        sx={{
                            maxHeight: '10vw',  // Set the height limit for body scrolling
                            overflowY: 'auto',   // Enable vertical scrolling
                        }}
                    >
                        <Table>
                            {selectedStakeholders.map((stakeholder, index) => (
                                <TableBody key={index}
                                    sx={{
                                        display: 'flex',
                                    }}
                                >
                                    <TableRow className={classes.InputTableRow} sx={{ padding: '0.2vw !important' }}>
                                        <TableCell className={classes.InputTableCell} align='center' sx={{ width: '22vw' }}>
                                            {stakeholder}
                                        </TableCell>
                                        <TableCell className={classes.InputTableCell} align='center' sx={{ width: '22vw' }}>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    gap: '1vw',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',  // Ensures vertical alignment
                                                    padding: '2px',  // Consistent padding
                                                }}
                                            >
                                                <ThemeProvider theme={AddButton}>
                                                    <Button onClick={(event: React.MouseEvent<HTMLElement>) => {
                                                        handleIndicatorPopupClick(event, stakeholder);
                                                    }}>
                                                        <Typography component='body' variant='body1' className={classes.setIndicatorBt}>
                                                            Set Indicators
                                                        </Typography>
                                                        <EditIcon sx={{ color: '#707070' }} />
                                                    </Button>
                                                </ThemeProvider>
                                            </Box>

                                        </TableCell>
                                        <TableCell className={classes.InputTableCell} align='center' sx={{ width: '3vw !important' }}>
                                            <ThemeProvider theme={ExitButton}>
                                                <Button className={classes.SmallerDeleteButton} onClick={(() => {
                                                    deleteStakeholderHandler(index);
                                                })}>
                                                    <ClearIcon sx={{ fontSize: '0.7vw' }} />
                                                </Button>
                                            </ThemeProvider>
                                        </TableCell>

                                    </TableRow>
                                </TableBody>
                            ))}
                        </Table>
                    </Box>
                </TableContainer>
                <Popper
                    id={processPopperSPA}
                    open={open}
                    anchorEl={processPopupSPA}
                    disablePortal
                    style={{
                        position: 'fixed',
                        transform: 'none !important',
                    }}
                >
                    <Box
                        sx={{
                            position: 'fixed !important',
                            transform: 'none !important',
                            top: '0 !important',
                            left: '0 !important',
                            zIndex: 1300,
                            width: '100%',
                            height: '-webkit-fill-available',
                            backgroundColor: 'rgba(0, 0, 0, 0.54)',
                        }}
                    >
                        <SPAIndicatorsTable
                            handleIndicatorPopupClick={handleIndicatorPopupClick}
                            form={form}
                            selectedStakeholderForm={selectedStakeholderForm}
                        />
                    </Box>
                </Popper>
            </Box>

        </ThemeProvider>
    );
}

export default SPAStakeholders;

