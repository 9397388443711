import React from "react";
import Box from "@mui/material/Box";
import FormGroup from "@mui/material/FormGroup";
import {
    ListItem,
    ListItemButton,
    ListItemText,
    Typography,
    ListItemIcon,
    Divider,
    Collapse,
    Checkbox,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import useStyles from "./LCSDropdownTheme.styles";
import boxWrapperStyle from "./LCSDropdownWrapperTheme.styles";

interface LCSDropdownComponent {
  stage: string;
  index: number;
  handleToggleDropdown: (index: number) => void;
  openDropdownStages: number[];
  children: React.ReactNode;
  selectStageAllChecked: boolean;
  handleSelectStageAllChange: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
}

function LCSDropdown({
    children,
    stage,
    index,
    handleToggleDropdown,
    openDropdownStages,
    selectStageAllChecked,
    handleSelectStageAllChange,
    
}: LCSDropdownComponent) {
    const classes = useStyles();
    const boxWrapperClasses = boxWrapperStyle();

    return (
        <Box className="lc-stage-dropdown__content">
            <FormGroup>
                <React.Fragment key={index}>
                    <ListItem
                        sx={{
                            display: "grid",
                            gridTemplateRows: "auto 1rem auto",
                            width: "15vw",
                            alignItems: "start",
                            padding: "0",
                        }}
                    >
                        <div className="lcs-dropdown-title-wrap">
                            <Checkbox
                                checked={selectStageAllChecked}
                                onChange={handleSelectStageAllChange}
                                sx={{
                                    fontSize: "1.2vw",
                                    "&MuiSvgIcon-root": {
                                        fontSize: "1.2vw",
                                    },
                                }}
                            />
                            <ListItemButton
                                onClick={() => handleToggleDropdown(index)}
                                sx={{ paddingLeft: 0, paddingRight: 0 }}
                            >
                                <ListItemText sx={{ alignSelf: "flex-start" }}>
                                    <Typography
                                        variant="h3"
                                        sx={{ paddingLeft: 0, paddingRight: 0 }}
                                    >
                                        {stage}
                                    </Typography>
                                </ListItemText>
                                <ListItemIcon
                                    sx={{ alignSelf: "center", justifyContent: "center" }}
                                >
                                    {openDropdownStages.includes(index) ? (
                                        <KeyboardArrowUpIcon sx={{ fontSize: "1.2vw" }} />
                                    ) : (
                                        <KeyboardArrowDownIcon sx={{ fontSize: "1.2vw" }} />
                                    )}
                                </ListItemIcon>
                            </ListItemButton>
                        </div>
                        <Divider variant="middle" component="li" />
                        <Collapse
                            in={openDropdownStages.includes(index)}
                            timeout="auto"
                            unmountOnExit
                        >
                            <Box className={boxWrapperClasses.BoxWrapper}>{children}</Box>
                        </Collapse>
                    </ListItem>
                </React.Fragment>
            </FormGroup>
        </Box>
    );
}

export default LCSDropdown;
