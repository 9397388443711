/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useMemo, useEffect, useCallback } from 'react';
import { EconomicResult, Product } from '../../../../../interface/Product';
import { ThemeProvider } from '@emotion/react';
import FormControl from '@mui/material/FormControl';

import { Box, Paper, Divider } from '@mui/material';
import deepClone from '../../../../../helper/deepClone';
import { ChartJSData, ChartJSOptions } from '../../../../../interface/ChartData';
import LCSDropdowns from '../../../../dropdowns/LCSDropdowns';
import useStyles from '../../../charts.styles';
import { initialOptions } from '../../../options/InitialOptionValues';
import ChartDownload from '../../../options/components/ChartDownload';
import ProductComparison from '../../../options/components/Comparison';
import GraphSetting from '../../../options/components/GraphSettings';
import { applyValueType } from '../../../options/util/applyOptions';
import B4BarPSILCA from '../../../social/psilca/stakeholder/B4BarPSILCA';
import B4PiePSILCA from '../../../social/psilca/stakeholder/B4PiePSILCA';
import ResultsTheme from '../../../style/ResultsTheme';
import { CreateChartTS } from '../../../util/CreateChartTS';
import Dropdown from '../../../../dropdowns/Dropdown';
import EcoChartNavigation from '../../../../dropdowns/BaseOptions/EcoChartNavigation';
import { processColors, palette1, palette2, palette3, palette4 } from '../../../../../helper/colors';
import { useFormContext } from '../../../../forms/FormContext';
import GraphType from '../../../options/components/GraphTypeOptions';
import { ChartColorIdentifiers } from '../../../options/components/GraphSettingsOptions';
import { ColorIndexData } from '../A4BarEco';



interface ChartData {
    product: Product;
}
function A4PieEco(props: ChartData) {
    const { formState, setFormState } = useFormContext();
    const classes = useStyles();
    const { product } = props;
    const [chartJSData, setChartJSData] = useState<ChartJSData>({ datasets: [], labels: [] });
    const [chartOptions, setChartOptions] = useState<ChartJSOptions>(initialOptions);
    const [colorIdentifiers, setColorIdentifiers] = useState<ChartColorIdentifiers[]>([]);
    const [selectedValueType, setSelectedValueType] = useState<string>('');
    const [results, setResults] = useState<EconomicResult[]>([]);
    const [selectedLCS, setSelectedLCS] = useState<string>('');
    const [selectedLCSS, setSelectedLCSS] = useState<string>('');
    const [graphColors, setGraphColors] = useState<ColorIndexData[]>(formState.processColorIndexes || []);

    useMemo(() => {
        const calc = product.calculations.find(
            (item) => item.calculationType === 'ECONOMIC',
        );
        const calcResults = calc?.calculationResults as EconomicResult[];
        setResults(calcResults);
    }, [product]);



  


    useEffect(() => {
        const chartData: ChartJSData = { datasets: [], labels: [] };
        const options: ChartJSOptions = deepClone<ChartJSOptions>(initialOptions);
        const chartColorIdentifiers : ChartColorIdentifiers[] = [];
        const matchingLCS = results.find((item) => item.name === selectedLCS);
        let lifeCycleSubstage;

        if (matchingLCS) {
            lifeCycleSubstage = matchingLCS.subStages.find((item) => item.name === selectedLCSS);

            if (lifeCycleSubstage) {
                const processIndexes = [];

                let processIndex = 0;

                lifeCycleSubstage.processes.forEach((process) => {
                    processIndexes.push({
                        name: process.processName,
                        index: processIndex++,
                        lifeCycleStage: `${selectedLCS}:${selectedLCSS}`,
                    });
                });

                // const desiredOrder = [
                //     'aluminium' /* Add other process names here */,
                // ];

                // const sortedProcesses = lifeCycleSubstage.processes.sort((a, b) => (
                //     desiredOrder.indexOf(a.processName) - desiredOrder.indexOf(b.processName)
                // ));

                // const totalProcesses = processIndexes.length;
        
                const sortedProcesses = lifeCycleSubstage.processes; // No sorting applied

                const datasets = sortedProcesses.sort((a, b) => a.processName.localeCompare(b.processName))
                    .map((process, index) => {

                        // chartColorIdentifiers.push({label:process.endProductName})
                        chartColorIdentifiers.push({
                            label: process.processName, 
                            identifier: `${selectedLCS}:${selectedLCSS}`,
                        });

                        return {
                            label: process.processName,
                            data: [process.costResult],
                        //backgroundColor: extendedPalette[colorIndex],
                        };
                    });
                console.log(chartColorIdentifiers);

                chartData.datasets = [{
                    data: datasets.map(dataset => dataset.data[0]),
                    // backgroundColor: datasets.map(dataset => dataset.backgroundColor),
                    label: 'Cost Per Process',
                }];

                chartData.labels = datasets.map(dataset => dataset.label);
            }
        }

        setChartJSData(chartData);
        setChartOptions(options);
        setColorIdentifiers(chartColorIdentifiers);    
    }, [selectedLCS, selectedLCSS, results, graphColors]);

    const [applyGraphSettings, setApplyGraphSettings] =
        useState<(options: ChartJSOptions, chartData: ChartJSData, colorIdentifiers?: ChartColorIdentifiers[]) => 
            { options: ChartJSOptions; chartData: ChartJSData; }>();

    const triggerChartUpdate = () => {
        let options = deepClone(chartOptions) || {};
        let chartData = deepClone(chartJSData);
        const applyOptionReturn = applyValueType(selectedValueType, chartData, options);
        options = applyOptionReturn.options;
        chartData = applyOptionReturn.chartData;
        if (applyGraphSettings) {
            ({ options, chartData } = applyGraphSettings(options, chartData, colorIdentifiers));
        }
        CreateChartTS(chartData, options, 'pie');
    };

    useEffect(() => {
        if (chartJSData && chartOptions) {
            triggerChartUpdate();
        }
    }, [chartJSData, chartOptions]);

    return (
        <ThemeProvider theme={ResultsTheme}>
            <Box
                sx={{
                    margin: '0',
                    maxWidth: '100%',
                    maxHeight: '-webkit-fill-available',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1vw',
                }}
            >
                <Box className='temp-box' />
                <Box component='div' className='results-wrapper'>
                    <Box component='div' className='results-options'>
                        <Paper square>
                            <Box component='div' className='results-options-top'>
                                <FormControl
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: '3rem',
                                        alignItems: 'stretch',

                                    }}
                                >
                                    <EcoChartNavigation product={product} defaultBaseIndex={0} defaultResultIndex={3} />
                                    <ProductComparison />
                                </FormControl>
                            </Box>
                        </Paper>
                        <Divider />
                        <Paper square>
                            <Box component='div' className='results-options-bottom'>
                                <Box>
                                    <FormControl
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}
                                        className='bottom-options side-left'
                                    >
                                        <GraphType
                                            type='pie'
                                            dimension='Economic'
                                            chart='A4'
                                        />
                                        <LCSDropdowns
                                            product={product}
                                            selectedLCS={selectedLCS}
                                            setSelectedLCS={setSelectedLCS}
                                            selectedLCSS={selectedLCSS}
                                            setSelectedLCSS={setSelectedLCSS}
                                            dimension="Social"
                                        />
                                        <Dropdown
                                            options={['Raw Values', '% Per Process']}
                                            valueSetter={setSelectedValueType}
                                            value={selectedValueType}

                                        />

                                    </FormControl>
                                </Box>
                                <Box>
                                    <FormControl
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            '@media (max-width: 1680px)': {
                                                gap: '1vw',
                                            },
                                        }}
                                        className='bottom-options side-right'
                                    >
                                        <ChartDownload chartData = {chartJSData} 
                                            chartName={'Total cost result - Process'} chartType='Pie Chart' />
                                        <GraphSetting
                                            chartOptions={chartOptions}
                                            setApplyGraphSettings={setApplyGraphSettings}
                                            triggerChartUpdate={triggerChartUpdate}
                                            colorIdentifiers={colorIdentifiers}
                                            chartData={chartJSData}
                                            graphType='pie'
                                            chartColorType="process"
                                        />

                                    </FormControl>
                                </Box>
                            </Box>
                        </Paper>
                    </Box>
                </Box>
                <Box className={classes.chartSec}>
                    <Paper
                        square
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <Box className={classes.chartSec}>
                            <canvas id='graph1Canvas' className='graph-canvas' />
                        </Box>
                    </Paper>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default A4PieEco;
