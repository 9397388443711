/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import useStyles from './B4PiePSILCA.styles';
import deepClone from '../../../../../../helper/deepClone';
import { ChartJSData, ChartJSOptions } from '../../../../../../interface/ChartData';
import { Product, PSILCAResult } from '../../../../../../interface/Product';
import { initialOptions } from '../../../../options/InitialOptionValues';
import { CreateChartTS } from '../../../../util/CreateChartTS';
import { getColorsForStakeholders, treemapRGB } from '../../../../../../helper/colors';

interface ChartData {
    product: Product,
    selectedLCS: string,
    selectedLCSS: string,
    selectedProcess: string,
    selectedValueType: string,
}

function B4PiePSILCA(props: ChartData) {
    const classes = useStyles();
    const {
        product, selectedProcess, selectedLCS, selectedLCSS, selectedValueType,
    } = props;
    const [chartJSData, setChartJSData] = useState<ChartJSData>({ datasets: [], labels: [] });
    const [chartOptions, setChartOptions] = useState<ChartJSOptions>();
    const [results, setResults] = useState<PSILCAResult[]>([]);

    useMemo(() => {
        const calc = product.calculations.find(
            (item) => item.calculationType === 'SOCIAL-PSILCA'
        );
        const calcResults = calc?.calculationResults as PSILCAResult[];
        setResults(calcResults);
    }, [product]);

    useEffect(() => {
        const chartData: ChartJSData = { datasets: [], labels: [] };
        const options: ChartJSOptions = deepClone<ChartJSOptions>(initialOptions);
        chartData.labels = results.map(
            (stakeholder) => stakeholder.stakeholder,
        );
        chartData.datasets = [
            {
                label: selectedProcess,
                data: results.map((stakeholder) => {
                    let stakeholderTotal = 0;
                    stakeholder.subcategories.forEach((subcategory) => {
                        let indicatorTotal = 0;
                        subcategory.indicators.forEach((indicator) => {
                            const matchingIndicator = indicator.indicatorResults.find(
                                (item) => item.lcsName === `${selectedLCS}:${selectedLCSS}`
                            );
                            if (matchingIndicator) {
                                const matchingProcess = matchingIndicator.processResults.processes.find(
                                    (item) => item.process === selectedProcess.split(' - ')[0]
                                );
                                if (matchingProcess) {
                                    indicatorTotal += matchingProcess.processTotalSum;
                                }
                            }
                        });
                        stakeholderTotal += indicatorTotal;
                    });
                    stakeholderTotal = Math.round(stakeholderTotal * 100) / 100;
                    return stakeholderTotal;
                }),
                // backgroundColor: treemapRGB.slice(0, results.length), 
                backgroundColor: getColorsForStakeholders(chartData.labels),
                borderWidth: 2,
                borderColor: "#FFFFFF",
            },
        ];

        chartData.labels = results.map(
            (stakeholder) => stakeholder.stakeholder,
        );

        // Apply specific options for the pie chart
        options.scales = {
            x: {
                display: false,
                grid: {
                    display: false,
                },
            } ,
            y: {
                display: false,
                grid: {
                    display: false,
                },
            } ,
        };
    
        options.plugins = options.plugins || {};
        options.plugins.tooltip = {
            enabled: true,
        };
        options.plugins.legend = {
            display: true,
            position: 'bottom',
        };

        setChartJSData(chartData);
        setChartOptions(options);
    }, [selectedValueType, selectedProcess]);

    useEffect(() => {
        if (chartJSData && chartOptions) {
            CreateChartTS(chartJSData, chartOptions, 'pie', 'graph2Canvas');
        }
    }, [chartJSData, chartOptions]);

    return (
        <Box className={classes.chartSec}>
            <canvas id='graph2Canvas' className='graph-canvas' />
        </Box>
    );
}

export default B4PiePSILCA;
