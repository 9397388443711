import React, { useEffect, useState } from "react";
// import Input from '../../../shared/components/FormElements/Input';
// import { Container } from '@mui/material';
import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { FormApi } from "@tanstack/react-form";
import {
    loadValuesFromFormContext,
    ProductDetailsForm,
} from "../../../../../interface/Form";
import { useFormContext } from "../../../../forms/FormContext";
import { Product } from "../../../../../interface/Product";
import { useRouterState } from "@tanstack/react-router";

function DimensionCheckboxes(props: {
  form: FormApi<ProductDetailsForm>;
  productToEdit: Product | undefined;
  validateForm: () => void;
}) {
    const { form, productToEdit, validateForm } = props;
    const [selectAll, setSelectAll] = useState(false);
    const route = useRouterState();

    const handleSelectAllChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const value = event.target.checked;
        form.setFieldValue("envDim", value);
        form.setFieldValue("socialDim", value);
        form.setFieldValue("ecoDim", value);

        setSelectAll(value);
        validateForm();
    };

    const { formState, setFormState } = useFormContext();
    const [updateString, setUpdateString] = useState("NOT UPDATED");

    const [socialChecked, setSocialChecked] = useState(false);
    const [envChecked, setEnvChecked] = useState(false);

    const [ecoChecked, setEcoChecked] = useState(false);

    useEffect(() => {
        if (productToEdit) {
            if (
                route.location.pathname.includes("edit") &&
        Object.keys(formState).length == 0
            ) {
                form.setFieldValue("envDim", productToEdit.dimensions.environmental);
                form.setFieldValue("socialDim", productToEdit.dimensions.social);
                form.setFieldValue("ecoDim", productToEdit.dimensions.economic);
                // setEnvChecked(productToEdit.dimensions.environmental);
                // setSocialChecked(productToEdit.dimensions.social);
                // setEcoChecked(productToEdit.dimensions.economic);

            } else {
                loadValuesFromFormContext(form, formState, [
                    "envDim",
                    "socialDim",
                    "ecoDim",
                ]);
                // setEnvChecked(form.state.values.envDim);
                // setSocialChecked(form.state.values.socialDim);
                // setEcoChecked(form.state.values.ecoDim);

            }
        } else {
            loadValuesFromFormContext(form, formState, [
                "envDim",
                "socialDim",
                "ecoDim",
            ]);
            // setEnvChecked(form.state.values.envDim);
            // setSocialChecked(form.state.values.socialDim);
            // setEcoChecked(form.state.values.ecoDim);

        }
    }, [productToEdit]);


    return (
        <Box
            className="dimension-checkboxes__content"
            sx={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
            }}
        >
            <Typography
                component="h2"
                variant="h2"
                onClick={() => {
                    console.log({
                        eco: form.state.values.ecoDim,
                        env: form.state.values.envDim,
                        social: form.state.values.socialDim,
                    });
                    setUpdateString("AFTER UPDATE");
                }}
            >
        Assessment Type 
            </Typography>
            <Box className="dimension-checkboxes-section">
                <Typography component="h3" variant="h3">
          Sustainability Dimensions
                </Typography>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={selectAll}
                                onChange={handleSelectAllChange}
                                sx={{ fontSize: "1vw" }}
                            />
                        }
                        label="Select All"
                        labelPlacement="start"
                        sx={{
                            justifyContent: "flex-end",
                            margin: 0,
                        }}
                    />
                    <form.Field
                        name="envDim"
                        children={(field) => {
                            return (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id={field.name}
                                            checked={form.state.values.envDim || false}
                                            onChange={(e) => {
                                                field.handleChange(Boolean(e.target.checked));
                                                setFormState({
                                                    ...formState,
                                                    envDim: e.target.checked,
                                                });
                                                validateForm();
                                                // setEnvChecked(e.target.checked)
                                            }}
                                            sx={{ fontSize: "1vw" }}
                                        />
                                    }
                                    label="Environmental"
                                />
                            );
                        }}
                    />
                    <form.Field
                        name="socialDim"
                        children={(field) => {
                            return (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id={field.name}
                                            checked={form.state.values.socialDim || false}
                                            onChange={(e) => {
                                                field.handleChange(Boolean(e.target.checked));
                                                setFormState({
                                                    ...formState,
                                                    socialDim: e.target.checked,
                                                });
                                                validateForm();
                                                // setSocialChecked(e.target.checked)
                                            }}
                                            sx={{ fontSize: "1vw" }}
                                        />
                                    }
                                    label="Social"
                                />
                            );
                        }}
                    />
                    <form.Field
                        name="ecoDim"
                        children={(field) => {
                            return (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id={field.name}
                                            checked={form.state.values.ecoDim || false}
                                            onChange={(e) => {
                                                field.handleChange(Boolean(e.target.checked));
                                                setFormState({
                                                    ...formState,
                                                    ecoDim: e.target.checked,
                                                });
                                                validateForm();
                                                // setEcoChecked(e.target.checked)
                                            }}
                                            sx={{ fontSize: "1vw" }}
                                        />
                                    }
                                    label="Economic"
                                />
                            );
                        }}
                    />
                </FormGroup>
            </Box>
        </Box>
    );
}

export default DimensionCheckboxes;
