import { ThreeDots } from 'react-loader-spinner';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => {return {
    loaderContainer: {
        position: 'fixed',
        left: '48%',
        top: '40%',
        zIndex: '99',
    },
    // To Changes here
    overlayLoader: {
        zIndex: '9',
        position: 'absolute',
        left: '48%',
        top: '40%',
    },
}});

export interface LoaderProps {
    loading: boolean;
    isFromAGGrid?: boolean;
}

function Loader(props: LoaderProps) {
    const { loading, isFromAGGrid } = props;
    const classes = useStyles();

    if (!loading) return null;

    return (
        <div className={isFromAGGrid ? classes.overlayLoader : classes.loaderContainer}>
            <ThreeDots
                height="70"
                width="70"
                radius="9"
                color="#166D46"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                visible
            />
        </div>
    );
}

export default Loader;
