import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import useStyles from './C4SunburstSPA.styles';
import { Bar } from 'react-chartjs-2';
// import Loader from '../../components/loader';
import toast from 'react-hot-toast';
import deepClone from '../../../../../../helper/deepClone';
import { ChartJSData, ChartJSOptions } from '../../../../../../interface/ChartData';
import SPAChartNavigation from '../../../../../dropdowns/BaseOptions/SPAChartNavigation';
import ResultsTheme from '../../../../style/ResultsTheme';
import { initialOptions } from '../../../../options/InitialOptionValues';
import ChartDownload from '../../../../options/components/ChartDownload';
import ProductComparison from '../../../../options/components/Comparison';
import GraphSetting from '../../../../options/components/GraphSettings';
import { applyValueType } from '../../../../options/util/applyOptions';
import { CreateChartTS } from '../../../../util/CreateChartTS';
import { SPAPSILCAPriorityResult, Product, SPALCSProcessResult, SPALifeCycleResult, SPAResult, SPAStakeholderResult } from '../../../../../../interface/Product';
import Dropdown from '../../../../../dropdowns/Dropdown';
import { palette1, palette2, palette3, palette4, productPerformanceColor, socialProcessColors } from '../../../../../../helper/colors';
import { useFormContext } from '../../../../../forms/FormContext';
import { WeighingSet, applyWeight, weighingMethods } from '../../../../../../helper/weighingMethod';
import LCSDropdowns from '../../../../../dropdowns/LCSDropdowns';
import Sunburst from "sunburst-chart";
import GraphType from '../../../../options/components/GraphTypeOptions';
import './sunburst.css';
import CircleIcon from '@mui/icons-material/Circle';
import Typography from '@mui/material/Typography';



interface ChartData {
    product: Product;
    doubleGraph?: boolean;
}

function C4SunburstSPA(props: ChartData) {
    const { formState, setFormState } = useFormContext();
    const classes = useStyles();
    const { product, doubleGraph } = props;
    const [chartJSData, setChartJSData] = useState<ChartJSData>({ datasets: [], labels: [] });
    const [chartOptions, setChartOptions] = useState<ChartJSOptions>(initialOptions);
    const [colorIdentifiers, setColorIdentifiers] = useState<ChartColorIdentifiers[]>([]);
    const [selectedValueType, setSelectedValueType] = useState<string>('');
    const [stakeholderOptions, setStakeholderOptions] = useState<string[]>([]);
    const [currentWeighingSet, setCurrentWeighingSet] = useState<WeighingSet>();
    const [selectedLCS, setSelectedLCS] = useState<string>('');
    const [selectedLCSS, setSelectedLCSS] = useState<string>('');
    const [selectedProcess, setSelectedProcess] = useState<string>('');
    const [results, setResults] = useState<SPAResult>();
    const sunburstRef = useRef<HTMLDivElement>(null);
    const [sunburstData, setSunburstData] = useState<SunburstData>();
    const [debounceTimeout, setDebounceTimeout] = useState<number | null>(null); // Add debounce timeout

    interface SunburstData {
        name: string,
        children: { name: string, children: { name: string, value: number }[] }[]
    }

    useMemo(() => {
        const calc = product.calculations.find(
            (item) => { return item.calculationType === 'SOCIAL-SPA' },
        );
        const calcResults = calc?.calculationResults as unknown as SPAResult;

        setResults((calcResults));
        const weighingMethod = weighingMethods.find((method) => method.name === product.socialData.weighingMethod.name);
        const weighingSet = weighingMethod?.sets.find((set) => set.name === product.socialData.weighingMethod.set);
        setCurrentWeighingSet(weighingSet);
        setStakeholderOptions(['All Stakeholders'].concat(calcResults.stakeholderResults.map((item) => { return item.name })));
    }, [product]);









    useEffect(() => {
        if (!results)
            return;

        const chartData: ChartJSData = { datasets: [], labels: [] };
        const options: ChartJSOptions = deepClone<ChartJSOptions>(initialOptions);
        const chartColorIdentifiers : ChartColorIdentifiers[] = [];

        if (options.scales && options.scales.y && options.scales.y.title) {
            options.scales.y.title.text = "Medium Risk Hours";
        }

        const spaLifeCycle = results.lifeCycleResults.find((lcs) => lcs.lcsName === `${selectedLCS}:${selectedLCSS}`);

        if (spaLifeCycle) {
            const process = spaLifeCycle.processResults.find(
                (item) => item.processName === selectedProcess.split(' - ')[0]
            );







            let sunburstData;
            if (process) {


                const uniqueCategories = process.PSILCAResults
                    .map((item) => item.category.split(":")[0])
                    .filter((item, index, self) => self.indexOf(item) === index);

                sunburstData = {
                    name: process.processName,
                    children: uniqueCategories.map((category) => {
                        return {
                            name: category,
                            children: process.PSILCAResults.filter(
                                (item2) => item2.category.split(":")[0] === category
                            ).map((item3) => {
                                return {
                                    name: item3.category.split(":")[1],
                                    value: item3.score,
                                };
                            }),
                        };
                    }),
                };


                setSunburstData(sunburstData);
                // console.log(sunburstData);
   
            }
 
        } else {
            // chartData.datasets = ca;cs
            //WIP
        }


        setChartJSData(chartData);
        setChartOptions(options);
        setColorIdentifiers(chartColorIdentifiers);    
    }, [selectedValueType,  selectedLCS, selectedLCSS, selectedProcess]);

    const [applyGraphSettings, setApplyGraphSettings] =
        useState<(options: ChartJSOptions, chartData: ChartJSData, colorIdentifiers?: ChartColorIdentifiers[]) => 
            { options: ChartJSOptions; chartData: ChartJSData; }>();
    const triggerChartUpdate = () => {
        if (!sunburstData || !sunburstRef.current) {
            return;
        }
        const sunburstElement = sunburstRef.current;
        console.log("Updating chart...");
            
        // Clear previous chart
        sunburstElement.innerHTML = "";
            
        // Initialize new Sunburst chart
        const myChart = Sunburst();
        let lastClickedNode = null;

        function getWordSubstring(str, wordCount) {
            const words = str.split(' '); // Split the string into words
            if (words.length <= wordCount) {
                return str; // If the string has fewer or equal words than the limit, return the full string
            }
            return words.slice(0, wordCount).join(' ') + '...'; // Return the limited words and add ellipsis
        }

        try {
            myChart
                .data(sunburstData)
                .color((d) => {
                    let color;
                    if (d.value) {
                        switch (d.value) {
                        case 1:
                            color = "#42a147";
                            break;
                        case 2:
                            color = "#fdc30a";
                            break;
                        case 3:
                            color = "#f47d00";
                            break;
                        case 4:
                            color = "#d22f2e";
                            break;
                        default:
                            color = "White";
                            break;
                        }
                        return color;
                    } else {
                        return "#E6E6E6";
                    }
                })
                .strokeColor("white")
                .size((d) => {
                    if (d.value) {
                        return 10;
                    }

                })
                .label((d) => {
                    if (d.name.length > 15) {
                        if (!d.value) {
                            // if(d?.__dataNode?.depth === 0){
                            //     return getWordSubstring(d.name, 3);
                            // } else {
                            return d.name.substring(0, 13) + "...";
                            // }
                        } else {
                            if (lastClickedNode && lastClickedNode === d) {

                                return d.name;
                            } else {
                                return d.name.substring(0, 17) + "...";
                            }
                        }
                    } else {
                        return d.name;
                    }
                })
                .nodeClassName((d) => {
                    if (lastClickedNode && lastClickedNode === d) {
                        if (!d.value) {
                            return "clicked process short";
                        } else {
                            return "clicked long";
                        }
                    } else {
                        if (!d.value) {
                            if(d?.__dataNode?.depth === 0){
                                console.log(d);

                                return "process processname short";
    
                            } else {
                                console.log(d);

                                return "process short";
                            }
                        } else {
                            return 'normal';
                        }
                    }
                })
                .onClick((d) => {
                    if (d?.__dataNode?.depth === 0) {
                        myChart.focusOnNode(d);
                    } else {
                        lastClickedNode = d;
                        myChart.focusOnNode(d);
                    }
                })
                .labelOrientation("radial")
                .handleNonFittingLabel((label, availablePx) => {
                    return label;
                })
                .radiusScaleExponent(2.5)(sunburstElement);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {

        // Clear previous debounce timeout if it exists
        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }
    
        // Set a new debounce timeout
        const timeoutId = setTimeout(() => {
            triggerChartUpdate();
        }, 300); // 300ms delay to debounce
    
        // Save the new timeout ID to state
        setDebounceTimeout(timeoutId);
    
        // Clean up function to clear the timeout if component unmounts or values change again before the timeout finishes
        return () => clearTimeout(timeoutId);
    }, [selectedLCS, selectedLCSS, selectedProcess, sunburstData]);
    

    return (
        <ThemeProvider theme={ResultsTheme}>
            <Box
                sx={{
                    margin: '0',
                    maxWidth: '100%',
                    maxHeight: '-webkit-fill-available',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1vw',
                    width: doubleGraph ? 'unset' : '-webkit-fill-available',
                }}
            >
                <Box component='div' className='results-wrapper'>
                    <Box component='div' className='results-options'>
                        <Box component='div' className='results-options-bottom'>
                            <Box>
                                <FormControl
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: '1rem',
                                        alignItems: 'stretch',
                                        flexWrap: 'wrap',
                                    }}
                                    className='bottom-options side-left two-graphs'
                                >
                                    <GraphType
                                        chart='C4'
                                        dimension='SPA'
                                        type='sunburst'
                                    />
                                    <LCSDropdowns
                                        product={product}
                                        selectedLCS={selectedLCS}
                                        setSelectedLCS={setSelectedLCS}
                                        selectedLCSS={selectedLCSS}
                                        setSelectedLCSS={setSelectedLCSS}
                                        selectedProcess={selectedProcess}
                                        setSelectedProcess={setSelectedProcess}
                                        dimension="Social"
                                    // defaultProcessOptions={['All Processes']}
                                    />

                                    <Dropdown
                                        options={['Raw Values', '% Per Subcategory']}
                                        valueSetter={setSelectedValueType}
                                        value={selectedValueType}

                                    />

                                </FormControl>
                            </Box>
                            <Box>
                                <FormControl
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        flexWrap: doubleGraph ? 'wrap' : 'nowrap',
                                        justifyContent: 'center',
                                        '@media (max-width: 1680px)': {
                                            gap: '1vw',
                                        },
                                        width: 'min-content',
                                    }}
                                    className='bottom-options side-right'
                                >
                                    <ChartDownload chartData={chartJSData}
                                        chartName={'Subcategory level result - Process (SDG/SPA)'}
                                        chartType='Sunburst Graph' />
                                    <GraphSetting
                                        chartOptions={chartOptions}
                                        setApplyGraphSettings={setApplyGraphSettings}
                                        triggerChartUpdate={triggerChartUpdate}
                                        colorIdentifiers={colorIdentifiers}
                                        chartData={chartJSData}
                                        chartColorType='performance'
                                        graphType='sunburst'
                                    />

                                </FormControl>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Divider />

                <Box className={classes.chartSec}
                    sx={{
                        justifyContent: doubleGraph ? 'normal' : 'center',
                        height: doubleGraph ? '63vh' : 'auto',
                    }}
                >
                    <Box className={classes.SunburstLegendWrap}>
                        <Box className={classes.SunburstLegendBox}>
                            <CircleIcon sx={{ color: '#42a147', fontSize: '1.2vw' }} />
                            <Typography className={classes.SunburstLegendText}>Very Good Performance</Typography>
                        </Box>
                        <Box className={classes.SunburstLegendBox}>
                            <CircleIcon sx={{ color: '#fdc30a', fontSize: '1.2vw' }} />
                            <Typography className={classes.SunburstLegendText}>Satisfactory Performance</Typography>
                        </Box>
                        <Box className={classes.SunburstLegendBox}>
                            <CircleIcon sx={{ color: '#f47d00', fontSize: '1.2vw' }} />
                            <Typography className={classes.SunburstLegendText}>Inadequate Performance</Typography>
                        </Box>
                        <Box className={classes.SunburstLegendBox}>
                            <CircleIcon sx={{ color: '#d22f2e', fontSize: '1.2vw' }} />
                            <Typography className={classes.SunburstLegendText}>Bad Performance</Typography>
                        </Box>
                    </Box>

                    <div ref={sunburstRef} id="sunburst" className="sunburst"
                        style={{
                            width: doubleGraph ? '100%' : '35vw',
                            height: doubleGraph ? '100%' : 'auto',
                        }}
                    ></div>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default C4SunburstSPA;
