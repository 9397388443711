import React, { useState, useEffect } from 'react';
import {
    Box,
} from '@mui/material';
import useStyles from './Product.style';
import { useParams, Outlet, Link, useNavigate, useRouterState } from '@tanstack/react-router';
import { useHttpClient } from '../../hooks/http-hook';
import { Product } from '../../interface/Product';
import { GraphLinks } from './components/GraphLinks/GraphLinks';
import ResultsProductHeader from '../../components/results/components/resultsproductheader';
import Typography from '@mui/material/Typography';
import { FormProvider } from '../../components/forms/FormContext';

interface Params {
    productId: string,
}

interface ProductLayoutProps {
    product: Product
}

function ProductLayout(props: ProductLayoutProps) {
    const { product } = props;
    const classes = useStyles();
    const { productId }: Params = useParams({ strict: false });
    const {
        isLoading, error, sendRequest, clearError,
    } = useHttpClient();

    const [hasEnv, setHasEnv] = useState(false);
    const [hasPSILCA, setHasPSILCA] = useState(false);
    const [hasSPA, setHasSPA] = useState(false);
    const [hasEco, setHasEco] = useState(false);


    const [loadedProduct, setLoadedProduct] = useState<Product>();

    const navigate = useNavigate();

    const router = useRouterState();
    //TEMP Need to find a way to differentiate Damage level Impact Methods from Impact Category level methods through the db
    //TODO save to mongoDB
    const damageTypeMethods = [
        "dfa238d9-380e-4654-bac5-2349940377d7",
        "82867c73-0329-4ad2-ba76-ef1e3e0cb088",
        "e42bd878-10fa-45ad-b6c6-c3f97a680d0c",
        "a5dca51e-157a-462a-ab47-3a0cebef1c64",
        "2a7b9cdf-a6bf-49fe-92cd-f560b7a7f57e",
        // "3b7b6f24-9809-3b46-a0be-2f09892ab9eb",
        //"692b670c-b4e9-3ded-b7b0-a84b00d39bdc",
        //"183b58c6-44f8-353b-9c75-1cc3d8fe0274",
    ];


    useEffect(() => {
        const envCalcs = product.calculations.find((calc) => {return calc.calculationType === "ENVIRONMENTAL"});
        const psilCalcs = product.calculations.find((calc) => {return calc.calculationType === "SOCIAL-PSILCA"});
        const ecoCalcs = product.calculations.find((calc) => {return calc.calculationType === "ECONOMIC"});
        setHasEnv(product.dimensions.environmental);
        setHasPSILCA(product.dimensions.social);
        setHasEco(product.dimensions.economic);
        const productPath = `/product/${product._id}`;
        if (product && router.location.pathname === productPath) { // check if in product root
            let navURL = productPath;
            if (envCalcs && product.environmentalData) {
                if (damageTypeMethods.find((method) => {return method === product.environmentalData.assessmentMethod.id})) {
                    navURL = productPath + '/Environmental/A1/Bar';
                } else if (product.environmentalData.nwSet != 'noSet') {
                    navURL = productPath + '/Environmental/B1/Bar';
                } else {
                    navURL = productPath + '/Environmental/D1/Bar';
                }
            } else if (psilCalcs) {
                navURL = productPath + '/Social/PSILCA/A1/Bar';
            }
            else if (ecoCalcs) {
                navURL = productPath + '/Economic/A1/Bar'
            }
            navigate({ to: navURL })
                .then((val) => { })
                .catch((err) => { });
        }
    }, [product])

    const navigateResultsDimensionsHandler = (dimension: string) => {
        const envCalcs = product.calculations.find((calc) => {return calc.calculationType === "ENVIRONMENTAL"});
        const psilCalcs = product.calculations.find((calc) => {return calc.calculationType === "SOCIAL-PSILCA"});
        const ecoCalcs = product.calculations.find((calc) => {return calc.calculationType === "ECONOMIC"});
        setHasEnv(!!envCalcs);
        setHasPSILCA(!!psilCalcs);
        setHasEco(!!ecoCalcs);
        const productPath = `/product/${product._id}`;

        let navURL = productPath;
        if (envCalcs && dimension == "environmental" && product.environmentalData) {
            if (damageTypeMethods.find((method) => {return method === product.environmentalData.assessmentMethod.id})) {
                navURL = productPath + '/Environmental/A1/Bar';
            } else if (product.environmentalData.nwSet != 'noSet') {
                navURL = productPath + '/Environmental/B1/Bar';
            } else {
                navURL = productPath + '/Environmental/D1/Bar';
            }
        } else if (psilCalcs && dimension == "social-psilca") {
            navURL = productPath + '/Social/PSILCA/A1/Bar';
        }
        else if (ecoCalcs && dimension == "economic") {
            navURL = productPath + '/Economic/A1/Bar'
        }
        navigate({ to: navURL })
            .then((val) => { })
            .catch((err) => { });
    }

    // useEffect(() => {
    //     const fetchProduct = async () => {
    //         const response = await sendRequest<ProductResponse>(
    //             `${process.env.REACT_APP_BACKEND_URL}/product/${productId}`,
    //             'GET',
    //             undefined,
    //             { 'Content-Type': 'application/json' },
    //         );
    //         setLoadedProduct(response.product);
    //         console.log(response);
    //     };
    //     fetchProduct().then((result) => {
    //         // Handle successful result here
    //         console.log('Products fetched successfully:', result);
    //     })
    //         .catch((err) => {
    //             // Handle error here
    //             console.error('Error fetching products:', err);
    //         });
    // }, []);

    return (
        <Box>
            <FormProvider>
                {/* <h2>Tossa Product</h2>
                    <h3>{productId}</h3> */}
                {/* {loadedProduct && <D1BarChart product={loadedProduct} />} */}
                <ResultsProductHeader env={hasEnv} eco={hasEco} psilca={hasPSILCA} spa={hasSPA} navHandler={navigateResultsDimensionsHandler} />
                <Outlet />
            </FormProvider>

        </Box>
    );
}
export default ProductLayout;
