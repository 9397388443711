import React, { useEffect, useState } from 'react';
import {
    Box,
    Paper,
    Typography,
    useMediaQuery,
    useTheme,
    Button,
    Popper,
    IconButton,
    Stack,
} from '@mui/material';
import { useNavigate } from '@tanstack/react-router';
import { ThemeProvider } from '@emotion/react';
import useStyles from './Dashboard.styles';
import DashboardTheme from './DashboardTheme';
import QuickStart3D from '../../uploads/images/quick-start-icons/Home - Get Started Icon - 3D.png';
import QuickStartESG from '../../uploads/images/quick-start-icons/Home - Get Started Icon - ESG.png';
import QuickStartWatchVid from '../../uploads/images/quick-start-icons/Home - Watch Video.png';
import SampleGraph1 from '../../uploads/images/samples/Indicator level result perProductSys undefined.png';
import SampleGraph2 from '../../uploads/images/samples/Normalized results_ Impact category level perProcess Bar Graph.png';
import SampleGraph3 from '../../uploads/images/samples/Stakeholder level result perProductSys Pie Chart.png';
import PDFDownload from '../../uploads/images/general-icons/Save-PDF-Guide.png';
import { getAccessTokenFromSessionStorage } from '../../helper/StorageHelper';
import CloseIcon from '@mui/icons-material/Close';
import ExitButton from '../../components/style/components/ExitButton';
import TokenExpireLogin from '../auth/TokenExpiredLogin';

function Dashboard() {
    const theme = useTheme();
    const navigate = useNavigate();
    const classes = useStyles();

    useEffect(() => {
        if (getAccessTokenFromSessionStorage() === null) {
            navigate({ to: '/login' });
        }
    }, [navigate]);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedImage, setSelectedImage] = useState<string | null>(null);

    const handleButtonClick = (event: React.MouseEvent<HTMLElement>, image: string) => {
        setAnchorEl(event.currentTarget);
        setSelectedImage(image);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setSelectedImage(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'image-popup' : undefined;

    return (
        <ThemeProvider theme={DashboardTheme}>
            <TokenExpireLogin  />

            <Box className={classes.BoxWrapper}>
                <Box className={classes.BoxSection} sx={{ justifyContent: 'flex-end' }}>
                    <Typography component='h2' variant='h2'>
                        Welcome, Insert Name!
                    </Typography>
                </Box>
                <Box className={classes.BoxGrid}>
                    <Paper square className={classes.PaperSpacing}>
                        <Typography component='h2' variant='h2'>
                            What is TOSSA?
                        </Typography>
                        <Box>
                            <Typography variant='body1' sx={{ fontSize: '0.7vw' }}>
                                Tossa software tool covers the impacts on the three dimensions
                                of sustainability for all activities involved in all lifecycle
                                stages of a product/service/technology together using life cycle
                                sustainability assessment method in one comprehensive and
                                user-friendly decision-support tool unique in terms of its
                                workflow, structure and graphic user-interface.
                            </Typography>
                        </Box>
                    </Paper>
                    <Paper square className={classes.PaperSpacing}>
                        <Typography component='h2' variant='h2'>
                            Get Started
                        </Typography>
                        <Box className={classes.ButtonSet}>
                            <Box>
                                <Button className={classes.ButtonLayout} variant='text'>
                                    <img
                                        src={QuickStartWatchVid}
                                        alt='Quick watch video icon'
                                        style={{ width: '6vw' }}
                                    />
                                    <Typography variant='body1' className={classes.GetStartedLinks}>Watch Video</Typography>
                                </Button>
                            </Box>
                            <Box>
                                <Button
                                    className={classes.ButtonLayout}
                                    variant='text'
                                    onClick={() => navigate({ to: '/createproduct/details' })}
                                >
                                    <img
                                        src={QuickStart3D}
                                        alt='Quick create product icon'
                                        style={{ width: '6vw' }}
                                    />
                                    <Typography variant='body1' className={classes.GetStartedLinks}>Create Product (3D)</Typography>
                                </Button>
                            </Box>
                            <Box>
                                <Button className={classes.ButtonLayout} variant='text' disabled>
                                    <img
                                        src={QuickStartESG}
                                        alt='Quick create esh icon'
                                        style={{ width: '6vw' }}
                                    />
                                    <Typography variant='body1' className={classes.GetStartedLinks}>
                                        Create Product (ESG) <br />
                                        (Currently Unavailable)
                                    </Typography>
                                </Button>
                            </Box>
                        </Box>
                    </Paper>
                    <Paper square className={classes.PaperSpacing}>
                        <Typography component='h2' variant='h2'>
                            Sample Graphs
                        </Typography>
                        <Box className={classes.ButtonSet}>
                            <Box>
                                <Button
                                    className={classes.ButtonLayout}
                                    variant='outlined'
                                    sx={{
                                        width: '9vw',
                                        height: 'auto',
                                        border: '1px solid #707070',
                                        borderRadius: 0,
                                        padding: 0,
                                    }}
                                    onClick={(e) => handleButtonClick(e, SampleGraph1)}
                                >
                                    <img
                                        src={SampleGraph1}
                                        alt='Sample graph 1'
                                        width={500}
                                        style={{
                                            
                                            clipPath: 'inset(1% 18% 1% 39%)',
                                            width: '20vw',
                                            position: 'relative',
                                            left: '-2vw',
                                        }}
                                    />
                                </Button>
                            </Box>
                            <Box>
                                <Button
                                    className={classes.ButtonLayout}
                                    variant='outlined'
                                    sx={{
                                        width: '9vw',
                                        height: 'auto',
                                        border: '1px solid #707070',
                                        borderRadius: 0,
                                        padding: 0,
                                    }}
                                    onClick={(e) => handleButtonClick(e, SampleGraph2)}
                                >
                                    <img
                                        src={SampleGraph2}
                                        alt='Sample graph 2'
                                        width={500}
                                        style={{ clipPath: 'inset(4% 31% 4% 31%)', width: '20vw' }}
                                    />
                                </Button>
                            </Box>
                            <Box>
                                <Button
                                    className={classes.ButtonLayout}
                                    variant='outlined'
                                    sx={{
                                        width: '9vw',
                                        height: 'auto',
                                        border: '1px solid #707070',
                                        borderRadius: 0,
                                        padding: 0,
                                    }}
                                    onClick={(e) => handleButtonClick(e, SampleGraph3)}
                                >
                                    <img
                                        src={SampleGraph3}
                                        alt='Sample graph 3'
                                        width={500}
                                        style={{ clipPath: 'inset(4% 31% 4% 31%)', width: '20vw' }}
                                    />
                                </Button>
                            </Box>
                        </Box>
                    </Paper>
                    <Paper square className={classes.PaperSpacing}>
                        <Typography component='h2' variant='h2'>
                            Updates and Guide
                        </Typography>
                        <Box className={classes.BoxSection} sx={{ flexDirection: 'row', height: '-webkit-fill-available' }}>
                            <Stack direction='column' spacing='5px'>
                                <Box>
                                    <Typography sx={{ fontWeight: 'bold' }} variant='body1'>
                                        Current Version
                                    </Typography>
                                    <Typography>Version 0.0.1</Typography>
                                </Box>
                                <Box>
                                    <Typography sx={{ fontWeight: 'bold' }} variant='body1'>
                                        Previous Versions
                                    </Typography>
                                    <Typography>None Currently</Typography>
                                </Box>
                                <Button
                                    variant='text'
                                    sx={{
                                        textDecoration: 'underline',
                                        fontSize: '0.7vw',
                                        color: '#707070',
                                        textTransform: 'capitalize',
                                    }}
                                >
                                    See all past updates
                                </Button>
                            </Stack>
                            <Stack direction='row' spacing='5px'>
                                <Stack direction='column' width='10vw' sx={{ gap: '1vw' }}>
                                    <Typography variant='body1'>
                                        Learn how some of the functions work with this guide.
                                    </Typography>
                                    <Typography variant='body1'>
                                        You are free to download this as need.
                                    </Typography>
                                    <Typography variant='body1'>
                                        Updated as of 00/00/0000
                                    </Typography>
                                </Stack>
                                <Box
                                    width='10vw'
                                    sx={{
                                        gap: '1vw',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }}
                                >
                                    <img
                                        src={PDFDownload}
                                        alt='PDF Icon'
                                        style={{ width: '4vw' }}
                                    />
                                    <Typography variant='body1' sx={{ textAlign: 'center' }}>
                                        Download the guide! (Unavailable)
                                    </Typography>
                                </Box>
                            </Stack>
                        </Box>
                    </Paper>
                </Box>
                <Popper
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    className={classes.SampleGraphsPopup}
                >
                    <Box 
                        className={classes.SampleGraphWrapper}

                    >
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'center', width: '100%' }}>

                            <ThemeProvider theme={ExitButton}>

                                <Button
                                    onClick={handleClose}
                                    sx={{
                                        marginRight: '0',
                                        marginTop: '0',
                                    }}
                                >
                                    <CloseIcon />
                                </Button>
                            </ThemeProvider>
                        </Box>
                        <Box
                            sx={{

                                padding: '1rem',
                            }}
                        >
                            {selectedImage && (
                                <img
                                    src={selectedImage}
                                    alt='Sample graph'
                                    style={{ width: 'auto', maxWidth: '75vw', maxHeight: '75vh', boxShadow: '0 0 3px #000000d1' }}
                                />
                            )}
                        </Box>
                    </Box>
                </Popper>
            </Box>
        </ThemeProvider>
    );
}

export default Dashboard;
