import React, { useState } from "react";
import "../Homepage.css";
import "./ServicesTOSSASoftware.css";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { Link } from "@tanstack/react-router";
import videodemothumb from "../../images/thumbnails/video-demonstation-thumb.png";
import VideoDemonstration from "../../components/VideoDemonstration";

const ServicesLCSASoftware: React.FC = () => {
    const [showVideoDemo, setShowVideoDemo] = useState(false);
    const showVideoDemoHandler = async () => {
        setShowVideoDemo(!showVideoDemo);
    };

    // useEffect(() => {
    //   if (modalOpen) {
    //     document.body.style.overflow = 'hidden'
    //   }
    // }, [modalOpen])

    return (
        <div className="website-only">
            <Header />

            <div className="header-container">
                <div className="header-transparent-layer"> </div>
                <img
                    src={require("../../images/headers/pexels-bill-white-165537.jpg")}
                    alt="Sun shining down the green forest"
                />
                <h1>Services</h1>
            </div>
            <div className="box-container">
                <h2>TOSSA Software</h2>
                <p className="p-hide">
          Our TOSSA commercial software package is unique by taking an
          integrated approach covering the impacts on the three dimensions of
          sustainability for all activities involved in all lifecycle stages of
          a product/service/technology together in one comprehensive and
          user-friendly decision-support tool unique in terms of its workflow,
          structure and graphic user-interface.
                </p>
            </div>
            <div className="box-container">
                <h2>Video Demonstration</h2>
                <p>Here is how the software works:</p>
                <div className="play-demo">
                    <div className="video-demo-box">
                        <div className="video-transparent-layer"> </div>

                        <img
                            src={videodemothumb}
                            alt="Thumbnail for the video demo"
                            width={500}
                        />
                        <button type="button" onClick={showVideoDemoHandler}>
              Play Video
                        </button>
                    </div>

                    {showVideoDemo && (
                        <div
                            className="video-demonstration"
                            style={{ display: showVideoDemo ? "" : "none" }}
                        >
                            <VideoDemonstration
                                showVideoDemo={showVideoDemo}
                                setShowVideoDemo={setShowVideoDemo}
                            />
                        </div>
                    )}
                </div>
            </div>
            <div className="box-container">
                <h2>Our software features</h2>
                <div className="image-container-four">
                    <img
                        src={require("../../images/box-images/pexels-lisa-fotios-3197390.jpg")}
                        alt="Person typing on a laptop"
                    />
                    <ul>
                        <li>
              Allowing users to compare different alternatives to choose the
              optimal and sustainable one or to identify hotspots and potential
              improvements needed to improve sustainability performance,
                        </li>
                        <li>
              Comparing performance with science-based threshold values,
              national and industry benchmark values
                        </li>
                        <li>
              Connecting performance with sustainable development goals (SDGs)
                        </li>
                        <li>
              Possibility of aggregating data across life cycle stages for
              actionable insights and streamlined reporting.
                        </li>
                        <li>
              Providing cloud-based solutions to help companies establish
              baselines of progress and share their success with stakeholders.
                        </li>
                        <li>
              Enabling companies to monitor and maintain the reporting process
              and track ESG metrics based on consistent standards of life cycle
              assessment.
                        </li>
                        <li>
              Providing different ways of seeing the reslits in a visual way
              using aggregated overall score, ranking, aggregated under each of
              the three dimensions of sustainability or detailed reslits
              depending on user preferences, and
                        </li>
                        <li>
              Helping ensure clients are meeting local and international
              requirements
                        </li>
                        <li>
              Possibility to include own data and/or generic data in the
              software to conduct assessment,
                        </li>
                        <li>Intuitive graphical modelling of assessed system, and</li>
                        <li>
              Providing direct access to databases that describes cost factors
              and environmental and social impact factors associated with
              sourcing and refining every raw material or processed component or
              energy of a manufactured item
                        </li>
                    </ul>
                </div>
            </div>
            <div className="box-container">
                <h2>Plans and pricing</h2>
                <p>Coming soon...</p>
            </div>
            <div className="box-container">
                <h2>TOSSA Software</h2>
                <div className="software-access-wrap">
                    <p>
            Our software is currently under development. If you are interested
            in knowing about it, please contact us
            admin@tossa-sustainability.com.
                    </p>
                    <Link to="/app" className="button-link">
            Access Application (WIP)
                    </Link>
                </div>
            </div>

            <Footer />
        </div>
    );
};

export default ServicesLCSASoftware;
