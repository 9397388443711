import { gql } from 'graphql-request';
import { addEscapeCharacters } from '../helper/validation';

const LOGIN = (email: string, password: string) => {return gql`
    query{
        login(
            email: ${addEscapeCharacters(email)},
            password: ${addEscapeCharacters(password)}
        ) {
            email
            message
        }
    }
`};

export default {
    LOGIN,
};
