export const processColors = [
    '#41BE84',
    '#925409',
    '#4F9DC9',
    '#EB9E43',
    '#F5C48A',
    '#613000',
    '#E971A2',
    '#FF9797',
    '#31855E',
    '#FF9C6E',
    '#FFCAB1',
    '#D49151',
    '#4F9DC9',
    '#C467BA',
    '#DF89D6',
    '#0C3D26',
    '#96b3cf',
    '#590d0d',
    '#282857',
    '#9d4888',
    '#46203c',
    '#182734',
    '#750b0b',
    '#db7d1f',
    '#96cfc1',
    '#e6734d',
    '#a7ef8f',
    '#ff5c5c',
    '#efa78f',
    '#ff6666',
    '#00994d',
    '#a3590f',
    '#5252ff',
    '#59330d',
    '#fe9ab3',
    '#b34019',
    '#fe9ffe',
    '#999900',
    '#50af99',
    '#6262b7',
    '#7dba69',
    '#9696cf',
    '#48749d',
    '#28574c',
    '#d7a8cb',
    '#345728',
    '#b34019',
    '#48749d',
    '#fe9ab3',
    '#efa78f',
    '#7dba69',
    '#182734',
    '#db7d1f',
    '#50af99',
    '#fe9ffe',
    '#ff6666',
    '#00994d',
    '#750b0b',
    '#28574c',
    '#345728',
    '#999900',
    '#46203c',
    '#590d0d',
    '#6262b7',
    '#ff5c5c',
    '#5252ff',
    '#e6734d',
    '#96cfc1',
    '#a3590f',
    '#96b3cf',
    '#db7d1f',
    '#96cfc1',
    '#282857',
    '#a7ef8f',
    '#9d4888',
    '#59330d',
    '#48749d',
    '#efa78f',
    '#182734',
    '#750b0b',
    '#282857',
    '#345728',
    '#ff6666',
    '#fe9ab3',
    '#ff5c5c',
    '#28574c',
    '#590d0d',
    '#59330d',
    '#00994d',
    '#96cfc1',
    '#e6734d',
    '#5252ff',
    '#d7a8cb',
    '#96b3cf',
    '#46203c',
    '#a3590f',
];
export const socialProcessColors = [
    '#F36B6B',
    '#7FB77E',
    '#E14243',
    '#86C6A9',
    '#C8812B',
    '#1B5D82',
    '#436B39',
    '#1F76A7',
    '#B1D7B4',
    '#BC2C2D',
    '#1A5B3D',
    '#EB9E43',
    '#234C0D',
    '#925409',
    '#31855E',
    '#FF9797',
    '#F3B76F',
    '#41BE84',
    '#F5C48A',
    '#E6C96E',
];
export const lifeCycleColors = [
    '#1B5D82',
    '#601F00',
    '#E14243',
    '#F3B76F',
    '#8BC5E5',
    '#841744',
    '#31855E',
    '#1A5B3D',
    '#98238C',
    '#4F9DC9',
    '#FF9BC4',
    '#1F76A7',
    '#DF89D6',
    '#BC2C2D',
    '#911010',
    '#C8812B',
];
export const sublifeCycleColors = [
    '#FF9C6E',
    '#841744',
    '#31855E',
    '#1A5B3D',
    '#98238C',
    '#4F9DC9',
    '#FF9BC4',
    '#1F76A7',
    '#DF89D6',
    '#BC2C2D',
    '#911010',
    '#C8812B',
    '#8D2282',
    '#1B5D82',
    '#fc6924',
    '#FF9797',
    '#E971A2',
];

export const elementaryColors = [
    '#5252ff',
    '#841744',
    '#50af99',
    '#345728',
    '#b34019',
    '#182734',
    '#59330d',
    '#e6734d',
    '#ff5c5c',
    '#96cfc1',
    '#7dba69',
    '#d7a8cb',
    '#efa78f',
    '#5252ff',
    '#750b0b',
    '#46203c',
    '#ff6666',
];

export const palette1 = ['#fc6924', '#FF9797', '#E971A2', '#E0383E', '#E070B7', '#E08C7B', '#EA7037', '#F5C673', '#EB9973'];
// export const palette2 = [
//   '#BC2C2D',
//   '#911010',
//   '#C8812B',
//   '#8D2282',
// ];
export const palette2 = [
    '#ff4000',
    '#00ff80',
    '#bf00ff',
    '#40ff00',
    '#ffff00',
    '#ffbf00',
    '#0000ff',
    '#00ff40',
    '#8000ff',
    '#ff0000',
    '#4000ff',
    '#80ff00',
    '#ff0040',
    '#00ffff',
    '#0040ff',
    '#ff0080',
    '#0080ff',
    '#ff00bf',
    '#00ffbf',
    '#ff8000',
    '#bfff00',
    '#ff00ff',
    '#00bfff',
    '#00ff00',
];
export const palette3 = [
    '#D80032',
    '#F1EB90',
    '#E1C78F',
    '#BE3144',
    '#3D0C11',
    '#662549',
    '#B0926A',
    '#F78CA2',
    '#22092C',
    '#6C5F5B',
    '#451952',
    '#952323',
    '#A73121',
    '#AE445A',
    '#FAE7C9',
    '#9FBB73',
    '#CE5A67',
    '#F4BF96',
    '#1F1717',
    '#706233',
    '#F9DEC9',
    '#4F4A45',
    '#F6F1EE',
    '#F2E8C6',
    '#FCF5ED',
    '#ED7D31',
    '#872341',
    '#F05941',
    '#DAD4B5',
    '#F39F5A',
    '#F3B664',
    '#EC8F5E',
];
export const palette4 = [
    '#0F0F0F',
    '#CDF5FD',
    '#1AACAC',
    '#89CFF3',
    '#80B3FF',
    '#C2D9FF',
    '#362FD9',
    '#7752FE',
    '#008170',
    '#363062',
    '#190482',
    '#8E8FFA',
    '#818FB4',
    '#64CCC5',
    '#DDF2FD',
    '#005B41',
    '#687EFF',
    '#232D3F',
    '#B6FFFA',
    '#427D9D',
    '#176B87',
    '#A0E9FF',
    '#2E97A7',
    '#F5E8C7',
    '#164863',
    '#00A9FF',
    '#EEEEEE',
    '#04364A',
    '#9BBEC8',
    '#DAFFFB',
    '#98E4FF',
    '#435585',
];
export const socialProcessColorsTransparent = [
    'rgb(243,107,107, 0.3)',
    'rgb(127,183,126, 0.3)',
    'rgb(225,66,67, 0.3)',
    'rgb(134,198,169, 0.3)',
    '#C8812B',
    '#1B5D82',
    '#436B39',
    '#1F76A7',
    '#B1D7B4',
    '#BC2C2D',
    '#1A5B3D',
    '#EB9E43',
    '#234C0D',
    '#925409',
    '#31855E',
    '#FF9797',
    '#F3B76F',
    '#41BE84',
    '#F5C48A',
    '#E6C96E',
];
export const palette2transparent = [
    'rgb(255,64,0, 0.3)',
    'rgb(0,255,128, 0.3)',
    'rgb(191,0,255, 0.3)',
    'rgb(64,255,0, 0.3)',
    '#ffff00',
    '#ffbf00',
    '#0000ff',
    '#00ff40',
    '#8000ff',
    '#ff0000',
    '#4000ff',
    '#80ff00',
    '#ff0040',
    '#00ffff',
    '#0040ff',
    '#ff0080',
    '#0080ff',
    '#ff00bf',
    '#00ffbf',
    '#ff8000',
    '#bfff00',
    '#ff00ff',
    '#00bfff',
    '#00ff00',
];
export const palette3transparent = [
    'rgb(216,0,50, 0.3)',
    'rgb(241,235,144, 0.3)',
    'rgb(225,199,143, 0.3)',
    'rgb(190,49,68, 0.3)',
    '#3D0C11',
    '#662549',
    '#B0926A',
    '#F78CA2',
    '#22092C',
    '#6C5F5B',
    '#451952',
    '#952323',
    '#A73121',
    '#AE445A',
    '#FAE7C9',
    '#9FBB73',
    '#CE5A67',
    '#F4BF96',
    '#1F1717',
    '#706233',
    '#F9DEC9',
    '#4F4A45',
    '#F6F1EE',
    '#F2E8C6',
    '#FCF5ED',
    '#ED7D31',
    '#872341',
    '#F05941',
    '#DAD4B5',
    '#F39F5A',
    '#F3B664',
    '#EC8F5E',
];
export const palette4transparent = [
    'rgb(15,15,15, 0.3)',
    'rgb(205,245,253, 0.3)',
    'rgb(26,172,172, 0.3)',
    'rgb(137,207,243, 0.3)',
    '#80B3FF',
    '#C2D9FF',
    '#362FD9',
    '#7752FE',
    '#008170',
    '#363062',
    '#190482',
    '#8E8FFA',
    '#818FB4',
    '#64CCC5',
    '#DDF2FD',
    '#005B41',
    '#687EFF',
    '#232D3F',
    '#B6FFFA',
    '#427D9D',
    '#176B87',
    '#A0E9FF',
    '#2E97A7',
    '#F5E8C7',
    '#164863',
    '#00A9FF',
    '#EEEEEE',
    '#04364A',
    '#9BBEC8',
    '#DAFFFB',
    '#98E4FF',
    '#435585',
];

export const treemapRGB: string[] = [
    '#e6e6e6',
    '#e34f4f',
    '#379569',
    '#2897d7',
    '#eba147',
];

export const productPerformanceColor = [
    '#1B5D82',
    '#1B5D82',
];

export const getColorsForStakeholders = (labels: string[]) => {
    labels = labels.map((label) => {
        const [defaultColor, vcaColor, workersColor, lcColor, societyColor] = treemapRGB;
        switch (label) {
        case 'Value Chain Actors':
            return vcaColor;
        case 'Workers':
            return workersColor;

        case 'Local Community':
            return lcColor;

        case 'Society':
            return societyColor;
        default:
            return defaultColor;
        }

        return defaultColor;
    });
    return labels;
};
