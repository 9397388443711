import React from 'react';
import '../Homepage.css';
import './OurMission.css';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Highlight1 from '../../images/figures/Highlight_Icon_01.png';
import Highlight2 from '../../images/figures/Highlight_Icon_02.png';
import Highlight3 from '../../images/figures/Highlight_Icon_03.png';

const AboutOurMission: React.FC = () => {
    return (
        <div className='website-only'>
            <Header />

            <div className='header-container'>
                <div className='header-transparent-layer'> </div>
                <img
                    src={require('../../images/headers/pexels-christina-morillo-1181365.jpg')}
                    alt='Man writes on board'
                />
                <h1>Mission</h1>
            </div>
            <div className='box-container'>
                <h2>Our Mission</h2>

                <p className='p-hide'>
          Our mission is to produce optimal assessment solutions for ESG and
          sustainable decision-making for companies in any industry following
          international well recognized life cycle standards.
                </p>
            </div>
            <div className='box-container'>
                <h2>Highlights</h2>
                <div className='image-container-two highlights'>
                    <div className='highlight-figure'>
                        <img
                            src={Highlight1}
                            alt='Lightbulb flashing'
                        />
                        <h3>Integrated approach</h3>
                        <hr className='line-break-two' />
                        <p>Covering triple-bottom line impacts</p>
                    </div>
                    <div className='highlight-figure'>
                        <img
                            src={Highlight2}
                            alt='Shiny trophy'
                        />
                        <h3>Quality Results</h3>
                        <hr className='line-break-two' />
                        <p>
              Based on well-recognized standard life cycle assessment
              methodology
                        </p>
                    </div>
                    <div className='highlight-figure'>
                        <img
                            src={Highlight3}
                            alt='Computer showcasing forms and papers'
                        />
                        <h3>One simple decision-support tool</h3>
                        <hr className='line-break-two' />
                        <p>Designed to guide users step-by-step in the process</p>
                    </div>
                </div>
            </div>
            <div className='box-container'>
                <h2>Our Core Values</h2>
                <p>
          For TOSSA, values shape everything we do as a company. You'll find
          these six qualities expressed throughout every part of your business.
                </p>
                <div className='core-values'>
                    <div className='core-value'>
                        <h3>Integrity</h3>
                        <hr className='line-break-two' />
                        <p>
              Integrity in the way of doing business. We are honest,
              transparent, and committed to doing what’s best for our customers
              and company.
                        </p>
                    </div>
                    <div className='core-value'>
                        <h3>Trust</h3>
                        <hr className='line-break-two' />

                        <p>
              We seek to build trust in every interaction with customers
              consistently.
                        </p>
                    </div>
                    <div className='core-value'>
                        <h3>Diversity and inclusion</h3>
                        <hr className='line-break-two' />

                        <p>
              Understanding, accepting, and valuing diversity and inclusion
              inspire creativity and drive innovation.
                        </p>
                    </div>
                    <div className='core-value'>
                        <h3>Innovation</h3>
                        <hr className='line-break-two' />

                        <p>
              By building sustainability into innovation, we help companies
              create products, services, and processes to generate long-term
              social and environmental benefits while creating economic profits
              for the organizations.
                        </p>
                    </div>
                    <div className='core-value'>
                        <h3>Quality</h3>
                        <hr className='line-break-two' />

                        <p>
              Quality is essential to provide excellent customer service and
              satisfying all their needs by maintaining rigorous standards for
              our work.
                        </p>
                    </div>
                    <div className='core-value'>
                        <h3>Simplicity</h3>
                        <hr className='line-break-two' />

                        <p>
              We believe Sustainability is not complicated, it depends on who
              explains it to you. Paraphrasing Albert Einstein, “Everything
              should be made as simple as possible, but no simpler.
                        </p>
                    </div>
                </div>
            </div>
            <div className='box-container'>
                <h2>
          Are you struggling with unclear practices for ESG – sustainability
          assessment and strategy construction? TOSSA is here to help!
                </h2>
                {/* Updated upstream */}
                <ul className='tossa-strategy'>
                    {/*}>>>>>>> Stashed changes*/}
                    <li>
            TOSSA is the only organized and scientifically sound ESG+ tool
            available to evaluate whole sustainability performance of
            products/services/technologies based on standard lifecycle
            assessment methodology
                    </li>
                    <li>
            Enables practices for ESG assessment and strategy construction
                    </li>
                    <li>
            Identifies the optimal path for tailored adaptive ESG strategies
                    </li>
                    <li>Empowers ESG-backed decision making</li>
                </ul>
            </div>

            <Footer />
        </div>
    );
};

export default AboutOurMission;
