import { Box, Button, Checkbox, FormControlLabel, Paper, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { FormState, useFormContext } from '../../../forms/FormContext';
import useStyles from './SubmitProductPopup.styles';
import { useForm } from '@tanstack/react-form';
import '../../../dropdowns/DropdownTheme.css';
import LoopIcon from '@mui/icons-material/Loop';
import { submitCreateProductHandler } from '../../../../helper/productSubmit';
import { useNavigate } from '@tanstack/react-router';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

interface SubmitProductPopupProps {
    setEnvLoading: React.Dispatch<React.SetStateAction<boolean | undefined>>,
    setSocialLoading: React.Dispatch<React.SetStateAction<boolean | undefined>>,
    setEcoLoading: React.Dispatch<React.SetStateAction<boolean | undefined>>,
    envIsLoading: boolean | undefined,
    socialIsLoading: boolean | undefined,
    ecoIsLoading: boolean | undefined,

    setShowSubmitProductPopup: React.Dispatch<React.SetStateAction<boolean>>
    editProductId?: string,
    missingInputs: string[];
}

interface SubmitProductForm {
    originalProductName: string,
    newProductName: string,
    keepOriginal: boolean,
}
export const SubmitProductPopup = ({
    setEnvLoading,
    setSocialLoading,
    setEcoLoading,
    envIsLoading,
    socialIsLoading,
    ecoIsLoading,
    setShowSubmitProductPopup,
    editProductId,
    missingInputs,
}: SubmitProductPopupProps) => {

    const classes = useStyles();
    const { formState, setFormState } = useFormContext();
    const [newProductId, setNewProductId] = useState<string>('');
    const submitHandler = () => {
        submitCreateProductHandler(formState, setEnvLoading, setSocialLoading, setEcoLoading, setNewProductId);
    }
    const navigate = useNavigate();

    const cancelHandler = () => {
        setShowSubmitProductPopup(false);
        setEnvLoading(undefined);
        setSocialLoading(undefined);
        setEcoLoading(undefined);
    }

    const form = useForm<SubmitProductForm>({
        defaultValues: {
            keepOriginal: false,
            originalProductName: formState.originalProductName || formState.productName,
            newProductName: formState.newProductName || formState.productName,

        },
        onSubmit: ({ value }) => {
            setFormState({ ...formState, ...value })
        },
    });
    return <>
        <Box className={classes.SelectPopupParent}>

            <Box className={classes.SelectPopupContent}>

                <Paper square className={classes.PaperWrapper}>

                    <Box className={classes.InnerContent}>
                        <Typography className={classes.PopupMessage} component='h2' variant='h2'>Submit Product?</Typography>
                        {editProductId && <div className={classes.SubmitEditWrapper}>
                            <Box className={classes.KeepOriginalCheck}>
                                <Typography component='h3' variant='h3'>Keep Original</Typography>

                                <form.Field
                                    name="keepOriginal"
                                    children={(field) => {
                                        return (
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        id={field.name}
                                                        checked={field.state.value}
                                                        onChange={(e) => {
                                                            field.handleChange(Boolean(e.target.checked));
                                                            form.handleSubmit();
                                                        }}
                                                    />
                                                }
                                                label=""
                                            />
                                        );
                                    }}
                                />
                            </Box>
                            <Box className={classes.NameRenamer}>
                                <form.Field
                                    name="originalProductName"
                                    children={(field) => {
                                        return (
                                            <TextField
                                                id={field.name}
                                                className={field.name}
                                                value={field.state.value}
                                                onBlur={field.handleBlur}
                                                onChange={(e) => {
                                                    field.handleChange(e.target.value);
                                                    form.handleSubmit();
                                                }}
                                                disabled={!form.state.values.keepOriginal}
                                                // onChange={(e) => {
                                                //     const value = Number(e.target.value);
                                                //     console.log('New xFontSize:', value);
                                                //     field.handleChange(value);
                                                // }}
                                                label="Original Product Name"
                                                type="input"
                                                size="small"
                                                sx={{
                                                    padding: '0.2vw',
                                                    '& .MuiInputBase-input': {
                                                        padding: '0.2vw',
                                                    },
                                                }}
                                            />
                                        );
                                    }}
                                />
                                <form.Field
                                    name="newProductName"
                                    children={(field) => {
                                        return (
                                            <TextField
                                                id={field.name}
                                                className={field.name}
                                                value={field.state.value}
                                                onBlur={field.handleBlur}
                                                onChange={(e) => {
                                                    field.handleChange(e.target.value);
                                                    form.handleSubmit();
                                                }}
                                                disabled={!form.state.values.keepOriginal}

                                                // onChange={(e) => {
                                                //     const value = Number(e.target.value);
                                                //     console.log('New xFontSize:', value);
                                                //     field.handleChange(value);
                                                // }}
                                                label="New Product Name"
                                                type="input"
                                                size="small"
                                                sx={{
                                                    padding: '0.2vw',
                                                    '& .MuiInputBase-input': {
                                                        padding: '0.2vw',
                                                    },
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </Box>
                        </div>}

                        <Box className={classes.ButtonWrap}>
                            {missingInputs.length > 0 && <>
                                <Box className={classes.MissingInputsBox}>
                                    <Typography className={classes.MissingInputsMessage} component='h3' variant='h3'>
                                        Note: The following inputs are missing:
                                    </Typography>
                                    <Box className={classes.MissingInputsList}>
                                        {missingInputs.map((val) => <p>{val}</p>)}
                                    </Box>
                                </Box>
                            </>}

                            {/* <h1>{isLoading ? "Submitting..." : ""}</h1> */}
                            {/* <Box className={classes.SubmittingLoadingBox}>
                                <Typography className={classes.SubmittingLoadingMessage} component='h2' variant='h2'>Submitting... </Typography><LoopIcon  className={classes.deleteLoadingSpinner} />
                            </Box> */}
                            <Button disabled={envIsLoading || socialIsLoading || ecoIsLoading}
                                onClick={() => { submitHandler() }} className={classes.PopupButtonText}>Submit</Button>
                            <Button onClick={() => { cancelHandler() }} className={classes.PopupButtonText}>Cancel</Button>
                        </Box>
                    </Box>

                </Paper>
                {((envIsLoading || socialIsLoading || ecoIsLoading) || (!envIsLoading && !socialIsLoading && !ecoIsLoading && newProductId)) && (
                    <Paper square className={classes.SubmittingLoadingPaper}>
                        <Box className={classes.CheckCalcWrapper}>
                            {envIsLoading === true ?
                                <Box className={classes.SubmittingLoadingBox}>
                                    {/* <Paper square className={classes.SubmittingLoadingPaper}> */}
                                    <Typography className={classes.SubmittingLoadingMessage} component='h2' variant='h2'>Environmental Calculating...
                                    </Typography><LoopIcon className={classes.deleteLoadingSpinner} />
                                    {/* </Paper> */}
                                </Box>
                                : envIsLoading === false ? <Box className={classes.SubmittingLoadingBox}>
                                    {/* <Paper square className={classes.SubmittingLoadingPaper}> */}
                                    <Typography className={classes.SubmittingLoadingMessage} component='h2' variant='h2'>Environmental Calculations done!
                                    </Typography>
                                    {/* </Paper> */}
                                </Box> : ""
                            }
                            {socialIsLoading === true ?
                                <Box className={classes.SubmittingLoadingBox}>
                                    {/* <Paper square className={classes.SubmittingLoadingPaper}> */}
                                    <Typography className={classes.SubmittingLoadingMessage} component='h2' variant='h2'>Social Calculating...
                                    </Typography><LoopIcon className={classes.deleteLoadingSpinner} />
                                    {/* </Paper> */}
                                </Box>
                                : socialIsLoading === false ? <Box className={classes.SubmittingLoadingBox}>
                                    {/* <Paper square className={classes.SubmittingLoadingPaper}> */}
                                    <Typography className={classes.SubmittingLoadingMessage} component='h2' variant='h2'>Social Calculations done!
                                    </Typography>
                                    {/* </Paper> */}
                                </Box> : ""
                            }
                            {ecoIsLoading === true ?
                                <Box className={classes.SubmittingLoadingBox}>
                                    {/* <Paper square className={classes.SubmittingLoadingPaper}> */}
                                    <Typography className={classes.SubmittingLoadingMessage} component='h2' variant='h2'>Economic Calculating...
                                    </Typography><LoopIcon className={classes.deleteLoadingSpinner} />
                                    {/* </Paper> */}
                                </Box>
                                : ecoIsLoading === false ? <Box className={classes.SubmittingLoadingBox}>
                                    {/* <Paper square className={classes.SubmittingLoadingPaper}> */}
                                    <Typography className={classes.SubmittingLoadingMessage} component='h2' variant='h2'>Economic Calculations done!
                                    </Typography>
                                    {/* </Paper> */}
                                </Box> : ""
                            }
                            {!envIsLoading && !socialIsLoading && !ecoIsLoading && newProductId && <Button onClick={() => {
                                navigate({ to: `/product/${newProductId}` })

                            }} className={classes.PopupButtonText} sx={{
                                width: 'auto !important',
                            }}>Go to Results</Button>
                            }

                        </Box>
                        {!envIsLoading && !socialIsLoading && !ecoIsLoading && newProductId && 
                        <CheckCircleRoundedIcon className={classes.CheckIconSuccess} />
                        }
                    </Paper>
                )}
            </Box>
        </Box>
    </>
}