/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable react/function-component-definition */
import React, { useEffect, useState } from "react";
import {
    Checkbox,
    Container,
    FormControlLabel,
    Popper,
    TextField,
} from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { styled, alpha } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import GeneralTheme from "../../../style/GeneralTheme";
import { ThemeProvider } from "@emotion/react";
import Dropdown from "../../../dropdowns/Dropdown";
import { useNavigate } from "@tanstack/react-router";
import SPAStakeholders from "./components/SPAStakeholders";

import useStyles from "../SocialInputs.styles";
import { useForm } from "@tanstack/react-form";
import { SocialInputsForm } from "../../../../interface/Form";
import { FormState, useFormContext } from "../../../forms/FormContext";
import { CreateProductNavButtons } from "../../components/CreateProductNavButtons";
import { SocialInputsLCSDropdowns } from "../components/SocialInputsLCSDropdowns";
import { SocialStakeholder } from "../../../../interface/Stakeholder";
import { fetchSocialStakeholders } from "../../../../helper/fetchUtil";
import "../SocialInputs.css";
import SocialSPAInputProcessList from "./components/SocialSPAInputProcessList";
import SelectSocialProcessPopup from "../components/SelectSocialProcessPopup";
import { weighingMethods } from "../../../../helper/weighingMethod";
import { SelectProcess } from "../../../../interface/Process";
import { SocialInputsLCSDropdownsSPA } from "../components/SocialInputsLCSDropdownsSPA";
import { LifeCycleStage } from "../../../../helper/lifeCycleStage";
import CreateProductHeader from "../../../results/components/createproductheader";

const SocialSPAInputs = ({ editProductId }: { editProductId?: string }) => {
    const navigate = useNavigate();
    // const classes = useStyles();

    const [selectedStakeholderSPA, setSelectedStakeholderSPA] =
        useState<string>("");

    const [selectableWeighingMethods, setSelectableWeighingMethods] = useState<
        string[]
    >([
        "Distance-to-target + Risk-based",
        "Equal Weighing",
        "Based on norms",
        "Stakeholders' judgement",
    ]);
    const { formState, setFormState } = useFormContext();

    const [selectableWeighingSets, setSelectableWeighingSets] = useState<
        string[]
    >([]);
    const [loadedStakeholders, setLoadedStakeholders] = useState<
        SocialStakeholder[]
    >([]);
    const [selectedStakeholder, setSelectedStakeholder] =
        useState<SocialStakeholder>();
    const selectWeighingMethodHandler = (val: string) => {
        const selectedMethod = weighingMethods.find((_method) => {
            return _method.name === val;
        });
        console.log(selectedMethod);
        if (selectedMethod) {
            setSelectableWeighingSets(
                selectedMethod.sets.map((set) => {
                    return set.name;
                })
            );
        }
    };

    useEffect(() => {
        fetchSocialStakeholders()
            .then((val) => setLoadedStakeholders(val))
            .catch();

        selectWeighingMethodHandler(weighingMethods[0].name);
    }, []);

    const [selectedLCS, setSelectedLCS] = useState<string>("");
    const [selectedLCSS, setSelectedLCSS] = useState<string>("");
    const [selectedSPALCS, setSelectedSPALCS] = useState<string>(
        formState?.socialSPALCS?.split(":")[0] || ""
    );
    const [selectedSPALCSS, setSelectedSPALCSS] = useState<string>(
        formState?.socialSPALCS?.split(":")[1] || ""
    );

    const [enableInputs, setEnableInputs] = useState<boolean>(false);
    const [enableStakeholderInputs, setEnableStakeholderInputs] = useState<boolean>(false);
    const [isFirstSelection, setIsFirstSelection] = useState(true);

    const selStages = formState.selectedLCS as string[];
    const spaLCStages = selStages?.filter(
        (item) => item.split(":")[0] === formState.socialSPALCS?.split(":")[0]
    );
    const nonSPALCS = selStages?.find((stage) => {
        if ((spaLCStages.length || 0) > 1) {
            const otherStage = spaLCStages.find(
                (item) => item !== formState.socialSPALCS
            );
            if (otherStage === stage) {
                return true;
            }
        } else {
            if (stage != formState.socialSPALCS) {
                return true;
            }
        }
        return false;
    });

    const form = useForm<SocialInputsForm>({
        defaultValues: {
            socialInputProcesses: formState.socialInputProcesses || [],
            currentLCS:
                nonSPALCS || (formState.currentLCS
                    ? formState.currentLCS.split(":")[0]
                    : ""),
            currentLCSS:
                nonSPALCS?.split(":")[1] || (formState.currentLCSS
                    ? formState.currentLCSS.split(":")[1]
                    : ""),
            socialAssessmentMethod: "SPA",
            socialSPAWeighingMethod:
                formState.socialSPAWeighingMethod || weighingMethods[0].name,
            socialSPAWeighingSet:
                formState.socialSPAWeighingSet || weighingMethods[0].sets[0].name,
            useSPALCS: formState.useSPALCS || false,
        },
        onSubmit: ({ value }) => {
            // Do something with form data
            let processes = value.socialInputProcesses;

            if (selectedSPALCS && selectedSPALCSS) {
                processes = processes.filter(
                    (item) => item.lcs != `${selectedSPALCS}:${selectedSPALCSS}`
                );
            }
            setFormState({
                ...formState,
                socialInputProcesses: processes,
                socialAssessmentMethod: value.socialAssessmentMethod,
                socialSPAWeighingMethod: value.socialSPAWeighingMethod,
                socialSPAWeighingSet: value.socialSPAWeighingSet,
                socialSPALCS: `${selectedSPALCS}:${selectedSPALCSS}`,
                currentLCS: value.currentLCS,
                currentLCSS: value.currentLCSS,
                useSPALCS: value.useSPALCS,
            });
            return;
        },
    });

    useEffect(() => {
        // setFormState({
        //     ...formState,
        //     socialAssessmentMethod: form.state.values.socialAssessmentMethod,
        // });
    }, [form.state.values.socialAssessmentMethod]);



    // const PaperSpacing = styled(Paper)(({ theme }) => {
    //     return {
    //         display: 'flex',
    //         gap: '1vw',
    //         flexDirection: 'column',
    //         width: '-webkit-fill-available',
    //         justifyContent: 'space-between',
    //     }
    // });



    useEffect(() => {
        if (selectedStakeholderSPA && loadedStakeholders.length > 0) {
            const selectedStakeholder = loadedStakeholders.find(
                (stakeholder) => stakeholder.name === selectedStakeholderSPA
            );
            setSelectedStakeholder(selectedStakeholder);
        }
    }, [selectedStakeholderSPA, loadedStakeholders]);

    const classes = useStyles();

    // const [showSelectProcessPopup, setShowSelectProcessPopup] =
    // useState<null | HTMLElement>(null);
    const [showSelectProcessPopup, setShowSelectProcessPopup] =
        useState<null | HTMLElement>(null);
    const handleProcessPopupClick = (event: React.MouseEvent<HTMLElement>) => {
        setShowSelectProcessPopup(
            showSelectProcessPopup ? null : event.currentTarget
        );
    };

    const [selectedProcess, setSelectedProcess] = useState<SelectProcess>();

    const openProcessSelect = (event: React.MouseEvent<HTMLElement>) => {
        setShowSelectProcessPopup(
            showSelectProcessPopup ? null : event.currentTarget
        );
    };

    const open = Boolean(showSelectProcessPopup);
    const processPopper = open ? "simple-popper spa-popper" : undefined;
    const selectProcessHandler = (process: SelectProcess) => {
        setSelectedProcess(process);
        // setFormState({ ...formState, selectedSPAProcess: process });
    };




    // useEffect(() => {
    //     if (selectedLCS && selectedLCSS) {
    //         setEnableInputs(true);
    //     } else {
    //         setEnableInputs(false);
    //     }
    // }, [selectedLCS, selectedLCSS]);

    const handleStakeholderInputs = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log(selectedLCS);
        console.log(selectedLCSS);
        setEnableStakeholderInputs(event.target.checked);
    };

    return (
        <ThemeProvider theme={GeneralTheme}>
            <button onClick={() => { console.log(formState) }}>INPTS</button>
            <CreateProductHeader formState={formState} currentPage='social' form ={form}/>

            <Box
                sx={{
                    width: "-webkit-fill-available",
                    display: "flex",
                    flexDirection: "column",
                    gap: "1vw",
                }}
            >
                <SocialInputsLCSDropdowns
                    setSelectedLCS={setSelectedLCS}
                    setSelectedLCSS={setSelectedLCSS}
                    assessmentMethod="SPA"
                    enableInputs={enableInputs}
                    setEnableInputs={setEnableInputs}
                    isFirstSelection={isFirstSelection}
                    setIsFirstSelection={setIsFirstSelection}
                />
                <Box
                    sx={{
                        width: "-webkit-fill-available",
                        display: "flex",
                        flexDirection: "row",
                        gap: "1vw",
                    }}
                >
                    <Paper
                        square
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: "-webkit-fill-available",
                        }}
                        className={classes.PaperSpacing}
                    >
                        <FormControlLabel
                            className="checkbox-big"
                            label=""

                            control={
                                <form.Field
                                    name="useSPALCS"
                                    children={(field) => {
                                        return (
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        id={field.name}
                                                        checked={field.state.value}
                                                        onChange={(e) => {
                                                            field.handleChange(Boolean(e.target.checked));
                                                            handleStakeholderInputs(e);
                                                        }}
                                                    />
                                                }
                                                label="Life Cycle Stage w/ primary social data to input"
                                            />
                                        );
                                    }}
                                />
                            }
                        />
                        <Box
                            sx={{
                                opacity: enableStakeholderInputs ? "1" : "0.5",
                                pointerEvents: enableStakeholderInputs ? "auto" : "none",   
                                display: 'flex' ,
                                flexDirection: 'column',
                                gap: '1vw',
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "2vw",
                                // '@media (max-width: 1680px)': {
                                //     flexDirection: 'column',
                                //     gap: '5px',
                                // },
                                }}
                                className="spa-lifecycle-wrap"
                            >
                                <SocialInputsLCSDropdownsSPA
                                    setSelectedLCS={setSelectedSPALCS}
                                    setSelectedLCSS={setSelectedSPALCSS}
                                    selectedPSILCALCS={selectedLCS}
                                    selectedPSILCALCSS={selectedLCSS}
                                />
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                }}
                                className="spa-lifecycle-stages"
                            ></Box>

                            <SPAStakeholders 
                            />
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "1vw",
                                    alignItems: "flex-start",
                                }}
                            >
                                <Typography component="h3" variant="h3">
                                Select corresponding process for Comparison
                                </Typography>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "1vw",
                                        alignItems: "stretch",
                                        fontSize: "0.7vw",
                                    }}
                                >
                                    <Box className={classes.ProcessSelect}>
                                        <Typography
                                            component="body"
                                            variant="body1"
                                            sx={{ background: 0 }}
                                        >
                                            {selectedProcess?.name}
                                        </Typography>
                                    </Box>
                                    <Button
                                        size="small"
                                        id="process-popup"
                                        onClick={handleProcessPopupClick} // Ensure this is correctly linked
                                        sx={{
                                            color: "white",
                                            padding: "6px",
                                            fontSize: "0.7vw",
                                            height: "auto !important",
                                            width: "10vw",
                                            minWidth: "auto",
                                        }}
                                    >
                                    Select Process
                                    </Button>
                                </Box>
                            </Box>
                            <Popper
                                id={processPopper}
                                open={open}
                                anchorEl={showSelectProcessPopup}
                                disablePortal
                                placement="top-start"
                                style={{
                                    position: "fixed",
                                    // transform: "none !important",
                                    transform: "translate(0px, 0px) !important",
                                    top: "0 !important",
                                    left: "0 !important",
                                    zIndex: 1300,
                                    width: "100%",
                                    height: "-webkit-fill-available",
                                    backgroundColor: "rgba(0, 0, 0, 0.54)",
                                }}
                            >
                                <SelectSocialProcessPopup
                                    selectHandler={selectProcessHandler}
                                    openProcessSelect={openProcessSelect}
                                />
                            </Popper>
                        </Box>
                    </Paper>
                    <Paper
                        square
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: "-webkit-fill-available",
                            justifyContent: "flex-start",
                            opacity: enableInputs ? "1" : "0.5",
                            pointerEvents: enableInputs ? "auto" : "none",
                        }}
                        className={classes.PaperSpacing}
                    >
                        <Typography component="h2" variant="h2">
                            Inputs
                        </Typography>
                        <Box sx={{ marginTop: "0" }}>
                            <SocialSPAInputProcessList
                                form={form}
                                lifeCycleStage={`${selectedLCS}:${selectedLCSS}`}
                                spaOn
                            />
                        </Box>
                    </Paper>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        gap: "1vw",
                        width: "-webkit-fill-available",
                        justifyContent: "flex-end",
                    }}
                >
                    <Paper
                        square
                        sx={{
                            gap: "1vw",
                            width: "-webkit-fill-available",
                            justifyContent: "center",
                        }}
                    >
                        <Box
                            sx={{
                                display: "grid",
                                gridTemplateColumns: "auto 1fr",
                                justifyItems: "center",
                                alignItems: "center",
                                width: "-webkit-fill-available",
                                opacity: enableInputs ? "1" : "0.5",
                                pointerEvents: enableInputs ? "auto" : "none",
                            }}
                        >
                            <Typography component="h2" variant="h2">
                                Weighting Method
                            </Typography>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    width: "-webkit-fill-available",
                                    gap: "3rem",
                                    justifyContent: "center",
                                    // '@media (max-width: 1680px)': {
                                    //     gap: '1vw',
                                    // },
                                }}
                            >
                                <form.Field
                                    name="socialSPAWeighingMethod"
                                    children={(field) => {
                                        return (
                                            <Dropdown
                                                options={selectableWeighingMethods}
                                                onChange={(e) => {
                                                    field.handleChange(e.target.value);
                                                    console.log(e.target.value);
                                                    selectWeighingMethodHandler(e.target.value);
                                                }}
                                                id={field.name}
                                                className={field.name}
                                                value={field.state.value}
                                                onBlur={field.handleBlur}
                                                label="Weighing Method"
                                                noDefaults
                                            />
                                        );
                                    }}
                                />
                                <form.Field
                                    name="socialSPAWeighingSet"
                                    children={(field) => {
                                        return (
                                            <Dropdown
                                                options={selectableWeighingSets}
                                                onChange={(e) => {
                                                    field.handleChange(e.target.value);
                                                }}
                                                id={field.name}
                                                className={field.name}
                                                value={field.state.value}
                                                onBlur={field.handleBlur}
                                                label="Weighing Set"
                                                noDefaults
                                            />
                                        );
                                    }}
                                />
                            </Box>
                        </Box>
                    </Paper>
                    <CreateProductNavButtons
                        form={form}
                        formState={formState}
                        currentPage="social"
                        editProductId={editProductId}
                    />
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default SocialSPAInputs;
