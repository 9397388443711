import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => {return {
    chartSec: {
        width: '100%',
        height: '63vh',
    },
    topOptions: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
    },
}});

export default useStyles;
