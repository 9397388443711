// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 1680px) {
    .css-kbai53-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
        width: 11rem !important;
        padding: 0vw !important;
    }
    .css-1aty8b6-MuiInputBase-root-MuiOutlinedInput-root {
        width: 11rem !important;
        padding: 0px !important;
    }
}

.css-1aty8b6-MuiInputBase-root-MuiOutlinedInput-root .css-ffi4rr-MuiInputBase-input-MuiOutlinedInput-input, .css-b2g2ww-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-b2g2ww-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-b2g2ww-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 0.5vw;
}

.css-kbai53-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    width: 11rem !important;
    padding: 0vw !important;
}

label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-sizeSmall.MuiInputLabel-outlined.MuiFormLabel-colorPrimary.MuiFormLabel-filled.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-sizeSmall.MuiInputLabel-outlined.css-mmgapu-MuiFormLabel-root-MuiInputLabel-root {
    /* margin-top: -1.5vh; */
}

h3.MuiTypography-root.MuiTypography-h3.css-r431bo-MuiTypography-root {
    font-size: 1.2vw;
}

h4.MuiTypography-root.MuiTypography-h3.css-r431bo-MuiTypography-root {
    font-size: 1vw;
}

.MuiStack-root.css-kq4he3-MuiStack-root {
    text-align: left;
}`, "",{"version":3,"sources":["webpack://./src/components/charts/options/components/GraphSettingsOptions/GraphSettingsOptions.css"],"names":[],"mappings":"AAAA;IACI;QACI,uBAAuB;QACvB,uBAAuB;IAC3B;IACA;QACI,uBAAuB;QACvB,uBAAuB;IAC3B;AACJ;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,uBAAuB;IACvB,uBAAuB;AAC3B;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":["@media (max-width: 1680px) {\r\n    .css-kbai53-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {\r\n        width: 11rem !important;\r\n        padding: 0vw !important;\r\n    }\r\n    .css-1aty8b6-MuiInputBase-root-MuiOutlinedInput-root {\r\n        width: 11rem !important;\r\n        padding: 0px !important;\r\n    }\r\n}\r\n\r\n.css-1aty8b6-MuiInputBase-root-MuiOutlinedInput-root .css-ffi4rr-MuiInputBase-input-MuiOutlinedInput-input, .css-b2g2ww-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-b2g2ww-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-b2g2ww-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {\r\n    padding: 0.5vw;\r\n}\r\n\r\n.css-kbai53-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {\r\n    width: 11rem !important;\r\n    padding: 0vw !important;\r\n}\r\n\r\nlabel.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-sizeSmall.MuiInputLabel-outlined.MuiFormLabel-colorPrimary.MuiFormLabel-filled.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-sizeSmall.MuiInputLabel-outlined.css-mmgapu-MuiFormLabel-root-MuiInputLabel-root {\r\n    /* margin-top: -1.5vh; */\r\n}\r\n\r\nh3.MuiTypography-root.MuiTypography-h3.css-r431bo-MuiTypography-root {\r\n    font-size: 1.2vw;\r\n}\r\n\r\nh4.MuiTypography-root.MuiTypography-h3.css-r431bo-MuiTypography-root {\r\n    font-size: 1vw;\r\n}\r\n\r\n.MuiStack-root.css-kq4he3-MuiStack-root {\r\n    text-align: left;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
