import React from "react";
import "../Homepage.css";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { Link } from "@tanstack/react-router";

const BioProfileMarwa: React.FC = () => {
    return (
        <div className="website-only">
            <Header />

            <div className="header-container">
                <div className="header-transparent-layer"> </div>
                <img
                    src={require("../../images/headers/pexels-pixabay-461956.jpg")}
                    alt="Mountains under the blue sky"
                />
                <h1>Our Team</h1>
            </div>
            <Link to="/ourteam" className="back">
        &lt; Back to the team
            </Link>
            <div className="box-container bio">
                <div className="bio-profile-detailed">
                    <div className="bio-side-one">
                        <img
                            src={require("../../images/staff-photos/Marwa_Hannouf_Pic.jpg")}
                            alt="Marwa"
                        />
                        <h3>Marwa Hannouf</h3>
                        <p>Founder, Director and CEO</p>
                    </div>
                    <div className="bio-side-two">
                        <h3>Her bio</h3>
                        <p>
              Postdoctoral Associate researcher at the University of Calgary
              within Global Research Initiative for Sustainable Low Carbon
              Unconventional Resources (GRI). Having a PhD in Environmental
              Design specialized in Energy and Environmental systems, with a
              business background specializing in Finance. She has strong
              expertise in working with life cycle sustainability assessment
              (LCSA) and its application in companies’ decision-making to
              improve sustainability performance. The technical expertise and
              qualifications she gained during her PhD and continue to build on
              as a postdoc on energy-related technologies, have simulated her
              entrepreneurial interest in taking the project to the next level
              translating the research results into a practical tool for
              sustainability assessment.
                        </p>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
};

export default BioProfileMarwa;
