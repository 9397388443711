import { useState, useMemo, useEffect } from 'react';
import useStyles from './B4PSILCACharts.styles';
import { ThemeProvider } from '@emotion/react';
import FormControl from '@mui/material/FormControl';
import Dropdown from '../../../../../dropdowns/Dropdown';
import { Box, Paper, Divider } from '@mui/material';
import B4PiePSILCA from '../B4PiePSILCA';
import B4BarPSILCA from '../B4BarPSILCA';
import deepClone from '../../../../../../helper/deepClone';
import { ChartJSData, ChartJSOptions } from '../../../../../../interface/ChartData';
import { Product, PSILCAResult } from '../../../../../../interface/Product';
import PSILCAChartNavigation from '../../../../../dropdowns/BaseOptions/PSILCAChartNavigation';
import LCSDropdowns from '../../../../../dropdowns/LCSDropdowns';
import ResultsTheme from '../../../../style/ResultsTheme';
import '../../../../style/ResultsTheme.css';
import { initialOptions } from '../../../../options/InitialOptionValues';
import GraphDownload from '../../../../options/components/ChartDownload';
import ProductComparison from '../../../../options/components/Comparison';
import GraphSetting from '../../../../options/components/GraphSettings';
import { applyValueType } from '../../../../options/util/applyOptions';
import { CreateChartTS } from '../../../../util/CreateChartTS';
import { treemapRGB } from '../../../../../../helper/colors';

interface ChartData {
    product: Product;
}
function B4PSILCACharts(props: ChartData) {
    const classes = useStyles();
    const { product } = props;
    const [chartJSData, setChartJSData] = useState<ChartJSData>({ datasets: [], labels: [] });
    const [chartOptions, setChartOptions] = useState<ChartJSOptions>();
    const [selectedValueType, setSelectedValueType] = useState<string>('');
    const [results, setResults] = useState<PSILCAResult[]>([]);
    const [selectedLCS, setSelectedLCS] = useState<string>('');
    const [selectedLCSS, setSelectedLCSS] = useState<string>('');
    const [selectedProcess, setSelectedProcess] = useState<string>('');

    useMemo(() => {
        const calc = product.calculations.find(
            (item) => {return item.calculationType === 'SOCIAL-PSILCA'},
        );
        const calcResults = calc?.calculationResults as PSILCAResult[];
        setResults((calcResults));
    }, [product]);


    useEffect(() => {
        const chartData: ChartJSData = { datasets: [], labels: [] };
        const options: ChartJSOptions = deepClone<ChartJSOptions>(initialOptions);

        let processes: string[] = [];
        const matchingLCS = results[0].subcategories[0].indicators[0]
            .indicatorResults.find((item) => { return item.lcsName === `${selectedLCS}:${selectedLCSS}` });
        if (matchingLCS) {
            processes = matchingLCS.processResults.processes.map((item) => { return item.process });
        }

        chartData.datasets = processes.map((process, index) => {
            return {
                label: process,
                data: results.map((stakeholder) => {
                    return stakeholder.subcategories
                        .map((subcategory) => {
                            return subcategory.indicators
                                .map((indicator) => {
                                    return indicator.indicatorResults
                                        .find(
                                            (lcs) => { return lcs.lcsName === `${selectedLCS}:${selectedLCSS}`; },
                                        )?.processResults.processes.find(
                                            (item) => { return item.process === process; },
                                        )?.processTotalSum;
                                })
                                .reduce((a: number, b) => { return a + (b || 0); }, 0);
                        })
                        .reduce((a, b) => { return a + b; }, 0);
                }),
                backgroundColor: treemapRGB.slice(0, results.length),
            };
        });

        chartData.labels = results.map(
            (stakeholder) => { return stakeholder.stakeholder; }
        );

        setChartJSData(chartData);
        setChartOptions(options);

    }, [selectedValueType, selectedLCS, selectedLCSS, results]);

    // useEffect(() => {
    //     if (chartJSData && chartOptions) {
    //         let options = deepClone(chartOptions) || {};
    //         let chartData = deepClone(chartJSData);
    //         const applyOptionReturn = applyValueType(selectedValueType, chartData, options, true);
    //         options = applyOptionReturn.options;
    //         chartData = applyOptionReturn.chartData;
    //         CreateChartTS(chartData, options);
    //     }
    // }, [chartJSData, chartOptions]);

    const [applyGraphSettings, setApplyGraphSettings] =
    useState<(options: ChartJSOptions, chartData: ChartJSData) =>
         { options: ChartJSOptions; chartData: ChartJSData; }>();    
    const triggerChartUpdate = () => {
        let options = deepClone(chartOptions) || {};
        let chartData = deepClone(chartJSData);
        const applyOptionReturn = applyValueType(selectedValueType, chartData, options);
        options = applyOptionReturn.options;
        chartData = applyOptionReturn.chartData;
        if (applyGraphSettings) {
            ({ options, chartData } = applyGraphSettings(options, chartData));
        }
        CreateChartTS(chartData, options, 'pie', 'graph2Canvas');
    };
    useEffect(() => {
        if (chartJSData && chartOptions) {
            const applyOptionReturn = applyValueType(selectedValueType, chartJSData, chartOptions);
            const updatedOptions = applyOptionReturn.options;
            const updatedChartData = applyOptionReturn.chartData;
            CreateChartTS(updatedChartData, updatedOptions, 'pie', 'graph2Canvas');
        }
    }, [chartJSData, chartOptions]);

    return (
        <ThemeProvider theme={ResultsTheme}>
            <Box
                sx={{
                    margin: '0',
                    maxWidth: '100%',
                    maxHeight: '-webkit-fill-available',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1vw',
                }}
            >
                <Box className='temp-box' />
                <Box component='div' className='results-wrapper'>
                    <Box component='div' className='results-options'>
                        <Paper square>
                            <Box component='div' className='results-options-top'>
                                <FormControl
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: '3rem',
                                        alignItems: 'stretch',

                                    }}
                                >
                                    <PSILCAChartNavigation product={product} defaultBaseIndex={1} defaultResultIndex={3} />
                                    <ProductComparison />
                                </FormControl>
                            </Box>
                        </Paper>
                        <Divider />
                        <Paper square>
                            <Box component='div' className='results-options-bottom'>
                                <Box>
                                    <FormControl
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}
                                        className='bottom-options side-left'
                                    >
                                        <LCSDropdowns
                                            product={product}
                                            selectedLCS={selectedLCS}
                                            setSelectedLCS={setSelectedLCS}
                                            selectedLCSS={selectedLCSS}
                                            setSelectedLCSS={setSelectedLCSS}
                                            selectedProcess={selectedProcess}
                                            setSelectedProcess={setSelectedProcess}
                                            dimension="Social"
                                        />
                                        <Dropdown
                                            options={['Raw Values', '% Per Process']}
                                            valueSetter={setSelectedValueType}
                                            value={selectedValueType}

                                        />

                                    </FormControl>
                                </Box>
                                <Box>
                                    <FormControl
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            '@media (max-width: 1680px)': {
                                                gap: '1vw',
                                            },
                                        }}
                                        className='bottom-options side-right'
                                    >
                                        <GraphDownload />
                                        <GraphSetting
                                            chartOptions={chartOptions}
                                            setApplyGraphSettings={setApplyGraphSettings}
                                            triggerChartUpdate={triggerChartUpdate}
                                            chartData = {chartJSData}
                                            chartColorType = 'treemap'
                                        />

                                    </FormControl>
                                </Box>
                            </Box>
                        </Paper>
                    </Box>
                </Box>
                <Box className={classes.chartSec}>
                    <Paper
                        square
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '-webkit-fill-available',
                            height: '-webkit-fill-available',
                        }}
                    >
                        <B4BarPSILCA product={product} selectedLCS={selectedLCS} selectedLCSS={selectedLCSS} selectedValueType={selectedValueType}
                            setApplyGraphSettings={setApplyGraphSettings}
                            triggerChartUpdate={triggerChartUpdate}
                            
                        />

                    </Paper>
                    <Paper
                        square
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '-webkit-fill-available',
                            height: '-webkit-fill-available',
                        }}
                    >
                        <B4PiePSILCA
                            product={product}
                            selectedLCS={selectedLCS}
                            selectedLCSS={selectedLCSS}
                            selectedProcess={selectedProcess}
                            selectedValueType={selectedValueType}
                            // setApplyGraphSettings={setApplyGraphSettings}
                            // triggerChartUpdate={triggerChartUpdate}
                        />
                    </Paper>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default B4PSILCACharts;
