import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import useStyles from './B4PieSPA.styles';
import deepClone from '../../../../../../helper/deepClone';
import { ChartJSData, ChartJSOptions } from '../../../../../../interface/ChartData';
import { Product, PSILCAResult, SPAResult } from '../../../../../../interface/Product';
import PSILCAChartNavigation from '../../../../../dropdowns/BaseOptions/PSILCAChartNavigation';
import ResultsTheme from '../../../../style/ResultsTheme';
import { initialOptions } from '../../../../options/InitialOptionValues';
import ChartDownload from '../../../../options/components/ChartDownload';
import ProductComparison from '../../../../options/components/Comparison';
import GraphSetting from '../../../../options/components/GraphSettings';
import { CreateChartTS } from '../../../../util/CreateChartTS';
import { palette1, palette2, palette3, palette4, processColors, treemapRGB } from '../../../../../../helper/colors';
import { useFormContext } from '../../../../../forms/FormContext';
import { WeighingMethod, WeighingSet, applyWeight, getWeighingSetValue, weighingMethods } from '../../../../../../helper/weighingMethod';
import Dropdown from '../../../../../dropdowns/Dropdown';
import SPAChartNavigation from '../../../../../dropdowns/BaseOptions/SPAChartNavigation';
import { applyValueType } from '../../../../options/util/applyOptions';
import { useCallback, useEffect, useMemo, useState } from 'react';
import LCSDropdowns from '../../../../../dropdowns/LCSDropdowns';
import autoRound from '../../../../../../helper/autoRound';



interface ChartData {
    product: Product,
    selectedLCS: string,
    selectedLCSS: string,
    selectedProcess: string,
    selectedValueType: string,
}


function B4PieSPA(props: ChartData) {
    const { formState, setFormState } = useFormContext();
    const classes = useStyles();
    const { product, selectedLCS, selectedLCSS, selectedProcess, selectedValueType } = props;
    const [chartJSData, setChartJSData] = useState<ChartJSData>({ datasets: [], labels: [] });
    const [chartOptions, setChartOptions] = useState<ChartJSOptions>(initialOptions);
    const [colorIdentifiers, setColorIdentifiers] = useState<ChartColorIdentifiers[]>([]);  
    const [currentWeighingSet, setCurrentWeighingSet] = useState<WeighingSet>();
    const [results, setResults] = useState<SPAResult>();

    useMemo(() => {
        const calc = product.calculations.find(
            (item) => { return item.calculationType === 'SOCIAL-SPA' },
        );
        const calcResults = calc?.calculationResults as unknown as SPAResult;
        console.log(calc);
        const weighingMethod = weighingMethods.find((method) => method.name === product.socialData.weighingMethod.name);
        const weighingSet = weighingMethod?.sets.find((set) => set.name === product.socialData.weighingMethod.set);
        setCurrentWeighingSet(weighingSet);
        setResults((calcResults));
    }, [product]);








    useEffect(() => {
        console.log(results);

        if (!results || !currentWeighingSet)
            return;


        const chartData: ChartJSData = { datasets: [], labels: [] };
        const options: ChartJSOptions = deepClone<ChartJSOptions>(initialOptions);
        const chartColorIdentifiers : ChartColorIdentifiers[] = [];

        if (options?.plugins?.datalabels) {
            options.plugins.datalabels.color = '#020202';
            options.plugins.datalabels.anchor = 'center';
            options.plugins.datalabels.align = 'center';
            // options.plugins.datalabels.textShadowBlur = 5;
            // options.plugins.datalabels.textShadowColor = 'rgba(0, 0, 0, 1)';
            options.plugins.datalabels.formatter =  function (value: any, context: any) {
                if (value === null || value === undefined) {
                    return "";
                }
    
                const maxThresholdValue = Math.max(
                    ...chartData.datasets.flatMap((dataset) => dataset.data)
                );
    
                let thresholdPercentage;
                switch (selectedValueType) {
                case '% Per Stakeholder':
                    thresholdPercentage = .10;
                    break;
                default:
                    thresholdPercentage = 0.15;
                    break;
                }

                const totalThreshold = maxThresholdValue * thresholdPercentage;
    
                if (value < totalThreshold) {
                    return "";
                } else {
    
                    return value.toString();  
                }
            };
        };

        const spaLifeCycle =
            results.lifeCycleResults.find((lcs) => lcs.lcsName === `${selectedLCS}:${selectedLCSS}`);
        console.log(results);
        if (!spaLifeCycle) {
            //SPA Process LC
            if(!results.SPAProcess)
                return;

            const stakeholders = deepClone(results.stakeholderResults);

            const val = stakeholders.map(
                (item) =>
                    //autoRound(
                    autoRound(
                        item.SPAPriorities.reduce((val1, val2) => {
                            return val1 + applyWeight(val2, "sam", currentWeighingSet);
                        }, 0), 2
                    )
                //)
            );

            chartColorIdentifiers.push({label: results.SPAProcess.name});

            chartData.datasets = [
                {
                    label: results.SPAProcess.name,
                    data: val,
                    backgroundColor: treemapRGB, //["#e76565", "#3da1db", "#f0b975", "#3ea876",],

                    //backgroundColor: "#FFCAB1",
                    borderColor: "white",
                    borderWidth: 2,
                },
            ];
            console.log("HERE");
        } else {
            chartData.labels = spaLifeCycle.processResults[0].PSILCAPriorities
                .map(item => item.category.split(":")[0])
                .filter((value, index, self) => self.indexOf(value) === index);


            spaLifeCycle.processResults.forEach((process, index) => {
                chartColorIdentifiers.push({label: process.processName});

                chartData.datasets.push({
                    label: process.processName,
                    data: process.PSILCAPriorities
                        .map(item => item.category.split(":")[0])
                        .filter((value, index, self) => self.indexOf(value) === index).map((stakeholder) => {
                            return (
                                Math.round(
                                    process.PSILCAPriorities.filter((item2) =>
                                        item2.category.startsWith(stakeholder)
                                    ).reduce((val1, val2) => {
                                        return val1 + applyWeight(val2, 'psilca', currentWeighingSet);
                                    }, 0) * 100
                                ) / 100
                            );
                        }),

                    // backgroundColor:
                    //     selectedPalette[
                    //     // getGraphColorIndex({
                    //     //     label: process.processName,
                    //     //     identifier: lifeCycle.lcsName,
                    //     // })
                    //         index
                    //     ],
                    //backgroundColor: ["#EB9E43", "#F5C48A", "#613000", "#E971A2"],//colors[index], //random color from array
                    borderColor: "000000",
                });
            });

            if (selectedProcess) {
                const matchingDataSet = chartData.datasets.find(
                    (item) => item.label === selectedProcess.split(" - ")[0]
                );
                if (matchingDataSet) {
                    chartData.datasets = [
                        deepClone(
                            matchingDataSet
                        ),
                    ];
                }


                if (chartData.datasets[0]) {
                    chartData.datasets[0].backgroundColor = treemapRGB; //colorsrgb;
                    chartData.datasets[0].borderWidth = 2;
                    chartData.datasets[0].borderColor = "#FFFFFF";
                }
            }

        }
        console.log(chartData);
        setChartJSData(chartData);
        setChartOptions(options);
        setColorIdentifiers(chartColorIdentifiers);

    }, [results, product,  currentWeighingSet, selectedValueType, selectedProcess]);


    const [applyGraphSettings, setApplyGraphSettings] =
        useState<(options: ChartJSOptions, chartData: ChartJSData, colorIdentifiers?: ChartColorIdentifiers[]) => 
            { options: ChartJSOptions; chartData: ChartJSData; }>();
    const triggerChartUpdate = () => {
        let options = deepClone(chartOptions) || {};
        let chartData = deepClone(chartJSData);
        const applyOptionReturn = applyValueType(selectedValueType, chartData, options, false, 'pie');
        options = applyOptionReturn.options;
        chartData = applyOptionReturn.chartData;
        if (applyGraphSettings) {
            ({ options, chartData } = applyGraphSettings(options, chartData, colorIdentifiers));
        }
        console.log(options);
        options.plugins = {
            ...options?.plugins,
            datalabels: {
                ...options?.plugins?.datalabels,
                color: 'black',
            },
        };
        CreateChartTS(chartData, options, 'pie', 'graph2Canvas');
    };
    useEffect(() => {
        if (chartJSData && chartOptions) {
            triggerChartUpdate();
        }
    }, [chartJSData, chartOptions]);


    return (
        <Box className={classes.chartSec}>
            <div className='settings-two-graph'>
                <ChartDownload chartData={chartJSData} 
                    chartName={'Stakeholder level result - Process'} chartType='Pie Chart' />
                <GraphSetting
                    chartOptions={chartOptions}
                    setApplyGraphSettings={setApplyGraphSettings}
                    triggerChartUpdate={triggerChartUpdate}
                    colorIdentifiers={colorIdentifiers}
                    chartData={chartJSData}
                    chartColorType='treemap'
                    graphType='pie'
                />
            </div>
            <canvas id='graph2Canvas' className='graph-canvas' />
        </Box>
    );
}

export default B4PieSPA;
