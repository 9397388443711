import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import useStyles from './B4BarSPA.styles';
import deepClone from '../../../../../../helper/deepClone';
import { ChartJSData, ChartJSOptions } from '../../../../../../interface/ChartData';
import { Product, PSILCAResult, SPAResult } from '../../../../../../interface/Product';
import PSILCAChartNavigation from '../../../../../dropdowns/BaseOptions/PSILCAChartNavigation';
import ResultsTheme from '../../../../style/ResultsTheme';
import { initialOptions } from '../../../../options/InitialOptionValues';
import ChartDownload from '../../../../options/components/ChartDownload';
import ProductComparison from '../../../../options/components/Comparison';
import GraphSetting from '../../../../options/components/GraphSettings';
import { CreateChartTS } from '../../../../util/CreateChartTS';
import { useFormContext } from '../../../../../forms/FormContext';
import { WeighingMethod, WeighingSet, applyWeight, getWeighingSetValue, weighingMethods } from '../../../../../../helper/weighingMethod';
import Dropdown from '../../../../../dropdowns/Dropdown';
import SPAChartNavigation from '../../../../../dropdowns/BaseOptions/SPAChartNavigation';
import { applyValueType } from '../../../../options/util/applyOptions';
import { useCallback, useEffect, useMemo, useState } from 'react';
import LCSDropdowns from '../../../../../dropdowns/LCSDropdowns';
import autoRound from '../../../../../../helper/autoRound';



interface ChartData {
    product: Product,
    selectedLCS: string,
    selectedLCSS: string,
    selectedValueType: string,
}
function B4BarSPA(props: ChartData) {
    const { formState, setFormState } = useFormContext();
    const classes = useStyles();
    const { product, selectedLCS, selectedLCSS, selectedValueType } = props;
    const [chartJSData, setChartJSData] = useState<ChartJSData>({ datasets: [], labels: [] });
    const [chartOptions, setChartOptions] = useState<ChartJSOptions>(initialOptions);
    const [colorIdentifiers, setColorIdentifiers] = useState<ChartColorIdentifiers[]>([]);  
    const [currentWeighingSet, setCurrentWeighingSet] = useState<WeighingSet>();
    const [selectedProcess, setSelectedProcess] = useState('');
    const [results, setResults] = useState<SPAResult>();

    useMemo(() => {
        const calc = product.calculations.find(
            (item) => { return item.calculationType === 'SOCIAL-SPA' },
        );
        const calcResults = calc?.calculationResults as unknown as SPAResult;
        console.log(calc);
        const weighingMethod = weighingMethods.find((method) => method.name === product.socialData.weighingMethod.name);
        const weighingSet = weighingMethod?.sets.find((set) => set.name === product.socialData.weighingMethod.set);
        setCurrentWeighingSet(weighingSet);
        setResults((calcResults));
    }, [product]);








    useEffect(() => {
        console.log(results);

        if (!results || !currentWeighingSet)
            return;


        const chartData: ChartJSData = { datasets: [], labels: [] };
        const options: ChartJSOptions = deepClone<ChartJSOptions>(initialOptions);
        const chartColorIdentifiers : ChartColorIdentifiers[] = [];

        if (options.scales && options.scales.y && options.scales.y.title) {
            options.scales.y.title.text = "Weighted Score";
        }


        const spaLifeCycle =
            results.lifeCycleResults.find((lcs) => lcs.lcsName === `${selectedLCS}:${selectedLCSS}`);
        if (!spaLifeCycle) {
            //SPA Process LC

            if (!results.SPAProcess)
                return;

            const stakeholders = deepClone(results.stakeholderResults);

            chartData.labels = stakeholders.map((item) => item.name);
            const val = stakeholders.map(
                (item) =>
                    //autoRound(
                    autoRound(
                        item.SPAPriorities.reduce((val1, val2) => {
                            return val1 + applyWeight(val2, "sam", currentWeighingSet);
                        }, 0), 2
                    )
                //)
            );
            chartColorIdentifiers.push({label: results.SPAProcess.name});

            chartData.datasets.push({
                label: results.SPAProcess.name,
                data: val,

                // backgroundColor:
                //     selectedPalette[
                //         0
                //     // getGraphColorIndex({
                //     //     label: results.SPAProcess.name,
                //     //     identifier: lifeCycle,

                //     // })
                //     ],
                borderColor: "000000",
            });

        } else {
            //not SPA LC

            chartData.labels = spaLifeCycle.processResults[0].PSILCAPriorities
                .map((item) => item.category.split(":")[0])
                .filter((value, index, self) => self.indexOf(value) === index);


            spaLifeCycle.processResults.forEach((process, index) => {
                chartColorIdentifiers.push({label: process.processName});

                chartData.datasets.push({
                    label: process.processName,
                    data: process.PSILCAPriorities
                        .map((item) => item.category.split(":")[0])
                        .filter((value, index, self) => self.indexOf(value) === index).map((stakeholder) => {
                            return (
                                Math.round(
                                    process.PSILCAPriorities.filter((item2) =>
                                        item2.category.startsWith(stakeholder)
                                    ).reduce((val1, val2) => {
                                        return val1 + applyWeight(val2, 'psilca', currentWeighingSet);
                                    }, 0) * 100
                                ) / 100
                            );
                        }),

                    // backgroundColor:
                    //     selectedPalette[
                    //         index
                    //     //   getGraphColorIndex({
                    //     //       label: process.processName,
                    //     //       identifier: spaLifeCycle.lcsName,
                    //     //   })
                    //     ],
                    //backgroundColor: ["#EB9E43", "#F5C48A", "#613000", "#E971A2"],//colors[index], //random color from array
                    borderColor: "000000",
                });
            });

        }
        console.log(chartData);
        setChartJSData(chartData);
        setChartOptions(options);
        setColorIdentifiers(chartColorIdentifiers);

    }, [results, product,  currentWeighingSet, selectedValueType, selectedLCS, selectedLCSS]);
    const [applyGraphSettings, setApplyGraphSettings] =
        useState<(options: ChartJSOptions, chartData: ChartJSData, colorIdentifiers?: ChartColorIdentifiers[]) => 
            { options: ChartJSOptions; chartData: ChartJSData; }>();
    const triggerChartUpdate = () => {
        if (!chartJSData.datasets || !chartOptions) {
            return; // Exit early if datasets or options are not properly initialized
        }

        let options = deepClone(chartOptions);
        let chartData = deepClone(chartJSData);
        const applyOptionReturn = applyValueType(selectedValueType, chartData, options, false, 'pie');
        options = applyOptionReturn.options;
        chartData = applyOptionReturn.chartData;
        if (applyGraphSettings) {
            ({ options, chartData } = applyGraphSettings(options, chartData, colorIdentifiers));

        }

        CreateChartTS(chartData, options);
    };
    useEffect(() => {
        if (chartJSData && chartOptions) {
            triggerChartUpdate();
        }
    }, [chartJSData, chartOptions]);

    return (

        <Box className={classes.chartSec}>
            <div className='settings-two-graph'>
                <ChartDownload chartData={chartJSData} 
                    chartName={'Stakeholder level result - Process'} chartType='Bar Graph' />
                <GraphSetting
                    chartOptions={chartOptions}
                    setApplyGraphSettings={setApplyGraphSettings}
                    triggerChartUpdate={triggerChartUpdate}
                    colorIdentifiers={colorIdentifiers}
                    chartData={chartJSData}
                    chartColorType='process'

                />
            </div>
            <canvas id='graph1Canvas' className='graph-canvas' />
        </Box>


    );
}

export default B4BarSPA;
