import { createTheme, ThemeProvider } from '@mui/material';
import { hover } from '@testing-library/user-event/dist/hover';
import { styled } from '@mui/material/styles';
import { green } from '@mui/material/colors';

const GeneralTheme = createTheme({
    components: {
        MuiPaper: {
            defaultProps: {
                elevation: 4,
            },
            styleOverrides: {
                root: {
                    elevation: 4,
                    padding: '10px',
                },
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    body1: {
                        fontSize: 14,
                        fontWeight: 'bold',
                        fontFamily: 'Roboto, sans-serif',
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    width: '158px',
                    height: 'auto',
                    borderRadius: 0,
                    fontWeight: 'bold',
                    fontFamily: '"Montserrat", sans-serif',
                    fontSize: '1.2vw',
                    textTransform: 'capitalize',
                    backgroundColor: '#166d46',
                    '&:hover': {
                        backgroundColor: '#D9EFDF',
                        color: '#004021',
                    },
                    '@media (max-width: 1680px)': {
                        // fontSize: '20px',
                        padding: '5px',
                        // height: '55px',
                    },
                    '@media (max-width: 1300px)': {
                        // fontSize: '12px',
                        padding: '5px',
                        height: '45px',
                        // width: '120px',
                    },
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: green[800],
                    fontSize: '1vw',
                    '&.Mui-checked': {
                        color: green[800],
                    },
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    // width: '15rem',
                    width: 'auto',
                    '@media (max-width: 1680px)': {
                        width: '11rem',
                    },
                },
            },
        },
    },
    typography: {
        fontFamily: 'Montserrat, sans-serif',
        h2: {
            fontSize: '1.4vw',
            fontWeight: 'bold',
            fontFamily: 'Montserrat, sans-serif',
            // '@media (max-width: 1680px)': {
            //     fontSize: 24,
            // },
            // '@media (max-width: 1300px)': {
            //     fontSize: 14,
            // },
        },
        h3: {
            // fontSize: '1.15vw',
            fontSize: '1vw',
            fontWeight: 'bold',
            fontFamily: 'Montserrat, sans-serif',
            // '@media (max-width: 1680px)': {
            //     fontSize: 18,
            // },
            // '@media (max-width: 1300px)': {
            //     fontSize: '1.5vw',
            // },
        },
        body1: {
            fontFamily: 'Roboto, sans-serif',
            fontSize: '0.7vw',
            textAlign:'left',
            // '@media (max-width: 1300px)': {
            //     fontSize: '0.8vw',
            // },
        },
    },
});

export default GeneralTheme;
