// NOTE: RUNNING A FORMATTER IN HERE WILL CAUSE ESLINT INDENT WARNING
import { Chart } from 'chart.js'; // Ensure you import Chart correctly
import { ChartJSOptions } from '../../../interface/ChartData';

function getDynamicFontSize(vw) {
    // Elements options apply to all of the options unless overridden in a dataset
    // In this case, we are setting the border of each horizontal bar to be 2px wide
    return Math.max(10, Math.round(window.innerWidth * (vw / 100)));
}

function getContrastingColor(backgroundColor) {
    const color = Array.isArray(backgroundColor) ? backgroundColor[0] : backgroundColor;

    const rgb = color.match(/\d+/g).map(Number);
    const brightness = Math.round(((rgb[0] * 299) + (rgb[1] * 587) + (rgb[2] * 114)) / 1000);

    return brightness > 200 ? 'black' : 'white';
}



export const initialOptions: ChartJSOptions = {
    indexAxis: 'x',
    interaction: { // Note: keeping this object while using a pie chart causes an error and breaks the tooltips
        mode: 'y',
    },
    elements: {
        bar: {
            borderWidth: 0,
        },
        line: {
            borderWidth: 1,
        },
    },
    responsive: true,
    maintainAspectRatio: false,
    devicePixelRatio: 3,
    plugins: {
        customCanvasBackgroundColor: {
            color: 'white',
        },
        legend: {
            position: 'bottom',
            display: true,
            labels: {
                font: {
                    size: getDynamicFontSize(0.7),
                    family: 'Roboto',
                },
            },
        },
        datalabels: {
            display: true,
            anchor: 'end',
            align: 'top',
            font: (context) => {
                const barColor = context.dataset.backgroundColor;
                return {
                    size: getDynamicFontSize(0.5),
                    family: 'Roboto',
                    weight: 'normal',
                    color: getContrastingColor(barColor),
                };
            },
            // textStrokeColor: 'white',
            // textStrokeWidth: 2,
            labels: {
                title: {
                    font: {
                        weight: '700',
                    },
                },
            },
            formatter: function (value, context) {
                const threshold = 1.01;
                return value < threshold ? null : value.toFixed(2);
            },
        },
        title: {
            display: false,
            text: '',
            font: {
                size: getDynamicFontSize(1),
                family: 'Roboto',
            },
        },
        tooltip: {
            enabled: true,

            position: 'nearest',
            padding: 6,
            mode: 'point',
            intersect: false,
            afterLabel: function(tooltipItem) {

                return tooltipItem.dataset.raw[tooltipItem.dataIndex][0]; // Show value1
            },
        },
    },
    scales: {
        x: {
            stacked: false,
            ticks: {
                font: {
                    size: getDynamicFontSize(0.7),
                    family: 'Roboto',
                    weight: 'bold',
                },
                display: false,
            },
            title: {
                display: false,
                text: '',
                font: {
                    size: getDynamicFontSize(1),
                    family: 'Roboto',
                },
            },
            categoryPercentage: 0.5,
            barPercentage: 0.5,
        },
        y: {
            title: {
                display: true,
                text: '',
                font: {
                    size: getDynamicFontSize(1),
                    family: 'Roboto',
                    weight: 'bold',
                },
            },
            beginAtZero: true,
            stacked: false,
            ticks: {
                maxTicksLimit: 4,
                font: {
                    size: getDynamicFontSize(0.7),
                    family: 'Roboto',
                },
            },
        },
        // r: {
        //     pointLabels: {
        //         font: {
        //             size: getDynamicFontSize(0.7),
        //             family: 'Roboto',
        //         },
        //     },
        // },
    },
    layout: {
        padding: {
            top: 30,
            bottom: 5,
            left: 5,
            right: 5,
        },
    },
};

// Function to update the chart with new options
function updateChartOptions(chart) {
    chart.options.plugins.legend.labels.font.size = getDynamicFontSize(1);
    chart.options.plugins.title.font.size = getDynamicFontSize(1);
    chart.options.plugins.datalabels.font.size = getDynamicFontSize(0.7);
    chart.options.scales.r.pointLabels.font.size = getDynamicFontSize(0.7);
    // chart.options.scales.x.ticks.font.size = getDynamicFontSize(0.7);
    // chart.options.scales.x.title.font.size = getDynamicFontSize(1);
    // chart.options.scales.y.title.font.size = getDynamicFontSize(1);
    // chart.options.scales.y.ticks.font.size = getDynamicFontSize(0.7);
    const isRadial = chart.config.type === 'radar' || chart.config.type === 'polarArea'
    || chart.config.type === 'pie';

    if (!isRadial) {
        chart.options.scales.x.ticks.font.size = getDynamicFontSize(0.7);
        chart.options.scales.x.title.font.size = getDynamicFontSize(1);
        chart.options.scales.y.ticks.font.size = getDynamicFontSize(0.7);
        chart.options.scales.y.title.font.size = getDynamicFontSize(1);
        chart.options.scales.x.title.display = false;

    } else {
        if (!chart.options.scales.r) {
            chart.options.scales.r = {
                pointLabels: {
                    font: {
                        size: getDynamicFontSize(0.7),
                        family: 'Roboto',
                    },
                },
            };
        }
        if (chart.options.scales.x) {
            delete chart.options.scales.x;
        }
        if (chart.options.scales.y) {
            delete chart.options.scales.y;
        }
        // if (chart.options.scales.r) {
        //     chart.options.scales.r.pointLabels.font.size = getDynamicFontSize(0.7);
        // }
        // if (chart.options.scales.x) {
        //     delete chart.options.scales.x;
        // }
        // if (chart.options.scales.y) {
        //     delete chart.options.scales.y;
        // }
    }
    
    chart.update();
}

// Add event listener to update chart on window resize
window.addEventListener('resize', () => {
    const chart = Chart.getChart('graph1Canvas'); // Replace with your chart ID or chart instance
    if (chart) {
        updateChartOptions(chart);
    }
});
