import React from "react";
import "./Header.css";
import { Link } from "@tanstack/react-router";
import TossaLogo from "../../uploads/images/tossa_logo.png";

const Header: React.FC = () => {
    return (
        <div className="website-only menu-wrap">
            <nav className="menu">
                {/* <div className='menu-home'>--> */}
                <Link to="/">
                    <img
                        src={TossaLogo}
                        width="150px"
                        alt="Icon version of the logo"
                        id="logo"
                    />
                </Link>
                {/* <!--</div>--> */}
                <div className="menu-right">
                    <div className="menu-dropdown">
                        <button className="menu-dropper">About</button>
                        <div className="dropper-items">
                            <Link to="/about/whoarewe">Who Are We?</Link>
                            <Link to="/about/ourmission">Our Mission</Link>
                        </div>
                    </div>
                    <Link to="/ourteam">Our Team</Link>
                    <div className="menu-dropdown">
                        <button className="menu-dropper">
                            <Link to="/services">Services</Link>
                        </button>
                        <div className="dropper-items">
                            <Link to="/services/tossasoftware">TOSSA Software</Link>
                            <Link to="/services/consultingservices">Consulting Services</Link>
                            <Link to="/services/training">Training</Link>
                        </div>
                    </div>
                    <div className="menu-dropdown">
                        <button className="menu-dropper">Resources</button>
                        <div className="dropper-items resource-dropper">
                            <Link to="/resources/resourceswhitepp">
                White Papers & Publications
                            </Link>
                            <Link to="/resources/webinars">Webinars</Link>
                            <Link to="/resources/faq">FAQ</Link>
                        </div>
                    </div>
                    <Link to="/contact">Contact</Link>
                    <Link to="/app" className="button-link">
            Access Application (WIP)
                    </Link>
                </div>
            </nav>

            <nav className="mobile-menu">
                <Link to="/">
                    <img
                        src={require("../images/TOSSA_Logo.png")}
                        width="200px"
                        alt="Icon version of the logo"
                        id="logo-mobile"
                    />
                </Link>
                <input type="checkbox" id="mobile-menu-button" />
                <label htmlFor="mobile-menu-button" id="mobile-menu-button-icon">
                    <img
                        src={require("../images/buttons/Mobile-button.png")}
                        alt="Mobile button"
                        width="50px"
                    />
                </label>
                <ul>
                    <div className="mobile-menu-line"></div>
                    <li>About</li>
                    <li className="mobile-under-section">
                        <Link to="/about/whoarewe">Who Are We?</Link>
                    </li>
                    <li className="mobile-under-section">
                        <Link to="/about/ourmission">Our Mission</Link>
                    </li>
                    <li>
                        <Link to="/ourteam">Our Team</Link>
                    </li>
                    <li>
                        <Link to="/services">Services</Link>
                    </li>
                    <li className="mobile-under-section">
                        <Link to="/services/lcsasoftware">LCSA Software</Link>
                    </li>
                    <li className="mobile-under-section">
                        <Link to="/services/consultingservices">Consulting Services</Link>
                    </li>
                    <li className="mobile-under-section">
                        <Link to="/services/training ">Training</Link>
                    </li>
                    <li>Resources</li>
                    <li className="mobile-under-section">
                        <Link to="/resources/resourceswhitepp">
              White Papers & Publications
                        </Link>
                    </li>
                    <li className="mobile-under-section">
                        <Link to="/resources/webinars">Webinars</Link>
                    </li>
                    <li className="mobile-under-section">
                        <Link to="/resources/faq">FAQ</Link>
                    </li>
                    <li>
                        <Link to="/contact">Contact</Link>
                    </li>
                </ul>
            </nav>
        </div>
    );
};

export default Header;
