import React from "react";
import "../Homepage.css";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const ServicesConsultingServices: React.FC = () => {
    return (
        <div className="website-only">
            <Header />

            <div className="header-container">
                <div className="header-transparent-layer"> </div>
                <img
                    src={require("../../images/headers/pexels-pixabay-209756.jpg")}
                    alt="Sun shining down the green forest"
                />
                <h1>Services</h1>
            </div>
            <div className="box-container">
                <h2>Consulting Services</h2>
                <p className="p-hide">
          You don’t need to own the tool to benefit from the results of
          sustainability assessments generated from using TOSSA services. At
          TOSSA Sustainability Services, we do recognize that not all our
          customers may have the technical and human resources to run TOSSA, our
          team will add more flexibility by providing customized assessments for
          customers through consultancy services using TOSSA. TOSSA provides
          LCA, ESG and sustainability-consulting. We create value through
          sustainability and life cycle thinking. We empower organizations to
          make them impactful, with scientific-based decisions that shift their
          organizational and sustainability performance. Move from theory to
          action with a practical, purpose-led plan that will deliver sustained
          outcomes.
                </p>
            </div>
            <div className="box-container">
                <h2>Our Sustainability Solutions</h2>
                <div className="image-container-two">
                    <div className="solution-container-two">
                        <img
                            src={require("../../images/box-images/pexels-eberhard-grossgasteiger-1062249.jpg")}
                            alt="Mountains with lake"
                        />
                        <h3>LCSA Advising</h3>
                        <ul>
                            <li>
                Helping your company’s performance become more environmentally
                friendly
                            </li>
                            <li>
                Building actionable strategies to reduce your environmental
                impacts
                            </li>
                            <li>
                Encouraging economic growth while driving societal benefits
                through sustainability assessment performance
                            </li>
                            <li>
                Measuring LCSA impact to help organizations develop and maintain
                transparency along the supply chain through scientific and
                holistic decisions.
                            </li>
                            <li>
                Evaluating, reporting on, and communicating environmental,
                social and economic performance of companies, products or
                services
                            </li>
                            <li>
                Driving future effectiveness of LCSA efforts through early
                analytics
                            </li>
                            <li>
                Reporting on your companies’ sustainability performance in
                connection with sustainable development goals (SDGs){" "}
                            </li>
                        </ul>
                    </div>
                    <div className="solution-container-two">
                        <img
                            src={require("../../images/box-images/pexels-greg-galas-2092828.jpg")}
                            alt="Forest with path"
                        />
                        <h3>ESG Services</h3>
                        <ul>
                            <li>
                Incorporating environmental, social, and governance factors into
                investment decisions, to better manage risk and generate
                sustainable, long-term return
                            </li>
                            <li>
                Screening, measuring, and mitigating the risks of an
                organization’s environmental and social performance towards
                sustainable development investments.
                            </li>
                            <li>
                Integrating social and environmental metrics into investments
                activities
                            </li>
                            <li>
                Reporting and communicating ESG metrics based on consistent life
                cycle standards
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
};

export default ServicesConsultingServices;
