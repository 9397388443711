import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import TableTheme from "../../../../style/components/TableTheme";
import { styled, ThemeProvider } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Button from "@mui/material/Button";
import AddButton from "../../../../style/components/AddButton";
import Popper, { PopperProps } from "@mui/material/Popper";
import { FormApi } from "@tanstack/react-form";
import {
    EnvFlow,
    EnvFlowInputLCS,
    EnvironmentalInputsForm,
} from "../../../../../interface/Form";
import { EnvFlowListItem } from "../EnvFlowListItem";
import { fetchUnitGroups } from "../../../../../helper/fetchUtil";
import { UnitGroup } from "../../../../../interface/Unit";
import { SelectFlow } from "../../../../../interface/Flow";
import SelectEnvProductFlowPopup from "../SelectEnvProductFlowPopup";
import { useFormContext } from "../../../../forms/FormContext";

const InputTableCell = styled(TableCell)(({ theme }) => {
    return {
        width: "-webkit-fill-available",
        fontSize: "0.7vw",
        lineHeight: "1.1vw",
        padding: "5px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "#004021",
            color: "white",
            height: "3vw",
        },
        [`&.${tableCellClasses.body}`]: {
            // fontSize: 14,
        },
        "@media (max-width: 1680px)": {
            padding: "5px",
            // fontSize: 10,
            // lineHeight: '15px',
        },
        "&:last-of-type": {
            width: "2rem",
            padding: "0",
            display: "flex",
            justifyContent: "center",
            color: "#004021",
            // height: '5.5rem',
            "@media (max-width: 1680px)": {
                // width: '7rem',
            },
        },
    };
});
interface EnvInputEmissionsListProps {
    envForm: FormApi<EnvironmentalInputsForm>;
    lifeCycleStage: string;
}

function EnvInputEmissionsList({
    envForm,
    lifeCycleStage,
}: EnvInputEmissionsListProps) {
    const [flowPopupEnv, setFlowPopupEnv] = useState<null | HTMLElement>(null);
    const [loadedUnitGroups, setLoadedUnitGroups] = useState<UnitGroup[]>([]);
    const { formState, setFormState } = useFormContext();

    useEffect(() => {
        fetchUnitGroups()
            .then((val) => {
                setFormState({ ...formState, unitGroups: val });
                setLoadedUnitGroups(val);
            })
            .catch((err) => {
            // Handle the error if needed
            });

    }, []);

    const [currentFlows, setCurrentFlows] = useState<EnvFlow[]>([]);

    const addNewFlow = (flow: SelectFlow) => {
        console.log(flow);
        if (flow) {
            const newIndex = currentFlows.length;
            const unitGroup = loadedUnitGroups.find((_unitGroup) => {
                return _unitGroup.id === flow.unitGroup;
            });
            if (unitGroup) {
                const unit = unitGroup.units[0];
                const newFlow: EnvFlow = {
                    index: newIndex,
                    endProductName: "",
                    flowId: flow.id,
                    flowName: flow.name,
                    flowQuantity: 1,
                    flowUnit: unit.id,
                    flowUnitGroup: unitGroup?.id,
                    flowComment: "",
                    category: flow.category,
                };
                setCurrentFlows(currentFlows.concat(newFlow));
            }
        }
    };
    const [selectHandler, setSelectHandler] = useState<
        (process: SelectFlow) => void
            >(() => {
                return addNewFlow;
            });

    const deleteHandler = (processIndex: number) => {
        setCurrentFlows(
            currentFlows.filter((item, index) => {
                return index != processIndex;
            })
        );
    };

    useEffect(() => {
        if (currentFlows.length > 0) {
            const stages = envForm.getFieldValue("envInputEmissions");
            if (stages) {
                const matchingStage = stages.find((stage) => {
                    return stage.lcs === lifeCycleStage;
                });
                if (matchingStage) {
                    matchingStage.values = currentFlows;
                } else {
                    stages.push({ lcs: lifeCycleStage, values: currentFlows });
                }
            }
            envForm.setFieldValue("envInputEmissions", stages);
        }
    }, [currentFlows]);

    const open = Boolean(flowPopupEnv);
    const flowPopper = open ? "process-popper" : undefined;

    const openFlowSelect = (event: React.MouseEvent<HTMLElement>) => {
        setFlowPopupEnv(flowPopupEnv ? null : event.currentTarget);
    };
    const selectAddedFlowHandler = (event: React.MouseEvent<HTMLElement>) => {
        setSelectHandler(() => {
            return addNewFlow;
        });
        openFlowSelect(event);
    };

    const loadFlows = () => {
        let stages: EnvFlowInputLCS[] = [];

        if (lifeCycleStage) {
            stages = envForm.getFieldValue("envInputEmissions");
            const matchingLCS = stages.find((stage) => {
                return stage.lcs === lifeCycleStage;
            });
            console.log(matchingLCS);
            setCurrentFlows(matchingLCS?.values || []);
        }
        return stages;
    };
    const loadInputs = () => {
        let stages: EnvFlowInputLCS[] = [];
        if (formState && formState.envInputEmissions) {
            stages = formState.envInputEmissions as EnvFlowInputLCS[];
        }
        return stages;
    };

    useEffect(() => {

        if (lifeCycleStage) {

            const listStages = loadFlows();
            if (listStages.length === 0) {
                const stages: EnvFlowInputLCS[] = loadInputs();
                const matchingLCS = stages.find((stage) => {
                    return stage.lcs === lifeCycleStage;
                });
                if (matchingLCS) {
                    setCurrentFlows(matchingLCS.values);
                }
            }
        }
    }, [lifeCycleStage]);

    return (
        <ThemeProvider theme={TableTheme}>
            <Box>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow
                                sx={{
                                    display: "grid",
                                    gridTemplateColumns: "1fr 2fr 1fr 1fr 1fr auto",
                                }}
                            >
                                {" "}
                                <InputTableCell align="center">Resources</InputTableCell>
                                <InputTableCell align="center">Elementary Flow</InputTableCell>
                                <InputTableCell align="center" className="qty-cell">
                                    QTY. per one unit of analysis
                                </InputTableCell>
                                <InputTableCell align="center">Unit</InputTableCell>
                                <InputTableCell align="center">Comment</InputTableCell>
                                <InputTableCell align="center"> </InputTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody
                            sx={{
                                display: "flex",
                                overflowY: "auto",
                                height: "13vh",
                                flexDirection: "column",
                                "::-webkit-scrollbar": {
                                    width: "0.2em",
                                },
                                "::-webkit-scrollbar-track": {
                                    background: "#939598",
                                    border: 0,
                                },
                                "::-webkit-scrollbar-thumb": {
                                    backgroundColor: "#32463e",
                                    border: 0,
                                },
                            }}
                        >
                            <Popper
                                id={flowPopper}
                                open={open}
                                anchorEl={flowPopupEnv}
                                disablePortal
                                style={{
                                    position: "fixed",
                                    transform: "none !important",
                                }}
                            >
                                <Box
                                    sx={{
                                        position: "fixed !important",
                                        transform: "none !important",
                                        top: "0 !important",
                                        left: "0 !important",
                                        zIndex: 1300,
                                        width: "100%",
                                        height: "-webkit-fill-available",
                                        backgroundColor: "rgba(0, 0, 0, 0.54)",
                                    }}
                                >
                                    <SelectEnvProductFlowPopup
                                        selectHandler={selectHandler}
                                        openFlowSelect={openFlowSelect}
                                    />
                                </Box>
                            </Popper>
                            {currentFlows.map((flow, index) => {
                                return (
                                    <EnvFlowListItem
                                        key={flow.flowName + index.toString()}
                                        envForm={envForm}
                                        index={index}
                                        openFlowSelect={openFlowSelect}
                                        setSelectHandler={setSelectHandler}
                                        unitGroups={loadedUnitGroups}
                                        flow={flow}
                                        lifeCycleStage={lifeCycleStage}
                                        stages={envForm.getFieldValue("envInputEmissions")}
                                        deleteHandler={deleteHandler}
                                    />
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>

                <ThemeProvider theme={AddButton}>
                    <Button
                        size="small"
                        id="process-popup"
                        onClick={(e) => {
                            selectAddedFlowHandler(e);
                        }}
                    >
                        Add
                        <AddCircleIcon />
                    </Button>
                </ThemeProvider>
            </Box>
        </ThemeProvider>
    );
}

export default EnvInputEmissionsList;
