import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { ThemeProvider } from "@mui/material/styles";
import GraphLink from "../../../../style/components/GraphLink";
import GraphSettingsTheme from "../GraphSettings/GraphSettings";
import Popper from "@mui/material/Popper";
import {
    Typography,
    Select,
    FormControl,
    MenuItem,
    InputLabel,
    TextField,
    colors,
    Checkbox,
    FormControlLabel,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

import Dropdown from "../../../../dropdowns/Dropdown";
import DropdownTheme from "../../../../style/components/DropdownTheme";
import "../GraphSettings/GraphSettings.css";
import ExitButton from "../../../../style/components/ExitButton";
import { useFormContext } from "../../../../forms/FormContext";
import {
    lifeCycleColors,
    sublifeCycleColors,
    elementaryColors,
    processColors,
    palette1,
    palette2,
    palette3,
    palette4,
    treemapRGB,
    productPerformanceColor,
    sdgColor,
    sdg17Colors,
    getColorsForStakeholders,
} from "../../../../../helper/colors";
import {
    ChartJSData,
    ChartJSOptions,
} from "../../../../../interface/ChartData";
import { useForm } from "@tanstack/react-form";
import "./GraphSettingsOptions.css";
import { Chart } from "chart.js";
import deepClone from "../../../../../helper/deepClone";
import { textAlign } from "html2canvas/dist/types/css/property-descriptors/text-align";
import autoRound from "../../../../../helper/autoRound";


export interface ChartColorIdentifiers {
    label: string,
    index?: number,
    identifier?: string,

}


interface GraphSettingsOptionsProps {
    graphSettingHandleClick: () => void;
    chartOptions: ChartJSOptions | undefined;
    setApplyGraphSettings: React.Dispatch<React.SetStateAction<((options: ChartJSOptions,
        chartData: ChartJSData, colorIdentifiers?: ChartColorIdentifiers[]) => {
            options: ChartJSOptions;
            chartData: ChartJSData;
        }) | undefined>>
    triggerChartUpdate: () => void;
    chartColorType?: string;
    chartData: ChartJSData;
    isSettingsVisible: boolean;
    setIsSettingsVisible: React.Dispatch<React.SetStateAction<boolean>>;
    noColors?: boolean;
    graphType?: string;
    chartLabels?: number;
    lifecycleStagesNo?: number;
    noDataLabels?: boolean;
    sdgOn?: boolean;
    productSysOnly?: boolean;
}

interface GraphSettingsForm {
    valueFormat: string;
    xFontFamily: string;
    xFontWeight: string;
    xFontSize: number;
    yFontFamily: string;
    yFontWeight: string;
    yFontSize: number;
    datalabelFontFamily: string;
    datalabelFontWeight: string;
    datalabelFontSize: number;
    datalabelDisplay: boolean;
    colorPalette: string;
    legendFontFamily: string;
    legendFontWeight: string;
    legendFontSize: number;
    scaleType: string;
    radarFontFamily: string;
    radarFontWeight: string;
    radarFontSize: number;
}

function getDynamicFontSize(baseFontSize) {
    const scaleFactor = window.innerWidth / 1920; // Assuming 1920px is the base width for scaling
    return Math.max(10, Math.round(baseFontSize * scaleFactor));
}

function GraphSettingsOptions(props: GraphSettingsOptionsProps) {
    const {
        graphSettingHandleClick,
        setApplyGraphSettings,
        triggerChartUpdate,
        chartColorType,
        isSettingsVisible,
        setIsSettingsVisible,
        noColors,
        graphType,
        chartLabels,
        lifecycleStagesNo,
        noDataLabels: datalabelDisabled,
        sdgOn,
        productSysOnly,
        // labelSettings = {
        //     fontFamily: "Arial",
        //     fontWeight: "500",
        //     fontSize: 14,
        // },
        // setLabelSettings,
    } = props;

    const { formState:_formState, setFormState } = useFormContext();

    const formState = useRef(_formState); 
    //This is a ref because the values inside applyGraphSettings are not automatically references.



    // Set options here
    const [selectableValueFormat, setSelectableValueFormat] = useState<string[]>([
        "Decimal",
        "Exponential",
    ]);
    const [selectableXFontFamily, setSelectableXFontFamily] = useState<string[]>([
        "Roboto",
        "Times New Roman",
        "Montserrat",
        "Arial",
    ]);
    const [selectableXFontWeight, setSelectableXFontWeight] = useState<string[]>([
        "500",
        "300",
        "700",
        "900",
    ]);
    const [selectableYFontFamily, setSelectableYFontFamily] = useState<string[]>([
        "Roboto",
        "Times New Roman",
        "Montserrat",
        "Arial",
    ]);
    const [selectableYFontWeight, setSelectableYFontWeight] = useState<string[]>([
        "500",
        "300",
        "700",
        "900",
    ]);
    const [selectableDatalabelFontFamily, setSelectableDatalabelFontFamily] =
        useState<string[]>(["Roboto", "Times New Roman", "Montserrat", "Arial"]);
    const [selectableDatalabelFontWeight, setSelectableDatalabelFontWeight] =
        useState<string[]>(["500", "300", "700", "900"]);
    const [selectableColorPaletteValues, setSelectableColorPaletteValues] =
        useState<string[][]>([
            processColors,
            palette1,
            palette2,
            palette3,
            palette4,
        ]);
    const [selectableRFontFamily, setSelectableRFontFamily] = useState<string[]>([
        "Roboto",
        "Times New Roman",
        "Montserrat",
        "Arial",
    ]);
    const [selectableRFontWeight, setSelectableRFontWeight] = useState<string[]>([
        "500",
        "300",
        "700",
        "900",
    ]);
    const [
        selectableColorPaletteLifeCycleValues,
        setSelectableColorPaletteLifeCycleValues,
    ] = useState<string[][]>([
        lifeCycleColors,
        palette1,
        palette2,
        palette3,
        palette4,
    ]);
    const [
        selectableColorPaletteSubLifeCycleValues,
        setSelectableColorPaletteSubLifeCycleValues,
    ] = useState<string[][]>([
        sublifeCycleColors,
        palette1,
        palette2,
        palette3,
        palette4,
    ]);
    const [
        selectableColorPaletteStakeholderValues,
        setSelectableColorPaletteStakeholderValues,
    ] = useState<string[][]>([
        treemapRGB,
        palette1,
        palette2,
        palette3,
        palette4,
    ]);
    const [
        selectableColorPaletteElementaryValues,
        setSelectableColorPaletteElementaryValues,
    ] = useState<string[][]>([
        elementaryColors,
        palette1,
        palette2,
        palette3,
        palette4,
    ]);
    const [
        selectableColorPalettePerformanceValues,
        setSelectableColorPalettePerformanceValues,
    ] = useState<string[][]>([
        productPerformanceColor,
        palette1,
        palette2,
        palette3,
        palette4,
    ]);
    const [selectableColorPaletteSDGValues, setSelectableColorPaletteSDGValues] =
        useState<string[][]>([sdgColor, palette1, palette2, palette3, palette4]);
    const [selectableColorPaletteNames, setSelectableColorPaletteNames] =
        useState<string[]>([
            "Main Color",
            "Pastel",
            "Dark",
            "Warm",
            "Cold",
        ]);
    const [selectableLegendFontFamily, setSelectableLegendFontFamily] = useState<
        string[]
    >(["Roboto", "Times New Roman", "Montserrat", "Arial"]);
    const [selectableLegendFontWeight, setSelectableLegendFontWeight] = useState<
        string[]
    >(["500", "300", "700", "900"]);
    const [selectableScaleType, setSelectableScaleType] = useState<string[]>([
        "linear",
        "logarithmic",
    ]);

    // Set defaults here
    const graphSettingsFormDefaults: GraphSettingsForm = {
        valueFormat: formState?.valueFormat || "Decimal",
        xFontFamily: formState.current.xFontFamily || selectableXFontFamily[0],
        xFontWeight: formState.current.xFontWeight || selectableXFontWeight[0],
        xFontSize: formState.current.xFontSize || 14,
        yFontFamily: formState.current.yFontFamily || selectableYFontFamily[0],
        yFontWeight: formState.current.yFontWeight || selectableYFontWeight[0],
        yFontSize: formState.current.yFontSize || 14,
        datalabelFontFamily:
            formState.current.datalabelFontFamily || selectableDatalabelFontFamily[0],
        datalabelFontWeight:
            formState.current.datalabelFontWeight || selectableDatalabelFontWeight[0],
        datalabelFontSize: formState.current.datalabelFontSize || 12,
        datalabelDisplay: formState.current.datalabelDisplay || true,
        colorPalette: formState.current.colorPalette || selectableColorPaletteNames[0],
        legendFontFamily:
            formState.current.legendFontFamily || selectableLegendFontFamily[0],
        legendFontWeight:
            formState.current.legendFontWeight || selectableLegendFontWeight[0],
        legendFontSize: formState.current.legendFontSize || 14,
        scaleType: formState.current.scaleType || selectableScaleType[0],
        radarFontFamily: formState.current.radarFontFamily || selectableRFontFamily[0],
        radarFontWeight: formState.current.radarFontWeight || selectableRFontWeight[0],
        radarFontSize: formState.current.radarFontSize || 12,
    };


    const form = useForm<GraphSettingsForm>({
        defaultValues: graphSettingsFormDefaults,
        onSubmit: ({ value }) => {
            setFormState({
                ...formState.current,
                ...value,
            });

            formState.current = {
                ...formState.current,
                ...value,
            }
        },
    });

    const findColorPaletteByName = (name: string): string[] => {
        const paletteIndex = selectableColorPaletteNames.indexOf(name);
        if (chartColorType === "lcs") {
            return (
                selectableColorPaletteLifeCycleValues[paletteIndex] ||
                selectableColorPaletteLifeCycleValues[0]
            );
        } else if (chartColorType === "lcss") {
            return (
                selectableColorPaletteSubLifeCycleValues[paletteIndex] ||
                selectableColorPaletteSubLifeCycleValues[0]
            );
        } else if (chartColorType === "process" || chartColorType === "processA1") {
            return (
                selectableColorPaletteValues[paletteIndex] ||
                selectableColorPaletteValues[0]
            );
        } else if (chartColorType === "elementary") {
            return (
                selectableColorPaletteElementaryValues[paletteIndex] ||
                selectableColorPaletteElementaryValues[0]
            );
        } else if (chartColorType === "treemap") {
            return (
                selectableColorPaletteStakeholderValues[paletteIndex] ||
                selectableColorPaletteStakeholderValues[0]
            );
        } else if (chartColorType === "performance") {
            return (
                selectableColorPalettePerformanceValues[paletteIndex] ||
                selectableColorPalettePerformanceValues[0]
            );
        } else if (chartColorType === "sdg") {
            return (
                selectableColorPaletteSDGValues[paletteIndex] ||
                selectableColorPaletteSDGValues[0]
            );
        } else {
            return (
                selectableColorPaletteValues[paletteIndex] ||
                selectableColorPaletteValues[1]
            );
        }
    };



    useEffect(() => {
        if (chartColorType === "lcs") {
            setSelectableColorPaletteNames([
                "LCS Colors",
                "Pastel",
                "Dark",
                "Warm",
                "Cold",
            ]);
        } else if (chartColorType === "lcss") {
            setSelectableColorPaletteNames([
                "LCSS Colors",
                "Pastel",
                "Dark",
                "Warm",
                "Cold",
            ]);
        } else if (chartColorType === "process" || chartColorType === "processA1") {
            setSelectableColorPaletteNames([
                "Process Colors",
                "Pastel",
                "Dark",
                "Warm",
                "Cold",
            ]);
        } else if (chartColorType === "elementary") {
            setSelectableColorPaletteNames([
                "Elementary Colors",
                "Pastel",
                "Dark",
                "Warm",
                "Cold",
            ]);
        } else if (chartColorType === "treemap") {
            setSelectableColorPaletteNames([
                "Stakeholder Colors",
                "Pastel",
                "Dark",
                "Warm",
                "Cold",
            ]);
        } else if (chartColorType === "performance") {
            setSelectableColorPaletteNames([
                "Performance Color",
                "Pastel",
                "Dark",
                "Warm",
                "Cold",
            ]);
        } else if (chartColorType === "sdg") {
            setSelectableColorPaletteNames([
                "SDG Color",
                "Pastel",
                "Dark",
                "Warm",
                "Cold",
            ]);
        } else {
            setSelectableColorPaletteNames([
                "Pastel",
                "Dark",
                "Warm",
                "Cold",
            ]);
        }
    }, [chartColorType]);


    // useEffect(() =>{

    //     if(!colorIdentifiers)
    //         return;


    //     let savedIdentifiers : ChartColorIdentifiers[] = [];
    //     if(formState.current.chartColorIdentifiers){
    //         savedIdentifiers = formState.current.chartColorIdentifiers;
    //     }

    //     colorIdentifiers.forEach((item) =>{
    //         const matchingIdentifier = savedIdentifiers.find((identifier) => 
    //             item.label === identifier.label && item.identifier === identifier.identifier);
    //         if(!matchingIdentifier){
    //             savedIdentifiers.push({label:item.label, identifier:item.identifier, index:savedIdentifiers.length - 1})
    //         }
    //     })

    //     setFormState({...formState, chartColorIdentifiers:savedIdentifiers})

    // } ,[colorIdentifiers])



    useEffect(() =>{
        console.log(formState);
    } ,[formState])

    const getColorPalette = (): string[] => {
        let palette: string[] = [];
        if (chartColorType === "lcs") {
            palette = lifeCycleColors;
        } else if (chartColorType === "lcss") {
            palette = sublifeCycleColors;
        } else if (chartColorType === "process" || chartColorType === "processA1") {
            palette = processColors;
        } else if (chartColorType === "elementary") {
            palette = elementaryColors;
        } else if (chartColorType === "treemap") {
            palette = treemapRGB;
        } else if (chartColorType === "performance") {
            palette = productPerformanceColor;
        } else if (chartColorType === "sdg") {
            palette = sdgColor;
        }
        else if (chartColorType === "sdg17") {
            palette = sdg17Colors;
        }
        else {
            palette = findColorPaletteByName(form.state.values.colorPalette);
        }
        // palette = findColorPaletteByName(form.state.values.colorPalette);
        console.log(form.state.values.colorPalette);

        return palette;
    };

    const adjustColor = (color: string, factor: number): string => {

        const f = parseInt(color.slice(1), 16),
            t = factor < 0 ? 0 : 255,
            p = factor < 0 ? factor * -1 : factor,
            R = f >> 16,
            G = f >> 8 & 0x00FF,
            B = f & 0x0000FF;
        return "#" + (0x1000000 + (Math.round((t - R) * p) + R)
            * 0x10000 + (Math.round((t - G) * p) + G) * 0x100 + (Math.round((t - B) * p) + B)).toString(16).slice(1);
    };

    const generateExtendedPalette = (baseColors: string[], totalColors: number): string[] => {
        const extendedPalette = [...baseColors];
        while (extendedPalette.length < totalColors) {
            baseColors.forEach((color) => {
                // Adjust brightness or saturation to generate new colors
                const newColor = adjustColor(color, extendedPalette.length / totalColors);
                extendedPalette.push(newColor);
                if (extendedPalette.length >= totalColors) return;
            });
        }
        return extendedPalette.slice(0, totalColors);
    };

    const getChartColorIndex = (identifier: ChartColorIdentifiers, savedIdentifiers: ChartColorIdentifiers[]) => {





        let indexToReturn = 0;

        if (identifier) {
            const matchingIdentifier = savedIdentifiers.find((item) =>
                identifier.label === item.label && identifier.identifier === item.identifier);
            if (!matchingIdentifier) {
                const index = savedIdentifiers.length;
                console.log(identifier);
                savedIdentifiers.push({ label: identifier.label, identifier: identifier.identifier, index })

                indexToReturn = index;
            } else {
                indexToReturn = matchingIdentifier.index || 0;
            }
        }

        return indexToReturn;

    }

    const applyColorPalette = (chartData: ChartJSData, colorIdentifiers?: ChartColorIdentifiers[]): ChartJSData => {


        console.log(colorIdentifiers);
        console.log(formState);

        // if (!colorIdentifiers || !formState || (colorIdentifiers && colorIdentifiers.length === 0)) {
        if (noColors){
            return chartData;
        }
        console.log(colorIdentifiers);

        let palette = findColorPaletteByName(form.state.values.colorPalette);
        if (chartColorType !== "treemap") {
            palette = generateExtendedPalette(palette, 20)
        }


        let savedIdentifiers: ChartColorIdentifiers[] = [];
        if (formState.current.chartColorIdentifiers) {
            savedIdentifiers = formState.current.chartColorIdentifiers;

        }
 

        let coloredChartData = {
            ...chartData,
        };

        // if (colorIdentifiers.length > 0){
        //     coloredChartData = {
        //         ...chartData,
        //         datasets: chartData.datasets.map((dataset, index) => {
        //             const colorIndex = getChartColorIndex(colorIdentifiers[index], savedIdentifiers);
        //             return {
        //                 ...dataset,
        //                 backgroundColor: palette[colorIndex], 
        //             };
        //         }),
        //     };
        // };
        // let coloredChartData = chartData;


        if (graphType === 'pie') {
            if(chartColorType === 'treemap') {
                coloredChartData = {
                    ...chartData,
                    datasets: chartData.datasets.map((dataset) => ({
                        ...dataset,
                        backgroundColor: getColorsForStakeholders(chartData.labels),
                    })),
                };
            } else {
                const newPalette: string[] = [];
                colorIdentifiers.forEach((item) => {
                    const index = getChartColorIndex({ label: item.label, identifier: item.identifier }, savedIdentifiers);
                    newPalette.push(palette[index]);
                }
                )
                // chartData.labels.forEach((label) => {
                //     const index = getChartColorIndex({ label: label }, savedIdentifiers);
                //     newPalette.push(palette[index]);

                // })
                coloredChartData = {
                    ...chartData,
                    datasets: chartData.datasets.map((dataset, index) => ({
                        ...dataset,
                        // backgroundColor: newPalette,
                        ...(
                            chartColorType === 'lcs' ||
                             chartColorType === 'lcss'
                                ?
                                { backgroundColor: palette } :
                                { backgroundColor: newPalette }),
                    })),
                };
            };
        }
        else {
            coloredChartData = {
                ...chartData,
                datasets: chartData.datasets.map((dataset, index) => ({
                    ...dataset,
                    // backgroundColor: palette[getChartColorIndex(colorIdentifiers[index], savedIdentifiers) || index],
                    ...(
                        chartColorType === 'lcs' ||
                        chartColorType === 'processA1' ||
                         chartColorType === 'lcss'  || 
                         chartColorType === 'performance'
                          ||
                          chartColorType === 'sdg17'
                            ?
                            { backgroundColor: palette[index] } :
                            { backgroundColor: palette[getChartColorIndex(colorIdentifiers[index], savedIdentifiers)] }),
                })),
            };
            console.log(colorIdentifiers);
        }

        if (graphType === "radar") {

            const hexToRgba = (hex, opacity) => {
                const r = parseInt(hex.slice(1, 3), 16);
                const g = parseInt(hex.slice(3, 5), 16);
                const b = parseInt(hex.slice(5, 7), 16);
            
                return `rgba(${r}, ${g}, ${b}, ${opacity})`;
            };

            coloredChartData = {
                ...chartData,
                datasets: chartData.datasets.map((dataset, index) => ({
                    ...dataset,
                    borderColor: palette[getChartColorIndex(colorIdentifiers[index], savedIdentifiers) || index],
                    backgroundColor: hexToRgba(palette[getChartColorIndex(colorIdentifiers[index], savedIdentifiers) || index], 0.7),
                })),
            };
        }

        if (savedIdentifiers.length > 0) {
            
            setFormState({ ...formState.current, chartColorIdentifiers: savedIdentifiers })
            formState.current = { ...formState.current, chartColorIdentifiers: savedIdentifiers }
        }
        console.log(savedIdentifiers);

        return coloredChartData;


    };



    const handleSettingsClose = () => {
        setIsSettingsVisible(false);
    };




    function vwToPixels(vw, maxBoxWidth) {
        const width =
            Math.max(
                document.documentElement.clientWidth || 1,
                window.innerWidth || 1
            ) *
            (vw / 100);
        return Math.min(width, maxBoxWidth);
    }

    const toVisualScaleType = (value: string): string => {
        return value.charAt(0).toUpperCase() + value.slice(1);
    };

    const toInternalScaleType = (value: string): string => {
        return value.toLowerCase(); // Convert back to lowercase
    };

    // const thresholdPercentage = (graphType === 'barindicator') ? 0 : 0.15;

    let thresholdPercentage;
    switch (graphType) {
    case 'barindicator':
        thresholdPercentage = 0;
        break;
    case 'barlcs':
        thresholdPercentage = 0.002;
        break;
    default:
        thresholdPercentage = 0.15;
        break;
    }

    // switch (form?.state?.values?.scaleType) {
    // case 'logarithmic':
    //     thresholdPercentage = 0.00001;
    //     break;
    // default:
    //     thresholdPercentage = 0.15;
    //     break;
    // }
    if (form?.state?.values?.scaleType === 'logarithmic') {
        // Apply a very low threshold for logarithmic scales
        thresholdPercentage = 0.00001;
    }

    // console.log(form?.state?.values?.scaleType);
    // console.log(thresholdPercentage);

    const maxChartLabels = 20;
    const maxStageLabels = 1;

    const applyGraphSettings = (
        options: ChartJSOptions,
        chartData: ChartJSData,
        colorIdentifiers: ChartColorIdentifiers[],
    ): {
        options: ChartJSOptions;
        chartData: ChartJSData;
    } => {
        const isRadial = graphType === "radar" || graphType === "polarArea";
        // console.log(chartData.datasets.length);
        // console.log(chartData.labels.length);
        // function getContrastingColor(backgroundColor: string | string[]): string {

        //     const color = Array.isArray(backgroundColor) ? backgroundColor[0] : backgroundColor;

        //     const rgb = color.match(/\d+/g)?.map(Number);

        //     if (!rgb || rgb.length < 3) return "black"; 

        //     const brightness = Math.round((rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000);
        //     return brightness > 200 ? "black" : "white"; 
        // }
        function hexToRgb(hex) {
            let r = 0, g = 0, b = 0;
            if (hex.length === 4) {
                r = parseInt(hex[1] + hex[1], 16);
                g = parseInt(hex[2] + hex[2], 16);
                b = parseInt(hex[3] + hex[3], 16);
            } else if (hex.length === 7) {
                r = parseInt(hex[1] + hex[2], 16);
                g = parseInt(hex[3] + hex[4], 16);
                b = parseInt(hex[5] + hex[6], 16);
            }
            return [r, g, b];
        }

        function getContrastingColor(backgroundColor: string[]) {
            let rgb;

            const color = Array.isArray(backgroundColor) ? backgroundColor[0] : backgroundColor;

            if (!color)
                return "black";

            if (color.startsWith('#')) {
                rgb = hexToRgb(color);
            } else if (color.startsWith('rgb')) {
                rgb = color.match(/\d+/g).map(Number);
            } else {
                console.error("Unknown color format:", color);
                return 'black';
            }

            const brightness = Math.round((rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000);

            if (graphType === 'radar') {
                return brightness > 50 ? 'black' : '#f7f7f7';

            } else if (graphType === 'stacked' && chartColorType === 'treemap') {
                return brightness > 0 ? 'black' : '#f7f7f7';
            } else if (form.state.values.colorPalette === 'Pastel') {
                return brightness > 0 ? 'black' : '#f7f7f7';

            } else if (form.state.values.colorPalette === 'Warm' || form.state.values.colorPalette === 'Cold') {
                return brightness > 255 ? 'black' : '#f7f7f7';

            } 
            else if (chartColorType === 'sdg') {
                return brightness > 0 ? 'black' : '#f7f7f7';

            }
            else if (chartColorType === 'sdg17') {
                return brightness > 0 ? 'black' : '#f7f7f7';

            }
            else {
                // return brightness > 180 ? 'black' : '#f7f7f7';
                return brightness > 255 ? 'black' : '#f7f7f7';
            }
        }
        const maxBarValue = Math.max(
            ...chartData.datasets.flatMap((dataset) => dataset.data)
        );
        function chartHasMultipleLifecycleStages(chartJSData) {
            return chartJSData.datasets.some(dataset => dataset.data.length > 1);
        }


        const newChartOptions = {
            ...options,
            scales: {
                ...options?.scales,
                x: {
                    ...options?.scales?.x,
                    ticks: {
                        ...options?.scales?.x?.ticks,
                        font: {
                            ...options?.scales?.x?.ticks?.font,
                            family: form.state.values.xFontFamily,
                            weight: form.state.values.xFontWeight,
                            size: getDynamicFontSize(form.state.values.xFontSize),
                        },
                        display: !((graphType === 'pie' || graphType === 'radar') || productSysOnly) ? true : false,
                    },
                    border: {
                        ...options?.scales?.x?.border,
                        display: !(graphType === 'pie' || graphType === 'radar'),
                    },
                    grid: {
                        ...options?.scales?.x?.grid,
                        display: !(graphType === 'pie' || graphType === 'radar'),

                    },
                    title: {
                        ...options?.scales?.y?.title,
                        display: false,
                    },
                },
                y: {
                    ...options?.scales?.y,
                    ticks: {
                        ...options?.scales?.y?.ticks,
                        font: {
                            ...options?.scales?.y?.ticks?.font,
                            family: form.state.values.yFontFamily,
                            weight: form.state.values.yFontWeight,
                            size: getDynamicFontSize(form.state.values.yFontSize),
                        },
                        // callback: function (value) {
                        //     const format = form.state.values.valueFormat || "Decimal";
                        //     return format === "Decimal"
                        //         ? value.toLocaleString()
                        //         : value.toExponential();
                        // },
                        callback: function (value) {
                            const format = form.state.values.valueFormat || "Decimal";
                            if (format === "Decimal") {
                                if (value >= 1000000) {
                                    return (value / 1000000) + 'M';
                                } else if (value >= 1000) {
                                    return (value / 1000) + 'K';
                                } else {
                                    return value.toLocaleString();
                                }
                            } else {
                                return value.toExponential();
                            }
                        },
                        display: !(graphType === 'pie' || graphType === 'radar'),
                        formatter: function (value, context) {
                            if (value === null || value === undefined) {
                                return "";
                            }
                            const maxThresholdValue = Math.max(
                                ...chartData.datasets.flatMap((dataset) => dataset.data)
                            );
                            // const thresholdPercentage = 0.15;
                            const totalThreshold = maxThresholdValue * thresholdPercentage;
                            const format = form.state.values.valueFormat || "Decimal";
                            if (value < totalThreshold) {
                                return null;
                            }
                            // if (format === "Exponential") {
                            //     return value.toExponential(2);
                            // } else {
                            //     return value.toFixed(2).toLocaleString();
                            // }

                            if (graphType === 'barindicator') {
                                if (format === "Exponential") {
                                    return value.toExponential(2);
                                } else {
                                    if (value >= 1000000) {
                                        return (value / 1000000).toFixed(2).toLocaleString() + 'M';

                                    } else if (value >= 1000) {
                                        return (value / 1000).toFixed(2).toLocaleString() + 'K';

                                    } else {
                                        return value.toFixed(2).toLocaleString();
                                    }
                                }
                            } else {
                                if (format === "Exponential") {
                                    return value.toExponential(2);
                                } else {
                                    return value.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    });
                                }
                            }
                        },

                    },
                    title: {
                        ...options?.scales?.y?.title,
                        display: !(graphType === 'pie' || graphType === 'radar'),
                        font: {
                            ...options?.scales?.y?.title?.font,
                            family: form.state.values.yFontFamily,
                            weight: form.state.values.yFontWeight,
                            size: getDynamicFontSize(form.state.values.yFontSize),
                        },
                    },
                    type: form.state.values.scaleType,
                    grid: {
                        ...options?.scales?.y?.grid,
                        display: !(graphType === 'pie' || graphType === 'radar'),
                    },
                    border: {
                        ...options?.scales?.x?.border,
                        display: !(graphType === 'pie' || graphType === 'radar'),
                    },
                    // ...(graphType === 'bardouble' && {
                    //     beginAtZero: true,
                    //     stepSize: 1,
                    //     min: 0,
                    //     max: 4,
                    //     autoSkip: false,
                    //     ticks: {
                    //         callback: function(value) {
                    //             if (value % 1 === 0) {  
                    //                 return value;
                    //             }
                    //             return '';  
                    //         },
                    //     },
                    // }),
                },
                ...(isRadial && { 
                    r: {
                        pointLabels: {
                            font: {
                                family: form.state.values.radarFontFamily,
                                weight: form.state.values.radarFontWeight,
                                size: getDynamicFontSize(form.state.values.radarFontSize),
                            },
                        },
                        ticks: {
                            beginAtZero: true,
                        },
                    },
                }),
                // r: {
                //     ...options.scales?.r,
                //     pointLabels: {
                //         ...options?.scales?.r?.pointLabels,
                //         font: {
                //             ...options?.scales?.r?.pointLabels?.font,
                //             family: form.state.values.radarFontFamily,
                //             weight: form.state.values.radarFontWeight,
                //             size: getDynamicFontSize(form.state.values.radarFontSize),
                //         },
                //     },
                // },
            },
            plugins: {
                ...options?.plugins,
                legend: {
                    ...options?.plugins?.legend,
                    labels: {
                        ...options?.plugins?.legend?.labels,
                        font: {
                            size: getDynamicFontSize(form.state.values.legendFontSize), // Dynamically scale the font size
                            family: form.state.values.legendFontFamily,
                            weight: form.state.values.legendFontWeight,
                        },
                        boxWidth: vwToPixels(3, 20),
                        sort: (a, b) => {
                            if(chartColorType === 'process'){
                                return a.text.localeCompare(b.text);
                            }
                        },
                    },
                },
                datalabels: {
                    ...options?.plugins?.datalabels,

                    // ...options?.datalabels,
                    display: datalabelDisabled ? false : form.state.values.datalabelDisplay,
                    // display: () => 
                    // {
                    //     if(graphType === 'barlcs' && ((lifecycleStagesNo > maxStageLabels) || (chartData.labels.length > maxChartLabels))){
                    //         return 'auto';
                    //     } else {
                    //         return datalabelDisabled ? false : form.state.values.datalabelDisplay;
                    //     }
                    // },
                    // textStrokeColor: "white",
                    // textStrokeWidth: 2,
                    color: (context: any) => {
                        if (graphType === 'pie') {
                            const backgroundColor = context.dataset.backgroundColor[context.dataIndex];
                            return getContrastingColor(backgroundColor);
                        }
                        else if (graphType === 'barlcs' && ((chartData.datasets.length > maxStageLabels) || (chartData.labels.length > maxChartLabels))) {
                            return 'black';
                        }
                        else if (graphType === 'barindicator' || graphType === 'barlcs') {
                            let value = context.raw;
                            const dataset = context.dataset;
                            const backgroundColor = dataset.backgroundColor;

                            if (isNaN(value)) {
                                value = context.dataset.data[context.dataIndex] || 0;
                            }

                            if (maxBarValue === 0) return 'end';

                            const percentageOfMax = value / maxBarValue;
                            if (form?.state?.values?.scaleType === 'logarithmic') {
                                return percentageOfMax > 0.2 ? getContrastingColor(backgroundColor) : 'white';

                            } else {
                                return percentageOfMax > 0.2 ? getContrastingColor(backgroundColor) : 'black';
                            }
                        }
                        // else if (graphType === 'barlcs') {
                        //     let value = context.raw;
                        //     const dataset = context.dataset;
                        //     const backgroundColor = dataset.backgroundColor;

                        //     if (isNaN(value)) {
                        //         value = context.dataset.data[context.dataIndex] || 0;
                        //     }

                        //     if (maxBarValue === 0) return 'end';

                        //     const percentageOfMax = value / maxBarValue;
                        //     if (form?.state?.values?.scaleType === 'logarithmic') {
                        //         return percentageOfMax > 0.005 ? getContrastingColor(backgroundColor) : 'white';

                        //     } else {
                        //         return 'black';
                        //     }
                        // }
                        else {
                            const dataset = context.dataset;
                            const backgroundColor = dataset.backgroundColor;
                            console.log(dataset);
                            return getContrastingColor(backgroundColor);
                        }

                    },
                    font: {
                        size: getDynamicFontSize(form.state.values.datalabelFontSize), // Dynamically scale the font size
                        family: form.state.values.datalabelFontFamily,
                        weight: form.state.values.datalabelFontWeight,
                    },
                    anchor: (context) => {

                        if (graphType === 'barlcs' && ((chartData.datasets.length > maxStageLabels) || (chartData.labels.length > maxChartLabels))) {
                            return 'end';
                        }
                        else if (graphType === 'barindicator' || graphType === 'barlcs') {
                            let value = context.raw;

                            if (isNaN(value)) {
                                value = context.dataset.data[context.dataIndex] || 0;
                            }

                            if (maxBarValue === 0) return 'end';

                            const percentageOfMax = value / maxBarValue;
                            if (form?.state?.values?.scaleType === 'logarithmic') {
                                return percentageOfMax > 0.00001 ? 'center' : 'end';

                            } else {
                                return percentageOfMax > 0.2 ? 'center' : 'end';
                            }
                        }
                        else {
                            return 'center';
                        }

                    },
                    align: (context) => {
                        if (graphType === 'barlcs' && ((chartData.datasets.length > maxStageLabels) || (chartData.labels.length > maxChartLabels))) {
                            return 'end';
                        }
                        else if (graphType === 'barindicator' || graphType === 'barlcs') {
                            let value = context.raw;

                            if (isNaN(value)) {
                                value = context.dataset.data[context.dataIndex] || 0;
                            }

                            if (maxBarValue === 0) return 'top';

                            const percentageOfMax = value / maxBarValue;
                            // console.log(percentageOfMax);
                            if (form?.state?.values?.scaleType === 'logarithmic') {
                                return percentageOfMax > 0.00001 ? 'center' : 'top';

                            } else {
                                return percentageOfMax > 0.2 ? 'center' : 'top';
                            }
                        } 
                        else {
                            return 'center';
                        }
                    },
                    offset: (context) => {

                        if (graphType === 'barlcs' && ((lifecycleStagesNo > maxStageLabels) || (chartData.labels.length > maxChartLabels))) {
                            const value = context.raw || context.dataset.data[context.dataIndex] || 0;

                            const minValue = Math.min(...context.dataset.data);
                            const range = maxBarValue - minValue;
                            const threshold = range * 0.05;

                            const percentageOfMax = value / maxBarValue;
                            if (form?.state?.values?.scaleType === 'logarithmic') {
                                return 0;

                            } else {
                                if (value < 1000) {
                                    return 10;
                                }
                                else if (value < 500) {
                                    return 8;
                                }
                                else if (value < 100) {
                                    return 4;
                                }
                                else if (value < 50) {
                                    return 0;
                                }
                                else {
                                    return percentageOfMax < (threshold / maxBarValue) ? 15 : 8;

                                }
                            }
                        } else {
                            return 8;
                        }
                    },


                    clip: false,
                    rotation: 0,
                    clamp: true,
                    textAlign: 'center',
                    formatter: function (value, context) {
                        if (value === null || value === undefined) {
                            return "";
                        }

                        const maxThresholdValue = Math.max(
                            ...chartData.datasets.flatMap((dataset) => dataset.data)
                        );
                        // const thresholdPercentage = graphType === 'barindicator' ? 0 : 0.15;
                        // let totalThreshold = maxThresholdValue * thresholdPercentage;
                        // console.log(totalThreshold);
                        // console.log(thresholdPercentage);

                        
                        const format = form.state.values.valueFormat || "Decimal";

                        let totalThreshold;

                        if (form?.state?.values?.scaleType === 'logarithmic') {
                            totalThreshold = Math.pow(10, Math.floor(Math.log10(maxThresholdValue)) - 4);
                        } else {
                            totalThreshold = maxThresholdValue * thresholdPercentage;
                        }

                        if (graphType === 'radar') {
                            return Number.isInteger(value) ? value.toFixed(0) : null;
                        }

                        if (value < totalThreshold || graphType === 'bardouble') {
                            // if(form?.state?.values?.scaleType === 'logarithmic' && value < 0.000015){
                            //     return null;

                            // } 
                            // else {
                            // console.log(`${value} is lower than ${totalThreshold}`);
                            return null;

                            // }
                        }

                        const percentageOfMax = value / maxBarValue;
                        // console.log(percentageOfMax);

                        // console.log(lifecycleStagesNo > maxStageLabels);
                        // console.log(chartData.labels.length > maxChartLabels);

                        // if ((graphType === 'barindicator' && percentageOfMax < 0.2 && (form?.state?.values?.scaleType !== 'logarithmic')) 
                        //     // || ((graphType === 'barindicator' && percentageOfMax < 0.0001) && form?.state?.values?.scaleType === 'logarithmic')
                        //     || (graphType === 'barlcs' && ((lifecycleStagesNo > maxStageLabels) 
                        //     || (chartData.labels.length > maxChartLabels)
                        //     ) && (form?.state?.values?.scaleType !== 'logarithmic') || (graphType === 'barlcs') )
                        // ) {
                        //     if (format === "Exponential") {
                        //         return `${value.toExponential(2)}\n|`;
                        //     } else {
                        //         if (value >= 1000000) {
                        //             return [
                        //                 `${(value / 1000000).toLocaleString(undefined, {
                        //                     minimumFractionDigits: 2,
                        //                     maximumFractionDigits: 2,
                        //                 })}M\n|`,
                        //             ];
                        //         } else if (value >= 1000) {
                        //             return [
                        //                 `${(value / 1000).toLocaleString(undefined, {
                        //                     minimumFractionDigits: 2,
                        //                     maximumFractionDigits: 2,
                        //                 })}K\n|`,
                        //             ];
                        //         } else {
                        //             return [
                        //                 `${value.toLocaleString(undefined, {
                        //                     minimumFractionDigits: 2,
                        //                     maximumFractionDigits: 2,
                        //                 })}\n|`,
                        //             ];
                        //         }
                        //     }
                        // } 
                        if (value < 0) {
                            return `${value.toFixed(2)}`;
                        }

                        if (graphType === 'barindicator' || ((graphType === 'barindicator' || graphType === 'barlcs') 
                            && (form?.state?.values?.scaleType === 'logarithmic'))
                        ) {
                            if (format === "Exponential") {
                                return `${value.toExponential(2)}`;
                            } else {
                                if (value >= 1000000) {
                                    return [
                                        `${(value / 1000000).toLocaleString(undefined, {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        })}M`,
                                    ];
                                } else if (value >= 1000) {
                                    return [
                                        `${(value / 1000).toLocaleString(undefined, {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        })}K`,
                                    ];
                                } else {

                                    if (value >= 0.01) {
                                        return value.toFixed(2); 
                                    } else if (value >= 0.001) {
                                        return value.toFixed(3);
                                    } else if (value >= 0.0001) {
                                        return value.toFixed(4); 
                                    } else if (value >= 0.00001) {
                                        return value.toFixed(5); 
                                    } else if (value >= 0.000001) {
                                        return value.toFixed(6); 
                                    } else if (value >= 0.0000001) {
                                        return value.toFixed(7); 
                                    } else {
                                        return [
                                            `${value.toLocaleString(undefined, {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                                
                                            })}`,
                                        ];
                                    }
                                    // return [
                                    //     `${value.toLocaleString(undefined, {
                                    //         minimumFractionDigits: 2,
                                    //         maximumFractionDigits: 2,
                                            
                                    //     })}`,
                                    // ];
                                }
                            }
                        }
                        // else if (graphType === 'barlcs') {
                        //     if (format === "Exponential") {
                        //         return `${value.toExponential(2)}\n|`;
                        //     } else {
                        //         if (value >= 1000000) {
                        //             return [
                        //                 `${(value / 1000000).toLocaleString(undefined, {
                        //                     minimumFractionDigits: 2,
                        //                     maximumFractionDigits: 2,
                        //                 })}M\n|`,
                        //             ];
                        //         } else if (value >= 1000) {
                        //             return [
                        //                 `${(value / 1000).toLocaleString(undefined, {
                        //                     minimumFractionDigits: 2,
                        //                     maximumFractionDigits: 2,
                        //                 })}K\n|`,
                        //             ];
                        //         } else {
                        //             return [
                        //                 `${value.toLocaleString(undefined, {
                        //                     minimumFractionDigits: 2,
                        //                     maximumFractionDigits: 2,
                        //                 })}\n|`,
                        //             ];
                        //         }
                        //     }
                        // }  
                        else {
                            if (format === "Exponential") {
                                return value.toExponential(2);
                            } else {
                                return value.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                });
                            }
                        }
                    },
                    // textShadowBlur: 5,
                    // textShadowColor: 'rgba(0, 0, 0, 1)',
                },
                tooltip: {
                    callbacks: {
                        label: function (tooltipItem: { dataset: string; raw: number; }) {
                            const format = form.state.values.valueFormat || "Decimal";

                            // const dataset = tooltipItem.dataset;
                            const value = tooltipItem.raw;
        
                            const epsilon = 1e-10;

                            if (value < 0) {
                                return `${value.toFixed(2)}`;
                            }

                            if (format === "Exponential") {
                                return value.toExponential(2);
                            } else {

                                // if (value < 0.01) {
                                //     return value.toFixed(3);
                                // } else if (value < 0.001) {
                                //     return value.toFixed(4);
                                // } else if (value < 0.0001) {
                                //     return value.toFixed(5);
                                // } else if (value < 0.00001) {
                                //     return value.toFixed(6);
                                // } else if (value < 0.000001) {
                                //     return value.toFixed(7);
                                // } else if (value < 0.0000001) {
                                //     return value.toFixed(8); // Added more precision here
                                // } else if (value < 0.00000001) {
                                //     return value.toFixed(9); // Go even further for very small values
                                // } else {
                                //     return value.toFixed(2); // Default formatting
                                // }
                                if (Math.abs(value) < epsilon) {
                                    return `< ${epsilon}`;  // Display values that are close to zero
                                }
                        
                                if (value >= 0.01) {
                                    return value.toFixed(2); 
                                } else if (value >= 0.001) {
                                    return value.toFixed(3);
                                } else if (value >= 0.0001) {
                                    return value.toFixed(4); 
                                } else if (value >= 0.00001) {
                                    return value.toFixed(5); 
                                } else if (value >= 0.000001) {
                                    return value.toFixed(6); 
                                } else if (value >= 0.0000001) {
                                    return value.toFixed(7); 
                                } else {
                                    return value.toFixed(8); 
                                }
                            }
                        },
                        title:(tooltipItem: { dataset: string; raw: number; }) => {
                            // console.log(tooltipItem);
                            const dataset = tooltipItem[0].dataset.label;
                            return `${dataset}`;
                        },
                    },
                    intersect: false,
                    mode: 'point',
                    // callbacks: {
                    //     label: function (value) {
                    //         const threshold = 1.01;
                    //         return value < threshold ? null : value.toFixed(2);
                    //     },
                    // },
                },
                title: {
                    ...options?.plugins?.title,
                    display: false,
                },
            },
            layout: {
                ...options?.layout,
                padding: () => {
                    if(graphType === 'pie'){
                        return 10;
                    }
                },
            },
        };

        const newChartData = applyColorPalette(chartData, colorIdentifiers);
        console.log(newChartData);
        return {
            options: newChartOptions,
            chartData: newChartData,
            datalabelFontFamily: form.state.values.datalabelFontFamily,
        };
    };

    // useEffect(() => {
    //     const handleResize = () => {
    //         const chart = Chart.getChart("graph1Canvas");
    //         if (chart) {
    //             chart.options.scales.x.ticks.font.size = getDynamicFontSize(form.state.values.xFontSize);
    //             chart.options.scales.y.ticks.font.size = getDynamicFontSize(form.state.values.yFontSize);
    //             chart.options.plugins.legend.labels.font.size = getDynamicFontSize(form.state.values.legendFontSize);
    //             chart.options.plugins.datalabels.font.size = getDynamicFontSize(form.state.values.datalabelFontSize);
    //             chart.update();
    //         }
    //     };

    //     window.addEventListener("resize", handleResize);
    //     return () => window.removeEventListener("resize", handleResize);
    // }, [form.state.values.xFontSize, form.state.values.yFontSize, form.state.values.legendFontSize, form.state.values.datalabelFontSize]);

    useEffect(() => {
        if (setApplyGraphSettings) {
            setApplyGraphSettings(() => applyGraphSettings);
            triggerChartUpdate();
        }
    }, [setApplyGraphSettings, chartColorType, form.state.values.colorPalette]);

    // useEffect(() => {
    //     if (!formState) {
    //         // Set defaults here
    //         setFormState(graphSettingsFormDefaults);
    //     }
    // }, []);

    return (
        <ThemeProvider theme={GraphSettingsTheme}>
            <Box
                sx={{
                    width: "14vw",
                    height: "-webkit-fill-available",
                    backgroundColor: "white",
                    borderLeft: "2px solid #32463E",
                    padding: "1rem",
                    position: "fixed",
                    right: 0,
                    top: "3vw",
                    zIndex: 100,
                    overflowY: "scroll",
                    display: isSettingsVisible ? "block" : "none",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography
                        component="h2"
                        variant="h2"
                        onClick={() => {
                            console.log(formState);
                        }}
                        sx={{
                            textAlign: "left",
                            "@media (max-width: 1680px)": {
                                marginLeft: "-0.2vw",
                            },
                        }}
                    >
                        Graph Settings
                    </Typography>
                    <ThemeProvider theme={ExitButton}>
                        <Button
                            variant="contained"
                            onClick={handleSettingsClose}
                            // onClick={isSettingsVisible}
                            className="button-closer"
                        >
                            <CloseIcon />
                        </Button>
                    </ThemeProvider>
                </Box>
                <ThemeProvider theme={DropdownTheme}>
                    <Box>
                        {graphType !== "sunburst" &&
                            graphType !== "radar" &&
                            graphType !== "sdgtable" &&
                            (
                                <Stack sx={{ marginBottom: "1vh" }} direction="column" spacing={2}>
                                    <Typography component="h3" variant="h3">
                                        Value Notation
                                    </Typography>
                                    <Divider />
                                    <Typography component="h4" variant="h4">
                                        Format
                                    </Typography>
                                    <FormControl
                                        sx={{
                                            width: "-webkit-fill-available",
                                            "@media (max-width: 1680px)": {
                                                width: "-webkit-fill-available",
                                            },
                                        }}
                                        size="small"
                                    >
                                        <form.Field
                                            name="valueFormat"
                                            children={(field) => {
                                                return (
                                                    <Dropdown
                                                        options={selectableValueFormat}
                                                        onChange={(e) => {
                                                            field.handleChange(e.target.value);
                                                            triggerChartUpdate();
                                                            form.handleSubmit();
                                                        }}
                                                        id={field.name}
                                                        className={field.name}
                                                        value={field.state.value}
                                                        onBlur={field.handleBlur}
                                                        noDefaults
                                                    />
                                                );
                                            }}
                                        />
                                    </FormControl>
                                </Stack>
                            )}
                        {graphType !== "treemap" && graphType !== "pie"
                            && graphType !== "sdgtable"
                            && graphType !== 'sunburst'
                            && graphType !== 'radar'
                            && (
                                <div className="temp-wrap-stackbox">
                                    <Stack
                                        sx={{ marginBottom: "1vh" }}
                                        direction="column"
                                        spacing={2}
                                    >
                                        <Typography component="h3" variant="h3">
                                            Axis X
                                        </Typography>
                                        <Divider />
                                        <Typography component="h4" variant="h4">
                                            Font
                                        </Typography>
                                        <FormControl
                                            sx={{
                                                width: "-webkit-fill-available",
                                                "@media (max-width: 1680px)": {
                                                    width: "-webkit-fill-available",
                                                },
                                            }}
                                            size="small"
                                        >
                                            <form.Field
                                                name="xFontFamily"
                                                children={(field) => {
                                                    return (
                                                        <Dropdown
                                                            options={selectableXFontFamily}
                                                            onChange={(e) => {
                                                                field.handleChange(e.target.value);
                                                                triggerChartUpdate();
                                                                form.handleSubmit();
                                                            }}
                                                            id={field.name}
                                                            className={field.name}
                                                            value={field.state.value}
                                                            onBlur={field.handleBlur}
                                                            noDefaults
                                                            label="Family"
                                                        />
                                                    );
                                                }}
                                            />
                                        </FormControl>
                                        <FormControl
                                            sx={{
                                                width: "-webkit-fill-available",
                                                "@media (max-width: 1680px)": {
                                                    width: "-webkit-fill-available",
                                                },
                                            }}
                                            size="small"
                                        >
                                            <form.Field
                                                name="xFontWeight"
                                                children={(field) => {
                                                    return (
                                                        <Dropdown
                                                            options={selectableXFontWeight}
                                                            onChange={(e) => {
                                                                field.handleChange(e.target.value);
                                                                triggerChartUpdate();
                                                                form.handleSubmit();
                                                            }}
                                                            id={field.name}
                                                            className={field.name}
                                                            value={field.state.value}
                                                            onBlur={field.handleBlur}
                                                            noDefaults
                                                            label="Weight"
                                                        />
                                                    );
                                                }}
                                            />
                                        </FormControl>
                                        <FormControl
                                            sx={{
                                                width: "5vw",
                                                "@media (max-width: 1680px)": {
                                                    width: "3vw",
                                                },
                                            }}
                                            size="small"
                                        >
                                            <form.Field
                                                name="xFontSize"
                                                children={(field) => {
                                                    return (
                                                        <TextField
                                                            id={field.name}
                                                            className={field.name}
                                                            value={field.state.value}
                                                            onBlur={field.handleBlur}
                                                            onChange={(e) => {
                                                                field.handleChange(Number(e.target.value));
                                                                triggerChartUpdate();
                                                                form.handleSubmit();
                                                            }}
                                                            // onChange={(e) => {
                                                            //     const value = Number(e.target.value);
                                                            //     console.log('New xFontSize:', value);
                                                            //     field.handleChange(value);
                                                            // }}
                                                            label="Size"
                                                            type="number"
                                                            size="small"
                                                        // sx={{
                                                        //     width: '11rem',
                                                        //     '& .MuiInputBase-input': {
                                                        //         width: '11rem',
                                                        //     },
                                                        // }}
                                                        />
                                                    );
                                                }}
                                            />
                                        </FormControl>
                                    </Stack>
                                    <Stack
                                        sx={{ marginBottom: "1vh" }}
                                        direction="column"
                                        spacing={2}
                                    >
                                        <Typography component="h3" variant="h3">
                                            Axis Y
                                        </Typography>
                                        <Divider />
                                        <Typography component="h4" variant="h4">
                                            Font
                                        </Typography>
                                        <FormControl
                                            sx={{
                                                width: "-webkit-fill-available",
                                                "@media (max-width: 1680px)": {
                                                    width: "-webkit-fill-available",
                                                },
                                            }}
                                            size="small"
                                        >
                                            <form.Field
                                                name="yFontFamily"
                                                children={(field) => {
                                                    return (
                                                        <Dropdown
                                                            options={selectableYFontFamily}
                                                            onChange={(e) => {
                                                                field.handleChange(e.target.value);
                                                                triggerChartUpdate();
                                                                form.handleSubmit();
                                                            }}
                                                            id={field.name}
                                                            className={field.name}
                                                            value={field.state.value}
                                                            onBlur={field.handleBlur}
                                                            noDefaults
                                                            label="Family"
                                                        />
                                                    );
                                                }}
                                            />
                                        </FormControl>
                                        <FormControl
                                            sx={{
                                                width: "-webkit-fill-available",
                                                "@media (max-width: 1680px)": {
                                                    width: "-webkit-fill-available",
                                                },
                                            }}
                                            size="small"
                                        >
                                            <form.Field
                                                name="yFontWeight"
                                                children={(field) => {
                                                    return (
                                                        <Dropdown
                                                            options={selectableYFontWeight}
                                                            onChange={(e) => {
                                                                field.handleChange(e.target.value);
                                                                triggerChartUpdate();
                                                                form.handleSubmit();
                                                            }}
                                                            id={field.name}
                                                            className={field.name}
                                                            value={field.state.value}
                                                            onBlur={field.handleBlur}
                                                            noDefaults
                                                            label="Weight"
                                                        />
                                                    );
                                                }}
                                            />
                                        </FormControl>
                                        <FormControl
                                            sx={{
                                                width: "5vw",
                                                "@media (max-width: 1680px)": {
                                                    width: "3vw",
                                                },
                                            }}
                                            size="small"
                                        >
                                            <form.Field
                                                name="yFontSize"
                                                children={(field) => {
                                                    return (
                                                        <TextField
                                                            id={field.name}
                                                            className={field.name}
                                                            value={field.state.value}
                                                            onBlur={field.handleBlur}
                                                            onChange={(e) => {
                                                                field.handleChange(Number(e.target.value));
                                                                triggerChartUpdate();
                                                                form.handleSubmit();
                                                            }}
                                                            required
                                                            label="Size"
                                                            type="number"
                                                            defaultValue={1}
                                                            size="small"
                                                        // sx={{
                                                        //     width: '11rem',
                                                        //     '& .MuiInputBase-input': {
                                                        //         width: '11rem',
                                                        //     },
                                                        // }}
                                                        />
                                                    );
                                                }}
                                            />
                                        </FormControl>
                                    </Stack>
                                </div>
                            )}
                        {graphType !== "sdgtable" && (
                            <Stack sx={{ marginBottom: "1vh" }} direction="column" spacing={2}>
                                <Typography component="h3" variant="h3">
                                    Datalabels
                                </Typography>
                                <Divider />
                                {/*{graphType !== "treemap" && ( */}
                                <Box
                                    sx={{
                                        width: "-webkit-fill-available",
                                        "@media (max-width: 1680px)": {
                                            width: "-webkit-fill-available",
                                        },
                                        textAlign: 'left',
                                        marginTop: '0 !important',
                                    }}
                                >
                                    <Typography component="h4" variant="h4">
                                        Font
                                    </Typography>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={form.state.values.datalabelDisplay}
                                                onChange={(e) => {
                                                    form.setFieldValue(
                                                        "datalabelDisplay",
                                                        e.target.checked
                                                    );
                                                    triggerChartUpdate();
                                                    form.handleSubmit();
                                                }}
                                            />
                                        }
                                        label="Display Datalabels"
                                    />
                                    <FormControl
                                        sx={{
                                            width: "-webkit-fill-available",
                                            "@media (max-width: 1680px)": {
                                                width: "-webkit-fill-available",
                                            },
                                        }}
                                        size="small"
                                    >
                                        <form.Field
                                            name="datalabelFontFamily"
                                            children={(field) => {
                                                return (
                                                    <Dropdown
                                                        options={selectableDatalabelFontFamily}
                                                        onChange={(e) => {
                                                            field.handleChange(e.target.value);
                                                            triggerChartUpdate();
                                                            form.handleSubmit();
                                                        }}
                                                        id={field.name}
                                                        className={field.name}
                                                        value={field.state.value}
                                                        onBlur={field.handleBlur}
                                                        noDefaults
                                                        label="Family"
                                                    />
                                                );
                                            }}
                                        />
                                    </FormControl>
                                    <FormControl
                                        sx={{
                                            width: "-webkit-fill-available",
                                            "@media (max-width: 1680px)": {
                                                width: "-webkit-fill-available",
                                            },
                                        }}
                                        size="small"
                                    >
                                        <form.Field
                                            name="datalabelFontWeight"
                                            children={(field) => {
                                                return (
                                                    <Dropdown
                                                        options={selectableDatalabelFontWeight}
                                                        onChange={(e) => {
                                                            field.handleChange(e.target.value);
                                                            triggerChartUpdate();
                                                            form.handleSubmit();
                                                        }}
                                                        id={field.name}
                                                        className={field.name}
                                                        value={field.state.value}
                                                        onBlur={field.handleBlur}
                                                        noDefaults
                                                        label="Weight"
                                                    />
                                                );
                                            }}
                                        />
                                    </FormControl>
                                    <FormControl
                                        className="css-weznp9-MuiFormControl-root"
                                        sx={{
                                            width: "5vw",
                                            marginTop: '1.2vh',
                                            "@media (max-width: 1680px)": {
                                                width: "3vw",
                                            },
                                        }}
                                        size="small"
                                    >
                                        <form.Field
                                            name="datalabelFontSize"
                                            children={(field) => {
                                                return (
                                                    <TextField
                                                        id={field.name}
                                                        className={field.name}
                                                        value={field.state.value}
                                                        onBlur={field.handleBlur}
                                                        onChange={(e) => {
                                                            field.handleChange(Number(e.target.value));
                                                            triggerChartUpdate();
                                                            form.handleSubmit();
                                                        }}
                                                        required
                                                        label="Size"
                                                        type="number"
                                                        defaultValue={1}
                                                        size="small"
                                                    // sx={{
                                                    //     width: '11rem',
                                                    //     '& .MuiInputBase-input': {
                                                    //         width: '11rem',
                                                    //     },
                                                    // }}
                                                    />
                                                );
                                            }}
                                        />
                                    </FormControl>
                                </Box>
                                {/* )} */}

                            </Stack>
                        )}
                        {graphType === "radar"
                            && (
                                <Stack
                                    sx={{ marginBottom: "1vh" }}
                                    direction="column"
                                    spacing={2}
                                >
                                    <Typography component="h3" variant="h3">
                                            Axis R
                                    </Typography>
                                    <Divider />
                                    <Typography component="h4" variant="h4">
                                            Font
                                    </Typography>
                                    <FormControl
                                        sx={{
                                            width: "-webkit-fill-available",
                                            "@media (max-width: 1680px)": {
                                                width: "-webkit-fill-available",
                                            },
                                        }}
                                        size="small"
                                    >
                                        <form.Field
                                            name="radarFontFamily"
                                            children={(field) => {
                                                return (
                                                    <Dropdown
                                                        options={selectableRFontFamily}
                                                        onChange={(e) => {
                                                            field.handleChange(e.target.value);
                                                            triggerChartUpdate();
                                                            form.handleSubmit();
                                                        }}
                                                        id={field.name}
                                                        className={field.name}
                                                        value={field.state.value}
                                                        onBlur={field.handleBlur}
                                                        noDefaults
                                                        label="Family"
                                                    />
                                                );
                                            }}
                                        />
                                    </FormControl>
                                    <FormControl
                                        sx={{
                                            width: "-webkit-fill-available",
                                            "@media (max-width: 1680px)": {
                                                width: "-webkit-fill-available",
                                            },
                                        }}
                                        size="small"
                                    >
                                        <form.Field
                                            name="radarFontWeight"
                                            children={(field) => {
                                                return (
                                                    <Dropdown
                                                        options={selectableRFontWeight}
                                                        onChange={(e) => {
                                                            field.handleChange(e.target.value);
                                                            triggerChartUpdate();
                                                            form.handleSubmit();
                                                        }}
                                                        id={field.name}
                                                        className={field.name}
                                                        value={field.state.value}
                                                        onBlur={field.handleBlur}
                                                        noDefaults
                                                        label="Weight"
                                                    />
                                                );
                                            }}
                                        />
                                    </FormControl>
                                    <FormControl
                                        sx={{
                                            width: "5vw",
                                            "@media (max-width: 1680px)": {
                                                width: "3vw",
                                            },
                                        }}
                                        size="small"
                                    >
                                        <form.Field
                                            name="radarFontSize"
                                            children={(field) => {
                                                return (
                                                    <TextField
                                                        id={field.name}
                                                        className={field.name}
                                                        value={field.state.value}
                                                        onBlur={field.handleBlur}
                                                        onChange={(e) => {
                                                            field.handleChange(Number(e.target.value));
                                                            triggerChartUpdate();
                                                            form.handleSubmit();
                                                        }}
                                                        // onChange={(e) => {
                                                        //     const value = Number(e.target.value);
                                                        //     console.log('New xFontSize:', value);
                                                        //     field.handleChange(value);
                                                        // }}
                                                        label="Size"
                                                        type="number"
                                                        size="small"
                                                        // sx={{
                                                        //     width: '11rem',
                                                        //     '& .MuiInputBase-input': {
                                                        //         width: '11rem',
                                                        //     },
                                                        // }}
                                                    />
                                                );
                                            }}
                                        />
                                    </FormControl>
                                </Stack>
                            )}
                        {((graphType !== "treemap" && chartColorType !== "treemap") && (chartColorType !== "sdg17" || chartColorType !== "sdg"  && sdgOn)
                            && (graphType !== "sunburst"))
                            && (
                                <div className="temp-wrap-stackbox">
                                    <Stack
                                        sx={{ marginBottom: "1vh" }}
                                        direction="column"
                                        spacing={2}
                                    >
                                        <Typography component="h3" variant="h3">
                                            Colors
                                        </Typography>
                                        <Divider />
                                        <Typography component="h4" variant="h4">
                                            Palette
                                        </Typography>
                                        <FormControl
                                            sx={{
                                                width: "-webkit-fill-available",
                                                "@media (max-width: 1680px)": {
                                                    width: "-webkit-fill-available",
                                                },
                                            }}
                                            size="small"
                                        >
                                            <form.Field
                                                name="colorPalette"
                                                children={(field) => {
                                                    return (
                                                        <Dropdown
                                                            options={selectableColorPaletteNames}
                                                            onChange={({ target: { value } }) => {
                                                                try {
                                                                    console.log("Palette selected:", value);
                                                                    field.handleChange(value);
                                                                    triggerChartUpdate();
                                                                    form.handleSubmit();
                                                                } catch (error) {
                                                                    console.error(
                                                                        "Error updating chart or submitting form:",
                                                                        error
                                                                    );
                                                                }
                                                            }}
                                                            id={field.name}
                                                            className={field.name}
                                                            value={field.state.value}
                                                            onBlur={field.handleBlur}
                                                            noDefaults
                                                            label="Palette"
                                                        />
                                                    );
                                                }}
                                            />
                                        </FormControl>
                                    </Stack>

                                </div>
                            )}

                        {((graphType !== "treemap") && (graphType !== "bar" && chartColorType !== "sdg") && (graphType !== "sdgtable")) && (
                            <Stack
                                sx={{ marginBottom: "1vh" }}
                                direction="column"
                                spacing={2}
                            >
                                <Typography component="h3" variant="h3">
                                    Legend
                                </Typography>
                                <Divider />
                                <Typography component="h4" variant="h4">
                                    Font
                                </Typography>
                                <FormControl
                                    sx={{
                                        width: "-webkit-fill-available",
                                        "@media (max-width: 1680px)": {
                                            width: "-webkit-fill-available",
                                        },
                                    }}
                                    size="small"
                                >
                                    <form.Field
                                        name="legendFontFamily"
                                        children={(field) => {
                                            return (
                                                <Dropdown
                                                    options={selectableLegendFontFamily}
                                                    onChange={(e) => {
                                                        field.handleChange(e.target.value);
                                                        triggerChartUpdate();
                                                        form.handleSubmit();
                                                    }}
                                                    id={field.name}
                                                    className={field.name}
                                                    value={field.state.value}
                                                    onBlur={field.handleBlur}
                                                    noDefaults
                                                    label="Family"
                                                />
                                            );
                                        }}
                                    />
                                </FormControl>
                                <FormControl
                                    sx={{
                                        width: "-webkit-fill-available",
                                        "@media (max-width: 1680px)": {
                                            width: "-webkit-fill-available",
                                        },
                                    }}
                                    size="small"
                                >
                                    <form.Field
                                        name="legendFontWeight"
                                        children={(field) => {
                                            return (
                                                <Dropdown
                                                    options={selectableLegendFontWeight}
                                                    onChange={(e) => {
                                                        field.handleChange(e.target.value);
                                                        triggerChartUpdate();
                                                        form.handleSubmit();
                                                    }}
                                                    id={field.name}
                                                    className={field.name}
                                                    value={field.state.value}
                                                    onBlur={field.handleBlur}
                                                    noDefaults
                                                    label="Weight"
                                                />
                                            );
                                        }}
                                    />
                                </FormControl>
                                <FormControl
                                    sx={{
                                        width: "5vw",
                                        "@media (max-width: 1680px)": {
                                            width: "3vw",
                                        },
                                    }}
                                    size="small"
                                >
                                    <form.Field
                                        name="legendFontSize"
                                        children={(field) => {
                                            return (
                                                <TextField
                                                    id={field.name}
                                                    className={field.name}
                                                    value={field.state.value}
                                                    onBlur={field.handleBlur}
                                                    onChange={(e) => {
                                                        field.handleChange(Number(e.target.value));
                                                        triggerChartUpdate();
                                                        form.handleSubmit();
                                                    }}
                                                    required
                                                    label="Size"
                                                    type="number"
                                                    defaultValue={1}
                                                    size="small"
                                                // sx={{
                                                //     width: '11rem',
                                                //     '& .MuiInputBase-input': {
                                                //         width: '11rem',
                                                //     },
                                                // }}
                                                />
                                            );
                                        }}
                                    />
                                </FormControl>
                            </Stack>
                        )}
                        {graphType !== "treemap"
                            && graphType !== "pie"
                            && graphType !== "sdgtable"
                            && graphType !== "sunburst"
                            && graphType !== 'radar'
                            && (
                                <Stack
                                    sx={{ marginBottom: "1vh" }}
                                    direction="column"
                                    spacing={2}
                                >
                                    <Typography component="h3" variant="h3">
                                        Scales
                                    </Typography>
                                    <Divider />
                                    <Typography component="h4" variant="h4">
                                        Type
                                    </Typography>
                                    <FormControl
                                        sx={{
                                            width: "-webkit-fill-available",
                                            "@media (max-width: 1680px)": {
                                                width: "-webkit-fill-available",
                                            },
                                        }}
                                        size="small"
                                    >
                                        <form.Field
                                            name="scaleType"
                                            children={(field) => {
                                                return (
                                                    <Dropdown
                                                        options={["Linear", "Logarithmic"]}
                                                        onChange={(e) => {
                                                            field.handleChange(
                                                                toInternalScaleType(e.target.value)
                                                            );
                                                            triggerChartUpdate();
                                                            form.handleSubmit();
                                                        }}
                                                        id={field.name}
                                                        className={field.name}
                                                        value={toVisualScaleType(field.state.value)}
                                                        onBlur={field.handleBlur}
                                                        noDefaults
                                                        label="Scale Type"
                                                    />
                                                );
                                            }}
                                        />
                                    </FormControl>
                                </Stack>
                            )}
                    </Box>
                </ThemeProvider>
            </Box>
        </ThemeProvider>
    );
}

export default GraphSettingsOptions;
