import React, { useState } from 'react';
import {
    MenuItem,
    Select,
    Checkbox,
    ListItemText,
    FormControl,
    InputLabel,
    OutlinedInput,
    SelectChangeEvent,
    FormControlLabel,
    Box,
    CheckboxProps,
} from '@mui/material';
import { ThemeProvider, styled } from '@mui/styles';
import '../../../../dropdowns/DropdownTheme.css'; // Ensure your CSS file is imported
import DropdownTheme from '../../../../style/components/DropdownTheme';
import useStyles from './styles/ComparisonTheme.styles';
import dropdownWrapperStyle from './styles/DropdownWrapper.styles';
import selectBorderRemove from './styles/SelectBorderRemove.styles';

const comparisons = [
    'Another Product',
    'Industry Average',
    'Scaled Down PB',
    'Best in Industry',
    'Company Target',
    'Industry Target',
];

function ProductComparison() {
    const classes = useStyles();
    const dropdownWrapperclasses = dropdownWrapperStyle();
    const borderRemoveClasses = selectBorderRemove();
    const [productComparison, setProductComparison] = useState<string[]>([]);
    const [activateComparison, setActivateComparison] = useState<boolean>(false);

    const handleChangeSDGLinkage = (event: SelectChangeEvent<typeof productComparison>) => {
        const {
            target: { value },
        } = event;
        setProductComparison(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleComparisonSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setActivateComparison(event.target.checked);
    };

    const CheckboxPadding = styled(Checkbox)<CheckboxProps>(({ theme }) => {
        return {
            marginLeft: 0,
            marginRight: 0,
            '& .MuiSvgIcon-root': {
                width: '1em',
                height: '1em',
                fontSize: '1.2vw',
            },
        };
    });

    return (
        <ThemeProvider theme={DropdownTheme}>
            <Box className={dropdownWrapperclasses.DropdownSwitchWrapper}>
                <FormControlLabel
                    control={
                        <CheckboxPadding
                            checked={activateComparison}
                            onChange={handleComparisonSwitch}
                            sx={{
                                padding: 0,
                                marginLeft: 0,
                                marginRight: 0,
                                '& .MuiSvgIcon-root': {
                                    width: '1em',
                                    height: '1em',
                                    fontSize: '1.2vw',
                                },
                            }}
                        />
                    }
                    sx={{
                        padding: 0,
                        marginLeft: 0,
                        marginRight: 0,
                    }}
                    label=""
                />
                <FormControl
                    sx={{
                        width: 265,
                        borderRadius: 0,
                        height: 'auto',
                        '@media (max-width: 1680px)': {
                            width: 220,
                        },
                    }}
                    size="small"
                >
                    <InputLabel
                        id="comparison-checkbox-label"
                        sx={{
                            color: activateComparison ? 'text.primary' : 'text.disabled',
                        }}
                    >
                        Comparison
                    </InputLabel>
                    <Select
                        labelId="comparison-checkbox-label"
                        id="comparison-checkbox"
                        multiple
                        value={productComparison}
                        onChange={handleChangeSDGLinkage}
                        input={<OutlinedInput label="Tag" />}
                        variant="standard"
                        classes={{
                            root: borderRemoveClasses.select,
                        }}
                        MenuProps={{
                            PaperProps: {
                                className: 'customDropdownPaper', // Apply custom class here
                            },
                        }}
                    >
                        {comparisons.map((comparison) => (
                            <MenuItem key={comparison} value={comparison} sx={{ fontSize: '0.7vw' }}>
                                <ListItemText primary={comparison} sx={{ fontSize: '0.7vw', textAlign: 'left' }} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
        </ThemeProvider>
    );
}

export default ProductComparison;
