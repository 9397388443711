// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#tail1 {
    position: relative;
    bottom: 0;
    left: 0px;
    width: 0;
    height: 0;
    border-color: transparent #707070 transparent transparent;
    border-width: 0.5vw;
    border-style: solid;
    margin-left: -12px;
}

/* #tail2 {
    position: relative;
    bottom: 0;
    left: 0;
    width: 0;
    height: 0;
    border-color: transparent #707070 transparent transparent;
    border-width: 10px;
    border-style: solid;
} */`, "",{"version":3,"sources":["webpack://./src/components/createproduct/SocialInputs/components/SocialInfoPopup/SocialInfoPopupTheme.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,SAAS;IACT,SAAS;IACT,QAAQ;IACR,SAAS;IACT,yDAAyD;IACzD,mBAAmB;IACnB,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;;;;;;;;;GASG","sourcesContent":["#tail1 {\r\n    position: relative;\r\n    bottom: 0;\r\n    left: 0px;\r\n    width: 0;\r\n    height: 0;\r\n    border-color: transparent #707070 transparent transparent;\r\n    border-width: 0.5vw;\r\n    border-style: solid;\r\n    margin-left: -12px;\r\n}\r\n\r\n/* #tail2 {\r\n    position: relative;\r\n    bottom: 0;\r\n    left: 0;\r\n    width: 0;\r\n    height: 0;\r\n    border-color: transparent #707070 transparent transparent;\r\n    border-width: 10px;\r\n    border-style: solid;\r\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
