/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable react/function-component-definition */
/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useContext, useEffect, useState } from "react";
// import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import { Typography } from "@mui/material";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ClearIcon from "@mui/icons-material/Clear";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CottageIcon from "@mui/icons-material/Cottage";
import InventoryIcon from "@mui/icons-material/Inventory";
import PendingIcon from "@mui/icons-material/Pending";
import PublicIcon from "@mui/icons-material/Public";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import "./MainMenu.css";
import TossaLogo from "../../uploads/images/tossa_logo.png";
import ProductListItem from "./components/ProductListItem";
import ComparisonItem from "./components/ComparisonItem";
import ProductProgressItem from "./components/ProductProgressItem";
import mainMenuBanner from "../../uploads/images/backgrounds/pexels-rien-ramerman-banner.jpg";
import menutheme from "./MainMenuTheme";
import { useHttpClient } from "../../hooks/http-hook";
import { useNavigate } from "@tanstack/react-router";
import { getAccessTokenFromSessionStorage } from "../../helper/StorageHelper";
import { requestProductDelete } from "../../helper/fetchUtil";
import ConfirmProductDelete from "./components/ConfirmProductDelete";

/**
 * This is a group that contains all of the created products made for this section.
 */

interface MainMenuProps {
  showCreateProductModal: boolean;
  setShowCreateProductModal: React.Dispatch<React.SetStateAction<boolean>>;
}
interface ProductResponse {
  products: ProductNameId[];
}
interface ProductNameId {
  _id: string;
  productName: string;
}
const MainMenu = () => {
    const { sendRequest } = useHttpClient();

    // REQUEST TO BACKEND

    const [products, setProducts] = useState<ProductNameId[]>([]);

    useEffect(() => {
        const fetchProducts = async () => {
            const token = getAccessTokenFromSessionStorage();
            const response = await sendRequest<ProductResponse>(
                `${process.env.REACT_APP_BACKEND_URL}/product/`,
                "GET",
                undefined,
                { Authorization: "Bearer " + token }
            );
            setProducts(response.products);
        };

        fetchProducts()
            .then((result) => {
                // Handle successful result here
                console.log("Products fetched successfully:", result);
            })
            .catch((err) => {
                // Handle error here
                console.error("Error fetching products:", err);
            });
    }, [sendRequest]);

    const [productsIsDown, setProductsIsDown] = useState(true);
    const [productListIsDown, setProductListIsDown] = useState(true);
    const [comparisonIsDown, setComparisonIsDown] = useState(false);
    const [progressIsDown, setProgressIsDown] = useState(false);

    const navigate = useNavigate();

    return (
        <ThemeProvider theme={menutheme}>
  
            <List
                className="product-list__content"
                component="div"
                sx={{
                    marginTop: "3vw",
                    position: "fixed",
                    bgcolor: "white",
                    paddingBottom: 0,
                    color: "black",
                    zIndex: 10,
                }}
            >
                <Box className="app-tossa-menu">
                    <img src={TossaLogo} alt="tossa-logo" className="website-logo" />
                    <Box
                        className="menu-banner-cover"
                        sx={{
                            bgcolor: "white",
                            // fill: 'white',
                            opacity: 0.75,
                            height: "16vh",
                            width: "24vw",
                            position: "absolute",
                            zIndex: 1,
                        }}
                    />

                    <img
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                        src={mainMenuBanner}
                        alt="background of a nice landscape"
                        className="menu-banner"
                    />
                </Box>
                {/* <div className='main-menu-title-box-wrap'> */}
                <Box className="main-menu-wrap">
                    <Box className="main-menu-box" component="ul">
                        <ListItem>
                            <ListItemButton
                                className="main-menu__title-box"
                                sx={{ borderBottom: "1px solid #707070" }}
                                onClick={() => {
                                    return navigate({
                                        to: "/app",
                                    });
                                }}
                            >
                                <ListItemIcon>
                                    <CottageIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Home"
                                    className="main-menu__title"
                                    primaryTypographyProps={{ variant: "h2" }}
                                />
                            </ListItemButton>
                        </ListItem>
                        {/* </div> */}
                        {/* <div className='main-menu-title-box-wrap created-product__list'> */}
                        <ListItem
                            sx={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <ListItemButton
                                className="main-menu__title-box"
                                onClick={() => {
                                    setProductListIsDown(!productListIsDown);
                                }}
                                sx={{
                                    width: "100%",
                                    borderBottom: "1px solid #707070",
                                }}
                            >
                                <ListItemIcon>
                                    <InventoryIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Product List"
                                    className="main-menu__title"
                                    primaryTypographyProps={{ variant: "h2" }}
                                />
                                <ListItemIcon sx={{ justifyContent: "flex-end" }}>
                                    {productListIsDown ? (
                                        <KeyboardArrowUpIcon />
                                    ) : (
                                        <KeyboardArrowDownIcon />
                                    )}
                                </ListItemIcon>
                            </ListItemButton>
                            <Collapse
                                in={productListIsDown}
                                timeout="auto"
                                orientation="vertical"
                                unmountOnExit
                                sx={{
                                    width: "100%",
                                }}
                            >
                                <List
                                    className="product-list__title-box"
                                    onClick={() => {
                                        setProductsIsDown(!productsIsDown);
                                    }}
                                    component="div"
                                >
                                    <ListItem
                                        sx={{
                                            width: "100%",
                                            padding: "0",
                                            borderBottom: "1px solid #707070",
                                        }}
                                    >
                                        <ListItemButton className="product-list-sub__title">
                                            <ListItemText
                                                primary="Products"
                                                className="product-list__title"
                                                primaryTypographyProps={{ variant: "h3" }}
                                            />
                                            <ListItemIcon className="product-list__title-arrow">
                                                {productsIsDown ? (
                                                    <KeyboardArrowUpIcon />
                                                ) : (
                                                    <KeyboardArrowDownIcon />
                                                )}
                                            </ListItemIcon>
                                        </ListItemButton>
                                    </ListItem>
                                </List>
                                <Collapse in={productsIsDown} timeout="auto" unmountOnExit>
                                    <List className="product-list characters">
                                        {products.map((product) => {
                                            return (
                                                <ProductListItem
                                                    productName={`${product.productName}`}
                                                    id={`${product._id}`}
                                                    key={`${product._id}`}
                                                    setProducts={setProducts}
                                                    products={products}
                                                />
                                            );
                                        })}
                                    </List>
                                </Collapse>
                                <List
                                    className="product-list__title-box"
                                    onClick={() => {
                                        setComparisonIsDown(!comparisonIsDown);
                                    }}
                                    component="div"
                                >
                                    <ListItem
                                        sx={{
                                            width: "100%",
                                            padding: "0",
                                            borderBottom: "1px solid #707070",
                                        }}
                                    >
                                        <ListItemButton className="product-list-sub__title">
                                            <ListItemText
                                                primary="Product Comparisons"
                                                className="product-list__title"
                                                primaryTypographyProps={{ variant: "h3" }}
                                            />
                                            <ListItemIcon className="product-list__title-arrow">
                                                {comparisonIsDown ? (
                                                    <KeyboardArrowUpIcon />
                                                ) : (
                                                    <KeyboardArrowDownIcon />
                                                )}
                                            </ListItemIcon>
                                        </ListItemButton>
                                    </ListItem>
                                </List>
                                <Collapse
                                    in={comparisonIsDown}
                                    timeout="auto"
                                    orientation="vertical"
                                    unmountOnExit
                                >
                                    {/* <div
            className='product-list__content-wrap'
            style={productsIsDown ? { display: 'none' } : {}}
          > */}
                                    <List className="product-list characters">
                                        {/* <ComparisonItem productName="Comparison A" />
                                        <ComparisonItem productName="Comparison B" />
                                        <ComparisonItem productName="Comparison C" /> */}
                                    </List>
                                    {/* </div> */}
                                </Collapse>
                            </Collapse>
                        </ListItem>

                        {/* </div> */}
                        <ListItem
                            sx={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <ListItemButton
                                className="main-menu__title-box"
                                onClick={() => {
                                    setProgressIsDown(!progressIsDown);
                                }}
                                sx={{
                                    width: "100%",
                                    borderBottom: "1px solid #707070",
                                }}
                            >
                                <ListItemIcon>
                                    <PendingIcon />
                                </ListItemIcon>
                                {/* <Typography component='h2'  variant='h3' className='main-menu__title'>Home</Typography > */}
                                {/* <h3 className='main-menu__title'>Product List</h3> */}
                                <ListItemText
                                    primary="Products In Progress"
                                    className="main-menu__title"
                                    primaryTypographyProps={{ variant: "h2" }}
                                />
                                <ListItemIcon sx={{ justifyContent: "flex-end" }}>
                                    {progressIsDown ? (
                                        <KeyboardArrowUpIcon />
                                    ) : (
                                        <KeyboardArrowDownIcon />
                                    )}
                                </ListItemIcon>
                            </ListItemButton>
                            <Collapse
                                in={progressIsDown}
                                timeout="auto"
                                unmountOnExit
                                orientation="vertical"
                                sx={{
                                    width: "100%",
                                }}
                            >
                                <List
                                    className="product-list__title-box"
                                    component="div"
                                    sx={{
                                        width: "100%",
                                        padding: "0",
                                    }}
                                >
                                    {/* <ProductProgressItem productName="Progress A" />
                                    <ProductProgressItem productName="Progress B" />
                                    <ProductProgressItem productName="Progress C" /> */}
                                </List>
                            </Collapse>
                        </ListItem>
                    </Box>
                    <Box className="main-menu-box menu-bottom-options" component="ul">
                        <ListItem
                            sx={{
                                color: "#004021",
                                backgroundColor: "white",
                            }}
                        >
                            <ListItemButton
                                className="main-menu__title-box"
                                onClick={() => {
                                    return navigate({
                                        to: "/",
                                    });
                                }}
                            >
                                <ListItemIcon>
                                    <PublicIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Return to Website"
                                    className="main-menu__title"
                                    primaryTypographyProps={{ variant: "h2" }}
                                />
                            </ListItemButton>
                        </ListItem>
                        <ListItem
                            sx={{
                                bgcolor: "#004021",
                                color: "white",
                            }}
                        >
                            <ListItemButton
                                className="main-menu__title-box"
                                //   onClick={() => {
                                //       setShowCreateProductModal(!showCreateProductModal);
                                //   }}
                                onClick={() => {
                                    return navigate({
                                        to: "./createproduct/details",
                                    });
                                }}
                            >
                                <ListItemIcon>
                                    <AddCircleIcon
                                        sx={{
                                            color: "white",
                                        }}
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Create Product"
                                    primaryTypographyProps={{ variant: "h2" }}
                                    className="main-menu__title"
                                />
                            </ListItemButton>
                            {/* </Link> */}
                        </ListItem>
                    </Box>
                </Box>
            </List>
        </ThemeProvider>
    );
};

export default MainMenu;
