/* eslint-disable @typescript-eslint/no-throw-literal */
import React from 'react';
import { getAccessTokenFromCookies, getAccessTokenFromSessionStorage } from '../helper/StorageHelper';
import {
    createRoute,
    redirect,
} from '@tanstack/react-router';
import Dashboard from '../screens/Dashboard';
import { generateIndexRoute } from './CreateRoute';
import Chart from '../screens/Chart';
import TestComponent from '../screens/Dashboard/TestComponent';
import ContributionTree from '../screens/ContributionTree';
import productRoutes from './ProductRoutes';
import inputRoutes from './InputRoutes';
function protectedRoutes() {
    const dashboardIndexRoute = generateIndexRoute('app');
    const chartIndexRoute = generateIndexRoute('chart');
    const processIndexRoute = generateIndexRoute('process');

    const dashboardRoute = createRoute({
        beforeLoad: () => {
            if (getAccessTokenFromSessionStorage() === null) {
                throw redirect({
                    to: '/login',
                });
            }
        },
        getParentRoute: () => {return dashboardIndexRoute},
        path: '/',
        component: () => {return <Dashboard />},
    });

    const testRoute = createRoute({
        beforeLoad: () => {
            if (getAccessTokenFromSessionStorage() === null) {
                throw redirect({
                    to: '/login',
                });
            }
        },
        getParentRoute: () => {return dashboardRoute},
        path: '/test',
        component: () => {return <TestComponent />},
    });

    const chartRoute = createRoute({
        beforeLoad: () => {
            if (getAccessTokenFromSessionStorage() === null) {
                throw redirect({
                    to: '/login',
                });
            }
        },
        getParentRoute: () => {return chartIndexRoute},
        path: '/',
        component: () => {return <Chart />},
    });

    const contTreeRoute = createRoute({
        beforeLoad: () => {
            if (getAccessTokenFromCookies() === null) {
                throw redirect({
                    to: '/login',
                });
            }
        },
        getParentRoute: () => {return processIndexRoute},
        path: '/',
        component: () => {return <ContributionTree />},
    });

    const routes = [
        dashboardIndexRoute.addChildren([
            dashboardRoute.addChildren([testRoute]),
        ]),
        chartIndexRoute.addChildren([
            chartRoute,
        ]),
        processIndexRoute.addChildren([contTreeRoute]),
        productRoutes(),
        inputRoutes(),
        // createProductIndexRoute.addChildren([createProductRoute]),
    ];

    return routes;
}

export default protectedRoutes;
