import React, {
    useEffect,
    useState,
    Dispatch,
    SetStateAction,
    SyntheticEvent,
} from 'react';
import { styled } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import NativeSelect from '@mui/material/NativeSelect';
import InputBase from '@mui/material/InputBase';
import { Product, EnvironmentalResult } from '../../../../interface/Product';
import Dropdown from '../../Dropdown';
import ThemeProvider from '@mui/styles/ThemeProvider';
import DropdownTheme from '../../../style/components/DropdownTheme';
import { useLocation } from 'react-router-dom';
import { useNavigate } from '@tanstack/react-router';
import { useProductStore } from '../../../../hooks/useProductStore';

interface Props {
    product: Product;
    defaultBaseIndex: number,
    defaultResultIndex: number,
}
function EcoChartNavigation(props: Props) {
    const { product, defaultBaseIndex, defaultResultIndex } = props;
    const [selectedBaseOption, setSelectedBaseOption] = useState<string>('');

    const [selectedResultsLayer, setSelectedResultsLayer] = useState<string>('');
    const {
        selectedBaseOption: prevSelectedBaseOption, updateSelectedBaseOption,
        selectedResultsLayer: prevSelectedResultsLayer, updateSelectedResultsLayer,
    } = useProductStore();

    const navigate = useNavigate();

    const baseOptions = [
        'Total cost result',
        'Cost category level result',
    ];

    const resultsOptions = [
        'Product System',
        'LifeCycle Stage',
        'LifeCycle Substage',
        'Process',
    ];

    const navigationHandler = async (baseOption: string, resultsLayer: string) => {
        if (baseOption && resultsLayer) {
            if (
                baseOption === 'Total cost result'
            ) {
                switch (resultsLayer) {
                case 'Product System':
                    await navigate({ to: '../../A1/Bar' });
                    break;
                case 'LifeCycle Stage':
                    await navigate({ to: '../../A2/Bar' });
                    break;
                case 'LifeCycle Substage':
                    await navigate({ to: '../../A3/Bar' });
                    break;
                case 'Process':
                    await navigate({ to: '../../A4/Bar' });
                    break;
                default:
                    break;
                }
            }
        }
    };

    const baseOptionChangeHandler = (value: string) => {
        setSelectedBaseOption(value);
        navigationHandler(value, selectedResultsLayer);
    };
    const resultsLayerChangeHandler = (value: string) => {
        setSelectedResultsLayer(value);
        navigationHandler(selectedBaseOption, value);
    };

    useEffect(() => {
        if (selectedBaseOption !== prevSelectedBaseOption) {
            updateSelectedBaseOption(selectedBaseOption);
        }
    }, [
        selectedBaseOption,
    ]);

    useEffect(() => {
        if (selectedResultsLayer !== prevSelectedResultsLayer) {
            updateSelectedResultsLayer(selectedResultsLayer);
        }
    }, [selectedResultsLayer]);

    useEffect(() => {
        setSelectedBaseOption(prevSelectedBaseOption || 'Total cost result');
        setSelectedResultsLayer(prevSelectedResultsLayer || 'Product System');
    }, []);

    // useEffect(() => {
    //     setSelectedBaseOption((defaultBaseIndex && baseOptions[defaultBaseIndex]) || 'Total cost result');
    //     setSelectedResultsLayer((defaultResultIndex && resultsOptions[defaultResultIndex]) || 'Product System');
    // }, []);

    return (
        <ThemeProvider theme={DropdownTheme}>
            <FormControl sx={{ width: '178px' }} size='small'>
                <>
                    {baseOptions.length > 0 && (
                        <Dropdown
                            options={baseOptions}
                            valueSetter={baseOptionChangeHandler}
                            defaultValue={prevSelectedBaseOption}
                            value={selectedBaseOption}
                        />
                    )}
                    {resultsOptions.length > 0 && (
                        <Dropdown
                            options={resultsOptions}
                            valueSetter={resultsLayerChangeHandler}
                            defaultValue={prevSelectedResultsLayer}
                            value={selectedResultsLayer}
                        />
                    )}
                </>
            </FormControl>
        </ThemeProvider>
    );
}

export default EcoChartNavigation;
