import Chart from 'chart.js';
import { ChartJSData, ChartJSOptions } from '../../../../interface/ChartData';
import autoRound from '../../../../helper/autoRound';
import { initialOptions } from '../InitialOptionValues';

export interface ApplyOptionReturn {
  chartData: ChartJSData;
  options: ChartJSOptions;
}
export const applyValueType = (
    valueType: string,
    chartData: ChartJSData,
    options: ChartJSOptions | undefined,
    multipleCategories?: boolean,
): ApplyOptionReturn => {
    if(options){

    
        if (
            valueType === '% Total'
    || valueType === '% Per Product System'
    || valueType === '% Per Lifecycle Substage'
    || valueType === '% Per Lifecycle Stage'
    || valueType === '% Per Flow'
    || valueType === '% Per Process'
    || valueType === '% Per Subcategory'
        ) {
            console.log(multipleCategories);
            if (chartData.datasets.length > 0) {
                if (!multipleCategories) {
                    let total = 0;
                    chartData.datasets.forEach((dataset) => {
                        if (dataset.data) {
                            total += Math.abs(dataset.data[0]);
                        }
                    });
                    chartData.datasets = chartData.datasets.map((dataset) => {return {
                        ...dataset,
                        data: dataset.data?.map(
                            (data) => {return data && autoRound((Math.abs(data) / total) * 100, 2)},
                        ),
                    }});
                } else {
                    const totals = chartData.datasets[0].data.map((data, index) =>{return chartData.datasets.reduce(
                        (total, _dataset) => {return total + _dataset.data[index]},
                        0,
                    )});
                    console.log(totals);
                    chartData.datasets = chartData.datasets.map((dataset) => {return {
                        ...dataset,
                        data: dataset.data.map((data, index) => {
                            console.log(data);
                            const num = autoRound((Math.abs(data) / totals[index]) * 100, 2);
                            return num;
                        }),
                    }});
                }
            }

            options = {
                ...options,
                scales: {
                    ...options.scales,
                    x: {
                        ...options.scales?.x,
                        stacked: true,
                    },
                    y: {
                        ...options.scales?.y,
                        stacked: true,
                        title: {
                            ...options.scales?.y?.title,
                            text: '%',
                        },
                        max: 100,
                        min: 0,
                    },
                },
                plugins: {
                    ...options.plugins,
                    tooltip: {
                        ...options?.plugins?.tooltip,
                        callbacks: {
                            ...options?.plugins?.tooltip?.callbacks,
                            title: (
                                yData: [
                {
                  dataset: {
                    label: string;
                  };
                },
              ],
                            ) => {return yData[0].dataset.label},
                            label: (yData: { raw: number }) => {
                            // Rounds the numbers to a certain digit.
                                let numToReturn: number;
                                // if (
                                //     formState.inputs.graph1Notation.value === 'Exponential'
                                // ) {
                                //     return autoRound(yData.raw, 2).toExponential();
                                // } else {
                                //     return autoRound(yData.raw, 5);
                                // }
                                // eslint-disable-next-line prefer-const
                                numToReturn = autoRound(yData.raw, 5);
                                return numToReturn.toString();
                            },
                        },
                    },
                },
            };
        } else if (valueType === 'Relative Contribution') {
            options = {
                ...options,
                scales: {
                    ...options.scales,
                    x: {
                        ...options.scales?.x,
                        stacked: false,
                    },
                    y: {
                        ...options.scales?.y,
                        stacked: false,
                        max: 100,
                        min: 0,
                    },
                },
            };
        } else {
            // raw values
            options = {
                ...options,
                scales: {
                    ...options.scales,
                    x: {
                        ...options.scales?.x,
                        stacked: true,
                    },
                    y: {
                        ...options.scales?.y,
                        stacked: true,
                        max: undefined,
                        min: undefined,
                    },
                },
                plugins: {
                    ...options.plugins,
                    tooltip: {
                        ...options?.plugins?.tooltip,
                        callbacks: {
                            ...options?.plugins?.tooltip?.callbacks,
                            title: (
                                yData: [
                {
                  dataset: {
                    label: string;
                  };
                },
              ],
                            ) => {return yData[0].dataset.label},
                            label: (yData: { raw: number }) => {
                            // Rounds the numbers to a certain digit.
                                let numToReturn: number;
                                // if (
                                //     formState.inputs.graph1Notation.value === 'Exponential'
                                // ) {
                                //     return autoRound(yData.raw, 2).toExponential();
                                // } else {
                                //     return autoRound(yData.raw, 5);
                                // }
                                // eslint-disable-next-line prefer-const
                                numToReturn = autoRound(yData.raw, 5);
                                return numToReturn.toString();
                            },
                        },
                    },
                },
            };
        }
        return { chartData, options };

    }
    return {chartData, options:initialOptions};
};

export const applyStacked = (
    options: ChartJSOptions | undefined,
    stacked: boolean,
): ChartJSOptions | undefined => {
    if(options){
        options = {
            ...options,
            scales: {
                ...options.scales,
                x: {
                    ...options.scales?.x,
                    stacked,
                },
                y: {
                    ...options.scales?.y,
                    stacked,
                },
            },
        };
    }
 
    return options;
};
