import React, {
    useEffect,
    useState,
    Dispatch,
    SetStateAction,
    SyntheticEvent,
} from 'react';
import { styled } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import NativeSelect from '@mui/material/NativeSelect';
import InputBase from '@mui/material/InputBase';
import { Product, EnvironmentalResult } from '../../../../interface/Product';
import Dropdown from '../../Dropdown';
import ThemeProvider from '@mui/styles/ThemeProvider';
import DropdownTheme from '../../../style/components/DropdownTheme';
import { useLocation } from 'react-router-dom';
import { useNavigate } from '@tanstack/react-router';
import { useProductStore } from '../../../../hooks/useProductStore';
import useStyles from './EnvChartNavigation.styles';

interface Props {
    product: Product;
    defaultBaseIndex?: number,
    defaultResultIndex?: number,
    sdgactive?: boolean,
}
function EnvChartNavigation(props: Props) {
    const { product, defaultBaseIndex, defaultResultIndex, sdgactive } = props;

    const classes = useStyles();
    const navigate = useNavigate();

    const baseOptions = [
        'Total weighted result: Single index',
        'Total weighted result: Damage type level',
        'Normalized results: impact category level',
        'Midpoint result: Impact category level',
        'Inventory result: Elementary flow level',
    ];

    const resultsOptions = [
        'Product System',
        'LifeCycle Stage',
        'LifeCycle Substage',
        'Process',
    ];

    const [selectedBaseOption, setSelectedBaseOption] = useState<string>(baseOptions[defaultBaseIndex]);
    const [selectedResultsLayer, setSelectedResultsLayer] = useState<string>(resultsOptions[defaultResultIndex]);
    const FormWrapper = styled(FormControl)(({ theme }) => {
        return {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            gap: '1vw',
        }
    });

    const navigationHandler = async (baseOption: string, resultsLayer: string) => {
        console.log(baseOption + " " + resultsLayer);
        if (baseOption && resultsLayer) {

            if (sdgactive === true) {
                if (
                    baseOption === 'Total weighted result: Single index'
                ) {
                    switch (resultsLayer) {
                    case 'Product System':
                        await navigate({ to: '../../../../Environmental/A1/Bar' });
                        break;
                    case 'LifeCycle Stage':
                        await navigate({ to: '../../../../Environmental/A2/Bar' });
                        break;
                    case 'LifeCycle Substage':
                        await navigate({ to: '../../../../Environmental/A3/Bar' });
                        break;
                    case 'Process':
                        await navigate({ to: '../../../../Environmental/A4/Bar' });
                        break;
                    default:
                        break;
                    }
                }
                else if (
                    baseOption === 'Total weighted result: Damage type level'


                ) {
                    switch (resultsLayer) {
                    case 'Product System':
                        await navigate({ to: '../../../../Environmental/B1/Bar' });
                        break;
                    case 'LifeCycle Stage':
                        await navigate({ to: '../../../../Environmental/B2/Bar' });
                        break;
                    case 'LifeCycle Substage':
                        await navigate({ to: '../../../../Environmental/B3/Bar' });
                        break;
                    case 'Process':
                        await navigate({ to: '../../../../Environmental/B4/Bar' });
                        break;
                    default:
                        break;
                    }
                }
                else if (
                    baseOption === 'Midpoint result: Impact category level'
                ) {
                    switch (resultsLayer) {
                    case 'Product System':
                        await navigate({ to: '../../../../Environmental/D1/Bar' });
                        break;
                    case 'LifeCycle Stage':
                        await navigate({ to: '../../../../Environmental/D2/Bar' });
                        break;
                    case 'LifeCycle Substage':
                        await navigate({ to: '../../../../Environmental/D3/Bar' });
                        break;
                    case 'Process':
                        await navigate({ to: '../../../../Environmental/D4/Bar' });
                        break;
                    default:
                        break;
                    }
                } else if (
                    baseOption === 'Normalized results: impact category level'
                ) {
                    switch (resultsLayer) {
                    case 'Product System':
                        await navigate({ to: '../../../../Environmental/C1/Bar' });
                        break;
                    case 'LifeCycle Stage':
                        await navigate({ to: '../../../../Environmental/C2/Bar' });
                        break;
                    case 'LifeCycle Substage':
                        await navigate({ to: '../../../../Environmental/C3/Bar' });
                        break;
                    case 'Process':
                        await navigate({ to: '../../../../Environmental/C4/Bar' });
                        break;
                    default:
                        break;
                    }
                } else if (
                    baseOption === 'Inventory result: Elementary flow level'
                ) {
                    switch (resultsLayer) {
                    case 'Product System':
                        await navigate({ to: '../../../../Environmental/E1/Bar' });
                        break;
                    case 'LifeCycle Stage':
                        await navigate({ to: '../../../../Environmental/E2/Bar' });
                        break;
                    case 'LifeCycle Substage':
                        await navigate({ to: '../../../../Environmental/E3/Bar' });
                        break;
                    case 'Process':
                        await navigate({ to: '../../../../Environmental/E4/Bar' });
                        break;
                    default:
                        break;
                    }
                }
            } else {
                if (
                    baseOption === 'Total weighted result: Single index'
                ) {
                    switch (resultsLayer) {
                    case 'Product System':
                        await navigate({ to: '../../../Environmental/A1/Bar' });
                        break;
                    case 'LifeCycle Stage':
                        await navigate({ to: '../../../Environmental/A2/Bar' });
                        break;
                    case 'LifeCycle Substage':
                        await navigate({ to: '../../../Environmental/A3/Bar' });
                        break;
                    case 'Process':
                        await navigate({ to: '../../../Environmental/A4/Bar' });
                        break;
                    default:
                        break;
                    }
                }
                else if (
                    baseOption === 'Total weighted result: Damage type level'


                ) {
                    switch (resultsLayer) {
                    case 'Product System':
                        await navigate({ to: '../../../Environmental/B1/Bar' });
                        break;
                    case 'LifeCycle Stage':
                        await navigate({ to: '../../../Environmental/B2/Bar' });
                        break;
                    case 'LifeCycle Substage':
                        await navigate({ to: '../../../Environmental/B3/Bar' });
                        break;
                    case 'Process':
                        await navigate({ to: '../../../Environmental/B4/Bar' });
                        break;
                    default:
                        break;
                    }
                }
                else if (
                    baseOption === 'Midpoint result: Impact category level'
                ) {
                    switch (resultsLayer) {
                    case 'Product System':
                        await navigate({ to: '../../../Environmental/D1/Bar' });
                        break;
                    case 'LifeCycle Stage':
                        await navigate({ to: '../../../Environmental/D2/Bar' });
                        break;
                    case 'LifeCycle Substage':
                        await navigate({ to: '../../../Environmental/D3/Bar' });
                        break;
                    case 'Process':
                        await navigate({ to: '../../../Environmental/D4/Bar' });
                        break;
                    default:
                        break;
                    }
                } else if (
                    baseOption === 'Normalized results: impact category level'
                ) {
                    switch (resultsLayer) {
                    case 'Product System':
                        await navigate({ to: '../../../Environmental/C1/Bar' });
                        break;
                    case 'LifeCycle Stage':
                        await navigate({ to: '../../../Environmental/C2/Bar' });
                        break;
                    case 'LifeCycle Substage':
                        await navigate({ to: '../../../Environmental/C3/Bar' });
                        break;
                    case 'Process':
                        await navigate({ to: '../../../Environmental/C4/Bar' });
                        break;
                    default:
                        break;
                    }
                } else if (
                    baseOption === 'Inventory result: Elementary flow level'
                ) {
                    switch (resultsLayer) {
                    case 'Product System':
                        await navigate({ to: '../../../Environmental/E1/Bar' });
                        break;
                    case 'LifeCycle Stage':
                        await navigate({ to: '../../../Environmental/E2/Bar' });
                        break;
                    case 'LifeCycle Substage':
                        await navigate({ to: '../../../Environmental/E3/Bar' });
                        break;
                    case 'Process':
                        await navigate({ to: '../../../Environmental/E4/Bar' });
                        break;
                    default:
                        break;
                    }
                }
            }
        }
    };

    const baseOptionChangeHandler = (value: string) => {
        setSelectedBaseOption(value);
        navigationHandler(value, selectedResultsLayer);
    };
    const resultsLayerChangeHandler = (value: string) => {
        setSelectedResultsLayer(value);
        navigationHandler(selectedBaseOption, value);
    };


    return (
        <ThemeProvider theme={DropdownTheme}>
            <FormWrapper size='small'>
                <>
                    {baseOptions.length > 0 && (
                        <Dropdown
                            options={baseOptions}
                            valueSetter={baseOptionChangeHandler}
                            value={selectedBaseOption}
                            label='Base Layer'
                            noDefaults
                        />
                    )}
                    {resultsOptions.length > 0 && (
                        <Dropdown
                            options={resultsOptions}
                            valueSetter={resultsLayerChangeHandler}
                            value={selectedResultsLayer}
                            label='Results Layer'
                            noDefaults

                        />
                    )}
                </>
            </FormWrapper>
        </ThemeProvider>
    );
}

export default EnvChartNavigation;
