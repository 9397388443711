import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    Box, Divider, FormControl, Paper, ThemeProvider,
} from '@mui/material';
import useStyles from './C4SPASDGTable.styles';
import { EnvironmentalResult, Product, SPAScore } from '../../../../../../interface/Product';
// import Loader from '../../components/loader';
import { Accumulator } from '../../../../../../interface/Accumulator';
import ImpactCategoryDropdown from '../../../../../dropdowns/ImpactCategoryDropdown';

import { initialOptions } from '../../../../options/InitialOptionValues';
import deepClone from '../../../../../../helper/deepClone';
import { ChartJSData, ChartJSOptions } from '../../../../../../interface/ChartData';
import { CreateChartTS } from '../../../../util/CreateChartTS';
import EnvChartNavigation from '../../../../../dropdowns/BaseOptions/EnvChartNavigation';
import ChartDownload from '../../../../options/components/ChartDownload';
import ProductComparison from '../../../../options/components/Comparison';
import GraphSetting from '../../../../options/components/GraphSettings';
import SDGLinkage from '../../../../options/components/SDGLinkage';
import ResultsTheme from '../../../../style/ResultsTheme';
import SDGChecklist from '../../../../components/SDGChecklist';
import { SDG, SDGCorrelation } from '../../../../../../interface/SDG';
import { fetchEnvSDGs, fetchUnitGroups } from '../../../../../../helper/fetchUtil';
import LCSDropdowns from '../../../../../dropdowns/LCSDropdowns';
import SDGTable from '../../../../sdgtable';
import { UnitGroup } from '../../../../../../interface/Unit';
import { sdgColor, palette1, palette2, palette3, palette4 } from '../../../../../../helper/colors';
import { useFormContext } from '../../../../../forms/FormContext';
import { WeighingSet, weighingMethods } from '../../../../../../helper/weighingMethod';
import { productPerformanceColor } from '../../../../../../helper/colors';
import { SPAResult } from '../../../../../../interface/Product';
import { applyValueType } from '../../../../options/util/applyOptions';
import { SDGResult } from '../../../../sdgtable';
import { fetchSocialSDGs } from '../../../../../../helper/fetchUtil';
import SPAChartNavigation from '../../../../../dropdowns/BaseOptions/SPAChartNavigation';
import GraphType from '../../../../options/components/GraphTypeOptions';
import { ChartColorIdentifiers } from '../../../../options/components/GraphSettingsOptions';


interface ChartData {
    productId?: string,
    product: Product,
}

interface SDGCategory {
    name: string,
    val: number,
    unit: string,
    correlation: string,
}

export 

function C4SPASDGTable(props: ChartData) {
    const { formState, setFormState } = useFormContext();
    const classes = useStyles();
    const { product } = props;
    const [chartJSData, setChartJSData] = useState<ChartJSData>({ datasets: [], labels: [] });
    const [chartOptions, setChartOptions] = useState<ChartJSOptions>(initialOptions);
    const [colorIdentifiers, setColorIdentifiers] = useState<ChartColorIdentifiers[]>([]);
    const [selectedValueType, setSelectedValueType] = useState<string>('');
    const [stakeholderOptions, setStakeholderOptions] = useState<string[]>([]);
    const [currentWeighingSet, setCurrentWeighingSet] = useState<WeighingSet>();
    const [selectedLCS, setSelectedLCS] = useState<string>('');
    const [selectedLCSS, setSelectedLCSS] = useState<string>('');
    const [selectedProcess, setSelectedProcess] = useState<string>('');
    const [results, setResults] = useState<SPAResult>();
    const [loadedSDGs, setLoadedSDGs] = useState<SDG[]>([]);
    const [selectedSDGs, setSelectedSDGs] = useState<string[]>([]);
    const [currentSDGs, setCurrentSDGs] = useState<SDGResult[]>([]);

    useMemo(() => {
        const calc = product.calculations.find(
            (item) => { return item.calculationType === 'SOCIAL-SPA' },
        );
        const calcResults = calc?.calculationResults as unknown as SPAResult;

        setResults((calcResults));
        const weighingMethod = weighingMethods.find((method) => method.name === product.socialData.weighingMethod.name);
        const weighingSet = weighingMethod?.sets.find((set) => set.name === product.socialData.weighingMethod.set);
        setCurrentWeighingSet(weighingSet);
        setStakeholderOptions(['All Stakeholders'].concat(calcResults.stakeholderResults.map((item) => { return item.name })));
        try {
            fetchSocialSDGs().then((sdgs) => setLoadedSDGs(sdgs)).catch();
        } catch (err) {
            console.log(err);
        }
    }, [product]);




    useEffect(() => {

        if (!results || !selectedLCS || !selectedLCSS)
            return;

        const chartData: ChartJSData = { datasets: [], labels: [] };
        const options: ChartJSOptions = deepClone<ChartJSOptions>(initialOptions);
        const chartColorIdentifiers : ChartColorIdentifiers[] = [];

        if (options.scales && options.scales.y && options.scales.y.title) {
            options.scales.y.title.text = "Medium Risk Hours";
        }

        // const selectedPalette = findColorPaletteByName(formState.colorPalette);
        console.log(results);
        const spaLifeCycle = results.lifeCycleResults.find((lcs) => lcs.lcsName === `${selectedLCS}:${selectedLCSS}`);

        let sdgs : SDGResult[] = [];
        if (spaLifeCycle) {
            const process = spaLifeCycle.processResults.find(
                (process) => process.processName === selectedProcess
            );
            if (process && selectedSDGs.length > 0) {
                sdgs = selectedSDGs.map((sdg) => {
                    return {
                        name:sdg,
                        categories: process.PSILCAResults.reduce(
                            (array: { name: string, val: number, correlation: SDGCorrelation, unit:string,}[], result) => {
                                const category = loadedSDGs
                                    .find((item) => item.name === sdg)
                                    ?.categories.find(
                                        (item) =>
                                            item.categoryName.toLowerCase() ===
                                            result.category.toLowerCase()
                                    );

                                if (category) {
                                    array.push({
                                        name: result.category,
                                        val: result.score,
                                        unit:"NoUnit",

                                        correlation: category.correlation,
                                    });
                                }

                                return array;
                            },
                            []
                        ).sort((a, b) => {
                            let val = a.name
                                .split(":")[0]
                                .localeCompare(b.name.split(":")[0]);
                            if (val === 0) {
                                val = a.name
                                    .split(":")[1]
                                    .localeCompare(b.name.split(":")[1]);
                            }

                            return val;
                        }),
                    };
                });
                setCurrentSDGs(sdgs);
            }
        } else {
            if (selectedSDGs.length > 0) {
                const spaResults = results.stakeholderResults.reduce(
                    (items: SPAScore[], item) => {
                        return items.concat(item.SPAResults);
                    },
                    []
                );


                sdgs = selectedSDGs.map((sdg) => {
                    return {
                        name:sdg,
                        categories: spaResults
                            .reduce((array: { name: string, val: number, correlation: SDGCorrelation, unit:string }[], result) => {
                                const category = loadedSDGs
                                    .find((item) => item.name === sdg)
                                    ?.categories.find(
                                        (item) =>
                                            item.categoryName.toLowerCase() ===
                                            result.category.toLowerCase()
                                    );

                                if (category) {
                                    if (result.score) {
                                        array.push({
                                            name: result.category,
                                            val: result.score,
                                            correlation: category.correlation,
                                            unit:"",
                                        });
                                    }
                                }

                                return array;
                            }, [])
                            .sort((a: { name: string; }, b: { name: string; }) => {
                                let val = a.name
                                    .split(":")[0]
                                    .localeCompare(b.name.split(":")[0]);
                                if (val === 0) {
                                    val = a.name
                                        .split(":")[1]
                                        .localeCompare(b.name.split(":")[1]);
                                }

                                return val;
                            }),
                    };
                });
                setCurrentSDGs(sdgs);
            }
        }


    
        setChartJSData(chartData);
        setChartOptions(options);
        setColorIdentifiers(chartColorIdentifiers);    
    }, [selectedValueType,  selectedLCS, selectedLCSS, selectedProcess, loadedSDGs]);

    const [applyGraphSettings, setApplyGraphSettings] =
        useState<(options: ChartJSOptions, chartData: ChartJSData, colorIdentifiers?: ChartColorIdentifiers[]) => 
            { options: ChartJSOptions; chartData: ChartJSData; }>();
    const triggerChartUpdate = () => {
        let options = deepClone(chartOptions) || {};
        let chartData = deepClone(chartJSData);
        const applyOptionReturn = applyValueType(selectedValueType, chartData, options);
        options = applyOptionReturn.options;
        chartData = applyOptionReturn.chartData;
        if (applyGraphSettings) {
            ({ options, chartData } = applyGraphSettings(options, chartData, colorIdentifiers));
        }
        CreateChartTS(chartData, options);
    };

    useEffect(() => {
        if (chartJSData && chartOptions) {
            triggerChartUpdate();
        }
    }, [chartJSData, chartOptions]);


    return (
        <ThemeProvider theme={ResultsTheme}>
            <Box
                sx={{
                    margin: '0',
                    maxWidth: '100%',
                    maxHeight: '-webkit-fill-available',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1vw',
                }}
            >

                <Box component='div' className='results-wrapper'>
                    <Box component='div' className='results-options'>
                        <Paper square>
                            <Box component='div' className='results-options-top'>
                                <FormControl
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: '3rem',
                                        alignItems: 'stretch',

                                    }}
                                >
                                    <SPAChartNavigation
                                        product={product}
                                        defaultBaseIndex={3}
                                        defaultResultIndex={3}
                                        reverse
                                    />
                                    {/* <SDGLinkage /> */}
                                    <SDGChecklist
                                        setSelectedSDGs={setSelectedSDGs}
                                        disabled={false}
                                        setting='C4'
                                        dimension='SPA'
                                    />
                                    {/* <ProductComparison /> */}
                                </FormControl>
                            </Box>
                        </Paper>
                        <Divider />
                        <Paper square>
                            <Box component='div' className='results-options-bottom'>
                                <Box>
                                    <FormControl
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}
                                        className='bottom-options side-left'
                                    >
                                        <GraphType
                                            dimension='SPA'
                                            chart='C4'
                                            type='sdg'
                                            sdgOn
                                        />
                                        <LCSDropdowns
                                            product={product}
                                            selectedProcess={selectedProcess}
                                            setSelectedLCS={setSelectedLCS}
                                            selectedLCS={selectedLCS}
                                            setSelectedLCSS={setSelectedLCSS}
                                            selectedLCSS={selectedLCSS}
                                            setSelectedProcess={setSelectedProcess}
                                        />
                                        {/* <ImpactCategoryDropdown
                                            product={product}
                                            selectedCategories={selectedCategories}
                                            setSelectedCategories={setSelectedCategories}
                                        /> */}
                                    </FormControl>
                                </Box>
                                <Box>
                                    <FormControl
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            '@media (max-width: 1680px)': {
                                                gap: '1vw',
                                            },
                                        }}
                                        className='bottom-options side-right'
                                    >
                                        <ChartDownload sdgTableData = {currentSDGs} chartType = "SDG Table" 
                                            chartName="Subcategory level result - Process (SDG/SPA) - SDG Table" 
                                        />
                                        <GraphSetting
                                            chartOptions={chartOptions}
                                            setApplyGraphSettings={setApplyGraphSettings}
                                            triggerChartUpdate={triggerChartUpdate}
                                            colorIdentifiers={colorIdentifiers}
                                            chartData = {chartJSData}
                                            chartColorType = 'sdg'
                                            graphType='sdgtable'
                                            // noColors
                                        />
                                    </FormControl>
                                </Box>
                            </Box>
                        </Paper>
                    </Box>
                </Box>
                <Box className={classes.chartSec}>
                    <Paper
                        square
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: '1vw',
                            padding: '2rem',
                        }}
                    >
                        
                        <SDGTable 
                            currentSDGs={currentSDGs} 
                            colorPalette={sdgColor}
                        />
                        {/* <Box className={classes.chartSec}>
                            <canvas id='graph1Canvas' className='graph-canvas' />
                        </Box> */}
                    </Paper>
                </Box>
            </Box>
        </ThemeProvider>
    );
}
export default C4SPASDGTable;
