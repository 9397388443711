import { useQuery } from '@tanstack/react-query';
import { URL_CONF } from '../helper/constants';
import gqlConfig from '../helper/GqlConfig';

interface QueryReturnType {
    data:
    | {
        login?: {
            access_token: string;
            email: string;
        };
        chart?:{
            sales: number;
            tax: number;
            month: string;
        }[];
    }

    | undefined
    isFetching: boolean
    isError: boolean
    error: QueryErrorResponse | null
    refetch: () => void
    // refetch: ({ orgId }?: { [key: string]: string | number }) => void
}

export type QueryReturnData = QueryReturnType['data'];

interface QueryErrorResponse {
    response: {
        errors: {
            message: string
            status: number
        }[]
        status: number
    }
}

type Config =
    | {
        enabled?: boolean
        refetchOnWindowFocus?: boolean
        onSuccess?: (data: unknown) => void
        onError?: (error: QueryErrorResponse) => boolean
    }
    | undefined;

// const {
//     data, isFetching, refetch, error,
// } = useGQLQuery(
//     'login',
//     authQuery.LOGIN(
//         state.email.toLowerCase(),
//         state.password,
//     ),
//     {},
//     {
//         enabled: false,
//     },
//     URL_CONF.AUTHENTICATION,
// );

export const useGQLQuery = (
    key: string[] | string, // Parameter declaration
    query: string,
    variables = {},
    config?: Config,
    path?: string,
): QueryReturnType => { // QueryReturnType is return type of this func
    const apiPath = path || URL_CONF.DEFAULT;
    const graphQLClient = gqlConfig(apiPath);

    const fetchData = async () => {
        const data = await graphQLClient.request(query, variables);
        return data;
    };

    const defaultQueryConfig = { refetchOnWindowFocus: false };
    const queryConfig = {
        ...config,
        retry: false,
        refetchOnWindowFocus: false,
        enabled: config && config.enabled !== undefined ? config.enabled : true,
    };

    return useQuery({
        queryKey: key as unknown as unknown[],
        queryFn: fetchData,
        ...defaultQueryConfig,
        ...queryConfig,
    });
};
