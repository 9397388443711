// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select-process-popup__content
  .css-nn0v2v-MuiTabPanel-root
  .MuiFormGroup-root.css-dmmspl-MuiFormGroup-root:last-of-type
  h2 {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/createproduct/EnvironmentalInputs/components/FlowDetails/ProcessProductFlowDetails.css"],"names":[],"mappings":"AAAA;;;;EAIE,aAAa;AACf","sourcesContent":[".select-process-popup__content\r\n  .css-nn0v2v-MuiTabPanel-root\r\n  .MuiFormGroup-root.css-dmmspl-MuiFormGroup-root:last-of-type\r\n  h2 {\r\n  display: none;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
