import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => {return {
    ProductListItem: {
        padding: '0.4vw !important',
        height: '4vh !important',
        justifyContent: 'space-between !important',
    },

}});

export default useStyles;
