import React, { useState } from "react";
import "../Homepage.css";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import videodemothumb from "../../images/thumbnails/video-demonstation-thumb.png";
import VideoDemonstration from "../../components/VideoDemonstration";

const AboutWhoAreWe: React.FC = () => {
    const [showVideoDemo, setShowVideoDemo] = useState(false);
    const showVideoDemoHandler = async () => {
        setShowVideoDemo(!showVideoDemo);
    };

    return (
        <div className="website-only">
            <Header />

            <div className="header-container">
                <div className="header-transparent-layer"> </div>
                <img
                    src={require("../../images/headers/pexels-pixabay-532192.jpg")}
                    alt="Snowy mountains behind a lake and trees"
                />
                <h1>Who are we?</h1>
            </div>
            <div className="box-container">
                <h2>What is TOSSA about?</h2>
                <p className="tossa-fancy">
                    <b>TOSSA:</b> <b>T</b>owards <b>O</b>ptimal <b>S</b>ustainability{" "}
                    <b>S</b>pace <b>A</b>ssessment decision-support tool
                </p>
                <p>
          As a group of researchers working on sustainability assessment methods
          and their practical application, our idea of TOSSA started from our
          recognition that research does not make a real world impact if not
          translated into practical tools easy to use by practitioners. The goal
          of TOSSA is to empower companies to make the best sustainable and ESG
          decisions through an easy-to-use and intuitive computer-based ESG and
          sustainability tool. The tool evaluates the whole sustainability
          performance of products/services/corporate operations based on
          standardized life cycle assessment methodology. Our unique approach
          integrates life cycle perspective in its triple bottom line dimensions
          and ESG (environmental, social and governance) with connection to
          Sustainable development goals (SDGs).
                </p>
            </div>
            <div className="box-container">
                <div className="about-cycle-and-esg">
                    <div className="life-cycle">
                        <h2>The Life Cycle</h2>
                        <img
                            src={require("../../images/figures/Life-Cycle-SDG-ESG.png")}
                            alt="The Life Cycle"
                            width="50%"
                        />
                    </div>

                    {/*<div className="image-container-two">
<h2>The ESG</h2>
<div className="esg-wrap">
<img src={require('../../images/figures/ESG-03.png')} alt="Environmental Icon" width="500px"/>
<img src={require('../../images/figures/ESG-02.png')} alt="Social Icon" width="500px"/>
<img src={require('../../images/figures/ESG-01.png')} alt="Goverance Icon" width="500px"/>
</div>
</div>*/}
                </div>

            </div>
            <div className="box-container">
                <h2>Video Demonstration</h2>
                <p>Here is how the software works:</p>
                <div className="play-demo">
                    <div className="video-demo-box">
                        <div className="video-transparent-layer"> </div>

                        <img
                            src={videodemothumb}
                            alt="Thumbnail for the video demo"
                            width={500}
                        />
                        <button type="button" onClick={showVideoDemoHandler}>
                  Play Video
                        </button>
                    </div>

                    {showVideoDemo && (


                        <div
                            className="video-demonstration"
                            style={{ display: showVideoDemo ? "" : "none" }}
                        >
                            <VideoDemonstration
                                showVideoDemo={showVideoDemo}
                                setShowVideoDemo={setShowVideoDemo}
                            />
                        </div>
                    )}
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default AboutWhoAreWe;
