import { ThemeProvider, createTheme } from '@mui/material/styles';

const DropdownTheme = createTheme({
    components: {
        MuiSelect: {
            styleOverrides: {
                root: {
                    minHeight: '28px',
                    padding: '0',
                    // border: '1px solid #707070',
                    borderRadius: '0px',
                    width: '11rem',
                },
                icon: {
                    fontSize: '0.7vw',
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    minHeight: '28px',
                    padding: '0',
                    // border: '1px solid #707070',
                    width: 'auto',
                    borderRadius: '0px',
                    fontSize: '0.75vw',
                    backgroundColor: 'white',
                    textAlign: 'left',
                    '&.MuiInputBase-input': {
                        // border: '1px solid #707070',
                        borderRadius: '0px',
                        width: '11rem',
                        '@media (max-width: 1680px)': {
                            padding: '0',
                            width: '10rem',
                        },
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    minHeight: '28px',
                    padding: '0',
                    // border: '1px solid #707070',
                    borderRadius: '0px',
                    fontSize: '0.7vw',
                    width: '-webkit-fill-available',
                    '@media (max-width: 1680px)': {
                        width: '80% !important',
                        padding: '0',
                        minHeight: '1.2vh',
                    },
                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    display: 'flex',
                    flexDirection: 'row',
                },
            },
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    borderStyle: 'solid',
                    borderColor: '#004021',
                    borderBottomWidth: 'medium',
                    width: '75%',
                    marginTop: 0,
                    marginLeft: '-1rem !important',
                },
            },
        },
        MuiStack: {
            styleOverrides: {
                root: {
                    marginTop: '2vh',
                    alignItems: 'flex-start',
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    backgroundColor: 'white',
                    '@media (max-width: 1680px)': {
                        fontSize: 12,
                        // transform: 'translate(14px, 5px) scale(1)',
                    },
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    '@media (max-width: 1680px)': {
                        fontSize: 12,
                    },
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                root: {
                    fontSize: '0.7vw',
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    fontSize: '1.2vw',
                    '& .MuiSvgIcon-root': {
                        width: '1em',
                        height: '1em',
                        fontSize: '1.2vw',
                    },
                },     
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontSize: '1.2vw',
                },
            },
        },
    },
    typography: {
        // h2: {
        //     fontSize: '1.4vw',
        //     fontWeight: 'bold',
        // },
        // h3: {
        //     fontSize: '1.2vw',
        //     fontWeight: 'bold',
        // },
        // h4: {
        //     fontSize: '1vw',
        //     fontWeight: 'normal',
        // },
        // body1: {
        //     fontFamily: 'Roboto, sans-serif',
        //     fontSize: '0.7vw',
        // },
        h2: {
            fontSize: '1vw',
            fontWeight: 'normal',
            textDecoration: 'none',
            fontFamily: 'Montserrat, sans-serif',
        },
        h3: {
            fontSize: '0.95vw',
            fontWeight: 'normal',
            fontFamily: 'Montserrat, sans-serif',

        },
        h4: {
            fontSize: '0.9vw',
            fontWeight: 'normal',
            fontFamily: 'Montserrat, sans-serif',
        },
        body1: {
            fontSize: '0.7vw',
            color: '#707070',
        },
    },
});

export default DropdownTheme;
