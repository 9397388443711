/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Box, createTheme, ThemeProvider } from '@mui/material';
import { hover } from '@testing-library/user-event/dist/hover';
import { styled } from '@mui/material/styles';
import { green } from '@mui/material/colors';
import TableRow from '@mui/material/TableRow';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => {return {
    components: {
        MuiSelect: {
            styleOverrides: {
                select: {
                    border: 'none',
                },
            },
        },
    },
    typography: {
        fontFamily: 'Roboto, sans-serif',
        h2: {
            fontSize: 32,
            fontWeight: 'bold',
        },
        h3: {
            fontSize: 20,
            fontWeight: 'bold',
            fontFamily: 'Montserrat, sans-serif',
        },
        body1: {
            fontFamily: 'Roboto, sans-serif',
            fontSize: 14,
        },
    },
}});

export default useStyles;
