import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => {return {
    DropdownSwitchWrapper: {
        border: '1px solid #bdbdbd',
        height: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '11vw',
    },
}});

export default useStyles;
