import React from "react";
import "../Homepage.css";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const ServicesTraining: React.FC = () => {
    return (
        <div className="website-only">
            <Header />

            <div className="header-container">
                <div className="header-transparent-layer"> </div>
                <img
                    src={require("../../images/headers/pexels-francesco-ungaro-449011.jpg")}
                    alt="Stone standing under the blue sky"
                />

                <h1>Services</h1>
            </div>
            <div className="box-container">
                <h2>Training</h2>
                <p className="p-hide">
          TOSSA Sustainability Services offers training sessions and courses on
          life cycle sustainability assessment, social life cycle assessment and
          life cycle assessment. Upcoming courses are announced below. On demand
          custom training sessions and courses are also available. If you are
          interested, please contact us <i>admin@tossa-sustainability.com</i>
                </p>
            </div>
            <div className="box-container">
                <h2>Upcoming course</h2>
                <h3>Introduction to Life Cycle Sustainability Assessment</h3>
                <p>
          Join us to learn about life cycle thinking and triple-bottom line
          sustainability assessment in two days online course.
                </p>
                <p>
                    <b>Dates:</b> <i>TBD</i>
                </p>
                <p>
                    <b>Description: </b>
          Looking to learn about life cycle thinking and triple-bottom line
          sustainability assessment? Interested in learning how to account for
          the environmental, economic and social impacts along the life cycle of
          your decisions, technologies, products or companies? Sustainability
          impacts are becoming more of a concern in any decision-making context
          whether you are a business manager, policy-maker, technology developer
          or researcher. But how to account for all the three dimensions of
          sustainability in your decisions while avoiding problem shifting and
          sub-optimization? Life cycle sustainability assessment is the best
          option … This interactive course gives you an introduction to the life
          cycle sustainability assessment method in its three components of life
          cycle assessment, life cycle costing and social life cycle assessment
          with theories, examples and case studies as well as demonstrations
          using an actual LCSA software tool.
                </p>
            </div>

            <Footer />
        </div>
    );
};

export default ServicesTraining;
