import React, {
    useMemo,
    useState,
    Dispatch,
    SetStateAction,
    SyntheticEvent,
    FocusEventHandler,
    useEffect,
} from "react";
import { styled } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import DropdownTheme from "../../style/components/DropdownTheme";
import { ThemeProvider } from "@mui/styles";
import { arraysHaveSameValues } from "../../../helper/utils";
import "../../style/components/Dropdown.css";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";

interface DropDownData {
  label?: string;
  options: string[]; // NOTE: This is considered a new object each re-render if you put the options directly into the component props (Ex. options={['example1', 'example2']}).
  // Put the values inside a useState instead to prevent re-render jank
  values?: string[];
  value: string | undefined;
  valueSetter?: Dispatch<SetStateAction<string>> | ((value: string) => void);
  defaultValue?: string;
  id?: string;
  onBlur?: FocusEventHandler;
  className?: string;
  onChange?: (event: SelectChangeEvent) => void;
  noDefaults?: boolean;
  inputOnly?: boolean;
  disabledValues?: string[];
  disableTemp?: boolean;
}

function Dropdown(props: DropDownData) {
    const {
        label,
        options,
        value,
        values,
        valueSetter,
        defaultValue,
        id,
        onBlur,
        className,
        onChange,
        noDefaults,
        inputOnly,
        disabledValues,
        disableTemp,
    } = props;
    const validateValue = (val: string): boolean => {
        let isValid = false;
        isValid = !!(
            options &&
      options.find((item) => {
          return item === val;
      })
        );
        if (!isValid) {
            isValid = !!(
                options &&
        options.find((item) => {
            return item === val;
        })
            );
        }
        return isValid;
    };

    const [prevOptions, setPrevOptions] = useState<string[]>([]);
    const [selectedOption, setSelectedOption] = useState("");

    useEffect(() => {
        if (!selectedOption && value) {
            setSelectedOption(value);
        }
    }, [value]);

    useMemo(() => {
        if (!noDefaults) {
            // No defaults disables this useMeme. This usememo sets the default value when the value object does not have an initial value.
            const optionsChanged = !arraysHaveSameValues<string>(
                prevOptions,
                options
            );

            if (inputOnly) {
                if (!value && valueSetter) {
                    valueSetter("inputdefault");
                    setSelectedOption("inputDefault");
                }
            } else {
                if (!value || optionsChanged) {
                    // only run if no value or options are changed
                    const val = defaultValue || values?.[0] || options[0];

                    if (val && val !== value && validateValue(val)) {

                        // only run if a default value (val) is defined and the value is valid (it exists in options)
                        setPrevOptions(options);
                        // IMPORTANT!! val !== value 'fixes' TypeError: Cannot convert undefined or null to object
                        // This error occurs in tanstack/react-form's form.FieldApi's onChange when using a dropdown whose options are immediately available on render (I have no idea why this is happening)
                        if (onChange) {

                            const event = {
                                target: { value: val },
                            };
                            onChange(event as SelectChangeEvent);
                            setSelectedOption(val);
                        } else if (valueSetter) {
                            valueSetter(val);
                            setSelectedOption(val);
                        }
                    }
                }
            }
        } 
    }, [defaultValue, options, values, value]);

    const selectHandler = (event: SelectChangeEvent) => {
        if (valueSetter && validateValue(event.target.value)) {
            valueSetter(event.target.value);
        }
    };

    return (
        <ThemeProvider theme={DropdownTheme}>
            <FormControl size="small">
                {label && <InputLabel shrink>{label}</InputLabel>}
                {(values ? values.length > 0 && options.length > 0 : options) && (
                    <Select
                        id={id}
                        className={className || ""}
                        onChange={(e: SelectChangeEvent) => {
                            if (onChange) {
                                onChange(e);
                            } else {
                                selectHandler(e);
                            }
                        }}
                        value={value || selectedOption}
                        onBlur={onBlur}
                        sx={{ borderRadius: 0, width: "11rem" }}
                        IconComponent={ArrowDropDown}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                            },
                            PopoverClasses: {
                                root: "MuiPopover-root",
                            },
                            disablePortal: true,
                        }}
                        displayEmpty={inputOnly}
                        disabled={inputOnly && disableTemp}
                        {...(inputOnly
                            ? {
                                renderValue: (selected) => {
                                    if (
                                        !selected ||
                      (selected === "inputdefault" && inputOnly)
                                    ) {
                                        return <em>Please Select...</em>;
                                    }
                                    return selected;
                                },
                            }
                            : {})}
                    >
                        {/* {inputOnly && (
                            <MenuItem value="inputdefault" disabled>Please Select...</MenuItem>
                        )} */}
                        {options.map((option, index) => {
                            return (
                                <MenuItem
                                    key={option}
                                    value={values && values.length > 0 ? values[index] : option}
                                    disabled={
                                        !!disabledValues?.find(
                                            (item) =>
                                                item ===
                        (values && values.length > 0 ? values[index] : option)
                                        )
                                    }
                                    selected = {option === selectedOption}
                                    
                                >
                                    {option}
                                </MenuItem>
                            );
                        })}
                    </Select>
                )}
            </FormControl>
        </ThemeProvider>
    );
}

export default Dropdown;
