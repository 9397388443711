// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lcs-dropdown-title-wrap {
    display: flex;
  }
  .lcs-dropdown-title-wrap .css-ksvk88-MuiButtonBase-root-MuiCheckbox-root {
    padding: 0;
  }
  .lcs-dropdown-title-wrap svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
    font-size: 1.4vw;
}`, "",{"version":3,"sources":["webpack://./src/components/createproduct/ProductDetails/components/LifeCycleStagesDropdowns.css"],"names":[],"mappings":"AAAA;IACI,aAAa;EACf;EACA;IACE,UAAU;EACZ;EACA;IACE,gBAAgB;AACpB","sourcesContent":[".lcs-dropdown-title-wrap {\r\n    display: flex;\r\n  }\r\n  .lcs-dropdown-title-wrap .css-ksvk88-MuiButtonBase-root-MuiCheckbox-root {\r\n    padding: 0;\r\n  }\r\n  .lcs-dropdown-title-wrap svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {\r\n    font-size: 1.4vw;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
