import React, { useEffect, useMemo, useState } from "react";
import {
    Container,
    Box,
    Paper,
    Typography,
    Stack,
    Button,
    FormControl,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import GeneralTheme from "../../style/GeneralTheme";
import { ThemeProvider } from "@emotion/react";
import ProductNameDescForm from "./components/ProductNameDescForm";
import DimensionCheckboxes from "./components/DimensionCheckboxes";
import LifeCycleStagesDropdowns from "./components/LifeCycleStagesDropdowns";
import { useNavigate, useRouterState } from "@tanstack/react-router";
import { fetchUnitGroups, sendRequest } from "../../../helper/fetchUtil";
import { UnitGroup } from "../../../interface/Unit";
import { useForm, FormApi } from "@tanstack/react-form";
import { FormState, useFormContext } from "../../forms/FormContext";
import {
    EnvFlowInputLCS,
    EnvProcessInputLCS,
    LifeCycleStageForm,
    ProductDetailsForm,
    SocialInputsLCS,
    defaultLCSFormValues,
} from "../../../interface/Form";
import Dropdown from "../../dropdowns/Dropdown";
import CreateProductHeader from "../../results/components/createproductheader";
import { CreateProductNavButtons } from "../components/CreateProductNavButtons";
import { Product, SPAResult } from "../../../interface/Product";
import { useLocation } from "react-router-dom";
import { StakeholderIndicators } from "../../../interface/Stakeholder";

interface ProjectDetailsProps {
  editProductId?: string;
}
interface ProductResponse {
  product: Product;
}

function ProjectDetailsInputs({ editProductId }: ProjectDetailsProps) {
    const navigate = useNavigate();
    const { formState, setFormState } = useFormContext();

    const getSelectedLCS = (value: ProductDetailsForm): string[] => {
        const lcsList: string[] = [];
        (Object.keys(value) as (keyof ProductDetailsForm)[]).forEach((key) => {
            if (key.includes(":") && value[key] === true) {
                lcsList.push(key as string);
            }
        });
        console.log(lcsList);
        return lcsList;
    };

    const [productToEdit, setProductToEdit] = useState<Product>();
    const form: FormApi<ProductDetailsForm> = useForm({
        // defaultValues: {
        //     //     productName: "",
        //     //     productDesc: "",
        //     //     analysisUnit: "",
        //     //     analysisUnitGroup: "",
        //     //     analysisUnitQTY: 1,
        //     //     envDim: false,
        //     //     socialDim: false,
        //     //     ecoDim: false,
        //     //     ...defaultLCSFormValues,
        //     //     currentLCS: "",
        //     //     currentLCSS: "",
        //     productName: formState.productName  || "",
        //     productDesc: formState.productDesc || "",
        //     analysisUnit:
        // formState.analysisUnit || productToEdit?.unitOfAnalysis.unit || "",
        //     analysisUnitGroup:
        // formState.analysisUnitGroup ||
        // productToEdit?.unitOfAnalysis.unitGroup ||
        // "",
        //     analysisUnitQTY:
        // formState.analysisUnitQTY || productToEdit?.unitOfAnalysis.unitQTY || 1,
        //     envDim: false,
        //     socialDim: false,
        //     ecoDim: false,
        //     ...defaultLCSFormValues,
        //     currentLCS:
        // formState.selectedLCS && formState.selectedLCS[0]?.split(":")[0],
        //     currentLCSS:
        // formState.selectedLCS && formState.selectedLCS[0]?.split(":")[1],
        // } as ProductDetailsForm,
        onSubmit: ({ value }) => {
            setFormState({
                ...formState,
                selectedLCS: getSelectedLCS(value),
                envDim: value.envDim,
                socialDim: value.socialDim,
                ecoDim: value.ecoDim,
                productName: value.productName,
                productDesc: value.productDesc,
                analysisUnit: value.analysisUnit,
                analysisUnitGroup: value.analysisUnitGroup,
                analysisUnitQTY: value.analysisUnitQTY  || 1,
                editProductId: productToEdit?._id,
                modifiedEdit: true,
            });
        },
    });

    useMemo(() => {
        console.log(formState);
    }, [formState]);
    const fetchProduct = async (productId: string): Promise<ProductResponse> => {
        const response: ProductResponse = await sendRequest<ProductResponse>(
            `${process.env.REACT_APP_BACKEND_URL}/product/${productId}`,
            "GET",
            undefined,
            { "Content-Type": "application/json" }
        );
        return response;
    };

    const [isFormValid, setIsFormValid] = useState(true);

    const validateForm = () => {
        const values = form.state.values;
        let isValid = true;
        if (getSelectedLCS(form.state.values).length === 0) {
            isValid = false;
        }

        if (!values.envDim && !values.ecoDim && !values.socialDim) {
            isValid = false;
        }

        if (!values.productName) {
            isValid = false;
        }

        setIsFormValid(isValid);
    };

    const populateFieldsWithProductData = (product: Product) => {
        const envInputEmissions: EnvFlowInputLCS[] = [];
        const envInputProcesses: EnvProcessInputLCS[] = [];
        const envOutputEmissions: EnvFlowInputLCS[] = [];
        const envOutputWaste: EnvFlowInputLCS[] = [];
        const socialInputProcesses: SocialInputsLCS[] = [];

        product.lifeCycleStages.forEach((lcs) => {
            if(product.dimensions.environmental || product.dimensions.economic){
                envInputProcesses.push({
                    lcs: lcs.stage,
                    values: lcs.value.environmental.inputs.processes,
                });
            }
            if (product.dimensions.environmental) {
          
                envInputEmissions.push({
                    lcs: lcs.stage,
                    values: lcs.value.environmental.inputs.emissionFlows,
                });
                envOutputEmissions.push({
                    lcs: lcs.stage,
                    values: lcs.value.environmental.outputs.emissionFlows,
                });
                envOutputWaste.push({
                    lcs: lcs.stage,
                    values: lcs.value.environmental.outputs.wasteFlows,
                });
            }
            if (product.dimensions.social) {
                socialInputProcesses.push({
                    lcs: lcs.stage,
                    values: lcs.value.social.inputs,
                });
            }
            // const key = lcs.stage as keyof LifeCycleStageForm;

            // form.state.values[key] = true;
        });

        const stages = product.lifeCycleStages.map((lcs) => lcs.stage);
        let productData: FormState = {
            analysisUnit: product.unitOfAnalysis.unit,
            analysisUnitGroup: product.unitOfAnalysis.unitGroup,
            analysisUnitQTY: product.unitOfAnalysis.unitQTY || 1,
            envDim: product.dimensions.environmental,
            socialDim: product.dimensions.social,
            ecoDim: product.dimensions.economic,
            envInputEmissions,
            envInputProcesses,
            envOutputEmissions,
            envOutputWaste,
            envAssessmentMethod: product.environmentalData?.assessmentMethod.id,
            envAssessmentMethodName: product.environmentalData?.assessmentMethod.name,
            envNWSet: product.environmentalData?.nwSet,
            productDesc: product.productDesc,
            productName: product.productName,
            selectedLCS: stages,
            currentLCS: stages[0]?.split(":")[0],
            currentLCSS: stages[0]?.split(":")[1],
            socialAssessmentMethod: product.socialData?.data?.type,
            socialInputProcesses,
            socialSPAWeighingMethod: product.socialData?.weighingMethod?.name,
            SPAIndicators: product.socialData?.data?.indicators,
            socialSPAWeighingSet: product.socialData?.weighingMethod?.set,
            productToEdit: product,
            modifiedEdit: true,
        };
        const SPACalcs = product.calculations.find(
            (calc) => calc.calculationType === "SOCIAL-SPA"
        );
        if (SPACalcs) {
            let selectedStakeholders: string[] = [];

            if (product.socialData?.data?.indicators) {
                const indicators = product.socialData?.data
                    ?.indicators as StakeholderIndicators;
                selectedStakeholders = Object.keys(indicators).reduce(
                    (list: string[], indicator) => {
                        if (
                            indicators[indicator] === "Yes" ||
              indicators[indicator] === "No"
                        ) {
                            if (!list.find((item) => item === indicator.split(":")[0])) {
                                list.push(indicator.split(":")[0]);
                            }
                        }

                        return list;
                    },
                    []
                );
            }
            const process = (SPACalcs.calculationResults as unknown as SPAResult)
                .SPAProcess;

            if (process) {
                productData = {
                    ...productData,
                    selectedSPAProcess: {
                        name: process.name,
                        refId: process.id,
                    },
                    socialSPALCS: process.lifeCycleStage,
                    selectedStakeholders: selectedStakeholders,
                };
            }
        }
        setFormState(productData);
   
        const lcsForm : LifeCycleStageForm = {...defaultLCSFormValues};
        product.lifeCycleStages.forEach((lcs) => {
            const key = lcs.stage as keyof LifeCycleStageForm;
            lcsForm[key] = true;
            (form.state.values[key] as boolean) = true;
        });
        const formData: ProductDetailsForm = {
            ...lcsForm,
            analysisUnit: product.unitOfAnalysis.unit,
            analysisUnitGroup: product.unitOfAnalysis.unitGroup,
            analysisUnitQTY: product.unitOfAnalysis.unitQTY,
            envDim: product.dimensions.environmental,
            socialDim: product.dimensions.social,
            ecoDim: product.dimensions.economic,
            productDesc: product.productDesc,
            productName: product.productName,
            currentLCS: stages[0]?.split(":")[0],
            currentLCSS: stages[0]?.split(":")[1],
        };



        // Object.keys(form.state.values).forEach((item) =>{
        //     const key = item as keyof ProductDetailsForm;
        //     form.setFieldValue(key, formData[key]);
        // })


    };

    useEffect(() => {
        if (editProductId) {
            fetchProduct(editProductId).then((response) => {
                setProductToEdit(response.product);
                validateForm();
            });
        }
        validateForm();
    }, [editProductId]);

    //Fill with edit data if: Loading details for the first time and switching products
    useEffect(() => {
        if (productToEdit && !formState.modifiedEdit) {
            populateFieldsWithProductData(productToEdit);
            console.log("POPULATING");
        }
        validateForm();
    }, [productToEdit]);

    return (
        <ThemeProvider theme={GeneralTheme}>
            {/* <button
                type="button"
                onClick={() => {
                    console.log(formState);
                    console.log(form.state.values);
                }}
                style={{
                    position: "absolute",
                    top: "4rem",
                    left: 0,
                }}
            >
                PRINT CONTEXT
            </button> */}

            <CreateProductHeader formState={formState} currentPage="details"
                form = {form}
            />
       
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    form
                        .handleSubmit()
                        .then(() => {})
                        .catch(() => {});
                }}
            >
                <Container
                    className="projectDetails-modal-content"
                    maxWidth={false}
                    sx={{
                        maxWidth: "100%",
                        // height: "83vh",
                        padding: "1rem",
                    }}
                >
                    <form.Subscribe
                        selector={(state) => {
                            return [state.canSubmit, state.isSubmitting];
                        }}
                        children={([canSubmit, isSubmitting]) => {
                            return (
                                <button
                                    type="submit"
                                    disabled={!canSubmit}
                                    style={{
                                        position: "absolute",
                                        top: "6rem",
                                        left: 0,
                                    }}
                                >
                                    {isSubmitting ? "..." : "Submit"}
                                </button>
                            );
                        }}
                    />

                    <FormControl
                        sx={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr",
                            gap: "1rem",
                            height: "auto",
                            textAlign: "left",
                            // "@media (max-width: 1680px)": {
                            //     gridTemplateColumns: "1fr",
                            //     gridTemplateRows: "1fr 1fr",
                            // },
                        }}
                    >
                        <Box
                            className="create-inputs-box project-details-box left"
                            sx={{
                                display: "grid",
                                gridTemplateRows: "auto auto",
                                gap: "1rem",
                                height: "-webkit-fill-available",
                                // "@media (max-width: 1680px)": {
                                //     gridTemplateColumns: "1fr 1fr",
                                //     gridTemplateRows: "1fr",
                                // },
                            }}
                        >
                            <Paper square className="project-details-box">
                                <ProductNameDescForm
                                    form={form}
                                    productToEdit={productToEdit}
                                    validateForm={validateForm}
                                />
                            </Paper>
                            <Paper
                                square
                                className="project-details-box"
                                sx={{
                                    height: "auto",
                                    "@media (max-width: 1680px)": {
                                        height: "-webkit-fill-available",
                                    },
                                }}
                            >
                                <DimensionCheckboxes
                                    form={form}
                                    productToEdit={productToEdit}
                                    validateForm={validateForm}
                                />
                            </Paper>
                        </Box>
                        <Box
                            className="create-inputs-box project-details-box right"
                            sx={{ height: "-webkit-fill-available", display: "flex" }}
                        >
                            <Paper
                                square
                                className="project-details-box"
                                sx={{
                                    height: "-webkit-fill-available",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                }}
                            >
                                <LifeCycleStagesDropdowns
                                    form={form}
                                    productToEdit={productToEdit}
                                    validateForm={validateForm}
                                />
                                <CreateProductNavButtons
                                    form={form}
                                    formState={formState}
                                    currentPage="details"
                                    editProductId={editProductId}
                                    formNotValid={!isFormValid}
                                />
                            </Paper>
                        </Box>
                    </FormControl>
                </Container>
            </form>
        </ThemeProvider>
    );
}

export default ProjectDetailsInputs;
