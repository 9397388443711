import { useState, useMemo, useEffect, useCallback } from 'react';
import { EconomicResult, Product } from '../../../../../interface/Product';
import { ThemeProvider } from '@emotion/react';
import FormControl from '@mui/material/FormControl';

import { Box, Paper, Divider } from '@mui/material';
import deepClone from '../../../../../helper/deepClone';
import { ChartDataSet, ChartJSData, ChartJSOptions } from '../../../../../interface/ChartData';
import LCSDropdowns from '../../../../dropdowns/LCSDropdowns';
import useStyles from '../../../charts.styles';
import { initialOptions } from '../../../options/InitialOptionValues';
import ChartDownload from '../../../options/components/ChartDownload';
import ProductComparison from '../../../options/components/Comparison';
import GraphSetting from '../../../options/components/GraphSettings';
import { applyValueType } from '../../../options/util/applyOptions';
import B4BarPSILCA from '../../../social/psilca/stakeholder/B4BarPSILCA';
import B4PiePSILCA from '../../../social/psilca/stakeholder/B4PiePSILCA';
import ResultsTheme from '../../../style/ResultsTheme';
import { CreateChartTS } from '../../../util/CreateChartTS';
import Dropdown from '../../../../dropdowns/Dropdown';
import EcoChartNavigation from '../../../../dropdowns/BaseOptions/EcoChartNavigation';
import { palette1, palette2, palette3, palette4, processColors, sublifeCycleColors } from '../../../../../helper/colors';
import GraphType from '../../../options/components/GraphTypeOptions';
import { useFormContext } from '../../../../forms/FormContext';



interface ChartData {
    product: Product;
}
function A3BarEco(props: ChartData) {
    const classes = useStyles();
    const { formState, setFormState } = useFormContext();
    const { product } = props;
    const [chartJSData, setChartJSData] = useState<ChartJSData>({ datasets: [], labels: [] });
    const [chartOptions, setChartOptions] = useState<ChartJSOptions>(initialOptions);
    const [colorIdentifiers, setColorIdentifiers] = useState<ChartColorIdentifiers[]>([]);
    const [results, setResults] = useState<EconomicResult[]>([]);
    const [selectedValueType, setSelectedValueType] = useState<string>('');

    useMemo(() => {
        const calc = product.calculations.find(
            (item) => {return item.calculationType === 'ECONOMIC'},
        );
        const calcResults = calc?.calculationResults as EconomicResult[];
        setResults((calcResults));
    }, [product]);







    
    useEffect(() => {
        const chartData: ChartJSData = { datasets: [], labels: [] };
        const options: ChartJSOptions = deepClone<ChartJSOptions>(initialOptions);
        const chartColorIdentifiers : ChartColorIdentifiers[] = [];

        if (options.scales && options.scales.y && options.scales.y.title) {
            options.scales.y.title.text = "Euros";
        }
        

        chartData.datasets = results.reduce((datasets: ChartDataSet[], lcs, index) => {return datasets.concat(
            lcs.subStages.map((lcss) => {
                chartColorIdentifiers.push({label:`${lcs.name}:${lcss.name}`});

                return {
                    label: `${lcs.name}:${lcss.name}`,
                    data: [
                        lcss.processes.reduce(
                            (processTotal: number, process) => {return processTotal + process.costResult},
                            0,
                        ),
                    ],
                    //backgroundColor: extendedPalette[index],
                    //   backgroundColor:
                    //     // sublifeCycleColors[
                    //     currentPaletteSubLifeCycle[
                    //       getGraphColorIndex({
                    //         label: `${lcs.name}:${lcss.name}`,

                //         // label: lcss.name,
                //         // identifier: loadedProduct.productName,
                //       })
                //     ],
                //   borderColor: "000000",
                }}),
        )}, []);

        // chartData.labels = ['Cost Per LifeCycle Substage'];
        chartData.labels = [''];
        setChartJSData(chartData);
        setChartOptions(options);
        setColorIdentifiers(chartColorIdentifiers);    
    }, [selectedValueType]);

    const [applyGraphSettings, setApplyGraphSettings] =
        useState<(options: ChartJSOptions, chartData: ChartJSData, colorIdentifiers?: ChartColorIdentifiers[]) => 
            { options: ChartJSOptions; chartData: ChartJSData; }>();
    const triggerChartUpdate = () => {
        let options = deepClone(chartOptions) || {};
        let chartData = deepClone(chartJSData);
        const applyOptionReturn = applyValueType(selectedValueType, chartData, options);
        options = applyOptionReturn.options;
        chartData = applyOptionReturn.chartData;
        if (applyGraphSettings) {
            ({ options, chartData } = applyGraphSettings(options, chartData, colorIdentifiers));
        }
        CreateChartTS(chartData, options);
    };

    useEffect(() => {
        if (chartJSData && chartOptions) {
            triggerChartUpdate();
        }
    }, [chartJSData, chartOptions]);

    return (
        <ThemeProvider theme={ResultsTheme}>
            <Box
                sx={{
                    margin: '0',
                    maxWidth: '100%',
                    maxHeight: '-webkit-fill-available',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1vw',
                }}
            >
                <Box className='temp-box' />
                <Box component='div' className='results-wrapper'>
                    <Box component='div' className='results-options'>
                        <Paper square>
                            <Box component='div' className='results-options-top'>
                                <FormControl
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: '3rem',
                                        alignItems: 'stretch',

                                    }}
                                >
                                    <EcoChartNavigation product={product} defaultBaseIndex={0} defaultResultIndex={2} />
                                    <ProductComparison />
                                </FormControl>
                            </Box>
                        </Paper>
                        <Divider />
                        <Paper square>
                            <Box component='div' className='results-options-bottom'>
                                <Box>
                                    <FormControl
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}
                                        className='bottom-options side-left'
                                    >
                                        <GraphType
                                            type='bar'
                                            dimension='Economic'
                                            chart='A3'
                                        />
                                        <Dropdown
                                            options={['Raw Values', '% Per Lifecycle Substage']}
                                            valueSetter={setSelectedValueType}
                                            value={selectedValueType}
                                        />
                                    </FormControl>
                                </Box>
                                <Box>
                                    <FormControl
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            '@media (max-width: 1680px)': {
                                                gap: '1vw',
                                            },
                                        }}
                                        className='bottom-options side-right'
                                    >
                                        <ChartDownload chartData = {chartJSData} 
                                            chartName={'Total cost result - Sublifecycle'} chartType='Bar Graph' />
                                        <GraphSetting
                                            chartOptions={chartOptions}
                                            setApplyGraphSettings={setApplyGraphSettings}
                                            triggerChartUpdate={triggerChartUpdate}
                                            colorIdentifiers={colorIdentifiers}
                                            chartData = {chartJSData}
                                            chartColorType = 'lcss'
                                            graphType="barindicator"
                                            productSysOnly
                                        />

                                    </FormControl>
                                </Box>
                            </Box>
                        </Paper>
                    </Box>
                </Box>
                <Box className={classes.chartSec}>
                    <Paper
                        square
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <Box className={classes.chartSec}>
                            <canvas id='graph1Canvas' className='graph-canvas' />
                        </Box>
                    </Paper>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default A3BarEco;
