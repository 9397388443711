
/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { useEffect, useState } from 'react';
import {

    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    tableCellClasses,
} from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled, alpha } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import FormGroup from '@mui/material/FormGroup';

import Tab from '@mui/material/Tab';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { ThemeProvider } from '@emotion/react';
import TableTheme from '../../../../style/components/TableTheme';
import TabContext from '@mui/lab/TabContext';
import ExitButton from '../../../../style/components/ExitButton';
import ClearIcon from '@mui/icons-material/Clear';
import { Exchange, Flow, Process } from '../../../../../interface/Process';
import { fetchFlowDetails, fetchProcessFlowDetails } from '../../../../../helper/fetchUtil';
import { useForm } from '@tanstack/react-form';
import ProductsSearchBoxTheme from '../../../../mainmenu/components/ProductsSearchBox/ProductsSearchBoxTheme';
import EnvProcessFlowListSearch from '../EnvProcessFlowListSearch';
import './ProcessProductFlowDetails.css'

interface ProcessFlowDetailsProps {
    flowId: string | undefined
    handleEnvProcessFlowClick: () => void
    handleEnvProcessFlowClickOff: () => void
    dimension: string,
}

function FlowDetails(
    { flowId, handleEnvProcessFlowClick, handleEnvProcessFlowClickOff, dimension }: ProcessFlowDetailsProps,
) {
    const [detailsFlowValue, setDetailsFlowValue] = React.useState('details-process');


    const [loadedFlow, setLoadedFlow] = useState<Flow>();


    useEffect(() => {
        if (flowId) {
            fetchFlowDetails(flowId, dimension).then((val) => {
                if (val) {
                    setLoadedFlow(val)

                }
            });

        }
    }, [flowId])


    const InputTableCell = styled(TableCell)(({ theme }) => {
        return {
            [`&.${tableCellClasses.head}`]: {
                backgroundColor: '#004021',
                color: 'white',
                fontSize: '0.7vw',
                padding: '1vw',
            },
            [`&.${tableCellClasses.body}`]: {
                fontSize: '0.7vw',
                padding: '1vw',
            },
        }
    });

    const InputTableRow = styled(TableRow)(({ theme }) => {
        return {
            // padding: '5px',
            fontSize: '0.8vw',
            color: '#707070',
            '&:nth-of-type(odd)': {
                backgroundColor: '#F3F3F3',
            },
            '&:nth-of-type(even)': {
                backgroundColor: '#E6E6E6',
            },
        }
    });

    const ProcessTopWrapper = styled(Box)(({ theme }) => {
        return {
            display: 'flex',
            flexDirection: 'row-reverse',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            height: '5vh',
        }
    });

    const SelectProcessPopupParent = styled(Box)(({ theme }) => {
        return {
            width: '-webkit-fill-available',
            height: '-webkit-fill-available',
            margin: '5rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }
    });

    const SelectProcessPopupContent = styled(Box)(({ theme }) => {
        return {
            width: "-webkit-fill-available",
            height: "-webkit-fill-available",
            display: "flex",
            flexDirection: "column",
            gap: "1vw",
        }
    });

    const TabEditProcess = styled(Tab)(({ theme }) => {
        return {
            width: '5vw',
            height: 'fit-content',
            fontSize: '0.8vw',
            padding: '2px',
            boxShadow: '0 0 0',
            textTransform: 'capitalize',
            fontWeight: 'bold',
            fontFamily: '"Montserrat", sans-serif',
            backgroundColor: '#166d46',
            color: 'white',
            margin: '0.5vw',
            minHeight: '3vh',
            minWidth: '5vw',
            '&:hover': {
                backgroundColor: '#D9EFDF',
                color: '#004021',
                transition: '0.3s',
            },
            '&.MuiTab-root.Mui-selected': {
                backgroundColor: '#D9EFDF',
                color: '#004021',
                transition: '0.3s',
            },
        }
    });

    const TabListStyled = styled(TabList)(({ theme }) => {
        return {
            margin: '1rem',
            marginBottom: '0',
            display: 'flex',
            gap: '1vw',
            '& .MuiTabs-indicator': { display: 'none' },
        }
    });



    return (
        <ThemeProvider theme={TableTheme}>
            {/* <ClickAwayListener onClickAway={handleEnvProcessFlowClickOff}> */}

            <FormGroup>
                <SelectProcessPopupParent
                    className='select-process-popup__parent process-flow-wrap'
                    sx={{
                        position: 'fixed',
                    }}
                >
                    <Paper square
                        sx={{
                            maxHeight: '40vw',
                            maxWidth: '70vw',
                            height: '-webkit-fill-available',
                            width: '-webkit-fill-available',
                        }}
                    >
                        <SelectProcessPopupContent className='select-process-popup__content'>
                            {/* <TabContext value={detailsFlowValue}> */}

                            {/* <TabPanel value='details-flow' sx={{ paddingTop: 0 }}> */}
                            <Box>
                                <ProcessTopWrapper>
                                    <ThemeProvider theme={ExitButton}>
                                        <Button
                                            onClick={handleEnvProcessFlowClick}
                                            sx={{ marginRight: '0rem', marginTop: '0rem' }}
                                        >
                                            <ClearIcon />
                                        </Button>
                                    </ThemeProvider>
                                </ProcessTopWrapper>
                            </Box>
                            <Typography
                                className='select-process-popup__title'
                                component='h2'
                                variant='h2'
                                sx={{ marginTop: '1rem', marginBottom: '1rem' }}
                            >
                                        Flow Details
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    width: '-webkit-fill-available',
                                    justifyContent: 'center',
                                }}
                            >
                                <TableContainer component={Box} sx={{ maxWidth: '60vw' }}>
                                    <Table sx={{ minWidth: '40vw', maxHeight: '40vh' }} aria-label='flow table'>
                                        <TableHead
                                            sx={{
                                                display: 'flex',
                                            }}
                                        >
                                            <TableRow>
                                                <InputTableCell
                                                    sx={{
                                                        width: '12vw',
                                                    }}
                                                >Flow Name</InputTableCell>
                                                {/* <InputTableCell align='center'>
                                                        Description
                                                    </InputTableCell> */}
                                                <InputTableCell
                                                    sx={{
                                                        width: '30vw',
                                                    }}
                                                    align='center'>
                                                        Category
                                                </InputTableCell>
                                                <InputTableCell
                                                    sx={{
                                                        width: '10vw',
                                                    }}
                                                    align='center'>
                                                        Flow Type
                                                </InputTableCell>


                                                <InputTableCell
                                                    sx={{
                                                        width: '10vw',
                                                    }}
                                                    align='center'>
                                                        Flow Property
                                                </InputTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody
                                            sx={{
                                                maxHeight: '24vw',
                                                height: 'auto',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                overflowY: 'scroll',
                                            }}
                                        >
                                            {loadedFlow && <InputTableRow key={loadedFlow.name}>
                                                <InputTableCell
                                                    sx={{
                                                        width: '12vw',
                                                    }}
                                                    component='th' scope='row'>
                                                    {loadedFlow.name}
                                                </InputTableCell>
                                                {/* <InputTableCell align='center'>
                                                                {exchange.description}
                                                            </InputTableCell> */}
                                                <InputTableCell
                                                    sx={{
                                                        width: '30vw',
                                                    }}
                                                    align='center'>
                                                    {loadedFlow.category}
                                                </InputTableCell>
                                                <InputTableCell
                                                    sx={{
                                                        width: '10vw',
                                                    }}
                                                    align='center'>
                                                    {loadedFlow.flowType}
                                                </InputTableCell>

                                                <InputTableCell
                                                    sx={{
                                                        width: '10vw',
                                                    }}
                                                    align='center'>
                                                    {loadedFlow.flowProperty}
                                                </InputTableCell>
                                            </InputTableRow>}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                            {/* </TabPanel> */}
                            {/* </TabContext> */}
                        </SelectProcessPopupContent>
                    </Paper>
                </SelectProcessPopupParent>
            </FormGroup>
        </ThemeProvider >
    );
}

export default FlowDetails;
