import './css/style.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { RouterProvider } from '@tanstack/react-router';
import router from './routes';
import { Toaster } from 'react-hot-toast';

const queryClient = new QueryClient();

let theme = createTheme();

theme = createTheme({
    breakpoints: {
        values: {
            // xs: 0,
            // sm: 767,
            // md: 1024,
            // lg: 1450,
            // xl: 1600,
            xs: 0,
            sm: 767,
            md: 1300,
            lg: 1680,
            xl: 1800,
        },
    },
    typography: {
        h1: {
            fontFamily: 'Montserrat, sans-serif',
            [theme.breakpoints.between('xs', 'sm')]: {
                fontSize: 20,
            },
        },
        h2: {
            fontFamily: 'Montserrat, sans-serif',
            [theme.breakpoints.between('xs', 'sm')]: {
                fontSize: 18,
            },
            '@media (max-width: 1680px)': {
                fontSize: 22,
            },
        },
        h3: {
            fontFamily: 'Montserrat, sans-serif',
            [theme.breakpoints.between('xs', 'sm')]: {
                fontSize: 16,
            },
        },
        h4: {
            fontFamily: 'Montserrat, sans-serif',
            [theme.breakpoints.between('xs', 'sm')]: {
                fontSize: 16,
            },
        },
        h5: {
            [theme.breakpoints.between('xs', 'sm')]: {
                fontSize: 14,
            },
        },
        h6: {
            [theme.breakpoints.between('xs', 'sm')]: {
                fontSize: 12,
            },
        },
        subtitle1: {
            fontFamily: 'Roboto, sans-serif',
            fontSize: 12,
        },
        subtitle2: {
            fontFamily: 'Roboto, sans-serif',
            fontSize: 16,
        },
        body1: {
            fontFamily: 'Roboto, sans-serif',
            fontSize: 14,
        },
        body2: {
            fontFamily: 'Roboto, sans-serif',
            fontSize: 13,
        },
        button: {
            fontSize: 16,
        },
    },
});

// declare module '@tanstack/react-router' {
//     interface Register {
//       router: typeof router
//     }
// }

function App() {
    return (
        <div className="App">
            <head>
                <meta name="viewport" content="width=device-width" />
            </head>
            <Toaster />
            <QueryClientProvider client={queryClient}>
                <ThemeProvider theme={theme}>
                    <RouterProvider router={router} />
                </ThemeProvider>
            </QueryClientProvider>
        </div>
    );
}

export default App;
