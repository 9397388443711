import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    BoxWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1vw',
        width: '-webkit-fill-available',
        height: '80vh',
        textAlign: 'left',
    },

    BoxSection: {
        display: 'flex',
        gap: '1vw',
        width: '-webkit-fill-available',
        justifyContent: 'center',
        alignItems: 'center',
    },

    BoxGrid: {
        gap: '1vw',
        width: '-webkit-fill-available',
        display: 'grid',
        gridTemplateRows: 'auto auto',
        gridTemplateColumns: '1fr 1fr',
    },

    PaperSpacing: {
        display: 'flex',
        gap: '1vw',
        flexDirection: 'column',
        width: '-webkit-fill-available',
        padding: '1vw',
    },

    ButtonLayout: {
        display: 'flex',
        gap: '5px !important',
        flexDirection: 'column',
        textTransform: 'capitalize',
        color: '#707070 !important',
        fontSize: '20px',
        fontWeight: 600,
    },

    ButtonSet: {
        display: 'flex',
        gap: '1vw',
        flexDirection: 'row',
        height: 'auto',
        justifyContent: 'center',
        alignItems: 'flex-end',
    },

    GetStartedLinks: {
        color: '#707070',
        textTransform: 'capitalize',
    },

    SampleGraphsPopup: {
        width: '100%',
        border: 0,
        height: '100%',
        padding: '8px',
        position: 'fixed', 
        backgroundColor: '#2c2c2c94',
        bottom: 0, 
        left: 0, 
        transform: 'translate(0px, 0)', 
        zIndex: 3000,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&': {
            position: 'fixed !important',
            bottom: '0 !important',
            left: '0 !important',
            transform: 'translate(0px, 0) !important',
        },
    },

    SampleGraphWrapper: {
        height: 'auto',
        backgroundColor: '#D9EFDF',
        border: 0,
        boxShadow: '0 0 3px #000000d1',
        display: 'grid',
        justifyItems: 'center',
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'auto 1fr',
    },
}));

export default useStyles;
