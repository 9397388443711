import React, { useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import useStyles from './A4StackedPSILCA.styles';
import Dropdown from '../../../../../dropdowns/Dropdown';
import deepClone from '../../../../../../helper/deepClone';
import { ChartJSData, ChartJSOptions } from '../../../../../../interface/ChartData';
import { Product, PSILCAResult } from '../../../../../../interface/Product';
import PSILCAChartNavigation from '../../../../../dropdowns/BaseOptions/PSILCAChartNavigation';
import LCSDropdowns from '../../../../../dropdowns/LCSDropdowns';
import ResultsTheme from '../../../../style/ResultsTheme';
import { initialOptions } from '../../../../options/InitialOptionValues';
import ChartDownload from '../../../../options/components/ChartDownload';
import ProductComparison from '../../../../options/components/Comparison';
import GraphSetting from '../../../../options/components/GraphSettings';
import { applyStacked, applyValueType } from '../../../../options/util/applyOptions';
import { CreateChartTS } from '../../../../util/CreateChartTS';
import { treemapRGB } from '../../../../../../helper/colors';
import GraphType from '../../../../options/components/GraphTypeOptions';
import { ChartColorIdentifiers } from '../../../../options/components/GraphSettingsOptions';



interface ChartData {
    product: Product;
}

function A4StackedPSILCA(props: ChartData) {
    const classes = useStyles();
    const { product } = props;
    const [chartJSData, setChartJSData] = useState<ChartJSData>({ datasets: [], labels: [] });
    const [chartOptions, setChartOptions] = useState<ChartJSOptions>(initialOptions);
    const [colorIdentifiers, setColorIdentifiers] = useState<ChartColorIdentifiers[]>([]);
    const [selectedLCS, setSelectedLCS] = useState<string>('');
    const [selectedLCSS, setSelectedLCSS] = useState<string>('');
    const [results, setResults] = useState<PSILCAResult[]>([]);
    const [selectedValueType, setSelectedValueType] = useState<string>('');

    useMemo(() => {
        const calc = product.calculations.find(
            (item) => {return item.calculationType === 'SOCIAL-PSILCA'},
        );
        const calcResults = calc?.calculationResults as PSILCAResult[];
        setResults((calcResults));
    }, [product]);

    useEffect(() => {
        const chartData: ChartJSData = { datasets: [], labels: [] };
        const options: ChartJSOptions = deepClone<ChartJSOptions>(initialOptions);
        const chartColorIdentifiers : ChartColorIdentifiers[] = [];
        if (options.scales && options.scales.y && options.scales.y.title) {
            options.scales.y.title.text = "Medium Risk Hours";
        }
        
        let processes: string[] = [];
        const matchingLCS = results[0].subcategories[0].indicators[0]
            .indicatorResults.find((item) => {return item.lcsName === `${selectedLCS}:${selectedLCSS}`});
        if (matchingLCS) {
            processes = matchingLCS.processResults.processes.map((item) => {return item.process});
        }

        chartData.datasets = results.map(
            (stakeholder, index) => {
                chartColorIdentifiers.push({label:stakeholder.stakeholder})
                return {
                    label: stakeholder.stakeholder,
                    data: processes.map((process) => {return stakeholder.subcategories
                        .map((subcategory) => {return subcategory.indicators
                            .map((indicator) => {return indicator.indicatorResults
                                .find(
                                    (lcs) => {return lcs.lcsName === `${selectedLCS}:${selectedLCSS}`},
                                )?.processResults.processes.find(
                                    (result) => {return result.process === process},
                                )?.processTotalSum || 0})
                            .reduce((a, b) => {return a + b}, 0)})
                        .reduce((a, b) => {return a + b}, 0)}),

                    backgroundColor: treemapRGB[index++],
                // backgroundColor: treemapSetColors[index], // colors[index],
                // borderColor: '000000',
                }},
        );

        chartData.labels = processes;

        setChartJSData(chartData);
        setChartOptions(options);
        setColorIdentifiers(chartColorIdentifiers);    
    }, [selectedLCS, selectedLCSS, selectedValueType]);

    const [applyGraphSettings, setApplyGraphSettings] =
        useState<(options: ChartJSOptions, chartData: ChartJSData, colorIdentifiers?: ChartColorIdentifiers[]) => 
            { options: ChartJSOptions; chartData: ChartJSData; }>();
    const triggerChartUpdate = () => {
        let options = deepClone(chartOptions) || {};
        let chartData = deepClone(chartJSData);
        const applyOptionReturn = applyValueType(selectedValueType, chartData, options,true);
        options = applyOptionReturn.options;
        chartData = applyOptionReturn.chartData;
        options = applyStacked(options, true);

        if (applyGraphSettings) {
            ({ options, chartData } = applyGraphSettings(options, chartData, colorIdentifiers));
        }
        CreateChartTS(chartData, options);
    };
    useEffect(() => {
        if (chartJSData && chartOptions) {
            triggerChartUpdate();
        }
    }, [chartJSData, chartOptions]);


    return (
        <ThemeProvider theme={ResultsTheme}>
            <Box
                sx={{
                    margin: '0',
                    maxWidth: '100%',
                    maxHeight: '-webkit-fill-available',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1vw',
                }}
            >
                <Box className='temp-box' />
                <Box component='div' className='results-wrapper'>
                    <Box component='div' className='results-options'>
                        <Paper square>
                            <Box component='div' className='results-options-top'>
                                <FormControl
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: '3rem',
                                        alignItems: 'stretch',

                                    }}
                                >
                                    <PSILCAChartNavigation product={product} defaultBaseIndex={0} defaultResultIndex={3} />
                                    <ProductComparison />
                                </FormControl>
                            </Box>
                        </Paper>
                        <Divider />
                        <Paper square>
                            <Box component='div' className='results-options-bottom'>
                                <Box>
                                    <FormControl
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}
                                        className='bottom-options side-left'
                                    >
                                        <GraphType
                                            type='stacked'
                                            dimension='PSILCA'
                                            chart='A4'
                                        />
                                        <LCSDropdowns
                                            product={product}
                                            selectedLCS={selectedLCS}
                                            setSelectedLCS={setSelectedLCS}
                                            selectedLCSS={selectedLCSS}
                                            setSelectedLCSS={setSelectedLCSS}
                                            dimension="Social"
                                        />
                                        <Dropdown
                                            options={['Raw Values', '% Per Process']}
                                            valueSetter={setSelectedValueType}
                                            value={selectedValueType}
                                        />
                                    </FormControl>
                                </Box>
                                <Box>
                                    <FormControl
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}
                                        className='bottom-options side-right'
                                    >

                                        <ChartDownload chartData = {chartJSData} 
                                            chartName={'Total weighted result: all stakeholders - Process'} chartType='Stacked Bar Graph' />
                                        <GraphSetting
                                            chartOptions={chartOptions}
                                            setApplyGraphSettings={setApplyGraphSettings}
                                            triggerChartUpdate={triggerChartUpdate}
                                            colorIdentifiers={colorIdentifiers}
                                            chartData = {chartJSData}
                                            chartColorType = 'treemap'
                                            graphType='stacked'
                                            noColors
                                        />

                                    </FormControl>
                                </Box>
                            </Box>
                        </Paper>
                    </Box>
                </Box>
                <Box className={classes.chartSec}>
                    <Paper
                        square
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <Box className={classes.chartSec}>
                            <canvas id='graph1Canvas' className='graph-canvas' />
                        </Box>
                    </Paper>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default A4StackedPSILCA;
