import { Box, Typography, TextField } from "@mui/material";
import { FormApi } from "@tanstack/react-form";
import { useQuery } from "@tanstack/react-query";
import { useState, useEffect } from "react";
import { fetchUnitGroups } from "../../../../../helper/fetchUtil";
import {
    ProductDetailsForm,
    loadValuesFromFormContext,
} from "../../../../../interface/Form";
import { Product } from "../../../../../interface/Product";
import { useFormContext } from "../../../../forms/FormContext";
import Dropdown from "../../../../dropdowns/Dropdown";
import { useRouterState } from "@tanstack/react-router";

function ProductNameDescForm(props: {
    form: FormApi<ProductDetailsForm>;
    productToEdit: Product | undefined;
    validateForm: () => void;
}) {
    const { form, productToEdit, validateForm } = props;
    const [selectableUnits, setSelectableUnits] = useState<string[]>([]);
    const [selectableUnitGroups, setSelectableUnitGroups] = useState<string[]>(
        []
    );
    const route = useRouterState();
    const isEditing = route.location.pathname.includes("edit");
    const { formState, setFormState } = useFormContext();

    const { data: loadedUnitGroups = [] } = useQuery({
        queryKey: ["unitGroups"],
        queryFn: () => {
            return fetchUnitGroups();
        },
        staleTime: Infinity,
    });
    const selectUnitGroupHandler = (
        selectedUG: string,
        defaultUnit?: string
    ): string[] => {
        const unitGroup = loadedUnitGroups.find((item) => {
            return item.name === selectedUG;
        });
        let units: string[] = [];
        if (unitGroup) {
            units = unitGroup.units.map((item) => {
                return item.name;
            });
            setSelectableUnits(units);
            const unit = defaultUnit || units[0];
            form.setFieldValue("analysisUnit", unit);
        }
        return units;
    };

    useEffect(() => {
        if (loadedUnitGroups.length > 0) {
            const unitGroups = loadedUnitGroups.map((item) => {
                return item.name;
            });
            setSelectableUnitGroups(unitGroups);

            if (productToEdit) {
                if (!form.state.values.productName && !formState.productName) {
                    form.setFieldValue("productName", productToEdit.productName);
                }else{
                    form.setFieldValue("productName", formState.productName);

                }
                if (!form.state.values.productDesc && !formState.productDesc) {
                    form.setFieldValue("productDesc", productToEdit.productDesc);
                }else{
                    form.setFieldValue("productDesc", formState.productDesc);

                }
                if (!form.state.values.analysisUnitQTY && !formState.analysisUnitQTY
                ) {
                    form.setFieldValue("analysisUnitQTY", productToEdit.unitOfAnalysis.unitQTY);

                }else{
                    form.setFieldValue("analysisUnitQTY", formState.analysisUnitQTY);

                }
      
                if (!form.state.values.analysisUnit && !formState.analysisUnit  
                     && !form.state.values.analysisUnitGroup && !formState.analysisUnitGroup) {
                    form.setFieldValue("analysisUnitGroup", productToEdit.unitOfAnalysis.unitGroup);

                    selectUnitGroupHandler(productToEdit.unitOfAnalysis.unitGroup, productToEdit.unitOfAnalysis.unit);
                }else{
                    form.setFieldValue("analysisUnitGroup", formState.analysisUnitGroup);

                    selectUnitGroupHandler(formState.analysisUnitGroup, formState.analysisUnit);
                }
            } else if (!isEditing) {
                console.log("NOT EDITING");

                const unitGroup = formState.analysisUnitGroup || unitGroups[0];
                loadValuesFromFormContext(form, formState, [
                    "productName",
                    "productDesc",
                    "analysisUnitQTY",
                    "analysisUnitGroup",
                    "analysisUnit",
                ]);
                const selectedUnitGroup = loadedUnitGroups.find((ug) => {
                    return ug.name === unitGroup;
                });
                form.setFieldValue("analysisUnitGroup", unitGroup);
                selectUnitGroupHandler(unitGroup, selectedUnitGroup?.units[0].name);
            }




        }

    }, [ loadedUnitGroups, productToEdit]);


    return (
        <Box
            className="product-name-desc-form__content"
            sx={{
                display: "flex",
                flexDirection: "column",
                gap: "1vw",
            }}
        >
            <Typography
                component="h2"
                variant="h2"
                onClick={() => {
                    console.log(formState);
                    console.log(form.state.values);
                }}
            >
                Product Details
            </Typography>
            <Box
                className="product-name-desc-section"
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.5rem",
                }}
            >
                <Typography component="h3" variant="h3">
                    Product Name
                </Typography>
                <form.Field
                    name="productName"
                    children={(field) => {
                        return (
                            <TextField
                                id={field.name}
                                className={`${field.name} product-name`}
                                value={field.state.value}
                                onBlur={field.handleBlur}
                                onChange={(e) => {
                                    field.handleChange(e.target.value);

                                    validateForm();
                                }}
                                required
                                label="Required"
                                placeholder="Product Name"
                                size="small"
                                sx={{
                                    width: "100%",
                                }}
                            />
                        );
                    }}
                />
            </Box>
            <Box className="product-name-desc-section">
                <Typography component="h3" variant="h3">
                    Product Description
                </Typography>
                <form.Field
                    name="productDesc"
                    children={(field) => {
                        return (
                            <TextField
                                id={field.name}
                                className={field.name}
                                value={field.state.value}
                                onBlur={field.handleBlur}
                                onChange={(e) => {
                                    field.handleChange(e.target.value);

                                    validateForm();
                                }}
                                placeholder="Description"
                                rows={8}
                                multiline
                                size="small"
                                sx={{
                                    width: "100%",
                                }}
                            />
                        );
                    }}
                />
            </Box>
            <Box
                className="product-name-desc-section"
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.5rem",
                }}
            >
                <Typography component="h3" variant="h3">
                    Unit of Analysis
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "0.5rem",
                        // '@media (max-width: 1680px)': {
                        //     flexDirection: 'column',
                        // },
                    }}
                >
                    <form.Field
                        name="analysisUnitQTY"
                        children={(field) => {
                            return (
                                <TextField
                                    id={field.name}
                                    className={field.name}
                                    value={field.state.value}
                                    onBlur={field.handleBlur}
                                    onChange={(e) => {
                                        return field.handleChange(Number(e.target.value));
                                    }}
                                    required
                                    label="Required"
                                    type="number"
                                    defaultValue={1}
                                    size="small"
                                    sx={{
                                        width: "fit-conten",
                                        "& .MuiInputBase-input": {
                                            width: "5vw",
                                            padding: "0.5vw",
                                        },
                                    }}
                                />
                            );
                        }}
                    />
                    <form.Field
                        name="analysisUnitGroup"
                        children={(field) => {
                            return (
                                <Dropdown
                                    options={selectableUnitGroups}
                                    onChange={(e) => {
                                        field.handleChange(e.target.value);
                                        selectUnitGroupHandler(e.target.value);
                                    }}
                                    id={field.name}
                                    className={field.name}
                                    value={field.state.value}
                                    onBlur={field.handleBlur}
                                    noDefaults
                                />
                            );
                        }}
                    />

                    <form.Field
                        name="analysisUnit"
                        children={(field) => {
                            return (
                                <Dropdown
                                    options={selectableUnits}
                                    onChange={(e) => {
                                        return field.handleChange(e.target.value);
                                    }}
                                    id={field.name}
                                    className={field.name}
                                    value={field.state.value}
                                    onBlur={field.handleBlur}
                                    noDefaults
                                />
                            );
                        }}
                    />
                </Box>
            </Box>
        </Box>
    );
}
export default ProductNameDescForm;
