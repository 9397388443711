import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import useStyles from './C4BarSPA.styles';
import { Bar } from 'react-chartjs-2';
// import Loader from '../../components/loader';
import toast from 'react-hot-toast';
import deepClone from '../../../../../../helper/deepClone';
import { ChartJSData, ChartJSOptions } from '../../../../../../interface/ChartData';
import SPAChartNavigation from '../../../../../dropdowns/BaseOptions/SPAChartNavigation';
import ResultsTheme from '../../../../style/ResultsTheme';
import { initialOptions } from '../../../../options/InitialOptionValues';
import ChartDownload from '../../../../options/components/ChartDownload';
import ProductComparison from '../../../../options/components/Comparison';
import GraphSetting from '../../../../options/components/GraphSettings';
import { applyValueType } from '../../../../options/util/applyOptions';
import { CreateChartTS } from '../../../../util/CreateChartTS';
import {
    SPAPSILCAPriorityResult, Product, SPALCSProcessResult, SPALifeCycleResult,
    SPAResult, SPAStakeholderResult,
} from '../../../../../../interface/Product';
import Dropdown from '../../../../../dropdowns/Dropdown';
import { palette1, palette2, palette3, palette4, processColors, productPerformanceColor, socialProcessColors } from '../../../../../../helper/colors';
import { useFormContext } from '../../../../../forms/FormContext';
import { WeighingSet, applyWeight, weighingMethods } from '../../../../../../helper/weighingMethod';
import LCSDropdowns from '../../../../../dropdowns/LCSDropdowns';
import GraphType from '../../../../options/components/GraphTypeOptions';
import { ChartColorIdentifiers } from '../../../../options/components/GraphSettingsOptions';



interface ChartData {
    product: Product;
    doubleGraph?: boolean;
}

function C4RadarSPA(props: ChartData) {
    const { formState, setFormState } = useFormContext();
    const classes = useStyles();
    const { product, doubleGraph } = props;
    const [chartJSData, setChartJSData] = useState<ChartJSData>({ datasets: [], labels: [] });
    const [chartOptions, setChartOptions] = useState<ChartJSOptions>(initialOptions);
    const [colorIdentifiers, setColorIdentifiers] = useState<ChartColorIdentifiers[]>([]);
    const [selectedValueType, setSelectedValueType] = useState<string>('');
    const [selectedStakeholder, setSelectedStakeholder] = useState<string>('All Stakeholders');
    const [stakeholderOptions, setStakeholderOptions] = useState<string[]>([]);
    const [currentWeighingSet, setCurrentWeighingSet] = useState<WeighingSet>();
    const [selectedLCS, setSelectedLCS] = useState<string>('');
    const [selectedLCSS, setSelectedLCSS] = useState<string>('');
    const [selectedProcess, setSelectedProcess] = useState<string>('');
    const [results, setResults] = useState<SPAResult>();

    useMemo(() => {
        const calc = product.calculations.find(
            (item) => { return item.calculationType === 'SOCIAL-SPA' },
        );
        const calcResults = calc?.calculationResults as unknown as SPAResult;

        setResults((calcResults));
        const weighingMethod = weighingMethods.find((method) => method.name === product.socialData.weighingMethod.name);
        const weighingSet = weighingMethod?.sets.find((set) => set.name === product.socialData.weighingMethod.set);
        setCurrentWeighingSet(weighingSet);
        setStakeholderOptions(
            ["All Stakeholders"].concat(
                calcResults.lifeCycleResults[0].processResults[0].PSILCAPriorities.map(
                    (item) => {
                        return item.category.split(":")[0]
                    }
                ).reduce((list : string[], stakeholder) =>{
                    if(!list.find(item => item === stakeholder)){
                        list.push(stakeholder);
                    }
                    return list;
                } ,[])
            )
        );     }, [product]);


    const [selectedPalette, setSelectedPalette] = useState<string[]>([]);  // Store selected palette here

    const handlePaletteChange = (palette: string[]) => {
        setSelectedPalette(palette);
    };


    
    useEffect(() => {
        if(!results)
            return;
        const chartData: ChartJSData = { datasets: [], labels: [] };
        const options: ChartJSOptions = deepClone<ChartJSOptions>(initialOptions);
        const chartColorIdentifiers : ChartColorIdentifiers[] = [];

        if (options.scales && options.scales.y && options.scales.y.title) {
            options.scales.y.title.text = "Medium Risk Hours";
        }
        const spaLifeCycle = results.lifeCycleResults.find((lcs) => lcs.lcsName === `${selectedLCS}:${selectedLCSS}`);
        
        if (spaLifeCycle) {
            const process = spaLifeCycle.processResults.find(
                (item) => item.processName === selectedProcess.split(" - ")[0]
            );

            if (process) {
                const stakeholder = selectedStakeholder;

                spaLifeCycle.processResults.forEach((item, index) => {
     
                    if (item.processName === process.processName) {

                        // const colorIndex = getGraphColorIndex({
                        //     label: process.processName,
                        //     identifier: spaLifeCycle.lcsName,
                        // });

                        const hexToRgba = (hex, opacity) => {
                            const r = parseInt(hex.slice(1, 3), 16);
                            const g = parseInt(hex.slice(3, 5), 16);
                            const b = parseInt(hex.slice(5, 7), 16);
                        
                            return `rgba(${r}, ${g}, ${b}, ${opacity})`;
                        };

                        chartColorIdentifiers.push({label:process.processName});
                        chartData.datasets.push({
                            label: process.processName,
                            data: process.PSILCAResults.filter((result) => {
                                if (
                                    stakeholder !== "All Stakeholders" &&
                                    !!stakeholder
                                ) {
                                    return (
                                        result.category.split(":")[0] === stakeholder
                                    );
                                } else {
                                    return true;
                                }
                            })
                                .sort(
                                    (a, b) =>
                                        a.category.localeCompare(b.category)
                                )
                                .map((item2) => item2.score),
                            // backgroundColor:
                            // hexToRgba(selectedPalette[index], 0.5), //"rgb(65, 190, 132, 0.3)", //random color from array
                            // borderColor: selectedPalette[index],
                        });
                    }

                });
                if (!(chartData.labels.length > 0)) {
                    chartData.labels = process.PSILCAResults.filter(
                        (result) => {
                            if (
                                stakeholder !== "All Stakeholders" &&
                                !!stakeholder
                            ) {
                                return (
                                    result.category.split(":")[0] === stakeholder
                                );
                            } else {
                                return true;
                            }
                        }
                    )
                        .sort(
                            (a, b) =>  a.category.localeCompare(b.category)
                        )
                        .map((item2) => item2.category);
                }
                const indexes : number[] = [];
                chartData.datasets[0].data =
                    chartData.datasets[0].data.filter((value, index) => {
                        if (value > 0) {
                            return true;
                        } else {
                            indexes.push(index);

                            return false;
                        }
                    });
                chartData.labels = chartData.labels.filter(
                    (item, index) => {
                        return !indexes.find((item) => item === index);
                    }
                );
            }
            delete options.scales;

        } else {
            // chartData.datasets = ca;cs
            //WIP?
        }

        console.log(chartData);

        setChartJSData(chartData);
        setChartOptions(options);
        setColorIdentifiers(chartColorIdentifiers);    
    }, [ selectedStakeholder,  selectedLCS, selectedLCSS, selectedProcess]);

    const [applyGraphSettings, setApplyGraphSettings] =
        useState<(options: ChartJSOptions, chartData: ChartJSData, colorIdentifiers?: ChartColorIdentifiers[]) => 
            { options: ChartJSOptions; chartData: ChartJSData; }>();
    const triggerChartUpdate = () => {
        let options = deepClone(chartOptions) || {};
        let chartData = deepClone(chartJSData);
        const applyOptionReturn = applyValueType(selectedValueType, chartData, options);
        options = applyOptionReturn.options;
        chartData = applyOptionReturn.chartData;
        if (applyGraphSettings) {
            ({ options, chartData } = applyGraphSettings(options, chartData, colorIdentifiers));
        }
        console.log(options);
        options.scales = {
            ...options?.scales,
            r: {
                ...options?.scales?.r,
                beginAtZero: true,  
                ticks: {
                    ...options?.scales?.r?.ticks,
                    stepSize: 1,
                    callback: function(value) { 
                        return Number.isInteger(value) ? value : null; 

                    },
                },
                
            },
        };
        CreateChartTS(chartData, options, 'radar');
    };

    useEffect(() => {
        if (chartJSData && chartOptions) {
            triggerChartUpdate();
        }
    }, [chartJSData, chartOptions]);

    return (
        <ThemeProvider theme={ResultsTheme}>
            <Box
                sx={{
                    margin: '0',
                    maxWidth: '100%',
                    maxHeight: '-webkit-fill-available',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1vw',
                    width:'-webkit-fill-available',
                }}
            >
                <Box component='div' className='results-wrapper'>
                    <Box component='div' className='results-options'>
                        {/* <Box component='div' className='results-options-top'>
                            <FormControl
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: '3rem',
                                    alignItems: 'stretch',

                                }}
                            >
                                <SPAChartNavigation product={product} defaultBaseIndex={2} defaultResultIndex={3} />
                                <ProductComparison />
                            </FormControl>
                        </Box> */}
                        <Box component='div' className='results-options-bottom'>
                            <Box>
                                <FormControl
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: '1rem',  // Adjust the gap between dropdowns
                                        alignItems: 'stretch', // Align all dropdowns vertically
                                        flexWrap: 'wrap', // 
                                        
                                    }}
                                    className='bottom-options side-left two-graphs'
                                >
                                    <GraphType
                                        chart='C4'
                                        dimension='SPA'
                                        type='radar'
                                    />
                                    <LCSDropdowns
                                        product={product}
                                        selectedLCS={selectedLCS}
                                        setSelectedLCS={setSelectedLCS}
                                        selectedLCSS={selectedLCSS}
                                        setSelectedLCSS={setSelectedLCSS}
                                        selectedProcess={selectedProcess}
                                        setSelectedProcess={setSelectedProcess}
                                        dimension="Social"
                                    />
                                    <Dropdown
                                        options={stakeholderOptions}
                                        valueSetter={setSelectedStakeholder}
                                        value={selectedStakeholder}

                                    />
                            

                                </FormControl>
                            </Box>
                            <Box>
                                <FormControl
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        flexWrap: doubleGraph ? 'wrap' : 'nowrap',
                                        justifyContent: 'center',
                                        '@media (max-width: 1680px)': {
                                            gap: '1vw',
                                        },
                                        width: 'min-content',
                                    }}
                                    className='bottom-options side-right'
                                >
                                    <ChartDownload chartData={chartJSData}
                                        chartName={'Subcategory level result - Process (SPA)'}
                                        chartType='Radar Chart' />
                                    <GraphSetting
                                        chartOptions={chartOptions}
                                        setApplyGraphSettings={setApplyGraphSettings}
                                        triggerChartUpdate={triggerChartUpdate}
                                        colorIdentifiers={colorIdentifiers}
                                        chartData={chartJSData}
                                        chartColorType='process'
                                        graphType='radar'
                                    />

                                </FormControl>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Divider />

                <Box className={classes.chartSec}>
                    <canvas id='graph1Canvas' className='graph-canvas' />
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default C4RadarSPA;
