import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import useStyles from './A3BarSPA.styles';
import deepClone from '../../../../../../helper/deepClone';
import { ChartJSData, ChartJSOptions } from '../../../../../../interface/ChartData';
import { Product, PSILCAResult, SPAResult } from '../../../../../../interface/Product';
import PSILCAChartNavigation from '../../../../../dropdowns/BaseOptions/PSILCAChartNavigation';
import ResultsTheme from '../../../../style/ResultsTheme';
import { initialOptions } from '../../../../options/InitialOptionValues';
import ChartDownload from '../../../../options/components/ChartDownload';
import ProductComparison from '../../../../options/components/Comparison';
import GraphSetting from '../../../../options/components/GraphSettings';
import { CreateChartTS } from '../../../../util/CreateChartTS';
import { palette1, palette2, palette3, palette4, processColors, sublifeCycleColors } from '../../../../../../helper/colors';
import { useFormContext } from '../../../../../forms/FormContext';
import { WeighingMethod, WeighingSet, applyWeight, getWeighingSetValue, weighingMethods } from '../../../../../../helper/weighingMethod';
import Dropdown from '../../../../../dropdowns/Dropdown';
import SPAChartNavigation from '../../../../../dropdowns/BaseOptions/SPAChartNavigation';
import { applyValueType } from '../../../../options/util/applyOptions';
import { useCallback, useEffect, useMemo, useState } from 'react';



interface ChartData {
    product: Product;
}

function A3BarSPA(props: ChartData) {
    const { formState, setFormState } = useFormContext();
    const classes = useStyles();
    const { product } = props;
    const [chartJSData, setChartJSData] = useState<ChartJSData>({ datasets: [], labels: [] });
    const [chartOptions, setChartOptions] = useState<ChartJSOptions>(initialOptions);
    const [colorIdentifiers, setColorIdentifiers] = useState<ChartColorIdentifiers[]>([]);    const [currentWeighingSet, setCurrentWeighingSet] = useState<WeighingSet>();
    const [selectedValueType, setSelectedValueType] = useState('');

    const [results, setResults] = useState<SPAResult>();

    useMemo(() => {
        const calc = product.calculations.find(
            (item) => { return item.calculationType === 'SOCIAL-SPA' },
        );
        const calcResults = calc?.calculationResults as unknown as SPAResult;
        console.log(calc);
        const weighingMethod = weighingMethods.find((method) => method.name === product.socialData.weighingMethod.name);
        const weighingSet = weighingMethod?.sets.find((set) => set.name === product.socialData.weighingMethod.set);
        setCurrentWeighingSet(weighingSet);
        setResults((calcResults));
    }, [product]);








    useEffect(() => {
        console.log(results);

        if (!results || !currentWeighingSet)
            return;


        const chartData: ChartJSData = { datasets: [], labels: [] };
        const options: ChartJSOptions = deepClone<ChartJSOptions>(initialOptions);
        const chartColorIdentifiers : ChartColorIdentifiers[] = [];

        if (options.scales && options.scales.y && options.scales.y.title) {
            options.scales.y.title.text = "Weighted Score";
        }

        const counter2 = 0;
        chartData.labels = [product.productName];

        chartData.datasets = results.lifeCycleResults.map((lcs) => {
            chartColorIdentifiers.push({label:lcs.lcsName});

            return {
                label: lcs.lcsName,
                data: [
                    lcs.processResults.reduce(
                        (processTotal, process) =>
                            processTotal +
                            process.PSILCAPriorities.reduce(
                                (priorityTotal, priority) =>
                                    priorityTotal + applyWeight(priority, "sam", currentWeighingSet),
                                0
                            ),
                        0
                    ),
                ],
                // backgroundColor: selectedPalette[counter2++], //"#1B5D82",//colors[Math.floor(Math.random() * 4)], //random color from array
                borderColor: "000000",
            };
        });

        if (results.SPAProcess?.lifeCycleStage) {
            const val = results.stakeholderResults.reduce(
                (samTotal, stakeholder) => {
                    return (
                        samTotal +
                        stakeholder.SPAPriorities.reduce(
                            (stakeholderTotal, category) => {
                                return (
                                    stakeholderTotal + applyWeight(category, "sam", currentWeighingSet)
                                );
                            },
                            0
                        )
                    );
                },
                0
            );
            chartColorIdentifiers.push({label:results.SPAProcess.lifeCycleStage});

            chartData.datasets.push({
                label: results.SPAProcess.lifeCycleStage,
                data: [val],
                // backgroundColor: selectedPalette[counter2++], //"#1B5D82",//colors[counter], //random color from array
                borderColor: "000000",
            });
        }




        setChartJSData(chartData);
        setChartOptions(options);
        setColorIdentifiers(chartColorIdentifiers);

    }, [results, product,  currentWeighingSet, selectedValueType]);
    const [applyGraphSettings, setApplyGraphSettings] =
        useState<(options: ChartJSOptions, chartData: ChartJSData, colorIdentifiers?: ChartColorIdentifiers[]) => 
            { options: ChartJSOptions; chartData: ChartJSData; }>();
    const triggerChartUpdate = () => {
        if (!chartJSData.datasets || !chartOptions) {
            return; // Exit early if datasets or options are not properly initialized
        }

        let options = deepClone(chartOptions);
        let chartData = deepClone(chartJSData);
        const applyOptionReturn = applyValueType(selectedValueType, chartData, options);
        options = applyOptionReturn.options;
        chartData = applyOptionReturn.chartData;
        if (applyGraphSettings) {
            ({ options, chartData } = applyGraphSettings(options, chartData, colorIdentifiers));

        }

        CreateChartTS(chartData, options);
    };
    useEffect(() => {
        if (chartJSData && chartOptions) {
            triggerChartUpdate();
        }
    }, [chartJSData, chartOptions]);

    return (
        <ThemeProvider theme={ResultsTheme}>
            <Box
                sx={{
                    margin: '0',
                    maxWidth: '100%',
                    maxHeight: '-webkit-fill-available',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1vw',
                }}
            >
                <Box className='temp-box' />
                <Box component='div' className='results-wrapper'>
                    <Box component='div' className='results-options'>
                        <Paper square>
                            <Box component='div' className='results-options-top'>
                                <FormControl
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: '3rem',
                                        alignItems: 'stretch',

                                    }}
                                >
                                    <SPAChartNavigation product={product} defaultBaseIndex={0} defaultResultIndex={2} />
                                    <ProductComparison />
                                </FormControl>
                            </Box>
                        </Paper>
                        <Divider />
                        <Paper square>
                            <Box component='div' className='results-options-bottom'>
                                <Box>
                                    <FormControl
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}
                                        className='bottom-options side-left'
                                    >
                                        <Dropdown
                                            options={['Raw Values', '% Per Lifecycle Substage']}
                                            valueSetter={setSelectedValueType}
                                            value={selectedValueType}
                                            label='Value Type'
                                        />
                                    </FormControl>
                                </Box>
                                <Box>
                                    <FormControl
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            '@media (max-width: 1680px)': {
                                                gap: '1vw',
                                            },
                                        }}
                                        className='bottom-options side-right'
                                    >


                                        <ChartDownload chartData={chartJSData}
                                            
                                            chartName={'Total weighted result: all stakeholders - Product System'}
                                            chartType='Bar Graph' />
                                        <GraphSetting
                                            chartOptions={chartOptions}
                                            setApplyGraphSettings={setApplyGraphSettings}
                                            triggerChartUpdate={triggerChartUpdate}
                                            colorIdentifiers={colorIdentifiers}
                                            chartData={chartJSData}
                                            chartColorType='lcss'
                                            graphType='barindicator'

                                        />

                                    </FormControl>
                                </Box>
                            </Box>
                        </Paper>
                    </Box>
                </Box>
                <Box className={classes.chartSec}>
                    <Paper
                        square
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <Box className={classes.chartSec}>
                            <canvas id='graph1Canvas' className='graph-canvas' />
                        </Box>
                    </Paper>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default A3BarSPA;
