/* eslint-disable max-len */
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => {return {
    chartSec: {
        width: "-webkit-fill-available",
        height: "60vh",
    },
    BoxItem: {
        padding: "5px",
        fontSize: "12px",
        color: "#707070",
        display: "grid",
        gridTemplateColumns: "1fr auto auto",
        columnGap: "1rem",
        "&:nth-of-type(odd)": {
            backgroundColor: "#F3F3F3",
        },
        "&:nth-of-type(even)": {
            backgroundColor: "#E6E6E6",
        },
    },

    ProcessTopWrapper: {
        display: "grid",
        gridTemplateColumns: "1fr auto",
        alignItems: "start",
        justifyItems: "center",
    },

    SelectPopupParent: {
        width: "-webkit-fill-available",
        height: "-webkit-fill-available",
        display: 'flex',
        justifyContent: 'center',
        position: 'fixed',
        top: 0,
        left: 0,
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.54)',
        zIndex: 1300,
    },

    SelectPopupContent: {
        width: "-webkit-fill-available",
        height: "-webkit-fill-available",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        justifyContent: 'center',
        alignItems: 'center',
    },

    PaperWrapper: {
        display: "flex",
        flexDirection: "column",
        gap: "1vw",
        padding: '2vw',
        justifyContent: 'center',
        alignItems: 'center',
    },

    InnerContent: {
        margin: '1vw',
        display: 'flex',
        flexDirection: 'column',
        gap: '1vw',
        alignItems: 'center',
    },

    SelectBorderRemove: {
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
        },
    },

    BackgroundBorderRemove:{
        border: 0,
        background: 0,
    },

    PopupMessage: {
        fontSize:'1.4vw !important',
        fontWeight: 'bold !important',
        // fontFamily: 'Roboto, sans-serif !important',
        lineHeight: '1.2 !important',
        marginBottom: '1rem !important',
        textDecoration: 'underline',
    },

    PopupButtonText: {
        fontSize:'0.8vw !important',
        fontWeight: 'bold',
        fontFamily: 'Montserrat, sans-serif',
        color: 'white !important',
        width: '5vw !important',
        height: 'auto !important',
        '@media (max-width: 1300px)': {
            height: 'auto',
        },
    },

    ButtonWrap: {
        display: 'flex',
        flexDirection: 'row',
        gap: '1vw',

    },

    SubmitEditWrapper: {
        display: "flex",
        flexDirection: "column",
    },
    NameRenamer: {
        display: "flex",
        gap: "1vw",
    },
    KeepOriginalCheck: {
        display: "flex",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        gap: "1vw",
    },

    ContentBox: {
        border: '0.2vw dashed #004021',
        padding: '2vw',
        // background: 'linear-gradient(90deg, #004021 70%, transparent 50%), linear-gradient(90deg, #004021 70%, transparent 50%), linear-gradient(0deg, #004021 70%, transparent 50%), linear-gradient(0deg, #004021 70%, transparent 50%)',
        // height: '100px',
        // width: '200px',
        // backgroundRepeat: 'repeat-x, repeat-x, repeat-y, repeat-y',
        // backgroundSize: '16px 4px, 16px 4px, 4px 16px, 4px 16px',
        // backgroundPosition: '0px 0px, 212px 116px, 0px 116px, 216px 0px',
        // padding: '10px',
        // animation: 'deleteSpinner 2s infinite linear',
    },

    IconPopup: {
        color: '#004021',
        fontSize: '2.5vw !important',
    },

    PopupMessageBanner: {
        // backgroundColor: '#004021',

    },

    '@global': {
        '@keyframes deleteSpinner': {
            '0%': {
                backgroundPosition: '0px 0px, 212px 116px, 0px 116px, 216px 0px',
            },
            '100%': {
                backgroundPosition: '212px 0px, 0px 116px, 0px 0px, 216px 116px',
            },
        },
    },
}});

export default useStyles;