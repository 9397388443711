import { Stack, Button } from '@mui/material';
import { FormApi } from '@tanstack/react-form';
import React, { useState, useEffect } from 'react';
import { FormState } from '../../../forms/FormContext';
import { useNavigate } from '@tanstack/react-router';
import { submitCreateProductHandler } from '../../../../helper/productSubmit';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function CreateProductNavButtons({
    form,
    formState,
    currentPage,
}: {
  form: FormApi<any>;
  formState: FormState;
  currentPage: string;
}) {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [prevNav, setPrevNav] = useState<string>();
    const [nextNav, setNextNav] = useState<string>();
    const navigate = useNavigate();

    useEffect(() => {
        if (formState) {
            const { envDim, socialDim, ecoDim } = formState;
            setIsSubmitting(false);

            if (currentPage === 'details') {
                if (envDim) {
                    setNextNav('../environmental');
                } else if (socialDim) {
                    setNextNav('../social');
                } else if (ecoDim) {
                    setNextNav('../economic');
                } else {
                    setNextNav(undefined);
                }
            } else if (currentPage === 'environmental') {
                setPrevNav('../details');
                if (socialDim) {
                    setNextNav('../social');
                } else if (ecoDim) {
                    setNextNav('../economic');
                } else {
                    setIsSubmitting(true);
                }
            } else if (currentPage === 'social') {
                if (envDim) {
                    setPrevNav('../environmental');
                } else {
                    setPrevNav('../details');
                }
                if (ecoDim) {
                    setNextNav('../economic');
                } else {
                    setIsSubmitting(true);
                }
            } else if (currentPage === 'economic') {
                if (socialDim) {
                    setPrevNav('../social');
                } else if (envDim) {
                    setPrevNav('../environmental');
                } else {
                    setPrevNav('../details');
                }
                setIsSubmitting(true);
            }
        }
    }, [formState?.envDim, formState?.socialDim, formState?.ecoDim]);

    return (
        <Stack direction='row' spacing={2} sx={{ justifyContent: 'flex-end' }}>
            {prevNav && (
                <form.Subscribe
                    selector={(state) => {return [state.canSubmit, state.isSubmitting]}}
                    children={([canSubmit, _isSubmitting]) => {return (
                    // <button type='submit' disabled={!canSubmit}>
                    //     {isSubmitting ? '...' : 'Submit'}
                    // </button>
                        <Button
                            variant='contained'
                            type='submit'
                            disabled={!canSubmit}
                            onClick={() => {
                                form
                                    .handleSubmit()
                                    .then(() => {
                                        navigate({ to: prevNav })
                                            .then((val) => {})
                                            .catch((err) => {});
                                    })
                                    .catch(() => {});
                            }}
                            sx={{
                                height: 'auto',
                                fontSize: '0.8vw',
                                width: '5vw',
                                '@media (max-width: 1300px)': {
                                    height: 'auto',
                                },
                            }}
                        >
              Previous
                        </Button>
                    )}}
                />
            )}
            {isSubmitting ? (
                <Button
                    variant='contained'
                    type='button'
                    onClick={() => {
                        form
                            .handleSubmit()
                            .then(() => {
                                submitCreateProductHandler(formState);
                            })
                            .catch(() => {});
                        // navigate({ to: '../social' })
                        //     .then((val) => { })
                        //     .catch((err) => { });
                    }}
                    sx={{
                        height: 'auto',
                        fontSize: '0.8vw',
                        width: '5vw',
                        '@media (max-width: 1300px)': {
                            height: 'auto',
                        },
                    }}
                >
          Submit
                </Button>
            ) : (
                <form.Subscribe
                    selector={(state) => {return [state.canSubmit, state.isSubmitting]}}
                    children={([canSubmit, _isSubmitting]) => {return (
                        <Button
                            variant='contained'
                            type='submit'
                            disabled={!canSubmit && !nextNav}
                            onClick={() => {
                                form
                                    .handleSubmit()
                                    .then(() => {
                                        navigate({ to: nextNav })
                                            .then((val) => {})
                                            .catch((err) => {});
                                    })
                                    .catch(() => {});
                            }}
                            sx={{
                                height: 'auto',
                                fontSize: '0.8vw',
                                width: '5vw',
                                '@media (max-width: 1300px)': {
                                    height: 'auto',
                                },
                            }}
                        >
              Next
                        </Button>
                    )}}
                />
            )}
        </Stack>
    );
}
