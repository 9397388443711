import { ThemeProvider, createTheme } from '@mui/material/styles';
import { hover } from '@testing-library/user-event/dist/hover';
import { green } from '@mui/material/colors';
import { Padding } from '@mui/icons-material';

const AddButton = createTheme({
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                    fontSize: '1vw',
                    borderRadius: 0,
                    padding: 0,
                    color: '#707070',
                    textTransform: 'capitalize',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    '&:hover':{
                        color: '#979797',
                    },
                    //   '@media (max-width: 1680px)': {
                    //     fontSize: '14px',
                    // },
                },
            },
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    fontSize: '1.1vw',
                    color: '#166d46',
                },
            },
        },
    },
    typography: {
        fontFamily: 'Montserrat, sans-serif',
        h2: {
            fontSize: 22,
            fontWeight: 'bold',
        },
        h3: {
            fontSize: 22,
            fontWeight: 'normal',
        },
        h4: {
            fontSize: 18,
            fontWeight: 'normal',
        },
        body1: {
            fontFamily: 'Roboto, sans-serif',
            fontSize: 14,
        },
    },
});

export default AddButton;
