import React, { useEffect, useState } from "react";
import {
    Box,
    Checkbox,
    CheckboxProps,
    FormControlLabel,
    styled,
} from "@mui/material";
import { useForm } from "@tanstack/react-form";
import { useFormContext } from "../../../forms/FormContext";
import "../SDGChecklist/SDGChecklist.css";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import router from "../../../../routes";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

interface SDGChecklistForm {
  "SDG1: No Poverty": boolean;
  "SDG2: Zero Hunger": boolean;
  "SDG3: Good health and well-being": boolean;
  "SDG4: Quality education": boolean;
  "SDG5: Gender Equality": boolean;
  "SDG6: Clean Water and Sanitation": boolean;
  "SDG7: Affordable and clean energy": boolean;
  "SDG8: Decent work and economic growth": boolean;
  "SDG9: Industry, innovation and infrastructure": boolean;
  "SDG10: Reduced inequalities": boolean;
  "SDG11: Sustainable cities and communities": boolean;
  "SDG12: Responsible consumption and production": boolean;
  "SDG13: Climate action": boolean;
  "SDG14: Life below water": boolean;
  "SDG15: Life on land": boolean;
  "SDG16: Peace, justice and strong institutions": boolean;
  "SDG17: Partnerships for the goals": boolean;
}

const SDGChecklist = ({
    setSelectedSDGs,
    disabled,
    setting,
    dimension,
}: {
  disabled: boolean;
  setSelectedSDGs: React.Dispatch<React.SetStateAction<string[]>>;
  setting?: string;
  dimension?: string;
}) => {
    const form = useForm<SDGChecklistForm>({
        defaultValues: {
            "SDG1: No Poverty": true,
            "SDG2: Zero Hunger": true,
            "SDG3: Good health and well-being": true,
            "SDG4: Quality education": true,
            "SDG5: Gender Equality": true,
            "SDG6: Clean Water and Sanitation": true,
            "SDG7: Affordable and clean energy": true,
            "SDG8: Decent work and economic growth": true,
            "SDG9: Industry, innovation and infrastructure": true,
            "SDG10: Reduced inequalities": true,
            "SDG11: Sustainable cities and communities": true,
            "SDG12: Responsible consumption and production": true,
            "SDG13: Climate action": true,
            "SDG14: Life below water": true,
            "SDG15: Life on land": true,
            "SDG16: Peace, justice and strong institutions": true,
            "SDG17: Partnerships for the goals": true,
        },
        onSubmit: ({ value }) => {
            const selectedSDGs: string[] = [];
            Object.keys(value).forEach((sdg) => {
                if (value[sdg]) {
                    selectedSDGs.push(sdg);
                }
            });
            setSelectedSDGs(selectedSDGs);
        },
    });

    useEffect(() => {
        if (form) {
            form.handleSubmit();
        }
    }, [form]);

    const [dropped, setDropped] = useState(false);

    //     useEffect(() => {
    //         let hasValue = !!Object.keys(formState.inputs).find(
    //             (item) => formState.inputs[item].value === true
    //         );
    //         if (!hasValue && !disabled) {
    //             setFormData(
    //                 {
    //                     "SDG1: No Poverty": {
    //                         value: true,
    //                         isValid: true,
    //                     },
    //                     "SDG2: Zero Hunger": {
    //                         value: true,
    //                         isValid: true,
    //                     },
    //                     "SDG3: Good health and well-being": {
    //                         value: true,
    //                         isValid: true,
    //                     },
    //                     "SDG4: Quality education": {
    //                         value: true,
    //                         isValid: true,
    //                     },
    //                     "SDG5: Gender Equality": {
    //                         value: true,
    //                         isValid: true,
    //                     },
    //                     "SDG6: Clean Water and Sanitation": {
    //                         value: true,
    //                         isValid: true,
    //                     },
    //                     "SDG7: Affordable and clean energy": {
    //                         value: true,
    //                         isValid: true,
    //                     },
    //                     "SDG8: Decent work and economic growth": {
    //                         value: true,
    //                         isValid: true,
    //                     },
    //                     "SDG9: Industry, innovation and infrastructure": {
    //                         value: true,
    //                         isValid: true,
    //                     },
    //                     "SDG10: Reduced inequalities": {
    //                         value: true,
    //                         isValid: true,
    //                     },
    //                     "SDG11: Sustainable cities and communities": {
    //                         value: true,
    //                         isValid: true,
    //                     },
    //                     "SDG12: Responsible consumption and production": {
    //                         value: true,
    //                         isValid: true,
    //                     },
    //                     "SDG13: Climate action": {
    //                         value: true,
    //                         isValid: true,
    //                     },
    //                     "SDG14: Life below water": {
    //                         value: true,
    //                         isValid: true,
    //                     },
    //                     "SDG15: Life on land": {
    //                         value: true,
    //                         isValid: true,
    //                     },
    //                     "SDG16: Peace, justice and strong institutions": {
    //                         value: true,
    //                         isValid: true,
    //                     },
    //                     "SDG17: Partnerships for the goals": {
    //                         value: true,
    //                         isValid: true,
    //                     },
    //                 },
    //                 true
    //             );

    //             Object.keys(formState.inputs).forEach((item) => {
    //                 let checkbox = document.getElementById(item);
    //                 if (checkbox) {
    //                     checkbox.checked = true;
    //                 }
    //             });
    //         }
    //     }, [disabled]);

    useEffect(() => {
        if (disabled) {
            setDropped(false);
        }
    }, [disabled]);

    // const CheckboxPadding = styled(CheckBoxIcon)<CheckboxProps>(({ theme }) => {
    //     return {
    //         marginLeft: 0,
    //         marginRight: 0,
    //         "& .MuiSvgIcon-root": {
    //             width: "1em",
    //             height: "1em",
    //             fontSize: "1.2vw",

    //         },
    //     };
    // });

    const navigateToSDG = () => {
        const path = `../../../../${dimension}/${setting}/Bar`;
        router
            .navigate({
                to: path,
            })
            .then((val) => {})
            .catch((err) => {});
    };

    return (
        <div className="sdg-linkage-box">
            <div className="listed-input sdg-link-checkbox">
                {/* <Input
                    id="sdgLink"
                    element="input"
                    type="checkbox"
                    validators={[]}
                    onInput={props.inputHandler}
                    defaultChecked={props.resultFormState.inputs.sdgLink.value}
                /> */}
                {/* <Checkbox
                /> */}
                <FormControlLabel
                    control={
                        <CheckBoxIcon
                            onClick={navigateToSDG}
                            sx={{
                                padding: 0,
                                marginLeft: 0,
                                marginRight: 0,
                                "& .MuiSvgIcon-root": {
                                    width: "1em",
                                    height: "1em",
                                    fontSize: "1.2vw",
                                    color: "green",
                                },
                            }}
                        />
                    }
                    sx={{
                        padding: 0,
                        marginLeft: 0,
                        marginRight: 0,
                    }}
                    label=""
                />
                <h2
                    className="sdg-linkage__dropdown"
                    style={disabled ? { opacity: "30%", fontWeight: "500" } : {}}
                    onClick={() => {
                        if (!disabled) {
                            setDropped(!dropped);
                        }
                    }}
                >
          SDG Linkage <i className={`arrow ${dropped ? "up" : "down"}`}></i>
                </h2>
                <Box>
                    {dropped ? (
                        <ArrowDropUpIcon sx={{ fontSize: "1.2vw", paddingTop: "0.3vh" }} />
                    ) : (
                        <ArrowDropDownIcon
                            sx={{ fontSize: "1.2vw", paddingTop: "0.3vh" }}
                        />
                    )}
                </Box>
            </div>
            <div
                className="sdg-checklist__content"
                style={disabled || !dropped ? { display: "none" } : {}}
            >
                <div className="listed-input sdg-checklist__listitem">
                    <form.Field
                        name="SDG1: No Poverty"
                        children={(field) => {
                            return (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id={field.name}
                                            checked={field.state.value}
                                            onChange={(e) => {
                                                field.handleChange(Boolean(e.target.checked));
                                                form.handleSubmit();
                                            }}
                                        />
                                    }
                                    label="SDG1: No Poverty"
                                />
                            );
                        }}
                    />
                </div>
                <div className="listed-input sdg-checklist__listitem">
                    <form.Field
                        name="SDG2: Zero Hunger"
                        children={(field) => {
                            return (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id={field.name}
                                            checked={field.state.value}
                                            onChange={(e) => {
                                                field.handleChange(Boolean(e.target.checked));
                                                form.handleSubmit();
                                            }}
                                        />
                                    }
                                    label="SDG2: Zero Hunger"
                                />
                            );
                        }}
                    />
                </div>
                <div className="listed-input sdg-checklist__listitem">
                    <form.Field
                        name="SDG3: Good health and well-being"
                        children={(field) => {
                            return (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id={field.name}
                                            checked={field.state.value}
                                            onChange={(e) => {
                                                field.handleChange(Boolean(e.target.checked));
                                                form.handleSubmit();
                                            }}
                                        />
                                    }
                                    label="SDG3: Good health and well-being"
                                />
                            );
                        }}
                    />
                </div>
                <div className="listed-input sdg-checklist__listitem">
                    <form.Field
                        name="SDG4: Quality education"
                        children={(field) => {
                            return (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id={field.name}
                                            checked={field.state.value}
                                            onChange={(e) => {
                                                field.handleChange(Boolean(e.target.checked));
                                                form.handleSubmit();
                                            }}
                                        />
                                    }
                                    label="SDG4: Quality education"
                                />
                            );
                        }}
                    />
                </div>
                <div className="listed-input sdg-checklist__listitem">
                    <form.Field
                        name="SDG5: Gender Equality"
                        children={(field) => {
                            return (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id={field.name}
                                            checked={field.state.value}
                                            onChange={(e) => {
                                                field.handleChange(Boolean(e.target.checked));
                                                form.handleSubmit();
                                            }}
                                        />
                                    }
                                    label="SDG5: Gender Equality"
                                />
                            );
                        }}
                    />
                </div>
                <div className="listed-input sdg-checklist__listitem">
                    <form.Field
                        name="SDG6: Clean Water and Sanitation"
                        children={(field) => {
                            return (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id={field.name}
                                            checked={field.state.value}
                                            onChange={(e) => {
                                                field.handleChange(Boolean(e.target.checked));
                                                form.handleSubmit();
                                            }}
                                        />
                                    }
                                    label="SDG6: Clean Water and Sanitation"
                                />
                            );
                        }}
                    />
                </div>
                <div className="listed-input sdg-checklist__listitem">
                    <form.Field
                        name="SDG7: Affordable and clean energy"
                        children={(field) => {
                            return (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id={field.name}
                                            checked={field.state.value}
                                            onChange={(e) => {
                                                field.handleChange(Boolean(e.target.checked));
                                                form.handleSubmit();
                                            }}
                                        />
                                    }
                                    label="SDG7: Affordable and clean energy"
                                />
                            );
                        }}
                    />
                </div>
                <div className="listed-input sdg-checklist__listitem">
                    <form.Field
                        name="SDG8: Decent work and economic growth"
                        children={(field) => {
                            return (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id={field.name}
                                            checked={field.state.value}
                                            onChange={(e) => {
                                                field.handleChange(Boolean(e.target.checked));
                                                form.handleSubmit();
                                            }}
                                        />
                                    }
                                    label="SDG8: Decent work and economic growth"
                                />
                            );
                        }}
                    />{" "}
                </div>
                <div className="listed-input sdg-checklist__listitem">
                    <form.Field
                        name="SDG9: Industry, innovation and infrastructure"
                        children={(field) => {
                            return (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id={field.name}
                                            checked={field.state.value}
                                            onChange={(e) => {
                                                field.handleChange(Boolean(e.target.checked));
                                                form.handleSubmit();
                                            }}
                                        />
                                    }
                                    label="SDG9: Industry, innovation and infrastructure"
                                />
                            );
                        }}
                    />{" "}
                </div>
                <div className="listed-input sdg-checklist__listitem">
                    <form.Field
                        name="SDG10: Reduced inequalities"
                        children={(field) => {
                            return (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id={field.name}
                                            checked={field.state.value}
                                            onChange={(e) => {
                                                field.handleChange(Boolean(e.target.checked));
                                                form.handleSubmit();
                                            }}
                                        />
                                    }
                                    label="SDG10: Reduced inequalities"
                                />
                            );
                        }}
                    />{" "}
                </div>
                <div className="listed-input sdg-checklist__listitem">
                    <form.Field
                        name="SDG11: Sustainable cities and communities"
                        children={(field) => {
                            return (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id={field.name}
                                            checked={field.state.value}
                                            onChange={(e) => {
                                                field.handleChange(Boolean(e.target.checked));
                                                form.handleSubmit();
                                            }}
                                        />
                                    }
                                    label="SDG11: Sustainable cities and communities"
                                />
                            );
                        }}
                    />{" "}
                </div>
                <div className="listed-input sdg-checklist__listitem">
                    <form.Field
                        name="SDG12: Responsible consumption and production"
                        children={(field) => {
                            return (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id={field.name}
                                            checked={field.state.value}
                                            onChange={(e) => {
                                                field.handleChange(Boolean(e.target.checked));
                                                form.handleSubmit();
                                            }}
                                        />
                                    }
                                    label="SDG12: Responsible consumption and production"
                                />
                            );
                        }}
                    />{" "}
                </div>
                <div className="listed-input sdg-checklist__listitem">
                    <form.Field
                        name="SDG13: Climate action"
                        children={(field) => {
                            return (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id={field.name}
                                            checked={field.state.value}
                                            onChange={(e) => {
                                                field.handleChange(Boolean(e.target.checked));
                                                form.handleSubmit();
                                            }}
                                        />
                                    }
                                    label="SDG13: Climate action"
                                />
                            );
                        }}
                    />{" "}
                </div>
                <div className="listed-input sdg-checklist__listitem">
                    <form.Field
                        name="SDG14: Life below water"
                        children={(field) => {
                            return (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id={field.name}
                                            checked={field.state.value}
                                            onChange={(e) => {
                                                field.handleChange(Boolean(e.target.checked));
                                                form.handleSubmit();
                                            }}
                                        />
                                    }
                                    label="SDG14: Life below water"
                                />
                            );
                        }}
                    />{" "}
                </div>
                <div className="listed-input sdg-checklist__listitem">
                    <form.Field
                        name="SDG15: Life on land"
                        children={(field) => {
                            return (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id={field.name}
                                            checked={field.state.value}
                                            onChange={(e) => {
                                                field.handleChange(Boolean(e.target.checked));
                                                form.handleSubmit();
                                            }}
                                        />
                                    }
                                    label="SDG15: Life on land"
                                />
                            );
                        }}
                    />{" "}
                </div>
                <div className="listed-input sdg-checklist__listitem">
                    <form.Field
                        name="SDG16: Peace, justice and strong institutions"
                        children={(field) => {
                            return (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id={field.name}
                                            checked={field.state.value}
                                            onChange={(e) => {
                                                field.handleChange(Boolean(e.target.checked));
                                                form.handleSubmit();
                                            }}
                                        />
                                    }
                                    label="SDG16: Peace, justice and strong institutions"
                                />
                            );
                        }}
                    />{" "}
                </div>
                <div className="listed-input sdg-checklist__listitem">
                    <form.Field
                        name="SDG4: Quality education"
                        children={(field) => {
                            return (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id={field.name}
                                            checked={field.state.value}
                                            onChange={(e) => {
                                                field.handleChange(Boolean(e.target.checked));
                                                form.handleSubmit();
                                            }}
                                        />
                                    }
                                    label="SDG17: Partnerships for the goals"
                                />
                            );
                        }}
                    />{" "}
                </div>
            </div>
        </div>
    );
};

export default SDGChecklist;
