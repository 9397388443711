export const processColors = [
    "#D94A4A", 
    "#D86E4B", 
    "#E5B800", 
    "#4F8A63", 
    "#5033A6", 
    "#990066", 
    "#2164B5", 
    "#8B4533", 
    "#666666", 
    "#C23A5C", 
    "#C06B26", 
    "#D1C000", 
    "#007D5A", 
    "#275E93", 
    "#5B00B3", 
    "#813C81", 
    "#7A3810", 
    "#5A5A5A", 
    "#A7323D", 
    "#B55C2D", 
    "#B08E00", 
    "#3E9046", 
    "#2D7298", 
    "#8246A1", 
    "#835C85", 
    "#694029", 
    "#4D4D4D", 
    "#8F3636", 
    "#874102", 
    "#947000", 
    "#216F6F", 
    "#1F4B70", 
    "#54077B", 
    "#624074", 
    "#5C3310", 
    "#404040", 
    "#742A44", 
    "#6A3C10", 
    "#7A5E00", 
    "#2C7436", 
    "#174684", 
    "#4C0364", 
    "#333333", 
    "#4A2C0A",
    "#5A347B",  
    "#40594C",  
    "#8C4F49", 
    "#6F8B2A", 
    "#3B5A92",
];

export const socialProcessColors = [
    '#A32C33', 
    '#4F7941', 
    '#C1472A', 
    '#2B7163', 
    '#8F511D', 
    '#164374', 
    '#3B6430', 
    '#1B537F', 
    '#5E8B61', 
    '#A5292A', 
    '#1B6145', 
    '#BD642B', 
    '#2E4E1E', 
    '#7A4710', 
    '#2A7D5B', 
    '#C24559', 
    '#A46D3B', 
    '#28876B', 
    '#948836', 
    '#B59950', 
    '#9F3357', 
    '#42793B', 
    '#89531B', 
    '#63376D', 
    '#18506C', 
    '#4E623E', 
    '#BF5F42', 
    '#4D7A72', 
    '#366B64', 
    '#9E3F40', 
    '#2F547C', 
    '#A06E4A', 
    '#843D61', 
    '#4A6530', 
    '#1E6178', 
    '#403F7C', 
    '#346657', 
    '#B94C37', 
    '#907632', 
    '#164264',
];


export const lifeCycleColors = [
    "#1B5D82",
    "#601F00",
    "#E14243",
    "#F3B76F",
    "#8BC5E5",
    "#841744",
    "#31855E",
    "#1A5B3D",
    "#98238C",
    "#4F9DC9",
    "#FF9BC4",
    "#1F76A7",
    "#DF89D6",
    "#BC2C2D",
    "#911010",
    "#C8812B",
];
export const sublifeCycleColors = [
    "#FF9C6E",
    "#841744",
    "#31855E",
    "#1A5B3D",
    "#98238C",
    "#4F9DC9",
    "#FF9BC4",
    "#1F76A7",
    "#DF89D6",
    "#BC2C2D",
    "#911010",
    "#C8812B",
    "#8D2282",
    "#1B5D82",
    "#fc6924",
    "#FF9797",
    "#E971A2",
];

export const elementaryColors = [
    // "#5252ff",
    // "#841744",
    // "#50af99",
    // "#345728",
    // "#b34019",
    // "#182734",
    // "#59330d",
    // "#e6734d",
    // "#ff5c5c",
    // "#96cfc1",
    // "#7dba69",
    // "#d7a8cb",
    // "#efa78f",
    // "#5252ff",
    // "#750b0b",
    // "#46203c",
    // "#ff6666",
    "#D94A4A", 
    "#D86E4B", 
    "#E5B800", 
    "#4F8A63", 
    "#5033A6", 
    "#990066", 
    "#2164B5", 
    "#8B4533", 
    "#666666", 
    "#C23A5C", 
    "#C06B26", 
    "#D1C000", 
    "#007D5A", 
    "#275E93", 
    "#5B00B3", 
    "#813C81", 
    "#7A3810", 
    "#5A5A5A", 
    "#A7323D", 
    "#B55C2D", 
    "#B08E00", 
    "#3E9046", 
    "#2D7298", 
    "#8246A1", 
    "#835C85", 
    "#694029", 
    "#4D4D4D", 
    "#8F3636", 
    "#874102", 
    "#947000", 
    "#216F6F", 
    "#1F4B70", 
    "#54077B", 
    "#624074", 
    "#5C3310", 
    "#404040", 
    "#742A44", 
    "#6A3C10", 
    "#7A5E00", 
    "#2C7436", 
    "#174684", 
    "#4C0364", 
    "#333333", 
    "#4A2C0A",
    "#5A347B",  
    "#40594C",  
    "#8C4F49", 
    "#6F8B2A", 
    "#3B5A92",
];

export const palette1 = [
    "#F4A8A8",
    "#F6B473",
    "#EFE77C",
    "#9ED7A8",
    "#7FC2F0",
    "#B199F4",
    "#F0A4DF",
    "#D0AA85",
    "#CCCCCC",
    "#F791AE",
    "#F4AB6E",
    "#F3DD78",
    "#A2E2C5",
    "#94D2F2",
    "#C094F4",
    "#E89AC6",
    "#C79B73",
    "#BFBFBF",
    "#F27878",
    "#E69355",
    "#EFD36D",
    "#97D495",
    "#85B9E5",
    "#B88FD3",
    "#DAA3D2",
    "#CEAE8B",
    "#CFCFCF",
    "#D97A7A",
    "#D98D5D",
    "#DAC967",
    "#75C2C2",
    "#A1C4DF",
    "#D98DD9",
    "#E2ACE2",
    "#CBAF93",
    "#D8D8D8",
    "#E38E8E",
    "#D18E69",
    "#E4D46D",
    "#A5D4A5",
    "#87B8D4",
    "#C19AC1",
    "#AFAFAF",
    "#CAB89C",    
];


export const palette2 = [
    "#6b2525",
    "#4b0029",
    "#356856",
    "#5a2a6a",
    "#1b6767",
    "#5e0000",
    "#2c3480",
    "#87544c",
    "#0b4232",
    "#3cae88",
    "#324e1e",
    "#738f45",
    "#2d5e3b",
    "#0d6647",
    "#5b8350",
    "#445b92",
    "#2b738a",
    "#a65030",
    "#5c6f1b",
    "#7a1745",
    "#2e335a",
    "#7d3c6e",
    "#2d2428",
    "#432b66",
    "#0b334f",
    "#4a3c1f",
    "#346c6f",
    "#720c3b",
    "#561e23",
    "#006f8f",
    "#1a1e70",
    "#4e607d",
    "#5a295a",
    "#27523b",
    "#8b6530",
    "#6c4329",
    "#003366",
    "#4d0077",
    "#561429",
    "#2d4f5a",
    "#3b2948",
    "#79233f",
    "#142238",
    "#4e2b0f",
    "#144839",
    "#61382c",
    "#744f68",
    "#344861",
];


export const palette3 = [
    '#D33434', // Red
    '#D35934', // Orange
    '#CC791E', // Brownish Orange
    '#BB663A', // Brownish Red
    '#CBA500', // Yellow
    '#9C7A00', // Dark Yellow
    '#7A9300', // Greenish Yellow
    '#BAC700', // Bright Greenish Yellow
    '#A75918', // Brown
    '#7C5F2C', // Dark Brown
    '#664A2A', // Brown
    '#9AA300', // Yellowish Green
    '#7E8500', // Olive
    '#A81414', // Dark Red
    '#832E2E', // Darker Red
    '#6E0000', // Very Dark Red
    '#502E2E', // Dark Brownish Red
    '#321F1F', // Very Dark Brown
    '#462320', // Dark Brownish Red
    '#6E4333', // Brown
    '#5E7300', // Greenish Yellow
    '#516200', // Greenish Yellow
    '#BAC700', // Yellowish Green
    '#5A4909', // Dark Brownish Yellow
    '#2D1512', // Dark Brownish Red
    '#4C5300', // Dark Olive
];

export const palette4 = [
    '#0B406D', // Dark Blue
    '#095D72', // Blue
    '#065248', // Greenish Blue
    '#023A42', // Dark Greenish Blue
    '#2C7F3B', // Green
    '#214D10', // Dark Green
    '#2E5741', // Darker Green
    '#3D3D3D', // Gray
    '#2E2E2E', // Dark Gray
    '#1F1F1F', // Very Dark Gray
    '#1B1B1B', // Darkest Gray
    '#5A3E9A', // Purple
    '#493785', // Dark Purple
    '#75648C', // Muted Purple
    '#4A7B75', // Muted Greenish Blue
    '#608E57', // Green
    '#4A7742', // Dark Greenish Blue
    '#486B3F', // Dark Green
    '#3E5D73', // Muted Blue
    '#54495B', // Muted Purple
    '#547D49', // Muted Green
    '#7B3658', // Muted Pink
    '#92547A', // Pinkish Purple
    '#653758', // Dark Pinkish Purple
    '#808068', // Grayish Green
    '#4D6D84', // Muted Blue
    '#402237', // Dark Purple
    '#51243B', // Dark Pinkish Red
    '#608E57', // Green
    '#373C39', // Dark Grayish Green
];



export const socialProcessColorsTransparent = [
    "rgb(243,107,107, 0.3)",
    "rgb(127,183,126, 0.3)",
    "rgb(225,66,67, 0.3)",
    "rgb(134,198,169, 0.3)",
    "#C8812B",
    "#1B5D82",
    "#436B39",
    "#1F76A7",
    "#B1D7B4",
    "#BC2C2D",
    "#1A5B3D",
    "#EB9E43",
    "#234C0D",
    "#925409",
    "#31855E",
    "#FF9797",
    "#F3B76F",
    "#41BE84",
    "#F5C48A",
    "#E6C96E",
];
export const palette2transparent = [
    "rgb(255,64,0, 0.3)",
    "rgb(0,255,128, 0.3)",
    "rgb(191,0,255, 0.3)",
    "rgb(64,255,0, 0.3)",
    "#ffff00",
    "#ffbf00",
    "#0000ff",
    "#00ff40",
    "#8000ff",
    "#ff0000",
    "#4000ff",
    "#80ff00",
    "#ff0040",
    "#00ffff",
    "#0040ff",
    "#ff0080",
    "#0080ff",
    "#ff00bf",
    "#00ffbf",
    "#ff8000",
    "#bfff00",
    "#ff00ff",
    "#00bfff",
    "#00ff00",
];
export const palette3transparent = [
    "rgb(216,0,50, 0.3)",
    "rgb(241,235,144, 0.3)",
    "rgb(225,199,143, 0.3)",
    "rgb(190,49,68, 0.3)",
    "#3D0C11",
    "#662549",
    "#B0926A",
    "#F78CA2",
    "#22092C",
    "#6C5F5B",
    "#451952",
    "#952323",
    "#A73121",
    "#AE445A",
    "#FAE7C9",
    "#9FBB73",
    "#CE5A67",
    "#F4BF96",
    "#1F1717",
    "#706233",
    "#F9DEC9",
    "#4F4A45",
    "#F6F1EE",
    "#F2E8C6",
    "#FCF5ED",
    "#ED7D31",
    "#872341",
    "#F05941",
    "#DAD4B5",
    "#F39F5A",
    "#F3B664",
    "#EC8F5E",
];
export const palette4transparent = [
    "rgb(15,15,15, 0.3)",
    "rgb(205,245,253, 0.3)",
    "rgb(26,172,172, 0.3)",
    "rgb(137,207,243, 0.3)",
    "#80B3FF",
    "#C2D9FF",
    "#362FD9",
    "#7752FE",
    "#008170",
    "#363062",
    "#190482",
    "#8E8FFA",
    "#818FB4",
    "#64CCC5",
    "#DDF2FD",
    "#005B41",
    "#687EFF",
    "#232D3F",
    "#B6FFFA",
    "#427D9D",
    "#176B87",
    "#A0E9FF",
    "#2E97A7",
    "#F5E8C7",
    "#164863",
    "#00A9FF",
    "#EEEEEE",
    "#04364A",
    "#9BBEC8",
    "#DAFFFB",
    "#98E4FF",
    "#435585",
];

export const treemapRGB: string[] = [
    // '#e6e6e6',
    "#e34f4f",
    "#379569",
    "#2897d7",
    "#eba147",
    "#d775e0",
    "#ff7043", 
];

export const sdg17Colors = [
    "#e5253a",
    "#dda63a",
    "#4c9e39",
    "#c5192d",
    "#fe3b21",
    "#27bce2",
    "#fdc20b",
    "#a31842",
    "#fd6824",
    "#dc1366",
    "#fd9d24",
    "#be8a2e",
    "#3f7e44",
    "#0b96d9",
    "#57c12b",
    "#00689d",
    "#18486b",
];

export const productPerformanceColor = ["#1B5D82", "#1B5D82"];

export const sdgColor = ["#6ADBD7", "#a3a3a3", "#e1dedb"];

export const getColorsForStakeholders = (labels: string[]) => {
    labels = labels.map((label) => {
        const defaultColor = "#cccccc";
        const [vcaColor, workersColor, societyColor, lcColor, consumersColor, childrenColor] = treemapRGB;
        switch (label) {
        case "Value Chain Actors":
            return vcaColor;
        case "Workers":
            return workersColor;
        case "Society":
            return societyColor;
        case "Local Community":
            return lcColor;
        case "Consumers":
            return consumersColor;
        case "Children":
            return childrenColor;
    
        default:
            return defaultColor;
        }

        return defaultColor;
    });
    return labels;
};
