import React, { useState } from 'react';
import useStyles from './ContributionTree.styles';
import { useHttpClient } from '../../hooks/http-hook';

function ContributionTree() {
    interface Data {
        id: number;
        owner: number;
        flow: number;
        unit: {
            id: string;
            name: string;
            conversionFactor: number;
            unitGroup: string;
            dbID: number;
        };
        isInput: boolean;
        flowPropertyFactor: number;
        resultingAmountValue: number;
        costValue: number;
        flowType: string;
        defaultProviderId: null | string;
    }
    const classes = useStyles();

    const {
        isLoading, error, sendRequest, clearError,
    } = useHttpClient();
    const [responseData, setResponseData] = useState<Data[]>([]); // State to store the fetched data

    const onSubmitHandler: React.FormEventHandler<HTMLDivElement> = (event) => {
        event.preventDefault();
        const pid = (((event.target as HTMLFormElement).elements[0]) as HTMLInputElement).value;
        console.log(process.env);
        return event;
    };

    return (
        <div className={classes.contTree} onSubmit={onSubmitHandler}>
            <form>
                <input id="processId" type="text" />
                <button type="submit">Submit</button>
            </form>
        </div>
    );
}

export default ContributionTree;
