/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable react/function-component-definition */
import React, { useEffect, useState } from 'react';
import { Container } from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled, alpha } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import GeneralTheme from '../../../style/GeneralTheme';
import { ThemeProvider } from '@emotion/react';
import FormControl from '@mui/material/FormControl';
import SelectSocialProcessPopup from '../components/SelectSocialProcessPopup';
import SocialInputProcessList from './SocialInputProcessList';
import Dropdown from '../../../dropdowns/Dropdown';
import { useNavigate } from '@tanstack/react-router';
import { FormApi } from '@tanstack/react-form';
import { SocialInputsForm } from '../../../../interface/Form';

const SocialPSILCAInputs = ({ socialForm, lifeCycleStage }: { socialForm: FormApi<SocialInputsForm>, lifeCycleStage: string }) => {
    const navigate = useNavigate();

    const BoxWrapper = styled(Box)(({ theme }) => {return {
        display: 'flex',
        flexDirection: 'column',
        gap: '1vw',
        width: '-webkit-fill-available',
    }});

    const BoxInputs = styled(Box)(({ theme }) => {return {
        display: 'flex',
        gap: '1vw',
        width: '-webkit-fill-available',
    }});

    const PaperSpacing = styled(Paper)(({ theme }) => {return {
        display: 'flex',
        gap: '1vw',
        flexDirection: 'column',
        width: '-webkit-fill-available',
        justifyContent: 'space-between',
    }});

    return (
        <ThemeProvider theme={GeneralTheme}>
            <Box
                sx={{
                    width: '-webkit-fill-available',
                    height: '56vh',
                }}
            >
                <PaperSpacing
                    square
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        width: '-webkit-fill-available',
                        height: '-webkit-fill-available',
                    }}
                >
                    <Typography component="h2" variant="h2">
                        Inputs
                    </Typography>
                    <SocialInputProcessList socialForm={socialForm} lifeCycleStage={lifeCycleStage} />
                </PaperSpacing>
            </Box>
        </ThemeProvider>
    );
};

export default SocialPSILCAInputs;
