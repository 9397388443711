const autoRound = (value: number, decimalPlaces: number) => {
    // Rounds a number to minimum decimal places before the number turns into 0 + additional decimal places if provided
    let roundedValue = value;
    let roundValue = 100;
    let counter = 0;
    if (!decimalPlaces) {
        decimalPlaces = 2;
    }
    let antiLoop = 0;
    const sign = Math.sign(value);
    if (value !== 0) {
        do {
            roundedValue = Math.round(value * roundValue) / roundValue;
            roundValue *= 10;
            antiLoop++;
            if (sign > 0) {
                if (roundedValue > 0) {
                    counter++;
                }
            } else if (sign < 0) {
                if (roundedValue < 0) {
                    counter++;
                }
            }
        } while (counter < decimalPlaces - 1 && antiLoop < 100);

        return roundedValue;
    }
    return value;
};

export default autoRound;
