import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import Paper from "@mui/material/Paper";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import useStyles from "./C4SunburstSPA.styles";
import { Bar } from "react-chartjs-2";
// import Loader from '../../components/loader';
import toast from "react-hot-toast";
import deepClone from "../../../../../../helper/deepClone";
import {
    ChartJSData,
    ChartJSOptions,
} from "../../../../../../interface/ChartData";
import SPAChartNavigation from "../../../../../dropdowns/BaseOptions/SPAChartNavigation";
import ResultsTheme from "../../../../style/ResultsTheme";
import { initialOptions } from "../../../../options/InitialOptionValues";
import ChartDownload from "../../../../options/components/ChartDownload";
import ProductComparison from "../../../../options/components/Comparison";
import GraphSetting from "../../../../options/components/GraphSettings";
import { applyValueType } from "../../../../options/util/applyOptions";
import { CreateChartTS } from "../../../../util/CreateChartTS";
import {
    SPAPSILCAPriorityResult,
    Product,
    SPALCSProcessResult,
    SPALifeCycleResult,
    SPAResult,
    SPAStakeholderResult,
    SPAScore,
} from "../../../../../../interface/Product";
import Dropdown from "../../../../../dropdowns/Dropdown";
import {
    palette1,
    palette2,
    palette3,
    palette4,
    productPerformanceColor,
    socialProcessColors,
} from "../../../../../../helper/colors";
import { useFormContext } from "../../../../../forms/FormContext";
import {
    WeighingSet,
    applyWeight,
    weighingMethods,
} from "../../../../../../helper/weighingMethod";
import LCSDropdowns from "../../../../../dropdowns/LCSDropdowns";
import Sunburst from "sunburst-chart";
import SDGChecklist from "../../../../components/SDGChecklist";
import { colors } from "@mui/material";
import { fetchSocialSDGs } from "../../../../../../helper/fetchUtil";
import { SDG, SDGCorrelation } from "../../../../../../interface/SDG";
import { SDGCategory, SDGResult } from "../../../../sdgtable";
import GraphType from "../../../../options/components/GraphTypeOptions";



interface ChartData {
    product: Product;
}

interface SunburstData {
    name: string,
    children: { name: string, children: { name: string, value: number }[] }[]
}


function C4SDGSunburstSPA(props: ChartData) {
    const { formState, setFormState } = useFormContext();
    const classes = useStyles();
    const { product } = props;
    const [chartJSData, setChartJSData] = useState<ChartJSData>({
        datasets: [],
        labels: [],
    });
    const [chartOptions, setChartOptions] = useState<ChartJSOptions>(initialOptions);
    const [colorIdentifiers, setColorIdentifiers] = useState<ChartColorIdentifiers[]>([]);
    const [selectedValueType, setSelectedValueType] = useState<string>("");
    const [stakeholderOptions, setStakeholderOptions] = useState<string[]>([]);
    const [currentWeighingSet, setCurrentWeighingSet] = useState<WeighingSet>();
    const [selectedLCS, setSelectedLCS] = useState<string>("");
    const [selectedLCSS, setSelectedLCSS] = useState<string>("");
    const [selectedProcess, setSelectedProcess] = useState<string>("");
    const [results, setResults] = useState<SPAResult>();
    const [loadedSDGs, setLoadedSDGs] = useState<SDG[]>([]);
    const [selectedSDGs, setSelectedSDGs] = useState<string[]>([]);
    const [selectableSDGs, setSelectableSDGs] = useState<string[]>([]);
    const [selectedSDG, setSelectedSDG] = useState("");
    const [currentSDGs, setCurrentSDGs] = useState<SDGResult[]>([]);
    const sunburstRef = useRef<HTMLDivElement>(null);
    const [sunburstData, setSunburstData] = useState<SunburstData>();
    const [debounceTimeout, setDebounceTimeout] = useState<number | null>(null); // Add debounce timeout

    useMemo(() => {
        const calc = product.calculations.find((item) => {
            return item.calculationType === "SOCIAL-SPA";
        });
        const calcResults = calc?.calculationResults as unknown as SPAResult;

        setResults(calcResults);
        const weighingMethod = weighingMethods.find(
            (method) => method.name === product.socialData.weighingMethod.name
        );
        const weighingSet = weighingMethod?.sets.find(
            (set) => set.name === product.socialData.weighingMethod.set
        );
        setCurrentWeighingSet(weighingSet);
        setStakeholderOptions(
            ["All Stakeholders"].concat(
                calcResults.stakeholderResults.map((item) => {
                    return item.name;
                })
            )
        );
        try {
            fetchSocialSDGs()
                .then((sdgs) => {
                    setSelectableSDGs(sdgs.map((sdg) => sdg.name));
                    setLoadedSDGs(sdgs);
                })
                .catch();
        } catch (err) {
            console.log(err);
        }
    }, [product]);

    const findColorPaletteByName = (name: string): string[] => {
        const palettes = {
            "Performance Colors": productPerformanceColor,
            Pastel: palette1,
            Dark: palette2,
            Warm: palette3,
            Cold: palette4,
        };
        return palettes[name] || palettes["Performance Colors"];
    };

    const generateExtendedPalette = (
        baseColors: string[],
        totalColors: number
    ): string[] => {
        const extendedPalette = [...baseColors];
        while (extendedPalette.length < totalColors) {
            baseColors.forEach((color) => {
                const newColor = adjustColor(color, extendedPalette.length / totalColors);
                extendedPalette.push(newColor);
                if (extendedPalette.length >= totalColors) return;
            });
        }
        return extendedPalette.slice(0, totalColors);
    };

    const adjustColor = (color: string, factor: number): string => {
        const f = parseInt(color.slice(1), 16),
            t = factor < 0 ? 0 : 255,
            p = factor < 0 ? factor * -1 : factor,
            R = f >> 16,
            G = (f >> 8) & 0x00ff,
            B = f & 0x0000ff;
        return (
            "#" +
            (
                0x1000000 +
                (Math.round((t - R) * p) + R) * 0x10000 +
                (Math.round((t - G) * p) + G) * 0x100 +
                (Math.round((t - B) * p) + B)
            )
                .toString(16)
                .slice(1)
        );
    };



    useEffect(() => {
        if (!results || loadedSDGs.length === 0 && !sunburstRef.current) return;

        const chartData: ChartJSData = { datasets: [], labels: [] };
        const options: ChartJSOptions = deepClone<ChartJSOptions>(initialOptions);
        const chartColorIdentifiers : ChartColorIdentifiers[] = [];

        if (options.scales && options.scales.y && options.scales.y.title) {
            options.scales.y.title.text = "Medium Risk Hours";
        }

        console.log(results);
        const spaLifeCycle = results.lifeCycleResults.find(
            (lcs) => lcs.lcsName === `${selectedLCS}:${selectedLCSS}`
        );

        let sdgs: SDGResult[] = [];
        if (spaLifeCycle) {
            const process = spaLifeCycle.processResults.find(
                (process) => process.processName === selectedProcess.split(" - ")[0]
            );
            if (process && selectedSDGs.length > 0) {
                sdgs = selectedSDGs.map((sdg) => {
                    return {
                        name: sdg,
                        categories: process.PSILCAResults.reduce(
                            (array: SDGCategory[], result) => {
                                const category = loadedSDGs
                                    .find((item) => item.name === sdg)
                                    ?.categories.find(
                                        (item) =>
                                            item.categoryName.toLowerCase() ===
                                            result.category.toLowerCase()
                                    );

                                if (category) {
                                    array.push({
                                        name: result.category,
                                        val: result.score,
                                       
                                        unit: "No Unit",
                                        correlation: category.correlation,
                                    });
                                }

                                return array;
                            },
                            []
                        ).sort((a, b) => {
                            let val = a.name
                                .split(":")[0]
                                .localeCompare(b.name.split(":")[0]);
                            if (val === 0) {
                                val = a.name.split(":")[1].localeCompare(b.name.split(":")[1]);
                            }

                            return val;
                        }),
                    };
                });
                setCurrentSDGs(sdgs);
            }
        } else {
            if (selectedSDGs.length > 0) {
                const stakeholderResults = results.stakeholderResults.reduce(
                    (items: SPAScore[], item) => {
                        return items.concat(item.SPAResults);
                    },
                    []
                );

                sdgs = selectedSDGs.map((sdg) => {
                    return {
                        name: sdg,
                        categories: stakeholderResults
                            .reduce((array: SDGCategory[], result) => {
                                const category = loadedSDGs
                                    .find((item) => item.name === sdg)
                                    ?.categories.find(
                                        (item) =>
                                            item.categoryName.toLowerCase() ===
                                            result.category.toLowerCase()
                                    );

                                if (category && result.score) {
                                    array.push({
                                        name: result.category,
                                        val: result.score,
                                        unit: "NoUnit",
                                        correlation: category.correlation,
                                    });
                                }

                                return array;
                            }, [])
                            .sort((a, b) => {
                                let val = a.name
                                    .split(":")[0]
                                    .localeCompare(b.name.split(":")[0]);
                                if (val === 0) {
                                    val = a.name
                                        .split(":")[1]
                                        .localeCompare(b.name.split(":")[1]);
                                }

                                return val;
                            }),
                    };
                });
                setCurrentSDGs(sdgs);
            }
        }

        let sdg: SDGResult | undefined;
        if (selectedSDG) {
            sdg = sdgs.find((item) => item.name === selectedSDG);
        } else {
            sdg = sdgs[0];
        }

        // Clear previous chart instance safely
        const sunburstElement = sunburstRef.current;

        if (sdg && sunburstElement) {
            const data = {
                name: sdg.name,
                children: [
                    {
                        name: "Direct",
                        children: sdg.categories
                            .filter(
                                (category) => category.correlation.toLocaleString() === "Direct"
                            )
                            .map((category) => {
                                return {
                                    name: category.name,
                                    value: category.val,
                                };
                            }),
                    },
                    {
                        name: "Indirect",
                        children: sdg.categories
                            .filter(
                                (category) =>
                                    category.correlation.toLocaleString() === "Indirect"
                            )
                            .map((category) => {
                                return {
                                    name: category.name,
                                    value: category.val,
                                };
                            }),
                    },
                ],
            };

            setSunburstData(data);
        }

        setChartOptions(options);
        setColorIdentifiers(chartColorIdentifiers);
        return () => {
            if (sunburstElement?.children[0]) {
                sunburstElement.removeChild(sunburstElement.children[0]);
            }
        };
    }, [
        
        selectedLCS,
        selectedLCSS,
        selectedProcess,
        loadedSDGs,
        selectedSDG,
    ]);

    const [applyGraphSettings, setApplyGraphSettings] =
        useState<
            (
                options: ChartJSOptions,
                chartData: ChartJSData
            ) => { options: ChartJSOptions; chartData: ChartJSData }
        >();

    const triggerChartUpdate = () => {
        if (!sunburstData || !sunburstRef.current) {
            return;
        }
        const sunburstElement = sunburstRef.current;
        console.log("Updating chart...");
        
        // Clear previous chart
        sunburstElement.innerHTML = "";
        
        // Initialize new Sunburst chart
        const myChart = Sunburst();

        let lastClickedNode = null;

        function getWordSubstring(str, wordCount) {
            const words = str.split(' '); // Split the string into words
            if (words.length <= wordCount) {
                return str; // If the string has fewer or equal words than the limit, return the full string
            }
            return words.slice(0, wordCount).join(' ') + '...'; // Return the limited words and add ellipsis
        }

        try {
            myChart
                .data(sunburstData)
                .color((d) => {
                    let color;
                    if (d.name === "Indirect") {
                        return "#a3a3a3";
                    } else if (d.name === "Direct") {
                        return "#7edfdb";
                    } else if (d.value) {
                        switch (d.value) {
                        case 1:
                            color = "#42a147";
                            break;
                        case 2:
                            color = "#fdc30a";
                            break;
                        case 3:
                            color = "#f47d00";
                            break;
                        case 4:
                            color = "#d22f2e";
                            break;
                        default:
                            color = "White";
                            break;
                        }
                        return color;
                    } else {
                        return "#E6E6E6";
                    }
                })
                .strokeColor("White")
                .size((d) => (d.value ? "10" : ""))
                .label((d) => {
                    if (d.name.length > 15) {
                        if (!d.value) {
                            // if(d?.__dataNode?.depth === 0){
                            //     return getWordSubstring(d.name, 3);
                            // } else {
                            return d.name.substring(0, 13) + "...";
                            // }
                        } else {
                            if (lastClickedNode && lastClickedNode === d) {

                                return d.name;
                            } else {
                                return d.name.substring(0, 17) + "...";
                            }
                        }
                    } else {
                        return d.name;
                    }
                })
                .nodeClassName((d) => {
                    if (lastClickedNode && lastClickedNode === d) {
                        if (!d.value) {
                            return "clicked process short";
                        } else {
                            return "clicked long";
                        }
                    } else {
                        if (!d.value) {
                            if(d?.__dataNode?.depth === 0){
                                console.log(d);

                                return "process processname short";
    
                            } else {
                                console.log(d);

                                return "process short";
                            }
                        } else {
                            return 'normal';
                        }
                    }
                })
                .onClick((d) => {
                    if (d?.__dataNode?.depth === 0) {
                        myChart.focusOnNode(d);
                    } else {
                        lastClickedNode = d;
                        myChart.focusOnNode(d);
                    }
                })
                .labelOrientation("radial")
                .handleNonFittingLabel((label) => label)
                .radiusScaleExponent(2)(sunburstElement);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        // Clear previous debounce timeout if it exists
        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }
    
        // Set a new debounce timeout
        const timeoutId = setTimeout(() => {
            triggerChartUpdate();
        }, 300); // 300ms delay to debounce
    
        // Save the new timeout ID to state
        setDebounceTimeout(timeoutId);
    
        // Clean up function to clear the timeout if component unmounts or values change again before the timeout finishes
        return () => clearTimeout(timeoutId);
    }, [selectedLCS, selectedLCSS, selectedProcess, sunburstData]);
    
    // useEffect(() => {
    //     if (sunburstData) {
    //         triggerChartUpdate();
    //     }
    // }, [sunburstData]);
    

    return (
        <ThemeProvider theme={ResultsTheme}>
            <Box
                sx={{
                    margin: "0",
                    maxWidth: "100%",
                    maxHeight: "-webkit-fill-available",
                    display: "flex",
                    flexDirection: "column",
                    gap: "1vw",
                }}
            >
                <Box component="div" className="results-wrapper">
                    <Box component="div" className="results-options">
                        <Paper square>
                            <Box component='div' className='results-options-top'>
                                <FormControl
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "1rem",
                                        alignItems: "stretch",
                                        flexWrap: "wrap",
                                    }}
                                    className="bottom-options side-left two-graphs"
                                >
                                    <SPAChartNavigation product={product} defaultBaseIndex={2} defaultResultIndex={3} />
                                    <SDGChecklist
                                        setSelectedSDGs={setSelectedSDGs}
                                        disabled={false}
                                        setting="C4"
                                        dimension="SPA"
                                    />
                                </FormControl>
                            </Box>
                        </Paper>
                        <Divider />
                        <Paper square>
                            <Box component="div" className="results-options-bottom">
                                <Box>
                                    <FormControl
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}
                                        className='bottom-options side-left'
                                    >
                                        <GraphType
                                            dimension="SPA"
                                            chart="C4"
                                            type="sunburst"
                                            sdgOn
                                        />
                                        <LCSDropdowns
                                            product={product}
                                            selectedLCS={selectedLCS}
                                            setSelectedLCS={setSelectedLCS}
                                            selectedLCSS={selectedLCSS}
                                            setSelectedLCSS={setSelectedLCSS}
                                            selectedProcess={selectedProcess}
                                            setSelectedProcess={setSelectedProcess}
                                            dimension="Social"
                                        />
                                        <Dropdown
                                            options={["Raw Values", "% Per Subcategory"]}
                                            valueSetter={setSelectedValueType}
                                            value={selectedValueType}
                                        />

                                        <Dropdown
                                            options={selectableSDGs}
                                            valueSetter={setSelectedSDG}
                                            value={selectedSDG}
                                        />
                                    </FormControl>
                                </Box>
                                <Box>
                                    <FormControl
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            flexWrap: "wrap",
                                            justifyContent: "center",
                                            "@media (max-width: 1680px)": {
                                                gap: "1vw",
                                            },
                                        }}
                                        className="bottom-options side-right"
                                    >
                                        <ChartDownload
                                            chartData={chartJSData}
                                            chartName={"Subcategory level result - Process (SDG/SPA)"}
                                            chartType="Sunburst Graph"
                                        />
                                        <GraphSetting
                                            chartOptions={chartOptions}
                                            setApplyGraphSettings={setApplyGraphSettings}
                                            triggerChartUpdate={triggerChartUpdate}
                                            colorIdentifiers={colorIdentifiers}
                                            chartData={chartJSData}
                                            chartColorType="performance"
                                            graphType="sunburst"
                                        />
                                    </FormControl>
                                </Box>
                            </Box>
                        </Paper>
                    </Box>
                </Box>
                <Box>
                    <Paper
                        square
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <Box className={classes.chartSec}>
                            <div ref={sunburstRef} id="sunburst" className="sunburst"></div>
                        </Box>
                    </Paper>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default C4SDGSunburstSPA;
