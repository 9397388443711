import { ChartJSData, ChartJSOptions } from '../../../interface/ChartData';
import ChartAuto, { ChartConfiguration, ChartTypeRegistry } from 'chart.js/auto';
import Chart from 'chart.js';

interface CreateChartData {
  type: keyof ChartTypeRegistry;
  data: ChartJSData;
  options: Chart.ChartOptions;
}

export const CreateChartTS = (
    chartJSData: ChartJSData ,
    chartOptions: ChartJSOptions | undefined,
    type: keyof ChartTypeRegistry = 'bar',
    canvasName? : string,
) => {
    let myChart: ChartAuto;
    const oldChart: ChartAuto | undefined = ChartAuto.getChart(canvasName || 'graph1Canvas');
    if (oldChart) {
        oldChart.destroy();
    }
    const ctx = document.getElementById(canvasName || 'graph1Canvas') as HTMLCanvasElement;
    if ((ctx != null) && chartOptions) {
        const createChartData: CreateChartData = {
            type,
            data: {
                labels: chartJSData.labels,
                datasets: chartJSData.datasets,
            },
            options: chartOptions as Chart.ChartOptions,
        };

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        myChart = new ChartAuto(ctx, createChartData);
    }
};
