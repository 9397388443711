import React from "react";
import "./Homepage.css";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Link } from "@tanstack/react-router";

const Services: React.FC = () => {
    return (
        <div className="website-only">
            <Header />

            <div className="header-container">
                <div className="header-transparent-layer"> </div>
                <img
                    src={require("../images/headers/pexels-snapwire-691458.jpg")}
                    alt="Windmills above the forest"
                />
                <h1>Services</h1>
            </div>
            <div className="box-container">
                <h2>Our Services</h2>
                <p className="p-hide">
          We know that developing optimal sustainability assessment solutions is
          important for you, TOSSA Sustainability Services will offer different
          ways to benefit from TOSSA tool. While TOSSA will be available for
          purchase through different licenses versions, our team will be
          providing consultancy services to conduct in-house customized
          assessment using TOSSA.{" "}
                </p>
            </div>
            <div className="box-container">
                <h2>Our Solutions</h2>
                <div className="image-container-three">
                    <div className="solution-container">
                        <img
                            src={require("../images/box-images/pexels-roberto-nickson-2559941.jpg")}
                            alt="Mountains in a cloudy jungle"
                        />
                        <div className="solution-info">
                            <h3>TOSSA Software</h3>
                            <p>
                Our TOSSA commercial software package will be unique by taking
                an integrated approach covering the impacts on the three
                dimensions of sustainability for all activities involved in all
                lifecycle stages of a product/service/technology together in one
                comprehensive and user-friendly decision-support tool unique in
                terms of its workflow, structure and graphic user-interface.
                            </p>
                            <Link to="/services/tossasoftware" className="button-link">
                Learn More
                            </Link>
                        </div>
                    </div>
                    <div className="solution-container">
                        <img
                            src={require("../images/box-images/pexels-daan-stevens-939328.jpg")}
                            alt="People watching the sun in a forest"
                        />
                        <div className="solution-info">
                            <h3>Consulting Services</h3>
                            <p>
                You don’t need to own the tool to benefit from the results of
                sustainability assessments generated from using TOSSA services.
                At TOSSA Sustainability Services, we do recognize that not all
                our customers may have the technical and human resources to run
                TOSSA, our team will add more flexibility by providing
                customized assessments for customers through consultancy
                services using TOSSA.{" "}
                            </p>
                            <Link to="/services/consultingservices" className="button-link">
                Learn More
                            </Link>
                        </div>
                    </div>
                    <div className="solution-container">
                        <img
                            src={require("../images/box-images/pexels-pixabay-144251.jpg")}
                            alt="Green mountains under a blue sky"
                        />
                        <div className="solution-info">
                            <h3>Training Courses</h3>
                            <p>
                TOSSA Sustainability Services offers training sessions and
                courses on life cycle sustainability assessment, social life
                cycle assessment and life cycle assessment. Upcoming courses are
                announced below.{" "}
                            </p>
                            <Link to="/services/training" className="button-link">
                Learn More
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
};

export default Services;
