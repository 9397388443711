/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useMemo, useEffect, useCallback } from 'react';
import { EconomicResult, Product } from '../../../../../interface/Product';
import { ThemeProvider } from '@emotion/react';
import FormControl from '@mui/material/FormControl';

import { Box, Paper, Divider } from '@mui/material';
import deepClone from '../../../../../helper/deepClone';
import { ChartJSData, ChartJSOptions } from '../../../../../interface/ChartData';
import LCSDropdowns from '../../../../dropdowns/LCSDropdowns';
import useStyles from '../../../charts.styles';
import { initialOptions } from '../../../options/InitialOptionValues';
import GraphDownload from '../../../options/components/ChartDownload';
import ProductComparison from '../../../options/components/Comparison';
import GraphSetting from '../../../options/components/GraphSettings';
import { applyValueType } from '../../../options/util/applyOptions';
import B4BarPSILCA from '../../../social/psilca/stakeholder/B4BarPSILCA';
import B4PiePSILCA from '../../../social/psilca/stakeholder/B4PiePSILCA';
import ResultsTheme from '../../../style/ResultsTheme';
import { CreateChartTS } from '../../../util/CreateChartTS';
import Dropdown from '../../../../dropdowns/Dropdown';
import EcoChartNavigation from '../../../../dropdowns/BaseOptions/EcoChartNavigation';
import { processColors, palette1, palette2, palette3, palette4 } from '../../../../../helper/colors';
import { useFormContext } from '../../../../forms/FormContext';
import GraphType from '../../../options/components/GraphTypeOptions';

interface ColorIndexData {
    label: string;
    identifier?: string;
}

interface ChartData {
    product: Product;
}
function A4PieEco(props: ChartData) {
    const { formState, setFormState } = useFormContext();
    const classes = useStyles();
    const { product } = props;
    const [chartJSData, setChartJSData] = useState<ChartJSData>({ datasets: [], labels: [] });
    const [chartOptions, setChartOptions] = useState<ChartJSOptions>();
    const [selectedValueType, setSelectedValueType] = useState<string>('');
    const [results, setResults] = useState<EconomicResult[]>([]);
    const [selectedLCS, setSelectedLCS] = useState<string>('');
    const [selectedLCSS, setSelectedLCSS] = useState<string>('');
    const [graphColors, setGraphColors] = useState<ColorIndexData[]>(formState.processColorIndexes || []);

    useMemo(() => {
        const calc = product.calculations.find(
            (item) => item.calculationType === 'ECONOMIC',
        );
        const calcResults = calc?.calculationResults as EconomicResult[];
        setResults(calcResults);
    }, [product]);

    const findColorPaletteByName = (name: string): string[] => {
        const palettes = {
            "Process Colors": processColors,
            "Sunset": palette1,
            "Multicolored": palette2,
            "Warm": palette3,
            "Cold": palette4,
        } as Record<string, string[]>;
        return palettes[name] || palettes["Process Colors"];
    };

    const generateExtendedPalette = (baseColors: string[], totalColors: number): string[] => {
        const extendedPalette = [...baseColors];
        while (extendedPalette.length < totalColors) {
            baseColors.forEach((color) => {
                const newColor = adjustColor(color, extendedPalette.length / totalColors);
                extendedPalette.push(newColor);
                if (extendedPalette.length >= totalColors) return;
            });
        }
        return extendedPalette.slice(0, totalColors);
    };

    const adjustColor = (color: string, factor: number): string => {
        const f = parseInt(color.slice(1), 16),
            t = factor < 0 ? 0 : 255,
            p = factor < 0 ? factor * -1 : factor,
            R = f >> 16,
            G = f >> 8 & 0x00FF,
            B = f & 0x0000FF;
        return "#" + (0x1000000 + (Math.round((t - R) * p) + R) * 0x10000 + (Math.round((t - G) * p) + G) * 0x100 + (Math.round((t - B) * p) + B)).toString(16).slice(1);
    };

    const getGraphColorIndex = useCallback(({ label, identifier }: ColorIndexData, palette: string[]) => {
        const hash = `${label}:${identifier}`.split('').reduce((hash, char) => {
            return char.charCodeAt(0) + ((hash << 5) - hash);
        }, 0);
        return Math.abs(hash) % palette.length;
    }, []);

    useEffect(() => {
        const chartData: ChartJSData = { datasets: [], labels: [] };
        const options: ChartJSOptions = deepClone<ChartJSOptions>(initialOptions);
        const matchingLCS = results.find((item) => item.name === selectedLCS);
        let lifeCycleSubstage;

        if (matchingLCS) {
            lifeCycleSubstage = matchingLCS.subStages.find((item) => item.name === selectedLCSS);

            if (lifeCycleSubstage) {
                const processIndexes = [];

                let processIndex = 0;

                lifeCycleSubstage.processes.forEach((process) => {
                    processIndexes.push({
                        name: process.processName,
                        index: processIndex++,
                        lifeCycleStage: `${selectedLCS}:${selectedLCSS}`,
                    });
                });

                const desiredOrder = [
                    'aluminium' /* Add other process names here */,
                ];

                const sortedProcesses = lifeCycleSubstage.processes.sort((a, b) => (
                    desiredOrder.indexOf(a.processName) - desiredOrder.indexOf(b.processName)
                ));

                const selectedPalette = findColorPaletteByName(formState.colorPalette);
                // const totalProcesses = processIndexes.length;
                const extendedPalette = generateExtendedPalette(selectedPalette, results.length);

                const datasets = sortedProcesses.map((process, index) => {
                    const colorIndex = getGraphColorIndex({
                        label: process.endProductName,
                    }, extendedPalette);
                    return {
                        label: process.processName,
                        data: [process.costResult],
                        backgroundColor: extendedPalette[colorIndex],
                    };
                });

                chartData.datasets = [{
                    data: datasets.map(dataset => dataset.data[0]),
                    backgroundColor: datasets.map(dataset => dataset.backgroundColor),
                    label: 'Cost Per Process',
                }];

                chartData.labels = datasets.map(dataset => dataset.label);
            }
        }

        options.scales = {
            x: {
                ticks: {
                    display: false,
                },
                grid: {
                    display: false,
                },
            } ,
            y: {
                ticks: {
                    display: false,
                },
                grid: {
                    display: false,
                },
            } ,
        };

        options.plugins = options.plugins || {};
        options.plugins.tooltip = {
            enabled: true,
        };
        options.plugins.legend = {
            display: true,
            position: 'bottom',
        };

        setChartJSData(chartData);
        setChartOptions(options);
    }, [selectedLCS, selectedLCSS, results, graphColors, getGraphColorIndex, formState.colorPalette]);

    const [applyGraphSettings, setApplyGraphSettings] =
        useState<(options: ChartJSOptions, chartData: ChartJSData) => { options: ChartJSOptions; chartData: ChartJSData; }>();

    const triggerChartUpdate = () => {
        let options = deepClone(chartOptions) || {};
        let chartData = deepClone(chartJSData);
        const applyOptionReturn = applyValueType(selectedValueType, chartData, options);
        options = applyOptionReturn.options;
        chartData = applyOptionReturn.chartData;
        if (applyGraphSettings) {
            ({ options, chartData } = applyGraphSettings(options, chartData));
        }
        CreateChartTS(chartData, options, 'pie');
    };

    useEffect(() => {
        if (chartJSData && chartOptions) {
            triggerChartUpdate();
        }
    }, [chartJSData, chartOptions]);

    return (
        <ThemeProvider theme={ResultsTheme}>
            <Box
                sx={{
                    margin: '0',
                    maxWidth: '100%',
                    maxHeight: '-webkit-fill-available',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1vw',
                }}
            >
                <Box className='temp-box' />
                <Box component='div' className='results-wrapper'>
                    <Box component='div' className='results-options'>
                        <Paper square>
                            <Box component='div' className='results-options-top'>
                                <FormControl
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: '3rem',
                                        alignItems: 'stretch',

                                    }}
                                >
                                    <EcoChartNavigation product={product} defaultBaseIndex={0} defaultResultIndex={3} />
                                    <ProductComparison />
                                </FormControl>
                            </Box>
                        </Paper>
                        <Divider />
                        <Paper square>
                            <Box component='div' className='results-options-bottom'>
                                <Box>
                                    <FormControl
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}
                                        className='bottom-options side-left'
                                    >
                                        <GraphType
                                            type='pie'
                                            dimension='Economic'
                                            chart='A4'
                                        />
                                        <LCSDropdowns
                                            product={product}
                                            selectedLCS={selectedLCS}
                                            setSelectedLCS={setSelectedLCS}
                                            selectedLCSS={selectedLCSS}
                                            setSelectedLCSS={setSelectedLCSS}
                                            dimension="Social"
                                        />
                                        <Dropdown
                                            options={['Raw Values', '% Per Process']}
                                            valueSetter={setSelectedValueType}
                                            value={selectedValueType}

                                        />

                                    </FormControl>
                                </Box>
                                <Box>
                                    <FormControl
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            '@media (max-width: 1680px)': {
                                                gap: '1vw',
                                            },
                                        }}
                                        className='bottom-options side-right'
                                    >
                                        <GraphDownload />
                                        <GraphSetting
                            
                                            chartOptions={chartOptions}
                                            setApplyGraphSettings={setApplyGraphSettings}
                                            triggerChartUpdate={triggerChartUpdate}
                                        />

                                    </FormControl>
                                </Box>
                            </Box>
                        </Paper>
                    </Box>
                </Box>
                <Box className={classes.chartSec}>
                    <Paper
                        square
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <Box className={classes.chartSec}>
                            <canvas id='graph1Canvas' className='graph-canvas' />
                        </Box>
                    </Paper>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default A4PieEco;
