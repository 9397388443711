import { createTheme, ThemeProvider } from '@mui/material';
import { hover } from '@testing-library/user-event/dist/hover';
import { styled } from '@mui/material/styles';
import { green } from '@mui/material/colors';

const HeaderDimensionsTheme = createTheme({
    components: {
        MuiPaper: {
            defaultProps: {
                elevation: 4,
                square: true,
            },
            styleOverrides: {
                root: {
                    elevation: 4,
                    padding: '10px',
                },
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    body1: {
                        fontSize: 14,
                        fontWeight: 'bold',
                        fontFamily: 'Roboto, sans-serif',
                    },
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    maxHeight: '48px',
                    width: '-webkit-fill-available',
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    width: '12vw',
                    boxShadow: '0 3px 6px #89B8A2',
                    backgroundColor: 'white',
                    padding: '0',
                    minHeight: '3.5vh',
                    textTransform: 'capitalize',
                    fontSize: '0.9vw',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                    color: '#004021',
                    '&.Mui-selected': {
                        backgroundColor: '#056939',
                        color: 'white',
                    },
                    '&.MuiTabs-indicator': {
                        display: 'none',
                    },
                    // '@media (max-width: 1300px)': {
                    //     fontSize: '1.1vw',
                    //     width: '9rem',
                    // },
                },
            },
        },
        // MuiTabs: {
        //     styleOverrides: {
        //         root: {
        //             width: '100%',
        //             display: 'flex',
        //             justifyContent: 'center',
        //             gap: '0.5rem',
        //         },
        //     },
        // },
        MuiButton: {
            styleOverrides: {
                root: {
                    maxWidth: '13vw',
                    minWidth: 'fit-content',
                    color: '#004021',
                    boxShadow: '0 3px 6px #89B8A2',
                    backgroundColor: 'white',
                    padding: '0',
                    minHeight: '32px',
                    textTransform: 'capitalize',
                    fontSize: '0.9vw',
                    borderRadius: '0',
                    paddingLeft: '4px',
                    paddingRight: '4px',
                    '&:hover': {
                        backgroundColor: '#D9EFDF',
                        color: '#004021',
                    },
                    // '@media (max-width: 1300px)': {
                    //     fontSize: '1.1vw',
                    //     // width: '8rem',
                    // },
                },
            },
        },
    },
    typography: {
        fontFamily: 'Montserrat, sans-serif',
        h2: {
            fontSize: 32,
            fontWeight: 'bold',
            fontFamily: 'Montserrat, sans-serif',
            '@media (max-width: 1680px)': {
                fontSize: 24,
            },
            '@media (max-width: 1300px)': {
                fontSize: 18,
            },
        },
        h3: {
            fontSize: 20,
            fontWeight: 'bold',
            fontFamily: 'Montserrat, sans-serif',
        },
        body1: {
            fontFamily: 'Roboto, sans-serif',
            fontSize: 14,
            '@media (max-width: 1680px)': {
                fontSize: 12,
            },
        },
    },
});

export default HeaderDimensionsTheme;
