import React from "react";
import "./Footer.css";

const Footer: React.FC = () => {
    return (
        <div>
            <footer>
                <h4>Contact Info</h4>
                <p>
          admin@tossa-sustainability.com | 1100-225 6 Ave SW Brookfield Place,
          Calgary, AB, Canada T2P 1N2
                </p>
                <h4>Follow Us</h4>
                <div className="social-media-icons-wrap">
                    <a href="https://www.linkedin.com/company/tossa-sustainability-services-inc">
                        <img
                            src={require("../images/social-media-icons/LinkedIn-Icon.png")}
                            alt="LinkedIn Icon"
                        />
                    </a>
                    <a href="https://twitter.com/Tossa_4Sd">
                        <img
                            src={require("../images/social-media-icons/Twitter-Icon.png")}
                            alt="Twitter Icon"
                        />
                    </a>
                    <a href="https://www.youtube.com/@tossasustainabilityservices">
                        <img
                            src={require("../images/social-media-icons/Youtube-Icon.png")}
                            alt="Youtube Icon"
                        />
                    </a>
                </div>

                <hr className="footer-line-break" />
                <p>© TOSSA Sustainability Services Inc. 2022</p>
            </footer>
        </div>
    );
};

export default Footer;
