import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => {return {
    chartSec: {
        width: '100%',
        height: '63vh',
    },
    TableHeaderCellStyled: {
        minWidth: '100px',
        color: 'white',
        backgroundColor: '#056939',
        lineHeight: '0.5rem',
        '@media (max-width: 1680px)': {
            fontSize: '12px',
        },
    },
    
    TableBodyCellStyled: {
        minWidth: '100px',
        padding: '15px',
        backgroundColor: '#D9EFDF',
        '@media (max-width: 1680px)': {
            fontSize: '12px',
            padding: '10px',
        },
    },
    
    SDGLegendWrap: {
        display: 'flex',
        flexDirection: 'row',
        gap: '1vw',
    },
    
    SDGLegendBox: {
        display: 'flex',
        flexDirection: 'row',
        gap: '5px',
    },
    
    TableHeaderCellImg: {
        padding: '1px',
        lineHeight: '10px',
        border: 0,
    },
}});

export default useStyles;
