import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => {return {

    IAMWrapper: {
        display: 'flex',
        alignItems: 'center',
    },



}});

export default useStyles;
